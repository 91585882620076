import React, { useEffect, useState } from "react";
import ReactRoutes from "./routes";
import useToken from "./constants/Storage/useToken";
import httpClient from "./constants/HttpClient/httpClient";
import httpClientForm from "./constants/HttpClient/httpClientForm";
import { CirclesWithBar } from "react-loader-spinner";
import { TOKEN } from "./constants/Services/constants";

function App() {
  const { token } = useToken();
  const [loading, setLoading] = useState(false);
  if (token) {
    // console.log("🚀 ~ file: App.js:13 ~ App ~ token:", token);
    httpClient.defaults.headers.common["Authorization"] =
      `Bearer ${token}` || `Bearer ${localStorage.getItem(TOKEN)}`;
    httpClientForm.defaults.headers.common["Authorization"] =
      `Bearer ${token}` || `Bearer ${localStorage.getItem(TOKEN)}`;
  }

  // console.log("token :", token);

  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 33333,
  };

  useEffect(() => {
    httpClient.interceptors.request.use(
      function (config) {
        // console.log("interceptor-config", config);
        setLoading(true);
        return config;
      },
      function (error) {
        setLoading(false);
        // disableScroll.off();
        return Promise.reject(error);
      }
    );

    httpClient.interceptors.response.use(
      function (response) {
        // console.log("http-interceptor", response);
        // setTimeout(() => {
        setLoading(false);
        // disableScroll.off();
        // }, 2000);
        return response;
      },
      function (error) {
        setLoading(false);
        // disableScroll.off();
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
      <CirclesWithBar
        visible={loading}
        height="110"
        width="110"
        color="#4fa94d"
        ariaLabel="circles-with-bar-loading"
        wrapperStyle={style}
      />

      <ReactRoutes />
    </>
  );
}

export default App;
