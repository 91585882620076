// @ts-nocheck
import React from "react";
import { tickIcon } from "../../assets/img";
import { userManagemnetTable } from "../../constants/StaticData";

const UserManagementTable = ({ lockedEmployees, release_locked_employee }) => {
  console.log("employss-list", lockedEmployees);
  return (
    <div className="user-mange-table">
      <table>
        <thead>
          <th>S.No</th>
          <th>Emp. Name</th>
          <th>Emp. ID</th>
          <th>Role</th>
          <th>status</th>
        </thead>
        <tbody>
          {lockedEmployees?.map((item, ind) => {
            return (
              <tr key={ind}>
                <td>{ind + 1}</td>
                <td>{item?.name}</td>
                <td>{item?.emp_id}</td>
                <td>{item?.role}</td>
                <td>
                  <div className="checkbox pointer">
                    {item?.status !== 1 && (
                      <img
                        src={tickIcon}
                        alt="check"
                        onClick={() => release_locked_employee(item?.id)}
                      />
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UserManagementTable;
