import React from "react";
import { Col } from "react-bootstrap";
import LabourMasterForm from "../../component/LabourMaster/LabourMasterForm";
import LabourMasterTab from "../../component/LabourMaster/LabourMasterTabSec";
import Header from "../../component/Header/Header";

const LabourMaster = () => {
  return (
    <Col xs={12} className="p-3">
      <Header />
      <LabourMasterForm />
      <LabourMasterTab />
    </Col>
  );
};

export default LabourMaster;
