export const logo = require("./logo.png");
export const loginTop = require("./login.png");
export const cres_img_1 = require("./cres_img_1.png");
export const cres_img_2 = require("./cres_img_2.jpg");
export const cres_img_3 = require("./cres_img_3.jpg");
export const cres_img_4 = require("./cres_img_4.jpg");
export const cres_img_5 = require("./cres_img_5.jpg");
export const dashImage = require("./dash.jpg");
//menu-icon
export const dashboard = require("./dashboard.png");
export const exection = require("./exection.png");
export const inventory = require("./inventory.png");
export const procurement = require("./procurement.png");
export const billing = require("./billing.png");
export const hr = require("./human-resources.png");
export const hrm = require("./hrms.png");
export const precure = require("./precure.png");
export const ism = require("./inventory.png");
export const finance = require("./finance.png");
export const report = require("./report.png");
export const logout = require("./logout.png");
export const calendarIcon = require("./calendar.png");

//employee-master-tab
export const addressIcon = require("./address.png");
export const bankIcon = require("./bank.png");
export const qualifWorkIcon = require("./qualif-work.png");
export const personalIcon = require("./personal.png");
export const currencyIcon = require("./salary.png");
export const uploadIcon = require("./upload.png");
export const arwIcon = require("./arw.png");
export const tickIcon = require("./tick.png");
export const dotIcon = require("./dot.png");
export const respIcon = require("./resp.png");

export const accountTreeIcon = require("./accountTree.png");

//dashboard

export const employeeIcon = require("./teamwork.png");
export const projectIcon = require("./project.png");
export const discountIcon = require("./discount.png");
// export const projectIcon = require('./project.png')

// employee-management
export const edit = require("./Edit.png");



