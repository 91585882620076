import React, { useState } from "react";
import { Col } from "react-bootstrap";
import EmployeeMasterForm from "../../component/EmployeeMaster/EmployeeMasterForm";
import EmployeeMasterTab from "../../component/EmployeeMaster/EmployeeMasterTab";
import Header from "../../component/Header/Header";

const EmployeeMaster = () => {
  const [employeeDetail, setEmployeeDetail] = useState();
  const [reset, setReset] = useState(false);
  return (
    <Col xs={12} className="p-3">
      <Header />
      <EmployeeMasterForm setEmployeeDetail={setEmployeeDetail} reset={reset} />
      {/* <EmployeeMasterTab
        employeeDetail={employeeDetail}
        reset={reset}
        setReset={setReset}
      /> */}
      {employeeDetail && (
        <EmployeeMasterTab
          employeeDetail={employeeDetail}
          reset={reset}
          setReset={setReset}
        />
      )}
    </Col>
  );
};

export default EmployeeMaster;
