import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { EMAIL } from "../../constants/Services/constants";
import { api } from "../../constants/Services";

const GSTAddress = ({
  gstFormDetails,
  setGstFormDetails,
  setTempForm,
  setShowDrop,
}) => {
  const [error, setError] = useState([]);
  const navigate = useNavigate();
  let newObj = Object.keys(gstFormDetails)
    .filter((key) => key !== "pan" && key !== "temp")
    .reduce((acc, key) => {
      acc[key] = gstFormDetails[key];
      return acc;
    }, {});
  // console.log("🚀 ~ file: GSTAddress.js:16 ~ GSTAddress ~ newObj:", newObj);

  const [gstaddressRequiredFields, setGstAddressRequiredFields] = useState({
    district: "",
    door_no: "",
    pincode: "",
    state: "",
    mobile: "",
    email: "",
  });

  const [addressNonRequiredFields, setAddressNonRequiredFields] = useState({
    phone_no: "",
    address: "",
    taluk: "",
  });

  const gstAddressSubmitHandler = () => {
    let temp = [];
    Object.entries(gstaddressRequiredFields).forEach((item) => {
      console.log("🚀 ~ file: GSTAddress.js:27 ~ Object.entries ~ item:", item);
      if (item[1] === "") {
        temp.push(item[0]);
      } else {
        let remove_error = error.indexOf(item[0]);
        if (item[0] === "email") {
          EMAIL.test(item[1])
            ? temp.splice(temp.indexOf(item[0]), 1)
            : temp.push(item[0]);
        } else {
          error.splice(remove_error, 1);
        }
      }
    });
    setError(temp);
    if (temp.length === 0 && error.length === 0) {
      let finalData = {
        ...newObj,
        ...addressNonRequiredFields,
        ...gstaddressRequiredFields,
        gst_no: `${gstFormDetails.gst_state}${gstFormDetails.pan}${gstFormDetails.temp}`,
        phone_no: "99999999",
      };
      api.vendorGst(finalData).then((res) => {
        console.log("vendor-gst-res", res);
        if (res?.status === "success") {
          gstFormResetHandler();
          navigate("/procurement/vendor-master", {
            state: {
              gst_state: gstFormDetails.gst_state,
              gst_date: gstFormDetails.gst_date,
              gst_type: gstFormDetails.gst_type,
              gst_no: `${gstFormDetails.gst_state}${gstFormDetails.pan}${gstFormDetails.temp}`,
            },
          });
        }
      });
    }
  };

  const gstFormResetHandler = () => {
    setGstAddressRequiredFields({
      district: "",
      door_no: "",
      pincode: "",
      state: "",
      mobile: "",
      email: "",
    });
    setGstFormDetails({
      pan: "",
      temp: "",
      gst_state: "",
      gst_type: "",
      vendor_id: "",
      gst_date: moment().format("YYYY-MM-DD"),
    });
    setAddressNonRequiredFields({
      phone_no: "",
      address: "",
      taluk: "",
    });

    setShowDrop(false);
    setTempForm({
      supplier_name: "",
      supplier_type: "",
    });
  };
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">Address Confirmation</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Door No & Street<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Door No & Street"
            value={gstaddressRequiredFields.door_no}
            onChange={(e) =>
              setGstAddressRequiredFields({
                ...gstaddressRequiredFields,
                door_no: e.target.value,
              })
            }
          />
          {error.includes("door_no") && (
            <p className="err">Please Enter Door No & Street</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Address Line 2</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Address Line 2"
            value={addressNonRequiredFields.address}
            onChange={(e) =>
              setAddressNonRequiredFields({
                ...addressNonRequiredFields,
                address: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Taluk</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Taluk"
            value={addressNonRequiredFields.taluk}
            onChange={(e) =>
              setAddressNonRequiredFields({
                ...addressNonRequiredFields,
                taluk: e.target.value.replace(/[^\sa-z]/gi, ""),
              })
            }
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            District<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter District"
            value={gstaddressRequiredFields.district}
            onChange={(e) =>
              setGstAddressRequiredFields({
                ...gstaddressRequiredFields,
                district: e.target.value,
              })
            }
          />
          {error.includes("district") && (
            <p className="err">Please Enter District</p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            State<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter State"
            value={gstaddressRequiredFields.state}
            onChange={(e) =>
              setGstAddressRequiredFields({
                ...gstaddressRequiredFields,
                state: e.target.value.replace(/[^\sa-z]/gi, ""),
              })
            }
          />
          {error.includes("state") && <p className="err">Please Enter State</p>}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Pincode<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Pincode"
            type="number"
            value={gstaddressRequiredFields.pincode}
            onChange={(e) =>
              setGstAddressRequiredFields({
                ...gstaddressRequiredFields,
                pincode: e.target.value.replace(/[^0-9]/gi, ""),
              })
            }
          />
          {error.includes("pincode") && (
            <p className="err">Please Enter Pincode</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Phone No</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Phone No"
            type="number"
            value={addressNonRequiredFields.phone_no}
            onChange={(e) =>
              setAddressNonRequiredFields({
                ...addressNonRequiredFields,
                phone_no: e.target.value.replace(/[^0-9]/gi, ""),
              })
            }
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Mobile No<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Mobile No"
            type="number"
            value={gstaddressRequiredFields.mobile}
            onChange={(e) =>
              setGstAddressRequiredFields({
                ...gstaddressRequiredFields,
                mobile: e.target.value.replace(/[^0-9]/gi, ""),
              })
            }
          />
          {error.includes("mobile") && (
            <p className="err">Please Enter Mobile No</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            E-Mail<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter your E-mail"
            type="email"
            value={gstaddressRequiredFields.email}
            onChange={(e) =>
              setGstAddressRequiredFields({
                ...gstaddressRequiredFields,
                email: e.target.value,
              })
            }
          />
          {error.includes("email") && (
            <p className="err">Please Enter Valid Email</p>
          )}
        </Col>
        <Col sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={gstAddressSubmitHandler}>
            Submit
          </button>
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
            onClick={gstFormResetHandler}>
            cancel
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default GSTAddress;
