import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { logo } from "../../assets/img";
import { api } from "../../constants/Services";
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import Header from "../../component/Header/Header";
import useUser from "../../constants/Storage/userData";
// import html2PDF from "jspdf-html2canvas";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { renderToString } from "react-dom/server"; // Import renderToString from ReactDOM for server-side rendering
import PrinterFooter from "../../component/PoList/PrinterFooter";

function PoOutputScreenCopy() {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { user } = useUser();
    const [poPrintDetails, setPOPrintDetails] = useState([]);
    const [terms, setTerms] = useState([]);
    const [directPoAttributes, setDirectPoAttributes] = useState([]);
    const [poAnnex, setPOAnnex] = useState([]);
    const [numToWords, setNumToWords] = useState("");
    const [poTable, setPoTable] = useState([]);
    const [approval, setapproval] = useState("");
    // poPrintDetails?.material_request?.request_type == 1
    function doConvert(numberInput) {
        let oneToTwenty = [
            "",
            "one ",
            "two ",
            "three ",
            "four ",
            "five ",
            "six ",
            "seven ",
            "eight ",
            "nine ",
            "ten ",
            "eleven ",
            "twelve ",
            "thirteen ",
            "fourteen ",
            "fifteen ",
            "sixteen ",
            "seventeen ",
            "eighteen ",
            "nineteen ",
        ];
        let tenth = [
            "",
            "",
            "twenty",
            "thirty",
            "forty",
            "fifty",
            "sixty",
            "seventy",
            "eighty",
            "ninety",
        ];

        let numToWord = numberInput.toString();
        if (numToWord.includes(".")) numToWord = numToWord.split(".")[0];

        let num = ("000000000" + numToWord)
            .slice(-9)
            .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

        if (!num) return;

        let outputText =
            num[1] != 0
                ? (oneToTwenty[Number(num[1])] ||
                    `${tenth[num[1][0]]} ${oneToTwenty[num[1][1]]}`) + " crore "
                : "";
        outputText +=
            num[2] != 0
                ? (oneToTwenty[Number(num[2])] ||
                    `${tenth[num[2][0]]} ${oneToTwenty[num[2][1]]}`) + " lakh "
                : "";

        outputText +=
            num[3] != 0
                ? (oneToTwenty[Number(num[3])] ||
                    `${tenth[num[3][0]]} ${oneToTwenty[num[3][1]]}`) + "thousand "
                : "";
        outputText +=
            num[4] != 0
                ? (oneToTwenty[Number(num[4])] ||
                    `${tenth[num[4][0]]} ${oneToTwenty[num[4][1]]}`) + "hundred "
                : "";
        outputText +=
            num[5] != 0
                ? (oneToTwenty[Number(num[5])] ||
                    `${tenth[num[5][0]]} ${oneToTwenty[num[5][1]]}`) + " "
                : "";
        // console.log("Result-1 = ", outputText);

        outputText = outputText?.charAt(0).toUpperCase() + outputText?.slice(1);
        // console.log("Result = ", outputText);

        setNumToWords(outputText);
    }

    const getList = (paramId) => {
        api
            .purchaseOrderView(paramId ? paramId : location.state?.id)
            .then((res) => {
                if (res?.status == "success") {
                    setPOPrintDetails(res?.purchase_orders);
                    setPoTable(res?.purchase_orders?.purchase_order_details);
                    setPOAnnex(res?.purchase_orders?.purchase_order_annexures);
                    let temp = [];

                    const totalValue =
                        res?.purchase_orders?.purchase_order_details?.reduce(
                            (acc, po) => acc + +po?.net_value,
                            0
                        );
                    setapproval(res?.purchase_orders?.approval[0]?.employee?.name);
                    if (totalValue) {
                        doConvert(totalValue);
                    }
                    if (
                        res?.purchase_orders?.terms_conditions &&
                        res?.purchase_orders?.terms_conditions.includes(",")
                    ) {
                        temp = res?.purchase_orders?.terms_conditions.split(",");
                    } else {
                        res?.purchase_orders?.terms_conditions &&
                            temp.push(res?.purchase_orders?.terms_conditions);
                    }
                    setTerms(temp);
                    if (res?.purchase_orders?.material_request?.request_type == 2) {
                        let temp_att = [];
                        let temp_obj = {};
                        res?.purchase_orders?.purchase_order_details?.map((po) => {
                            po?.attributes && po?.attributes.includes(",")
                                ? temp_att.push(po?.attributes.split(","))
                                : temp_att.push(po?.attributes);
                            temp_obj[po?.id] = temp_att;
                        });

                        setDirectPoAttributes(temp_obj);
                    }
                }
            });
    };
    useEffect(() => {
        if (searchParams?.get("id")) {
            getList(searchParams?.get("id"));
        } else {
            getList();
        }
    }, [searchParams?.get("id")]);

    const downloadPdfDocument = (div_id) => {
        const divToPrint = document.getElementById(div_id);

        // Return false;
        window.scrollTo(0, 0);
        html2canvas(divToPrint).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const imgWidth = 190;
            const pageHeight = 290;
            const bottomSpace = 20; // Space at the bottom of each page
            const marginBottom = 10; // Margin bottom of the content in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            // Define your footer content and style
            // const footerText = document.getElementById("page-break"); // Assuming footer content is inside an element with id "page-break"

            // const footerHTML = "sdkask jhsjd hjkh";
            const footerHTML = renderToString(<PrinterFooter />);

            const footerFontSize = 10;
            const footerMargin = 10; // Margin from bottom of the page in mm
            const footerHeight = footerFontSize / 2; // Estimated height of footer text

            let heightLeft = imgHeight;
            const doc = new JsPDF("pt", "mm");
            let position = 0;

            doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight + 24);
            addFooter(
                doc,
                footerHTML,
                footerFontSize,
                pageHeight - marginBottom - footerMargin
            );
            heightLeft -= pageHeight - bottomSpace;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight + 24);
                addFooter(
                    doc,
                    footerHTML,
                    footerFontSize,
                    pageHeight - marginBottom - footerMargin
                );
                heightLeft -= pageHeight - bottomSpace;
            }

            doc.save("PoInvoice.pdf");
        });
    };

    function addFooter(doc, text, fontSize, yPosition) {
        doc.setFontSize(fontSize);
        doc.text(text, 10, yPosition);

        // You can add more styling here as needed, such as line or box around the footer
    }

    return (

        <div className="container" style={{ backgroundColor: "#fff", }}>
            <div id="po-out-main-cont" >
                <div

                    style={{
                        borderBottom: "0px solid black",
                        borderTop: "1px solid black",
                        borderRight: "1px solid black",
                        borderLeft: "1px solid black",
                    }}
                >
                    <div
                        className="all"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0px  10px"
                        }}
                    >
                        <div
                            className="img"
                            style={{ display: "flex", justifyContent: "center" }}
                        >
                            <img src={logo} alt="" style={{ width: "50%" }} />
                        </div>
                        <div className="head" style={{ textAlign: "center", padding: 10 }}>
                            <h5 style={{ margin: "2px  0px" }}>
                                Crescon Projects and Services Pvt Ltd
                            </h5>
                            <p style={{ margin: 0 }}>
                                3rd Floor, Gulecha Towers 158, Arcot Road, Vadapalani Chennai - 600
                                026
                            </p>
                            <h5 style={{ margin: "2px 0px 0px" }}>Purchase Order</h5>
                        </div>
                        <div className="iso" style={{ padding: 10 }}>
                            <p style={{ margin: "2px 0px" }}>
                                ISO 9001:2015 : Quality Management System
                            </p>
                            <p style={{ margin: "2px 0px" }}>
                                ISO 14001:2015 : Environmental Management System
                            </p>
                            <p style={{ margin: 0 }}>
                                ISO 45001:2018 : Occupational Health &amp; Safety Management System
                            </p>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <table
                        border={1}
                        cellSpacing={0}
                        width="100%; border:1px solid black !important;"
                    >
                        <tbody>
                            <tr>
                                <td style={{ padding: "10px", width: "60%" }}>
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td
                                                    colSpan={2}
                                                    style={{ padding: "0px 0px 5px 10px", textAlign: "center" }}
                                                >
                                                    <b>TO</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Vendor Name</td>
                                                <td style={{ padding: "2px 10px" }}>
                                                    M/s.Ashhirwadd analytical laboratary
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Address</td>
                                                <td style={{ padding: "2px 10px" }}>
                                                    No.182,2nd floor,4th Main road,Mahaveer Nager
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>State</td>
                                                <td style={{ padding: "2px 10px" }}>Pondicherry</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Mobile No</td>
                                                <td style={{ padding: "2px 10px" }}>7598491505</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Email ID</td>
                                                <td style={{ padding: "2px 10px" }}>aageotech@gmail.com</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>GST No</td>
                                                <td style={{ padding: "2px 10px" }}>34AOSPR9650R1ZN</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td style={{ padding: "10px " }}>
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Po No</td>
                                                <td style={{ padding: "2px 10px" }}>111E202400441</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>PR Date</td>
                                                <td style={{ padding: "2px 10px" }}>2024-06-02</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Mr No</td>
                                                <td style={{ padding: "2px 10px" }}>111E00332</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>MR Date</td>
                                                <td style={{ padding: "2px 10px" }}>2024-06-02</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            {/* <tr>
        </tr> */}
                        </tbody>
                    </table>
                    <table
                        border={1}
                        cellSpacing={0}
                        width="100%; border:1px solid black !important;"
                        style={{ fontFamily: "Arial, sans-serif", border: "1px solid black" }}
                    >
                        <tbody>
                            <tr>
                                <td style={{ padding: "10px", width: "60%" }}>
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td
                                                    colSpan={2}
                                                    style={{ textAlign: "center", padding: "4px 0px" }}
                                                >
                                                    <b>Invoice Address:</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Head Office</td>
                                                <td style={{ padding: "2px 10px" }}>
                                                    Crescon Projects and Services Pvt Ltd
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Address</td>
                                                <td style={{ padding: "2px 10px" }}>
                                                    3rd Floor,Gulecha Towers 158,Arcot Road,Vadapalani Chennai -
                                                    600 026
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Contact Person</td>
                                                <td style={{ padding: "2px 10px" }}>M.Janakiraman</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Contact No</td>
                                                <td style={{ padding: "2px 10px" }}>044-2365 2551</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Mail ID</td>
                                                <td style={{ padding: "2px 10px" }}>
                                                    purchase@cresconprojects.com
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>GST No</td>
                                                <td style={{ padding: "2px 10px" }}>33AAECC6175F1ZT</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td style={{ padding: "10px" }}>
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr>
                                                <td
                                                    colSpan={2}
                                                    style={{ textAlign: "center", padding: "4px 0px" }}
                                                >
                                                    <b>Delivery Address</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Project Name</td>
                                                <td style={{ padding: "2px 10px" }}>Project 111</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Project Address</td>
                                                <td style={{ padding: "2px 10px" }}>686, CS Street</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>State</td>
                                                <td style={{ padding: "2px 10px" }}>Tamil Nadu</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>District</td>
                                                <td style={{ padding: "2px 10px" }}>Chennai</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Pincode</td>
                                                <td style={{ padding: "2px 10px" }}>600 100</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Contact No</td>
                                                <td style={{ padding: "2px 10px" }}>8546321023</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: "2px 10px" }}>Mail ID</td>
                                                <td style={{ padding: "2px 10px" }}>project@gmail.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr></tr>
                        </tbody>
                    </table>
                    <div
                        style={{
                            borderTop: "0px solid black",
                            borderBottom: "1px solid black",
                            borderRight: "1px solid black",
                            borderLeft: "1px solid black"
                        }}
                        className="newpage"
                    >
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr style={{ borderBottom: "1px solid #cde2fe" }}>
                                    <td style={{ padding: "5px 5px", textAlign: "left", fontSize: 14 }}>
                                        <b>S.No.</b>
                                    </td>
                                    <td style={{ padding: "5px 5px", textAlign: "left", fontSize: 14 }}>
                                        <b>Material Description</b>
                                    </td>
                                    <td style={{ padding: "5px 5px", textAlign: "left", fontSize: 14 }}>
                                        <b>UOM</b>
                                    </td>
                                    <td style={{ padding: "5px 5px", textAlign: "left", fontSize: 14 }}>
                                        <b>Quantity</b>
                                    </td>
                                    <td style={{ padding: "5px 5px", textAlign: "left", fontSize: 14 }}>
                                        <b>Unit Rate (INR)</b>
                                    </td>
                                    <td style={{ padding: "5px 5px", textAlign: "left", fontSize: 14 }}>
                                        <b>Total Value </b>
                                    </td>
                                    <td style={{ padding: "5px 5px", textAlign: "left", fontSize: 14 }}>
                                        <b>HSN Code </b>
                                    </td>
                                    <td style={{ padding: "5px 5px", textAlign: "left", fontSize: 14 }}>
                                        <b>IGST </b>
                                    </td>
                                    <td style={{ padding: "5px 5px", textAlign: "left", fontSize: 14 }}>
                                        <b>Net Value </b>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #cde2fe" }}>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>S.No.</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>
                                        Material Description
                                    </td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #cde2fe" }}>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Attribure</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>
                                        150AH x 80Nos.Battery Bank,Battery racks/Stand along with DC
                                        Isolation breaker,required interconnecting cable and accessories
                                        for successful installation.
                                    </td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                </tr>
                                <tr style={{ borderBottom: "1px solid #cde2fe" }}>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>S.No.</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>
                                        Material Description
                                    </td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Net Value</td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #cde2fe" }}>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>Attribure</td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }}>
                                        150AH x 80Nos.Battery Bank,Battery racks/Stand along with DC
                                        Isolation breaker,required interconnecting cable and accessories
                                        for successful installation.
                                    </td>
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                    <td style={{ padding: "3px 5px", textAlign: "left" }} />
                                </tr>
                            </tbody>
                        </table>
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr style={{ borderBottom: "1px solid #cde2fe" }}>
                                    <td style={{ padding: 10, textAlign: "center" }}>
                                        <b>Basic Rate(INR)</b>
                                    </td>
                                    <td style={{ padding: 10, textAlign: "center" }}>
                                        <b>IGST</b>
                                    </td>
                                    <td style={{ padding: 10, textAlign: "center" }}>
                                        <b>Total Value</b>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "1px solid #cde2fe" }}>
                                    <td style={{ padding: 10, textAlign: "center" }}>
                                        <b>2445659</b>
                                    </td>
                                    <td style={{ padding: 10, textAlign: "center" }}>
                                        <b>461795.94</b>
                                    </td>
                                    <td style={{ padding: 10, textAlign: "center" }}>
                                        <b>3027328.94</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style={{ margin: "15px 20px" }} className="newpage">
                            <p>
                                Amount In Words (Rupees Thirty lakh twenty seven thousand three
                                hundred twenty eight Only)
                            </p>
                            <p>
                                <b>Terms And Conditions</b>
                            </p>
                            <p>1.Payment</p>
                            <p>2.Transportation</p>
                            <p>3.Delivery</p>
                            <p>4.return Policy</p>
                        </div>
                    </div>
                </div>
                <table
                    cellSpacing={0}
                    width="100%;"
                    className="all newpage"
                    style={{
                        borderTop: "0px solid #000",
                        borderLeft: "1px solid #000",
                        borderBottom: "1px solid #000",
                        borderRight: "1px solid #000"
                    }}
                >
                    <tfoot className="report-footer footer">
                        <tr>
                            <td
                                style={{
                                    padding: "0px 10px",
                                    textAlign: "center",
                                    borderBottom: "#000 solid 1px"
                                }}
                            >
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{ padding: "6px 10px 1px 10px", textAlign: "start" }}
                                            >
                                                Rajesh Ravi
                                            </td>
                                            <td style={{ padding: "6px 10px 1px 10px", textAlign: "end" }}>
                                                M. JanakiRaman
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{ padding: "6px 10px 1px 10px", textAlign: "start" }}
                                            >
                                                Prepared
                                            </td>
                                            <td style={{ padding: "6px 10px 1px 10px", textAlign: "end" }}>
                                                Approved
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: "0px 10px", textAlign: "center" }}>
                                <table style={{ width: "100%" }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: "10px 10px 0px", textAlign: "start" }}>
                                                Please Sign and Return Duplicate Copy as a Token of Acceptance
                                            </td>
                                            <td style={{ padding: "10px 10px 0px", textAlign: "end" }}>
                                                For Crescon Projects and Services Pvt Ltd
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style={{ padding: "40px 10px 2px 10px", textAlign: "start" }}
                                            >
                                                Vendor Acceptance
                                            </td>
                                            <td
                                                style={{ padding: "40px 10px 2px 10px", textAlign: "end" }}
                                            >
                                                Authorised Signature
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {poPrintDetails?.status == 2 && (
                        <button
                            class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
                            onClick={() => downloadPdfDocument("po-out-main-cont")}
                        >
                            Print
                        </button>
                    )}
                </div>
                <div className="">
                    {searchParams?.get("id") ? (
                        <Link
                            class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2 "
                            to={`/procurement/puchase-order-list?projectname=${searchParams?.get(
                                "projectname"
                            )}&project=${searchParams?.get("project")}`}
                        >
                            Close
                        </Link>
                    ) : (
                        <button
                            class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
                            onClick={() => navigate(-1)}
                        >
                            Close
                        </button>
                    )}
                </div>
            </div>
        </div>


    )
};

export default PoOutputScreenCopy;
