import { Col } from "react-bootstrap";
import React from "react";
import BoqAmendTable from "./BoqAmendTable";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function BoqAmendTableSection({ unChanged, boqDetail }) {
  // console.log(boqDetail);
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center ">
      <Tabs
        defaultActiveKey="list"
        id="uncontrolled-tab-example"
        className="mx-3"
        onSelect={(e) => console.log("e", e)}
      >
        <Tab className="mb-2" eventKey="list" title="List">
          <BoqAmendTable unChanged={unChanged} tab="unchanged" />
        </Tab>
        <Tab className="mb-2" eventKey="newly-added" title="Newly Added">
          <BoqAmendTable boqDetail={boqDetail} tab="newly_added" />
        </Tab>
        <Tab className="mb-2" eventKey="removed" title="Removed">
          <BoqAmendTable boqDetail={boqDetail} tab="deleted" />
        </Tab>
        <Tab className="mb-2" eventKey="updated" title="Updated">
          <BoqAmendTable boqDetail={boqDetail} tab="changed" />
        </Tab>
      </Tabs>
    </Col>
  );
}

export default BoqAmendTableSection;
