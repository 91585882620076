import React, { useState, useEffect } from "react";
import eye_icon from "../../assets/icons/common/Eye.svg";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";
import moment from "moment";

function BOQAmendmentList() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [MRList, setMRList] = useState([]);

  const [selectedProject, setSelectedProject] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [boqLists, setBoqLists] = useState([]);

  const getMaterialList = () => {
    let data = {
      project_id: +selectedProject,
    };
    api.matrialList(data).then((res) => {
      if (res?.status == "success") {
        setMRList(res?.material_requests);
      }
    });
  };

  const getList = (e) => {
    api.boq_list(e).then((resp) => {
      console.log(resp);
      if (resp.status == "success") {
        setBoqLists(resp?.all_boqs);
      }
    });
  };

  const getProjects = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };
  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (searchParams?.get("proId")) {
      setSelectedProject(searchParams?.get("proId"));
    }
  }, [searchParams?.get("proId")]);

  useEffect(() => {
    getMaterialList();

    if (selectedProject !== "") {
      getList(selectedProject);
    }
  }, [selectedProject]);
  console.log(boqLists);
  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white my-3 text-center">
        <header>
          <Col xs={12} className="d-flex mb-3 py-2">
            <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 ms-auto pointer"
              value={selectedProject}
              onChange={(e) => {
                setSelectedProject(e.target.value);
              }}
            >
              <option>Select Project</option>
              {projectList?.map((project) => (
                <option key={project?.id} value={project?.id}>
                  {project?.name}
                </option>
              ))}
            </select>
          </Col>
        </header>
        {/* <h4>Material Requests List</h4> */}
        <h4>BOQ Amendment List</h4>
        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>BOQ No</th>
                <th>Created at</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {boqLists?.length > 0 &&
                boqLists?.map((it, ind) => {
                  return (
                    <tr>
                      <td>{ind + 1}</td>
                      <td>{it?.boq_no}</td>
                      <td>{moment(it?.created_at).format("DD-MMM-yyyy")}</td>
                      {it?.status == 0 && <td> Initiated</td>}
                      {it?.status == 1 && <td> Accept</td>}
                      {it?.status == 2 && <td> DeActive</td>}
                      {it?.status == 3 && <td> Rejected</td>}
                      <td>
                        <Link
                          to={`/home/boq-amendment-list/boq-amendment-view?id=${
                            it?.id
                          }&proId=${+selectedProject}`}
                        >
                          <img
                            // onClick={() => {
                            //   navigate(
                            //     "/home/boq-amendment-list/boq-amendment-view",
                            //     { state: { id: it?.id } }
                            //   );
                            // }}
                            style={{ width: "20px" }}
                            className="pointer ms-2"
                            src={eye_icon}
                            alt="eye-icon"
                          />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Col>
    </Col>
  );
}

export default BOQAmendmentList;
