import React, { Component } from "react";
import Chart from "react-apexcharts";

function ProjectPieChart({ showSelect }) {
  console.log(showSelect);
  const chart_one = {
    series: [showSelect == "Month" ? 147 : 70, 1654],
    options: {
      colors: ["#ff9a34", "#008920"],
      labels: ["Completed Projects", "Ongoing Projects"],
      dataLabels: {
        enabled: true,
        enabledOnSeries: 10,
        textAnchor: "middle",
      },
      subtitle: {
        align: "center",
      },
      legend: {
        show: false,
      },
    },
  };
  return (
    <Chart
      options={chart_one.options}
      series={chart_one.series}
      type="pie"
      width="300"
      height="300"
    />
  );
}

export default ProjectPieChart;
