import React, { useState } from "react";
import StatusModal from "../StatusModal";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";

const BoqCreationForm = ({
  projectList,
  projectDetails,
  boqFormDetails,
  setBoqFormDetails,
  setProjectDetails,
}) => {
  const [boqError, setBoqError] = useState([]);
  const [showStatus, setShowStatus] = useState(false);
  const statusModalClose = () => {
    setShowStatus(!showStatus);
  };

  const boqFormSubmitHandler = () => {
    let set_error = Object.entries(boqFormDetails)
      .filter((item) => item[1] === "" && item.splice(1, 1))
      ?.reduce((acc, cur_item) => acc.concat(cur_item), "");
    setBoqError(set_error);

    if (boqError.length === 0) {
      console.log("test-tt", boqFormDetails);
      api.project_boq_add(boqFormDetails).then((res) => {
        console.log(
          "🚀 ~ file: BoqCreationForm.js:28 ~ api.project_boq_add ~ res:",
          res
        );
        if (res?.status === "success") {
          statusModalClose();
          setProjectDetails({
            ...projectDetails,
            boq_details: res?.boq_details,
          });
        }
      });
    }
  };
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="ms-xl-0 w-95">BOQ Creation</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <select
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 "
            value={boqFormDetails.project_id}
            onChange={(e) =>
              setBoqFormDetails({
                ...boqFormDetails,
                project_id: e.target.value,
              })
            }>
            <option key="0" value={null}>
              Select Project
            </option>
            {projectList?.map((project) => (
              <option key={project?.id} value={project?.id}>
                {project?.name}
              </option>
            ))}
          </select>

          {boqError.includes("project_id") && (
            <p className="err"> Please Select Project</p>
          )}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">BOQ No</label>
          <p
            style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1">
            {boqFormDetails.boq_no}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Completion Date</label>
          <p
            style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1">
            {boqFormDetails.completion_date}
          </p>
          {/* <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Completion Date"
            value={boqFormDetails.completion_date}
            onChange={(e) =>
              setBoqFormDetails({
                ...boqFormDetails,
                completion_date: e.target.value,
              })
            }
          /> */}
          {boqError.includes("completion_date") && (
            <p className="err"> Please Enter Completion Date</p>
          )}
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">LOI Date</label>
          <p
            style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1">
            {boqFormDetails.loi_date}
          </p>
          {/* <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter LOI Date"
            value={boqFormDetails.loi_date}
            onChange={(e) =>
              setBoqFormDetails({
                ...boqFormDetails,
                loi_date: e.target.value,
              })
            }
          /> */}
          {boqError.includes("loi_date") && (
            <p className="err"> Please Enter LOI Date</p>
          )}
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={6}
          xl={6}
          className="inputinner-box text-start position-relative">
          <label className="w-100">Upload File</label>

          <p className="file_name-display-box">
            {boqFormDetails.import_excel?.name
              ? boqFormDetails.import_excel?.name
              : "Upload Document"}
          </p>

          <div className="d-sm-flex w-90 ms-0 my-1 emply-mas-doc-box accordion-body bg-lightBlue">
            <input
              type="file"
              className="border-0 px-1"
              onChange={(e) =>
                setBoqFormDetails({
                  ...boqFormDetails,
                  import_excel: e.target.files[0],
                })
              }
            />
          </div>
          {boqError.includes("import_excel") && (
            <p className="err"> Please Upload Excel</p>
          )}
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={6}
          xl={6}
          className="inputinner-box text-start my-2">
          <label className="w-100"></label>
          <a
            href="http://erp-script.cresconprojects.com/public/excel/cresscon-project-boqs.xlsx"
            target="_blank"
            rel="noreferrer"
            className="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-4 py-2 pointer">
            Download
          </a>
        </Col>

        <Col xs={12} sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={boqFormSubmitHandler}>
            Submit
          </button>
        </Col>
      </Col>
      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
        />
      )}
    </Col>
  );
};

export default BoqCreationForm;
