// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import StatusModal from "../StatusModal";
import { addressIcon, personalIcon } from "../../assets/img";
import { qualifWorkIcon, report, respIcon } from "../../assets/img";
import { api } from "../../constants/Services";
import PCAddressTab from "./PCAddressTab";
import PCProjectDetailsTab from "./PCProjectDetails";

const ProjectCreationTab = ({ projectForm, setProjectForm, setFormError }) => {
  const [tab, setTab] = useState("address");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const statusModalClose = () => {
    setShowStatus(!showStatus);
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  const [projectDetails, setProjectDetails] = useState({
    awarded_date: "",
    start_date: "",
    end_date: "",
    loi_date: "",
    value: "",
    eot: "",
  });
  const [projectAddressRequiredDetails, setProjectAddressRequiredDetails] =
    useState({
      door_no: "",
      district: "",
      state: "",
      pincode: "",
      mobile: "",
      email: "",
    });
  const [projectAddressDetails, setProjectAddressDetails] = useState({
    address: "",
    taluk: "",
    phone_no: "",
    alternate_email: "",
  });

  const finalData = {
    ...projectForm,
    ...projectAddressDetails,
    ...projectAddressRequiredDetails,
    ...projectDetails,
    // head_office: "Crescon-Projects and Service Pvt Ltd", 04-09-2024
    // phone_no: "99999999",
    // address: "safsdfsdf",
    // taluk: "sdfsdf",
  };

  const resetData = () => {
    setProjectAddressDetails({
      address: "",
      taluk: "",
      mobile_1: "",
      alternate_email: "",
    });

    setProjectAddressRequiredDetails({
      door_no: "",
      district: "",
      state: "",
      pincode: "",
      mobile: "",
      email: "",
    });

    setProjectDetails({
      awarded_date: "",
      start_date: "",
      end_date: "",
      loi_date: "",
      value: "",
      eot_date: "",
    });

    setProjectForm({
      head_office: "Crescon-Projects and Service Pvt Ltd",
      name: "",
      project_no: "",
      date: "",
    });
  };

  const ProjectCreationFormSubmitHandler = () => {
    api.projectCreate(finalData).then((res) => {
      console.log("first-project-creation", res);
      if (res?.status === "success") {
        resetData();
        setDisableSubmit(true);
        setShowStatus(true);
      }
    });
  };

  const tabonClickHandler = (e) => {
    Object.values(projectAddressRequiredDetails).every((x) => x !== "") &&
      setTab(e);
  };

  return (
    <Col className="roles-resp-tab py-2 px-1 px-md-3 py-2 mt-1 text-center">
      <Col className="d-flex flex-wrap justify-content-center">
        <button
          onClick={(e) => tabonClickHandler((e = "address"))}
          className={
            tab == "address"
              ? "tab-list bg-gradient border-0"
              : "tab-list bg-gray border-0"
          }
        >
          <img src={addressIcon} className="tabIcon" alt="tab-icon" />
          <p>Address</p>
        </button>

        <button
          onClick={(e) => tabonClickHandler((e = "project"))}
          className={
            tab == "project"
              ? "tab-list bg-gradient border-0"
              : "tab-list bg-gray border-0"
          }
        >
          <img src={personalIcon} className="tabIcon" alt="tab-icon" />
          <p>Project Details</p>
        </button>
      </Col>
      <Col xs={12} className="my-3 py-3">
        {tab == "address" && (
          <PCAddressTab
            setTab={setTab}
            setFormError={setFormError}
            projectForm={projectForm}
            setProjectForm={setProjectForm}
            setProjectAddressRequiredDetails={setProjectAddressRequiredDetails}
            projectAddressRequiredDetails={projectAddressRequiredDetails}
            setProjectAddressDetails={setProjectAddressDetails}
            projectAddressDetails={projectAddressDetails}
          />
        )}
        {tab == "project" && (
          <PCProjectDetailsTab
            disableSubmit={disableSubmit}
            ProjectCreationFormSubmitHandler={ProjectCreationFormSubmitHandler}
            setProjectDetails={setProjectDetails}
            projectDetails={projectDetails}
          />
        )}
      </Col>
      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
        />
      )}
    </Col>
  );
};

export default ProjectCreationTab;
