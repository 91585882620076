import React from "react";
import { Col } from "react-bootstrap";
import LeftLogin from "../component/Login/LeftLogin";
import RightLogin from "../component/Login/RightLogin";
import RightRegister from "../component/Login/RightRegister";

const Register = () => {
  return (
    <Col xs={12} className="d-flex bg-lightBlue">
      <LeftLogin />
      <RightRegister />
    </Col>
  );
};

export default Register;
