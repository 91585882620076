import React, { useState, useEffect } from "react";
import eye_icon from "../../assets/icons/common/Eye.svg";
import edit_icon from "../../assets/icons/common/Edit.svg";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";

function AssetsRequestListScreen() {
  const [materialList, setMaterialList] = useState([]);
  const [selMaterial, setSelMaterial] = useState("");

  const navigate = useNavigate();

  const getMaterialList = () => {
    let params = `?type=1`;
    api.getMaterialsLists(params).then((res) => {
      if (res?.status == "success") {
        setMaterialList(res?.materials);
      }
    });
  };

  const dropDownMaterialHandler = (sel_material) => {
    setSelMaterial(+sel_material);
  };

  useEffect(() => {
    getMaterialList();
  }, []);

  const materialDetailHandler = (sel_material, action) => {
    navigate("/procurement/assets-action", {
      state: { id: sel_material, action },
    });
  };

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white my-3 text-center">
        <header>
          {/* <Col xs={12} className="d-flex mb-3 py-2">
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Search...,"
              //   onChange={(e) => projectSearchHandler(e.target.value)}
            />
 
          </Col> */}
        </header>
        <h4>Assets List</h4>
        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Assets Name</th>
                <th>Assets Code</th>
                <th>Material Type</th>
                <th>Material Group</th>
                <th>UOM</th>
                <th></th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {materialList?.map((material, ind) => (
                <tr>
                  <td>{ind + 1}</td>
                  <td>{material?.name}</td>
                  <td>{material?.material_code}</td>
                  <td>{material?.material_type}</td>
                  <td>{material?.group}</td>
                  <td>{material?.uom}</td>
                  <td></td>
                  <td>
                    <div className="d-flex">
                      <img
                        onClick={() =>
                          materialDetailHandler(material?.id, "view")
                        }
                        style={{ width: "20px" }}
                        className="pointer me-1"
                        src={eye_icon}
                        alt="eye-icon"
                      />
                      <img
                        onClick={() =>
                          materialDetailHandler(material?.id, "edit")
                        }
                        style={{ width: "20px" }}
                        className="pointer ms-1"
                        src={edit_icon}
                        alt="edit-icon"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col>
    </Col>
  );
}

export default AssetsRequestListScreen;
