// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
import { tickIcon } from "../../assets/img";
import {
  hrManagement,
  procurement,
  stockInv,
} from "../../constants/StaticData";

const ResponsibilityTab = ({
  setRoleModules,
  setTab,
  modules,
  setModules,
  setAllowAccess,
  allowAccess,
  password,
  passAlaErr,
}) => {
  const [roleModuleList, setRoleModuleList] = useState([]);

  useEffect(() => {
    api.modules_list().then((res) => {
      setRoleModuleList(res?.modules);
    });
  }, []);

  const checkBoxHandler = (id) => {
    let checks = [...modules];
    checks.includes(id)
      ? checks.splice(checks.indexOf(id), 1)
      : checks.push(+id);
    setModules(checks);
  };

  const roleModuleSubmitHandler = () => {
    let data = { module_ids: modules };
    api.employee_modules_permissions(data).then((res) => {
      if (res?.status === "success") {
        setRoleModules(res?.modules);
        setAllowAccess([allowAccess, "responsibility"]);
        setTab("report");
      }
    });
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4>Roles</h4>
      <div className="d-flex flex-wrap justify-content-center">
        <div className="roles-box text-start me-md-4">
          {roleModuleList?.map((item, ind) => {
            return (
              <button
                onClick={() => checkBoxHandler(item?.id)}
                key={item?.id}
                value={item?.id}
                className="border-0 cust-btn d-flex fs-14 align-items-center mb-2"
              >
                <div className="checkboxs me-2">
                  {modules?.includes(item?.id) && (
                    <img src={tickIcon} alt="tick" />
                  )}
                </div>
                {item?.code}
              </button>
            );
          })}
        </div>
      </div>
      {/* <button
        className={
          "bg-primar float-left border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
        }
        onClick={() =>
          password?.length > 5
            ? roleModuleSubmitHandler()
            : passAlaErr == false && password?.length == 0
            ? alert("Please Enter Password")
            : ""
        }
      >
        Next
      </button> */}
      <button
        className={
          "bg-primar float-left border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
        }
        onClick={() => roleModuleSubmitHandler()}
      >
        Next
      </button>
    </Col>
  );
};

export default ResponsibilityTab;
