import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
function PoCreationtTableSection({
  tableHeadings,
  tableFields,
  currentTable,
  tableSelHandler,
  boqAttributeModalHandler,
  commonFields,
  boqFields,
  basicPriceHandler,
  tableError,
  miscModalHandler,
  miscEnable,
  setCommonFields,
  newSpecifs,
  selectedMaterial,
  setSelectedMaterial,
}) {
  const [allowRow, setAllowRow] = useState(1);
  const [rows, setRows] = useState([1]);
  // console.log("assets_type", currentTable);

  const [BCode, setBCode] = useState([]);
  const [mCode, setMCode] = useState([]);
  const [mName, setMName] = useState([]);
  const [matDetail, setMatDetail] = useState();
  const [showStatus, setShowStatus] = useState(false);
  const [boqDetail, setBoqDetail] = useState();
  const [dropDown, setDropDown] = useState({
    m_code: "",
  });
  const [filter, setFilter] = useState({
    m_code: "",
  });
  const [mrTableFields, setMrTableFields] = useState({
    qty: {},
    remarks: {},
  });
  // console.log("matDetail", matDetail);
  useEffect(() => {
    setMCode(tableFields);
  }, [tableFields]);

  const dropHandler = (id, sel_drop) => {
    // setSelectedMaterial([]);
    // console.log("id", id);
    // console.log("sel_drop", sel_drop);
    dropDown?.m_code == id
      ? setDropDown({ ...dropDown, m_code: "" })
      : setDropDown({ ...dropDown, m_code: id });
  };

  const material_code_view = (m_code, drop_id) => {
    // console.log("m_code", m_code);
    // console.log("drop_id", drop_id);
    // setSelectedMaterial([]);
    api.material_code_view(m_code?.material_code).then((res) => {
      if (res?.status == "success") {
        setMatDetail({ ...matDetail, [drop_id]: res?.material });
        setDropDown({ ...dropDown, m_code: "" });
        if (mCode) {
          const filtered_material_code = mCode?.filter(
            (boq) => boq != res?.material?.material_code
          );
          setMCode(filtered_material_code);
          setFilter({
            ...filter,
            m_code: "",
          });
        }
      }
    });
  };

  useEffect(() => {
    let temp = new Array(+allowRow);
    temp.fill(1);
    setRows(temp);
  }, [allowRow]);

  // console.log(">>>>>", boqFields?.attribute[matDetail[`${2}`]?.id]?.length);

  return (
    <>
      {currentTable == "assets_type" ? (
        <Col className="emply-master-form py-2  mt-3 text-center">
          <Col xs={12} className="d-flex">
            <button
              className="bg-primar border-0 rounded-1 my-1 text-white f-1 fs-14 px-4 py-2 ms-auto"
              onClick={() => setAllowRow(+allowRow + 1)}
            >
              Add
            </button>
          </Col>
          <Col
            xs={11}
            className="emply-master-form py-2 me-2  mt-3 text-center"
          >
            <div style={{ overflowX: "scroll" }} className="boq-table bg-white">
              <table className="table">
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>Select Assets</th>
                    <th style={{ minWidth: "200px" }} className="">
                      Assets Code
                    </th>
                    <th style={{ minWidth: "200px" }} className="">
                      Asset Description
                    </th>
                    <th>UOM</th>
                    <th>Attributes</th>
                    <th style={{ minWidth: "230px" }}>Quantity</th>
                    <th>Basic Price</th>
                    <th>Total Value</th>
                    <th>Misc</th>
                    <th>Net Value</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {rows?.map((item, index) => {
                    return (
                      index <= allowRow && (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="text-center">
                            <input
                              type="checkbox"
                              value={matDetail && matDetail[`${index}`]?.id}
                              className="pointer"
                              onChange={(e) => tableSelHandler(e.target.value)}
                              checked={selectedMaterial?.includes(
                                matDetail && matDetail[`${index}`]?.id
                              )}
                              disabled={matDetail ? false : true}
                            />
                          </td>
                          <td>
                            {matDetail && matDetail[`${index}`] ? (
                              <p>{matDetail[`${index}`]?.material_code}</p>
                            ) : (
                              <p
                                style={{ minWidth: "150px" }}
                                className="pointer"
                                onClick={() =>
                                  dropHandler(`${index}_m_code`, "m_code")
                                }
                              >
                                Select Material Code
                              </p>
                            )}
                            {dropDown?.m_code == `${index}_m_code` && (
                              <ul className="drop-cont">
                                <li>
                                  <input
                                    className="border-0 bg-lightBlue px-2 py-1 mt-1"
                                    value={filter?.m_code}
                                    onChange={(e) =>
                                      setFilter({
                                        ...filter,
                                        m_code: e.target.value,
                                      })
                                    }
                                  />
                                </li>
                                {mCode &&
                                  mCode?.map((material_code, ind) => (
                                    <li
                                      key={ind}
                                      onClick={() =>
                                        material_code_view(
                                          material_code,
                                          `${index}`
                                        )
                                      }
                                    >
                                      {material_code?.material_code}
                                    </li>
                                  ))}
                              </ul>
                            )}
                          </td>
                          <td>Asset Description</td>
                          <td>{matDetail && matDetail[`${index}`]?.uom}</td>
                          <td>
                            {matDetail && !matDetail[index]?.attribute ? (
                              <button
                                className="border-0 bg-primary1 rounded-1 text-white"
                                onClick={() => {
                                  boqAttributeModalHandler(
                                    matDetail[`${index}`]?.id,
                                    matDetail[`${index}`]?.attribute
                                  );
                                }}
                              >
                                Show Attributes
                              </button>
                            ) : (
                              <button
                                className="border-0 bg-primary1 rounded-1 text-white"
                                onClick={() => alert("No Attribute")}
                              >
                                Show Attributes
                              </button>
                            )}
                          </td>
                          <td className=" text-center">
                            {matDetail && matDetail[`${index}`]?.quantity}
                          </td>
                          <td className=" text-center">
                            {" "}
                            {matDetail && (
                              <>
                                {" "}
                                <input
                                  value={
                                    matDetail &&
                                    commonFields &&
                                    commonFields?.basic_price[
                                      matDetail[`${index}`]?.id
                                    ]
                                  }
                                  type={"number"}
                                  disabled={
                                    matDetail
                                      ? boqFields?.attribute[
                                          matDetail[`${index}`]?.id
                                        ]?.length == undefined &&
                                        newSpecifs?.[
                                          matDetail[`${index}`]?.id
                                        ]?.[0]?.length == undefined
                                      : false
                                  }
                                  onChange={(e) =>
                                    basicPriceHandler(
                                      matDetail && matDetail[`${index}`]?.id,
                                      e.target.value,
                                      matDetail &&
                                        matDetail[`${index}`]?.quantity,
                                      matDetail &&
                                        matDetail[`${index}`]?.total_value
                                    )
                                  }
                                />
                                {tableError?.basic_rate?.includes(
                                  matDetail && matDetail[`${index}`]?.id
                                ) && (
                                  <p className="err">Exceeding Max Price</p>
                                )}{" "}
                              </>
                            )}
                          </td>
                          <td className=" text-center">
                            {" "}
                            {matDetail &&
                              commonFields &&
                              commonFields?.total_price[
                                +matDetail[`${index}`]?.id
                              ]}
                          </td>
                          <td>
                            {matDetail &&
                            boqFields &&
                            boqFields?.misc[+matDetail[`${index}`]?.id] &&
                            !miscEnable ? (
                              <p className="text-center">
                                {matDetail &&
                                  boqFields?.misc[+matDetail[`${index}`]?.id]}
                              </p>
                            ) : matDetail ? (
                              <button
                                className="border-0 bg-primary1 rounded-1 text-white"
                                onClick={() =>
                                  miscModalHandler(+matDetail[`${index}`]?.id)
                                }
                              >
                                Misc
                              </button>
                            ) : (
                              <button
                                className="border-0 bg-primary1 rounded-1 text-white"
                                onClick={() => alert("No Misc")}
                              >
                                Misc
                              </button>
                            )}
                          </td>
                          <td className=" text-center">
                            {matDetail &&
                              boqFields?.misc[+matDetail[`${index}`]?.id] &&
                              matDetail &&
                              boqFields?.misc[+matDetail[`${index}`]?.id] +
                                commonFields?.total_price[
                                  +matDetail[`${index}`]?.id
                                ]}
                          </td>
                          <td>
                            <input
                              className="border-0 w-100 text-center"
                              value={
                                matDetail &&
                                commonFields?.remarks[
                                  +matDetail[`${index}`]?.id
                                ] &&
                                matDetail &&
                                commonFields?.remarks[
                                  +matDetail[`${index}`]?.id
                                ]
                              }
                              onChange={(e) =>
                                setCommonFields({
                                  ...commonFields,
                                  remarks: {
                                    [+matDetail[`${index}`]?.id]:
                                      e.target.value,
                                  },
                                })
                              }
                            />
                          </td>
                        </tr>
                      )
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Col>
        </Col>
      ) : (
        <Col
          style={{ overflowX: "scroll" }}
          className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center "
        >
          <Col
            xs={11}
            className="emply-master-form py-2 me-2  mt-3 text-center"
          >
            <div style={{ overflowX: "scroll" }} className="boq-table bg-white">
              <table className="table">
                <thead>
                  <tr>
                    {tableHeadings?.map((heading) => (
                      <th>{heading}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-center">{tableFields}</tbody>
              </table>
            </div>
          </Col>
        </Col>
      )}
    </>
  );
}

export default PoCreationtTableSection;
