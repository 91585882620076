import React, { useState, useEffect } from "react";
import eye_icon from "../../assets/icons/common/Eye.svg";
import { AiOutlineFilePdf } from "react-icons/ai";
import { Col, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";

function InvoiceScrutinyList() {
  const [selectedProject, setSelectedProject] = useState("");
  const [ISList, setISList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const navigate = useNavigate();

  const getProjects = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };
  useEffect(() => {
    getProjects();
  }, []);

  const dummy_date = [
    1,
    // "Sample Project",
    "Ks awademy",
    "INV345678",
    "23-06-2023",
    "INVS345678",
    "20-06-2023",
    "1000",
    "ninos",
    "Active",
  ];

  useEffect(() => {}, [selectedProject]);

  const materialTransferDetailHandler = (sel_mt, mt_colde, mt_date) => {
    navigate("/procurement/po-output", {
      state: { id: sel_mt },
    });
  };

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white my-3 text-center">
        <header>
          <Col xs={12} className="d-flex my-2">
            <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 ms-auto pointer"
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}>
              <option>Select Project</option>
              {projectList?.map((project) => (
                <option key={project?.id} value={project?.id}>
                  {project?.name}
                </option>
              ))}
            </select>
          </Col>
        </header>
        <h4 className="my-1 py-1">Invoice Scrutiny List</h4>
        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                {/* <th>Project Name</th> */}
                <th>Vendor Name</th>
                <th>Invoice No</th>
                <th>Invoice Date </th>
                <th>Invoice Scrutiny No</th>
                <th>Invoice Scrutiny Date </th>
                <th>Net Value</th>
                <th>Created By</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {dummy_date?.map((material) => (
                  <td>{material}</td>
                ))}
                <td>
                  <div className="d-flex">
                    {/* <AiOutlineFilePdf
                      className="pointer fs-5"
                      onClick={() => setShowModal(!showModal)}
                    /> */}
                    <img
                      // onClick={() =>
                      //   materialTransferDetailHandler(
                      //     material?.id,
                      //     material?.po_no,
                      //     material?.po_date
                      //   )
                      // }
                      style={{ width: "20px" }}
                      className="pointer ms-2"
                      src={eye_icon}
                      alt="eye-icon"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Col>
      <Modal
        className="pdf-modal-cont"
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        centered>
        <Modal.Header closeButton className="border-0 d-flex">
          <Modal.Title className="text-center mx-auto">
            Invoice Scrutiny
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src="https://arxiv.org/pdf/quant-ph/0410100.pdf"
            title="Sample Pdf"
            className="w-90"></iframe>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={() => setShowModal(!showModal)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}

export default InvoiceScrutinyList;
