import httpClient from "../../HttpClient/httpClient";
import httpClientForm from "../../HttpClient/httpClientForm";
import PrepareResponse from "./prepareResponse";
import HandleException from "./handleException";
import { TOKEN } from "../constants";

//  POST METHOD

export const post = async (url, data) => {
  try {
    let res = await httpClient.post(url, data);
    return PrepareResponse(res);
  } catch (error) {
    return HandleException(error);
  }
};

// GET METHOD

export const get = async (url, data) => {
  try {
    let res = await httpClient.get(url);
    return PrepareResponse(res);
  } catch (error) {
    return HandleException(error);
  }
};

// DELETE METHOD

export const deletes = async (url, data) => {
  try {
    let res = await httpClient.delete(url);
    return PrepareResponse(res);
  } catch (error) {
    return HandleException(error);
  }
};

// FILE IMAGE POST METHOD

export const file_post = async (url, data) => {
  try {
    let res = await httpClientForm.post(url, data);
    return PrepareResponse(res);
  } catch (error) {
    return HandleException(error);
  }
};
