import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import Header from "../../component/Header/Header";
import InvoiceScrutiny from "../../component/ism/IsmInvoiceScrutiny/IsmInvoiceScrutinyForm";
import InvoiceScrutinyTable from "../../component/ism/IsmInvoiceScrutiny/IsmInvoiceScrutinyTable";
import InvoiceScrutinyLastForm from "../../component/ism/IsmInvoiceScrutiny/IsnInvoiceScrunityLastForm";
import { api } from "../../constants/Services";
import { useNavigate } from "react-router";
import useUser from "../../constants/Storage/userData";

const IsmInvoiceScrutinyScreen = () => {
  const [projectList, setProjectList] = useState([]);
  const [tds, setTds] = useState({});
  const { user } = useUser();

  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const poPrintHandler = () => {
    setModal(!modal);

    navigate("/ism/print", {
      state: {
        id: 1,
        title: "Invoice Scrutiny",
        from: true,
        to: false,
        left_fields: [
          "Vendor Name",
          "Address",
          "State",
          "Mobile No",
          "Email Id",
          "GST No",
        ],

        right_fields: [
          "Project Name",
          "Project Address",
          "Invoice No",
          "Invoice Date",
          "Invoice Scrutiny No",
          "Invoice Scrutiny Date",
        ],
        table_heading: [
          "Sl.No",
          "Material Code",
          " Material Description",
          " UOM",
          "Quantity",
          "Basic Rate (INR)",
          "TDS(%)",
          "Net Value",
        ],
        sample_data: [
          "1",
          "M0123445",
          "Material Description",
          "Box",
          "10",
          "100",
          "0",
          "1000",
        ],
      },
    });
  };

  const getProject = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  useEffect(() => {
    getProject();
  }, []);

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <InvoiceScrutiny projectList={projectList} />
      <InvoiceScrutinyTable setTds={setTds} tds={tds} />
      <InvoiceScrutinyLastForm user={user?.name} setModal={setModal} />
      <Modal show={modal} centered onHide={() => setModal(!modal)}>
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1">Invoice Scrutiny : </label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <p className="text-center fs-6">Do you want to Print the Output?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            onClick={poPrintHandler}
            variant="primary">
            Print
          </Button>
          <Button variant="secondary" onClick={() => setModal(!modal)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default IsmInvoiceScrutinyScreen;
