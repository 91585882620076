import { useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { api } from "../../constants/Services";

function WorkOrderTable({
  orderType,
  poppupHandle,
  tabelBoq,
  tabelData,
  setTableData,
  setBoq,
  boq,
  row,
  setRow,
  setViewTable,
  companyData,
  vendorData,
}) {
  const Table_heading = [
    "S.No",
    "BOQ Code",
    // "Material Description",
    // "Boq Description",
    "Work Description",
    "UOM",
    // orderType,
    "Quantity",
    "Basic Rate",
    "Total Value",
    "Misc",
    "Net Value",
    "Remarks",
  ];

  const [micsPoppup, setMicePoppup] = useState(false);
  const [matCode, setMatCode] = useState([]);

  useEffect(() => {
    setMatCode(tabelBoq);
  }, [tabelBoq]);

  // useEffect(() => {
  //   setBoq({});
  // }, [tabelBoq]);

  const addRow = () => {
    let temp = [...row];
    temp.push(1);
    // setRow(temp);
    setViewTable(true);
  };

  // FOR SELECT DATA
  const [dropDown, setDropDown] = useState({
    boq: "",
    m_code: "",
    m_name: "",
  });

  const [filter, setFilter] = useState({
    material_code: "",
    material_name: "",
  });

  const [selId, setSelId] = useState(null);
  const [selInd, setSelInd] = useState(null);
  const miscModalHandler = (id, price, ind) => {
    console.log(price, id, ind);
    setCommonFields({
      ...commonFields,
      total_price: { ...commonFields?.total_price, [+id]: +price },
    });
    setMicePoppup(true);
    setSelId(id);
    setSelInd(ind);
  };

  const [hsnDetail, setHsnDetail] = useState({
    hsn_code: "",
    hsn_des: "",
    igst: "",
    sgst: "",
    cgst: "",
    igst_value: "",
    sgst_value: "",
    cgst_value: "",
  });

  const [miscDetails, setMiscDetails] = useState({
    discount_value: "",
    dis_percent: "",
    total_val_af_dis: "",
    net_value: "",
  });

  const [commonFields, setCommonFields] = useState({
    basic_price: {},
    total_price: {},
    remarks: {},
  });

  const calcu_percentage_value = (percent, total_value) => {
    const percentate_value = (percent / 100) * total_value;
    return percentate_value;
  };

  const roundOffActionHandler = (action) => {
    let temp;
    if (action == "-") temp = Math.floor(miscDetails?.net_value);
    else temp = Math.ceil(miscDetails?.net_value);
    setMiscDetails({ ...miscDetails, net_value: temp });
  };

  const revisedTotalCalc = (tot_val, dis_val) => tot_val - dis_val;

  const miscDiscountHandler = async (percent, total_price) => {
    const disVal = calcu_percentage_value(+percent, +total_price);
    const revised_total = revisedTotalCalc(+total_price, +disVal);
    let net_value;

    if (disVal && revised_total) {
      const cgst = calcu_percentage_value(hsnDetail.cgst[selId], revised_total);
      const sgst = calcu_percentage_value(hsnDetail.sgst[selId], revised_total);
      setHsnDetail({
        ...hsnDetail,
        sgst_value: { [selId]: cgst },
        cgst_value: { [selId]: sgst },
      });
      net_value = cgst + sgst;
    }
    setMiscDetails({
      discount_value: disVal,
      dis_percent: percent,
      total_val_af_dis: revised_total,
      net_value: net_value,
    });
  };

  const searchHsn = (hsn_code, total_val) => {
    let data = {
      code: hsn_code,
    };
    api.searchHSN(data).then((res) => {
      if (res?.status === "success") {
        if (companyData?.state !== vendorData?.state) {
          console.log("state!");
          setHsnDetail({
            hsn_code: {
              ...hsnDetail?.hsn_code,
              // [selId]: res?.hsn_code?.code_name,
              [selId]: res?.hsn_code?.id,
            },
            hsn_des: {
              ...hsnDetail?.hsn_des,
              [selId]: res?.hsn_code?.description,
            },
            igst: { ...hsnDetail?.igst, [selId]: res?.hsn_code?.igst },
            igst_value: {
              ...hsnDetail?.igst_value,
              [selId]: calcu_percentage_value(res?.hsn_code?.igst, total_val),
            },
            sgst: { ...hsnDetail?.sgst, [selId]: "-" },
            cgst: { ...hsnDetail?.cgst, [selId]: "-" },
            sgst_value: { ...hsnDetail?.sgst_value, [selId]: "-" },
            cgst_value: { ...hsnDetail?.cgst_value, [selId]: "-" },
          });
          setMiscDetails({
            ...miscDetails,
            net_value: calcu_percentage_value(res?.hsn_code?.igst, total_val),
          });
        } else {
          setHsnDetail({
            ...hsnDetail,
            hsn_code: {
              ...hsnDetail?.hsn_code,
              [selId]: res?.hsn_code?.code_name,
            },
            hsn_des: {
              ...hsnDetail?.hsn_des,
              [selId]: res?.hsn_code?.description,
            },
            igst: { ...hsnDetail?.igst, [selId]: "-" },
            igst_value: { ...hsnDetail?.igst_value, [selId]: "-" },
            sgst: { ...hsnDetail?.sgst, [selId]: res?.hsn_code?.sgst },
            sgst_value: {
              ...hsnDetail?.sgst_value,
              [selId]: calcu_percentage_value(res?.hsn_code?.sgst, total_val),
            },
            cgst: { ...hsnDetail?.cgst, [selId]: res?.hsn_code?.cgst },
            cgst_value: {
              ...hsnDetail?.cgst_value,
              [selId]: calcu_percentage_value(res?.hsn_code?.cgst, total_val),
            },
          });

          setMiscDetails({
            ...miscDetails,
            net_value:
              calcu_percentage_value(res?.hsn_code?.sgst, total_val) +
              calcu_percentage_value(res?.hsn_code?.cgst, total_val),
          });
        }
      }
    });
  };

  const miscSubmitHandler = () => {
    let temp = { ...boq };

    let obj = { ...temp[selInd] };

    let final = {
      ...obj,
      misc_value: Number(miscDetails?.net_value).toFixed(2),
      net_value: +miscDetails?.net_value + +obj?.total_value,
    };

    setBoq((val) => ({ ...val, [selInd]: final }));
    setMicePoppup(false);
  };

  const getMaterial = (item) => {
    let split = item?.split(",");
    console.log(split);
    let finder = tabelBoq?.find((i) => i?.id == +split[0]);
    // console.log("finder", finder);
    setBoq((val) => ({ ...val, [+split[1]]: finder }));
    let temp = [...tabelData];
    temp.push(finder?.id);
    let dublicateRemove = Array.from(new Set(temp));
    setTableData(dublicateRemove);

    setDropDown({ ...dropDown, boq: "", m_code: "" });
  };

  const handleChange = (event, ind, type, id) => {
    let obj = { ...boq[ind] };

    // console.log("objobj", boq[ind]);

    let viswa = +boq[ind]?.accessories_1 + +boq[ind]?.installation_rate;

    // console.log("viswa", viswa);

    if (type == "qty" || type == "basic_rate") {
      let final = {
        ...obj,
        [type]: event,
        ["total_value"]:
          type == "qty"
            ? +obj?.basic_rate * +event
            : type == "basic_rate"
            ? +obj?.qty * +event
            : 0,
        ["misc_value"]: "",
        ["net_value"]: "",
        ["error"]:
          type == "qty"
            ? +obj?.basic_rate * +event > +viswa
              ? 1
              : 0
            : type == "basic_rate"
            ? +obj?.qty * +event > +viswa
              ? 1
              : 0
            : 0,
      };

      setBoq((val) => ({ ...val, [ind]: final }));
    } else {
      let final = {
        ...obj,
        [type]: event,
      };

      setBoq((val) => ({ ...val, [ind]: final }));
    }
  };

  // console.log("boq", boq);

  const checkBox = (item) => {
    let temp = [...tabelData];

    let inx = temp.indexOf(item);

    if (temp.includes(item)) {
      temp.splice(inx, 1);
    } else {
      temp.push(item);
    }

    setTableData(temp);
  };

  const dropHandler = (id, sel_drop) => {
    // console.log("id", id);
    // console.log("sel_drop", sel_drop);
    if (sel_drop == "boq") {
      dropDown?.boq == id
        ? setDropDown({ ...dropDown, boq: "" })
        : setDropDown({ ...dropDown, boq: id });
    } else if (sel_drop == "m_code") {
      dropDown?.m_code == id
        ? setDropDown({ ...dropDown, m_code: "" })
        : setDropDown({ ...dropDown, m_code: id });
    } else {
      dropDown?.m_name == id
        ? setDropDown({ ...dropDown, m_name: "" })
        : setDropDown({ ...dropDown, m_name: id });
    }
  };

  // console.log("tabelData", tabelData);

  const selectFilter = (event, type) => {
    setFilter({
      ...filter,
      material_code: event,
    });
    if (event?.length > 0) {
      let finder = tabelBoq?.filter((i) =>
        i?.boq_code?.toLocaleLowerCase()?.includes(event.toLocaleLowerCase())
      );
      setMatCode(finder);
    } else {
      setMatCode(tabelBoq);
    }
  };

  // console.log("tabelBoq", tabelBoq);

  return (
    <Col
      style={{ width: "95%" }}
      className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center me-auto "
    >
      <Col xs={12} className="d-flex mb-2">
        <button
          className="bg-primar border-0 rounded-1 my-1 text-white f-1 fs-14 px-4 py-2 ms-auto"
          onClick={() => addRow()}
        >
          Add
        </button>
      </Col>
      <Col className="emply-master-form py-2  mt-3 text-center">
        <div style={{ overflowX: "scroll" }} className="boq-table bg-white ">
          <table className="table">
            <thead>
              <tr>
                {Table_heading?.map((heading) => (
                  <th>{heading}</th>
                ))}
              </tr>
            </thead>
            <tbody className="text-center">
              {row?.map((item, ind) => {
                return (
                  <tr>
                    <td>
                      {" "}
                      <div className="d-flex gap-1 align-items-center">
                        <div>{ind + 1}</div>{" "}
                        <div>
                          {boq && boq[ind]?.id ? (
                            // && boq[ind]?.net_value
                            <input
                              type={"checkbox"}
                              className="mt-1"
                              onClick={() => checkBox(boq[ind]?.id)}
                              checked={tabelData?.includes(boq[ind]?.id)}
                            />
                          ) : (
                            <input
                              type={"checkbox"}
                              className="mt-1"
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td>
                      {" "}
                      {/* {boq && boq[ind]?.boq_code ? (
                        boq[ind]?.boq_code
                      ) : (
                        <div>
                          <select
                            className="border-0 bg-lightBlue px-2 py-1 mt-1 pointer"
                            onChange={(e) => getMaterial(e.target.value)}
                          >
                            <option hidden>Select BOQ Code</option>
                            {tabelBoq?.map((item, index) => {
                              return (
                                <option key={index} value={[item?.id, ind]}>
                                  {item?.boq_code}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )} */}
                      <p
                        style={{ minWidth: "110px" }}
                        className="pointer"
                        onClick={() => dropHandler(`${ind}_boq`, "boq")}
                      >
                        {boq && boq[ind]?.boq_code
                          ? boq[ind]?.boq_code
                          : "Select Material Code"}
                      </p>
                      {dropDown?.boq == `${ind}_boq` && (
                        <ul className="drop-cont">
                          <li>
                            <input
                              className="border-0 bg-lightBlue px-2 py-1 mt-1"
                              value={filter?.material_code}
                              onChange={(e) =>
                                selectFilter(e.target.value, "code")
                              }
                            />
                          </li>
                          {matCode?.map((item, index) => (
                            <li
                              key={index}
                              value={[item?.id, ind]}
                              onClick={(e) =>
                                getMaterial([item?.id, ind].toString())
                              }
                            >
                              {item?.boq_code}
                            </li>
                          ))}
                        </ul>
                      )}
                    </td>
                    {/* <td>
                      {boq && boq[ind]?.id && boq[ind]?.material_description}
                    </td> */}
                    <td>
                      <input
                        onChange={(e) =>
                          handleChange(e.target.value, ind, "description")
                        }
                        value={boq && boq[ind]?.id && boq[ind]?.description}
                        className="border-0"
                      />
                    </td>
                    <td> {boq && boq[ind]?.id && boq[ind]?.uom}</td>
                    <td>
                      {" "}
                      <input
                        style={{ width: "100px" }}
                        type={"number"}
                        value={boq && boq[ind]?.id && boq[ind]?.qty}
                        onChange={(e) =>
                          handleChange(e.target.value, ind, "qty")
                        }
                        className="border-0"
                      />
                      {boq && +boq[ind]?.error == 1 && (
                        <p className="err" style={{ fontSize: "10px" }}>
                          {" "}
                          Exceeded The Quantity
                        </p>
                      )}
                    </td>
                    <td>
                      <input
                        style={{ width: "100px" }}
                        type={"number"}
                        onChange={(e) =>
                          handleChange(e.target.value, ind, "basic_rate")
                        }
                        value={boq && boq[ind]?.id && boq[ind]?.basic_rate}
                        className="border-0"
                      />
                      {boq && boq[ind]?.id && boq[ind]?.error == 1 && (
                        <p className="err">Exceeding Max Price</p>
                      )}
                    </td>
                    <td>{boq && boq[ind]?.id && boq[ind]?.total_value}</td>
                    <td className="">
                      {boq && boq[ind]?.id && boq[ind]?.misc_value ? (
                        boq[ind]?.misc_value
                      ) : (
                        <button
                          className="border-0 bg-primary1 rounded-1 text-white"
                          onClick={() =>
                            miscModalHandler(
                              boq && boq[ind]?.id,
                              boq && boq[ind]?.id && boq[ind]?.total_value,
                              ind
                            )
                          }
                          disabled={
                            boq &&
                            boq[ind]?.id &&
                            boq[ind]?.total_value &&
                            companyData?.state &&
                            vendorData?.state
                              ? false
                              : true
                          }
                        >
                          Misc
                        </button>
                      )}
                    </td>
                    <td>
                      {" "}
                      {boq &&
                        boq[ind]?.id &&
                        boq[ind]?.net_value &&
                        boq[ind]?.net_value}
                    </td>
                    <td className="text-center">
                      {" "}
                      <input
                        onChange={(e) =>
                          handleChange(e.target.value, ind, "remarks")
                        }
                        value={boq && boq[ind]?.id && boq[ind]?.remarks}
                        className="border-0"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Col>

      {/* Misc Poppup start */}
      {micsPoppup && (
        <Modal
          show={micsPoppup}
          centered
          onHide={() => {
            setMicePoppup(!micsPoppup);
          }}
        >
          <>
            <Modal.Header closeButton className="border-0">
              <h5 className="mx-auto">Miscellaneous Screen</h5>
            </Modal.Header>
            <Modal.Body>
              <div className="w-100 my-1 py-1">
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  HSN Code
                </label>
                <input
                  onChange={(e) =>
                    searchHsn(e.target.value, commonFields?.total_price[selId])
                  }
                />
              </div>
              <div className="w-100 my-1 py-1">
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  HSN Description
                </label>
                <input value={hsnDetail?.hsn_des[selId] ?? " "} />
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Total Value
                  </label>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={commonFields?.total_price[selId]}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Total Value After Discount
                  </label>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={miscDetails?.total_val_af_dis}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Discount
                  </label>
                  <input
                    className="w-25"
                    onChange={(e) =>
                      miscDiscountHandler(
                        e.target.value,
                        commonFields?.total_price[selId]
                      )
                    }
                  />
                  <span className="ms-1">%</span>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={miscDetails?.discount_value}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    IGST
                  </label>
                  <input
                    className="w-25"
                    readOnly
                    value={hsnDetail?.igst[selId]}
                  />
                  <span className="ms-1">%</span>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={hsnDetail?.igst_value[selId] ?? ""}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    SGST
                  </label>
                  <input
                    className="w-25"
                    readOnly
                    value={hsnDetail?.sgst[selId]}
                  />
                  <span className="ms-1">%</span>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={hsnDetail?.sgst_value[selId]}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    CGST
                  </label>
                  <input
                    className="w-25"
                    readOnly
                    value={hsnDetail?.cgst[selId]}
                  />
                  <span className="ms-1">%</span>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={hsnDetail?.cgst_value[selId]}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Round Off
                  </label>
                  <button
                    className="border-0 me-1 bg-primary1"
                    onClick={() => roundOffActionHandler("+")}
                  >
                    +
                  </button>
                  <button
                    className="border-0 ms-1 bg-primary2"
                    onClick={() => roundOffActionHandler("-")}
                  >
                    -
                  </button>
                </Col>
              </div>

              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Net Value
                  </label>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    type="text"
                    value={
                      miscDetails?.net_value
                        ? Number(miscDetails?.net_value).toFixed(2)
                        : ""
                    }
                  />
                </Col>
              </div>
            </Modal.Body>
            <Modal.Footer className="border-0">
              <button
                onClick={() => setMicePoppup(false)}
                className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
              >
                Cancel
              </button>
              <button
                className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
                onClick={miscSubmitHandler}
              >
                Save
              </button>
            </Modal.Footer>
          </>
        </Modal>
      )}
      {/* Misc Poppup end */}
    </Col>
  );
}

export default WorkOrderTable;
