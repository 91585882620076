import { Button, Col, Modal, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Header from "../../component/Header/Header";
import edit_icon from "../../assets/icons/common/Edit.svg";
import delete_icon from "../../assets/icons/common/Delete.svg";
function PoTermsAndCondition({ submitHandler }) {
  const [show, setShow] = useState(false);
  const [terms, setTerms] = useState("");

  const handleTermsChange = (terms) => {
    setTerms(terms);
  };
  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form  px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <h4 className="my-2 py-1">Terms And Condtions</h4>
        <header className="d-flex">
          <button
            type="button"
            onClick={() => setShow(!show)}
            className="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-4 py-2 ms-auto">
            Add Terms
          </button>
        </header>
        <div className="user-mange-table">
          <table className="table">
            <thead>
              <tr>
                <th className="ps-2">S.No</th>
                <th className="ps-2">Description</th>
                <th className="ps-2">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fs-14px ps-3">{1}</td>
                <td className="fs-14px">Description</td>
                <td>
                  <div className="d-flex gap-3">
                    <img
                      style={{ width: "15px" }}
                      className="pointer"
                      src={edit_icon}
                      alt="eye-icon"
                    />
                    <img
                      style={{ width: "15px" }}
                      className="pointer"
                      src={delete_icon}
                      alt="eye-icon"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Col>
      {/* <div className="mx-3 px-2">
        <h6 className="text-start my-2 f3 primary  my-2 py-2 w-100">
          Terms and Condtions
        </h6>
        <ul>
          <li className="fs-12 py-1 my-1 f1">01. Delivery Period</li>
          <li className="fs-12 py-1 my-1 f1">02. Payment terms</li>
          <li className="fs-12 py-1 my-1 f1">03. GST: Extra at actual</li>
          <li className="fs-12 pt-1 mt-1 f1">04. Instruction:</li>
        </ul>
        <div className="mx-2 px-1">
          <p className="fs-12 gray mb-1 pb-1 f1">
            1. Copy of Tax invoice with Delivery Proof shall be sent to Email
            Id: "billing@cresonproject.com" with in 1 to 2 days after delivery/
            dispatch for billing and payment processing.
          </p>
          <p className="fs-12 gray py-1 my-1 f1">
            2. Original invoice should courier to our office addtress along with
            PO & DC Copy.
          </p>
        </div>
        <Col sm={12} className="inputinner-box text-start">
          <button className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
            Submit
          </button>
          <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3">
            cancel
          </button>
        </Col>
      </div> */}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton className="border-0">
          <Modal.Title>
            <h5>Add Terms & Condition</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} className="my-1">
              <label className="w-100 my-1 py-1">Description</label>
              <textarea
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="description"
                name="description"
                maxLength={250}
                onChange={handleTermsChange}
                value={terms}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
            onClick={() => setShow(false)}>
            Close
          </button>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={submitHandler}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}

export default PoTermsAndCondition;
