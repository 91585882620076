// @ts-nocheck
import React from "react";
import { Col } from "react-bootstrap";
import { logo } from "../../assets/img";

const LeftLogin = () => {
  return (
    <Col xs={12} md={5} className="left-login d-md-flex ac-jc h-100">
      <div className="text-center">
        <img src={logo} alt="" />
        <h2>THE EPC COMPANY</h2>
      </div>
    </Col>
  );
};

export default LeftLogin;
