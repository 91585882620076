import { Col } from "react-bootstrap";

function WorkOrderLastSection({
  name1,
  setName1,
  name2,
  setName2,
  phone1,
  setPhone1,
  phone2,
  setPhone2,
  remarks,
  setRemarks,
  name1Err,
  name2Err,
  phone1Err,
  phone2Err,
}) {
  return (
    <Col className="d-flex flex-wrap mx-lg-3">
      <Col
        xs={12}
        sm={6}
        lg={6}
        xl={6}
        className="inputinner-box text-start mb-0 "
      >
        <label className="w-100">Name</label>
        <input
          className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
          onChange={(e) => setName1(e.target.value)}
          value={name1}
        />
        {name1?.length == 0 && name1Err && <p className="err">Enter Name</p>}
      </Col>
      <Col
        xs={12}
        sm={6}
        lg={6}
        xl={6}
        className="inputinner-box text-start mb-0 "
      >
        <label className="w-100">Name</label>
        <input
          className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
          onChange={(e) => setName2(e.target.value)}
          value={name2}
        />
        {name2?.length == 0 && name2Err && <p className="err">Enter Name</p>}
      </Col>

      <Col
        xs={12}
        sm={6}
        lg={6}
        xl={6}
        className="inputinner-box text-start mb-0 "
      >
        <label className="w-100">Phone No.</label>
        <input
          className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
          onChange={(e) => setPhone1(e.target.value)}
          value={phone1}
          type={"number"}
        />
        {phone1?.length == 0 && phone1Err && (
          <p className="err">Enter Phone No.</p>
        )}
      </Col>

      <Col
        xs={12}
        sm={6}
        lg={6}
        xl={6}
        className="inputinner-box text-start mb-0 "
      >
        <label className="w-100">Phone No</label>
        <input
          className="border-0 bg-lightBlue px-2 py-1 mt-1 w-90 ms-0"
          onChange={(e) => setPhone2(e.target.value)}
          value={phone2}
          type={"number"}
        />
        {phone2?.length == 0 && phone2Err && (
          <p className="err">Enter Phone No.</p>
        )}
      </Col>
      <Col
        xs={12}
        sm={6}
        lg={6}
        xl={6}
        className="inputinner-box text-start d-flex flex-column justify-content-center"
      >
        <label className="mb-1">Remarks</label>
        <textarea
          style={{ width: "90%" }}
          className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
          onChange={(e) => setRemarks(e.target.value)}
          value={remarks}
        ></textarea>
      </Col>
      {/* <Col xs={12} className="mt-0">
        <Col xs={12} sm={6} className="inputinner-box text-start mt-0 me-auto">
          <label className="w-100 mt-0">Approved By</label>
          <input className="border-0 bg-lightBlue px-2 py-1  w-90 ms-0" />
        </Col>
      </Col> */}
    </Col>
  );
}

export default WorkOrderLastSection;
