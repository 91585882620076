import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import UserManagementTable from "./UserManagementTable";
import { api } from "../../constants/Services";
import edit_icon from "../../assets/icons/common/Edit.svg";
import { useNavigate } from "react-router-dom";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

const CompanyManagement = () => {
  const navigate = useNavigate();
  // unlock_employee
  const [list, setList] = useState([]);

  const getList = () => {
    api
      .getCompanies()
      .then((res) => {
        // console.log("locked_employess", res);
        if (res?.status === "success") {
          setList(res?.companies);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const statusHandler = (id) => {
    api
      .getCompaniesStatus(id)
      .then((res) => {
        if (res?.status == "success") {
          alert(res?.message);
          getList();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4>Company Master</h4>
      <div className="d-flex justify-content-end">
        <button
          className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
          onClick={() => navigate("/home/company-master")}
        >
          Add Company
        </button>
      </div>
      <div className="user-mange-table">
        <table>
          <thead>
            <th>S.No</th>
            <th>Name</th>
            <th>State</th>
            <th>Status</th>
            <th>Action</th>
          </thead>
          <tbody>
            {list?.map((item, ind) => {
              return (
                <tr key={ind}>
                  <td>{ind + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.state}</td>
                  <td
                    className="emp-manage-toggle-switch-box"
                    role={"button"}
                    onClick={() => statusHandler(item?.id)}
                  >
                    {item?.status !== 1 ? (
                      <BootstrapSwitchButton
                        onlabel="Active"
                        offlabel="InActive"
                        onstyle="success"
                        offstyle="danger"
                        disabled
                        width={100}
                      />
                    ) : (
                      <BootstrapSwitchButton
                        key={item?.id}
                        name={item?.id}
                        onlabel="Active"
                        onstyle="success"
                        checked={item?.status == 1}
                        offstyle="danger"
                        width={100}
                      />
                    )}
                  </td>
                  <td>
                    <div className="checkbox pointer">
                      <img
                        class="pointer ms-1"
                        src={edit_icon}
                        alt="eye-icon"
                        style={{ width: "20px" }}
                        onClick={() =>
                          navigate("/home/company-master", {
                            state: { id: item?.id, type: "edit" },
                          })
                        }
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Col>
  );
};

export default CompanyManagement;
