import React, { useState } from "react";
import { EMAIL, NUMBERS_ONLY } from "../../constants/Services/constants";
import { Col } from "react-bootstrap";

const AddressTab = ({
  setTab,
  setTempFormData,
  tempFormData,
  setAllowAccess,
  allowAccess,
}) => {
  const [error, setError] = useState([]);

  // console.log("temp-form-data-phone", tempFormData);

  const [addressRequiredFields, setAddressRequiredFields] = useState({
    address1: tempFormData?.address1 || "",
    address2: tempFormData?.address2 || "",
    taluk: tempFormData?.taluk || "",
    district: tempFormData?.district || "",
    state: tempFormData?.state || "",
    pincode: tempFormData?.pincode || "",
    mobile: tempFormData?.mobile || "",
    email_id: tempFormData?.email_id || "",
  });

  const [addressData, setAddressData] = useState({
    phone: tempFormData?.telephone || "",
  });

  const resetData = () => {
    setAddressData({
      telephone: "",
    });

    setAddressRequiredFields({
      address1: "",
      address2: "",
      taluk: "",
      district: "",
      state: "",
      pincode: "",
      mobile: "",
      email_id: "",
    });
  };

  // console.log("addrs", addressRequiredFields);

  const NumberHandler = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value;
    switch (name) {
      case "pincode":
        if (NUMBERS_ONLY.test(value) || value === "") {
          setAddressRequiredFields({
            ...addressRequiredFields,
            pincode: value.replace(/[^0-9]/gi, ""),
          });
        }
        break;
      case "mobile":
        if (NUMBERS_ONLY.test(value) || value === "") {
          setAddressRequiredFields({
            ...addressRequiredFields,
            mobile: value.replace(/[^0-9]/gi, ""),
          });
        } else if (value === "") {
          setAddressRequiredFields({
            ...addressRequiredFields,
            mobile_no: value.replace(/[^0-9]/gi, ""),
          });
        }
        break;
      case "phone":
        if (NUMBERS_ONLY.test(value) || value === "") {
          setAddressData({
            ...addressData,
            phone: value,
          });
        }
        break;
      default:
        if (NUMBERS_ONLY.test(value) || value === "") {
          setAddressData({
            ...addressData,
            phone: value,
          });
        }
    }
  };
  const empMasterAddressSubmitHandler = () => {
    let temp = [];
    Object.entries(addressRequiredFields).forEach((item) => {
      if (item[1] === "") {
        temp.push(item[0]);
      }

      if (item[0] === "email_id") {
        if (!EMAIL.test(item[1])) {
          temp.push(item[0]);
        }
      }
    });
    setError(temp);

    if (temp.length === 0) {
      setTempFormData({
        ...tempFormData,
        ...addressData,
        ...addressRequiredFields,
      });
      setTab("person");
      setAllowAccess([...allowAccess, "address"]);
    }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-0 text-center">
      <h4 className="ms-xl-0 w-95">Employee Master</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Door No & Street<span className="err">*</span>
          </label>
          <input
            value={addressRequiredFields.address1}
            onChange={(e) =>
              setAddressRequiredFields({
                ...addressRequiredFields,
                address1: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Door No & Street"
          />
          {Object.values(error)?.includes("address1") && (
            <p className="err">Door No Field Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Address Line 2<span className="err">*</span>
          </label>
          <input
            value={addressRequiredFields.address2}
            onChange={(e) =>
              setAddressRequiredFields({
                ...addressRequiredFields,
                address2: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Address Line 2"
          />
          {Object.values(error)?.includes("address2") && (
            <p className="err">Address Field Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Taluk<span className="err">*</span>
          </label>
          <input
            value={addressRequiredFields.taluk}
            onChange={(e) =>
              setAddressRequiredFields({
                ...addressRequiredFields,
                taluk: e.target.value.replace(/[^\sa-z]/gi, ""),
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Taluk"
          />
          {Object.values(error)?.includes("taluk") && (
            <p className="err">Taluk Field Required</p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            District<span className="err">*</span>
          </label>
          <input
            value={addressRequiredFields.district}
            onChange={(e) =>
              setAddressRequiredFields({
                ...addressRequiredFields,
                district: e.target.value.replace(/[^\sa-z]/gi, ""),
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter District"
          />
          {Object.values(error)?.includes("district") && (
            <p className="err">District Field Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            State<span className="err">*</span>
          </label>
          <input
            value={addressRequiredFields.state}
            onChange={(e) =>
              setAddressRequiredFields({
                ...addressRequiredFields,
                state: e.target.value.replace(/[^\sa-z]/gi, ""),
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter State"
          />
          {Object.values(error)?.includes("state") && (
            <p className="err">State Field Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Pincode<span className="err">*</span>
          </label>
          <input
            value={addressRequiredFields.pincode}
            name="pincode"
            onChange={(e) => NumberHandler(e)}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Pincode"
          />
          {Object.values(error)?.includes("pincode") && (
            <p className="err">Pincode Field Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Telephone No</label>
          <input
            name="phone"
            value={addressData.telephone}
            onChange={(e) => NumberHandler(e)}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Telephone No"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Mobile No<span className="err">*</span>
          </label>
          <input
            value={addressRequiredFields.mobile}
            name="mobile"
            onChange={(e) => NumberHandler(e)}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Mobile No"
          />
          {Object.values(error)?.includes("mobile") && (
            <p className="err">Mobile Number Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            E-Mail<span className="err">*</span>
          </label>
          <input
            value={addressRequiredFields.email_id}
            onChange={(e) =>
              setAddressRequiredFields({
                ...addressRequiredFields,
                email_id: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
          />
          {Object.values(error)?.includes("email_id") && (
            <p className="err">Please Enter Valid Email Id</p>
          )}
        </Col>
        <Col sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={empMasterAddressSubmitHandler}>
            Next
          </button>
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
            onClick={resetData}>
            cancel
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default AddressTab;
