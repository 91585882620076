import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";

const AddressTab = ({ setAdd, add, setBtn, btn1, tabonClickHandler }) => {
  const [disable, setDisable] = useState(false);

  const [no, setNo] = useState("");
  const [district, setDistrict] = useState("");
  const [address, setAddress] = useState("");
  const [states, setStates] = useState("");
  const [taluk, setTaluk] = useState("");
  const [pincode, setPincode] = useState("");
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [mail, setMail] = useState("");

  const [noErr, setNoErr] = useState(false);
  const [districtErr, setDistrictErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [statesErr, setStatesErr] = useState(false);
  const [talukErr, setTalukErr] = useState(false);
  const [pincodeErr, setPincodeErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [mailErr, setMailErr] = useState(false);

  const submit = () => {
    if (
      no?.length == 0 ||
      district?.length == 0 ||
      states?.length == 0 ||
      pincode?.length == 0 ||
      mobile?.length == 0 ||
      mail?.length == 0
    ) {
      setNoErr(true);
      setDistrictErr(true);
      setNoErr(true);
      setStatesErr(true);
      setPincodeErr(true);
      setMobileErr(true);
      setMailErr(true);
    } else {
      let data = {};

      data.no = no;
      data.district = district;
      data.address = address;
      data.states = states;
      data.taluk = taluk;
      data.pincode = pincode;
      data.phone = pincode;
      data.mobile = mobile;
      data.mail = mail;
      tabonClickHandler("taxation");
      setAdd(data);
      setBtn(1);
    }
  };

  const view = () => {
    if (
      add?.address == undefined &&
      add?.district == undefined &&
      add?.mail == undefined &&
      add?.mobile == undefined &&
      add?.no == undefined &&
      add?.phone == undefined &&
      add?.pincode == undefined &&
      add?.states == undefined &&
      add?.taluk == undefined
    ) {
      setNo("");
      setDistrict("");
      setAddress("");
      setStates("");
      setTaluk("");
      setPincode("");
      setPhone("");
      setMobile("");
      setMail("");
    } else {
      setNo(add?.no);
      setDistrict(add?.district);
      setAddress(add?.address);
      setStates(add?.states);
      setTaluk(add?.taluk);
      setPincode(add?.pincode);
      setPhone(add?.phone);
      setMobile(add?.mobile);
      setMail(add?.mail);
    }
  };

  useEffect(() => {
    view();
  }, []);

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-0 text-center">
      <h4 className="ms-xl-0 w-95">Address</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Door No & Street<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Door No & Street"
            onChange={(e) => setNo(e.target.value)}
            value={no}
          />
          {no?.length == 0 && noErr && (
            <p className="err">Enter door no & street</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Address Line 2</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Address Line 2"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
          />
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Taluk</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Taluk"
            onChange={(e) => setTaluk(e.target.value.replace(/[^\sa-z]/gi, ""))}
            value={taluk}
          />
          {/* {taluk?.length == 0 && talukErr && (
            <p className="err">Enter door no & street</p>
          )} */}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            District<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter District"
            onChange={(e) => setDistrict(e.target.value)}
            value={district}
          />
          {district?.length == 0 && districtErr && (
            <p className="err">Enter District</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            State<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter State"
            onChange={(e) =>
              setStates(e.target.value.replace(/[^\sa-z]/gi, ""))
            }
            value={states}
          />
          {states?.length == 0 && statesErr && (
            <p className="err">Enter State</p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Pincode<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Pincode"
            onChange={(e) => setPincode(e.target.value.replace(/[^0-9]/gi, ""))}
            value={pincode}
          />
          {pincode?.length == 0 && pincodeErr && (
            <p className="err">Enter Pincode</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Phone No</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Phone No"
            onChange={(e) => setPhone(e.target.value.replace(/[^0-9]/gi, ""))}
            value={phone}
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Mobile No<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Moblie No"
            onChange={(e) => setMobile(e.target.value.replace(/[^0-9]/gi, ""))}
            value={mobile}
          />
          {mobile?.length == 0 && mobileErr && (
            <p className="err">Enter Mobile</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            E-Mail<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter your Email"
            onChange={(e) => setMail(e.target.value)}
            value={mail}
          />
          {mail?.length == 0 && mailErr && <p className="err">Enter Email</p>}
        </Col>
        <Col sm={12} className="inputinner-box text-start">
          <button
            className={
              btn1 == 0
                ? "bg-gray  border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
                : "bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            }
            onClick={submit}
            disabled={btn1 == 0 ? true : false}>
            Next
          </button>
          {/* <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3">
            cancel
          </button> */}
        </Col>
      </Col>
    </Col>
  );
};

export default AddressTab;
