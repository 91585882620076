// @ts-nocheck
import React from "react";
import { InvoiceScrutinyTableData } from "../../../constants/StaticData";

const InvoiceScrutinyTable = ({ setTds, tds }) => {
  return (
    <div className="user-mange-table bg-white p-2">
      <table>
        <thead>
          <tr>
            <th className="text-center text-nowrap">S.no</th>
            <th className="text-center text-nowrap">Material Code</th>
            <th className="text-center text-nowrap">Material Description</th>
            <th className="text-center text-nowrap">Unit</th>
            <th className="text-center text-nowrap">Quantity</th>
            <th className="text-center text-nowrap">Basic Rate</th>
            <th className="text-center text-nowrap">Total Value</th>
            <th className="text-center text-nowrap">TDS(%)</th>
            <th className="text-center text-nowrap">TCS(%)</th>
            <th className="text-center text-nowrap">Net Value</th>
          </tr>
        </thead>
        <tbody>
          {InvoiceScrutinyTableData.map((item, ind) => {
            return (
              <tr key={ind}>
                <td className="text-center">{item?.id}</td>
                <td className="text-center">{item?.uom}</td>
                <td className="text-center">{item?.materialDesc}</td>
                <td className="text-center">{item?.rate}</td>
                <td className="text-center">{item?.quantity}</td>
                <td className="text-center">{item?.nValue}</td>
                <td className="text-center">{item?.tValue}</td>
                <td className="text-center">
                  <input
                    type="number"
                    className="border-0 w-25 text-center"
                    value={tds[item?.id]}
                    onChange={(e) =>
                      setTds({ ...tds, [item?.id]: e.target.value })
                    }
                  />
                </td>
                <td className="text-center">
                  <input
                    type="number"
                    className="border-0 w-25 text-center"
                    value={tds[item?.id]}
                    onChange={(e) =>
                      setTds({ ...tds, [item?.id]: e.target.value })
                    }
                  />
                </td>
                <td className="text-center">{item?.tValue}</td>

                {/* <td className="text-center">
                  <input
                    className="border-0 w-25 text-center"
                    value={tds[item?.id]}
                    onChange={(e) =>
                      setTds({ ...tds, [item?.id]: e.target.value })
                    }
                  />
                </td>
                <td className="text-center">
                  {tds[item?.id] &&
                    +item?.tValue - +item?.tValue * +`0.${tds[item?.id]}`}
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceScrutinyTable;
