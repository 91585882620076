// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
// import { tickIcon } from "../../assets/img";
// import { mmCheck } from "../../constants/StaticData";

const AttributeForm = ({ materialDetails, setMaterialDetail }) => {
  const [Make, setMake] = useState("");
  const [Modal, setModal] = useState("");
  const [Color, setColor] = useState("");
  const [Type, setType] = useState("");
  const [Specification, setSpecification] = useState({
    Specification1: "",
    Specification2: "",
    Specification3: "",
    Specification4: "",
  });

  const [MakeErr, setMakeErr] = useState(false);
  const [ColorErr, setColorErr] = useState(false);
  const [ModalErr, setModalErr] = useState(false);
  const [TypeErr, setTypeErr] = useState(false);

  const submitHandler = () => {
    if (
      Make?.length == 0 ||
      Color?.length == 0 ||
      Modal?.length == 0 ||
      Type?.length == 0
    ) {
      setMakeErr(true);
      setColorErr(true);
      setModalErr(true);
      setTypeErr(true);
    } else {
      let data = {
        ...Specification,
        make: Make,
        model: Modal,
        attribute_type: Type,
        material_id: materialDetails?.id,
        specification1: Specification?.Specification1,
        specification2: Specification?.Specification2,
        specification3: Specification?.Specification3,
        specification4: Specification?.Specification4,
        color: Color,
      };
      api.material_attribute_add(data).then((res) => {
        if (res?.status == "success") {
          console.log("res", res);
          setMake("");
          setColor("");
          setModal("");
          setType("");
          setColor("");
          setMaterialDetail("");
          setSpecification({
            Specification1: "",
            Specification2: "",
            Specification3: "",
            Specification4: "",
          });
        }
      });
    }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4>Attribute Master</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Code</label>
          <p
            style={{ minHeight: "2.1rem", width: "90%" }}
            className="border-0 bg-lightBlue px-2 py-1 me-auto mt-1"
          >
            {materialDetails?.material_code}
          </p>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Make</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Make"
            onChange={(e) => setMake(e.target.value)}
            value={Make}
          />
          {Make?.length == 0 && MakeErr && <p className="err">enter make</p>}
        </Col>
        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Color</label>
          <div
            style={{ width: "90%" }}
            className="color-input  me-auto bg-lightBlue px-2 py-1 mt-1 d-flex"
          >
            <input
              type="text"
              onChange={(e) => setColor(e.target.value)}
              className="border-0"
              placeholder="Enter Color"
            />
            <p className="mt-1 ms-2">{Color}</p>
          </div>
          {Color?.length == 0 && ColorErr && <p className="err">enter color</p>}
        </Col> */}
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Color</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Color"
            onChange={(e) => setColor(e.target.value)}
            value={Color}
          />
          {Color?.length == 0 && ColorErr && <p className="err">enter color</p>}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Model</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Modal"
            onChange={(e) => setModal(e.target.value)}
            value={Modal}
          />
          {Modal?.length == 0 && ModalErr && <p className="err">enter modal</p>}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Type</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Type"
            onChange={(e) => setType(e.target.value)}
            value={Type}
          />
          {Type?.length == 0 && TypeErr && <p className="err">enter type</p>}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Specification 1</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Specification"
            onChange={(e) =>
              setSpecification({
                ...Specification,
                Specification1: e.target.value,
              })
            }
            value={Specification?.Specification1}
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Specification 2</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Specification"
            onChange={(e) =>
              setSpecification({
                ...Specification,
                Specification2: e.target.value,
              })
            }
            value={Specification?.Specification2}
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Specification 3</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Specification"
            onChange={(e) =>
              setSpecification({
                ...Specification,
                Specification3: e.target.value,
              })
            }
            value={Specification?.Specification3}
          />
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Specification 4</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Specification"
            onChange={(e) =>
              setSpecification({
                ...Specification,
                Specification4: e.target.value,
              })
            }
            value={Specification?.Specification4}
          />
        </Col>

        <Col sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={submitHandler}
          >
            Submit
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default AttributeForm;
