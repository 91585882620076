import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import Header from "../../component/Header/Header";
import InvoiceBooking from "../../component/ism/InvoiceBookingForm";
import MaterialInstruction from "../../component/ism/MatrialInstruction";
import UserManagementTable from "../../component/ism/UserManagementTable";
import { useNavigate } from "react-router";
import { api } from "../../constants/Services";
import useUser from "../../constants/Storage/userData";
import StatusModal from "../../component/StatusModal";

const IsmInvoiceScreen = () => {
  const [showStatus, setShowStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [grnList, setGrnList] = useState([]);
  const [grnDetailsList, setGrnDetailsList] = useState([]);
  const { user } = useUser();
  const [tableSel, setTableSel] = useState([]);

  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [list, setList] = useState([]);
  const [materialList, setMaterialList] = useState([]);

  const [company_id, setCompany_id] = useState("");
  const [company_idErr, setCompany_idErr] = useState(false);
  const [refInvoiceNo, setRefInvoiceNo] = useState("");
  const [InvoiceNo, setInvoiceNo] = useState("");
  const [projectName, setProjectName] = useState("");
  const [grnNo, setGrnNo] = useState("");
  const [dcNo, setDcNo] = useState("");
  const [InvoiceDate, setInvoiceDate] = useState("");
  const [grnDate, setGrnDate] = useState("");
  const [dcDate, setDcDate] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [file, setFile] = useState("");
  const [fileErr, setFileErr] = useState(false);

  const [InvoiceNoErr, setInvoiceNoErr] = useState(false);
  const [refInvoiceNoErr, setRefInvoiceNoErr] = useState(false);
  const [projectNameErr, setProjectNameErr] = useState(false);
  const [InvoiceDateErr, setInvoiceDateErr] = useState(false);
  const [grnNoErr, setGrnNoErr] = useState("");
  const [vendorNameErr, setVendorNameErr] = useState("");
  const [listCompany, setCompanyList] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [vendorData, setVendorData] = useState({});

  const statusModalClose = () => {
    setShowStatus(!showStatus);
  };

  const submitHandler = () => {
    if (
      InvoiceNo?.length == 0 ||
      projectName?.length == 0 ||
      InvoiceDate?.length == 0
    ) {
      setInvoiceNoErr(true);
      setProjectNameErr(true);
      setInvoiceDateErr(true);
    } else {
      alert("saved");
    }
  };

  const poPrintHandler = () => {
    setModal(!modal);
  };

  const getProject = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  const getCompanies = () => {
    api
      .getCompanies()
      .then((res) => {
        if (res?.status == "success") {
          let finder = res?.companies?.filter((i) => i?.status == 1);
          if (finder?.length > 0) {
            setCompanyList(finder);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCompanies();
    getProject();
  }, []);
  const [micsPoppup, setMicePoppup] = useState(false);
  const [search, setSearch] = useState();

  const projectSelect = (id) => {
    setProjectName(id);
    setGrnNo("");
    setList([]);
    api
      .grnDetailView(id)
      .then((res) => {
        // let temp = res?.grns?.filter((i)=>i?.status == 2)
        let temp = res?.grns?.filter((i) => i?.invoice?.length == 0);
        // console.log('temp', temp);
        setGrnList(temp);
      })
      .catch((err) => {
        console.log("err", err);
      });

    // NBI
    api
      .invoiceBookNbi(id)
      .then((res) => {
        setMaterialList(res?.project_nbi_items);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  console.log("grnList", grnList);

  const grnSelect = (id) => {
    setGrnNo(id);
    setList([]);
    let grnData = grnList?.find((i) => i?.id == id);
    setGrnDetailsList(grnData);
    setDcDate(grnData?.dc_date);
    setDcNo(grnData?.dc_no);
    setGrnDate(grnData?.grn_date);
    setVendorName(grnData?.vendor_id);
    setVendorData(grnData?.vendor);
    // setInvoiceNo(grnData?.invoice_no);
    // setInvoiceDate(grnData?.invoice_date);
    setList(grnData?.grn_detail);
    // console.log("grnData", grnData);
  };

  const addTab = () => {
    let temp = [...list];

    temp.push({
      custom: "custom",
    });
    setList(temp);
  };

  // HSN POPUP
  const [selId, setSelId] = useState(null);
  const [selInd, setSelInd] = useState(null);
  const miscModalHandler = (id, price, ind) => {
    setCommonFields({
      ...commonFields,
      total_price: { ...commonFields?.total_price, [+id]: price },
    });
    setMicePoppup(true);
    setSelId(id);
    setSelInd(ind);
  };

  const [cessData, setCessData] = useState({
    cess_value: "",
    cess_percentage: "",
  });

  const [hsnDetail, setHsnDetail] = useState({
    hsn_code: "",
    hsn_des: "",
    igst: "",
    sgst: "",
    cgst: "",
    igst_value: "",
    sgst_value: "",
    cgst_value: "",
  });

  const [miscDetails, setMiscDetails] = useState({
    discount_value: "",
    dis_percent: "",
    total_val_af_dis: "",
    net_value: "",
  });

  const [commonFields, setCommonFields] = useState({
    basic_price: {},
    total_price: {},
    remarks: {},
  });

  const calcu_percentage_value = (percent, total_value) => {
    const percentate_value = (percent / 100) * total_value;
    return percentate_value;
  };

  const roundOffActionHandler = (action) => {
    let temp;
    if (action == "-") temp = Math.floor(miscDetails?.net_value);
    else temp = Math.ceil(miscDetails?.net_value);
    setMiscDetails({ ...miscDetails, net_value: temp });
  };

  const miscSubmitHandler = () => {
    let temp = [...list];

    let obj = { ...temp[selInd] };

    // console.log("obj", obj);
    // console.log("obj.tcs", obj?.tcs);
    // console.log("obj.tds", obj?.tds);

    if (obj?.tcs) {
      let percentTcs = (+obj?.tcs / 100) * obj?.total_price;
      // console.log("percentTcs", percentTcs);

      let final = {
        ...obj,
        ["net_value"]: Number(
          +percentTcs + +obj?.total_price + +miscDetails?.net_value
        ).toFixed(2),
        ["misc_value"]: Number(+miscDetails?.net_value).toFixed(2),
        ["tcs_value"]: percentTcs,
        ["hsn_sgst"]: hsnDetail?.sgst[selId],
        ["hsn_cgst"]: hsnDetail?.cgst[selId],
        ["hsn_igst"]: hsnDetail?.igst[selId],
        ["hsn_value"]:
          hsnDetail?.sgst_value[selId] && hsnDetail?.cgst_value[selId]
            ? +hsnDetail?.cgst_value[selId] + +hsnDetail?.sgst_value[selId]
            : hsnDetail?.igst_value[selId]
            ? +hsnDetail?.igst_value
            : 0,
      };

      temp[selInd] = final;
      setList(temp);
      setMicePoppup(false);
    } else if (obj?.tds) {
      let percentTds = (+obj?.tds / 100) * obj?.total_price;
      // console.log("percentTds", percentTds);

      let final = {
        ...obj,
        ["net_value"]: Number(
          +obj?.total_price + +miscDetails?.net_value - percentTds
        ).toFixed(2),
        ["tds_value"]: percentTds,
        ["misc_value"]: Number(+miscDetails?.net_value).toFixed(2),
        ["hsn_sgst"]: hsnDetail?.sgst[selId],
        ["hsn_cgst"]: hsnDetail?.cgst[selId],
        ["hsn_igst"]: hsnDetail?.igst[selId],
        ["hsn_value"]:
          hsnDetail?.sgst_value[selId] && hsnDetail?.cgst_value[selId]
            ? +hsnDetail?.cgst_value[selId] + +hsnDetail?.sgst_value[selId]
            : hsnDetail?.igst_value[selId]
            ? +hsnDetail?.igst_value
            : 0,
      };

      temp[selInd] = final;
      setList(temp);
      setMicePoppup(false);
    }
  };

  const revisedTotalCalc = (tot_val, dis_val) => tot_val - dis_val;

  const miscDiscountHandler = async (percent, total_price) => {
    const disVal = calcu_percentage_value(+percent, +total_price);
    const revised_total = revisedTotalCalc(+total_price, +disVal);
    let percenVal = 0;
    if (cessData?.cess_percentage?.[selId]) {
      percenVal = calcu_percentage_value(
        +cessData?.cess_percentage?.[selId],
        +total_price
      );
    }

    let net_value;

    if (companyData?.state !== vendorData?.state) {
      const igst = calcu_percentage_value(hsnDetail.igst[selId], revised_total);
      setHsnDetail({
        ...hsnDetail,
        igst_value: { [selId]: igst },
        sgst_value: { [selId]: "-" },
        cgst_value: { [selId]: "-" },
      });
      net_value = igst + +percenVal;
    } else {
      if (disVal && revised_total) {
        const cgst = calcu_percentage_value(
          hsnDetail.cgst[selId],
          revised_total
        );
        const sgst = calcu_percentage_value(
          hsnDetail.sgst[selId],
          revised_total
        );
        setHsnDetail({
          ...hsnDetail,
          sgst_value: { [selId]: cgst },
          cgst_value: { [selId]: sgst },
          igst_value: { [selId]: "-" },
        });
        net_value = cgst + sgst + percenVal ? percenVal : 0;
      }
    }
    setMiscDetails({
      discount_value: disVal,
      dis_percent: percent,
      total_val_af_dis: revised_total,
      net_value: net_value,
    });
  };

  const miscCessHandler = (percent, total_price) => {
    const percenVal = calcu_percentage_value(+percent, +total_price);
    setCessData({
      ...cessData,
      cess_value: { [selId]: percenVal },
      cess_percentage: { [selId]: percent },
    });

    let sgst_value =
      hsnDetail?.sgst_value[selId] !== "-" ? hsnDetail?.sgst_value[selId] : 0;
    let cgst_value =
      hsnDetail?.cgst_value[selId] !== "-" ? hsnDetail?.cgst_value[selId] : 0;
    let igst_value =
      hsnDetail?.igst_value[selId] !== "-" ? hsnDetail?.igst_value[selId] : 0;

    let sum = +sgst_value + +cgst_value + +igst_value;

    let net_value = +percenVal + +sum;

    setMiscDetails({
      ...miscDetails,
      net_value: net_value,
    });
  };

  const searchHsn = (hsn_code, total_val) => {
    let data = {
      code: hsn_code,
    };
    api.searchHSN(data).then((res) => {
      if (res?.status === "success") {
        if (companyData?.state !== vendorData?.state) {
          console.log("state!");
          setHsnDetail({
            hsn_code: {
              ...hsnDetail?.hsn_code,
              // [selId]: res?.hsn_code?.code_name,
              [selId]: res?.hsn_code?.id,
            },
            hsn_des: {
              ...hsnDetail?.hsn_des,
              [selId]: res?.hsn_code?.description,
            },
            igst: { ...hsnDetail?.igst, [selId]: res?.hsn_code?.igst },
            igst_value: {
              ...hsnDetail?.igst_value,
              [selId]: calcu_percentage_value(res?.hsn_code?.igst, total_val),
            },
            sgst: { ...hsnDetail?.sgst, [selId]: "-" },
            cgst: { ...hsnDetail?.cgst, [selId]: "-" },
            sgst_value: { ...hsnDetail?.sgst_value, [selId]: "-" },
            cgst_value: { ...hsnDetail?.cgst_value, [selId]: "-" },
          });
          setMiscDetails({
            ...miscDetails,
            net_value: calcu_percentage_value(res?.hsn_code?.igst, total_val),
          });
        } else {
          setHsnDetail({
            ...hsnDetail,
            hsn_code: {
              ...hsnDetail?.hsn_code,
              [selId]: res?.hsn_code?.code_name,
            },
            hsn_des: {
              ...hsnDetail?.hsn_des,
              [selId]: res?.hsn_code?.description,
            },
            igst: { ...hsnDetail?.igst, [selId]: "-" },
            igst_value: { ...hsnDetail?.igst_value, [selId]: "-" },
            sgst: { ...hsnDetail?.sgst, [selId]: res?.hsn_code?.sgst },
            sgst_value: {
              ...hsnDetail?.sgst_value,
              [selId]: calcu_percentage_value(res?.hsn_code?.sgst, total_val),
            },
            cgst: { ...hsnDetail?.cgst, [selId]: res?.hsn_code?.cgst },
            cgst_value: {
              ...hsnDetail?.cgst_value,
              [selId]: calcu_percentage_value(res?.hsn_code?.cgst, total_val),
            },
          });

          setMiscDetails({
            ...miscDetails,
            net_value:
              calcu_percentage_value(res?.hsn_code?.sgst, total_val) +
              calcu_percentage_value(res?.hsn_code?.cgst, total_val),
          });
        }
      }
    });
  };

  const handleChange = (event, ind, type, item) => {
    let temp = [...list];

    let obj = { ...temp[ind] };

    if (type == "received_qty" || type == "basic_price") {
      if (item?.custom == "custom") {
        let sum_value = Math.abs(
          +item?.transport_total - +item?.raised_transport
        );

        let sum =
          type == "received_qty"
            ? obj?.basic_price * +event
            : type == "basic_price"
            ? obj?.received_qty * +event
            : 0;

        console.log("sum_value", sum_value);
        let final = {
          ...obj,
          [type]: event,
          ["total_price"]:
            type == "received_qty"
              ? obj?.basic_price * +event
              : type == "basic_price"
              ? obj?.received_qty * +event
              : 0,

          ["misc_value"]: "",
          ["net_value"]: 0,
          ["error"]: +sum > +sum_value ? 1 : 0,
        };

        temp[ind] = final;
        setList(temp);
      } else {
        let final = {
          ...obj,
          [type]: event,
          ["total_price"]:
            type == "received_qty"
              ? obj?.basic_price * event
              : type == "basic_price"
              ? obj?.received_qty * event
              : 0,

          ["misc_value"]: "",
          ["net_value"]: 0,
        };

        temp[ind] = final;
        setList(temp);
      }
      setCommonFields({
        ...commonFields,
        total_price: { ...commonFields?.total_price, [+selId]: event },
      });
    } else if (type == "tds") {
      let final = {
        ...obj,
        [type]: +event,
        ["net_value"]: 0,
        ["misc_value"]: "",
      };
      delete final?.tcs;
      delete final?.tcs_value;
      temp[ind] = final;
      setList(temp);
    } else if (type == "tcs") {
      let final = {
        ...obj,
        [type]: +event,
        ["net_value"]: 0,
        ["misc_value"]: "",
      };
      delete final?.tds;
      delete final?.tds_value;
      temp[ind] = final;
      setList(temp);
    } else {
      let final = {
        ...obj,
        [type]: event,
        ["net_value"]: 0,
        ["misc_value"]: "",
      };

      temp[ind] = final;
      setList(temp);
    }
  };

  const handleCessChange = (event, id) => {
    setCessData((val) => ({ ...val, [id]: event }));
  };

  useEffect(() => {
    if (list?.length > 0) {
      // console.log("l", list);
      const total_price = list.reduce((a, b) => +a + +b.net_value, 0);
      setTotalPrice(total_price);
      // console.log("total_price", total_price);
    }
  }, [list]);

  // console.log("list", list);

  const companyHandle = (id) => {
    let finder = listCompany?.find((i) => i?.id == id);
    setCompanyData(finder);
    setCompany_id(id);
  };

  return (
    <Col
      // xs={12}
      // lg={10}
      // sm={10}
      // md={12}
      // className="py-2 px-1 p-md-3 invoice-res"
      className="py-2  px-1 p-md-3 col-12"
    >
      <Header />
      <InvoiceBooking
        refInvoiceNoErr={refInvoiceNoErr}
        setRefInvoiceNoErr={setRefInvoiceNoErr}
        projectList={projectList}
        setInvoiceNo={setInvoiceNo}
        InvoiceNo={InvoiceNo}
        refInvoiceNo={refInvoiceNo}
        setRefInvoiceNo={setRefInvoiceNo}
        setProjectName={setProjectName}
        projectName={projectName}
        setGrnNo={setGrnNo}
        grnNo={grnNo}
        setInvoiceDate={setInvoiceDate}
        InvoiceDate={InvoiceDate}
        setInvoiceNoErr={setInvoiceNoErr}
        InvoiceNoErr={InvoiceNoErr}
        projectNameErr={projectNameErr}
        setProjectNameErr={setProjectNameErr}
        InvoiceDateErr={InvoiceDateErr}
        setInvoiceDateErr={setInvoiceDateErr}
        submitHandler={submitHandler}
        projectSelect={projectSelect}
        grnList={grnList}
        grnSelect={grnSelect}
        grnDate={grnDate}
        vendorName={vendorName}
        setVendorName={setVendorName}
        grnNoErr={grnNoErr}
        vendorNameErr={vendorNameErr}
        file={file}
        setFile={setFile}
        fileErr={fileErr}
        setFileErr={setFileErr}
        setCompany_id={setCompany_id}
        company_id={company_id}
        company_idErr={company_idErr}
        companyHandle={companyHandle}
        listCompany={listCompany}
        setVendorData={setVendorData}
      />
      {list?.length > 0 && (
        <UserManagementTable
          setTableSel={setTableSel}
          tableSel={tableSel}
          handleChange={handleChange}
          list={list}
          setList={setList}
          addTab={addTab}
          miscModalHandler={miscModalHandler}
          setMaterialList={setMaterialList}
          materialList={materialList}
        />
      )}

      <MaterialInstruction
        user={user?.name}
        setModal={setModal}
        totalPrice={totalPrice}
        projectName={projectName}
        setProjectName={setProjectName}
        setProjectNameErr={setProjectNameErr}
        grnNo={grnNo}
        setGrnNoErr={setGrnNoErr}
        vendorName={vendorName}
        setVendorNameErr={setVendorNameErr}
        file={file}
        setFileErr={setFileErr}
        setInvoiceDateErr={setInvoiceDateErr}
        setInvoiceNoErr={setInvoiceNoErr}
        InvoiceNo={InvoiceNo}
        InvoiceDate={InvoiceDate}
        list={list}
        dcDate={dcDate}
        dcNo={dcNo}
        setShowStatus={setShowStatus}
        setStatusMessage={setStatusMessage}
        refInvoiceNo={refInvoiceNo}
        setCompany_idErr={setCompany_idErr}
        company_id={company_id}
      />

      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
          statusMessage={statusMessage}
        />
      )}

      {/* Misc Poppup start */}
      {micsPoppup && (
        <Modal
          show={micsPoppup}
          centered
          onHide={() => {
            setMicePoppup(!micsPoppup);
          }}
        >
          <>
            <Modal.Header closeButton className="border-0">
              <h5 className="mx-auto">Miscellaneous Screen</h5>
            </Modal.Header>
            <Modal.Body>
              <div className="w-100 my-1 py-1">
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  HSN Code
                </label>
                <input
                  onChange={(e) =>
                    searchHsn(e.target.value, commonFields?.total_price[selId])
                  }
                />
              </div>
              <div className="w-100 my-1 py-1">
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  HSN Description
                </label>
                <input value={hsnDetail?.hsn_des[selId] ?? " "} />
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Total Value
                  </label>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={commonFields?.total_price[selId]}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Total Value After Discount
                  </label>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={miscDetails?.total_val_af_dis}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Discount
                  </label>
                  <input
                    className="w-25"
                    onChange={(e) =>
                      miscDiscountHandler(
                        e.target.value,
                        commonFields?.total_price[selId]
                      )
                    }
                  />
                  <span className="ms-1">%</span>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={miscDetails?.discount_value}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    IGST
                  </label>
                  <input
                    className="w-25"
                    readOnly
                    value={hsnDetail?.igst[selId]}
                  />
                  <span className="ms-1">%</span>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={hsnDetail?.igst_value[selId] ?? ""}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    SGST
                  </label>
                  <input
                    className="w-25"
                    readOnly
                    value={hsnDetail?.sgst[selId]}
                  />
                  <span className="ms-1">%</span>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={hsnDetail?.sgst_value[selId]}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    CGST
                  </label>
                  <input
                    className="w-25"
                    readOnly
                    value={hsnDetail?.cgst[selId]}
                  />
                  <span className="ms-1">%</span>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={hsnDetail?.cgst_value[selId]}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Cess
                  </label>
                  <input
                    className="w-25"
                    value={cessData?.cess_percentage?.[selId]}
                    onChange={(e) =>
                      miscCessHandler(
                        e.target.value,
                        miscDetails?.total_val_af_dis
                          ? miscDetails?.total_val_af_dis
                          : commonFields?.total_price[selId]
                      )
                    }
                  />
                  <span className="ms-1">%</span>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={cessData?.cess_value?.[selId]}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Round Off
                  </label>
                  <button
                    className="border-0 me-1 bg-primary1"
                    onClick={() => roundOffActionHandler("+")}
                  >
                    +
                  </button>
                  <button
                    className="border-0 ms-1 bg-primary2"
                    onClick={() => roundOffActionHandler("-")}
                  >
                    -
                  </button>
                </Col>
              </div>

              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Net Value
                  </label>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    type="text"
                    value={
                      miscDetails?.net_value
                        ? Number(miscDetails?.net_value).toFixed(2)
                        : ""
                    }
                  />
                </Col>
              </div>
            </Modal.Body>
            <Modal.Footer className="border-0">
              <button
                onClick={() => setMicePoppup(false)}
                className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
              >
                Cancel
              </button>
              <button
                className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
                onClick={miscSubmitHandler}
              >
                Save
              </button>
            </Modal.Footer>
          </>
        </Modal>
      )}
      {/* Misc Poppup end */}

      <Modal show={modal} centered onHide={() => setModal(!modal)}>
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1">Invoice Booking : </label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <p className="text-center fs-6">Do you want to Print the Output?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            onClick={poPrintHandler}
            variant="primary"
          >
            Print
          </Button>
          <Button variant="secondary" onClick={() => setModal(!modal)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default IsmInvoiceScreen;
