import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";
import { pan_status } from "../../constants/StaticData";

const TaxationTab = ({ setTax, tax, setBtn2, tabonClickHandler }) => {
  const [pan, setPan] = useState("");
  const [panStatus, setPanStatus] = useState("");
  console.log(
    "🚀 ~ file: TaxationTab.js:9 ~ TaxationTab ~ panStatus:",
    panStatus
  );

  const [panErr, setPanErr] = useState(false);
  const [panStatusErr, setPanStatusErr] = useState(false);

  const submit = () => {
    if (
      pan?.length < 5 ||
      panStatus?.length == 0
      // ||
      // panStatus.charAt(0) != pan.charAt(3)
    ) {
      setPanErr(true);
      setPanStatusErr(true);
    } else {
      // alert("Taxation Saved");
      let data = {};
      data.pan = pan;
      data.panstatus = panStatus;
      setTax(data);
      tabonClickHandler("bank");
      setBtn2(1);
    }
  };

  const view = () => {
    if (tax?.pan == undefined && tax?.panStatus == undefined) {
      setPan("");
      setPanStatus("");
    } else {
      setPan(tax?.pan);
      setPanStatus(tax?.panstatus);
    }
  };

  useEffect(() => {
    view();
  }, []);

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-0 text-center">
      <h4 className="w-95 ms-xl-0">Bank Details</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Pan<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Name of Bank"
            onChange={(e) => setPan(e.target.value)}
            value={pan}
          />
          {pan?.length == 0 && panErr && (
            <p className="err">Please Enter Valid Pan Number</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Pan Status</label>
          <div
            style={{ width: "90%" }}
            className="finance-box text-start m-auto ms-0 w-90"
          >
            {/* <Select
              style={{ width: "90%" }}
              className="input-select  ms-0"
              options={pan_status}
              onChange={(e) => setPanStatus(e)}
              value={panStatus}
            /> */}
            <select
              style={{ border: "2px solid #f1f7ff" }}
              className="input-select  ms-0"
              onChange={(e) => setPanStatus(e.target.value)}
              value={panStatus}
            >
              <option value={""}>select pan status</option>
              <option value={"P-individuals"}>P-individuals</option>
              <option value={"B-Body of Individuals (BOI)"}>
                B-Body of Individuals (BOI)
              </option>
              <option value={"A-Association of Persons (AOP)"}>
                A-Association of Persons (AOP)
              </option>
              <option value={"H-Hindu Undivided Family (HUF)"}>
                H-Hindu Undivided Family (HUF)
              </option>
              <option value={"C-companies"}>C-companies</option>
              <option value={"F-partnership firms"}>F-partnership firms</option>
              <option value={"T- trusts"}>T- trusts</option>
            </select>
            {panStatus?.length == 0 && panStatusErr && (
              <p className="err">select pan status</p>
            )}
          </div>
        </Col>

        <Col sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={submit}
          >
            Next
          </button>
          {/* <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3">
            cancel
          </button> */}
        </Col>
      </Col>
    </Col>
  );
};

export default TaxationTab;
