// @ts-nocheck
import React, { useState, useEffect } from "react";
import StatusModal from "../StatusModal";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
import { tickIcon } from "../../assets/img";
import { qualifWorkIcon, report, respIcon } from "../../assets/img";
import FinanceTab from "./FinanceTab";
import ResponsibilityTab from "./ResponsibilityTab";
import RolesTab from "./RolesTab";

const RolesRespTab = ({
  setReset,
  passErr,
  setPassErr,
  employeeId,
  employeeDepartment,
  employeeDesination,
  password,
  checkbox,
  setcheckbox,
  empRole,
  employeeName,
  notibox,
  setSubEmployeeDepartment,
  subEmployeeDepartment,
  setnotibox,
  passAlaErr,
  setEmployeeProjects,
  employeeProjects,
}) => {
  const [tab, setTab] = useState("responsibility");
  const [roleModules, setRoleModules] = useState([]);
  const [allowAccess, setAllowAccess] = useState([]);
  const [empPermissions, setEmpPermissions] = useState([]);
  const [modules, setModules] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [checkBox, setCheckBox] = useState([]);
  const [showStatus, setShowStatus] = useState(false);
  const statusModalClose = () => {
    setShowStatus(!showStatus);
    setTimeout(window.location.reload(), 2000);
    // setTab("responsibility");
  };
  useEffect(() => {
    if (employeeId) {
      api.employee_view(employeeId).then((res) => {
        if (res?.status === "success") {
          setPermissions(res?.permissions);
          setModules(res?.modules);
        }
      });
    }
  }, [employeeId]);

  const tabonClickHandler = (e) => {
    if (allowAccess.includes(e)) {
      console.log("e", e);
      setTab(e);
    }
  };

  const rolesSubmitHandler = () => {
    let web = checkbox.web === true ? 1 : 2;
    password.length < 5 ? setPassErr(true) : setPassErr(false);
    let mobile = checkbox.mobile === true ? 1 : 2;
    let formData = new FormData();
    formData.append("name", employeeName);
    formData.append("designation", employeeDesination);
    formData.append("department", employeeDepartment);
    formData.append("role", empRole);
    formData.append("web_access", web);
    formData.append("mobile_access", mobile);
    formData.append("employee_id", employeeId);

    if (employeeProjects?.length > 0) {
      employeeProjects?.map((item, ind) => {
        formData.append(`project[${ind}]`, +item);
      });
    }

    if (subEmployeeDepartment?.length > 0) {
      subEmployeeDepartment?.map((item, ind) => {
        formData.append(`material_departments[${ind}]`, item);
      });
    } else {
      formData.append(`material_departments[${0}]`, []);
    }

    if (notibox?.level_1) {
      formData.append("employee_level", 1);
    } else if (notibox?.level_2) {
      formData.append("employee_level", 2);
    } else if (notibox?.level_3) {
      formData.append("employee_level", 3);
    } else {
      formData.append("employee_level", 0);
    }
    if (password) {
      formData.append("password", password);
    }

    // if (!passErr && password != "") {
    api.employee_role_assign(formData).then((res) => {
      if (res?.status == "success") {
        console.log("role-api-submie", res);
        setTimeout(() => {
          statusModalClose();
          // setReset(true);
        }, 500);
        console.log("emp-id -form", employeeId);
      }
    });
    // }
  };
  const roleResFinalSubmitHandler = () => {
    let roles_final_data = {
      employee_id: "",
      permissions: [],
      finance: [],
    };
    if (checkBox.length > 0 && empPermissions.length > 0 && employeeId) {
      roles_final_data.employee_id = employeeId;
      roles_final_data.permissions = empPermissions;
      roles_final_data.finance = checkBox;

      api.employee_roles_permissions(roles_final_data).then((res) => {
        if (res.status === "success") {
          // statusModalClose();
          rolesSubmitHandler();
        }
        // console.log("roles-final-submit", res);
      });
    }
  };
  return (
    <Col className="roles-resp-tab py-2 px-1 px-md-3 py-2 mt-1 text-center">
      <Col className="d-flex flex-wrap justify-content-center">
        <button
          onClick={(e) => tabonClickHandler((e = "responsibility"))}
          className={
            tab === "responsibility"
              ? "tab-list bg-gradient border-0"
              : "tab-list bg-gray border-0"
          }
        >
          <img src={respIcon} className="tabIcon" alt="res-icon" />
          <p>Roles</p>
        </button>
        <button
          onClick={(e) => tabonClickHandler((e = "report"))}
          className={
            tab === "report"
              ? "tab-list bg-gradient border-0"
              : "tab-list bg-gray border-0"
          }
        >
          <img src={report} className="tabIcon reporttab" alt="report" />
          <p>Screen & Report</p>
        </button>
        <button
          onClick={(e) => tabonClickHandler((e = "finance"))}
          className={
            tab === "finance"
              ? "tab-list bg-gradient border-0"
              : "tab-list bg-gray border-0"
          }
        >
          <img src={qualifWorkIcon} className="tabIcon" alt="qualification" />
          <p>Finance</p>
        </button>
      </Col>
      <Col xs={12}>
        {tab === "responsibility" && (
          <ResponsibilityTab
            modules={modules}
            setModules={setModules}
            allowAccess={allowAccess}
            setAllowAccess={setAllowAccess}
            setRoleModules={setRoleModules}
            setTab={setTab}
            password={password}
            passAlaErr={passAlaErr}
          />
        )}
        {tab === "report" && roleModules && (
          <RolesTab
            allowAccess={allowAccess}
            setAllowAccess={setAllowAccess}
            roleModules={roleModules}
            permissions={permissions}
            setPermissions={setPermissions}
            setEmpPermissions={setEmpPermissions}
            setTab={setTab}
          />
        )}
        {tab === "finance" && (
          <FinanceTab
            checkBox={checkBox}
            setCheckBox={setCheckBox}
            roleResFinalSubmitHandler={roleResFinalSubmitHandler}
            empPermissions={empPermissions}
            setReset={setReset}
          />
        )}
      </Col>
      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
        />
      )}
    </Col>
  );
};

export default RolesRespTab;
