import React, { useState } from "react";
import { Col } from "react-bootstrap";
import GSTAddress from "../../component/GSTReg/GSTAddress";
import GSTRegForm from "../../component/GSTReg/GSTRegForm";
import Header from "../../component/Header/Header";
import moment from "moment";

const GstRegistrationScreen = () => {
  const [showDrop, setShowDrop] = useState(false);
  const [tempForm, setTempForm] = useState({
    supplier_name: "",
    supplier_type: "",
  });
  const [gstFormDetails, setGstFormDetails] = useState({
    pan: "",
    temp: "",
    gst_state: "",
    gst_type: "",
    vendor_id: "",
    gst_date: moment().format("YYYY-MM-DD"),
  });
  console.log(
    "🚀 ~ file: GstRegistrationScreen.js:22 ~ GstRegistrationScreen ~ gstFormDetails:",
    gstFormDetails
  );

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <GSTRegForm
        showDrop={showDrop}
        setShowDrop={setShowDrop}
        gstFormDetails={gstFormDetails}
        setGstFormDetails={setGstFormDetails}
        setTempForm={setTempForm}
        tempForm={tempForm}
      />
      {Object.values(gstFormDetails).every((x) => x !== "") && (
        <GSTAddress
          setShowDrop={setShowDrop}
          gstFormDetails={gstFormDetails}
          setGstFormDetails={setGstFormDetails}
          setTempForm={setTempForm}
        />
      )}
    </Col>
  );
};

export default GstRegistrationScreen;
