import React, { useState, useEffect } from "react";
import eye_icon from "../../assets/icons/common/Eye.svg";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";
import { PaginationControl } from "react-bootstrap-pagination-control";

function MaterialReceiptList() {
  const [selectedProject, setSelectedProject] = useState("");
  const [page, setPage] = useState(1);
  const [MrList, setMrList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const navigate = useNavigate();

  const getProjects = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };
  useEffect(() => {
    getProjects();
  }, []);

  const getMaterialTransfer = (id, page) => {
    let param = `?page=${page}`;
    let obj = {
      project_id: id,
    };
    api
      .matertialReceiptList(param, obj)
      .then((res) => {
        let temp = [];
        res?.material_receipt?.map((item, ind) => {
          item?.material_receipt_detail?.map((transfer, indx) => {
            temp.push({
              material_receipt_id: item?.id,
              material_receipt_status: item?.status,
              ...item,
              ...transfer,
            });
          });
        });

        setMrList({ pagination: res?.pagination, material_receipt: temp });

        // console.log("matertialTransferList", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // useEffect(() => {
  //   getMaterialTransfer();
  // }, [selectedProject, page]);

  const materialTransferDetailHandler = (sel_mt, mt_colde, mt_date) => {
    navigate("/ism/matrial/output", {
      state: { id: sel_mt, type: "internal" },
    });
  };

  // console.log("MrList", MrList);

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white my-3 text-center">
        <header>
          <Col xs={12} className="d-flex my-2">
            <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 ms-auto pointer"
              value={selectedProject}
              onChange={(e) => {
                setSelectedProject(e.target.value);
                setPage(1);
                getMaterialTransfer(e.target.value, 1);
              }}
            >
              <option>Select Project</option>
              {projectList?.map((project) => (
                <option key={project?.id} value={project?.id}>
                  {project?.name}
                </option>
              ))}
            </select>
          </Col>
        </header>
        <h4 className="my-1 py-1">Material Receipt List</h4>
        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Receipt No</th>
                <th>Material Code</th>
                {/* <th>Material Description</th> */}
                <th>UOM</th>
                <th className="text-center text-nowrap">Transfer Quanity</th>
                <th>Accept Quanity</th>
                {/* <th>Net Value</th> */}
                <th>Created By</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {MrList?.material_receipt?.map((material, ind) => (
                <tr>
                  <td>{ind + 1}</td>
                  <td>{material?.receipt_no}</td>
                  <td>
                    {
                      material?.material_transfer_detail?.material
                        ?.material_code
                    }
                  </td>
                  <td>{material?.material_transfer_detail?.material?.uom}</td>
                  <td>{material?.material_transfer_detail?.transfer_qty}</td>
                  <td className="text-center">
                    {material?.received_qty}
                  </td>
                
                  {/* <td>{material?.received_qty}</td> */}
                  <td>{material?.employee?.name}</td>
                  <td>
                    {material?.material_receipt_status == 1
                      ? "Pending"
                      : material?.material_receipt_status == 2
                      ? "Accepted"
                      : "Rejected"}
                  </td>
                  <td>
                    <div className="d-flex">
                      <img
                        onClick={() =>
                          materialTransferDetailHandler(
                            material?.material_receipt_id,
                            material?.po_no,
                            material?.po_date
                          )
                        }
                        style={{ width: "20px" }}
                        className="pointer ms-2"
                        src={eye_icon}
                        alt="eye-icon"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="my-1 py-1 d-flex justify-content-end">
          <PaginationControl
            page={page}
            between={4}
            total={MrList?.pagination?.total}
            limit={10}
            changePage={(page) => {
              setPage(page);
              getMaterialTransfer(selectedProject, page);
            }}
            ellipsis={1}
          />
        </div>
      </Col>
    </Col>
  );
}

export default MaterialReceiptList;
