import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../../constants/Services";
import moment from "moment";

const IsmGinForm = ({
  purchaseOrderDetails,
  setPurchaseOrderDetails,
  setDateError,
  dateError,
  projectType,
  setProjectType,
  projectName,
  setProjectName,
  pRDDate,
  setPRDDate,
  prNo,
  setPrNo,
  PONo,
  setPONo,
  DCNo,
  setDCNo,
  DCDate,
  setDCDate,
  projectTypeErr,
  GRNDate,
  setGRNDate,
  InvoiceNo,
  setInvoiceNo,
  grnFile,
  setGrnFile,
  setInvoiceDate,
  InvoiceDate,
  projectNameErr,
  setProjectNameErr,
  PONoErr,
  setPONoErr,
  DCNoErr,
  setDCNoErr,
  GRNNoErr,
  setGRNNoErr,
  DCDateErr,
  setDCDateErr,
  GRNDateErr,
  setGRNDateErr,
  InvoiceNoErr,
  setInvoiceNoErr,
  InvoiceDateErr,
  setInvoiceDateErr,
  setWareHouseErr,
  wareHouseErr,
  wareHouse,
  setWareHouse,
  setVendor,
  vendor,
  setDetails,
  setCompany_id,
  company_id,
  company_idErr,
}) => {
  const [projectList, setProjectList] = useState([]);
  const [warehouseList, setWaerHouseList] = useState([]);
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const getProject = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  const ginDateHandler = (val, target) => {
    let temp = [...dateError];
    const today = new Date();
    const entered_date = new Date(val);
    if (entered_date > today) {
      // console.log("com", target);
      temp.push(target);
    } else {
      temp?.splice(temp?.indexOf(target), 1);
      switch (target) {
        case "dc":
          setDCDate(val);
          break;
        case "grn":
          setGRNDate(val);
          break;
        case "invoice":
          setInvoiceDate(val);
          break;
        default:
        // code block
      }
    }
    setDateError(temp);
  };

  // console.log("pr", projectList);

  useEffect(() => {
    if (projectType == 1 && +PONo > 0 && projectName?.length > 0) {
      let formdata = new FormData();
      formdata.append("purchase_order_id", +PONo);
      formdata.append("project_id", projectName);

      api
        .purchaseOrderView(PONo)
        .then((res) => {
          if (res?.status == "success") {
            setPurchaseOrderDetails(res?.purchase_orders);
            setDetails([]);
          }
          // console.log("po-details", res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (projectType == 2 && projectName?.length > 0) {
      let formdata = new FormData();
      formdata.append("project_id", projectName);
      let params = "?type=2";
      api
        .getMaterialsLists(params)
        .then((res) => {
          if (res?.status == "success") {
            let temp = {
              purchase_order_details: res?.materials,
            };
            setPurchaseOrderDetails(temp);
            setDetails([]);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [PONo, projectName, projectType]);

  useEffect(() => {
    if (projectName) {
      let data = {
        project_id: +projectName,
      };
      // api.purchaseOrderList(data).then((res) => {
      api.grnPoLists(data).then((res) => {
        console.log("po-list", res);
        setPurchaseOrderList(res?.purchase_orders);
        setPurchaseOrderDetails([]);
      });
    }
  }, [projectName]);

  const getWareHouseProject = (id) => {
    api
      .warehousePojectView(id)
      .then((res) => {
        setWaerHouseList(res?.warehouse_project);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getWareHouse = () => {
    api
      .warehouseList()
      .then((res) => {
        setWaerHouseList(res?.warehouses);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getVendor = () => {
    api.get_vendors().then((res) => {
      console.log("res-vendors-list", res);
      if (res?.status == "success") {
        setVendorList(res?.vendors);
      }
    });
  };

  useEffect(() => {
    getProject();
    // getWareHouse();
    getVendor();
  }, []);

  useEffect(() => {
    // console.log("project-name", projectName);
    if (projectName) {
      const sel_project = projectList?.filter(
        (project) => project?.id === +projectName
      );
      console.log("sel_project", sel_project);
      setPrNo(sel_project[0]?.project_no);
      setPRDDate(sel_project[0]?.start_date);
    }
  }, [projectName]);

  useEffect(() => {
    if (PONo) {
      let vendorName = purchaseOrderList?.find(
        (i) => i?.purchase_order?.id == +PONo
      );
      // console.log("vendorName", vendorName);
      setVendor(vendorName?.purchase_order?.vendor_id);
    }
  }, [PONo]);

  const filtered_date = (sel_date, grn_date, project_type) => {
    if (+project_type == 2) {
      setGRNDate(sel_date);
    } else {
      let today = new Date();
      let selected_date = new Date(sel_date);
      let now = new Date(grn_date);
      var defaultDate = now - 1000 * 60 * 60 * 24 * 1;
      defaultDate = new Date(defaultDate);
      if (selected_date > defaultDate || selected_date == defaultDate) {
        setGRNDate(sel_date);
      }
    }
  };

  const [listCompany, setCompanyList] = useState([]);
  const getCompanies = () => {
    api
      .getCompanies()
      .then((res) => {
        if (res?.status == "success") {
          let finder = res?.companies?.filter((i) => i?.status == 1);
          if (finder?.length > 0) {
            setCompanyList(finder);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  // console.log("purchaseOrderDetails", purchaseOrderDetails);

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">GRN Entry</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <select
            className="border-0 bg-lightBlue px-2 py-2 w-90 mt-1"
            placeholder="Select Company"
            onChange={(e) => setCompany_id(e.target.value)}
            value={company_id}
          >
            <option value={""}>Select Company</option>
            {listCompany?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          {company_id?.length == 0 && company_idErr && (
            <p className="err">Select Company</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Type</label>
          <select
            style={{ minHeight: "2.1rem", fontSize: "12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => {
              setPurchaseOrderDetails([]);
              setProjectName("");
              setPONo("");
              setVendor("");
              setProjectType(e.target.value);
            }}
            value={projectType}
          >
            <option hidden>Select project</option>
            <option value={2}>Cash Purchase</option>
            <option value={1}>PO</option>
          </select>

          {projectType?.length == 0 && projectTypeErr && (
            <p className="err">Select Project Type</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <select
            style={{ minHeight: "2.1rem", fontSize: "12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => {
              setProjectName(e.target.value);
              getWareHouseProject(e.target.value);
            }}
            value={projectName}
          >
            <option hidden>Select Project</option>
            {projectList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>

          {projectName?.length == 0 && projectNameErr && (
            <p className="err">Select Project Name</p>
          )}
        </Col>

        {+projectType === 1 && (
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">PO No</label>
            <select
              style={{ minHeight: "2.1rem", fontSize: "12px" }}
              value={PONo}
              onChange={(e) => setPONo(e.target.value)}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            >
              <option hidden>Select PO</option>
              {purchaseOrderList?.map((po) => (
                <option key={po?.id} value={po?.purchase_order?.id}>
                  {po?.purchase_order?.po_no}
                </option>
              ))}
            </select>
            {PONoErr && <p className="err">Select PO</p>}
          </Col>
        )}
        {+projectType === 1 && (
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">PO Date</label>
            <p
              style={{
                minHeight: "2.1rem",
                width: "90%",
                lineHeight: "2.1rem",
                fontSize: "12px",
              }}
              className="input-select bg-lightBlue ms-0 ps-3"
            >
              {purchaseOrderDetails?.po_date}
            </p>
          </Col>
        )}

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Vendor Name</label>
          {/* <p
            style={{ minHeight: "2.1rem", width: "90%", lineHeight: "2.1rem" }}
            className="input-select bg-lightBlue ms-0 ps-3"
          >
            {purchaseOrderDetails?.vendor?.company_name}
          </p> */}
          <select
            style={{ minHeight: "2.1rem", fontSize: "12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => setVendor(e.target.value)}
            value={vendor}
            disabled={projectType == 1 ? true : false}
          >
            <option hidden>Select Vendor</option>
            {vendorList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.company_name}
              </option>
            ))}
          </select>
        </Col>

        {+projectType === 1 && (
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">MR No</label>
            <p
              style={{
                minHeight: "2.1rem",
                width: "90%",
                lineHeight: "2.1rem",
                fontSize: "12px",
              }}
              className="input-select bg-lightBlue ms-0 ps-3"
            >
              {/* {prNo} */}
              {purchaseOrderDetails?.material_request?.mr_no}
            </p>
          </Col>
        )}

        {+projectType === 1 && (
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">MR Date</label>
            <p
              style={{
                minHeight: "2.1rem",
                width: "90%",
                lineHeight: "2.1rem",
                fontSize: "12px",
              }}
              className="input-select bg-lightBlue ms-0 ps-3"
            >
              {/* {pRDDate} */}
              {purchaseOrderDetails?.material_request?.mr_date}
            </p>
          </Col>
        )}

        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Type</label>
          <p
            style={{ minHeight: "2.1rem", width: "90%", lineHeight: "2.1rem" }}
            className="input-select bg-lightBlue ms-0 ps-3">
            {purchaseOrderDetails?.vendor?.type ||
              purchaseOrderDetails?.mr?.material_type}
          </p>
        </Col> */}

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">DC No</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter DC No"
            onChange={(e) => setDCNo(e.target.value)}
            value={DCNo}
            style={{ fontSize: "12px" }}
          />
          {DCNo?.length == 0 && DCNoErr && <p className="err">Enter DC No. </p>}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">DC Date</label>
          <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Date"
            onChange={(e) => ginDateHandler(e.target.value, "dc")}
            value={DCDate}
            style={{ fontSize: "12px" }}
          />
          {DCDate?.length == 0 && DCDateErr && (
            <p className="err">enter DC date</p>
          )}
          {dateError?.includes("dc") && (
            <p className="err">Future Days Not Allowed</p>
          )}
        </Col>

        {/* {projectType === "PO" && ( */}
        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GRN No</label>
          <p
            style={{
              minHeight: "2.1rem",
              width: "90%",
              lineHeight: "2.1rem",
            }}
            className="input-select bg-lightBlue ms-0 ps-3"
          ></p>
        </Col> */}
        {/* )} */}

        {/* {projectType === "PO" && ( */}
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GRN Date</label>
          <input
            style={{ fontSize: "12px" }}
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Date"
            onChange={(e) =>
              filtered_date(
                e.target.value,
                purchaseOrderDetails?.po_date,
                projectType
              )
            }
            value={GRNDate}
          />
          {GRNDate?.length == 0 && GRNDateErr && (
            <p className="err">Enter GRN Date</p>
          )}
          {dateError?.includes("grn") && (
            <p className="err">Future Days Not Allowed</p>
          )}
        </Col>
        {/* )} */}
        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Invoice No.</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Invoice No"
            onChange={(e) => setInvoiceNo(e.target.value)}
            value={InvoiceNo} style={{fontSize:"12px"}}
          />
          {InvoiceNo?.length == 0 && InvoiceNoErr && (
            <p className="err">Enter Invoice No.</p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Invoice Date</label>
          <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Date"
            onChange={(e) => ginDateHandler(e.target.value, "invoice")}
            value={InvoiceDate} style={{fontSize:"12px"}}
          />
          {InvoiceDate?.length == 0 && InvoiceDateErr && (
            <p className="err">Enter Invoice Date</p>
          )}
          {dateError?.includes("invoice") && (
            <p className="err">Future Days Not Allowed</p>
          )}
        </Col> */}
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Ware House</label>
          <select
            className="input-select bg-lightBlue ms-0"
            style={{
              border: "2px solid #f1f7ff",
              width: "90%",
              marginRight: "auto",
              fontSize: "12px",
            }}
            onChange={(e) => setWareHouse(e.target.value)}
            value={wareHouse}
          >
            <option value={""} hidden>
              Select Warehouse
            </option>
            {warehouseList?.map((item, ind) => {
              return (
                <option value={item?.id} key={ind}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          {wareHouse?.length == 0 && wareHouseErr && (
            <p className="err">Select Warehouse</p>
          )}
        </Col>
        <Col
          xs={12}
          sm={6}
          xl={6}
          style={{ overflow: "hidden" }}
          className="inputinner-box text-start positon-relative"
        >
          <label className="w-100 my-1">Upload Document</label>
          <div
            style={{ overflowX: "hidden", width: "90%", minHeight: "2.1rem" }}
            className="d-flex ms-0 emply-mas-doc-box accordion-body bg-lightBlue"
          >
            <input
              type="file"
              onChange={(e) => setGrnFile(e.target.files[0])}
              className="border-0 px-1"
              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
              text/plain, application/pdf"
            />
            {/* {docError && <p className="err">Please Upload Document</p>}  */}
          </div>
        </Col>

        {/* <Col xs={12} sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={submitHandler}>
            Fetch
          </button>
        </Col> */}
      </Col>
    </Col>
  );
};

export default IsmGinForm;
