// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { tickIcon } from "../../assets/img";
import { api } from "../../constants/Services";

const FinanceTab = ({ roleResFinalSubmitHandler, checkBox, setCheckBox }) => {
  const years = ["2023 - 2024", "2024 - 2025"];
  const checkBoxHandler = (id) => {
    let checks = [...checkBox];

    if (checks.includes(id)) {
      let indx = checks.indexOf(id);
      checks.splice(indx, 1);
      setCheckBox(checks);
    } else {
      checks.push(id);
      setCheckBox(checks);
    }
  };

  // const roleResFinalSubmitHandler = () => {
  //   console.log("coming to function");
  //   let roles_final_data = {
  //     employee_id: "",
  //     permissions: [],
  //     finance: [],
  //   };
  //   // console.log("check", checkBox);
  //   // console.log("per", empPermissions);
  //   // console.log("emp_id", empId);
  //   if (checkBox.length > 0 && empPermissions.length > 0 && empId) {
  //     roles_final_data.employee_id = empId;
  //     roles_final_data.permissions = empPermissions;
  //     roles_final_data.finance = checkBox;

  //     api.employee_roles_permissions(roles_final_data).then((res) => {
  //       if (res.status === "success") {
  //         statusModalClose();
  //         setReset(true);
  //       }
  //       // console.log("roles-final-submit", res);
  //     });
  //   }
  // };
  return (
    <Col className="emply-master-form px-1 py-2 px-md-3 py-md-3 bg-white mt-3 text-center">
      <h4 className="mb-2">Finance</h4>
      <div className="d-flex flex-wrap justify-content-center">
        {years?.map((item, ind) => {
          return (
            <button
              onClick={() => checkBoxHandler(item)}
              key={ind}
              className="border-0 mx-2 my-2 cust-btn d-flex fs-12 align-items-center mb-2"
            >
              <div className="checkboxs me-2">
                {checkBox.includes(item) && (
                  <img src={tickIcon} alt="tick-icon" />
                )}
              </div>
              {item}
            </button>
          );
        })}
      </div>

      <button className="bg-gray1 border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2 me-2">
        Cancel
      </button>
      <button
        className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
        onClick={roleResFinalSubmitHandler}
      >
        Submit
      </button>
    </Col>
  );
};

export default FinanceTab;
