import React from "react";
import { Col } from "react-bootstrap";

const DprForm = () => {
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="ms-xl-0 w-95">DPR</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <p
            style={{ minHeight: "2rem", width: "90%", marginRight: "auto" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1">
            Crescon Projects and Service Pvt Ltd
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Date</label>
          <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter  Date"
          />
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Project Name"
          />
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">DPR No</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter DPR No"
          />
        </Col>

        <Col xs={12} sm={12} className="inputinner-box text-start">
          <button className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
            Submit
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default DprForm;
