import React, { useState, useEffect } from "react";

import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
import Header from "../../component/Header/Header";
import BoqAmendForm from "../../component/BoqAm/BoqAmendForm";
import BoqAmendTableSection from "../../component/BoqAm/BoqAmendTableSec";

const BoqAmendment = () => {
  const [projectList, setProjectList] = useState([]);
  const [boqId, setBoqId] = useState("");
  const [unChanged, setUnChanged] = useState();
  const [boqDetail, setBoqDetail] = useState();
  const [newly, setNewly] = useState();
  const [updated, setUpdated] = useState();
  const [deleted, setDeleted] = useState();
  const [projectDetails, setProjectDetails] = useState({
    boq_details: [],
    project: {},
    project_boq: {},
  });

  const [boqNonRequiredFields, setBoqNonRequiredFields] = useState({
    created_date: "",
    completion_date: "",
    previous_boq_no: "",
    loi_date: "",
  });
  const [boqFormDetails, setBoqFormDetails] = useState({
    project_id: "",
    boq_no: "",
    import_excel: "",
  });

  useEffect(() => {
    api.project_list().then((res) => {
      // console.log("project-res-list", res);
      if (res?.status === "success") {
        setProjectList(res?.projects);
      }
    });
  }, []);

  useEffect(() => {
    if (boqFormDetails.project_id) {
      // console.log(
      //   "🚀 ~ file: BoqCreation.js:34 ~ useEffect ~ boqFormDetails.project_id:",
      //   boqFormDetails.project_id
      // );
      api.project_show(boqFormDetails.project_id).then((res) => {
        console.log("prpoject-details", res);
        if (res?.status === "success") {
          setProjectDetails({
            ...projectDetails,
            boq_details: res?.boq_details,
          });
          setBoqId(res?.project?.id);
          setBoqNonRequiredFields({
            created_date: res?.project_boq?.updated_at,
            completion_date: res?.project?.end_date,
            previous_boq_no: res?.project_boq?.boq_no,
            loi_date: res?.project?.loi_date,
          });
        }
      });
      api.project_compare(boqFormDetails.project_id).then((res) => {
        // console.log("prpoject-detailss", res?.first_boq_details);
        // console.log("prpoject-result", res?.result);
        // console.log(
        //   "prpoject-result",
        //   res?.result?.status == "unchanged" && res?.first_boq_details
        // );
        setBoqDetail(res?.result);
        setUnChanged(res?.first_boq_details);
        // setNewly(res?.result?.result == "newly_added");
        // setBoqDetail(res?.result);
        // setBoqDetail(res?.result);
      });
    }
  }, [boqFormDetails.project_id]);

  return (
    <Col xs={12} className="py-2 px-1 boq-cont-res p-md-3">
      <Header />
      <BoqAmendForm
        boqId={boqId}
        boqNonRequiredFields={boqNonRequiredFields}
        setBoqNonRequiredFields={setBoqNonRequiredFields}
        projectList={projectList}
        boqFormDetails={boqFormDetails}
        setBoqFormDetails={setBoqFormDetails}
      />
      <BoqAmendTableSection
        boqDetail={boqDetail}
        unChanged={unChanged}
        boq_details={projectDetails.boq_details}
      />
      <Col sm={12} className="inputinner-box text-start">
        <button
          className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
          onClick={() => window.location.reload()}
        >
          Close
        </button>
      </Col>
    </Col>
  );
};

export default BoqAmendment;
