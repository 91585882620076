import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
import Select from "react-select";

const EmployeeMasterForm = ({ setEmployeeDetail, reset }) => {
  const [error, setError] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [document, setDocument] = useState();
  const [departmentList, setDepartmentList] = useState([]);
  const [subDepartmentList, setSubDepartmentList] = useState([]);
  const [designationList, setDesinationList] = useState([]);
  const [empPhoto, setEmpPhoto] = useState();
  const [employeeDepartment, setEmployeeDepartment] = useState("");
  const [subEmployeeDepartment, setSubEmployeeDepartment] = useState([]);
  const [employeeDesination, setEmployeeDesination] = useState("");

  const subDepartment = [
    "ELECTRICAL",
    "CIVIL",
    "MECHANICAL",
    "HVAC",
    "FIRE FIGHTING",
    "ELV",
    "OTHERS",
    "PHE",
    "PROCESS PIPE",
    "SOLVENT PIPE",
    "ASSETS",
  ];

  useEffect(() => {
    let temp = [];

    subDepartment?.map((item, ind) => {
      temp.push({ value: item, label: item });
    });

    setSubDepartmentList(temp);
  }, []);

  // console.log("subDepartmentList", subEmployeeDepartment);
  const employeeDepartmentHandler = (e) => {
    setEmployeeDepartment(+e.target.value);
  };

  const employeeNameHandler = (e) => {
    setEmployeeName(e.target.value.replace(/[^\sa-z]/gi, ""));
  };

  const employeeDesinationHandler = (e) => {
    setEmployeeDesination(+e.target.value);
  };

  const addEmployee = (data) => {
    api.employee_add(data).then((res) => {
      if (res?.status === "success") {
        setEmployeeDetail(res?.employee);
        // console.log("emp_add", res);
        // setEmployeeName("");
        // setDepartmentList([]);
        // setDesinationList([]);
        // setEmpPhoto();
      }
    });
  };
  const employeeAddHandler = () => {
    let formdata = new FormData();

    formdata.append("name", employeeName);
    formdata.append("designation", employeeDesination);
    formdata.append("department", employeeDepartment);
    if (empPhoto) {
      formdata.append("image", empPhoto);
    }

    formdata.append("employee_level", 0);

    subEmployeeDepartment?.map((item, ind) => {
      formdata.append(`material_departments[${ind}]`, item?.value);
    });

    let data = {
      name: employeeName,
      designation: employeeDesination,
      department: employeeDepartment,
    };

    if (empPhoto) {
      data.image = empPhoto;
    }

    let temp = [];
    Object.entries(data).forEach((item) => {
      if (item[1] === "") {
        temp.push(item[0]);
      }
    });
    setError(temp);
    // console.log("error", error);
    if (error?.length === 0) {
      addEmployee(formdata);
    }
  };

  useEffect(() => {
    api.employee_create_data().then((res) => {
      if (res?.status === "success") {
        setDepartmentList(res?.departments);
        setDesinationList(res?.designations);
      }
    });

    if (reset) {
      setEmployeeName("");
      setEmployeeDepartment("");
      setEmployeeDesination("");
    }
  }, [reset]);

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="ms-xl-0 w-95">Employee Master</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Name<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter your Name"
            value={employeeName}
            onChange={employeeNameHandler}
          />
          {error?.includes("name") && <p className="err">Enter Name</p>}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Department<span className="err">*</span>
          </label>
          <select
            value={employeeDepartment}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={employeeDepartmentHandler}
          >
            <option value="not-value">Select Department</option>
            {departmentList &&
              departmentList?.map((department) => (
                <option key={department?.id} value={department?.id}>
                  {department?.name}
                </option>
              ))}
          </select>
          {error?.includes("department") && (
            <p className="err">Select Department</p>
          )}
        </Col>
        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Sub Department<span className="err">*</span>
          </label>
          <Select
            options={subDepartmentList}
            isMulti
            onChange={(e) => setSubEmployeeDepartment(e)}
            value={subEmployeeDepartment}
            className=" border-0 emply-mas-file-input px-1 h-50"
          /> */}
        {/* {error?.includes("department") && (
            <p className="err">Select Department</p>
          )} */}
        {/* </Col> */}

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Designation<span className="err">*</span>
          </label>
          <select
            value={employeeDesination}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={employeeDesinationHandler}
          >
            <option value={null}>Select Designation</option>
            {designationList &&
              designationList?.map((designation) => (
                <option key={designation?.id} value={designation?.id}>
                  {designation?.name}
                </option>
              ))}
          </select>
          {error?.includes("designation") && (
            <p className="err">Select Designation</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Employee Photo</label>
          <div className="d-sm-flex mt-1 w-90 ms-0 emply-mas-file-box bg-lightBlue position-relative">
            <p className="bg-lightBlue border-0 emp-mas-form-img-text-box">
              {empPhoto ? empPhoto?.name : "Upload Photo"}
            </p>
            <input
              type="file"
              name="test"
              onChange={(e) => setEmpPhoto(e.target.files[0])}
              className=" border-0 emply-mas-file-input px-1"
            />
          </div>
        </Col>
        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Employee Document</label>
          <div className="d-sm-flex mt-1 w-90 ms-0 emply-mas-file-box bg-lightBlue position-relative">
            <p className="bg-lightBlue border-0 emp-mas-form-img-text-box">
              {document ? document?.name : "Upload Document"}
            </p>
            <input
              type="file"
              name="test"
              onChange={(e) => setDocument(e.target.files[0])}
              className=" border-0 emply-mas-file-input px-1"
            />
          </div>
        </Col> */}
        <Col xs={12} sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={employeeAddHandler}
          >
            Submit
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default EmployeeMasterForm;
