import React, { useState, useEffect } from "react";
import eye_icon from "../../assets/icons/common/Eye.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { Col, Modal, Button } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";
import { PaginationControl } from "react-bootstrap-pagination-control";

function InternalReceiptList() {
  const [selectedProject, setSelectedProject] = useState("");
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [projectList, setProjectList] = useState([]);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // PROJECT LISTS
  const getProjects = () => {
    api
      .project_list()
      .then((res) => {
        setProjectList(res?.projects);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // INTERN RETURN LIST
  const getList = (id, page) => {
    let obj = {
      project_id: id,
    };

    let param = `?page=${page}`;
    api
      .internReturnList(param, obj)
      .then((res) => {
        let temp = [];
        res?.internal_returns?.map((item, ind) => {
          item?.internal_return_detail?.map((intenal, inx) => {
            let obj = {
              ...intenal,
              ...item,
            };

            delete obj?.internal_return_detail;

            temp.push(obj);
          });
        });

        console.log("temp", temp);

        setList({ internal_returns: temp, pagination: res?.pagination });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(()=>{
    if(searchParams?.get("projectID")){
      setSelectedProject(searchParams?.get("projectID"))
      getList(searchParams?.get("projectID"),1);
    }
  }, [searchParams?.get("projectID")]);
  // useEffect(() => {
  //   getList();
  // }, [selectedProject]);

  const materialTransferDetailHandler = (sel_mt, mt_colde, mt_date) => {
    navigate("/ism/output", {
      state: { id: sel_mt, type: "internal" },
    });
  };

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white my-3 text-center">
        <header>
          <Col xs={12} className="d-flex my-2">
            <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 ms-auto pointer"
              value={selectedProject}
              onChange={(e) => {
                setSelectedProject(e.target.value);
                getList(e.target.value, 1);
                setPage(1);
              }}
            >
              <option value={""}>Select Project</option>
              {projectList?.map((project) => (
                <option key={project?.id} value={project?.id}>
                  {project?.name}
                </option>
              ))}
            </select>
          </Col>
        </header>
        {/* <h4 className="my-1 py-1">Internal Receipt List</h4> */}
        <h4 className="my-1 py-1">Internal Return List</h4>
        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Return Slip No</th>
                <th>Material Code</th>
                {/* <th>Material Description</th> */}
                <th>UOM</th>
                <th>Type</th>
                <th>Issued Quantity </th>
                <th>Return Quantity </th>
                {/* <th>Issued By </th> */}
                <th>Created By </th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {list?.internal_returns?.map((material, ind) => (
                <tr>
                  <td>{ind + 1}</td>
                  <td>{material?.return_slip_no}</td>
                  <td>
                    {material?.material_issue_detail?.material?.material_code}
                  </td>
                  <td>{material?.material_issue_detail?.material?.uom}</td>
                  <td>{material?.type == 1 ? "Returnable" : "Excess Qty"}</td>
                  <td>{material?.material_issue_detail?.issued_qty}</td>
                  <td>
                    {
                      // material?.available_stock
                      material?.return_qty
                    }
                  </td>
                  <td>{material?.employee?.name}</td>
                  <td>
                    {material?.status == 1
                      ? "Pending"
                      : material?.status == 2
                      ? "Accepted"
                      : "Rejected"}
                  </td>
                  <td>
                    <div className="d-flex">
                    <Link to={`/ism/output?id=${material?.id
                              }&projectID=${+selectedProject}&type=internal`}
                          >
                      <img
                        onClick={() =>
                          materialTransferDetailHandler(
                            material?.id,
                            material?.po_no,
                            material?.po_date
                          )
                        }
                        style={{ width: "20px" }}
                        className="pointer ms-2"
                        src={eye_icon}
                        alt="eye-icon"
                      />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="my-1 py-1 d-flex justify-content-end">
          <PaginationControl
            page={page}
            between={4}
            total={list?.pagination?.total}
            limit={10}
            changePage={(page) => {
              setPage(page);
              console.log(page);
            }}
            ellipsis={1}
          />
        </div>
      </Col>
      <Modal
        size="lg"
        className="h-75"
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        centered
      >
        <Modal.Header closeButton className="border-0 d-flex">
          <Modal.Title className="text-center mx-auto">GRN PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src="https://arxiv.org/pdf/quant-ph/0410100.pdf"
            title="Sample Pdf"
            className="w-90"
          ></iframe>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={() => setShowModal(!showModal)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}

export default InternalReceiptList;
