import React from "react";
import { Col } from "react-bootstrap";
import Header from "../Header/Header";

const Home = () => {
  return (
    <Col>
      <Header />
    </Col>
  );
};

export default Home;
