// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { loginTop } from "../../assets/img";
import { api } from "../../constants/Services";
import { department, fYear, Projects } from "../../constants/StaticData";

const RightRegister = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [project, setProjects] = useState();

  const submitHandler = () => {
    navigate("/hrm/employee-master");
  };

  const getProject = () => {
    api.employeeProjects().then((res) => {
      setList(res?.projects);
      console.log(res);
    });
  };

  const submitHandlers = () => {
    let data = {
      project_id: project,
    };
    api.employeeProfileSelect(data).then((res) => {});
  };

  useEffect(() => {
    getProject();
  }, []);

  return (
    <Col xs={12} md={7} className="d-flex ac-jc h-100 rit-login">
      <div className="text-center w-100 d-block">
        <div className="login-logo-box">
          <img src={loginTop} alt="login-top" />
        </div>
        <div className="bg-white login-box">
          <h1>Roles & Responsibility</h1>
          <div className="inputinner-box text-start">
            <label>Department</label>
            <Select className="input-select" options={department} />
          </div>
          <div className="inputinner-box text-start">
            <label>Roles</label>
            <input className="input-select1" />
          </div>
          <div className="inputinner-box text-start">
            <label>Projects</label>
            {/* <Select className="input-select" options={Projects} /> */}
            <select
              className="input-select"
              style={{ borderColor: "hsl(0, 0%, 80%)" }}
              onChange={(e) => setProjects(e.target.validity)}
            >
              <option>select project</option>
              {list?.map((item, ind) => (
                <option value={item?.id} key={ind}>
                  {item?.title}
                </option>
              ))}
            </select>
          </div>
          <div className="inputinner-box text-start">
            <label>Financial Year</label>
            <Select className="input-select" options={fYear} />
          </div>
          <button onClick={submitHandler} className="bg-darkBlue">
            Continue
          </button>
        </div>
      </div>
    </Col>
  );
};

export default RightRegister;
