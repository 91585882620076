import React from "react";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import EmployeeManagementComponent from "../../component/EmployeeManagement/EmployeeManagementComponent";

const EmployeeManagementscreen = () => {
  return (
    <Col xs={12} className="p-3">
      <Header />
      <EmployeeManagementComponent />
    </Col>
  );
};

export default EmployeeManagementscreen;
