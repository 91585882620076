import React, { useState, useEffect } from "react";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useNavigate } from "react-router-dom";
import eye_icon from "../../assets/icons/common/Eye.svg";
import edit_icon from "../../assets/icons/common/Edit.svg";
import moment from "moment";

import { Col, Modal, Button } from "react-bootstrap";
import { api } from "../../constants/Services";
import { edit } from "../../assets/img";

const EmployeeManagementComponent = () => {
  const navigate = useNavigate();
  const [employeeList, setEmployeeList] = useState([]);
  const [empsearchList, setEmpSearchList] = useState([]);
  const [resDate, setResDate] = useState(moment().format("DD MMMM yyy"));
  const [empId, setEmpId] = useState("");
  const [show, setShow] = useState(false);

  // emp_resignation

  const employeeSearchHandler = (searched_target) => {
    let filteredList = employeeList?.filter((emp) =>
      emp?.name.toLowerCase().includes(searched_target.toLowerCase())
    );
    setEmpSearchList(filteredList);
  };

  const getEmployeesList = () => {
    api.employees_list().then((res) => {
      // console.log("employees_list", res);
      if (res?.status === "success") {
        setEmployeeList(res?.employees);
        setEmpSearchList(res?.employees);
        setShow(false);
      }
    });
  };

  useEffect(() => {
    getEmployeesList();
  }, []);

  //   console.log("emp_list", employeeList);
  const empResignationHandler = () => {
    let formData = new FormData();
    formData.append("resignation_date", resDate);
    api.emp_resignation(empId, formData).then((res) => {
      console.log("resignation-emp-res", res);
      if (res?.status === "success") {
        getEmployeesList();
      }
    });
  };

  const empStatusHandler = (id) => {
    setShow(!show);
    setEmpId(+id);
  };

  return (
    <>
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <h4 className="my-1 py-1">Employee Management</h4>
        <input
          className="border-0 bg-lightBlue px-2 py-1 w-50 mt-1"
          placeholder="Search...,"
          onChange={(e) => employeeSearchHandler(e.target.value)}
        />
        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Emp. Name</th>
                <th>Emp. ID</th>
                <th>Role</th>
                <th>Date of Join</th>
                <th>Date of Resignation</th>
                <th>Action</th>
                <th>status</th>
              </tr>
            </thead>
            <tbody>
              {empsearchList.length > 0 &&
                empsearchList?.map((employee, ind) => {
                  return (
                    <tr key={employee?.id}>
                      <td>{ind + 1}</td>
                      <td>{employee?.name}</td>
                      <td>{employee?.emp_id}</td>
                      <td>{employee?.role}</td>
                      <td>{employee?.doj}</td>
                      <td>{employee?.resignation_date}</td>
                      <td style={{ padding: "2px" }}>
                        <div className="pointer-1">
                          <img
                            className="pointer"
                            style={{ width: "20px", marginRight: "10px" }}
                            src={eye_icon}
                            alt="eye-icon"
                            onClick={() =>
                              navigate("/hrm/employee-view", {
                                state: { id: employee?.id },
                              })
                            }
                          />
                          <img
                            class="pointer ms-1"
                            src={edit_icon}
                            alt="eye-icon"
                            style={{ width: "20px" }}
                            onClick={() =>
                              navigate("/hrm/employee-edit", {
                                state: { id: employee?.id },
                              })
                            }
                          />
                        </div>
                      </td>

                      <td className="emp-manage-toggle-switch-box">
                        {/* <p>{employee?.status}</p> */}

                        {employee?.status === 4 ? (
                          <BootstrapSwitchButton
                            onlabel="Active"
                            offlabel="InActive"
                            onstyle="success"
                            offstyle="danger"
                            disabled
                            width={100}
                          />
                        ) : (
                          <BootstrapSwitchButton
                            key={employee?.id}
                            name={employee?.id}
                            onChange={() => empStatusHandler(employee?.id)}
                            onlabel="Active"
                            onstyle="success"
                            checked={employee?.status !== 4}
                            offstyle="danger"
                            width={100}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Col>
      <Modal show={show} onHide={() => setShow(!show)} centered>
        <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
        <Modal.Body>
          <Col xs={12} className="inputinner-box text-start d-flex flex-column">
            <label className="w-100 text-center fs-5 my-1 py-1">
              Date of Resignation
            </label>
            <input
              type="date"
              value={resDate}
              onChange={(e) => setResDate(e.target.value)}
              className="border-0 bg-lightBlue px-2 py-1 w-50 mx-auto my-2"
              placeholder="Date of Resignation"
            />
          </Col>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={() => setShow(!show)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={empResignationHandler}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmployeeManagementComponent;
