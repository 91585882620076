import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";
import { api } from "../../constants/Services";
import { sassFalse } from "sass";

const VendorMasterForm = ({
  companyName,
  setCompanyName,
  // supplierName,
  // setSupplierName,
  setProdectType,
  prodectType,
  vendorType,
  setVendorType,
  subVendorType,
  setSubVendorType,
}) => {
  const [supplierNameErr, setSupplierNameErr] = useState(false);
  const [prodectTypeErr, setProdectTypeErr] = useState(false);

  const [vendorTypeErr, setVendorTypeErr] = useState(false);

  const [subVendorTypeErr, setSubVendorTypeErr] = useState(false);

  const [uploads, setUpload] = useState();

  const uploadFile = () => {
    let data = {
      import_excel: uploads,
    };
    if (uploads) {
      api.vendorImport(data).then((res) => {
        alert(res?.message);
        setUpload("");
      });
    } else {
      alert("Please Upload File");
    }
  };

  const onChangeVendorHandler = (e) => {
    setVendorType(e.target.value);
    if (vendorType?.length > 0) {
      setVendorTypeErr(false);
    }
  };

  const onChangeSubVendorHandler = (e) => {
    setSubVendorType(e.target.value);
    if (subVendorType?.length > 0) {
      setSubVendorTypeErr(false);
    }
  };

  console.log("setVendorType :", vendorType);
  return (
    <Col className="emply-master-form px-3 py-2 py-md-3 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">Vendor Master</h4>
      <Col className="d-flex flex-wrap mt-3">
        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Name</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter your Name"
            onChange={(e) => setName(e.target.value.replace(/[^\sa-z]/gi, ""))}
            value={name}
          />
          {name?.length == 0 && nameErr && <p className="err">Enter Name</p>}
        </Col> */}
        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Supplier Type</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter your Supplier Type"
            onChange={(e) => setSupplierType(e.target.value)}
            value={supplierType}
          />
          {supplierType?.length == 0 && supplierTypeErr && (
            <p className="err">Enter Name</p>
          )}
        </Col> */}
        {/* http://localhost/cresscon/public/excel/vendor-sample-cresscon.xlsx  */}

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Company Name</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter your Supplier Name"
            onChange={(e) =>
              setCompanyName(e.target.value.replace(/[^\sa-z]/gi, ""))
            }
            value={companyName}
          />
          {companyName?.length == 0 && supplierNameErr && (
            <p className="err">Enter Company Name</p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Product Type</label>
          <div
            style={{ width: "90%" }}
            className="finance-box text-start m-auto ms-0 w-90">
            <select
              className="input-select bg-lightBlue ms-0"
              style={{ border: "2px solid #f1f7ff" }}
              onChange={(e) => setProdectType(e.target.value)}
              value={prodectType}>
              <option value={""}>Select product type</option>
              <option value={"ELECTRICAL"}>ELECTRICAL</option>
              <option value={"CIVIL"}>CIVIL</option>
              <option value={"MECHANICAL"}>MECHANICAL</option>
              <option value={"HVAC"}>HVAC</option>
              <option value={"FIRE FIGHTING"}>FIRE FIGHTING</option>
              <option value={"ELV"}>ELV</option>
              <option value={"OTHERS"}>OTHERS</option>
              <option value={"PHE"}>PHE</option>
              <option value={"PROCESS PIPE"}>PROCESS PIPE</option>
              <option value={"SOLVENT PIPE"}>SOLVENT PIPE</option>
            </select>
            {prodectType?.length == 0 && prodectTypeErr && (
              <p className="err">select product type</p>
            )}
          </div>
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Vendor Type</label>
          <select
            name=""
            id=""
            onChange={(e) => onChangeVendorHandler(e)}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1">
            <option value="">Choose vendor type</option>
            <option value="Creditor">Creditor</option>
            <option value="Debitor">Debitor</option>
          </select>
          {vendorType?.length == 0 && vendorTypeErr && (
            <p className="err">select vendor type</p>
          )}
        </Col>
        {vendorType === "Debitor" && (
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Vendor Sub Type</label>
            <input
              type="text"
              disabled
              value="Debitor"
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            />
          </Col>
        )}
        {vendorType === "Creditor" && (
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Vendor Sub Type</label>
            {vendorType === "Debitor" && (
              <input
                type="text"
                disabled
                value="Debitor"
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              />
            )}

            {vendorType === "Creditor" && (
              <select
                name=""
                id=""
                onChange={(e) => onChangeSubVendorHandler(e)}
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1">
                <option value="">Choose vendor sub type</option>
                <option value="Vendor Material">Vendor Material</option>
                <option value="Vendor Bulk">Vendor Bulk</option>
                <option value="Machinery Hire">Machinery Hire</option>
                <option value="Sub-Contract">Sub-Contract</option>
                <option value="Technical Consultant">
                  Technical Consultant
                </option>
                <option value="Sub-Contract">Services Rent</option>
              </select>
            )}
            {subVendorType?.length == 0 && subVendorTypeErr && (
              <p className="err">select vendor sub type</p>
            )}
          </Col>
        )}

        <Col xs={12} className="inputinner-box text-start">
          <div className="d-flex justify-content-end my-3">
            <a
              href="http://erp-script.cresconprojects.com/public/excel/vendor-sample-cresscon.xlsx"
              target="_blank"
              rel="noreferrer"
              className="bg-primar border-0 rounded-1 text-white f-1 me-auto fs-14 px-4 py-2 pointer">
              Sample Download
            </a>
            <div class="input-group  w-50">
              <input
                type="file"
                class="form-control border-0 bg-lightBlue w-90"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                onChange={(e) => setUpload(e.target.files[0])}
                aria-describedby="button-addon2"
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={uploadFile}>
                Upload
              </button>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          {/* <label className="w-100">Supplier Code</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter your Supplier Code"
            onChange={(e) => setSupplierCode(e.target.value)}
            value={supplierCode}
          />
          {supplierCode?.length == 0 && supplierCodeErr && (
            <p className="err">Enter Name</p>
          )} */}
        </Col>

        {/* <Col xs={12} sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={submit}>
            Submit
          </button>
        </Col> */}
      </Col>
    </Col>
  );
};

export default VendorMasterForm;
