import React from "react";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import DprTableSection from "../../component/Dpr/DprTableSec";
import DprForm from "../../component/Dpr/DprForm";

const Dpr = () => {
  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <DprForm />
      <DprTableSection />
    </Col>
  );
};

export default Dpr;
