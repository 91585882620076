import React from "react";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import AssetsMasterForm from "../../component/AssetsMaster/AssetsMasterForm";
import AssetsMasterLevl from "../../component/AssetsMaster/AssetMaterLastSection";

const AssetsMasterScreen = () => {
  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <AssetsMasterForm />
      {/* <AssetsMasterLevl />  */}
    </Col>
  );
};

export default AssetsMasterScreen;
