import React from "react";
import Chart from "react-apexcharts";
import { Card, Col, Row } from "react-bootstrap";
import { dashImage } from "../assets/img";
import BillingBudget from "../component/dashboard/BillingBudget";
import Header from "../component/dashboard/Header";
import EmployeeProgressBar from "../component/dashboard/EmployeeProgressBar";
import ProjectProgressBar from "../component/dashboard/ProjectProgressBar";
import RevenueChart from "../component/dashboard/RevenueChart";

const DashBoard = () => {
  // PIE CHARTS

  // const customChart = {
  //   height: 150,
  //   type: "donut",
  //   options: {
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     plotOptions: {
  //       pie: {
  //         donut: {
  //           size: "75%",
  //         },
  //       },
  //     },
  //     labels: [
  //       "Employess Joined",
  //       "Employess Releaving",
  //       "Employess having Access",
  //     ],
  //     legend: {
  //       show: false,
  //     },
  //     tooltip: {
  //       theme: "datk",
  //     },
  //     grid: {
  //       padding: {
  //         top: 20,
  //         right: 0,
  //         bottom: 0,
  //         left: 0,
  //       },
  //     },
  //     colors: ["#008000", "#F7EC07", "#2575F1"],
  //     fill: {
  //       opacity: [1, 1, 1],
  //     },
  //     stroke: {
  //       width: 0,
  //     },
  //   },
  //   series: [1, 2, 3],
  // };

  return (
    <Col xs={12} className="">
      {/* <img src={dashImage} className="px-3" /> */}

      <Header />
      <div className="d-flex flex-wrap">
        <Col xs={12} md={5} className="">
          <EmployeeProgressBar />
        </Col>
        <Col xs={12} md={7}>
          <BillingBudget />
        </Col>
      </div>
      <div className="d-flex flex-wrap my-3">
        <Col xs={12} md={5} className="">
          <ProjectProgressBar />
        </Col>
        <Col xs={12} md={7}>
          <RevenueChart />
        </Col>
      </div>
    </Col>
    // <React.Fragment>
    //   <Row>
    //     <Col sm={12} style={{ overflowX: "hidden" }}>
    //       <Card className="mt-2">
    //         <Card.Body>
    //           <div>
    //             <p>Statistics Card </p>
    //             <p>Monthly/Yearly Statistics</p>
    //           </div>
    //         </Card.Body>
    //       </Card>
    //     </Col>
    //     <Col sm={5}>
    //       <Card>
    //         <Card.Body>
    //           <Row>
    //             <Col className="text-right font-weight-bold">
    //               Total No.of Employess
    //             </Col>
    //           </Row>
    //           <Chart {...customChart} />
    //           <Row className="mt-3 text-center">
    //             <Col sm={12}>
    //               <h3 className="m-0">
    //                 <i className="fas fa-circle f-10 m-r-5 text-success" />
    //               </h3>
    //               <span className="ml-3">Employess Joined</span>
    //             </Col>
    //             <Col sm={12}>
    //               <h3 className="m-0">
    //                 <i className="fas fa-circle text-warning f-10 m-r-5" />
    //               </h3>
    //               <span className="ml-3">Employess Releaving</span>
    //             </Col>
    //             <Col sm={12}>
    //               <h3 className="m-0">
    //                 <i className="fas fa-circle text-warning f-10 m-r-5" />
    //               </h3>
    //               <span className="ml-3">Employess having Access</span>
    //             </Col>
    //           </Row>
    //         </Card.Body>
    //       </Card>
    //     </Col>
    //   </Row>
    // </React.Fragment>
  );
};

export default DashBoard;
