// import MaterialRequestAmendTable from "./MaterialRequestAmendTable";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import StatusModal from "../StatusModal";
import { api } from "../../constants/Services";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function MaterialRequestAmendmentTableSection({
  currentTableDetails,
  setCurrentTableDetails,
  finalDetails,
}) {
  console.log("final-table", currentTableDetails);
  const [reqValErr, setReqValErr] = useState([]);
  const [IndentValue, setIndentValue] = useState([]);
  const [requiredValue, setRequiredValue] = useState({});
  const [allowableValue, setAllowableValue] = useState({});
  const [disabled, setDisabled] = useState(false);
  // console.log(
  // "🚀 ~ file: MaterialRequestAmendmentTableSection.js:16 ~ requiredValue:",
  // requiredValue
  // );
  const [showStatus, setShowStatus] = useState(false);
  const statusModalClose = () => {
    setShowStatus(!showStatus);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    let temp = {};
    currentTableDetails?.material_request_details?.map((item, ind) => {
      temp = {
        ...temp,
        [item?.id]: +item?.required_quantity,
      };
    });
    // console.log(
    // "🚀 ~ file: MaterialRequestAmendmentTableSection.js:27 ~ useEffect ~ temp :",
    // temp
    // );
    // setRequiredValue(temp);
  }, []);

  const requiredValueHandler = (
    id,
    avaliable_qty,
    prev_purchase,
    typed_value,
    Indenting_qty,
    mr_quantity
  ) => {
    let calulated_qty = avaliable_qty - prev_purchase;
    let value = typed_value.replace(/[^\s0-9.]/gi, "");

    setRequiredValue({
      ...requiredValue,
      ...id,
      [id]: value,
    });

    // setAllowableValue({
    //   ...allowableValue,
    //   [id]: value,
    // });

    if (+typed_value == 0) {
      setDisabled(true);
    } else if (avaliable_qty !== "") {
      // if (+typed_value < +avaliable_qty || +typed_value == +avaliable_qty) {
      //   let ind_val = +avaliable_qty - +typed_value;
      if (+typed_value > +mr_quantity) {
        // if (+typed_value > +avaliable_qty) { // viswa 06-04-2024
        console.log("ee");
        setDisabled(true);
        setReqValErr([...reqValErr, id]);
      } else {
        setIndentValue({
          ...IndentValue,
          [id]: +typed_value,
        });
        reqValErr.splice(reqValErr.indexOf(id), 1);
        setReqValErr(reqValErr);
      }
    }

    // if (avaliable_qty !== "") {
    //   if (+typed_value < +avaliable_qty || +typed_value == +avaliable_qty) {
    //     let ind_val = +avaliable_qty - +typed_value;
    //     setIndentValue({
    //       ...IndentValue,
    //       [id]: ind_val,
    //     });

    //     reqValErr.splice(reqValErr.indexOf(id), 1);
    //     setReqValErr(reqValErr);
    //   } else {
    //     setReqValErr([...reqValErr, id]);
    //   }
    // }
  };

  const mrRequestActionHandler = () => {
    let formdata = new FormData();

    currentTableDetails?.material_request_details?.map((item, index) => {
      formdata.append(`details[${index}][boq_code]`, item?.boq_code ?? "");
      formdata.append(
        `details[${index}][material_id]`,
        item?.material_id ?? ""
      );
      formdata.append(
        `details[${index}][required_quantity]`,
        requiredValue[item?.id] ?? 0
      );
      formdata.append(`details[${index}][remarks]`, item?.remarks ?? "");
    });
    formdata.append("project_id", currentTableDetails?.project_id);
    formdata.append("head_office", currentTableDetails?.head_office);
    formdata.append("request_type", currentTableDetails?.request_type);
    formdata.append("department", currentTableDetails?.department);
    formdata.append("mr_no", currentTableDetails?.mr_no);
    formdata.append("mr_date", currentTableDetails?.mr_date);
    formdata.append("company_id", currentTableDetails?.company_id);
    formdata.append(
      "parent_id",
      currentTableDetails?.material_request_details[0]?.material_request_id ??
        ""
    );

    api.material_request_store(formdata).then((res) => {
      if (res?.status == "success") {
        // alert(res?.message);
        statusModalClose();
        // window.location.reload();
      } else {
        console.log("marter-requ-final-store-api", res);
      }
    });
  };

  console.log(
    "currentTableDetails?.material_request_details",
    currentTableDetails,
    currentTableDetails?.material_request_details
  );

  return (
    <Col className="emply-master-form p-3 my-3 bg-white">
      <Col
        xs={10}
        className="emply-master-form pe-3 py-2 py-md-4 bg-white mt-3 text-center"
      >
        <div style={{ overflowX: "scroll" }} className="user-mange-table">
          <table>
            <thead>
              <tr>
                <th className="nowrap">S.no</th>
                <th className="nowrap" style={{ minWidth: "110px" }}>
                  BOQ Code
                </th>
                <th className="nowrap">Material Code</th>
                <th className="nowrap">
                  <p style={{ width: "150px" }}>Material Name</p>
                </th>
                {/* <th>Materi Type</th>  */}
                {/* <th>Material Group</th> */}
                {/* <th style={{ minWidth: "510px" }}>Material Description</th>  */}
                <th className="nowrap">UOM</th>
                <th className="nowrap">Previouly Purchased</th>
                <th className="nowrap">Cumulative Qty</th>
                <th className="nowrap">Required Qty</th>
                <th className="nowrap">Stock Qty</th>
                <th className="nowrap">Allowable Qty</th>
                <th className="nowrap">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {currentTableDetails?.material_request_details?.map(
                (item, ind) => {
                  return (
                    <tr key={ind}>
                      <td>0{ind + 1}</td>
                      <td>{item?.boq_code}</td>
                      <td style={{ width: "500px" }}>
                        {item?.material?.material_code}
                      </td>
                      <td>{item?.material?.name}</td>
                      {/* <td>{currentTableDetails?.material_type}</td>  */}
                      {/* <td>{item?.material?.group}</td> */}
                      <td>{item?.uom}</td>
                      <td className=" text-center">
                        {item.previously_purchased}
                      </td>
                      <td className=" text-center">
                        {item?.cumulative_quantity}
                      </td>
                      <td>
                        <input
                          className="border-0 w-100 text-center"
                          value={requiredValue[item?.id] || ""}
                          onChange={(e) =>
                            requiredValueHandler(
                              item?.id,
                              item?.total_quantity,
                              item.purchased_qty,
                              e.target.value,
                              +item?.qty - +item?.approved_po_qty
                                ? +item?.qty - +item?.approved_po_qty
                                : 0,
                              // +item?.mr_quantity
                              +item?.mr_quantity < +item?.cumulative_quantity
                                ? Math.abs(
                                    +item?.cumulative_quantity - +item?.qty
                                  )
                                : item?.mr_quantity
                            )
                          }
                        />
                        {/* <p className="err">temp showing 0 till inv stock finish</p> */}
                        {currentTableDetails?.request_type == 1 &&
                          reqValErr.includes(item?.id) && (
                            <p className="err text-nowrap">
                              More than Avaliable Qty
                            </p>
                          )}
                      </td>{" "}
                      {/* <td>{item?.total_quantity}</td>    10-04-2024*/}
                      <td className=" text-center">{item?.stock_quantity}</td>
                      <td className=" text-center">
                        {/* <p>{allowableValue[item?.id] ?? ""}</p> */}
                        {/* {+item?.qty - +item?.approved_po_qty
                          ? +item?.qty - +item?.approved_po_qty
                          : "0"} */}
                        {/* {item?.mr_quantity} */}
                        {+item?.mr_quantity < +item?.cumulative_quantity
                          ? Math.abs(+item?.cumulative_quantity - +item?.qty)
                          : item?.mr_quantity}
                      </td>
                      <td className=" text-center">{item?.remarks}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
        {/* <MaterialRequestTable
        final_table_details={final_table_details}
        setCurrentTableDetails={setCurrentTableDetails}
      /> */}
        <Col sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            disabled={
              currentTableDetails?.request_type == 2
                ? false
                : reqValErr.length > 0
                ? true
                : false
            }
            onClick={mrRequestActionHandler}
          >
            Save
          </button>
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
            onClick={() => window.location.reload()}
          >
            cancel
          </button>
        </Col>
        {showStatus && (
          <StatusModal
            statusModalClose={statusModalClose}
            showStatus={showStatus}
          />
        )}
      </Col>
    </Col>
  );
}

export default MaterialRequestAmendmentTableSection;
