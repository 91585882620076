import React from "react";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import IsmMaterialRequestAmendForm from "../../component/ism/IsmMaterialRequestAmend/IsmMaterialRequestAmendForm";
import IsmMaterialRequestAmendTableSection from "../../component/ism/IsmMaterialRequestAmend/IsmMaterialRequestRequestAmendTabSec";

const IsmMaterialRequestAmendmentScreen = () => {
  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <IsmMaterialRequestAmendForm />
      <IsmMaterialRequestAmendTableSection />
    </Col>
  );
};

export default IsmMaterialRequestAmendmentScreen;
