import React, { useState } from "react";
import { Col } from "react-bootstrap";

const PCAddressTab = ({
  setTab,
  setProjectAddressDetails,
  setProjectAddressRequiredDetails,
  projectAddressRequiredDetails,
  projectAddressDetails,
  projectForm,
  setProjectForm,
  setFormError,
}) => {
  const [error, setError] = useState([]);
  const [addErr, setAddErr] = useState(false);
  console.log("🚀 ~ file: PCAddressTab.js:15 ~ error:", error);

  const projectCreateNextHandler = () => {
    let temp_1 = [];
    let temp = [];

    if (projectAddressDetails.address == "") {
      setAddErr(true);
    }
    Object.entries(projectForm).forEach((f_data) => {
      if (f_data[1] == "") {
        temp_1.push(f_data[0]);
      } else {
        temp_1.splice(temp_1.indexOf(f_data[0]), 1);
      }
    });

    Object.entries(projectAddressRequiredDetails).forEach((item) => {
      if (item[1] === "") {
        temp.push(item[0]);
      } else {
        let remove_error = error.indexOf(item[0]);
        error.splice(remove_error, 1);
      }
    });
    setError(temp);
    setFormError(temp_1);
    if (temp.length == 0 && temp_1.length == 0) {
      setTab("project");
    }
  };
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-0">
      <h4 className="w-95 ms-lg-0">Address</h4>
      <Col className="d-sm-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Door No & Street<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Door No & Street"
            value={projectAddressRequiredDetails.door_no}
            onChange={(e) =>
              setProjectAddressRequiredDetails({
                ...projectAddressRequiredDetails,
                door_no: e.target.value,
              })
            }
          />
          {error.includes("door_no") && (
            <p className="err">Please Enter Door No & Street</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Address Line 2</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Address Line 2"
            value={projectAddressDetails.address}
            onChange={(e) =>
              setProjectAddressDetails({
                ...projectAddressDetails,
                address: e.target.value,
              })
            }
          />
          {projectAddressDetails.address == "" && addErr && (
            <p className="err">Please Enter Door No & Street</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Taluk</label>
          <input
            className="custom-file-input border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Taluk"
            value={projectAddressDetails.taluk}
            onChange={(e) =>
              setProjectAddressDetails({
                ...projectAddressDetails,
                taluk: e.target.value.replace(/[^\sa-z]/gi, ""),
              })
            }
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            District<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            value={projectAddressRequiredDetails.district}
            onChange={(e) =>
              setProjectAddressRequiredDetails({
                ...projectAddressRequiredDetails,
                district: e.target.value.replace(/[^\sa-z]/gi, ""),
              })
            }
            placeholder="Enter District"
          />
          {error.includes("district") && (
            <p className="err">Please Enter Districtt</p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            State<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter State"
            value={projectAddressRequiredDetails.state}
            onChange={(e) =>
              setProjectAddressRequiredDetails({
                ...projectAddressRequiredDetails,
                state: e.target.value.replace(/[^\sa-z]/gi, ""),
              })
            }
          />
          {error.includes("state") && <p className="err">Please Enter State</p>}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Pincode<span className="err">*</span>
          </label>
          <input
            className="custom-file-input border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Pincode"
            type="number"
            value={projectAddressRequiredDetails.pincode}
            onChange={(e) =>
              setProjectAddressRequiredDetails({
                ...projectAddressRequiredDetails,
                pincode: e.target.value.replace(/[^0-9]/gi, ""),
              })
            }
          />
          {error.includes("pincode") && (
            <p className="err">Please Enter Pincode</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Mobile No <span className="err">*</span>
          </label>
          <input
            className="custom-file-input border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Mobile No"
            type="number"
            value={projectAddressRequiredDetails.mobile}
            onChange={(e) =>
              setProjectAddressRequiredDetails({
                ...projectAddressRequiredDetails,
                mobile: e.target.value,
              })
            }
          />
          {error.includes("mobile") && (
            <p className="err">Please Enter Mobile No</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Alternate Mobile No</label>
          <input
            className="custom-file-input border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Alternate Mobile No"
            type="number"
            value={projectAddressDetails.phone_no}
            onChange={(e) =>
              setProjectAddressDetails({
                ...projectAddressDetails,
                phone_no: e.target.value.replace(/[^0-9]/gi, ""),
              })
            }
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Email<span className="err">*</span>
          </label>
          <input
            className="custom-file-input border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Email"
            value={projectAddressRequiredDetails.email}
            onChange={(e) =>
              setProjectAddressRequiredDetails({
                ...projectAddressRequiredDetails,
                email: e.target.value,
              })
            }
          />
          {error.includes("email") && (
            <p className="err">Please Enter EMail Id</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Alternate Email</label>
          <input
            className="custom-file-input border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Alternate Email"
            value={projectAddressDetails.alternate_email}
            onChange={(e) =>
              setProjectAddressDetails({
                ...projectAddressDetails,
                alternate_email: e.target.value,
              })
            }
          />
        </Col>

        <Col sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={projectCreateNextHandler}
          >
            Next
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default PCAddressTab;
