import React, { useEffect, useState } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import PoCreationAmendTable from "./PoCreationAmendTable";
const PoCreationtAmendTableSection = ({
  hsnDetail,
  requestionType,
  purchaseOrderTableDetails,
  setMiscSaveDetails,
  miscSaveDetails,
  setBasicPrice,
  basicPrice,
  qty,
  searchHsn,
  setQty,
  setHsnDetail,
  setMiscModalOpen,
  miscModalOpen,
  setUpdateMisc,
  masterType,
  selectCheckBox,
  checkBox,
  miscPrice,
  setMiscPrice,
  setMiscModalOpens,
  setMiscModals,
  miscModalOpens,
  setQtyErr,
  qtyErr,
  
  tolorance,
  setTolorance,
}) => {
  const [miscModal, setMiscModal] = useState(false);
  const [miscId, setMiscId] = useState("");

  const [miscModalDetails, setMiscModalDetails] = useState({
    discount_percent: "",
    discount_value: "",
    igst_value: "",
    sgst_value: "",
    cgst_value: "",
    sel_id: "",
    net_value: "",
    totalValue: "",
  });
  const roundOffActionHandler = (action) => {
    let temp =
      action == "-"
        ? Math.floor(miscModalDetails?.discount_value)
        : Math.ceil(miscModalDetails?.discount_value);
    setMiscModalDetails({ ...miscModalDetails, discount_value: temp });
  };
  const miscDiscounthandler = (dis_per, total_val) =>
    dis_per == 0 ? 1 : (+dis_per / 100) * +total_val;

  // MISC VLAUE
  const miscNetvalueHandler = (dis_value, total_val) =>
    dis_value == 0 ? 1 : dis_value + total_val;

  const miscValueHandler = (id, total_value, dis_per, hsn_code, misc) => {
    let dis_val = miscDiscounthandler(+dis_per, +total_value);
    setMiscId(id);

    setMiscModalDetails({
      ...miscModalDetails,
      net_value: misc,
      discount_percent: dis_per,
      discount_value: dis_val,
      sel_id: +id,
      totalValue: +total_value,
    });
    console.log("total_value", total_value);
    searchHsn(hsn_code, total_value);
    setMiscModal(!miscModal);
  };

  const total_val_calulator = (basic_price, qty) => {
    let total_val = +basic_price * +qty;
    return total_val;
  };

  const basicPriceChange = (value, id) => {
    setBasicPrice({
      ...basicPrice,
      [id]: value,
    });
    setMiscModalOpen(true);
    setMiscModalOpens((val) => ({ ...val, [id]: true }));
    setMiscModals((val)=>({...val, [id]:true}))
  };

  const qtyChange = (value, id, allowableValue, qty_value, total_po_approved_qty) => {
    console.log("allowableValue", Math.abs(allowableValue));
    if (Math.abs(total_po_approved_qty?.issued - total_po_approved_qty?.total) == 0) {
      if (value == qty_value) {
        setQtyErr({
          ...qtyErr,
          [id]: 0,
        });
      } else {
        setQtyErr({
          ...qtyErr,
          [id]: 2,
        });
      }

      setMiscModalOpens((val) => ({ ...val, [id]: false }));
    } else if (value > Math.abs(total_po_approved_qty?.issued - total_po_approved_qty?.total)) {
      setQtyErr({
        ...qtyErr,
        [id]: 1,
      });

      setMiscModalOpens((val) => ({ ...val, [id]: true }));
    } else {
      setQtyErr({
        ...qtyErr,
        [id]: 0,
      });

      setMiscModalOpens((val) => ({ ...val, [id]: true }));
    }

    setQty({
      ...qty,
      [id]: value,
    });

    // console.log("allowableValue", allowableValue);

    setMiscModalOpen(true);
    setMiscModals((val)=>({...val, [id]:true}))
  };

  const hsnChange = (event) => {
    setHsnDetail({ ...hsnDetail, hsn_code: event });
    searchHsn(event, miscModalDetails?.totalValue);
  };

  const hsnClose = (miscId) => {
    // console.log(miscId, "miscId");
    setMiscSaveDetails({
      ...miscSaveDetails,
      [miscId]: {
        ...miscSaveDetails[miscId],
        net_value:
        Number(basicPrice[miscId] * qty[miscId] +
          miscNetvalueHandler(
            miscDiscounthandler(
              +miscModalDetails?.discount_percent,
              +miscModalDetails?.totalValue
            ),
            hsnDetail?.igst_value
          )).toFixed(2)
      },
    });
    setMiscModal(!miscModal);
    setMiscModalOpens((val) => ({ ...val, [miscId]: false }));

    // UPDATE
    setUpdateMisc((val) => ({
      ...val,
      [miscId]: {
        net_value:
          +basicPrice[miscId] * +qty[miscId] +
          miscNetvalueHandler(
            miscDiscounthandler(
              +miscModalDetails?.discount_percent,
              +miscModalDetails?.totalValue
            ),
            hsnDetail?.igst_value
          ),
        basic_price: +basicPrice[miscId],
        total_value: +basicPrice[miscId] * +qty[miscId],
        misc: miscNetvalueHandler(
          miscDiscounthandler(
            +miscModalDetails?.discount_percent,
            +miscModalDetails?.totalValue
          ),
          hsnDetail?.igst_value
        ),
        hsn_code: +hsnDetail?.hsn_code,
        hsn_id: +hsnDetail?.hsn_id,
        discount_percent: miscModalDetails?.discount_percent,
        discount_value: miscModalDetails?.discount_percent,
        qty: +qty[miscId],
        igst:hsnDetail.igst ? hsnDetail.igst : '-',
        igst_value:hsnDetail?.igst_value ? hsnDetail?.igst_value : '-',
        sgst:hsnDetail?.sgst ? hsnDetail?.sgst : '-',
        sgst_value:hsnDetail?.sgst_value ? hsnDetail?.sgst_value : '-',
        cgst:hsnDetail?.cgst ? hsnDetail?.cgst : '-',
        cgst_value:hsnDetail?.cgst_value ? hsnDetail?.cgst_value : '-'
      },
    }));

    setMiscPrice({
      ...miscPrice,
      [miscId]: Number(
        miscNetvalueHandler(
          miscDiscounthandler(
            +miscModalDetails?.discount_percent,
            +miscModalDetails?.totalValue
          ),
          hsnDetail?.igst_value
        )
      ).toFixed(2),
    });
  };

  // console.log("qtyErr", qtyErr);

  // console.log("miscModalOpens", miscModalOpens);

  // console.log("miscSaveDetails", miscSaveDetails);

  // console.log("purchaseOrderTableDetails", purchaseOrderTableDetails);

  return (
    <Col
      style={{ overflowX: "scroll" }}
      className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center "
    >
      <Col xs={10} className="emply-master-form py-2  mt-3 text-center">
        <div style={{ overflowX: "scroll" }} className="boq-table bg-white">
          <table xs={10} className="table">
            <thead>
              {masterType == "Assets" ? (
                <tr>
                  <th>S-No</th>
                  <th>Boq Code</th>
                  <th>Material Code</th>
                  <th>Material Description</th>
                  <th>UOM</th>
                  <th style={{ minWidth: "150px" }}>Attributes</th>
                  <th>Quantity</th>
                  <th>Basic Price</th>
                  <th style={{width: "100px" }}>Tolerance</th>
                  <th>Total Value</th>
                  <th>MISC</th>
                  <th>Net Value</th>
                  <th>Remarks</th>
                </tr>
              ) : requestionType == 1 ? (
                <tr>
                  <th>S-No</th>
                  <th>Boq Code</th>
                  <th>Material Code</th>
                  <th>Material Description</th>
                  <th>UOM</th>
                  <th style={{ minWidth: "150px" }}>Attributes</th>
                  <th>Quantity</th>
                  <th>Required Quantity</th>
                  <th>Basic Price</th>
                  <th style={{ width: "100px" }}>Tolerance</th>
                  <th>Total Value</th>
                  <th>MISC</th>
                  <th>Net Value</th>
                  <th>Remarks</th>
                </tr>
              ) : (
                <tr>
                  <th>S-No</th>
                  <th>Boq Code</th>
                  <th>Material Code</th>
                  <th>Material Description</th>
                  <th>UOM</th>
                  <th style={{ minWidth: "150px" }}>Attributes</th>
                  <th>Quantity</th>
                  <th>Basic Price</th>
                  <th>Total Value</th>
                  <th>Remarks</th>
                </tr>
              )}
            </thead>
            {masterType == "Assets" ? (
              <tbody>
                {purchaseOrderTableDetails?.map((item, ind) => {
                  return (
                    <tr key={ind}>
                      <td>
                        <div className="d-flex gap-2">
                          <p>{ind + 1}</p>
                          <input
                            type={"checkbox"}
                            onClick={() => selectCheckBox(item?.id)}
                            checked={checkBox?.includes(item?.id)}
                          />
                        </div>
                      </td>
                      <td>{item.boq_code}</td>
                      <td>{item.material?.material_code}</td>
                      <td style={{textAlign:"start"}}>{item.material?.name}</td>
                      <td>{item?.uom ?? item?.material?.uom}</td>
                      <td>
                        <ul>
                          {item?.specifications?.length > 0 &&
                            item?.specifications?.map((item, ind) => (
                              <li key={ind}>{item}</li>
                            ))}
                        </ul>
                      </td>

                      <td>
                        <input
                          className="border-0 text-center"
                          value={qty[item?.id]}
                          type={"number"}
                          onChange={(e) =>
                            setQty({
                              ...qty,
                              [item?.id]: e.target.value,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="border-0 text-center" style={{width:"100px"}}
                          type={"number"}
                          onChange={(e) =>
                            basicPriceChange(e.target.value, item?.id)
                          }
                          value={basicPrice[item?.id]}
                        />
                      </td>
                      <td>
                      <input
                       style={{ width: "100px" }}
                          className="border-0 text-center"
                          type={"number"}
                          onChange={(e) =>
                            setTolorance((val)=>({...val,[item?.id]:e.target.value}))
                          }
                          value={tolorance[item?.id]}
                        />
                        {/* {item.tolerance} */}
                        {+tolorance[item?.id] > 15 && (
                          <p className="err" style={{ fontSize: "10px" }}>
                            Exceeded The Quantity
                          </p>
                        )}
                        </td>
                      <td>{basicPrice[item?.id] * qty[item?.id]}</td>
                      {/* <td>
                        {miscSaveDetails && miscSaveDetails[+item?.id]?.misc}
                      </td> */}

                      <td>
                        {miscModalOpens[item?.id] ? (
                          <button
                            className="border-0 bg-primary1 rounded-1 text-white"
                            onClick={() =>
                              miscValueHandler(
                                item?.id,
                                +item?.qty * +basicPrice[item?.id],
                                item?.discount_percent,
                                item?.hsn_code?.code_name,
                                item?.misc
                              )
                            }
                          >
                            Misc
                          </button>
                        ) : (
                          // <p className="text-center">
                          //   {miscModalDetails?.net_value}
                          // </p>
                          <p className="text-center">
                            {miscNetvalueHandler(
                              miscDiscounthandler(
                                +miscModalDetails?.discount_percent,
                                +miscModalDetails?.totalValue
                              ),
                              hsnDetail?.igst_value
                            )}
                          </p>
                        )}
                      </td>

                      <td>
                        {miscSaveDetails &&
                          miscSaveDetails[+item?.id]?.net_value}
                      </td>

                      <td>{item?.remarks}</td>
                    </tr>
                  );
                })}
              </tbody>
            ) : requestionType == 1 ? (
              <tbody>
                {purchaseOrderTableDetails?.map((item, ind) => {
                  return (
                    <tr key={ind}>
                      <td>
                        <div className="d-flex gap-2">
                          <p>{ind + 1}</p>
                          <input
                            type={"checkbox"}
                            onClick={() => selectCheckBox(item?.id)}
                            checked={checkBox?.includes(item?.id)}
                          />
                        </div>
                      </td>
                      <td>{item.boq_code}</td>
                      <td>{item.material?.material_code}</td>
                      <td style={{textAlign:"start"}}>{item.material?.name}</td>
                      <td>{item?.uom ?? item?.material?.uom}</td>
                      <td>
                        <ul>
                          {item?.specifications?.map((item, ind) => (
                            <li key={ind}>{item}</li>
                          ))}
                        </ul>
                      </td>
                      <td>{item?.required_quantity}</td>
                      <td className="text-center">
                        <input
                          className="border-0 text-center"
                          value={qty[item?.id]}
                          type={"number"}
                          onChange={(e) =>
                            qtyChange(
                              e.target.value,
                              item?.id,
                              // +item?.approved_po_qty - +item?.quantity
                              item?.available_quantity,
                              +item?.quantity,
                              item?.total_po_approved_qty
                            )
                          }
                        />
                        {+qtyErr[item?.id] == 1 && (
                          <p className="err" style={{ fontSize: "10px" }}>
                            Exceeded The Quantity
                          </p>
                        )}
                        {+qtyErr[item?.id] == 2 && (
                          <p className="err" style={{ fontSize: "10px" }}>
                            Exceeded The Quantity
                          </p>
                        )}
                      </td>
                      <td>
                        <input
                           className="border-0 text-center" style={{width:"100px"}}
                          type={"number"}
                          onChange={(e) =>
                            basicPriceChange(e.target.value, item?.id)
                          }
                          value={basicPrice[item?.id]}
                          disabled
                        />
                      </td>
                      <td>
                      <input
                       style={{ width: "100px" }}
                          className="border-0 text-center"
                          type={"number"}
                          onChange={(e) =>
                            setTolorance((val)=>({...val,[item?.id]:e.target.value}))
                          }
                          value={tolorance[item?.id]}
                        />
                        {/* {item.tolerance} */}
                        {+tolorance[item?.id] > 15 && (
                          <p className="err" style={{ fontSize: "10px" }}>
                            Exceeded The Quantity
                          </p>
                        )}
                      </td>
                      <td>
                        {qty[item?.id]
                          ? +basicPrice[item?.id] * +qty[item?.id]
                          : ""}
                      </td>
                      {/* <td>
                        {miscSaveDetails && miscSaveDetails[+item?.id]?.misc}
                      </td> */}

                      <td>
                        {miscModalOpens[item?.id] ? (
                          <button
                            className="border-0 bg-primary1 rounded-1 text-white"
                            onClick={() =>
                              miscValueHandler(
                                item?.id,
                                +basicPrice[item?.id] * +qty[item?.id],
                                item?.discount_percent,
                                item?.hsn_code?.code_name,
                                item?.misc
                              )
                            }
                          >
                            Misc
                          </button>
                        ) : (
                          // <p className="text-center">
                          //   {miscModalDetails?.net_value}
                          // </p>
                          <p className="text-center">
                            {/* {miscNetvalueHandler(
                              miscDiscounthandler(
                                +miscModalDetails?.discount_percent,
                                +miscModalDetails?.totalValue
                              ),
                              hsnDetail?.igst_value
                            )} */}
                            {/* {miscPrice} */}
                            {miscPrice[item?.id]}
                          </p>
                        )}
                      </td>

                      <td>
                        {miscSaveDetails &&
                          miscSaveDetails[+item?.id]?.net_value}
                      </td>

                      <td>{item?.remarks}</td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                {purchaseOrderTableDetails?.map((item, ind) => {
                  return (
                    <tr key={ind}>
                      <td>
                        <div className="d-flex gap-2">
                          <p>{ind + 1}</p>
                          <input
                            type={"checkbox"}
                            onClick={() => selectCheckBox(item?.id)}
                            checked={checkBox?.includes(item?.id)}
                          />
                        </div>
                      </td>
                      <td>{item.boq_code}</td>
                      <td>{item.material?.material_code}</td>
                      <td style={{textAlign:"start"}}>{item.material?.name}</td>
                      <td>{item?.uom ?? item?.material?.uom}</td>
                      <td>{item?.attributes ?? "-"}</td>

                      <td>
                        <input
                          className="border-0 "
                          value={qty[item?.id]}
                          onChange={(e) =>
                            setQty({
                              ...qty,
                              [item?.id]: e.target.value,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="border-0 text-center" style={{width:"100px"}}
                          onChange={(e) =>
                            setBasicPrice({
                              ...basicPrice,
                              [item?.id]: e.target.value,
                            })
                          }
                          value={basicPrice[item?.id]}
                        />
                      </td>
                      <td>
                        {total_val_calulator(
                          basicPrice[item?.id],
                          +qty[item?.id]
                        )}
                      </td>
                      <td>{item?.remarks}</td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </Col>

      <Modal
        show={miscModal}
        centered
        onHide={() => {
          setMiscModal(!miscModal);
        }}
      >
        <Modal.Header closeButton className="border-0">
          <h5 className="mx-auto">Miscellaneous Screen</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 my-1 py-1">
            <label style={{ width: "140px" }} className="fs-14 me-2">
              HSN Code
            </label>
            <input
              value={hsnDetail?.hsn_code}
              onChange={(e) => hsnChange(e.target.value)}
            />
          </div>
          <div className="w-100 my-1 py-1">
            <label style={{ width: "140px" }} className="fs-14 me-2">
              HSN Description
            </label>
            <input readOnly value={hsnDetail?.hsn_des ?? " "} />
          </div>
          <div className="w-100 d-flex my-1 py-1">
            <Col xs={8}>
              <label style={{ width: "140px" }} className="fs-14 me-2">
                Total Value
              </label>
            </Col>
            <Col xs={4}>
              <label className="fs-14 me-2">Value</label>
              <input
                className="w-50"
                readOnly
                type="text"
                value={miscModalDetails?.totalValue}
              />
            </Col>
          </div>
          <div className="w-100 d-flex my-1 py-1">
            <Col xs={8}>
              <label style={{ width: "140px" }} className="fs-14 me-2">
                Discount
              </label>
              <input
                className="w-25"
                type="number"
                value={miscModalDetails?.discount_percent ?? ""}
                onChange={(e) =>
                  setMiscModalDetails({
                    ...miscModalDetails,
                    discount_percent: e.target.value,
                  })
                }
              />
              <span className="ms-1">%</span>
            </Col>
            <Col xs={4}>
              <label className="fs-14 me-2">Value</label>
              <input
                readOnly
                className="w-50"
                value={miscDiscounthandler(
                  +miscModalDetails?.discount_percent,
                  +miscModalDetails?.totalValue
                )}
              />
            </Col>
          </div>
          <div className="w-100 d-flex my-1 py-1">
            <Col xs={8}>
              <label style={{ width: "140px" }} className="fs-14 me-2">
                IGST
              </label>
              <input readOnly className="w-25" value={hsnDetail.igst ?? "-"} />
              <span className="ms-1">%</span>
            </Col>
            <Col xs={4}>
              <label className="fs-14 me-2">Value</label>
              <input
                readOnly
                className="w-50"
                value={hsnDetail?.igst_value ?? "-"}
              />
            </Col>
          </div>
          <div className="w-100 d-flex my-1 py-1">
            <Col xs={8}>
              <label style={{ width: "140px" }} className="fs-14 me-2">
                SGST
              </label>
              <input className="w-25" readOnly value={hsnDetail?.sgst ?? "-"} />
              <span className="ms-1">%</span>
            </Col>
            <Col xs={4}>
              <label className="fs-14 me-2">Value</label>
              <input
                className="w-50"
                readOnly
                value={hsnDetail?.sgst_value ?? "-"}
              />
            </Col>
          </div>
          <div className="w-100 d-flex my-1 py-1">
            <Col xs={8}>
              <label style={{ width: "140px" }} className="fs-14 me-2">
                CGST
              </label>
              <input className="w-25" readOnly value={hsnDetail?.cgst ?? "-"} />
              <span className="ms-1">%</span>
            </Col>
            <Col xs={4}>
              <label className="fs-14 me-2">Value</label>
              <input
                className="w-50"
                readOnly
                value={hsnDetail?.cgst_value ?? "-"}
              />
            </Col>
          </div>
          <div className="w-100 d-flex my-1 py-1">
            <Col xs={8}>
              <label style={{ width: "140px" }} className="fs-14 me-2">
                Round Off
              </label>
              <button
                className="border-0 me-1 bg-primary1"
                onClick={() => roundOffActionHandler("+")}
              >
                +
              </button>
              <button
                className="border-0 ms-1 bg-primary2"
                onClick={() => roundOffActionHandler("-")}
              >
                -
              </button>
            </Col>
          </div>

          <div className="w-100 d-flex my-1 py-1">
            <Col xs={8}>
              <label style={{ width: "140px" }} className="fs-14 me-2">
                Net Value
              </label>
            </Col>
            <Col xs={4}>
              <label className="fs-14 me-2">Value</label>
              <input
                className="w-50"
                readOnly
                type="text"
                value={miscNetvalueHandler(
                  miscDiscounthandler(
                    +miscModalDetails?.discount_percent,
                    +miscModalDetails?.totalValue
                  ),
                  hsnDetail?.igst_value
                )}
                // value={`${miscModalDetails?.net_value ?? " "}`}
              />
            </Col>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
            onClick={() => {
              setMiscModal(!miscModal);
              setMiscModalOpen(false);
              setMiscModalOpens((val) => ({ ...val, [miscId]: false }));
            }}
          >
            Cancel
          </button>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={() => {
              hsnClose(miscId);
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default PoCreationtAmendTableSection;
