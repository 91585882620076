import React, { useState } from "react";
import StatusModal from "../StatusModal";
import moment from "moment";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
import { BASE_FILE_URL } from "../../constants/Services/constants";

const BoqAmendForm = ({
  projectList,
  boqId,
  boqFormDetails,
  setBoqFormDetails,
  boqNonRequiredFields,
  setBoqNonRequiredFields,
}) => {
  const [boqError, setBoqError] = useState([]);

  // console.log("🚀 ~ file: BoqAmendForm.js:16 ~ boqId:", boqId);
  const [boqAmendFormDetails, setBoqAmendFormDetails] = useState({
    head_office: "",
    completion_date: "",
    project_name: "",
    boq_no: "",
    loi_date: "",
  });

  const [showStatus, setShowStatus] = useState(false);
  const statusModalClose = () => {
    setShowStatus(!showStatus);
  };

  const boqFormSubmitHandler = () => {
    let set_error = Object.entries(boqFormDetails)
      .filter((item) => item[1] === "" && item.splice(1, 1))
      ?.reduce((acc, cur_item) => acc.concat(cur_item), "");
    setBoqError(set_error);

    if (boqError.length === 0) {
      console.log("test-tt", boqFormDetails);
      api.project_boq_add(boqFormDetails).then((res) => {
        console.log("boq-log-AMend-res", res);
        if (res?.status === "success") {
          statusModalClose();
          setTimeout(window.location.reload(), 2000);
          setBoqNonRequiredFields({
            created_date: "",
            completion_date: "",
            previous_boq_no: "",
            loi_date: "",
          });
          setBoqFormDetails({
            project_id: "",
            boq_no: "",
            import_excel: "",
          });
        }
      });
    }
  };
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="ms-xl-0 w-95">BOQ Amendment</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <select
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 "
            value={boqFormDetails.project_id}
            onChange={(e) =>
              setBoqFormDetails({
                ...boqFormDetails,
                project_id: e.target.value,
              })
            }
          >
            <option key="0" value={null}>
              Select Project
            </option>
            {projectList?.map((project) => (
              <option key={project?.id} value={project?.id}>
                {project?.name}
              </option>
            ))}
          </select>

          {boqError.includes("project_id") && (
            <p className="err"> Please Select Project</p>
          )}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Previous BOQ No</label>
          <p
            style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            {boqNonRequiredFields.previous_boq_no}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">BOQ No</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter New Boq Number"
            value={boqFormDetails?.boq_no}
            onChange={(e) =>
              setBoqFormDetails({
                ...boqFormDetails,
                boq_no: e.target.value,
              })
            }
          />
          {boqError.includes("boq_no") && (
            <p className="err"> Please Enter BOQ No</p>
          )}
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Created Date</label>
          <p
            style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            {boqNonRequiredFields?.created_date != "" &&
              moment(boqNonRequiredFields?.created_date).format("MMM Do YYYY")}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Current Date</label>
          <p
            style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            {moment().format("MMM Do YYYY")}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Completion Date</label>
          <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Completion Date"
            value={boqNonRequiredFields.completion_date}
            onChange={(e) =>
              setBoqNonRequiredFields({
                ...boqNonRequiredFields,
                completion_date: e.target.value,
              })
            }
          />
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">LOI Date</label>
          <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter LOI Date"
            value={boqNonRequiredFields.loi_date}
            onChange={(e) =>
              setBoqNonRequiredFields({
                ...boqNonRequiredFields,
                loi_date: e.target.value,
              })
            }
          />
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={6}
          xl={6}
          className="inputinner-box text-start position-relative"
        >
          <label className="w-100">Upload File</label>

          <p className="file_name-display-box">
            {boqFormDetails.import_excel?.name
              ? boqFormDetails.import_excel?.name
              : "Upload Document"}
          </p>

          <div className="d-sm-flex w-90 ms-0 my-1 emply-mas-doc-box accordion-body bg-lightBlue">
            <input
              type="file"
              className="border-0 px-1"
              onChange={(e) =>
                setBoqFormDetails({
                  ...boqFormDetails,
                  import_excel: e.target.files[0],
                })
              }
            />
          </div>
          {boqError.includes("import_excel") && (
            <p className="err"> Please Upload Excel</p>
          )}
        </Col>

        <Col
          xs={12}
          sm={6}
          lg={6}
          xl={6}
          className="inputinner-box text-start my-2"
        >
          <label className="w-100"></label>
          <a
            // href={`http://202.88.218.166/public/index.php/api/project-boq-download/${+boqId}`}
            href={`${BASE_FILE_URL}api/project-boq-download/${+boqId}`}
            rel="noreferrer"
            className="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-4 py-2 pointer"
          >
            Download
          </a>
        </Col>

        <Col xs={12} sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={boqFormSubmitHandler}
          >
            Submit
          </button>
        </Col>
      </Col>
      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
        />
      )}
    </Col>
  );
};

export default BoqAmendForm;
