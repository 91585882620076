import React from "react";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import UserManagement from "../../component/UserManagement/UserManagement";

const UserManagementscreen = () => {
  return (
    <Col xs={12} className="p-3">
      <Header />
      <UserManagement />
    </Col>
  );
};

export default UserManagementscreen;
