import React, { useState, useEffect } from "react";
import eye_icon from "../../assets/icons/common/Eye.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";

function PurchaseOrderList() {
  const [PODetailsList, setPODetailsList] = useState([]);
  const [POList, setPOList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectName, setProjectName] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selPurcaseOrder, setSelPurchaseOrder] = useState("");
  const [show, setShow] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [raisedValue, setRaisedValue] = useState("");
  const [balanceValue, setBalanceValue] = useState("");
  const navigate = useNavigate();

  const getPurchaseOrderList = () => {
    let data = {
      project_id: +selectedProject,
    };
    api.purchaseOrderList(data).then((res) => {
      if (res?.status == "success") {
        let raised_nbi = res?.purchase_orders?.reduce(
          (acc, po) => acc + po?.project_boq?.raised_nbi,
          0
        );

        let balance_nbi = res?.purchase_orders?.reduce(
          (acc, po) => acc + po?.project_boq?.issued_nbi,
          0
        );

        // if (Number.isInteger(raised_nbi)) {
        //   setRaisedValue(raised_nbi);
        // } else {
        //   setRaisedValue("-");
        // }

        // if (Number.isInteger(balance_nbi)) {
        //   setBalanceValue(balance_nbi);
        // } else {
        //   setBalanceValue("-");
        // }

        setPOList(res?.purchase_orders);
        setRaisedValue(res?.issued_nb_value);
        setBalanceValue(res?.total_nb_value);
        // res?.purchase_orders?.filter((i)=>i?.boq_detail)
        removeQueryParams();
      }
    });
  };

  const dropDownMaterialHandler = (sel_po) => {
    setSelPurchaseOrder(+sel_po);
  };

  const getProjects = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  const getProjectLists = () => {
    api.project_list_without_boq().then((res) => {
      setProjectList(res?.projects);
    });
  };

  useEffect(() => {
    if (searchParams?.get("project")) {
      setSelectedProject(searchParams?.get("project"));
    }
  }, [searchParams?.get("project")]);

  useEffect(() => {
    // getProjects();
    getProjectLists();
  }, []);

  useEffect(() => {
    getPurchaseOrderList();
    const ProjectName = projectList
      ?.filter((project) => project?.id == selectedProject)
      .reduce((acc, filtered_project) => filtered_project?.name, "");
    if (searchParams?.get("projectname")) {
      setProjectName(searchParams?.get("projectname"));
    } else {
      setProjectName(ProjectName);
    }
  }, [selectedProject]);

  const purcaseOrderDetailHandler = (sel_po, po_num, po_date) => {
    navigate("/procurement/po-output", {
      state: { id: sel_po },
    });
    // navigate("/procurement/purchase-order-action", {
    //   state: { id: sel_po, po_no: po_num, po_date: po_date },
    // });
  };

  const removeQueryParams = () => {
    const proId = searchParams.get("project");
    const projectName = searchParams.get("projectname");

    if (proId) {
      // 👇️ delete each query param
      searchParams.delete("project");

      // 👇️ update state after
      setSearchParams(searchParams);
    }

    if (projectName) {
      searchParams.delete("projectname");
      setSearchParams(searchParams);
    }
  };

  // console.log("balanceValue", balanceValue, "raisedValue", raisedValue);

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white my-3 text-center">
        <header>
          <Col xs={12} className="d-flex my-2">
            {/* <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-25 mt-1 me-auto pointer"
              value={selPurcaseOrder}
              onChange={(e) => dropDownMaterialHandler(e.target.value)}>
              <option>Choose Category</option>
              {POList?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select> */}
            <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 ms-auto pointer"
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
            >
              <option>Select Project</option>
              {projectList?.map((project) => (
                <option key={project?.id} value={project?.id}>
                  {project?.name}
                </option>
              ))}
            </select>
          </Col>
          {/* <Col xs={12} className="my-3 py-2 d-flex">
            <input
              type="checkbox"
              className="pointer mx-2"
              checked={show == "approved"}
              value="approved"
              onChange={(e) => setShow(e.target.value)}
            />
            <label className="mx-1 px-1">Approved</label>
            <input
              type="checkbox"
              className="pointer mx-2"
              checked={show == "declined"}
              value="declined"
              onChange={(e) => setShow(e.target.value)}
            />
            <label className="mx-1 px-1">Declined</label>
          </Col> */}
        </header>
        <h4 className="my-1 py-1">Purchase Order List</h4>
        <div className="d-flex justify-content-end mt-2 mb-2">
          <div className="d-flex gap-2">
            <div className="d-flex gap-2">
              <p>Purchase Value : </p>
              <p>
                {raisedValue == NaN
                  ? "-"
                  : raisedValue == "NaN"
                  ? "-"
                  : raisedValue}
              </p>
            </div>
            <div className="d-flex gap-2">
              <p>Total NBI Value : </p>
              <p>{balanceValue}</p>
            </div>
          </div>
        </div>
        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                {/* <th>Project Name</th> */}
                <th className="text-nowrap">Vendor Name</th>
                <th className="text-nowrap">PO Number</th>
                <th className="text-nowrap">PO Date </th>
                {/* <th className="text-nowrap">Raised Value </th> */}
                {/* <th className="text-nowrap">Balance Value</th> */}
                {/* <th className="text-nowrap">Procure Type</th> */}
                <th className="text-nowrap">Created By</th>
                <th className="text-nowrap">Status</th>
                <th className="text-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              {POList?.length > 0 &&
                POList?.map((purcaseOrder, ind) => {
                  // let matCode = purcaseOrder?.boq_detail?.find(
                  //   (i) => i?.material_code == "NB"
                  // );
                  // console.log("matCode", matCode);
                  return (
                    <tr>
                      <td>{ind + 1}</td>
                      {/* <td>{projectName}</td> */}
                      <td style={{textAlign:"justify"}}>
                        {purcaseOrder?.vendor?.company_name ||
                          purcaseOrder?.purchase_order?.vendor?.company_name}
                      </td>
                      <td>
                        {purcaseOrder?.po_no ||
                          purcaseOrder?.purchase_order?.po_no}
                      </td>
                      <td>
                        {purcaseOrder?.po_date ||
                          purcaseOrder?.purchase_order?.po_date}
                      </td>
                      {/* <td className="text-center">
                        {purcaseOrder?.project_boq?.raised_nbi}
               
                      </td>
                      <td className="text-center">
                        {purcaseOrder?.project_boq?.issued_nbi}
                    
                      </td> */}
                      {/* <td>
                        {purcaseOrder?.procure_type ||
                          purcaseOrder?.purchase_order?.procure_type}
                      </td> */}
                      <td className="text-center">
                        {purcaseOrder?.created_by ||
                          purcaseOrder?.purchase_order?.created_user?.name}
                      </td>
                      <td>
                        {/* {purcaseOrder?.approved_by == null &&
                      purcaseOrder?.status == 1
                        ? "Not Seen"
                        : purcaseOrder?.approved_by == null
                        ? "Rejected"
                        : "Approved"} */}
                        {purcaseOrder?.status == 1
                          ? "Pending"
                          : purcaseOrder?.status == 2
                          ? "Approved"
                          : purcaseOrder?.status == 3
                          ? "Rejected"
                          : purcaseOrder?.purchase_order?.status == 1
                          ? "Pending"
                          : purcaseOrder?.purchase_order?.status == 2
                          ? "Approved"
                          : purcaseOrder?.purchase_order?.status == 3
                          ? "Rejected"
                          : "Pending"}
                      </td>
                      <td>
                        <div className="d-flex">
                          <Link
                            to={`/procurement/po-output?id=${
                              purcaseOrder?.id ||
                              purcaseOrder?.purchase_order?.id
                            }&project=${+selectedProject}&projectname=${projectName}`}
                          >
                            <img
                              onClick={() =>
                                purcaseOrderDetailHandler(
                                  purcaseOrder?.id ||
                                    purcaseOrder?.purchase_order?.id,
                                  purcaseOrder?.po_no ||
                                    purcaseOrder?.purchase_order?.po_no,
                                  purcaseOrder?.po_date ||
                                    purcaseOrder?.purchase_order?.po_date
                                )
                              }
                              style={{ width: "20px" }}
                              className="pointer ms-2"
                              src={eye_icon}
                              alt="eye-icon"
                            />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Col>
    </Col>
  );
}

export default PurchaseOrderList;
