import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const AssetsSearch = ({ materialLevelDetails, setMatId }) => {
  const { child_material, parent_material } = materialLevelDetails;

  const first_child = child_material?.filter(
    (children) => children?.level == 2
  );
  const sec_child = child_material?.filter((children) => children?.level == 3);
  const third_child = child_material?.filter(
    (children) => children?.level == 4
  );
  // console.log("🚀 ~ file: MaterialMasterLevl.js:31 ~ MaterialMasterLevl ~ third_child:", third_child)

  const fouth_child = child_material?.filter(
    (children) => children?.level == 5
  );
  const fifthx_child = child_material?.filter(
    (children) => children?.level == 6
  );

  const navigate = useNavigate();

  const [filteredMaterialList, setFilteredMaterialList] = useState([]);
  const materDetailsHandler = (sel_material) => {
    // console.log("sel_material", sel_material);
    setMatId(sel_material);
    // navigate("/procurement/material-action", {
    //   state: { id: sel_material, action: "view" },
    // });
  };
  const materialFilterHandler = (search_value) => {
    const renderedList = parent_material?.filter((parent) => {
      if (
        parent?.material_code?.includes(search_value) ||
        parent?.name.trim().toLowerCase().includes(search_value)
      ) {
        return parent;
      }
    });
    search_value.length > 0
      ? setFilteredMaterialList(renderedList)
      : setFilteredMaterialList([]);
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <Col xs={12} className="inputinner-box text-start text-center">
        <input
          className="border-0 bg-lightBlue px-2 py-1 w-50 mt-1"
          placeholder="Search...,"
          onChange={(e) => materialFilterHandler(e.target.value)}
        />
      </Col>
      <h4>Assets Master</h4>
      {filteredMaterialList &&
        filteredMaterialList?.map((parent) => (
          <Col key={parent?.id}>
            <button
              className="d-flex level-btn"
              onClick={() => setMatId(parent?.id)}
            >
              <p className="primary f2">{parent?.name}</p>
              <p className="ms-2 f2">{parent?.material_code}</p>
            </button>
            {first_child?.map(
              (child, ind) =>
                child?.parent_id === parent.id && (
                  <Col key={child?.id}>
                    <Col
                      xs={12}
                      className="level-master d-flex justify-content-end pointer"
                      onClick={() => materDetailsHandler(child?.parent_id)}
                    >
                      <div className="line-space" />
                      <Col>
                        <Col className="d-flex level-btn justify-content-between">
                          <div className="d-flex align-items-center">
                            <div className="text-start ms-2">
                              <p className="text-black fs-14 f3">
                                {child?.name}
                              </p>
                              <p className="text-dark fs-12 f1">
                                Level {child.level}
                              </p>
                            </div>
                          </div>
                        </Col>
                        {sec_child &&
                          sec_child?.map(
                            (sec_level_child) =>
                              sec_level_child?.parent_id == child?.id && (
                                <Col
                                  xs={12}
                                  key={sec_level_child?.id}
                                  className="level-master d-flex justify-content-end pointer"
                                  onClick={() =>
                                    materDetailsHandler(
                                      sec_level_child?.parent_id
                                    )
                                  }
                                >
                                  <div className="line-space" />
                                  <Col>
                                    <Col className="d-flex level-btn justify-content-between">
                                      <div className="d-flex align-items-center">
                                        <div className="text-start ms-2">
                                          <p className="text-black fs-14 f3">
                                            {sec_level_child?.name}
                                          </p>
                                          <p className="text-dark fs-12 f1">
                                            Level {sec_level_child.level}
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                    {third_child &&
                                      third_child?.map(
                                        (third_level_child) =>
                                          third_level_child?.parent_id ==
                                            sec_level_child?.id && (
                                            <Col
                                              xs={12}
                                              key={third_level_child?.id}
                                              className="level-master d-flex justify-content-end pointer"
                                              onClick={() =>
                                                materDetailsHandler(
                                                  third_level_child?.parent_id
                                                )
                                              }
                                            >
                                              <div className="line-space" />
                                              <Col>
                                                <Col className="d-flex level-btn justify-content-between">
                                                  <div className="d-flex align-items-center">
                                                    <div className="text-start ms-2">
                                                      <p className="text-black fs-14 f3">
                                                        {
                                                          third_level_child?.name
                                                        }
                                                      </p>
                                                      <p className="text-dark fs-12 f1">
                                                        Level
                                                        {
                                                          third_level_child?.level
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </Col>
                                                {fouth_child &&
                                                  fouth_child?.map(
                                                    (fouth_level_child) =>
                                                      fouth_level_child?.parent_id ==
                                                        third_level_child?.id && (
                                                        <Col
                                                          xs={12}
                                                          key={
                                                            fouth_level_child?.id
                                                          }
                                                          className="level-master d-flex justify-content-end pointer"
                                                          onClick={() =>
                                                            materDetailsHandler(
                                                              fouth_level_child?.parent_id
                                                            )
                                                          }
                                                        >
                                                          <div className="line-space" />
                                                          <Col>
                                                            <Col className="d-flex level-btn justify-content-between">
                                                              <div className="d-flex align-items-center">
                                                                <div className="text-start ms-2">
                                                                  <p className="text-black fs-14 f3">
                                                                    {
                                                                      fouth_level_child?.name
                                                                    }
                                                                  </p>
                                                                  <p className="text-dark fs-12 f1">
                                                                    Level
                                                                    {
                                                                      fouth_level_child?.level
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </Col>
                                                            {fifthx_child &&
                                                              fifthx_child?.map(
                                                                (
                                                                  fifth_level_child
                                                                ) =>
                                                                  fifth_level_child?.parent_id ==
                                                                    fouth_level_child?.id && (
                                                                    <Col
                                                                      xs={12}
                                                                      key={
                                                                        fifth_level_child?.id
                                                                      }
                                                                      className="level-master d-flex justify-content-end pointer"
                                                                      onClick={() =>
                                                                        materDetailsHandler(
                                                                          fifth_level_child?.parent_id
                                                                        )
                                                                      }
                                                                    >
                                                                      <div className="line-space" />
                                                                      <Col>
                                                                        <Col className="d-flex level-btn justify-content-between">
                                                                          <div className="d-flex align-items-center">
                                                                            <div className="text-start ms-2">
                                                                              <p className="text-black fs-14 f3">
                                                                                {
                                                                                  fifth_level_child?.name
                                                                                }
                                                                              </p>
                                                                              <p className="text-dark fs-12 f1">
                                                                                Level
                                                                                {
                                                                                  fifth_level_child?.level
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                          </div>
                                                                        </Col>
                                                                      </Col>
                                                                    </Col>
                                                                  )
                                                              )}
                                                          </Col>
                                                        </Col>
                                                      )
                                                  )}
                                              </Col>
                                            </Col>
                                          )
                                      )}
                                  </Col>
                                </Col>
                              )
                          )}
                      </Col>
                    </Col>
                  </Col>
                )
            )}
          </Col>
        ))}
    </Col>
  );
};
