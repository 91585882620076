import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { logo } from "../../assets/img";
import { api } from "../../constants/Services";
import { useLocation } from "react-router-dom";
import Header from "../../component/Header/Header";
import useUser from "../../constants/Storage/userData";
import html2PDF from "jspdf-html2canvas";

function WorkOrderPrint() {
  const location = useLocation();
  const { user } = useUser();
  const [poPrintDetails, setPOPrintDetails] = useState([]);
  const [terms, setTerms] = useState([]);
  const [poAnnex, setPOAnnex] = useState([]);
  const [numToWords, setNumToWords] = useState("");
  const [poTable, setPoTable] = useState([]);

  useEffect(() => {}, []);
  const downloadPdfDocument = () => {
    const input = document.getElementById("work-order-print-main-cont");

    html2PDF(input, {
      jsPDF: {
        format: "a4",
      },
      html2canvas: {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
      },

      imageType: "image/jpeg",
      output: "./pdf/generate.pdf",
    });
  };
  return (
    <Col xs={12} className="py-2 px-1 p-md-3 w-lg-90">
      <Header />
      <div id="work-order-print-main-cont" className="po-main-box my-3">
        <section className="p-3">
          <header className="d-flex ">
            <Col xs={1} className="d-flex my-3 ">
              <img
                className="ms-4"
                style={{ width: "70px", height: "70px" }}
                src={logo}
                alt="logo"
              />
            </Col>
            <Col
              xs={7}
              className="d-flex flex-column po-vendor-detail-invoice-box my-3">
              <h5 className="mx-auto py-1">
                Crescon Projects and Services Pvt Ltd
              </h5>
              <p className="py-1 mx-auto ">
                3rd Floor, Gulecha Towers 158, Arcot Road, Vadapalani Chennai –
                600 026
              </p>
              <h6 className="m-auto py-1">Work Order</h6>
            </Col>
            <Col xs={4} className="my-auto ">
              <p className="py-1 fs-13px">
                ISO 9001:2015 : Quality Management System
              </p>
              <p className="py-1 fs-13px">
                ISO 14001:2015 : Environmental Management System
              </p>
              <p className="py-1 fs-13px">
                ISO 45001:2018 : Occupational Health & Safety Management System
              </p>
            </Col>
          </header>
          <main className="">
            <section className="d-flex">
              <Col xs={6} className="po-vendor-detail-box p-3">
                <h6>To</h6>
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>Vendor Name</label>
                  </Col>
                  <Col md={8}>
                    <p>-</p>
                  </Col>
                </div>
                <div className="d-flex  my-1 py-1">
                  <Col md={4}>
                    <label>Address</label>
                  </Col>
                  <Col md={8}>
                    <p>-</p>
                  </Col>
                </div>
                <div className="d-flex ">
                  <Col md={4}>
                    <label>State</label>
                  </Col>
                  <Col md={8}>
                    <p>{poPrintDetails?.vendor?.state ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>Mobile No</label>
                  </Col>
                  <Col md={8}>
                    <p>{poPrintDetails?.vendor?.mobile ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex ">
                  <Col md={4}>
                    <label>Email Id</label>
                  </Col>

                  <Col md={8}>
                    <p>{poPrintDetails?.vendor?.email ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex ">
                  <Col md={4}>
                    <label>GST No</label>
                  </Col>

                  <Col md={8}>
                    <p>{poPrintDetails?.vendor?.gst_no ?? "-"}</p>
                  </Col>
                </div>
              </Col>
              <Col xs={6} className="po-vendor-detail-invoice-box p-3">
                <div className="d-flex ">
                  <label>Po No</label>
                  <p>{poPrintDetails?.po_no ?? "-"}</p>
                </div>
                <div className="d-flex ">
                  <label>PR Date</label>
                  <p>{poPrintDetails?.po_date ?? "-"}</p>
                </div>
              </Col>
            </section>
            <section className="d-flex">
              <Col
                style={{ borderRight: "1px solid black" }}
                xs={6}
                className="po-vendor-detail-invoice-box p-3">
                <h6>Invoicing Address:</h6>

                <div className="d-flex flex-wrap">
                  <Col md={4}>
                    <label>Head Office</label>
                  </Col>
                  <Col md={8}>
                    <p>Crescon Projects and Services Pvt Ltd</p>
                  </Col>
                  {/* </div> */}
                  <Col md={4}>
                    <label>Address</label>
                  </Col>
                  <Col md={8}>
                    <p>
                      3rd Floor, Gulecha Towers 158, Arcot Road, Vadapalani
                      Chennai – 600 026
                    </p>
                  </Col>

                  <Col md={4} className="mt-4">
                    <label>Contact Person</label>
                  </Col>
                  <Col md={8} className="mt-4">
                    <p>M. Janakiraman</p>
                  </Col>
                  <Col md={4}>
                    <label>Contact No</label>
                  </Col>
                  <Col md={8}>
                    <p>044 - 2365 2551</p>
                  </Col>
                  <Col md={4}>
                    <label>Mail id</label>
                  </Col>
                  <Col md={8}>
                    <p>purchase@cresconprojects.com</p>
                  </Col>
                  <Col md={4}>
                    <label>Gst No</label>
                  </Col>
                  <Col md={8}>
                    <p>33AAECC6175F1ZT</p>
                  </Col>
                </div>
              </Col>
              <Col xs={6} className="po-vendor-detail-invoice-box p-3">
                <h6>Delivery Address:</h6>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>Project Name</label>
                  </Col>
                  <Col xs={8}>
                    <p>{poPrintDetails?.project?.name ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>Project Address</label>
                  </Col>
                  <Col xs={8}>
                    <p>{"-"}</p>
                  </Col>
                </div>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>State</label>
                  </Col>
                  <Col xs={8}>
                    <p>{poPrintDetails?.project?.state ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>District</label>
                  </Col>
                  <Col xs={8}>
                    <p>{poPrintDetails?.project?.district ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>Pincode</label>
                  </Col>

                  <Col xs={8}>
                    <p>{poPrintDetails?.project?.pincode ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>Contact No</label>
                  </Col>
                  <Col xs={8}>
                    <p>{poPrintDetails?.project?.mobile ?? "-"}</p>
                  </Col>
                </div>
                <div className="d-flex">
                  <Col xs={4}>
                    <label>Mail id</label>
                  </Col>
                  <Col xs={8}>
                    <p>{poPrintDetails?.project?.email ?? "-"}</p>
                  </Col>
                </div>
              </Col>
            </section>
            <Col className="p-2  m-1 text-center">
              <div
                style={{ overflowX: "scroll" }}
                className="po-out-table bg-white">
                <table
                  id="po-output-table"
                  className="table table-responsive-xl">
                  <thead>
                    <tr>
                      <th>Sl.No</th>
                      <th>Boq Code</th>
                      <th>Boq Description</th>
                      <th>Work Description</th>
                      <th>UOM</th>
                      <th>Basic Rate</th>
                      <th>Net Value</th>
                      <th>Total Value</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </Col>
            <div className="d-flex gap-2 m-3">
              <label>Amount In Words</label>
              <p className="mt-1">({numToWords})</p>
            </div>
            <section className="po-terms-box">
              <h6>Terms And Conditions</h6>
              <div>
                <ul className="">
                  {terms?.map((term) => (
                    <li className="py-1">{term}</li>
                  ))}
                </ul>
              </div>
            </section>
            <section>
              <h6 className="px-2 pt-2 m-1">Annexure Details</h6>
              <Col className="emply-master-form px-2  m-1 text-center">
                <div
                  style={{ overflowX: "scroll" }}
                  className="boq-table bg-white">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className=""> Sl.No</th>
                        <th className="">Spefication and Description</th>
                        <th className="">Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {poAnnex &&
                        poAnnex?.map((annex, ind) => (
                          <tr key={annex?.id}>
                            <td>{ind + 1}</td>
                            <td>{annex?.descriptions}</td>
                            <td>{annex?.quantity}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </Col>
              <div className="d-flex justify-content-between w-90">
                <div>
                  <p>{user?.name}</p>
                  <label>Prepared</label>
                </div>
                <p>Approved</p>
              </div>
            </section>
          </main>
          <footer className="d-flex">
            <Col
              xs={6}
              style={{ borderRight: "1px solid black", minHeight: "6rem" }}
              className="d-flex flex-column">
              <p className="">
                Please Sign and Return Duplicate Copy as a Token of Acceptance
              </p>
              <p className="mt-auto mb-1">Vendor Acceptance</p>
            </Col>
            <Col xs={6} className="d-flex flex-column">
              <p className="ms-auto me-2 pe-3">
                For Crescon Projects and Services Pvt Ltd
              </p>
              <p className="mt-auto ms-auto me-4 mb-1">Authorised Signatory</p>
            </Col>
          </footer>
          {/* Register Office Address:  */}
        </section>
      </div>
      <button
        class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
        onClick={downloadPdfDocument}>
        Print
      </button>
    </Col>
  );
}

export default WorkOrderPrint;
