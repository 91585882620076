import React from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";
import { Projects } from "../../constants/StaticData";

const LabourMasterForm = () => {
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="ms-xl-0 w-95">Labour Master</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Name</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter your Name"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Labour Id</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter your Labour Id"
          />
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Labour Photo</label>
          <div className="d-sm-flex mt-1 w-90 ms-0 emply-mas-file-box bg-lightBlue">
            <input
              type="file"
              className=" border-0 emply-mas-file-input px-1"
              placeholder="Upload Your Photo"
            />
          </div>
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <div className="inputinner-box text-start w-90 ms-0">
            <label>Catagories</label>
            <Select className="input-select" options={Projects} />
          </div>
        </Col>
        <Col xs={12} sm={12} className="inputinner-box text-start">
          <button className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
            Submit
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default LabourMasterForm;
