import React, { useState } from "react";
import { Col } from "react-bootstrap";

const IsmMaterialRequestAmendForm = () => {
  const [headOffice, setHeadOffice] = useState("");
  const [materialType, setMaterialType] = useState("");
  const [projectName, setProjectName] = useState("");
  const [prNo, setPrNo] = useState("");
  const [requestionType, setRequestionType] = useState("");
  const [pRDDate, setPRDDate] = useState("");

  const [headOfficeErr, setHeadOfficeErr] = useState(false);
  const [materialTypeErr, setMaterialTypeErr] = useState(false);
  const [projectNameErr, setProjectNameErr] = useState(false);
  const [prNoErr, setPrNoErr] = useState(false);
  const [requestionTypeErr, setRequestionTypeErr] = useState(false);
  const [pRDDateErr, setPRDDateErr] = useState(false);

  const submitHandler = () => {
    if (
      headOffice?.length == 0 ||
      materialType?.length == 0 ||
      projectName?.length == 0 ||
      requestionType?.length == 0 ||
      pRDDate?.length == 0
    ) {
      setHeadOfficeErr(true);
      setMaterialTypeErr(true);
      setProjectNameErr(true);
      setPrNoErr(true);
      setRequestionTypeErr(true);
      setPRDDateErr(true);
    } else {
      alert("saved");
    }
  };
  return (
    <>
      {/* <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <h4 className="w-95 ms-xl-0">Material Request Amendment</h4>
        <Col className="d-flex flex-wrap mx-lg-3">
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Head Office</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Head Office"
            />
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Material Type</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Material Type"
            />
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Project Name</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Project Name"
            />
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">PR No</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter PR No"
            />
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Requestion Type</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Requestion Type"
            />
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">PR Date</label>
            <input
              type="date"
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Date"
            />
          </Col>
          <Col xs={12} sm={12} className="inputinner-box text-start">
            <button className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
              Fetch
            </button>
          </Col>
        </Col>
      </Col>
      // NEW */}
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <h4 className="w-95 ms-xl-0">Material Request</h4>
        <Col className="d-flex flex-wrap mx-lg-3">
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Head Office</label>
            <p
              style={{ minHeight: "2rem", width: "90%", marginRight: "auto" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              Crescon Projects and Service Pvt Ltd
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Material Type</label>
            <select
              className="input-select bg-lightBlue ms-0"
              style={{ border: "2px solid #f1f7ff" }}
              onChange={(e) => setMaterialType(e.target.value)}
              value={materialType}>
              <option value={""}>select Material type</option>
              <option value={"ELECTRICAL"}>ELECTRICAL</option>
              <option value={"CIVIL"}>CIVIL</option>
              <option value={"MECHANICAL"}>MECHANICAL</option>
              <option value={"HVAC"}>HVAC</option>
              <option value={"FIRE FIGHTING"}>FIRE FIGHTING</option>
              <option value={"ELV"}>ELV</option>
              <option value={"OTHERS"}>OTHERS</option>
              <option value={"PHE"}>PHE</option>
              <option value={"PROCESS PIPE"}>PROCESS PIPE</option>
              <option value={"SOLVENT PIPE"}>SOLVENT PIPE</option>
            </select>
            {materialType?.length == 0 && materialTypeErr && (
              <p className="err">enter material type</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Project Name</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Project Name"
              onChange={(e) => setProjectName(e.target.value)}
              value={projectName}
            />
            {projectName?.length == 0 && projectNameErr && (
              <p className="err">enter project name</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">PR No</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter PR No"
              onChange={(e) => setPrNo(e.target.value)}
              value={prNo}
            />
            {prNo?.length == 0 && prNoErr && <p className="err">enter PR no</p>}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Requestion Type</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Requestion Type"
              onChange={(e) => setRequestionType(e.target.value)}
              value={requestionType}
            />
            {requestionType?.length == 0 && requestionTypeErr && (
              <p className="err">enter requestion type</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">PR Date</label>
            <input
              type="date"
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Date"
              onChange={(e) => setPRDDate(e.target.value)}
              value={pRDDate}
            />
            {pRDDate?.length == 0 && pRDDateErr && (
              <p className="err">enter PR date</p>
            )}
          </Col>
          <Col xs={12} sm={12} className="inputinner-box text-start">
            <button
              className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
              onClick={submitHandler}>
              Fetch
            </button>
          </Col>
        </Col>
      </Col>
    </>
  );
};

export default IsmMaterialRequestAmendForm;
