import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import RolesRespForm from "../../component/RolesResp/RolesRespForm";
import RolesRespTab from "../../component/RolesResp/RolesRespTab";

const RolesRespScreen = () => {
  const [employeeId, setEmployeeId] = useState();
  const [employeeName, setEmployeeName] = useState("");
  const [passErr, setPassErr] = useState(false);
  const [passAlaErr, setPassAlaErr] = useState(true);
  const [empRole, setEmpRole] = useState("");
  const [employeeDepartment, setEmployeeDepartment] = useState("");
  const [subEmployeeDepartment, setSubEmployeeDepartment] = useState([]);
  const [employeeDesination, setEmployeeDesination] = useState("");
  const [employeeProjects, setEmployeeProjects] = useState([]);
  const [password, setPassword] = useState("");
  const [checkbox, setcheckbox] = useState({
    web: false,
    mobile: false,
  });

  const [notibox, setnotibox] = useState({
    level_1: false,
    level_2: false,
    level_3: false,
  });

  const [approveBox, setApproveBox] = useState({
    mr: false,
    po: false,
  });

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <RolesRespForm
        setApproveBox={setApproveBox}
        approveBox={approveBox}
        setPassErr={setPassErr}
        setEmployeeId={setEmployeeId}
        passErr={passErr}
        checkbox={checkbox}
        password={password}
        setPassword={setPassword}
        employeeDesination={employeeDesination}
        setcheckbox={setcheckbox}
        notibox={notibox}
        setnotibox={setnotibox}
        setEmployeeDesination={setEmployeeDesination}
        empRole={empRole}
        employeeDepartment={employeeDepartment}
        setEmployeeDepartment={setEmployeeDepartment}
        setSubEmployeeDepartment={setSubEmployeeDepartment}
        subEmployeeDepartment={subEmployeeDepartment}
        employeeId={employeeId}
        employeeName={employeeName}
        setEmployeeName={setEmployeeName}
        setEmpRole={setEmpRole}
        setPassAlaErr={setPassAlaErr}
        setEmployeeProjects={setEmployeeProjects}
        employeeProjects={employeeProjects}
      />
      <RolesRespTab
        setPassErr={setPassErr}
        passErr={passErr}
        passAlaErr={passAlaErr}
        checkbox={checkbox}
        setcheckbox={setcheckbox}
        notibox={notibox}
        setnotibox={setnotibox}
        password={password}
        employeeDesination={employeeDesination}
        setEmployeeDesination={setEmployeeDesination}
        empRole={empRole}
        employeeDepartment={employeeDepartment}
        setEmployeeDepartment={setEmployeeDepartment}
        setSubEmployeeDepartment={setSubEmployeeDepartment}
        subEmployeeDepartment={subEmployeeDepartment}
        employeeId={employeeId}
        employeeName={employeeName}
        setEmployeeName={setEmployeeName}
        setEmpRole={setEmpRole}
        setEmployeeProjects={setEmployeeProjects}
        employeeProjects={employeeProjects}
      />
    </Col>
  );
};

export default RolesRespScreen;
