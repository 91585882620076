import React, { useState, useEffect } from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import eye_icon from "../../assets/icons/common/Eye.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Col, Modal, Button } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";
import { PaginationControl } from "react-bootstrap-pagination-control";

function GrnList() {
  const [selectedProject, setSelectedProject] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [documentUrl, setDocumentUrl] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const getProjects = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  const getList = (projectId, page) => {
    let param = `?page=${page}`;
    let formdata = new FormData();
    formdata.append("project_id", projectId);
    api
      .grnList(param, formdata)
      .then((res) => {
        // console.log("grnList", res);
        setList(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const dummy_date = [
    1,
    // "Sample Project",
    "Ks awademy",
    "PO1298765",
    "12-06-2020",
    "GRN345678",
    "23-06-2023",
    "Ninos",
    "Active",
  ];

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (searchParams?.get("projectID")) {
      setSelectedProject(searchParams?.get("projectID"));
      getList(searchParams?.get("projectID"), 1);
    }
  }, [searchParams?.get("projectID")]);

  // useEffect(() => {
  //   getList();
  // }, [page]);

  // useEffect(() => {
  //   getList();
  // }, [selectedProject, page]);

  const materialTransferDetailHandler = (sel_mt, mt_colde, mt_date) => {
    navigate("/procurement/po-output", {
      state: { id: sel_mt },
    });
  };

  const documentViewr = (item) => {
    setShowModal(true);
    setDocumentUrl(item?.img_url);
  };

  const purcaseOrderDetailHandler = (sel_po, po_num, po_date) => {
    navigate("/ism/grn-output", {
      state: { id: sel_po },
    });
    // navigate("/procurement/purchase-order-action", {
    //   state: { id: sel_po, po_no: po_num, po_date: po_date },
    // });
  };

  // console.log("list?.pagination?.total", list?.pagination?.total);

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white my-3 text-center">
        <header>
          <Col xs={12} className="d-flex my-2">
            <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 ms-auto pointer"
              value={selectedProject}
              onChange={(e) => {
                setSelectedProject(e.target.value);
                getList(e.target.value, 1);
                setPage(1);
              }}
            >
              <option>Select Project</option>
              {projectList?.map((project) => (
                <option key={project?.id} value={project?.id}>
                  {project?.name}
                </option>
              ))}
            </select>
          </Col>
        </header>
        <h4 className="my-1 py-1">GRN List</h4>

        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                {/* <th>Project Name</th> */}
                <th>Vendor Name</th>
                <th>PO Number</th>
                <th>PO Date </th>
                <th>GRN No</th>
                <th>GRN Date</th>
                <th>Created By</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {list?.grns?.map((material, ind) => {
                return (
                  <tr>
                    <td style={{ fontSize: "12px" }}>{ind + 1}</td>
                    <td style={{ fontSize: "12px", textAlign: "start" }}>
                      {material?.vendor?.company_name
                        ? material?.vendor?.company_name
                        : material?.vendor?.name}
                    </td>
                    <td style={{ fontSize: "12px" }}>
                      {material?.purchase_order?.po_no
                        ? material?.purchase_order?.po_no
                        : "-"}
                    </td>
                    <td style={{ fontSize: "12px" }}>
                      {material?.purchase_order?.po_date
                        ? material?.purchase_order?.po_date
                        : "-"}
                    </td>
                    <td style={{ fontSize: "12px" }}>{material?.grn_no}</td>
                    <td style={{ fontSize: "12px" }}>{material?.grn_date}</td>
                    <td style={{ fontSize: "12px" }}>
                      {material?.employee?.name}
                    </td>
                    <td style={{ fontSize: "12px" }}>
                      {material?.status == 1
                        ? "Pending"
                        : material?.status == 2
                        ? "Accepted"
                        : "Rejected"}
                    </td>
                    <td style={{ fontSize: "12px" }}>
                      <div className="d-flex gap-2">
                        <div>
                          <AiOutlineFilePdf
                            className="pointer fs-5"
                            onClick={() => documentViewr(material)}
                          />
                        </div>
                        <div>
                          <Link
                            to={`/ism/grn-output?id=${
                              material?.id
                            }&projectID=${+selectedProject}`}
                          >
                            <img
                              onClick={() =>
                                purcaseOrderDetailHandler(
                                  material?.purchase_order?.id,

                                  material?.purchase_order?.po_no,

                                  material?.purchase_order?.po_date
                                )
                              }
                              style={{ width: "20px" }}
                              className="pointer ms-2"
                              src={eye_icon}
                              alt="eye-icon"
                            />
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Col>
      <Modal
        // size="md"
        // style={{ height: "75vh" }}
        show={showModal}
        // className="pdf-modal-cont"
        onHide={() => setShowModal(!showModal)}
        dialogClassName="modal-90w"
        centered
        // size="lg"
        // aria-labelledby="example-modal-sizes-title-lg"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton className="border-0 d-flex">
          <Modal.Title className="text-center mx-auto">GRN</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "100%", textAlign: "center" }}>
            <iframe
              src={documentUrl}
              title="Sample Pdf"
              className="w-100"
              style={{ height: "500px" }}
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={() => setShowModal(!showModal)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="my-1 py-1 d-flex justify-content-end">
        <PaginationControl
          page={page}
          between={4}
          total={list?.pagination?.total}
          limit={10}
          changePage={(page) => {
            setPage(page);
            getList(selectedProject, page);
            console.log(page);
          }}
          ellipsis={1}
        />
      </div>
    </Col>
  );
}

export default GrnList;
