import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { api } from "../../constants/Services";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";

function VendorActionPage() {
  const [vendorDetails, setVendorDetails] = useState();
  const navigate = useNavigate();
  // console.log(
  // "🚀 ~ file: VendorView.js:10 ~ VendorActionPage ~ vendorDetails:",
  // vendorDetails
  // );
  const location = useLocation();

  const vendor_details = location?.state[0];

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [prodectType, setProdectType] = useState("");
  const [vendorType, setVendorType] = useState("");
  const [doorNo, setDoorNo] = useState("");
  const [address, setAddress] = useState("");
  const [states, setStates] = useState("");
  const [district, setDistrict] = useState("");
  const [taluk, setTaluk] = useState("");
  const [pincode, setPincode] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [mobile, setMobile] = useState("");
  const [pan, setPan] = useState("");
  const [gst, setGstNo] = useState("");
  const [gstType, setGstType] = useState("");
  const [gstState, setGstState] = useState("");
  const [gstTypeErr, setGstTypeErr] = useState(false);
  const [gstNoErr, setGstNoErr] = useState("");
  const [gstDate, setGstDate] = useState("");
  const [gstDateErr, setGstDateErr] = useState("");
  const [rating, setRating] = useState("");
  const [ratingErr, setRatingErr] = useState(false);
  const [panErr, setPanErr] = useState(false);
  const [mobileErr, setMobileErr] = useState(false);
  const [phoneNoErr, setPhoneNoErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [pincodeErr, setPincodeErr] = useState(false);
  const [talukErr, setTalukErr] = useState(false);
  const [districtErr, setDistrictErr] = useState(false);
  const [statesErr, setStatesErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [codeErr, setCodeErr] = useState(false);
  const [vendorTyeErr, setVendorTypeErr] = useState(false);
  const [prodectTypeErr, setProdectTypeErr] = useState(false);
  const [doorNoErr, setDoorNoErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);
  const [btn, setBtn] = useState(false);

  useEffect(() => {
    let data = {
      vendor_id: vendor_details?.id,
    };
    api.vendorView(data).then((res) => {
      if (res?.status == "success") {
        setVendorDetails(res?.vendor);
        let data = res?.vendor;
        setName(data?.company_name);
        setCode(data?.code);
        setProdectType(data?.product_type);
        setDoorNo(data?.door_no);
        setAddress(data?.address);
        setStates(data?.state);
        setDistrict(data?.district);
        setTaluk(data?.taluk);
        setPincode(data?.pincode);
        setEmail(data?.email);
        setPhoneNo(data?.phone_no);
        setMobile(data?.mobile);
        setPan(data?.pan);
        setGstNo(data?.gst_no);
        setGstDate(data?.gst_date);
        let split = data?.gst_state_type?.split("-");
        if (split?.length > 0) {
          setGstType(split[1]);
          setGstState(split[0]);
        }

        setRating(data?.rating);
        setVendorType(data?.type);
      }
    });
  }, [vendor_details]);

  // console.log("gstType", gstType);

  const submitHandler = () => {
    if (
      name?.length == 0 ||
      code?.length == 0 ||
      prodectType?.length == 0 ||
      doorNo?.length == 0 ||
      address?.length == 0 ||
      states?.length == 0 ||
      district?.length == 0 ||
      taluk?.length == 0 ||
      pincode.length == 0 ||
      email?.length == 0 ||
      phoneNo?.length == 0 ||
      mobile?.length == 0 ||
      pan?.length == 0 ||
      gst?.length == 0 ||
      gstDate?.length == 0 ||
      gstType?.length == 0 ||
      rating?.length == 0 ||
      vendorType?.length == 0
    ) {
      setNameErr(true);
      setCodeErr(true);
      setProdectTypeErr(true);
      setDoorNoErr(true);
      setAddressErr(true);
      setStatesErr(true);
      setDistrictErr(true);
      setTalukErr(true);
      setPincodeErr(true);
      setEmailErr(true);
      setPhoneNoErr(true);
      setMobileErr(true);
      setPanErr(true);
      setGstDateErr(true);
      setGstNoErr(true);
      setGstTypeErr(true);
      setRatingErr(true);
      setVendorTypeErr(true);
    } else {
      setBtn(true);
      let formdata = new FormData();
      formdata.append("vendor_id", vendor_details?.id);
      formdata.append("company_name", name);
      formdata.append("name", name);
      formdata.append("code", code);
      formdata.append("type", vendorType);
      formdata.append("product_type", prodectType);
      formdata.append("door_no", doorNo);
      formdata.append("address", address);
      formdata.append("state", states);
      formdata.append("district", district);
      formdata.append("taluk", taluk);
      formdata.append("pincode", pincode);
      formdata.append("email", email);
      formdata.append("phone_no", phoneNo);
      formdata.append("mobile", mobile);
      formdata.append("pan", pan);
      formdata.append("gst_no", gst);
      formdata.append("gst_date", gstDate);
      formdata.append("gst_status", vendorDetails?.gst_status);
      formdata.append("gst_type", gstType);
      formdata.append("rating", rating);
      formdata.append("gst_state", gstState);
      formdata.append(
        "bank_name",
        vendorDetails?.vendor_bank_detail?.bank_name
      );
      formdata.append(
        "account_no",
        vendorDetails?.vendor_bank_detail?.account_no
      );
      formdata.append("ifsc", vendorDetails?.vendor_bank_detail?.ifsc);
      api
        .vendorEdit(formdata)
        .then((res) => {
          console.log("res", res);
          navigate(-1);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <Col xs={12} className="p-3">
      <Header />

      <Col
        xs={12}
        className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center"
      >
        <h4> Vendor View </h4>
        <Col xs={12} xxl={8} md={10} className="d-flex flex-wrap mx-auto">
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Vendor Name</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            {name?.length == 0 && nameErr && <p className="err">Enter Name</p>}
          </Col>

          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Code</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Code"
              onChange={(e) => setCode(e.target.value)}
              value={code}
            />
            {code?.length == 0 && codeErr && <p className="err">Enter Code</p>}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Product Type</label>
            <select
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 py-2"
              style={{ border: "2px solid #f1f7ff" }}
              onChange={(e) => setProdectType(e.target.value)}
              value={prodectType}
            >
              <option value={""} hidden disabled>
                Select product type
              </option>
              <option value={"ELECTRICAL"}>ELECTRICAL</option>
              <option value={"CIVIL"}>CIVIL</option>
              <option value={"MECHANICAL"}>MECHANICAL</option>
              <option value={"HVAC"}>HVAC</option>
              <option value={"FIRE FIGHTING"}>FIRE FIGHTING</option>
              <option value={"ELV"}>ELV</option>
              <option value={"OTHERS"}>OTHERS</option>
              <option value={"PHE"}>PHE</option>
              <option value={"PROCESS PIPE"}>PROCESS PIPE</option>
              <option value={"SOLVENT PIPE"}>SOLVENT PIPE</option>
            </select>
            {prodectType?.length == 0 && prodectTypeErr && (
              <p className="err">Select Product Type</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Vendor Type</label>
            <select
              name=""
              id=""
              onChange={(e) => setVendorType(e.target.value)}
              value={vendorType}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            >
              <option value="" hidden disabled>
                Choose Vendor Type
              </option>
              <option value="Creditor">Creditor</option>
              <option value="Debitor">Debitor</option>
            </select>
            {vendorType?.length == 0 && vendorTyeErr && (
              <p className="err">select vendor type</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Door No & Street</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Door No"
              onChange={(e) => setDoorNo(e.target.value)}
              value={doorNo}
            />
            {doorNo?.length == 0 && doorNoErr && (
              <p className="err">Enter Door No.</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Address</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Address"
              onChange={(e) => setAddress(e.target.value)}
              value={address}
            />
            {address?.length == 0 && addressErr && (
              <p className="err">Enter Address</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">State</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter State"
              onChange={(e) => setStates(e.target.value)}
              value={states}
            />
            {states?.length == 0 && statesErr && (
              <p className="err">Enter State</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">District</label>

            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter District"
              onChange={(e) => setDistrict(e.target.value)}
              value={district}
            />
            {district?.length == 0 && districtErr && (
              <p className="err">Enter District</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Taluk</label>

            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Taluk"
              onChange={(e) => setTaluk(e.target.value)}
              value={taluk}
            />
            {taluk?.length == 0 && talukErr && (
              <p className="err">Enter Taluk</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Pincode</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Pincode"
              onChange={(e) => setPincode(e.target.value)}
              value={pincode}
            />
            {pincode?.length == 0 && pincodeErr && (
              <p className="err">Enter Pincode</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Email</label>

            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            {email?.length == 0 && emailErr && (
              <p className="err">Enter Email</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Telephone No</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Phone No"
              onChange={(e) => setPhoneNo(e.target.value)}
              value={phoneNo}
            />
            {phoneNo?.length == 0 && phoneNoErr && (
              <p className="err">Enter Phone No.</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Mobile No</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Mobile"
              onChange={(e) => setMobile(e.target.value)}
              value={mobile}
            />
            {mobile?.length == 0 && mobileErr && (
              <p className="err">Enter Mobile</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">PAN No.</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter PAN"
              onChange={(e) => setPan(e.target.value)}
              value={pan}
            />
            {pan?.length == 0 && panErr && <p className="err">Enter PAN</p>}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">GST Status</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
            >
              {vendorDetails?.gst_status == 1 ? "Yes" : "No"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">GST No.</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter GST No"
              onChange={(e) => setGstNo(e.target.value)}
              value={gst}
            />
            {gst?.length == 0 && gstNoErr && (
              <p className="err">Enter GST No</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">GST Date</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter GST No"
              type={"date"}
              onChange={(e) => setGstDate(e.target.value)}
              value={gstDate}
            />
            {gstDate?.length == 0 && gstDateErr && (
              <p className="err">Select GST Date</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">GST Type</label>

            <select
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter GST Type"
              onChange={(e) => setGstType(e.target.value)}
              value={gstType}
            >
              <option hidden disabled>
                Select GST Type
              </option>
              <option value="regular">Regular</option>
              <option value="composite">Composite</option>
            </select>
            {gstType?.length == 0 && gstTypeErr && (
              <p className="err">Select GST Typeo</p>
            )}
          </Col>

          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Rating</label>

            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Rating"
              onChange={(e) => setRating(e.target.value)}
              value={rating}
            />
            {rating?.length == 0 && ratingErr && (
              <p className="err">Enter Rating</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Created Date</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
            >
              {moment(vendorDetails?.updated_at).format("YYYY-MM-DD")}
            </p>
          </Col>
        </Col>
        <div className="d-flex justify-content-end gap-3">
          <button
            className="bg-gray border-0 rounded-1 my-3 bg-success text-white f-1 fs-14 px-4 py-2 "
            onClick={() => submitHandler()}
          >
            Update
          </button>
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 "
            onClick={() => navigate("/procurement/vendor-details")}
          >
            Close
          </button>
        </div>
      </Col>
    </Col>
  );
}

export default VendorActionPage;
