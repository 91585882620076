// @ts-nocheck
import React, { useState } from "react";
import useUser from "../constants/Storage/userData";
import { Modal, Button, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../constants/Services";
import {
  billing,
  dashboard,
  exection,
  hr,
  inventory,
  procurement,
} from "../assets/img";
// import Login from "../screens/Login";
import NavBarTop from "./NavBarTop";

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const permissions = localStorage.getItem("permissions");
  const [bB, setBB] = useState(false);
  const [hrM, setHrM] = useState(true);
  const [procurment, setProcurment] = useState(false);
  const [execution, setExecution] = useState(false);
  const [ism, setIsm] = useState(false);
  const [fn, setFn] = useState(false);
  const [report, setReport] = useState(false);
  const [logout, setLogout] = useState(false);
  const [show, setShow] = useState(false);
  const [changePassword, setChangePassword] = useState("");
  const [passConfirm, setPassConfirm] = useState("");

  const changePasswordHandler = () => {
    let formData = new FormData();
    formData.append("password", changePassword);
    formData.append("password_confirmation", passConfirm);
    api.changeEmployeePassword(formData).then((res) => {
      setShow(!show);
      setTimeout(logOutAcc(), 2000);
    });
  };

  // console.log("permissions", permissions);

  const logOutAcc = () => {
    let confirm = window.confirm("Do you want to Logout?");
    if (confirm) {
      localStorage.clear();
      alert("Logut Successfully");
      setTimeout(() => {
        window.location.href = "/";
      }, 800);
    }
  };

  return (
    <div className="nav-bar">
      <NavBarTop />
      <div className="menu-list">
        {user?.emp_id == "CPS/1378" || user?.emp_id == "CPS/001" ? (
          <button
            onClick={() => navigate("/dashbord")}
            className={
              splitLocation[1] === "dashbord"
                ? "toggle-nav-btn-active cust-btn fs-14"
                : "toggle-nav-btn cust-btn fs-14"
            }
          >
            <div
              className={
                splitLocation[1] === "dashbord"
                  ? "menu-icon-active"
                  : "menu-icon"
              }
            >
              <img
                style={{
                  width: splitLocation[1] === "dashbord" ? "13px" : "15px",
                }}
                src={dashboard}
                alt="dashboard"
              />
            </div>
            DashBoard
          </button>
        ) : null}

        <button
          onClick={() => {
            setBB(!bB);
            setHrM(false);
            setProcurment(false);
            setIsm(false);
            setFn(false);
            setReport(false);
          }}
          className={
            splitLocation[1] === "home"
              ? "toggle-nav-btn-active cust-btn fs-14"
              : "toggle-nav-btn cust-btn fs-14"
          }
        >
          <div
            className={
              splitLocation[1] === "home" ? "menu-icon-active" : "menu-icon"
            }
          >
            <img
              style={{ width: splitLocation[1] === "home" ? "13px" : "15px" }}
              src={billing}
              alt="dashboard"
            />
          </div>
          Billing & Budget
        </button>
        <div className={bB ? "sub-menu bb" : "sub-menu"}>
          {permissions?.includes("CAN-COMPANY-BILLING & BUDGET") ? (
            <button onClick={() => navigate("/home/company-masters")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "company-masters"
                      ? "10px"
                      : splitLocation[2] == "company-master"
                      ? "10px"
                      : "0px",
                }}
              />
              Company Master
            </button>
          ) : null}
          {permissions?.includes("CAN-PROJECT CREATION-BILLING & BUDGET") ? (
            <button onClick={() => navigate("/home/project-creation")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "project-creation" ? "10px" : "0px",
                }}
              />
              Project Creation
            </button>
          ) : null}

          {permissions?.includes("CAN-BOQ CREATION-BILLING & BUDGET") ? (
            <button onClick={() => navigate("/home/projects-list")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "projects-list" ? "10px" : "0px",
                }}
              />
              Project List
            </button>
          ) : null}
          {permissions?.includes("CAN-BOQ CREATION-BILLING & BUDGET") ? (
            <button onClick={() => navigate("/home/boq-creation")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "boq-creation" ? "10px" : "0px",
                }}
              />
              BOQ Creation
            </button>
          ) : null}

          {/* {permissions?.includes("CAN-BOQ AMENDMENT-BILLING & BUDGET") ? ( */}
          {permissions?.includes("CAN-BOQ AMENDMENT-BILLING & BUDGET") ? (
            <button onClick={() => navigate("/home/boq-amendment")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "boq-amendment" ? "10px" : "0px",
                }}
              />
              BOQ Amendment
            </button>
          ) : null}

          {permissions?.includes("CAN-BOQ CREATION-BILLING & BUDGET") ? (
            <button onClick={() => navigate("/home/boq-amendment-list")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "boq-amendment-list" ? "10px" : "0px",
                }}
              />
              BOQ Amendment List
            </button>
          ) : null}

          {/* <button onClick={() => navigate("/home/dpr")}>
            <div
              className="active-line"
              style={{
                width: splitLocation[2] == "dpr" ? "10px" : "0px",
              }}
            />
            DPR
          </button> */}
        </div>

        <button
          onClick={() => {
            setBB(false);
            setHrM(!hrM);
            setProcurment(false);
            setIsm(false);
            setFn(false);
            setReport(false);
          }}
          className={
            splitLocation[1] === "hrm"
              ? "toggle-nav-btn-active cust-btn fs-14"
              : "toggle-nav-btn cust-btn fs-14"
          }
        >
          <div
            className={
              splitLocation[1] === "hrm" ? "menu-icon-active" : "menu-icon"
            }
          >
            <img
              style={{ width: splitLocation[1] === "home" ? "13px" : "15px" }}
              alt="dash-img"
              src={hr}
            />
          </div>
          HR Management
        </button>

        <div className={hrM ? "sub-menu bb" : "sub-menu"}>
          {permissions?.includes("CAN-EMPLOYEE MASTER-HR MANAGEMENT") ? (
            <button onClick={() => navigate("/hrm/employee-master")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "employee-master" ? "10px" : "0px",
                }}
              />
              Employee Master
            </button>
          ) : null}
          {permissions?.includes("CAN-LABOUR MASTER-HR MANAGEMENT") ? (
            <button onClick={() => navigate("/hrm/labour-master")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "labour-master" ? "10px" : "0px",
                }}
              />
              Labour Master
            </button>
          ) : null}
          {permissions?.includes("CAN-USER MANAGEMENTS-HR MANAGEMENT") ? (
            <button onClick={() => navigate("/hrm/user-management")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "user-management" ? "10px" : "0px",
                }}
              />
              User Managements
            </button>
          ) : null}
          {permissions?.includes("CAN-EMPLOYEE MANAGEMENT-HR MANAGEMENT") ? (
            <button onClick={() => navigate("/hrm/employee-management")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] === "employee-management" ? "10px" : "0px",
                }}
              />
              Employee Managements
            </button>
          ) : null}
          {permissions?.includes("CAN-ROLES RESPONSIBILITY-HR MANAGEMENT") ? (
            <button onClick={() => navigate("/hrm/roles-responsibility")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "roles-responsibility" ? "10px" : "0px",
                }}
              />
              Roles Responsibility
            </button>
          ) : null}
        </div>
        <button
          onClick={() => {
            setBB(false);
            setExecution(!execution);
            setHrM(false);
            setProcurment(false);
            setIsm(false);
            setFn(false);
            setReport(false);
          }}
          className={
            splitLocation[1] === "execution"
              ? "toggle-nav-btn-active cust-btn fs-14"
              : "toggle-nav-btn cust-btn fs-14"
          }
        >
          <div
            className={
              splitLocation[1] === "execution"
                ? "menu-icon-active"
                : "menu-icon"
            }
          >
            <img
              style={{ width: splitLocation[1] === "home" ? "13px" : "15px" }}
              src={exection}
              alt="dashoard"
            />
          </div>
          Execution
        </button>
        <div className={execution ? "sub-menu bb" : "sub-menu"}>
          {permissions?.includes("CAN-MATERIAL REQUEST-PROCUREMENT") && (
            <button onClick={() => navigate("/execution/material-request")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "material-request" ? "10px" : "0px",
                }}
              />
              Material Request
            </button>
          )}

          {permissions?.includes(
            "CAN-MATERIAL REQUEST AMENDMENT-PROCUREMENT"
          ) && (
            <button
              onClick={() => navigate("/execution/material-request-amendment")}
            >
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "material-request-amendment"
                      ? "10px"
                      : "0px",
                }}
              />
              Material Request Amendment
            </button>
          )}

          {permissions?.includes("CAN-MATERIAL REQUEST LIST-PROCUREMENT") && (
            <button
              onClick={() => navigate("/execution/material-request-list")}
            >
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "material-request-list"
                      ? "10px"
                      : "0px",
                }}
              />
              Material Request List
            </button>
          )}
        </div>
        <button
          onClick={() => {
            setBB(false);
            setHrM(false);
            setProcurment(!procurment);
            setIsm(false);
            setFn(false);
            setReport(false);
          }}
          className={
            splitLocation[1] === "procurement"
              ? "toggle-nav-btn-active cust-btn fs-14"
              : "toggle-nav-btn cust-btn fs-14"
          }
        >
          <div
            className={
              splitLocation[1] === "procurement"
                ? "menu-icon-active"
                : "menu-icon"
            }
          >
            <img
              style={{ width: splitLocation[1] === "home" ? "13px" : "15px" }}
              src={procurement}
              alt="dashboard"
            />
          </div>
          Procurement
        </button>
        <div className={procurment ? "sub-menu bb" : "sub-menu"}>
          {permissions?.includes("CAN-ASSETS MASTER-PROCUREMENT") ? (
            <button onClick={() => navigate("/procurement/assets-master")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "assets-master" ? "10px" : "0px",
                }}
              />
              Assets Master
            </button>
          ) : null}
          {permissions?.includes("CAN-ASSETS MASTER-PROCUREMENT") ? (
            <button onClick={() => navigate("/procurement/assets-list")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "assets-list" ? "10px" : "0px",
                }}
              />
              Assets List
            </button>
          ) : null}
          {permissions?.includes("CAN-ASSETS MASTER-PROCUREMENT") ? (
            <button onClick={() => navigate("/procurement/vendor-master")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "vendor-master" ? "10px" : "0px",
                }}
              />
              Vendor Master
            </button>
          ) : null}
          {permissions?.includes("CAN-VENDOR MASTER-PROCUREMENT") ? (
            <button onClick={() => navigate("/procurement/vendor-details")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "vendor-details" ? "10px" : "0px",
                }}
              />
              Vendor Details
            </button>
          ) : null}
          {permissions?.includes("CAN-GST REGISTRATION-PROCUREMENT") ? (
            <button onClick={() => navigate("/procurement/gst-registration")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "gst-registration" ? "10px" : "0px",
                }}
              />
              GST Registration
            </button>
          ) : null}
          {permissions?.includes("CAN-MATERIAL MASTER-PROCUREMENT") ? (
            <button onClick={() => navigate("/procurement/material-master")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "material-master" ? "10px" : "0px",
                }}
              />
              Material Master
            </button>
          ) : null}
          {permissions?.includes("CAN-MATERIAL MASTER LIST-PROCUREMENT") ? (
            <button onClick={() => navigate("/procurement/material-list")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "material-list" ? "10px" : "0px",
                }}
              />
              Material list
            </button>
          ) : null}
          {permissions?.includes("CAN-ATTRIBUTE MASTER-PROCUREMENT") ? (
            <button onClick={() => navigate("/procurement/attribute-master")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "attribute-master" ? "10px" : "0px",
                }}
              />
              Attribute Master
            </button>
          ) : null}
          {permissions?.includes("CAN-HSN CODE-PROCUREMENT") ? (
            <button onClick={() => navigate("/procurement/hsn")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "hsn" ? "10px" : "0px",
                }}
              />
              HSN
            </button>
          ) : null}
          {permissions?.includes("CAN-PO CREATION-PROCUREMENT") ? (
            <button onClick={() => navigate("/procurement/po-creation")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "po-creation" ? "10px" : "0px",
                }}
              />
              Po Creation
            </button>
          ) : null}
          {permissions?.includes("CAN-WORK ORDER-PROCUREMENT") ? (
            <button onClick={() => navigate("/procurement/work-order")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "work-order" ? "10px" : "0px",
                }}
              />
              Work Order
            </button>
          ) : null}
          {permissions?.includes("CAN-WORK ORDER AMENDMENT-PROCUREMENT") ? (
            <>
              <button
                onClick={() => navigate("/procurement/work-order-amendment")}
              >
                <div
                  className="active-line"
                  style={{
                    width:
                      splitLocation[2] == "work-order-amendment"
                        ? "10px"
                        : "0px",
                  }}
                />
                Work Order Amendment
              </button>
            </>
          ) : null}

          {permissions?.includes("CAN-WORK ORDER LIST-PROCUREMENT") ? (
            <button onClick={() => navigate("/procurement/work-order-list")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "work-order-list" ? "10px" : "0px",
                }}
              />
              Work Order List
            </button>
          ) : null}
          {permissions?.includes("CAN-PO CREATION AMENDMENT-PROCUREMENT") ? (
            <button
              onClick={() => navigate("/procurement/po-creation-amendment")}
            >
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "po-creation-amendment"
                      ? "10px"
                      : "0px",
                }}
              />
              Po Creation Amendment
            </button>
          ) : null}

          {permissions?.includes("CAN-PO LIST-PROCUREMENT") ? (
            <button onClick={() => navigate("/procurement/puchase-order-list")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "puchase-order-list" ? "10px" : "0px",
                }}
              />
              Purchase Order List
            </button>
          ) : null}
        </div>
        <button
          onClick={() => {
            setBB(false);
            setHrM(false);
            setProcurment(false);
            setIsm(!ism);
            setFn(false);
            setReport(false);
          }}
          className={
            splitLocation[1] === "ism"
              ? "toggle-nav-btn-active cust-btn fs-14"
              : "toggle-nav-btn cust-btn fs-14"
          }
        >
          <div
            className={
              splitLocation[1] === "ism" ? "menu-icon-active" : "menu-icon"
            }
          >
            <img
              style={{ width: splitLocation[1] === "ism" ? "13px" : "15px" }}
              src={inventory}
              alt="dashboard"
            />
          </div>
          Inventory & Stock Managemnet
        </button>
        <div className={ism ? "sub-menu bb" : "sub-menu"}>
          {/* {permissions?.includes(
            "CAN-MATERIAL REQUEST-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("ism/ism-material-request")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "ism-material-request" ? "10px" : "0px",
                }}
              />
              Material Request
            </button>
          ) : null} */}
          {/* {permissions?.includes(
            "CAN-MATERIAL REQUEST AMENDMENT-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button
              onClick={() => navigate("ism/ism-material-request-amendment")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "ism-material-request-amendment"
                      ? "10px"
                      : "0px",
                }}
              />
              Material Request Amendment
            </button>
          ) : null} */}
          {permissions?.includes(
            "CAN-GRN ENTRY-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/ism-gin-entry")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "ism-gin-entry" ? "10px" : "0px",
                }}
              />
              GRN Entry
            </button>
          ) : null}
          {permissions?.includes(
            "CAN-GRN VIEW-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/grn-list")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "grn-list" ? "10px" : "0px",
                }}
              />
              GRN List
            </button>
          ) : null}
          {permissions?.includes(
            "CAN-INVOICE BOOKING-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/invoice-booking")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "invoice-booking" ? "10px" : "0px",
                }}
              />
              Invoice Booking
            </button>
          ) : null}
          {permissions?.includes(
            "CAN-INVOICE BOOKING-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/invoice-booking-list")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "invoice-booking-list" ? "10px" : "0px",
                }}
              />
              Invoice Booking List
            </button>
          ) : null}
          {/* {permissions?.includes(
            "CAN-INVOICE SCRUTINY-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/invoice-Scrutiny")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "invoice-Scrutiny" ? "10px" : "0px",
                }}
              />
              Invoice Scrutiny
            </button>
          ) : null} */}
          {/* {permissions?.includes(
            "CAN-INVOICE SCRUTINY-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/invoice-Scrutiny-list")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "invoice-Scrutiny-list"
                      ? "10px"
                      : "0px",
                }}
              />
              Invoice Scrutiny List
            </button>
          ) : null} */}

          {permissions?.includes(
            "CAN-MATERIAL ISSUE-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/ism-material-issue")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "ism-material-issue" ? "10px" : "0px",
                }}
              />
              Material Issue
            </button>
          ) : null}
          {permissions?.includes(
            "CAN-MATERIAL ISSUE-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/material-issue-list")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "material-issue-list" ? "10px" : "0px",
                }}
              />
              Material Issue List
            </button>
          ) : null}
          {permissions?.includes(
            "CAN-INTERNAL RETURN-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/internal-Receipt")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "internal-Receipt" ? "10px" : "0px",
                }}
              />
              Internal Return
            </button>
          ) : null}
          {permissions?.includes(
            "CAN-INTERNAL RETURN-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/internal-Receipt-list")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "internal-Receipt-list"
                      ? "10px"
                      : "0px",
                }}
              />
              Internal Return List
            </button>
          ) : null}
          {permissions?.includes(
            "CAN-MATERIAL TRANSFER-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/ism-material-transfer")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "ism-material-transfer"
                      ? "10px"
                      : "0px",
                }}
              />
              Material Transfer
            </button>
          ) : null}
          {permissions?.includes(
            "CAN-MATERIAL TRANSFER-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/material-transfer-list")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "material-transfer-list"
                      ? "10px"
                      : "0px",
                }}
              />
              Material Transfer List
            </button>
          ) : null}

          {permissions?.includes(
            "CAN-MATERIAL RECEIPT-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/ism-material-receipt")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "ism-material-receipt" ? "10px" : "0px",
                }}
              />
              Material Receipt
            </button>
          ) : null}
          {permissions?.includes(
            "CAN-MATERIAL RECEIPT-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/material-receipt-list")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "material-receipt-list"
                      ? "10px"
                      : "0px",
                }}
              />
              Material Receipt List
            </button>
          ) : null}
          {permissions?.includes(
            "CAN-MATERIAL RETURN-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/ism-material-return")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "ism-material-return" ? "10px" : "0px",
                }}
              />
              Material Return
            </button>
          ) : null}

          {permissions?.includes(
            "CAN-MATERIAL RETURN-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/material-return-list")}>
              <div
                className="active-line"
                style={{
                  width:
                    splitLocation[2] == "material-return-list" ? "10px" : "0px",
                }}
              />
              Material Return List
            </button>
          ) : null}

          {permissions?.includes(
            "CAN-DAY END PROCESS-INVENTORY & STOCK MANAGEMENT"
          ) ? (
            <button onClick={() => navigate("/ism/day-end-process")}>
              <div
                className="active-line"
                style={{
                  width: splitLocation[2] == "day-end-process" ? "10px" : "0px",
                }}
              />
              Day End Process
            </button>
          ) : null}
          <button onClick={() => navigate("/ism/warehouse")}>
            <div
              className="active-line"
              style={{
                width: splitLocation[2] == "warehouse" ? "10px" : "0px",
              }}
            />
            WareHouse
          </button>
        </div>
        <div className="d-flex flex-column">
          <button
            class="bg-primar border-0 rounded-1 my-3 mx-5 text-white f-1 fs-14 px-4 py-2"
            onClick={logOutAcc}
          >
            Logout
          </button>
          <button
            class="bg-primar border-0 rounded-1 my-3 mx-5 text-white f-1 fs-14 px-2 py-2"
            onClick={() => setShow(!show)}
          >
            Change Password
          </button>
        </div>
      </div>
      <Modal centered show={show} onHide={() => setShow(!show)}>
        <Modal.Header className="border-0" closeButton>
          <label className="w-100 text-center">Change Password</label>
        </Modal.Header>
        <Modal.Body>
          <Col xs={10} className="inputinner-box text-start mx-auto">
            <label className="w-100">Enter Password</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Password"
              value={changePassword}
              onChange={(e) => setChangePassword(e.target.value)}
            />
          </Col>
          <Col xs={10} className="inputinner-box text-start mx-auto">
            <label className="w-100">Enter Confirmation Password</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Confirmation Password"
              value={passConfirm}
              onChange={(e) => setPassConfirm(e.target.value)}
            />
          </Col>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={() => setShow(!show)}>
            Close
          </Button>
          <Button variant="primary" onClick={changePasswordHandler}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NavBar;
