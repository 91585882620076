import React, { useState, useEffect } from "react";
import eye_icon from "../../assets/icons/common/Eye.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";
import { PaginationControl } from "react-bootstrap-pagination-control";

function WorkOrderListScreen() {
  const [PODetailsList, setPODetailsList] = useState([]);
  const [POList, setPOList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectName, setProjectName] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selPurcaseOrder, setSelPurchaseOrder] = useState("");
  const [show, setShow] = useState("");
  const [page, setPage] = useState(1);
  const [projectList, setProjectList] = useState([]);
  const navigate = useNavigate();

  const getProjectList = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  const getWorkOrderList = (event, pages) => {
    let param = `?page=${pages}`;
    let obj = {
      project_id: event,
    };
    setSelectedProject(event);
    setPage(pages);
    api
      .workOrderList(param, obj)
      .then((res) => {
        if (res?.status == "success") {
          setPODetailsList(res);
        } else {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const purcaseOrderDetailHandler = (item, id, no, date) => {
    navigate("/procurement/work-order-output", {
      state: { id: item?.id },
    });
  };

  useEffect(() => {
    getProjectList();
  }, []);

  // useEffect(() => {
  //   getWorkOrderList();
  // }, [selectedProject, page]);

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white my-3 text-center">
        <header>
          <Col xs={12} className="d-flex my-2">
            {/* <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-25 mt-1 me-auto pointer"
              value={selPurcaseOrder}
              onChange={(e) => dropDownMaterialHandler(e.target.value)}>
              <option>Choose Category</option>
              {POList?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select> */}
            <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 ms-auto pointer"
              value={selectedProject}
              onChange={(e) => getWorkOrderList(e.target.value, 1)}
            >
              <option>Select Project</option>
              {projectList?.map((project) => (
                <option key={project?.id} value={project?.id}>
                  {project?.name}
                </option>
              ))}
            </select>
          </Col>
          {/* <Col xs={12} className="my-3 py-2 d-flex">
            <input
              type="checkbox"
              className="pointer mx-2"
              checked={show == "approved"}
              value="approved"
              onChange={(e) => setShow(e.target.value)}
            />
            <label className="mx-1 px-1">Approved</label>
            <input
              type="checkbox"
              className="pointer mx-2"
              checked={show == "declined"}
              value="declined"
              onChange={(e) => setShow(e.target.value)}
            />
            <label className="mx-1 px-1">Declined</label>
          </Col> */}
        </header>
        <h4 className="my-1 py-1">Work Order List</h4>
        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                {/* <th>Project Name</th> */}
                <th>Vendor Name</th>
                <th>Work Order No.</th>
                <th>Work Order Date </th>
                {/* <th>Raised Value </th> */}
                {/* <th>Balance Value</th> */}
                {/* <th>Procure Type</th> */}
                <th>Created By</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {PODetailsList?.work_orders?.map((purcaseOrder, ind) => (
                <tr>
                  <td>{ind + 1}</td>
                  {/* <td>{projectName}</td> */}
                  <td>{purcaseOrder?.vendor?.company_name}</td>
                  <td>{purcaseOrder?.order_no}</td>
                  <td>{purcaseOrder?.order_date}</td>
                  {/* <td className="text-center">{"-"}</td> */}
                  {/* <td className="text-center">{"-"}</td> */}
                  {/* <td>{purcaseOrder?.procure_type}</td> */}
                  <td>{purcaseOrder?.employee?.name}</td>
                  <td>
                    {/* {purcaseOrder?.approved_by == null &&
                      purcaseOrder?.status == 1
                        ? "Not Seen"
                        : purcaseOrder?.approved_by == null
                        ? "Rejected"
                        : "Approved"} */}
                    {purcaseOrder?.status == 1
                      ? "Pending"
                      : purcaseOrder?.status == 2
                      ? "Approved"
                      : purcaseOrder?.status == 3
                      ? "Rejected"
                      : "Pending"}
                  </td>
                  <td>
                    <div className="d-flex">
                      {/* <Link
                        to={`/procurement/po-output?id=${
                          purcaseOrder?.id
                        }&project=${+selectedProject}&projectname=${projectName}`}
                      > */}
                      <img
                        onClick={() =>
                          purcaseOrderDetailHandler(
                            purcaseOrder,
                            purcaseOrder?.id,
                            purcaseOrder?.po_no,
                            purcaseOrder?.po_date
                          )
                        }
                        style={{ width: "20px" }}
                        className="pointer ms-2"
                        src={eye_icon}
                        alt="eye-icon"
                      />
                      {/* </Link> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="my-1 py-1 d-flex justify-content-end">
          <PaginationControl
            page={page}
            between={4}
            total={PODetailsList?.pagination?.total}
            limit={10}
            changePage={(pages) => {
              getWorkOrderList(selectedProject, pages);
            }}
            ellipsis={1}
          />
        </div>
      </Col>
    </Col>
  );
}

export default WorkOrderListScreen;
