import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
import Header from "../../component/Header/Header";
import BoqCreationForm from "../../component/BoqCreation/BoqCreationForm";
import BoqTableSection from "../../component/BoqCreation/BoqTableSec";

const BoqCreation = () => {
  const [projectList, setProjectList] = useState([]);

  const [projectDetails, setProjectDetails] = useState({
    boq_details: [],
    project: {},
    project_boq: {},
  });
  const [boqFormDetails, setBoqFormDetails] = useState({
    completion_date: "",
    project_id: "",
    boq_no: "",
    loi_date: "",
    import_excel: "",
  });

  useEffect(() => {
    api.project_list_without_boq().then((res) => {
      if (res?.status === "success") {
        setProjectList(res?.projects);
      }
    });
  }, []);

  useEffect(() => {
    if (boqFormDetails.project_id) {
      api.project_show(+boqFormDetails.project_id).then((res) => {
        console.log("prpoject-details", res);
        if (res?.status === "success") {
          setProjectDetails({
            ...projectDetails,
            // boq_details: res?.boq_details,
            boq_details: res?.first_boq_details,
          });
          setBoqFormDetails({
            ...boqFormDetails,
            boq_no: res?.project?.project_no,
            completion_date: res?.project?.end_date,
            loi_date: res?.project?.loi_date,
          });
        }
      });
    }
  }, [boqFormDetails.project_id]);
  return (
    <Col xs={12} className="py-2 px-1 boq-cont-res p-md-3" style={{overflowX:"hidden"}}>
      <Header  />
      <BoqCreationForm
        setProjectDetails={setProjectDetails}
        projectDetails={projectDetails}
        projectList={projectList}
        boqFormDetails={boqFormDetails}
        setBoqFormDetails={setBoqFormDetails}
      />
      <BoqTableSection boq_details={projectDetails.boq_details} />

      <Col sm={12} className="inputinner-box text-start">
        <button
          className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
          onClick={() => window.location.reload()}>
          Close
        </button>
      </Col>
    </Col>
  );
};

export default BoqCreation;
