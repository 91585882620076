// @ts-nocheck
import React from "react";
import { Col } from "react-bootstrap";
import { boq_data } from "../../constants/StaticData";

const BoqTable = ({ boq_details }) => {
  console.log(boq_details);
  return (
    <Col className="emply-master-form py-2  mt-3 text-center">
      <div style={{ overflowX: "scroll" }} className="boq-table bg-white">
        <table className="table">
          <thead>
            <tr>
              <th> Sl.No</th>
              <th>Material Code</th>
              <th>Material Description</th>
              <th>BOQ Code</th>
              <th style={{ minWidth: "510px" }}>BOQ Description Unit</th>
              <th>UOM</th>
              <th>Quantity</th>
              <th>Supply Rate </th>
              <th>Installation Rate </th>
              <th>B.Material-1</th>
              <th>Accessories-1</th>
              <th>Fitting </th>
              <th>Support</th>
              <th>Accessories-2</th>
              <th>Welding</th>
              <th>Paint</th>
              <th>Insulation</th>
              <th>MISC</th>
              <th>Basic Rate</th>
              <th>Accessories Rate</th>
              <th>Transport Charges</th>
              <th>Cont.Rate</th>
              <th>Financial Rate</th>
              <th>GST Supp %</th>
              <th>GST Incl %</th>
            </tr>
          </thead>
          <tbody>
            {boq_details?.map((item, ind) => {
              return (
                <tr key={item?.id}>
                  <td>{ind + 1}</td>
                  <td>{item?.material_code}</td>
                  <td>{item?.material_description}</td>
                  <td>{item?.boq_code}</td>
                  <td>{item?.boq_description}</td>
                  <td>{item?.uom}</td>
                  <td>{item?.qty}</td>
                  <td>{item?.supply_rate}</td>
                  <td>{item?.installation_rate}</td>
                  <td>{item?.b_material_1}</td>
                  <td>{item?.accessories_1}</td>
                  <td>{item?.fitting}</td>
                  <td>{item?.support}</td>
                  <td>{item?.accessories_2}</td>
                  <td>{item?.wellding}</td>
                  <td>{item?.paint}</td>
                  <td>{item?.insulation}</td>
                  <td>{item?.misc}</td>
                  <td>{item?.basic_rate}</td>
                  <td>{item?.accessories_rate}</td>
                  <td>{item?.transport_charges}</td>
                  <td>{item?.cont_rate}</td>
                  <td>{item?.financial_rate}</td>
                  <td>{item?.gst_supply}</td>
                  <td>{item?.gst_inclu}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Col>
  );
};

export default BoqTable;
