import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { InvoiceScrutinyTableData } from "../../../constants/StaticData";

const InvoiceScrutiny = ({ projectList }) => {
  const [InvoiceNo, setInvoiceNo] = useState("");
  const [materialType, setMaterialType] = useState("");
  const [grnNo, setGrnNo] = useState("");
  const [projectName, setProjectName] = useState("");
  const [InvoiceDate, setInvoiceDate] = useState("");
  const [InvoiceNoErr, setInvoiceNoErr] = useState(false);
  const [materialTypeErr, setMaterialTypeErr] = useState(false);
  const [projectNameErr, setProjectNameErr] = useState(false);
  const [InvoiceDateErr, setInvoiceDateErr] = useState(false);

  const submitHandler = () => {
    if (
      InvoiceNo?.length == 0 ||
      materialType?.length == 0 ||
      projectName?.length == 0 ||
      InvoiceDate?.length == 0
    ) {
      setInvoiceNoErr(true);
      setMaterialTypeErr(true);
      setProjectNameErr(true);
      setInvoiceDateErr(true);
    } else {
      alert("saved");
    }
  };
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">Invoice Scrutiny</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <p
            style={{ minHeight: "2rem", width: "90%", marginRight: "auto" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1">
            Crescon Projects and Service Pvt Ltd
          </p>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>

          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => setProjectName(e.target.value)}
            value={projectName}>
            <option>select project</option>
            {projectList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>

          {projectName?.length == 0 && projectNameErr && (
            <p className="err">enter project name</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Vendor Name</label>

          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => setProjectName(e.target.value)}
            value={projectName}>
            <option>select Vendor</option>
            {projectList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>

          {/* {projectName?.length == 0 && projectNameErr && (
            <p className="err">enter project name</p>
          )} */}
        </Col>
        {/* <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Type</label>
          <p
            style={{ minHeight: "2.1rem", width: "90%", lineHeight: "2.1rem" }}
            className="input-select bg-lightBlue ms-0 ps-3">
            Plumber
          </p>
        </Col> */}
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Invoice No</label>
          <select
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => setGrnNo(e.target.value)}
            value={grnNo}>
            <option>select project</option>
            {InvoiceScrutinyTableData?.map((item, index) => (
              <option key={index} value={item?.id}>
                INV{item?.uom}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Invoice Date</label>
          <p
            style={{ minHeight: "2.1rem", width: "90%", lineHeight: "2.1rem" }}
            className="input-select bg-lightBlue ms-0 ps-3">
            12-05-2023
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Invoice Scrutiny No</label>
          <p
            style={{ minHeight: "2.1rem", width: "90%", lineHeight: "2.1rem" }}
            className="input-select bg-lightBlue ms-0 ps-3">
            {}
          </p>
        </Col>
        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Invoice Scrutiny Date</label>
          <input type="date" className="bg-lightBlue ms-0 p-1 w-90 border-0" />
        </Col>

        {/* <Col xs={12} sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={submitHandler}>
            Fetch
          </button>
        </Col> */}
      </Col>
    </Col>
  );
};

export default InvoiceScrutiny;
