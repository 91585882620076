// @ts-nocheck
import React from "react";
const dummy_data = [
  {
    b_code: 1090,
    m_code: "MC980",
    m_des: "Lorem ipift",
    UOM: "123",
    Required_Quantity: "High",
    Previouly_Purchased: "Yes",
    Avaliable_QTY: 103,
    Indenting_Quantity: "id103",
  },
  {
    b_code: 1090,
    m_code: "MC980",
    m_des: "Lorem ipift",
    UOM: "123",
    Required_Quantity: "High",
    Previouly_Purchased: "Yes",
    Avaliable_QTY: 103,
    Indenting_Quantity: "id103",
  },
  {
    b_code: 1090,
    m_code: "MC980",
    m_des: "Lorem ipift",
    UOM: "123",
    Required_Quantity: "High",
    Previouly_Purchased: "Yes",
    Avaliable_QTY: 103,
    Indenting_Quantity: "id103",
  },
  {
    b_code: 1090,
    m_code: "MC980",
    m_des: "Lorem ipift",
    UOM: "123",
    Required_Quantity: "High",
    Previouly_Purchased: "Yes",
    Avaliable_QTY: 103,
    Indenting_Quantity: "id103",
  },
  {
    b_code: 1090,
    m_code: "MC980",
    m_des: "Lorem ipift",
    UOM: "123",
    Required_Quantity: "High",
    Previouly_Purchased: "Yes",
    Avaliable_QTY: 103,
    Indenting_Quantity: "id103",
  },
];
const IsmMaterialRequestTable = () => {
  return (
    <div className="user-mange-table">
      <table>
        <thead>
          <tr>
            <th>S.no</th>
            <th>BOQ Code</th>
            <th>Material Code</th>
            <th>Material Description</th>
            <th>UOM</th>
            <th>Required Quantity</th>
            <th>Previouly Purchased</th>
            <th>Avaliable QTY</th>
            <th>Indenting Quantity</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {dummy_data.map((item, ind) => {
            return (
              <tr key={ind}>
                <td>0{ind + 1}</td>
                <td>{item.b_code}</td>
                <td>{item.m_code}</td>
                <td>{item.m_des}</td>
                <td>{item.UOM}</td>
                <td>{item.Required_Quantity}</td>
                <td>{item.Previouly_Purchased}</td>
                <td>{item.Avaliable_QTY}</td>
                <td>{item.Indenting_Quantity}</td>
                <td>-</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default IsmMaterialRequestTable;
