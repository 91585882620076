import React from "react";
import { Col } from "react-bootstrap";

const LbMasBankDetails = () => {
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-0 text-center">
      <h4 className="w-95 ms-xl-0">Bank Details</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Name of Bank<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Name of Bank"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Account No<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Account No"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Account Type</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Account Type"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            IFSC Code<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter IFSC Code"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Branch Name</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Branch Name"
          />
        </Col>
        <Col sm={12} className="inputinner-box text-start">
          <button className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
            Submit
          </button>
          <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3">
            cancel
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default LbMasBankDetails;
