import React, { useState } from "react";
import { Col } from "react-bootstrap";

import Header from "../../component/Header/Header";
import VendorMasterForm from "../../component/VendorMaster/VendorMasterForm";
import VendorMasterTab from "../../component/VendorMaster/VendorMasterTab";

const VendorMasterScreen = () => {
  // const [supplierName, setSupplierName] = useState("");
  const [prodectType, setProdectType] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [vendorType, setVendorType] = useState("");
  const [subVendorType, setSubVendorType] = useState("");

  const [input, setInput] = useState([]);

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <VendorMasterForm
        setInput={setInput}
        // supplierName={supplierName}
        companyName={companyName}
        setCompanyName={setCompanyName}
        // setSupplierName={setSupplierName}
        setProdectType={setProdectType}
        prodectType={prodectType}
        vendorType={vendorType}
        setVendorType={setVendorType}
        subVendorType={subVendorType}
        setSubVendorType={setSubVendorType}
      />
      {companyName && prodectType && (
        <VendorMasterTab
          companyName={companyName}
          // supplierName={supplierName}
          prodectType={prodectType}
          vendorType={vendorType}
          subVendorType={subVendorType}
        />
      )}
    </Col>
  );
};

export default VendorMasterScreen;
