import Carousel from "react-bootstrap/Carousel";
import {
  cres_img_1,
  cres_img_2,
  cres_img_3,
  cres_img_4,
  cres_img_5,
  logo,
} from "../assets/img";
// import LeftLogin from "../component/Login/LeftLogin";
// import RightLogin from "../component/Login/RightLogin";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import eye_icon from "../assets/icons/common/Eye.svg";
import hide_password_icon from "../assets/icons/common/HidePassword.svg";
import httpClient from "../constants/HttpClient/httpClient";
import httpClientForm from "../constants/HttpClient/httpClientForm";
import { api } from "../constants/Services";
import useToken from "../constants/Storage/useToken";
import useUser from "../constants/Storage/userData";

const Login = () => {
  const navigate = useNavigate();
  const [employeeId, setEmployeeId] = useState("");
  const [showPassword, setShowPassowrd] = useState(false);
  const [password, setPassword] = useState("");
  const [errorCount, setErrorCount] = useState(0);

  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  // console.log("tocken", token);
  // console.log("set-tock", setToken);
  const employeeIdHandler = (e) => {
    setEmployeeId(e.target.value);
  };

  const employeePasswordHandler = (e) => {
    setPassword(e.target.value);
  };

  // console.log("password", password);
  // console.log("id-emp", employeeId);
  // console.log("error-attempt", errorCount);

  const onsubmitHandler = () => {
    let data = {
      emp_id: employeeId,
      password: password,
    };
    setEmployeeId("");
    setPassword("");
    api.login(data).then((res) => {
      // console.log("login-res", res);
      if (res?.status === "success") {
        setErrorCount(0);
        setToken(res?.token);
        setUser(res?.employee);
        localStorage.setItem("permissions", JSON.stringify(res?.permissions));

        httpClient.defaults.headers.common["Authorization"] =
          `Bearer ${res?.token}` || "";
        httpClientForm.defaults.headers.common["Authorization"] =
          `Bearer ${res?.token}` || "";
        // employee

        // name , updated_at , role
        navigate("/notification");
        window.location.reload();
        // navigate("/notification");
      } else if (res?.status === "error") {
        // setErrorCount(errorCount + 1);
        // if (errorCount === 1) {
        //   alert("Kind reminder one more attempt is there");
        // }
      }
    });
  };

  return (
    <Col xs={12}>
      <Carousel autoplay interval={5000} indicators={false}>
        <Carousel.Item arrows={false}>
          <img
            className="d-block h-100 w-100"
            src={cres_img_1}
            alt="First slide"
          />
          <Carousel.Caption>
            <p>
              One of the leading industrial construction services providing in{" "}
            </p>
            <h3>South Asia</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block h-100  w-100"
            src={cres_img_2}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3>Crescon</h3>
            <h3>The EPC Company</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block h-100 w-100"
            src={cres_img_3}
            alt="Third slide"
          />
          <Carousel.Caption>
            <p>
              One of the leading industrial construction services providing in{" "}
            </p>
            <h3>South Asia</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block h-100  w-100"
            src={cres_img_4}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>Crescon</h3>
            <h3>The EPC Company</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block h-100  w-100"
            src={cres_img_5}
            alt="Third slide"
          />
          <Carousel.Caption>
            <p>
              One of the leading industrial construction services providing in
            </p>
            <h3>South Asia</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div>
        <div>
          <h1 className="login-top">ERPCMS</h1>
        </div>
        <div
          style={{ maxWidth: "320px" }}
          className="cres-right-login login-box bg-white"
        >

          <div className="d-flex align-items-center">
            <img
              src={logo}
              style={{ width: "50px" }}
              alt="login_image"
              className="float-start"
            />
            <h1 className="w-50 ms-auto me-2">Login</h1>
          </div>
          <div className="inputinner-box text-start w-100">
            <label className="w-100">Employee Id</label>
            <input
              className="input-select1"
              value={employeeId}
              onChange={employeeIdHandler}
            />
          </div>

          <div className="inputinner-box text-start">
            <label>Password</label>
            <div className="d-grid">
              <input
                style={{ gridRow: 1, gridColumn: 1 }}
                className="input-select1"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={employeePasswordHandler}
              />

              <img
                style={{
                  width: "20px",
                  cursor: "pointer",
                  position: "relative",
                  left: "90%",
                  top: "9px",
                  gridRow: 1,
                  gridColumn: 1,
                }}
                src={showPassword ? hide_password_icon : eye_icon}
                alt="eye-icon"
                onClick={() => setShowPassowrd(!showPassword)}
              />
            </div>

            {errorCount === 3 && (
              <p className="err">
                Your Account has been blocked. To Proceed Further Kindly Contact
                Admin
              </p>
            )}
          </div>

          <button
            onClick={onsubmitHandler}
            className={errorCount > 2 ? "login-disable-btn " : "bg-darkBlue"}
            disabled={errorCount > 2 ? true : false}
          >
            Continue
          </button>
        </div>
      </div>
    </Col>
  );
};

export default Login;
