import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import moment from "moment";
import "moment-precise-range-plugin";

const QualifWork = ({
  setTab,
  setTempFormData,
  tempFormData,
  setAllowAccess,
  allowAccess,
}) => {
  const [error, setError] = useState([]);
  const [qualification, setQualification] = useState({
    dor: "",
    cps_exp: "",
    specialisation: "",
  });
  const [qualificationlRequiredFields, setQualificationRequiredFields] =
    useState({
      qualification: tempFormData?.qualification || "",
      previous_exp: tempFormData?.previous_exp || "",
      doj: tempFormData?.doj || "",
    });

  useEffect(() => {
    const m1 = moment(qualificationlRequiredFields.doj, "YYYY-MM-DD");
    const m2 = moment().format("YYYY-MM-DD");
    if (m2 > m1?._i || m2 == m1?._i) {
      // alert("present & future");
      const diff = moment.preciseDiff(m1, m2, true);
      const str = `${diff.years} Years, ${diff.months} Months, ${diff.days} Days`;
      str && setQualification({ ...qualification, cps_exp: str });
    } else {
      setQualification({ ...qualification, cps_exp: "" });
    }
  }, [qualificationlRequiredFields.doj]);

  const resetData = () => {
    setQualification({
      dor: "",
      specialization: "",
      cps_exp: "",
    });

    setQualificationRequiredFields({
      qualification: "",
      previous_exp: "",
      doj: "",
    });
  };
  const empMasterQualificationNextClickHandler = () => {
    // console.log("quali_req_fields", qualificationlRequiredFields);

    let temp = [];
    Object.entries(qualificationlRequiredFields).forEach((item) => {
      console.log("each-value", item);
      if (item[1] === "") {
        temp.push(item[0]);
      }
    });

    setError(temp);
    if (Object.values(qualificationlRequiredFields).every((x) => x !== "")) {
      setTempFormData({
        ...tempFormData,
        ...qualification,
        ...qualificationlRequiredFields,
      });
      setTab("salary");
      resetData();
      setAllowAccess([...allowAccess, "qw"]);
    }
  };
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-0 text-center mb-3">
      <h4 className="ms-xl-0 w-95">Qualif & Working</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Education Qualification<span className="err">*</span>
          </label>
          <input
            value={qualificationlRequiredFields.qualification}
            onChange={(e) =>
              setQualificationRequiredFields({
                ...qualificationlRequiredFields,
                qualification: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Education Qualification"
          />
          {Object.values(error)?.includes("qualification") && (
            <p className="err">Education Qualification Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label>Specialisation</label>
          <input
            value={qualification.specialisation}
            onChange={(e) =>
              setQualification({
                ...qualification,
                specialisation: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Specialization"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Previous Experience<span className="err">*</span>
          </label>
          <input
            value={qualificationlRequiredFields.previous_exp}
            onChange={(e) =>
              setQualificationRequiredFields({
                ...qualificationlRequiredFields,
                previous_exp: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Previous Experience"
          />
          {Object.values(error)?.includes("previous_exp") && (
            <p className="err">Previous Experience Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Date of Join<span className="err">*</span>
          </label>
          <input
            value={qualificationlRequiredFields.doj}
            onChange={(e) =>
              setQualificationRequiredFields({
                ...qualificationlRequiredFields,
                doj: e.target.value,
              })
            }
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Date of Join"
          />
          {Object.values(error)?.includes("doj") && (
            <p className="err">Date of Joing Field Required</p>
          )}
        </Col>
        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Date of Resignation</label>
          <input
            value={qualification.dor}
            onChange={(e) =>
              setQualification({
                ...qualification,
                dor: e.target.value,
              })
            }
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Date of Resignation"
          />
        </Col> */}
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">CPS Working Experience</label>
          <p
            style={{ width: "90%", minHeight: "2rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1">
            {qualification.cps_exp ? `${qualification.cps_exp}` : ""}
          </p>
        </Col>
        <Col sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={empMasterQualificationNextClickHandler}>
            Next
          </button>
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
            onClick={resetData}>
            cancel
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default QualifWork;
