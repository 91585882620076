// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { api } from "../../../constants/Services";

const MaterialInstruction = ({
  setModal,
  setPoNoErr,
  setProjectNameErr,
  setGrnErr,
  setReturnNoErr,
  setReturnDeteErr,
  grn,
  projectName,
  grnDate,
  warehouse,
  returnNo,
  returnDete,
  vendorName,
  poNo,
  vendorId,
  tableSel,
  setShowStatus,
  setStatusMessage,
  grnDetailsList,
  stockValue,
  company_id,
  setCompany_idErr,
}) => {
  const navigate = useNavigate();
  const [btn, setBtn] = useState(false);
  const [Instructions, setInstructions] = useState("");
  const [InstructionsErr, setInstructionsErr] = useState(false);

  console.log("grnDetailsList", grnDetailsList);
  console.log("tableSel", tableSel, company_id, stockValue);

  const submitHandler = () => {
    console.log(123);
    // setModal(true);
    if (
      // grn?.length == 0 ||
      projectName?.length == 0 ||
      // returnNo?.length == 0 ||
      returnDete?.length == 0 ||
      poNo?.length == 0 ||
      company_id?.length == 0
    ) {
      // setGrnErr(true);
      setProjectNameErr(true);
      // setReturnNoErr(true);
      setReturnDeteErr(true);
      setPoNoErr(true);
      setCompany_idErr(true);
    } else {
      console.log("098");
      let formdata = new FormData();
      formdata.append("project_id", projectName);
      formdata.append("company_id", company_id);
      formdata.append("purchase_order_id", poNo);
      formdata.append("vendor_id", vendorId);
      formdata.append("grn_id", 20);
      // formdata.append("ware_house_id", warehouse);
      formdata.append("return_no", returnNo);
      formdata.append("return_date", returnDete);
      if (Instructions?.length > 0) {
        formdata.append("remarks", Instructions);
      }

      let count = 1;
      let tempStocks = [];

      if (grnDetailsList?.length > 0) {
        grnDetailsList?.map((item, ind) => {
          if (tableSel?.includes(item?.id)) {
            if (item?.error == 1) {
              count = count + 1;
            }

            console.log("item", item);

            formdata.append(
              // `material_return_details[${ind}][grn_detail_id]`,
              `material_return_details[${ind}][purchase_order_detail_id]`,
              item?.id
            );

            formdata.append(
              `material_return_details[${ind}][return_qty]`,
              item?.received_qtys
            );

            // formdata.append(
            //   `material_return_details[${ind}][return_qty]`,
            //   item?.net_value
            // );

            if (item?.remarks) {
              formdata.append(
                `material_return_details[${ind}][remarks]`,
                item?.remarks
              );
            }

            let stockObj = stockValue?.[item?.id];

            if (stockObj) {
              if (Object.values(stockObj)?.length > 0) {
                Object.keys(stockObj).map((items, indx) => {
                  tempStocks.push({
                    project_stock_id: items,
                    return_qty: stockObj[items],
                  });
                });
              }
            }
          }
        });
      }

      if (tempStocks?.length > 0) {
        tempStocks?.map((item, ind) => {
          formdata.append(
            `stock_return_details[${ind}][project_stock_id]`,
            item?.project_stock_id
          );
          formdata.append(
            `stock_return_details[${ind}][return_qty]`,
            item?.return_qty
          );
        });
      }

      if (count == 1) {
        setBtn(true);
        api
          .matertialReturnAdd(formdata)
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              setShowStatus(true);
              setStatusMessage(res?.message + " " + res?.material_return_no);
            } else {
              console.log("res", res);
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };
  // console.log("stockValue", stockValue);
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <Col className="d-flex flex-wrap">
        {/* <Col xs={12} sm={6} className="inputinner-box text-start">
          <label className="w-100">Prepared By</label>
          <p
            style={{
              minHeight: "2.1rem",
              width: "90%",
              lineHeight: "2.1rem",
            }}
            className="input-select bg-lightBlue ms-0 ps-3 my-1">
           </p>
        </Col> */}
        {/* <Col xs={12} sm={6} className="inputinner-box text-start">
          <label className="w-100">Approved By</label>
          <p
            style={{
              minHeight: "2.1rem",
              width: "90%",
              lineHeight: "2.1rem",
            }}
            className="input-select bg-lightBlue ms-0 ps-3 my-1">
            {}
          </p>
        </Col> */}

        <Col xs={12} sm={6} className="inputinner-box text-start">
          <label className="w-100">Remarks</label>
          <textarea
            rows="5"
            className="border-0 bg-lightBlue px-2 py-1 w-95 mt-1 fs-14"
            placeholder="Enter Remarks"
            onChange={(e) => setInstructions(e.target.value)}
            value={Instructions}
          />
          {Instructions?.length == 0 && InstructionsErr && (
            <p className="err">enter instructions</p>
          )}
        </Col>
      </Col>
      <Col className="d-flex justify-content-between">
        <div>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2 me-3"
            onClick={submitHandler}
          >
            Save
          </button>
          <button className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
            Cancel
          </button>
        </div>
        {/* <button className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
          Export
        </button> */}
      </Col>
    </Col>
  );
};

export default MaterialInstruction;
