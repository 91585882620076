import React, { useState, useEffect } from "react";
import eye_icon from "../../assets/icons/common/Eye.svg";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";

function MaterialRequestListScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [mrDetailsList, setMrDetailsList] = useState([]);
  const [MRList, setMRList] = useState([]);
  const [locationId, setLocationId] = useState(true);

  const [selectedProject, setSelectedProject] = useState("");
  const [projectName, setProjectName] = useState("");
  const [selMaterial, setSelMaterial] = useState("");
  const [show, setShow] = useState("");
  const [projectList, setProjectList] = useState([]);

  const getMaterialList = () => {
    let data = {
      project_id: +selectedProject,
    };
    api.matrialList(data).then((res) => {
      if (res?.status == "success") {
        setMRList(res?.material_requests);
        removeQueryParams();
      }
    });
  };

  const dropDownMaterialHandler = (sel_material) => {
    setSelMaterial(+sel_material);
  };

  const getProjects = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };
  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (searchParams?.get("proId")) {
      setSelectedProject(searchParams?.get("proId"));
    }
  }, [searchParams?.get("proId")]);

  useEffect(() => {
    getMaterialList();
    const ProjectName = projectList
      ?.filter((project) => project?.id == selectedProject)
      .reduce((acc, filtered_project) => filtered_project?.name, "");
    if (searchParams?.get("projectName")) {
      setProjectName(searchParams?.get("projectName"));
    } else {
      setProjectName(ProjectName);
    }
  }, [selectedProject]);

  const materialRequestDetailHandler = (sel_material) => {
    navigate("/execution/material-request-view", {
      state: {
        id: sel_material,
        proId: +selectedProject,
        projectName: projectName,
      },
    });
  };

  const removeQueryParams = () => {
    const proId = searchParams.get("proId");
    const projectName = searchParams.get("projectName");

    if (proId) {
      // 👇️ delete each query param
      searchParams.delete("proId");

      // 👇️ update state after
      setSearchParams(searchParams);
    }

    if (projectName) {
      searchParams.delete("projectName");
      setSearchParams(searchParams);
    }
  };

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white my-3 text-center">
        <header>
          <Col xs={12} className="d-flex mb-3 py-2">
            {/* <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-25 mt-1 me-auto pointer"
              value={selMaterial}
              onChange={(e) => dropDownMaterialHandler(e.target.value)}>
              <option>Choose Category</option>
              {MRList?.map((item) => (
                <option key={item?.id} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select> */}
            <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 ms-auto pointer"
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
            >
              <option>Select Project</option>
              {projectList?.map((project) => (
                <option key={project?.id} value={project?.id}>
                  {project?.name}
                </option>
              ))}
            </select>
          </Col>
          {/* <Col xs={12} className="my-3 py-2 d-flex">
            <input
              type="checkbox"
              className="pointer mx-1"
              checked={show == "approved"}
              value="approved"
              onChange={(e) => setShow(e.target.value)}
            />
            <label className="mx-1 px-1">Approved</label>
            <input
              type="checkbox"
              className="pointer mx-1"
              checked={show == "declined"}
              value="declined"
              onChange={(e) => setShow(e.target.value)}
            />
            <label className="mx-1 px-1">Declined</label>
          </Col> */}
        </header>
        <h4>Material Requests List</h4>

        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Project Name</th>
                <th>MR No</th>
                <th>MR Date</th>
                <th>Requestion Type</th>
                <th>Created by</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {MRList?.map((material, ind) => {
                return (
                  <tr>
                    <td>{ind + 1}</td>
                    <td>{projectName}</td>
                    <td>{material?.mr_no}</td>
                    <td>{material?.mr_date}</td>
                    <td>{material?.request_type == 1 ? "BOQ" : "Direct PO"}</td>
                    <td>{material?.created_user?.name}</td>
                    <td>
                      {/* {material?.approved_by == null && material?.status == 1
                      ? "Not Seen"
                      : material?.approved_by == null
                        ? "Rejected"
                        : "Approved"} */}
                      {material?.status == 1
                        ? "Pending"
                        : material?.status == 2
                          ? "Apporved"
                          : material?.status == 3
                            ? "Rejected"
                            : "Pending"}
                    </td>
                    <td>
                      <div className="d-flex">
                        <Link
                          to={`/execution/material-request-view?id=${material?.id
                            }&proId=${+selectedProject}&projectName=${projectName}`}
                        >
                          <img
                            // onClick={() =>
                            //   materialRequestDetailHandler(material?.id)
                            // }
                            style={{ width: "20px" }}
                            className="pointer ms-2"
                            src={eye_icon}
                            alt="eye-icon"
                          />
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Col>
    </Col>
  );
}

export default MaterialRequestListScreen;
