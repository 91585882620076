import React, { useState } from "react";
import { NUMBERS_ONLY } from "../../constants/Services/constants";
import { Col } from "react-bootstrap";

const PersonalDetails = ({
  reset,
  setTab,
  setTempFormData,
  tempFormData,
  setAllowAccess,
  allowAccess,
}) => {
  const [error, setError] = useState([]);
  const [personalData, setPersonalData] = useState({
    father: tempFormData?.father || "",
    mother: tempFormData?.mother || "",
    spouse_name: tempFormData?.spouse_name || "",
    material_status: tempFormData?.material_status || "",
  });
  const [personalRequiredFields, setPersonalRequiredFields] = useState({
    blood_group: tempFormData?.blood_group || "",
    emergency_contact_name: tempFormData?.emergency_contact_name || "",
    emergency_contact: tempFormData?.emergency_contact || "",
  });

  const empMasterPersonalDetailsNextClickHandler = () => {
    let temp = [];
    Object.entries(personalRequiredFields).forEach((item) => {
      console.log("each-value", item);
      if (item[1] === "") {
        temp.push(item[0]);
      }
    });

    setError(temp);
    if (Object.values(personalRequiredFields).every((x) => x !== "")) {
      setTempFormData({
        ...tempFormData,
        ...personalData,
        ...personalRequiredFields,
      });
      setTab("qw");
      setAllowAccess([...allowAccess, "person"]);
    }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-0 text-center">
      <h4 className="ms-xl-0 w-95">Personal Details</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Father Name</label>
          <input
            onChange={(e) =>
              setPersonalData({
                ...personalData,
                father: e.target.value.replace(/[^\sa-z]/gi, ""),
              })
            }
            value={personalData.father}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Father Name"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Mother Name </label>
          <input
            value={personalData.mother}
            onChange={(e) =>
              setPersonalData({
                ...personalData,
                mother: e.target.value.replace(/[^\sa-z]/gi, ""),
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Mother Name"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Spouse Name </label>
          <input
            value={personalData.spouse_name}
            onChange={(e) =>
              setPersonalData({
                ...personalData,
                spouse_name: e.target.value.replace(/[^\sa-z]/gi, ""),
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Spouse Name"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label>Material Status</label>
          <input
            value={personalData.material_status}
            onChange={(e) =>
              setPersonalData({
                ...personalData,
                material_status: e.target.value.replace(/[^\sa-z]/gi, ""),
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Material Status"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Blood Group<span className="err">*</span>
          </label>
          <input
            value={personalRequiredFields.blood_group}
            onChange={(e) =>
              setPersonalRequiredFields({
                ...personalRequiredFields,
                blood_group: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Blood Group"
          />
          {Object.values(error)?.includes("blood_group") && (
            <p className="err">Blood Group Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Emergency Contact Person<span className="err">*</span>
          </label>
          <input
            value={personalRequiredFields.emergency_contact_name}
            onChange={(e) =>
              setPersonalRequiredFields({
                ...personalRequiredFields,
                emergency_contact_name: e.target.value.replace(
                  /[^\sa-z]/gi,
                  ""
                ),
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Emergency Contact Person"
          />
          {Object.values(error)?.includes("emergency_contact_name") && (
            <p className="err">Emergency Contact Person Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Emergency Phone No<span className="err">*</span>
          </label>
          <input
            value={personalRequiredFields.emergency_contact}
            onChange={(e) => {
              if (NUMBERS_ONLY.test(e.target.value)) {
                setPersonalRequiredFields({
                  ...personalRequiredFields,
                  emergency_contact: e.target.value.replace(/[^0-9]/gi, ""),
                });
              } else if (e.target.value === "") {
                setPersonalRequiredFields({
                  ...personalRequiredFields,
                  emergency_contact: e.target.value,
                });
              }
            }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Emergency Contact Number"
          />
          {Object.values(error)?.includes("emergency_contact") && (
            <p className="err">emergency_contact Required</p>
          )}
        </Col>
        <Col sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={empMasterPersonalDetailsNextClickHandler}>
            Next
          </button>
          <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3">
            cancel
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default PersonalDetails;
