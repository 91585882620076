import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { logo } from "../../assets/img";
import { api } from "../../constants/Services";
import { useLocation } from "react-router-dom";
import Header from "../../component/Header/Header";
import useUser from "../../constants/Storage/userData";
import html2PDF from "jspdf-html2canvas";

function IsmPrintScreen() {
  const location = useLocation();
  const { user } = useUser();
  const [PrintDetails, setPrintDetails] = useState([]);
  const [terms, setTerms] = useState([]);
  const [Annex, setAnnex] = useState([]);
  const [numToWords, setNumToWords] = useState("");

  let {
    id,
    title,
    from,
    to,
    table_heading,
    sample_data,
    right_fields,
    left_fields,
  } = location.state;

  function doConvert(numberInput) {
    let oneToTwenty = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    let tenth = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];
    let numToWord = numberInput.toString();
    if (numToWord.includes(".")) numToWord = numToWord.split(".")[0];
    let num = ("000000000" + numToWord)
      .slice(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

    if (!num) return;

    let outputText =
      num[1] != 0
        ? (oneToTwenty[Number(num[1])] ||
            `${tenth[num[1][0]]} ${oneToTwenty[num[1][1]]}`) + " crore "
        : "";
    outputText +=
      num[2] != 0
        ? (oneToTwenty[Number(num[2])] ||
            `${tenth[num[2][0]]} ${oneToTwenty[num[2][1]]}`) + " lakh "
        : "";

    outputText +=
      num[3] != 0
        ? (oneToTwenty[Number(num[3])] ||
            `${tenth[num[3][0]]} ${oneToTwenty[num[3][1]]}`) + "thousand "
        : "";
    outputText +=
      num[4] != 0
        ? (oneToTwenty[Number(num[4])] ||
            `${tenth[num[4][0]]} ${oneToTwenty[num[4][1]]}`) + "hundred "
        : "";
    outputText +=
      num[5] != 0
        ? (oneToTwenty[Number(num[5])] ||
            `${tenth[num[5][0]]} ${oneToTwenty[num[5][1]]}`) + " "
        : "";
    console.log("Result-1 = ", outputText);

    outputText = outputText?.charAt(0).toUpperCase() + outputText?.slice(1);
    console.log("Result = ", outputText);

    setNumToWords(outputText);
  }

  useEffect(() => {
    api.purchaseOrderView(id).then((res) => {
      if (res?.status == "success") {
        setPrintDetails(res?.purchase_orders);
        setAnnex(res?.purchase_orders?.purchase_order_annexures);
        let temp = res?.purchase_orders?.terms_conditions;
        const totalValue = res?.purchase_orders?.purchase_order_details?.reduce(
          (acc, po) => acc + +po?.net_value,
          0
        );
        if (totalValue) {
          doConvert(totalValue);
        }

        temp = temp.split(",");
        setTerms(temp);
      }
    });
  }, []);
  const downloadPdfDocument = (div_id) => {
    const input = document.getElementById(div_id);

    html2PDF(input, {
      jsPDF: {
        format: "a4",
      },
      // html2canvas: { width: 70000 },
      imageType: "image/jpeg",
      output: "./pdf/generate.pdf",
    });
  };
  return (
    // boq-cont-res
    <Col xs={12} lg={11} className="py-2 px-1 p-md-3">
      <Header />
      <div id="po-out-main-cont" className="po-main-box my-3">
        <section className="p-3">
          <header className="d-flex ">
            <Col xs={1} className="d-flex my-3 ">
              <img
                className="ms-4"
                style={{ width: "70px", height: "70px" }}
                src={logo}
                alt="logo"
              />
            </Col>
            <Col
              xs={7}
              className="d-flex flex-column po-vendor-detail-invoice-box my-3"
            >
              <h5 className="mx-auto py-1">
                Crescon Projects and Services Pvt Ltd
              </h5>
              <p className="py-1 mx-auto ">
                3rd Floor, Gulecha Towers 158, Arcot Road, Vadapalani Chennai –
                600 026
              </p>
              <h6 className="m-auto py-1">{title}</h6>
            </Col>
            <Col xs={4} className="my-auto ">
              <p className="py-1 fs-13px">
                ISO 9001:2015 : Quality Management System
              </p>
              <p className="py-1 fs-13px">
                ISO 14001:2015 : Environmental Management System
              </p>
              <p className="py-1 fs-13px">
                ISO 45001:2018 : Occupational Health & Safety Management System
              </p>
            </Col>
          </header>
          <main className="">
            <section className="d-flex">
              <Col
                id="left-fields"
                xs={6}
                className="p-3 po-vendor-detail-box "
              >
                {from && <h6>From </h6>}
                {left_fields?.map((item) => (
                  <div className="d-flex ">
                    <Col md={4}>
                      <label className="w-100">{item}</label>
                    </Col>

                    <Col md={8}>
                      <p>{"-"}</p>
                    </Col>
                  </div>
                ))}
              </Col>

              <Col
                id="right-fields"
                xs={6}
                className="p-3 po-vendor-detail-box "
              >
                {to && <h6>To</h6>}
                {right_fields?.map((item) => (
                  <div className="d-flex ">
                    <Col md={4}>
                      <label className="w-100">{item}</label>
                    </Col>

                    <Col md={8}>
                      <p>{"-"}</p>
                    </Col>
                  </div>
                ))}
              </Col>
            </section>

            <Col className="p-2  m-1 text-center">
              <div
                style={{ overflowX: "scroll" }}
                className="po-out-table bg-white"
              >
                <table
                  id="po-output-table"
                  className="table table-responsive-xl"
                >
                  <thead>
                    <tr>
                      {table_heading?.map((heading) => (
                        <th>{heading}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {sample_data?.map((item) => (
                        <td>{item}</td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
            <div className="d-flex gap-2 m-3">
              <label>Amount In Words</label>
              <p className="mt-1">({numToWords})</p>
            </div>
            <section>
              <div className="d-flex p-2 mx-2 align-items-center">
                <Col md={2} lg={1}>
                  <label>Remarks</label>
                </Col>
                <Col md={10} lg={11}>
                  <p>: Sample Remark</p>
                </Col>
              </div>
              <div className="d-flex justify-content-between w-90">
                <div>
                  <p>{user?.name}</p>
                  <label>Prepared</label>
                </div>
                <p>Approved</p>
              </div>
            </section>
          </main>
        </section>
      </div>
      <button
        class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
        onClick={() => downloadPdfDocument("po-out-main-cont")}
      >
        Print
      </button>
    </Col>
  );
}

export default IsmPrintScreen;
