import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
import Header from "../../component/Header/Header";
import AttributeForm from "../../component/MatrialMaster/AttributeForm";
import MaterialMasterForm from "../../component/MatrialMaster/MaterialMasterForm";
import MaterialMasterLevl from "../../component/MatrialMaster/MaterialMasterLevl";

const MaterialMasterScreen = () => {
  const [showAttribute, setShowAttribute] = useState(false);
  const [matId, setMatId] = useState(null);
  const [materialLevelDetails, setMaterialLevelDetails] = useState({
    parent_material: [],
    child_material: [],
  });
  const [error, setError] = useState([]);

  const [MaterialMasFormDetails, setMaterialMasFormDetails] = useState({
    group: "",
    uom: "",
    parent_name: "",
    level: "",
  });

  const [materialMasRequiredFields, setMaterialMasRequiredFields] = useState({
    name: "",
    code: "",
    material_type: "",
    is_child: "",
  });

  const [attributeOptionalFields, setAttributeOptionalFields] = useState({
    specification1: "",
    specification2: "",
    specification3: "",
    specification4: "",
  });

  const [attributeRequiredDetails, setAttributeRequiredDetails] = useState({
    make: "",
    color: "",
    model: "",
    attribute_type: "",
  });

  const [materialQuantity, setMaterialQuantity] = useState({
    name: "",
    value: "",
  });
  // console.log("materialQuantity:", materialQuantity?.value);
  useEffect(() => {
    api.material_assests_search("?type=2").then((res) => {
      if (res?.status === "success") {
        setMaterialLevelDetails({
          parent_material: res?.parent_suggestions,
          child_material: res?.other_level_sugestions,
        });
      }
      // console.log("mater-search-res", res);
    });
  }, []);

  useEffect(() => {
    if (matId) {
      api.material_show(matId).then((res) => {
        setMaterialMasFormDetails({
          ...MaterialMasFormDetails,
          parent_name: res?.material?.name ?? "",
        });
      });
    }
  }, [matId]);
  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <MaterialMasterForm
        setError={setError}
        error={error}
        setMatId={setMatId}
        matId={matId}
        MaterialMasFormDetails={MaterialMasFormDetails}
        setMaterialMasFormDetails={setMaterialMasFormDetails}
        materialMasRequiredFields={materialMasRequiredFields}
        setMaterialMasRequiredFields={setMaterialMasRequiredFields}
        setShowAttribute={setShowAttribute}
        showAttribute={showAttribute}
        materialQuantity={materialQuantity}
        setMaterialQuantity={setMaterialQuantity}
      />
      {showAttribute && (
        <AttributeForm
          attributeOptionalFields={attributeOptionalFields}
          attributeRequiredDetails={attributeRequiredDetails}
          setAttributeOptionalFields={setAttributeOptionalFields}
          setAttributeRequiredDetails={setAttributeRequiredDetails}
          setError={setError}
          error={error}
          setMatId={setMatId}
          matId={matId}
          setShowAttribute={setShowAttribute}
          MaterialMasFormDetails={MaterialMasFormDetails}
          setMaterialMasFormDetails={setMaterialMasFormDetails}
          materialMasRequiredFields={materialMasRequiredFields}
          setMaterialMasRequiredFields={setMaterialMasRequiredFields}
        />
      )}
      <MaterialMasterLevl
        setMatId={setMatId}
        materialLevelDetails={materialLevelDetails}
      />
    </Col>
  );
};

export default MaterialMasterScreen;
