import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";

const BankDetailsTab = ({ setBank, bank, setBtn3, tabonClickHandler }) => {
  const [bankName, setBankName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [accountType, setAccountType] = useState("");
  const [IFSC, setIFSC] = useState("");
  const [branch, setBranch] = useState("");

  const [bankNameErr, setBankNameErr] = useState(false);
  const [accountNoErr, setAccountNoErr] = useState(false);
  const [accountTypeErr, setAccountTypeErr] = useState(false);
  const [IFSCErr, setIFSCErr] = useState(false);
  const [branchErr, setBranchErr] = useState(false);

  const submit = () => {
    if (
      bankName?.length == 0 ||
      accountNo?.length == 0 ||
      accountType?.length == 0 ||
      IFSC?.length == 0 ||
      branch?.length == 0
    ) {
      setBankNameErr(true);
      setAccountNoErr(true);
      setAccountTypeErr(true);
      setIFSCErr(true);
      setBranchErr(true);
    } else {
      alert("Bank Detaills Saved");

      let data = {};

      data.bankName = bankName;
      data.accountNo = accountNo;
      data.accountType = accountType;
      data.IFSC = IFSC;
      data.branch = branch;

      tabonClickHandler("rating");
      setBank(data);
      setBtn3(1);
    }
  };

  const view = () => {
    if (
      bank?.bankName == undefined &&
      bank?.accountNo == undefined &&
      bank?.accountType == undefined &&
      bank?.IFSC == undefined &&
      bank?.branch == undefined
    ) {
      setBankName("");
      setAccountNo("");
      setAccountType("");
      setIFSC("");
      setBranch("");
    } else {
      setBankName(bank?.bankName);
      setAccountNo(bank?.accountNo);
      setAccountType(bank?.accountType);
      setIFSC(bank?.IFSC);
      setBranch(bank?.branch);
    }
  };

  useEffect(() => {
    view();
  }, []);

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-0 text-center">
      <h4>Bank Details</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={4} className="inputinner-box text-start">
          <label className="w-100">
            Name of Bank<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-95 mt-1"
            placeholder="Enter Name of Bank"
            onChange={(e) =>
              setBankName(e.target.value.replace(/[^\sa-z]/gi, ""))
            }
            value={bankName}
          />
          {bankName?.length == 0 && bankNameErr && (
            <p className="err">Enter bank name</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={4} className="inputinner-box text-start">
          <label>
            Account No<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-95 mt-1"
            placeholder="Enter Account No"
            onChange={(e) => setAccountNo(e.target.value)}
            value={accountNo}
          />
          {accountNo?.length == 0 && accountNoErr && (
            <p className="err">Enter account no</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={4} className="inputinner-box text-start">
          <label className="w-100">Account Type</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-95 mt-1"
            placeholder="Enter Account Type"
            onChange={(e) => setAccountType(e.target.value)}
            value={accountType}
          />
          {accountType?.length == 0 && accountTypeErr && (
            <p className="err">Enter account type</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={4} className="inputinner-box text-start">
          <label className="w-100">
            IFSC Code<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-95 mt-1"
            placeholder="Enter IFSC Code"
            onChange={(e) => setIFSC(e.target.value)}
            value={IFSC}
          />
          {IFSC?.length == 0 && IFSCErr && <p className="err">Enter IFSC</p>}
        </Col>
        <Col xs={12} sm={6} xl={4} className="inputinner-box text-start">
          <label className="w-100">Branch Name</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-95 mt-1"
            placeholder="Enter Branch Name"
            onChange={(e) =>
              setBranch(e.target.value.replace(/[^\sa-z]/gi, ""))
            }
            value={branch}
          />
          {branch?.length == 0 && branchErr && (
            <p className="err">Enter branch name</p>
          )}
        </Col>
        <Col sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={submit}>
            Next
          </button>
          {/* <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3">
            cancel
          </button> */}
        </Col>
      </Col>
    </Col>
  );
};

export default BankDetailsTab;
