// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { boq_data } from "../../constants/StaticData";

const BoqAmendTable = ({ unChanged, boqDetail, tab }) => {
  // console.log("🚀 ~ file: BoqTable.js:7 ~ BoqTable ~ boq_details:", boqDetail);
  const [unchangedDa, setUnchangedDa] = useState([]);
  const [newlyDa, setNewlyDa] = useState([]);
  const [deletedDa, setDeletedDa] = useState([]);
  const [changedDa, setChangedDa] = useState([]);

  useEffect(() => {
    let newly = boqDetail?.filter((item) => item?.status == "newly_added");
    setNewlyDa(newly);
    let deletes = boqDetail?.filter((item) => item?.status == "deleted");
    setDeletedDa(deletes);
    let change = boqDetail?.filter((item) => item?.status == "changed");
    setChangedDa(change);
  }, [boqDetail]);

  console.log("unchangedDa", unChanged);
  console.log("newlyDa", newlyDa);
  console.log("deletedDa", deletedDa);
  console.log("changedDa", changedDa);

  // console.log(newly);
  return (
    <Col className="emply-master-form py-2  mt-3 text-center">
      <div style={{ overflowX: "scroll" }} className="boq-table bg-white">
        <table className="table">
          <thead>
            <tr>
              <th> Sl.No</th>
              <th>Material Code</th>
              <th>Material Description</th>
              <th>BOQ Code</th>
              <th style={{ minWidth: "510px" }}>BOQ Description Unit</th>
              <th>UOM</th>
              <th>Quantity</th>
              <th>Supply Rate </th>
              <th>Installation Rate </th>
              <th>B.Material-1</th>
              <th>Accessories-1</th>
              <th>Fitting </th>
              <th>Support</th>
              <th>Accessories-2</th>
              <th>Welding</th>
              <th>Paint</th>
              <th>Insulation</th>
              <th>MISC</th>
              <th>Basic Rate</th>
              <th>Accessories Rate</th>
              <th>Transport Charges</th>
              <th>Cont.Rate</th>
              <th>Financial Rate</th>
              <th>GST Supp %</th>
              <th>GST Incl %</th>
            </tr>
          </thead>
          <tbody>
            {unChanged?.length > 0 &&
              tab == "unchanged" &&
              unChanged?.map((item, ind) => {
                return (
                  <tr key={item?.id}>
                    <td>{ind + 1}</td>
                    <td>{item?.material_code}</td>
                    <td>{item?.material_description}</td>
                    <td>{item?.boq_code}</td>
                    <td>{item?.boq_description}</td>
                    <td>{item?.uom}</td>
                    <td>{item?.qty}</td>
                    <td>{item?.supply_rate}</td>
                    <td>{item?.installation_rate}</td>
                    <td>{item?.b_material_1}</td>
                    <td>{item?.accessories_1}</td>
                    <td>{item?.fitting}</td>
                    <td>{item?.support}</td>
                    <td>{item?.accessories_2}</td>
                    <td>{item?.wellding}</td>
                    <td>{item?.paint}</td>
                    <td>{item?.insulation}</td>
                    <td>{item?.misc}</td>
                    <td>{item?.basic_rate}</td>
                    <td>{item?.accessories_rate}</td>
                    <td>{item?.transport_charges}</td>
                    <td>{item?.cont_rate}</td>
                    <td>{item?.financial_rate}</td>
                    <td>{item?.gst_supply}</td>
                    <td>{item?.gst_inclu}</td>
                  </tr>
                );
              })}
            {newlyDa?.length > 0 &&
              tab == "newly_added" &&
              newlyDa?.map((item, ind) => {
                return (
                  <tr key={item?.id}>
                    <td>{ind + 1}</td>
                    <td>{item?.material_code}</td>
                    <td>{item?.material_description}</td>
                    <td>{item?.boq_code}</td>
                    <td>{item?.boq_description}</td>
                    <td>{item?.uom}</td>
                    <td>{item?.qty}</td>
                    <td>{item?.supply_rate}</td>
                    <td>{item?.installation_rate}</td>
                    <td>{item?.b_material_1}</td>
                    <td>{item?.accessories_1}</td>
                    <td>{item?.fitting}</td>
                    <td>{item?.support}</td>
                    <td>{item?.accessories_2}</td>
                    <td>{item?.wellding}</td>
                    <td>{item?.paint}</td>
                    <td>{item?.insulation}</td>
                    <td>{item?.misc}</td>
                    <td>{item?.basic_rate}</td>
                    <td>{item?.accessories_rate}</td>
                    <td>{item?.transport_charges}</td>
                    <td>{item?.cont_rate}</td>
                    <td>{item?.financial_rate}</td>
                    <td>{item?.gst_supply}</td>
                    <td>{item?.gst_inclu}</td>
                  </tr>
                );
              })}
            {deletedDa?.length > 0 &&
              tab == "deleted" &&
              deletedDa?.map((item, ind) => {
                return (
                  <tr key={item?.id}>
                    <td>{ind + 1}</td>
                    <td>{item?.material_code}</td>
                    <td>{item?.material_description}</td>
                    <td>{item?.boq_code}</td>
                    <td>{item?.boq_description}</td>
                    <td>{item?.uom}</td>
                    <td>{item?.qty}</td>
                    <td>{item?.supply_rate}</td>
                    <td>{item?.installation_rate}</td>
                    <td>{item?.b_material_1}</td>
                    <td>{item?.accessories_1}</td>
                    <td>{item?.fitting}</td>
                    <td>{item?.support}</td>
                    <td>{item?.accessories_2}</td>
                    <td>{item?.wellding}</td>
                    <td>{item?.paint}</td>
                    <td>{item?.insulation}</td>
                    <td>{item?.misc}</td>
                    <td>{item?.basic_rate}</td>
                    <td>{item?.accessories_rate}</td>
                    <td>{item?.transport_charges}</td>
                    <td>{item?.cont_rate}</td>
                    <td>{item?.financial_rate}</td>
                    <td>{item?.gst_supply}</td>
                    <td>{item?.gst_inclu}</td>
                  </tr>
                );
              })}
            {changedDa?.length > 0 &&
              tab == "changed" &&
              changedDa?.map((item, ind) => {
                return (
                  <tr key={item?.id}>
                    <td>{ind + 1}</td>
                    <td
                      style={{
                        background: item?.changedColumns?.material_code
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.material_code}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.material_description
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.material_description}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.boq_code
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.boq_code}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.boq_description
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.boq_description}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.uom
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.uom}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.qty
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.qty}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.supply_rate
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.supply_rate}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.installation_rate
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.installation_rate}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.b_material_1
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.b_material_1}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.accessories_1
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.accessories_1}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.fitting
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.fitting}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.support
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.support}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.accessories_2
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.accessories_2}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.wellding
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.wellding}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.paint
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.paint}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.insulation
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.insulation}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.misc
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.misc}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.basic_rate
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.basic_rate}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.accessories_rate
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.accessories_rate}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.transport_charges
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.transport_charges}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.cont_rate
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.cont_rate}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.financial_rate
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.financial_rate}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.gst_supply
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.gst_supply}
                    </td>
                    <td
                      style={{
                        background: item?.changedColumns?.gst_inclu
                          ? "#c2c2c2"
                          : "transparent",
                      }}
                    >
                      {item?.gst_inclu}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </Col>
  );
};

export default BoqAmendTable;
