import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { logo } from "../../assets/img";
import { api } from "../../constants/Services";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Header from "../../component/Header/Header";
import useUser from "../../constants/Storage/userData";
// import html2PDF from "jspdf-html2canvas";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";

function MaterialReturnOutputScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const types = location?.state?.type;
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useUser();
  const [poPrintDetails, setPOPrintDetails] = useState([]);
  const [terms, setTerms] = useState([]);
  const [directPoAttributes, setDirectPoAttributes] = useState([]);
  const [poAnnex, setPOAnnex] = useState([]);
  const [numToWords, setNumToWords] = useState("");
  const [poTable, setPoTable] = useState([]);
  const [approval, setApproval] = useState("");
  // poPrintDetails?.material_request?.request_type == 1
  // console.log("location", location);
  function doConvert(numberInput) {
    let oneToTwenty = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    let tenth = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    let numToWord = numberInput.toString();
    if (numToWord.includes(".")) numToWord = numToWord.split(".")[0];

    let num = ("000000000" + numToWord)
      .slice(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

    if (!num) return;

    let outputText =
      num[1] != 0
        ? (oneToTwenty[Number(num[1])] ||
            `${tenth[num[1][0]]} ${oneToTwenty[num[1][1]]}`) + " crore "
        : "";
    outputText +=
      num[2] != 0
        ? (oneToTwenty[Number(num[2])] ||
            `${tenth[num[2][0]]} ${oneToTwenty[num[2][1]]}`) + " lakh "
        : "";

    outputText +=
      num[3] != 0
        ? (oneToTwenty[Number(num[3])] ||
            `${tenth[num[3][0]]} ${oneToTwenty[num[3][1]]}`) + "thousand "
        : "";
    outputText +=
      num[4] != 0
        ? (oneToTwenty[Number(num[4])] ||
            `${tenth[num[4][0]]} ${oneToTwenty[num[4][1]]}`) + "hundred "
        : "";
    outputText +=
      num[5] != 0
        ? (oneToTwenty[Number(num[5])] ||
            `${tenth[num[5][0]]} ${oneToTwenty[num[5][1]]}`) + " "
        : "";
    // console.log("Result-1 = ", outputText);

    outputText = outputText?.charAt(0).toUpperCase() + outputText?.slice(1);
    // console.log("Result = ", outputText);

    setNumToWords(outputText);
  }

  const getList = (paramId) => {
    api
      .matertialReturnView(paramId ? paramId : location.state?.id)
      .then((res) => {
        if (res?.status == "success") {
          setPOPrintDetails(res?.material_return);
          const totalValue =
            res?.material_return?.material_return_detail?.reduce(
              (acc, po) =>
                acc +
                (+po?.purchase_order_detail?.net_value /
                  +po?.purchase_order_detail?.qty) *
                  +po?.return_qty,
              0
            );
          setApproval(res?.material_return?.approval?.[0]?.employee?.name);
          if (totalValue) {
            doConvert(totalValue);
          }
        }
      });
  };

  const getInternalList = () => {
    api.internReturnView(location.state?.id).then((res) => {
      if (res?.status == "success") {
        setPOPrintDetails(res?.internal_return);
        const totalValue = res?.internal_return?.internal_return_detail?.reduce(
          (acc, po) => acc + po?.return_qty,
          0
        );
        setApproval(res?.internal_return?.approval?.[0]?.employee?.name);
        if (totalValue) {
          doConvert(totalValue);
        }
      }
    });
  };

  useEffect(() => {
    if (types == "internal") {
      getInternalList();
    } else if (searchParams?.get("id")) {
      getList(searchParams?.get("id"));
    } else {
      getList();
    }
  }, [searchParams?.get("id")]);

  const downloadPdfDocument = (div_id) => {
    const divToPrint = document.getElementById(div_id);

    // return false;
    window.scrollTo(0, 0);
    html2canvas(divToPrint).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 190;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      // return false;
      let heightLeft = imgHeight;
      const doc = new JsPDF("pt", "mm");
      let position = 0;
      doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight + 24);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight + 24);
        heightLeft -= pageHeight;
      }
      doc.save("PoInvoice.pdf");
    });
  };

  return (
    <Col
      xs={12}
      className="py-2 px-1 p-md-3 w-lg-90"
      // style={{ width: "1039px", height: "1216px" }}
    >
      <Header />
      <div
        // style={{ height: "100vh", overflow: "hidden" }}
        id="po-out-main-cont"
        className="po-main-box my-3 avoid-break"
      >
        <section className="p-3">
          <header className="d-flex ">
            <Col xs={1} className="d-flex my-3 ">
              <img
                className="ms-4"
                style={{ width: "70px", height: "70px" }}
                src={logo}
                alt="logo"
              />
            </Col>
            <Col
              xs={7}
              className="d-flex flex-column po-vendor-detail-invoice-box my-3"
            >
              <h5 className="mx-auto py-1">
                Crescon Projects and Services Pvt Ltd
              </h5>
              <p className="py-1 mx-auto ">
                3rd Floor, Gulecha Towers 158, Arcot Road, Vadapalani Chennai –
                600 026
              </p>
              <h6 className="m-auto py-1">
                {types == "internal" ? "Internal Return" : "Material Return"}
              </h6>
            </Col>
            <Col xs={4} className="my-auto ">
              <p className="py-1 fs-13px">
                ISO 9001:2015 : Quality Management System
              </p>
              <p className="py-1 fs-13px">
                ISO 14001:2015 : Environmental Management System
              </p>
              <p className="py-1 fs-13px">
                ISO 45001:2018 : Occupational Health & Safety Management System
              </p>
            </Col>
          </header>
          <main className="">
            <section className="d-flex">
              <Col xs={6} className="po-vendor-detail-box p-3">
                <div className="d-flex  py-1 my-1">
                  <Col md={5}>
                    <label>Project Name</label>
                  </Col>
                  <Col md={7}>
                    <p>{poPrintDetails?.project?.name}</p>
                  </Col>
                </div>

                <div className="d-flex  my-1 py-1">
                  <Col md={5}>
                    <label className="text-nowrap">
                      {types == "internal" ? "Return Slip No" : "PO Number"}
                    </label>
                  </Col>
                  <Col md={7}>
                    <p className="mt-1">
                      {poPrintDetails?.request_slip_no ||
                        poPrintDetails?.purchase_order?.po_no}
                    </p>
                  </Col>
                </div>
                <div className="d-flex  py-1 my-1">
                  <Col md={5}>
                    <label style={{ width: "124px" }}>Return Receipt No.</label>
                  </Col>
                  <Col md={7}>
                    <p>{poPrintDetails?.return_no}</p>
                  </Col>
                </div>
                <div className="d-flex  py-1 my-1">
                  <Col md={5}>
                    <label style={{ width: "134px" }}>
                      Return Receipt Date
                    </label>
                  </Col>
                  <Col md={7}>
                    <p>{poPrintDetails?.return_date}</p>
                  </Col>
                </div>
                <div className="d-flex ">
                  <Col md={5}>
                    <label className="text-nowrap">
                      {types == "internal" ? "Return Slip Date" : "Vendor Name"}
                    </label>
                  </Col>
                  <Col md={7}>
                    <p className="mt-1">
                      {poPrintDetails?.request_slip_date ||
                        poPrintDetails?.vendor?.company_name}
                    </p>
                  </Col>
                </div>
                {types == "internal" && (
                  <div className="d-flex  py-1 my-1">
                    <Col md={5}>
                      <label>Return By</label>
                    </Col>
                    <Col md={7}>
                      <p>{poPrintDetails?.return_by}</p>
                    </Col>
                  </div>
                )}
                {/* <div className="d-flex  py-1 my-1">
                  <Col md={5}>
                    <label className="text-nowrap">GRN Number</label>
                  </Col>
                  <Col md={7}>
                    <p className="mt-1">
                      {poPrintDetails?.issue_date ||
                        poPrintDetails?.grn?.grn_no}
                    </p>
                  </Col>
                </div>
                <div className="d-flex  my-1 py-1">
                  <Col md={5}>
                    <label className="text-nowrap">GRN Date</label>
                  </Col>
                  <Col md={7}>
                    <p className="mt-1">
                      {poPrintDetails?.issue_no ||
                        poPrintDetails?.grn?.grn_date}
                    </p>
                  </Col>
                </div> */}

                <div className="d-flex ">
                  <Col md={5}>
                    <label>WareHouse</label>
                  </Col>

                  <Col md={7}>
                    <p>
                      {poPrintDetails?.warehouse?.name ||
                        poPrintDetails?.material_issue?.warehouse?.name}
                    </p>
                    {/* <p>
                      {poPrintDetails?.warehouse?.address ||
                        poPrintDetails?.grn?.warehouse?.address}
                    </p> */}
                  </Col>
                </div>
              </Col>
              <Col
                style={{ borderRight: "1px solid black" }}
                xs={6}
                className="po-vendor-detail-invoice-box p-3"
              >
                <h6>Invoicing Address:</h6>

                <div className="d-flex flex-wrap">
                  <Col md={4}>
                    <label>Head Office</label>
                  </Col>
                  <Col md={8}>
                    <p>
                      {types == "internal"
                        ? "Crescon Projects and Services Pvt Ltd"
                        : poPrintDetails?.project?.head_office}
                    </p>
                  </Col>
                  <Col md={4}>
                    <label>Address</label>
                  </Col>
                  <Col md={8}>
                    <p>
                      {types == "internal"
                        ? "3rd Floor, Gulecha Towers 158, Arcot Road, Vadapalani – 600 026"
                        : poPrintDetails?.project?.door_no +
                          " " +
                          poPrintDetails?.project?.address +
                          " " +
                          poPrintDetails?.project?.district +
                          " " +
                          poPrintDetails?.project?.state}
                    </p>
                  </Col>

                  <Col md={4} className="mt-4">
                    <label>Contact Person</label>
                  </Col>
                  <Col md={8} className="mt-4">
                    <p>
                      {types == "internal"
                        ? "M. Janakiraman"
                        : poPrintDetails?.project?.name}
                    </p>
                  </Col>
                  <Col md={4}>
                    <label>Contact No</label>
                  </Col>
                  <Col md={8}>
                    <p>
                      {types == "internal"
                        ? "044 - 2365 2551"
                        : poPrintDetails?.project?.mobile}
                    </p>
                  </Col>
                  <Col md={4}>
                    <label>Mail ID</label>
                  </Col>
                  <Col md={8}>
                    <p>
                      {types == "internal"
                        ? "purchase@cresconprojects.com"
                        : poPrintDetails?.project?.email}
                    </p>
                  </Col>
                  {types == "internal" && (
                    <>
                      {" "}
                      <Col md={4}>
                        <label>Gst No</label>
                      </Col>
                      <Col md={8}>
                        <p>33AAECC6175F1ZT</p>
                      </Col>
                    </>
                  )}
                </div>
              </Col>
            </section>

            <Col className="p-2  m-1 text-center">
              <div
                style={{ overflowX: "scroll" }}
                className="po-out-table bg-white"
              >
                <table
                  id="po-output-table"
                  className="table table-responsive-xl"
                >
                  <thead>
                    {types == "internal" ? (
                      <tr>
                        <th>Sl.No</th>
                        <th>Material Code</th>
                        <th>Material Description</th>
                        <th>UOM</th>
                        <th>Issued Quantity</th>
                        <th>Return Quantity</th>
                        <th>Remarks</th>
                      </tr>
                    ) : (
                      <tr>
                        <th>Sl.No</th>
                        <th>Material Code</th>
                        <th>Material Description</th>
                        <th>UOM</th>
                        <th>Stock Quantity</th>
                        <th>Return Quantity</th>
                        <th>Invoice Amount</th>
                        <th>Remarks</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {types == "internal"
                      ? poPrintDetails?.material_return_detail?.map(
                          (item, ind) => {
                            return (
                              <tr>
                                <td>{ind + 1}</td>
                                <td>
                                  {
                                    item?.material_issue_detail?.material
                                      ?.material_code
                                  }
                                </td>
                                <td>
                                  {item?.material_issue_detail?.material?.name}
                                </td>
                                <td>
                                  {item?.material_issue_detail?.material?.uom}
                                </td>
                                <td>
                                  {item?.material_issue_detail?.issued_qty}
                                </td>
                                <td>{item?.return_qty}</td>
                                <td>{item?.return_qty}</td>
                                <td>{item?.remarks}</td>
                              </tr>
                            );
                          }
                        )
                      : poPrintDetails?.material_return_detail?.map(
                          (item, ind) => {
                            return (
                              <>
                                <tr key={item?.id}>
                                  <td>{ind + 1}</td>
                                  <td>
                                    {
                                      item?.purchase_order_detail?.material
                                        ?.material_code
                                    }
                                  </td>
                                  <td>
                                    {" "}
                                    {
                                      item?.purchase_order_detail?.material
                                        ?.name
                                    }
                                  </td>
                                  <td>
                                    {" "}
                                    {item?.purchase_order_detail?.material?.uom}
                                  </td>
                                  <td> {item?.available_stock}</td>
                                  {/* <td> {item?.grn_detail?.received_qty}</td> */}
                                  <td> {item?.return_qty}</td>
                                  <td>
                                    {" "}
                                    {Number(
                                      (+item?.purchase_order_detail?.net_value /
                                        +item?.purchase_order_detail?.qty) *
                                        +item?.return_qty
                                    ).toFixed(2)}
                                  </td>
                                  <td>{item?.remarks}</td>
                                </tr>
                              </>
                            );
                          }
                        )}
                  </tbody>
                </table>
              </div>
            </Col>

            {types == "internal" && (
              <div className="d-flex gap-2 m-3 page_break_before_after">
                <label>Amount In Words</label>
                <p className="mt-1">
                  ({numToWords ? `Rupees ${numToWords} Only` : "-"})
                </p>
              </div>
            )}

            <section className="po-terms-box page_break_before_after">
              <h6>Remarks</h6>
              <div>{poPrintDetails?.remarks}</div>
            </section>

            <section>
              <div className="d-flex justify-content-between w-90 page_break_before_after">
                <div>
                  <p className="text-capitalize">
                    {poPrintDetails?.created_user?.name ||
                      poPrintDetails?.employee?.name}
                  </p>
                  <label>Prepared</label>
                </div>

                <div>
                  <p className="text-capitalize">
                    {poPrintDetails?.created_user?.name ||
                      poPrintDetails?.employee?.name}
                  </p>
                  <label>Prepared</label>
                </div>

                <div>
                  <p className="text-capitalize">{approval ? approval : "-"}</p>
                  <label>Approved</label>
                </div>
              </div>
            </section>
          </main>
          <footer id="page-break" className="d-flex">
            <Col
              xs={6}
              style={{ borderRight: "1px solid black", minHeight: "6rem" }}
              className="d-flex flex-column"
            >
              <p className="">
                Please Sign and Return Duplicate Copy as a Token of Acceptance
              </p>
              <p className="mt-auto mb-1">Vendor Acceptance</p>
            </Col>
            <Col xs={6} className="d-flex flex-column">
              <p className="ms-auto me-2 pe-3">
                For Crescon Projects and Services Pvt Ltd
              </p>
              <p className="mt-auto ms-auto me-4 mb-1">Authorised Signatory</p>
            </Col>
          </footer>
        </section>
      </div>{" "}
      <div className="d-flex justify-content-between">
        <div>
          {poPrintDetails?.status == 2 && (
            <button
              class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
              onClick={() => downloadPdfDocument("po-out-main-cont")}
            >
              Print
            </button>
          )}
        </div>
        <div className="">
          {searchParams?.get("id") &&
          searchParams.get("path") == "mrl" &&
          searchParams.get("method") !== "noti" ? (
            <Link
              class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2 "
              to={`/ism/material-return-list?id=${searchParams?.get(
                "id"
              )}&projectID=${searchParams?.get("projectID")}`}
            >
              Close
            </Link>
          ) : searchParams?.get("id") &&
            searchParams.get("path") !== "mrl" &&
            searchParams.get("method") == "noti" ? (
            <button
              class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
              onClick={() => navigate("/notification")}
            >
              Close
            </button>
          ) : (
            <button
              class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
              onClick={() => navigate(-1)}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </Col>
  );
}

export default MaterialReturnOutputScreen;
