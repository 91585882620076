// @ts-nocheck
import React, { useEffect, useState } from "react";
import { tickIcon } from "../../../assets/img";
import { api } from "../../../constants/Services";
import { ism_mat_issue_data } from "../../../constants/StaticData";

const IsmMaterialIssueTable = ({
  tableSel,
  setTableSel,
  setMaterialTab,
  materialTab,
  project,
  setMaterialList,
  materialList,
  setMaterials,
  materials,
}) => {
  const [row, setRow] = useState([1]);
  const [matCode, setMatCode] = useState([]);

  const [dropDown, setDropDown] = useState({
    boq: "",
    m_code: "",
    m_name: "",
  });

  const [filter, setFilter] = useState({
    material_code: "",
    material_name: "",
  });

  useEffect(() => {
    if (materialList?.length > 0) {
      setMatCode(materialList);
    }
  }, [materialList]);

  const getMaterialList = (id) => {
    let obj = {
      project_id: project,
      material_id: id,
    };

    api
      .matertialStockCheck(obj)
      .then((res) => {})
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getMaterialsList = () => {
    let params = "?type=2";
    api
      .getMaterialsLists(params)
      .then((res) => {
        if (res?.status == "success") {
          setMaterialList(res?.materials);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    // getMaterialsList();
  }, []);

  const addRow = () => {
    let temp = [...row];
    temp.push(1);
    setRow(temp);
  };

  const getMaterial = (item) => {
    // console.log("it", item);
    let split = item?.split(",");
    // console.log("split", split);
    getMaterialList(+split[0]);
    let finder = materialList?.find((i) => i?.id == +split[0]);
    // console.log("item", item);
    // console.log("split", split);
    console.log("finder", finder);
    // setMaterialTab([]);
    setMaterials((val) => ({ ...val, [+split[1]]: finder }));
    setDropDown({ ...dropDown, boq: "", m_code: "" });
    let temp = [...materialTab];
    temp.push(finder?.id);
    setMaterialTab(temp);

    // let obj = {
    //   project_id: project,
    //   material_id: +split[0],
    // };

    // api
    //   .matertialStockCheck(obj)
    //   .then((res) => {
    //     if (res?.status == "success") {
    //       let obj = {
    //         ...res?.stock_qty,
    //         remarks: "",
    //         issued_qty: "",
    //       };
    //       setMaterialTab([]);
    //       setMaterials((val) => ({ ...val, [+split[1]]: obj }));
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //   });
  };

  // console.log("materials", materials);

  const handleChange = (event, ind, type, id) => {
    let obj = { ...materials };
    let temp = obj[ind];
    let arr = [...materialTab];
    let inx = arr.findIndex((obj) => obj.id == id);

    // console.log("temp", temp);

    if (type == "issued_qty") {
      let final = {
        ...temp,
        [type]: event,
        ["error"]: +event > +temp?.received_qty_cumulative ? 1 : 0,
      };

      setMaterials((val) => ({ ...val, [ind]: final }));
      arr[inx] = final;
    } else {
      let final = {
        ...temp,
        [type]: event,
      };

      setMaterials((val) => ({ ...val, [ind]: final }));
      arr[inx] = final;
    }

    // setMaterialTab(arr);
  };

  const checkBox = (item) => {
    let temp = [...materialTab];

    if (temp.includes(item)) {
      let inx = temp.indexOf(item);
      temp.splice(inx, 1);
    } else {
      temp.push(item);
    }

    setMaterialTab(temp);
  };

  const dropHandler = (id, sel_drop) => {
    // console.log("id", id);
    // console.log("sel_drop", sel_drop);
    if (sel_drop == "boq") {
      dropDown?.boq == id
        ? setDropDown({ ...dropDown, boq: "" })
        : setDropDown({ ...dropDown, boq: id });
    } else if (sel_drop == "m_code") {
      dropDown?.m_code == id
        ? setDropDown({ ...dropDown, m_code: "" })
        : setDropDown({ ...dropDown, m_code: id });
    } else {
      dropDown?.m_name == id
        ? setDropDown({ ...dropDown, m_name: "" })
        : setDropDown({ ...dropDown, m_name: id });
    }
  };

  console.log("materials", materials);

  // console.log("matCode", matCode);

  const selectFilter = (event, type) => {
    if (type == "code") {
      setFilter({
        ...filter,
        material_code: event,
      });

      if (event?.length > 0) {
        let finder = materialList?.filter((i) =>
          i?.material?.material_code
            ?.toLocaleLowerCase()
            ?.includes(event.toLocaleLowerCase())
        );

        setMatCode(finder);
      } else {
        setMatCode(materialList);
      }
    } else {
      setFilter({
        ...filter,
        material_name: event,
      });

      if (event?.length > 0) {
        let finder = materialList?.filter((i) =>
          i?.material?.name
            ?.toLocaleLowerCase()
            ?.includes(event.toLocaleLowerCase())
        );

        setMatCode(finder);
      } else {
        setMatCode(materialList);
      }
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        <button
          className="bg-primar border-0 rounded-1 my-1 text-white f-1 fs-14 px-4 py-2 ms-auto"
          onClick={() => addRow()}
        >
          Add
        </button>
      </div>
      <div className="user-mange-table">
        <table>
          <thead>
            <tr>
              <th>S.No</th>
              <th className="text-nowrap">Material Code</th>
              <th className="text-nowrap">Material Description</th>
              <th>UOM</th>
              <th className="text-nowrap text-center">Stock Quantity</th>
              <th className="text-nowrap text-center">Issued Quantity</th>
              <th className="text-nowrap text-center">Remarks</th>
            </tr>
          </thead>
          <tbody>
            {row?.map((item, ind) => {
              return (
                <tr key={ind}>
                  <td>
                    <div className="d-flex gap-1 align-items-center">
                      <div>{ind + 1}</div>{" "}
                      <div>
                        {materials &&
                        materials[ind]?.material?.material_code ? (
                          <input
                            type={"checkbox"}
                            className="mt-1"
                            onClick={() => checkBox(materials[ind]?.id)}
                            checked={materialTab?.includes(materials[ind]?.id)}
                          />
                        ) : (
                          <input type={"checkbox"} className="mt-1" disabled />
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    {/* {materials && materials[ind]?.material?.material_code ? (
                      materials[ind]?.material?.material_code
                    ) : (
                      <div>
                        <select
                          className="border-0 bg-lightBlue px-2 py-1 mt-1 pointer"
                          onChange={(e) => getMaterial(e.target.value)}
                        >
                          <option hidden>Select Material Code</option>
                          {materialList?.map((item, index) => {
                            return (
                              <option key={index} value={[item?.id, ind]}>
                                {item?.material?.material_code}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )} */}

                    <p
                      style={{ minWidth: "110px",fontSize:"12px" }}
                      className="pointer"
                      onClick={() => dropHandler(`${ind}_boq`, "boq")}
                    >
                      {materials && materials[ind]?.material?.material_code
                        ? materials && materials[ind]?.material?.material_code
                        : "Select Material Code"}
                    </p>

                    {dropDown?.boq == `${ind}_boq` && (
                      <ul className="drop-cont">
                        <li>
                          <input style={{fontSize:"12px"}}
                            className="border-0 bg-lightBlue px-2 py-1 mt-1"
                            value={filter?.material_code}
                            onChange={(e) =>
                              selectFilter(e.target.value, "code")
                            }
                          />
                        </li>
                        {matCode?.map((item, index) => (
                          <li style={{fontSize:"12px"}}
                            key={index}
                            value={[item?.id, ind]}
                            onClick={(e) =>
                              getMaterial([item?.id, ind].toString())
                            }
                          >
                            {item?.material?.material_code}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                  <td>
                    <p
                      style={{ minWidth: "110px" ,fontSize:"12px" }}
                      className="pointer"
                      onClick={() => dropHandler(`${ind}_m_code`, "boq")}
                    >
                      {materials && materials[ind]?.material?.name
                        ? materials && materials[ind]?.material?.name
                        : "Select Material Code"}
                    </p>

                    {dropDown?.boq == `${ind}_m_code` && (
                      <ul className="drop-cont">
                        <li>
                          <input style={{fontSize:"12px"}}
                            className="border-0 bg-lightBlue px-2 py-1 mt-1"
                            value={filter?.material_name}
                            onChange={(e) =>
                              selectFilter(e.target.value, "name")
                            }
                          />
                        </li>
                        {matCode?.map((item, index) => (
                          <li style={{fontSize:"12px"}}
                            key={index}
                            value={[item?.id, ind]}
                            onClick={(e) =>
                              getMaterial([item?.id, ind].toString())
                            }
                          >
                            {item?.material?.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                  <td style={{textAlign:"center",fontSize:"12px"}}>{materials && materials[ind]?.material?.uom}</td>
                  <td style={{textAlign:"center",fontSize:"12px"}}>
                    {/* <input
                      className="border-0"
                      type="number"
                      value={materials && materials[ind]?.quantity}
                      onChange={(e) =>
                        handleChange(e.target.value, ind, "quantity")
                      }
                      disabled={
                        materials && materials[ind]?.material_code
                          ? false
                          : true
                      }
                    /> */}

                    {/* {materials && materials[ind]?.received_qty} */}
                    {materials && materials[ind]?.received_qty_cumulative}
                  </td>
                  <td>
                    <input style={{textAlign:"center", fontSize:"12px"}}
                      className="border-0"
                      type="number"
                      value={materials && materials[ind]?.issued_qty}
                      onChange={(e) =>
                        handleChange(
                          e.target.value,
                          ind,
                          "issued_qty",
                          materials[ind]?.id
                        )
                      }
                      disabled={
                        materials && materials[ind]?.material?.material_code
                          ? false
                          : true
                      }
                    />
                    {/* {materials &&
                      +materials[ind]?.issued_qty >
                        +materials[ind]?.received_qty && (
                        <p className="err" style={{ fontSize: "10px" }}>
                          Exceeded The Quantity
                        </p>
                      )} */}
                    {materials && +materials[ind]?.error == 1 && (
                      <p className="err" style={{ fontSize: "10px" }}>
                        Exceeded The Quantity
                      </p>
                    )}
                  </td>
                  <td>
                    <input style={{textAlign:"center"}}
                      className="border-0 w-100"
                      value={materials && materials[ind]?.remarks}
                      onChange={(e) =>
                        handleChange(
                          e.target.value,
                          ind,
                          "remarks",
                          materials[ind]?.id
                        )
                      }
                      disabled={
                        materials && materials[ind]?.material?.material_code
                          ? false
                          : true
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>{" "}
    </div>
  );
};

export default IsmMaterialIssueTable;
