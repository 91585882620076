import React, { useState } from "react";
import { Col } from "react-bootstrap";

import moment from "moment";
import { EMAIL } from "../../constants/Services/constants";
import { api } from "../../constants/Services";

const VendorGSTR = ({
  gstFormDetails,
  setGstFormDetails,
  prodectType,
  // supplierName,
  companyName,

  submit,
}) => {
  console.log("🚀 ~ file: VendorGst.js:16 ~  gstFormDetails:", gstFormDetails);
  const [error, setError] = useState([]);

  const gstFormResetHandler = () => {
    setGstFormDetails({
      pan: "",
      temp: "",
      gst_no: "",
      gst_state: "",
      gst_type: "",
      gst_date: moment().format("YYYY-MM-DD"),
    });
  };

  const vendorGstSubmitHandler = () => {
    let temp = [];
    Object.entries(gstFormDetails).forEach((item) => {
      if (item[1] == "") {
        temp.push(item[0]);
      } else {
        error.splice(error.indexOf(item[0]), 1);
        temp.splice(temp.indexOf(item[0], 1));
      }
    });
    setError(temp);
    if (temp.length == 0) {
      submit();
      // gstFormResetHandler();
    }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-3 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">GST Registration</h4>
      <Col className="d-flex flex-wrap mt-2">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Comapany Name</label>
          <p className="">{companyName}</p>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Supplier Type</label>
          {prodectType}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Date</label>
          <input
            value={gstFormDetails.gst_date}
            onChange={(e) =>
              setGstFormDetails({ ...gstFormDetails, gst_date: e.target.value })
            }
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Date"
          />
          {error.includes("gst_date") && (
            <p className="err">Gst Date is Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">PAN</label>
          <p
            style={{ width: "90%", marginRight: "auto", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1">
            {gstFormDetails?.pan}
          </p>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">State Code</label>
          <select
            value={gstFormDetails.gst_state}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 pointer"
            onChange={(e) =>
              setGstFormDetails({
                ...gstFormDetails,
                gst_state: e.target.value,
              })
            }>
            <option>Choose Gst State</option>
            <option value={35}>35 Andaman and Nicobar Islands </option>
            <option value={4}>4 Chandigarh </option>
            <option value={26}>26 Dadra & Nagar Haveli and Daman & Di </option>
            <option value={97}>97 Other Territory </option>
            <option value={37}>37 Andhra Pradesh </option>
            <option value={12}>12 Arunachal Pradesh </option>
            <option value={18}>18 Assam </option>
            <option value={10}>10 Bihar </option>
            <option value={22}>22 Chattisgarh </option>
            <option value={30}>30 Goa</option>
            <option value={24}>24 Gujarat</option>
            <option value={6}>6 Haryana </option>
            <option value={2}>2 Himachal Pradesh</option>
            <option value={1}>1 Jammu and Kashmir </option>
            <option value={20}>20 Jharkhand </option>
            <option value={29}>29 Karnataka</option>
            <option value={32}>32 Kerala</option>
            <option value={31}>31 Lakshadweep Islands </option>
            <option value={23}>23 Madhya Pradesh</option>
            <option value={27}>27 Maharashtra </option>
            <option value={14}>14 Manipur </option>
            <option value={17}>17 Meghalaya </option>
            <option value={15}>15 Mizoram </option>
            <option value={13}>13 Nagaland </option>
            <option value={21}>21 Odisha</option>
            <option value={34}>34 Pondicherry</option>
            <option value={3}>3 Punjab </option>
            <option value={8}>8 Rajasthan </option>
            <option value={11}>11 Sikkim </option>
            <option value={33}>33 Tamil Nadu </option>
            <option value={36}>36 Telangana</option>
            <option value={16}>16 Tripura</option>
            <option value={9}>9 Uttar Pradesh</option>
            <option value={5}>5 Uttarakhand</option>
            <option value={19}>19 West Bengal</option>
          </select>
          {error.includes("gst_state") && (
            <p className="err">Gst State is Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GST Type</label>
          <select
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 pointer"
            value={gstFormDetails.gst_type}
            onChange={(e) =>
              setGstFormDetails({ ...gstFormDetails, gst_type: e.target.value })
            }>
            <option>Choose Gst Type</option>
            <option value="regular">Regular</option>
            <option value="composite">Composite</option>
          </select>
          {error.includes("gst_type") && (
            <p className="err">Gst Type is Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GST Registration Form</label>
          <input
            value={gstFormDetails?.temp}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter your GST Registration Form"
            onChange={(e) =>
              setGstFormDetails({ ...gstFormDetails, temp: e.target.value })
            }
          />
          {error.includes("temp") && (
            <p className="err">Gst Registration Form is Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GST No</label>
          <p
            style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1">
            {`${gstFormDetails?.gst_state}${gstFormDetails?.pan}${gstFormDetails?.temp}`}
          </p>
        </Col>
      </Col>
      <Col sm={12} className="inputinner-box text-start">
        <button
          className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
          onClick={vendorGstSubmitHandler}>
          Submit
        </button>
        <button
          className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
          onClick={gstFormResetHandler}>
          cancel
        </button>
      </Col>
    </Col>
  );
};

export default VendorGSTR;
