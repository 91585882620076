// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { accountTreeIcon, tickIcon } from "../../assets/img";

const ReportLevel = ({ materialLevelDetails, setMatId }) => {
  const [filteredMaterialList, setFilteredMaterialList] = useState([]);

  const materialFilterHandler = (search_value) => {
    const renderedList = materialLevelDetails?.parent_material?.filter(
      (parent) =>
        parent?.material_code
          ?.toLowerCase()
          .includes(search_value.toLowerCase())
    );

    search_value.length > 0
      ? setFilteredMaterialList(renderedList)
      : setFilteredMaterialList([]);
  };
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <Col xs={12} className="inputinner-box text-start text-center">
        <input
          className="border-0 bg-lightBlue px-2 py-1 w-50 mt-1"
          placeholder="Search...,"
          onChange={(e) => materialFilterHandler(e.target.value)}
        />
      </Col>
      <h4>Material List</h4>
      {filteredMaterialList &&
        filteredMaterialList?.map((parent) => (
          <Col key={parent?.id}>
            <button
              className="d-flex level-btn"
              onClick={() => setMatId(parent?.id)}>
              <p className="primary f2">{parent?.material_type}</p>
              <p className="ms-2 f2">{parent?.material_code}</p>
            </button>
            {materialLevelDetails.child_material?.map(
              (child) =>
                child?.parent_id === parent.id && (
                  <Col
                    xs={12}
                    key={child?.id}
                    className="level-master d-flex justify-content-end pointer"
                    onClick={() => setMatId(child?.id)}>
                    <div className="line-space" />
                    <Col className="d-flex level-btn justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="img-box">
                          <img
                            src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1031&q=80"
                            className="inner-img"
                            alt="inner-img"
                          />
                        </div>
                        <div className="text-start ms-2">
                          <p className="text-black fs-14 f3">{child?.name}</p>
                          <p className="text-dark fs-12 f1">{child.level}</p>
                        </div>
                      </div>
                      <button className="tree-icon">
                        <img src={accountTreeIcon} alt="acc-tree" />
                      </button>
                    </Col>
                  </Col>
                )
            )}
          </Col>
        ))}
    </Col>
  );
};

export default ReportLevel;
