import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import UserManagementTable from "./UserManagementTable";
import { api } from "../../constants/Services";

const UserManagement = () => {
  // unlock_employee
  const [lockedEmployees, setLockedEmployees] = useState([]);

  const release_locked_employee = (id) => {
    api.unlock_employee(id).then((res) => {
      if (res?.status === "success") {
        get_locked_employess();
      }
      // console.log("unlock_employee_res", res);
    });
  };
  const get_locked_employess = () => {
    api.locked_employees().then((res) => {
      // console.log("locked_employess", res);
      if (res?.status === "success") setLockedEmployees(res?.employees);
    });
  };

  useEffect(() => {
    get_locked_employess();
  }, []);
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4>User Management</h4>
      {lockedEmployees.length > 0 && (
        <UserManagementTable
          lockedEmployees={lockedEmployees}
          release_locked_employee={release_locked_employee}
        />
      )}
    </Col>
  );
};

export default UserManagement;
