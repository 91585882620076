// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { api } from "../../constants/Services";
import Header from "../../component/Header/Header";

const BoqAmendView = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  // console.log(location);
  const [boqView, setBoqView] = useState();

  useEffect(() => {
    api.boq_view(searchParams.get("id")).then((resp) => {
      console.log(resp);
      setBoqView(resp?.boq_details);
    });
  }, [searchParams.get("id")]);

  // const [selectedProject, setSelectedProject] = useState("");
  const [materialRequestDetails, setMaterialRequestDetails] = useState({
    table: [],
    material_type: {},
  });
  const [list, setList] = useState();

  const material_request_id = location?.state?.id;
  const project_id = location?.state?.proId;

  // useEffect start

  const getView = (id) => {
    // console.log("id", id);
    let data = {
      material_request_id: id,
    };
    api.matrialRequest(data).then((res) => {
      if (res?.status == "success") {
        // setMaterialRequestDetails(
        //   res?.material_request?.material_request_details
        // );
        // console.log("res", res);
        setMaterialRequestDetails({
          material_type: res?.material_request?.material_type,
          table: res?.material_request_list,
        });
      }
    });
  };

  // const getList = () => {
  //   let data = {
  //     project_id: searchParams?.get("proId"),
  //   };
  //   api.matrialList(data).then((res) => {
  //     if (res?.status == "success") {
  //       setList(res?.material_requests);
  //     }
  //   });
  // };

  return (
    <div>
      <Col xs={12} className="py-2 px-1 p-md-3">
        <Header />
        <Col xs={3} className="px-1 float-end">
          <Link
            to={`/home/boq-amendment-list?proId=${searchParams?.get("proId")}`}
          >
            <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-auto">
              Close
            </button>
          </Link>
        </Col>
        <Col className="emply-master-form py-2  mt-3 text-center">
          <div style={{ overflowX: "scroll" }} className="boq-table bg-white">
            <table className="table">
              <thead>
                <tr>
                  <th> Sl.No</th>
                  <th>Material Code</th>
                  <th>Material Description</th>
                  <th>BOQ Code</th>
                  <th style={{ minWidth: "510px" }}>BOQ Description Unit</th>
                  <th>UOM</th>
                  <th>Quantity</th>
                  <th>Supply Rate </th>
                  <th>Installation Rate </th>
                  <th>B.Material-1</th>
                  <th>Accessories-1</th>
                  <th>Fitting </th>
                  <th>Support</th>
                  <th>Accessories-2</th>
                  <th>Welding</th>
                  <th>Paint</th>
                  <th>Insulation</th>
                  <th>MISC</th>
                  <th>Basic Rate</th>
                  <th>Accessories Rate</th>
                  <th>Transport Charges</th>
                  <th>Cont.Rate</th>
                  <th>Financial Rate</th>
                  <th>GST Supp %</th>
                  <th>GST Incl %</th>
                </tr>
              </thead>
              <tbody>
                {boqView?.length > 0 &&
                  boqView?.map((item, ind) => {
                    return (
                      <tr key={item?.id}>
                        <td>{ind + 1}</td>
                        <td>{item?.material_code}</td>
                        <td>{item?.material_description}</td>
                        <td>{item?.boq_code}</td>
                        <td>{item?.boq_description}</td>
                        <td>{item?.uom}</td>
                        <td>{item?.qty}</td>
                        <td>{item?.supply_rate}</td>
                        <td>{item?.installation_rate}</td>
                        <td>{item?.b_material_1}</td>
                        <td>{item?.accessories_1}</td>
                        <td>{item?.fitting}</td>
                        <td>{item?.support}</td>
                        <td>{item?.accessories_2}</td>
                        <td>{item?.wellding}</td>
                        <td>{item?.paint}</td>
                        <td>{item?.insulation}</td>
                        <td>{item?.misc}</td>
                        <td>{item?.basic_rate}</td>
                        <td>{item?.accessories_rate}</td>
                        <td>{item?.transport_charges}</td>
                        <td>{item?.cont_rate}</td>
                        <td>{item?.financial_rate}</td>
                        <td>{item?.gst_supply}</td>
                        <td>{item?.gst_inclu}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Col>
      </Col>
    </div>
  );
};

export default BoqAmendView;
