import React, { useState, useEffect } from "react";
import Header from "../component/Header/Header";
import { Col } from "react-bootstrap";
import NotificationTab from "../component/Notification/NotificationTab";
import { useLocation } from "react-router-dom";
import { api } from "../constants/Services";

const NotificationRequestScreen = () => {
  const [unChanged, setUnChanged] = useState();
  const [boqDetail, setBoqDetail] = useState();

  const location = useLocation();
  const id = location?.state?.listData?.project_boq?.project_id
    ? location?.state?.listData?.project_boq?.project_id
    : location?.state?.listData?.approval?.approvable?.project_id ? location?.state?.listData?.approval?.approvable?.project_id : location?.state?.listData?.project?.id
  const boq_id = location?.state?.listData?.project_boq?.id
    ? location?.state?.listData?.project_boq?.id
    : location?.state?.listData?.approval?.approvable?.id ? location?.state?.listData?.approval?.approvable?.id : location?.state?.listData?.
    previous_approved_list?.approvable?.id;
  console.log("location", location?.state);

  const apiHandler = () => {
    api.notif_compare(id, boq_id).then((res) => {
      console.log(res);
      if (res?.status == "success") {
        // console.log(res);
        setBoqDetail(res?.result);
        setUnChanged(res?.first_boq_details);
      } else {
        // alert(res?.message);
      }
    });
  };

  useEffect(() => {
    apiHandler();
  }, [location?.state]);

  return (
    <div>
      <Col xs={12} className="py-2 px-1 p-md-3">
        <Header />
        <NotificationTab boqDetail={boqDetail} unChanged={unChanged} />
      </Col>
    </div>
  );
};

export default NotificationRequestScreen;
