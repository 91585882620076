// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { tickIcon } from "../../assets/img";
import { api } from "../../constants/Services";
import { BASE_FILE_URL } from "../../constants/Services/constants";
import { mmCheck } from "../../constants/StaticData";
import { AssetsSearch } from "./AssetsSearch";

const AssetsMasterForm = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState("");
  const [upload, setUpload] = useState("");
  const [attributeBoxShow, setAttributeBoxShow] = useState(false);
  // const [color, setColor] = useState();
  const [matId, setMatId] = useState(null);
  const [materialLevelDetails, setMaterialLevelDetails] = useState({
    parent_material: [],
    child_material: [],
  });

  const checkClickHandler = (clicked) => setChecked(clicked);

  const [AssetsGroup, setAssetsGroup] = useState("");
  const [UOM, setUOM] = useState("");
  const [ParentName, setParentName] = useState("");
  const [AssetsType, setAssetsType] = useState("");
  const [AssetsName, setAssetsName] = useState("");
  const [AssetsCode, setAssetsCode] = useState("");
  const [Make, setMake] = useState("");
  const [Color, setColor] = useState("");
  const [Modal, setModal] = useState("");
  const [Type, setType] = useState("");
  const [Specification1, setSpecification1] = useState("");
  const [Specification2, setSpecification2] = useState("");
  const [Specification3, setSpecification3] = useState("");
  const [Specification4, setSpecification4] = useState("");
  const [value, setValue] = useState("");
  const [perValue, setPerValue] = useState("");
  const [quantity, setQuantity] = useState("");
  const [AssetsGroupErr, setAssetsGroupErr] = useState(false);
  const [UOMErr, setUOMErr] = useState(false);
  const [ParentNameErr, setParentNameErr] = useState(false);
  const [AssetsTypeErr, setAssetsTypeErr] = useState(false);
  const [AssetsNameErr, setAssetsNameErr] = useState(false);
  const [MakeErr, setMakeErr] = useState(false);
  const [ColorErr, setColorErr] = useState(false);
  const [ModalErr, setModalErr] = useState(false);
  const [TypeErr, setTypeErr] = useState(false);
  const [SpecificationErr1, setSpecificationErr1] = useState(false);
  const [SpecificationErr2, setSpecificationErr2] = useState(false);
  const [SpecificationErr3, setSpecificationErr3] = useState(false);
  const [SpecificationErr4, setSpecificationErr4] = useState(false);
  const [CheckErr, setCheckErr] = useState(false);
  const [AssetsCodeErr, setAssetsCodeErr] = useState(false);
  const [valueErr, setValueErr] = useState(false);
  const [perValueErr, setPerValueErr] = useState(false);
  const [quantityErr, setQuantityErr] = useState(false);

  const [MaterialMasFormDetails, setMaterialMasFormDetails] = useState({
    group: "",
    uom: "",
    parent_name: "",
    level: "",
  });

  const submitHandler = () => {
    console.log("coming to function");
    if (attributeBoxShow) {
      console.log("fonint to first if");
      if (
        AssetsGroup?.length == 0 ||
        UOM?.length == 0 ||
        // ParentName?.length == 0 ||
        AssetsType?.length == 0 ||
        AssetsName?.length == 0 ||
        AssetsCode?.length == 0 ||
        Make?.length == 0 ||
        Color?.length == 0 ||
        Modal?.length == 0 ||
        Type?.length == 0 ||
        Specification1?.length == 0 ||
        Specification2?.length == 0 ||
        Specification3?.length == 0 ||
        Specification4?.length == 0 ||
        checked?.length == 0
      ) {
        setAssetsGroupErr(true);
        setUOMErr(true);
        // setParentNameErr(true);
        setAssetsTypeErr(true);
        setAssetsNameErr(true);
        setAssetsCodeErr(true);
        setMakeErr(true);
        setColorErr(true);
        setModalErr(true);
        setTypeErr(true);
        setCheckErr(true);
        setSpecificationErr1(true);
        setSpecificationErr2(true);
        setSpecificationErr3(true);
        setSpecificationErr4(true);
      } else {
        let formdata = new FormData();
        formdata.append("type", 1);
        formdata.append("name", AssetsName);
        formdata.append("code", AssetsCode);
        formdata.append("material_type", AssetsType);
        formdata.append("uom", UOM);
        formdata.append("group", AssetsGroup);
        formdata.append("is_child", 0);
        formdata.append("attribute", 1);
        formdata.append("make", Make);
        formdata.append("color", Color);
        formdata.append("model", Modal);
        formdata.append("attribute_type", Type);
        formdata.append("specification1", Specification1);
        formdata.append("specification2", Specification2);
        formdata.append("specification3", Specification3);
        formdata.append("specification4", Specification4);
        if (matId) {
          formdata.append("parent_id", matId);
        }

        if (AssetsType?.toLowerCase().startsWith("quan")) {
          formdata.append("quantity", quantity);
          formdata.append("total_value", value);
        }
        if (AssetsType?.toLowerCase().startsWith("item")) {
          formdata.append("quantity", quantity);
          formdata.append("per_asset_value", perValue);
          formdata.append("total_value", value);
        }

        api.material_assests_add(formdata).then((res) => {
          if (res?.status == "success") {
            alert("The Assets Added Successfully");
            navigate("/procurement/assets-list");
          } else {
            alert(res?.message);
          }
        });
      }
    } else {
      console.log("coming to first else");
      if (
        AssetsGroup?.length == 0 ||
        UOM?.length == 0 ||
        // ParentName?.length == 0 ||
        AssetsType?.length == 0 ||
        AssetsName?.length == 0 ||
        AssetsCode?.length == 0 ||
        checked?.length == 0
      ) {
        setAssetsGroupErr(true);
        setUOMErr(true);
        // setParentNameErr(true);
        setAssetsTypeErr(true);
        setAssetsNameErr(true);
        setAssetsCodeErr(true);
      } else {
        let formdata = new FormData();
        formdata.append("type", +1);
        formdata.append("name", AssetsName);
        formdata.append("code", AssetsCode);
        formdata.append("material_type", AssetsType);
        formdata.append("uom", UOM);
        formdata.append("group", AssetsGroup);
        if (matId) {
          formdata.append("parent_id", matId);
        }

        formdata.append("is_child", +0);
        formdata.append("attribute", +0);

        if (AssetsType?.toLowerCase().startsWith("quan")) {
          formdata.append("quantity", quantity);
          formdata.append("total_value", value);
        }
        if (AssetsType?.toLowerCase().startsWith("item")) {
          formdata.append("quantity", quantity);
          formdata.append("per_asset_value", perValue);
          formdata.append("total_value", value);
        }
        api.material_assests_add(formdata).then((res) => {
          if (res?.status == "success") {
            alert("The Assets Added Successfully");
            navigate("/procurement/assets-list");
          } else {
            alert(res?.message);
          }
        });
      }
    }
  };

  useEffect(() => {
    api.material_assests_search("?type=1").then((res) => {
      if (res?.status === "success") {
        setMaterialLevelDetails({
          parent_material: res?.parent_suggestions,
          child_material: res?.other_level_sugestions,
        });
      }
    });
  }, []);

  // console.log("matId", matId);

  useEffect(() => {
    if (matId) {
      api.material_show(matId).then((res) => {
        setMaterialMasFormDetails({
          ...MaterialMasFormDetails,
          parent_name: res?.material?.name ?? "",
        });
      });
    }
  }, [matId]);

  const uploadFile = () => {
    let data = {
      import_excel: upload,
    };
    if (upload) {
      api.assetsImport(data).then((res) => {
        alert(res?.message);
        setUpload("");
      });
    } else {
      alert("Please Upload File");
    }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">Assets Master</h4>
      <Col xs={12} className="inputinner-box text-start">
        <div className="d-flex justify-content-end my-3">
          <div class="input-group  w-50">
            <a
              href={`${BASE_FILE_URL}excel/cresscon-asset-sample.xlsx`}
              target="_blank"
              rel="noreferrer"
              className="bg-primar border-0 rounded-1 text-white f-1 me-auto fs-14 px-4 py-2 pointer"
            >
              Sample Download
            </a>
          </div>
          <div class="input-group  w-50">
            <input
              type="file"
              class="form-control border-0 bg-lightBlue w-90"
              placeholder="Recipient's username"
              aria-label="Recipient's username"
              onChange={(e) => setUpload(e.target.files[0])}
              aria-describedby="button-addon2"
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
              onClick={uploadFile}
            >
              Upload
            </button>
          </div>
        </div>
      </Col>
      {/* <Col xs={12} className="inputinner-box text-start">
        <div className="d-flex justify-content-end my-3">
          <a
            href="http://erp-script.cresconprojects.com/public/excel/vendor-sample-cresscon.xlsx"
            target="_blank"
            rel="noreferrer"
            className="bg-primar border-0 rounded-1 text-white f-1 me-auto fs-14 px-4 py-2 pointer">
            Sample Download
          </a>
        </div>
      </Col> */}
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Assets Name</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Assets Name"
            onChange={(e) => setAssetsName(e.target.value)}
            value={AssetsName}
          />
          {AssetsName?.length == 0 && AssetsNameErr && (
            <p className="err">enter assets name</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Assets Group</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Assets Group"
            onChange={(e) => setAssetsGroup(e.target.value)}
            value={AssetsGroup}
          />
          {AssetsGroup?.length == 0 && AssetsGroupErr && (
            <p className="err">enter assets group</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">UOM</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter UOM"
            onChange={(e) => setUOM(e.target.value)}
            value={UOM}
          />
          {UOM?.length == 0 && UOMErr && <p className="err">enter UOM</p>}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Assets Type</label>
          {/* <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Material Type"
            onChange={(e) => setAssetsType(e.target.value)}
            value={AssetsType}
          /> */}
          <select
            className="input-select bg-lightBlue ms-0"
            style={{
              border: "2px solid #f1f7ff",
              width: "90%",
              marginRight: "auto",
            }}
            onChange={(e) => setAssetsType(e.target.value)}
            value={AssetsType}
          >
            <option value={""}>select assets type</option>
            <option value={"QUANTITY"}>Quantity</option>
            <option value={"ITEM BASE"}>Item Base</option>
          </select>
          {AssetsType?.length == 0 && AssetsTypeErr && (
            <p className="err">enter assets type</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Assets Code</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Assets Code"
            onChange={(e) => setAssetsCode(e.target.value)}
            value={AssetsCode}
          />
          {AssetsCode?.length == 0 && AssetsCodeErr && (
            <p className="err">enter assets code</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Parent Name</label>
          {/* <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Parent Name"
            onChange={(e) => setParentName(e.target.value)}
            value={ParentName}
          /> */}
          <p
            style={{ minHeight: "2rem", width: "90%", marginRight: "auto" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            {MaterialMasFormDetails.parent_name}
          </p>

          {ParentName?.length == 0 && ParentNameErr && (
            <p className="err">enter parent name</p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="d-flex">
          <Col sm={6}>
            <Col xs={12} className="inputinner-box text-start">
              {/* <label className="w-100">Last level</label> */}
              <div className="d-flex mt-2">
                <button
                  className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3"
                  onClick={() => checkClickHandler("level")}
                >
                  <div className="checkbox me-1">
                    {checked === "level" && (
                      <img src={tickIcon} alt="tick-icon" />
                    )}
                  </div>
                </button>
                Last level
              </div>
            </Col>
            <Col xs={12} md={12} className="inputinner-box text-start">
              {/* <label className="w-100">Last Parent</label> */}
              <div className="d-flex mt-2">
                <button
                  className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3"
                  onClick={() => checkClickHandler("parent")}
                >
                  <div className="checkbox me-1">
                    {checked === "parent" && <img src={tickIcon} />}
                  </div>
                </button>
                Last Parent
              </div>
            </Col>
          </Col>
          <Col sm={6}>
            <Col xs={12} md={12} className="inputinner-box text-start">
              {/* <label className="w-100">Attributes</label> */}
              <div className="d-flex mt-2">
                <button
                  className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3"
                  onClick={() => setAttributeBoxShow(!attributeBoxShow)}
                >
                  <div className="checkbox me-1">
                    {attributeBoxShow && (
                      <img src={tickIcon} alt="check-icon" />
                    )}
                  </div>
                </button>
                Attributes
              </div>
            </Col>
          </Col>
          {checked?.length == 0 && CheckErr && (
            <p className="err">select checkbox</p>
          )}
        </Col>
        {AssetsType?.toLowerCase().startsWith("item") && (
          <>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Quantity</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Quantity"
                onChange={(e) => setQuantity(e.target.value)}
                value={quantity}
              />
              {quantity?.length == 0 && quantityErr && (
                <p className="err">enter quantity</p>
              )}
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Per Value</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Per Value"
                onChange={(e) => setPerValue(e.target.value)}
                value={perValue}
              />
              {perValue?.length == 0 && perValueErr && (
                <p className="err">enter per value</p>
              )}
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Value</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Value"
                onChange={(e) => setValue(e.target.value)}
                value={value}
              />
              {value?.length == 0 && valueErr && (
                <p className="err">enter value</p>
              )}
            </Col>
          </>
        )}

        {AssetsType?.toLowerCase().startsWith("quan") && (
          <>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Quantity</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Quantity"
                onChange={(e) => setQuantity(e.target.value)}
                value={quantity}
              />
              {quantity?.length == 0 && quantityErr && (
                <p className="err">enter quantity</p>
              )}
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Value</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Value"
                onChange={(e) => setValue(e.target.value)}
                value={value}
              />
              {value?.length == 0 && valueErr && (
                <p className="err">enter value</p>
              )}
            </Col>
          </>
        )}

        {!attributeBoxShow && (
          <Col sm={12} className="inputinner-box text-start">
            <button
              className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
              onClick={submitHandler}
            >
              Save
            </button>
            <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3">
              cancel
            </button>
          </Col>
        )}

        {/* <Col xs={12} md={6} className="inputinner-box text-start">
          <label className="w-100">Attributes</label>
          <div className="d-flex mt-2">
            {mmCheck.map((item, ind) => {
              return (
                <button
                  className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3"
                  key={ind}
                >
                  <div className="checkbox me-1">
                    <img src={tickIcon} />
                  </div>
                  {item?.name} application
                </button>
              );
            })}
          </div>
        </Col> */}
      </Col>
      {attributeBoxShow && (
        <>
          <h4 className="text-start my-1 py-1 w-100">Attribute</h4>

          <Col className="d-flex flex-wrap">
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Make</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Make"
                onChange={(e) => setMake(e.target.value)}
                value={Make}
              />
              {Make?.length == 0 && MakeErr && (
                <p className="err">enter make</p>
              )}
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Color</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Color"
                onChange={(e) => setColor(e.target.value)}
                value={Color}
              />
              {Color?.length == 0 && ColorErr && (
                <p className="err">enter color</p>
              )}
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Modal</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Modal"
                onChange={(e) => setModal(e.target.value)}
                value={Modal}
              />
              {Modal?.length == 0 && ModalErr && (
                <p className="err">enter modal</p>
              )}
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Type</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Type"
                onChange={(e) => setType(e.target.value)}
                value={Type}
              />
              {Type?.length == 0 && TypeErr && (
                <p className="err">enter type</p>
              )}
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Specification 1</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Specification 1"
                onChange={(e) => setSpecification1(e.target.value)}
                value={Specification1}
              />
              {Specification1?.length == 0 && SpecificationErr1 && (
                <p className="err">enter specification 1</p>
              )}
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Specification 2</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Specification 2"
                onChange={(e) => setSpecification2(e.target.value)}
                value={Specification2}
              />
              {Specification2?.length == 0 && SpecificationErr2 && (
                <p className="err">enter specification 2</p>
              )}
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Specification 3</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Specification 3"
                onChange={(e) => setSpecification3(e.target.value)}
                value={Specification3}
              />
              {Specification3?.length == 0 && SpecificationErr3 && (
                <p className="err">enter specification 3</p>
              )}
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Specification 4</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Specification 4"
                onChange={(e) => setSpecification4(e.target.value)}
                value={Specification4}
              />
              {Specification4?.length == 0 && SpecificationErr4 && (
                <p className="err">enter specification 4</p>
              )}
            </Col>

            <Col sm={12} className="inputinner-box text-end ">
              <button
                className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
                onClick={submitHandler}
              >
                Save
              </button>
              <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3">
                cancel
              </button>
            </Col>
          </Col>
        </>
      )}
      <AssetsSearch
        setMatId={setMatId}
        materialLevelDetails={materialLevelDetails}
      />
    </Col>
  );
};

export default AssetsMasterForm;
