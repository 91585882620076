// @ts-nocheck
import React, { useState } from "react";
import { api } from "../../constants/Services";
import { Col } from "react-bootstrap";
import { tickIcon } from "../../assets/img";

const BoqTable = ({
  boq_details,
  currentPage,
  setCurrentPage,
  setCurrentTable,
  setCurrentTableDetails,
  currentTableDetails,
  pagination,
  projectID,
}) => {
  const [checkedBoq, setCheckedBoq] = useState([]);
  console.log("check", checkedBoq);
  console.log("🚀 ~ file: MrBoqTable.js:15 ~ checkedBoq:", checkedBoq);

  const boqSelctHandler = (boq_id) => {
    if (checkedBoq.includes(boq_id)) {
      let filter_list = checkedBoq.filter((checked) => boq_id !== +checked);
      setCheckedBoq(filter_list);
    } else {
      setCheckedBoq((checkedBoq) => [...checkedBoq, +boq_id]);
    }
  };

  const checkedSubmitHandler = () => {
    let formData = new FormData();

    formData.append("project_id", projectID);
    checkedBoq?.map((id, ind) => {
      formData.append(`boq_ids[${ind}]`, id);
    });

    api.material_request_boq_checked(formData).then((res) => {
      if (res?.status === "success") {
        console.log("checked_boq", res?.boq_lists);
        let temp = res?.boq_lists;
        let renderedList = temp?.map((item) => ({
          ...item,
          required_quantity: "",
          remarks: "",
          error: 0,
        }));
        setCurrentTableDetails(renderedList);
        setCurrentTable("final");
      }
    });
  };
  return (
    <Col className="emply-master-form py-2  mt-3 text-center">
      <div style={{ overflowX: "scroll" }} className="boq-table bg-white">
        <table className="table">
          <thead>
            <tr>
              <th> Sl.No</th>
              <th>Select Boq</th>
              <th>BOQ Code</th>
              <th style={{minWidth:"500px"}}>BOQ Description Unit</th>
              <th>UOM</th>
              <th>Quantity</th>
              <th>Supply Rate </th>
              <th>Installation Rate </th>
              <th>B.Material-1</th>
              <th>Accessories-1</th>
              <th>Fitting </th>
              <th>Support</th>
              <th>Accessories-2</th>
              <th>Insulation</th>
              <th>MISC</th>
              <th>Basic Rate</th>
              <th>Accessories Rate</th>
              <th>Transport Charges</th>
              <th>Cont.Rate</th>
              <th>Financial Rate</th>
              <th>GST Supp %</th>
              <th>GST Incl %</th>
            </tr>
          </thead>
          <tbody>
            {boq_details?.map((item, ind) => {
              return (
                <tr key={item?.id}>
                  <td>{ind + 1}</td>
                  <td>
                    <div
                      className="checkbox pointer"
                      onClick={() => boqSelctHandler(item?.id)}
                    >
                      {checkedBoq.includes(item?.id) && (
                        <img src={tickIcon} alt="check" />
                      )}
                    </div>
                  </td>
                  <td>{item?.boq_code}</td>
                  <td style={{minWidth:"500px" , textAlign:"start"}}>{item?.boq_description}</td>
                  <td>{item?.uom}</td>
                  <td>{item?.qty}</td>
                  <td>{item?.supply_rate}</td>
                  <td>{item?.installation_rate}</td>
                  <td>{item?.b_material_1}</td>
                  <td>{item?.accessories_1}</td>
                  <td>{item?.fitting}</td>
                  <td>{item?.support}</td>
                  <td>{item?.accessories_2}</td>
                  <td>{item?.insulation}</td>
                  <td>{item?.misc}</td>
                  <td>{item?.basic_rate}</td>
                  <td>{item?.accessories_rate}</td>
                  <td>{item?.transport_charges}</td>
                  <td>{item?.cont_rate}</td>
                  <td>{item?.financial_rate}</td>
                  <td>{item?.gst_supply}</td>
                  <td>{item?.gst_inclu}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Col xs={12} sm={12} className="inputinner-box d-flex gap-3 text-start">
          <button
            className={
              +currentPage == 1
                ? "bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2 opacity-75"
                : "bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
            }
            onClick={() => setCurrentPage(+currentPage - 1)}
            disabled={+currentPage == 1 ? true : false}
          >
            Previous
          </button>
          <button
            className={
              +pagination?.last_page == +currentPage
                ? "bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2 opacity-75"
                : "bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
            }
            onClick={() => setCurrentPage(+currentPage + 1)}
            disabled={+pagination?.last_page == +currentPage ? true : false}
          >
            Next
          </button>
        </Col>
        <Col xs={12} sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
            onClick={checkedSubmitHandler}
          >
            Submit
          </button>
        </Col>
      </div>
    </Col>
  );
};

export default BoqTable;
