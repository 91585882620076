import React from "react";
import { Col } from "react-bootstrap";

const LabourDocument = () => {
  return (
    <Col className="emply-master-form px-3 py-2 d-flex justify-content-center flex-column py-md-4 bg-white mt-0 text-center">
      <h4 className="w-100 my-1 py-1">Document</h4>
      <Col
        xs={12}
        sm={6}
        xl={6}
        xxl={5}
        className="inputinner-box my-3 mx-auto text-start"
      >
        <div className="d-sm-flex  w-90 ms-0 emply-mas-doc-box accordion-body bg-lightBlue">
          <input type="file" className="border-0 px-1" />
        </div>
      </Col>
    </Col>
  );
};

export default LabourDocument;
