import React, { useState, useEffect } from "react";
import eye_icon from "../../assets/icons/common/Eye.svg";
import { AiOutlineFilePdf } from "react-icons/ai";
import { Col, Modal, Button } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../component/Header/Header";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { api } from "../../constants/Services";

function InvoiceBookingList() {
  const [selectedProject, setSelectedProject] = useState("");
  const [page, setPage] = useState(1);
  const [IBList, setIBList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const getProjects = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  const getList = () => {
    if (selectedProject?.length > 0) {
      let obj = {
        project_id: selectedProject,
      };
      let param = `?page=${page}`;
      api
        .invoiceList(param, obj)
        .then((res) => {
          // console.log("invoiceList", res);
          setIBList(res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      let param = `?page=${page}`;
      api
        .invoiceList(param)
        .then((res) => {
          // console.log("invoiceList", res);
          setIBList(res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (searchParams?.get("projectID")) {
      setSelectedProject(searchParams?.get("projectID"));
      getList(searchParams?.get("projectID"), 1);
    }
  }, [searchParams?.get("projectID")]);

  const getInvoice = (id, page) => {
    let obj = {
      project_id: id,
    };
    let param = `?page=${page}`;
    api
      .invoiceList(param, obj)
      .then((res) => {
        // console.log("invoiceList", res);
        setIBList(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  // useEffect(() => {
  //   getList();
  // }, [selectedProject, page]);

  const materialTransferDetailHandler = (sel_mt, mt_colde, mt_date) => {
    navigate("/ism/grn-output", {
      state: { id: sel_mt, type: "invoice" },
    });
  };

  const documentViewr = (item) => {
    setShowModal(true);
    setDocumentUrl(item?.img_url);
  };

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white my-3 text-center">
        <header>
          <Col xs={12} className="d-flex my-2">
            <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 ms-auto pointer"
              value={selectedProject}
              onChange={(e) => {
                setSelectedProject(e.target.value);
                setPage(1);
                getInvoice(e.target.value, 1);
              }}
            >
              <option value={""}>Select Project</option>
              {projectList?.map((project) => (
                <option key={project?.id} value={project?.id}>
                  {project?.name}
                </option>
              ))}
            </select>
          </Col>
        </header>
        <h4 className="my-1 py-1">Invoice Booking List</h4>
        <div
          className="user-mange-table over overflow-scroll"
          style={{ overflowX: "scroll" }}
        >
          <table>
            <thead>
              <tr className="">
                <th className="text-nowrap">S.No</th>
                {/*<th className="text-nowrap">Project Name</th> */}
                <th className="text-nowrap">Vendor Name</th>
                <th className="text-nowrap">PO Number</th>
                {/* <th className="text-nowrap">PO Date </th> */}
                <th className="text-nowrap">GRN No</th>
                <th className="text-nowrap">GRN Date</th>
                <th className="text-nowrap">Invoice No</th>
                <th className="text-nowrap">Invoice Date</th>
                {/*<th className="text-nowrap">Procure Type</th>  */}
                <th className="text-nowrap">Created By</th>
                <th className="text-nowrap text-center">Type</th>
                <th className="text-nowrap">Status</th>
                <th className="text-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              {IBList?.grns?.map((material, ind) => {
                return (
                  <tr className="text-center">
                    <td style={{ fontSize: "12px" }}>{ind + 1}</td>
                    <td style={{ fontSize: "12px", textAlign: "start" }}>
                      {" "}
                      {material?.vendor?.company_name
                        ? material?.vendor?.company_name
                        : material?.vendor?.name}
                    </td>
                    <td style={{ fontSize: "12px" }}>
                      {material?.grn?.purchase_order?.po_no
                        ? material?.grn?.purchase_order?.po_no
                        : "-"}
                    </td>
                    {/* <td style={{fontSize:"12px"}}>
                      {material?.grn?.purchase_order?.po_date
                        ? material?.grn?.purchase_order?.po_date
                        : "-"}
                    </td> */}
                    <td style={{ fontSize: "12px" }}>
                      {material?.grn?.grn_no}
                    </td>
                    <td className="text-nowrap" style={{ fontSize: "12px" }}>
                      {material?.grn?.grn_date}
                    </td>
                    <td style={{ fontSize: "12px" }}>{material?.invoice_no}</td>
                    <td className="text-nowrap" style={{ fontSize: "12px" }}>
                      {material?.invoice_date}
                    </td>
                    <td className="text-nowrap" style={{ fontSize: "12px" }}>
                      {material?.employee?.name}
                    </td>
                    <td style={{ fontSize: "12px" }}>
                      {material?.grn?.type == 2 ? "Cash Purchase" : "Po"}
                    </td>
                    <td style={{ fontSize: "12px" }}>
                      {material?.status == 1
                        ? "Pending"
                        : material?.status == 2
                        ? "Accepted"
                        : "Rejected"}
                    </td>
                    <td style={{ fontSize: "12px" }}>
                      <div className="d-flex">
                        <AiOutlineFilePdf
                          className="pointer fs-5"
                          onClick={() => documentViewr(material)}
                        />
                        <Link
                          to={`/ism/grn-output?id=${
                            material?.id
                          }&projectID=${+selectedProject}&type=invoice`}
                        >
                          <img
                            onClick={() =>
                              materialTransferDetailHandler(material?.id)
                            }
                            style={{ width: "20px" }}
                            className="pointer ms-2"
                            src={eye_icon}
                            alt="eye-icon"
                          />
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="my-1 py-1 d-flex justify-content-end">
            <PaginationControl
              page={page}
              between={4}
              total={IBList?.pagination?.total}
              limit={10}
              changePage={(page) => {
                setPage(page);
                getInvoice(selectedProject, page);
                console.log(page);
              }}
              ellipsis={1}
            />
          </div>
        </div>
      </Col>

      <Modal
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        dialogClassName="modal-90w"
        centered
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton className="border-0 d-flex">
          <Modal.Title className="text-center mx-auto">
            {" "}
            Invoice Booking PDF
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ width: "100%", textAlign: "center" }}>
            <iframe
              src={documentUrl}
              title="Sample Pdf"
              className="w-100"
              style={{ height: "500px" }}
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={() => setShowModal(!showModal)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
}

export default InvoiceBookingList;
