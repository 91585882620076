import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import Header from "../../component/Header/Header";
import MaterialReturnTable from "../../component/inventory/MaterialRurn/MaterialReturnTable";
import MaterialRurnForm from "../../component/inventory/MaterialRurn/MaterialRurnForm";
import MaterialInstruction from "../../component/inventory/MaterialRurn/MatrialInstruction";
import { api } from "../../constants/Services";
import { useNavigate } from "react-router";
import useUser from "../../constants/Storage/userData";
import StatusModal from "../../component/StatusModal";

const IsmMaterialReturnScreen = () => {
  const [podate, setPoDate] = useState();
  const [showStatus, setShowStatus] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [company_id, setCompany_id] = useState("");
  const [company_idErr, setCompany_idErr] = useState(false);
  const navigate = useNavigate();
  const [projectList, setProjectList] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [POList, setPOList] = useState([]);
  const [vendorName, setVendorName] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [grn, setGrn] = useState("");
  const [grnList, setGrnList] = useState([]);
  const [grnDetailsList, setGrnDetailsList] = useState([]);
  const [grnDate, setGrnDate] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [poNo, setPoNo] = useState("");
  const [poNoErr, setPoNoErr] = useState(false);
  const [projectNameErr, setProjectNameErr] = useState(false);
  const [returnDeteErr, setReturnDeteErr] = useState(false);
  const [returnNoErr, setReturnNoErr] = useState(false);
  const [grnErr, setGrnErr] = useState(false);
  const [returnDete, setReturnDete] = useState("");
  const [returnNo, setReturnNo] = useState("");
  const [listWarehouse, setListWarehouse] = useState([]);
  const [listWarehouseCheck, setListWarehouseCheck] = useState([]);
  const [storeIndex, setStoreIndex] = useState(null);
  const [stockValue, setStockValue] = useState({});
  const [projectType, setProjectType] = useState("");
  const [dateError, setDateError] = useState([]);

  const { user } = useUser();
  const [tableStaticData, setTableStaticTable] = useState({
    rejected_qty: {},
    remarks: {},
    net_value: {},
  });
  const [tableSel, setTableSel] = useState([]);

  const [modal, setModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const poPrintHandler = () => {
    setModal(!modal);
  };

  const statusModalClose = () => {
    setShowStatus(!showStatus);
  };

  const getProject = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };

  const projectSelectionHandler = (e) => {
    let projec_id = +e.target.value;
    getPurchaseOrderList(projec_id);
    setProjectName(projec_id);
    setGrnDetailsList([]);
  };

  const getPurchaseOrderList = (selectedProject) => {
    setProjectName(+selectedProject);
    let data = {
      project_id: +selectedProject,
    };
    // api.purchaseOrderList(data).then((res) => {
    api.grnPoLists(data).then((res) => {
      if (res?.status == "success") {
        setPOList(res?.purchase_orders);
        setVendorName("");
        setVendorId("");
      }
    });
  };

  useEffect(() => {
    getProject();
  }, []);

  const getGrn = (id) => {
    let obj = {
      project_id: projectName,
      purchase_order_id: id,
    };
    api
      .matertialReturnGrnList(obj)
      .then((res) => {
        setGrnList(res?.project_grns);
        // console.log("matertialReturnGrnList", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const poSelector = (id) => {
    getGrn(id);
    let finder = POList?.find((i) => i?.purchase_order?.id == id);
    // console.log("finder", finder);
    setPoDate(finder?.purchase_order?.po_date);
    setPoNo(id);
    setGrn("");
    setVendorName(finder?.purchase_order?.vendor?.company_name);
    setVendorId(finder?.purchase_order?.vendor_id);
    setStockValue({});
    setStoreIndex(null);
    setListWarehouse([]);

    // 10-04-2024
    api
      .purchaseOrderView(id)
      .then((res) => {
        if (res?.status == "success") {
          let temp = [];
          res?.purchase_orders?.purchase_order_details?.map((item, ind) => {
            temp.push({
              ...item,
              net_value_no: item?.net_value,
              per_net_value: +item?.net_value / +item?.qty,
            });
          });
          setGrnDetailsList(temp);
          // setDetails([]);
        }
        // console.log("po-details", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  // console.log("po",podate)
  const selectGrn = (id) => {
    setGrn(id);
    let finder = grnList?.find((i) => i?.id == id);
    // console.log("finderGrn", finder);
    setGrnDate(finder?.grn_date);
    // setWarehouse(finder?.ware_house_id);
    let temp = [];
    finder.grn_detail?.map((item, ind) => {
      temp.push({
        ...item,
        purchase_order: finder?.purchase_order,
        purchase_order_net_value:
          finder?.purchase_order?.purchase_order_details?.[0]?.net_value,
        po_order_net_value:
          finder?.purchase_order?.purchase_order_details?.[0]?.net_value,
      });
    });
    // console.log("temptemp", temp);
    // setGrnDetailsList(temp);
  };

  const getMatrialStock = (project_id, material_id, ind, id) => {
    let payload = {
      project_id: project_id,
      material_id: material_id,
    };
    api
      .getProMaterialStock(payload)
      .then((res) => {
        let temp = [];
        res?.stocks?.map((item, ind) => {
          temp.push({
            ...item,
            stock: "",
            error: 0,
            po_no: +id,
          });
        });
        setStoreIndex(ind);
        setListWarehouse(temp);
        setModalShow(true);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleChanges = (event, ind, available_stock, id, item) => {
    let temp = [...listWarehouse];
    let obj = temp[ind];
    if (+event > +available_stock) {
      let final = {
        ...obj,
        stock: event,
        error: 1,
      };
      temp[ind] = final;
    } else {
      let final = {
        ...obj,
        stock: event,
        error: 0,
      };

      temp[ind] = final;
    }
    setListWarehouse(temp);
    let stocks = { ...stockValue };
    let getStock = stocks[item?.po_no];
    let final_value = {
      ...getStock,
      [id]: event,
    };
    setStockValue((val) => ({ ...val, [item?.po_no]: final_value }));
    // console.log("item", item);
  };

  // console.log("listWarehouse", listWarehouse);

  const submitStock = () => {
    let temp = [...grnDetailsList];
    let checker = [];
    let finder = 1;
    let ids = [...tableSel];

    listWarehouse?.map((item) => {
      if (listWarehouseCheck?.includes(item?.id)) {
        checker.push(item);
      }
      if (item?.error == 1) {
        finder = finder + 1;
      }
    });

    let totalStocks = checker?.reduce(
      (accumulator, currentValue) => +accumulator + +currentValue?.stock,
      0
    );

    temp[storeIndex] = {
      ...temp[storeIndex],
      received_qtys: totalStocks,
      net_value: Number(
        +totalStocks * +temp[storeIndex]?.per_net_value
      ).toFixed(2),
    };

    if (finder == 1) {
      if (!ids.includes(temp[storeIndex]?.id) && checker?.length > 0) {
        ids.push(temp[storeIndex]?.id);
      }
      setGrnDetailsList(temp);
      setTableSel(ids);
      setModalShow(false);
    }
  };

  const handleClick = (id) => {
    let temp = [...listWarehouseCheck];
    let indx = temp.indexOf(id);

    if (temp.includes(id)) {
      temp.splice(indx, 1);
      setStockValue((val) => ({ ...val, [id]: "" }));
    } else {
      temp.push(id);
    }

    setListWarehouseCheck(temp);
  };

  // console.log("tableSel", tableSel);

  // console.log("grnDetailsList", grnDetailsList);
  // useEffect(() => {
  //   if (purchaseOrderDetails?.po_table?.length > 0) {
  //     purchaseOrderDetails?.po_table?.map((poDetail, ind) => {
  //       setPoTableStaticFields({
  //         ...poTableStaticFields,
  //         basic_rate: {
  //           ...poTableStaticFields?.basic_rate,
  //           [poDetail?.id]: poDetail?.basic_price,
  //         },
  //         remarks: {
  //           ...poTableStaticFields?.remarks,
  //           [poDetail?.id]: poDetail?.remarks,
  //         },
  //       });
  //     });
  //   }
  // }, [purchaseOrderDetails]);

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <MaterialRurnForm
        podate={podate}
        setPoDate={setPoDate}
        setDateError={setDateError}
        dateError={dateError}
        projectType={projectType}
        setProjectType={setProjectType}
        POList={POList}
        projectList={projectList}
        getPurchaseOrderList={getPurchaseOrderList}
        vendorName={vendorName}
        poSelector={poSelector}
        grn={grn}
        setGrn={setGrn}
        grnList={grnList}
        selectGrn={selectGrn}
        grnDate={grnDate}
        warehouse={warehouse}
        projectSelectionHandler={projectSelectionHandler}
        projectNameErr={projectNameErr}
        poNoErr={poNoErr}
        poNo={poNo}
        grnErr={grnErr}
        returnDeteErr={returnDeteErr}
        returnNoErr={returnNoErr}
        returnDete={returnDete}
        returnNo={returnNo}
        setReturnDate={setReturnDete}
        setReturnNo={setReturnNo}
        projectName={projectName}
        setWarehouse={setWarehouse}
        company_id={company_id}
        setCompany_id={setCompany_id}
        company_idErr={company_idErr}
      />
      <MaterialReturnTable
        getMatrialStock={getMatrialStock}
        projectName={projectName}
        setTableSel={setTableSel}
        tableSel={tableSel}
        POList={POList}
        tableStaticData={tableStaticData}
        setTableStaticTable={setTableStaticTable}
        grnDetailsList={grnDetailsList}
        setGrnDetailsList={setGrnDetailsList}
      />
      <MaterialInstruction
        setModal={setModal}
        setPoNoErr={setPoNoErr}
        setProjectNameErr={setProjectNameErr}
        setGrnErr={setGrnErr}
        setReturnNoErr={setReturnNoErr}
        setReturnDeteErr={setReturnDeteErr}
        grn={grn}
        projectName={projectName}
        grnDate={grnDate}
        warehouse={warehouse}
        returnNo={returnNo}
        returnDete={returnDete}
        vendorName={vendorName}
        poNo={poNo}
        vendorId={vendorId}
        tableSel={tableSel}
        setShowStatus={setShowStatus}
        setStatusMessage={setStatusMessage}
        grnDetailsList={grnDetailsList}
        stockValue={stockValue}
        company_id={company_id}
        setCompany_idErr={setCompany_idErr}
      />

      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
          statusMessage={statusMessage}
        />
      )}

      <Modal show={modal} centered onHide={() => setModal(!modal)}>
        <Modal.Header closeButton className="border-0">
          <label className="w-50 mx-auto my-1 py-1">Material Return : </label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <p className="text-center fs-6">Do you want to Print the Output?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            onClick={poPrintHandler}
            variant="primary"
          >
            Print
          </Button>
          <Button variant="secondary" onClick={() => setModal(!modal)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalShow} centered onHide={() => setModalShow(!modalShow)}>
        <Modal.Header closeButton className="border-0">
          <label className="w-100 mx-auto my-1 py-1">Material Stocks</label>
        </Modal.Header>
        <Modal.Body className="border-0">
          <div
            className=""
            style={{ width: "100%", height: "500px", overflow: "auto" }}
          >
            {listWarehouse?.map((item, ind) => {
              return (
                <div className="mx-3" key={ind}>
                  <div className="d-flex gap-2 justify-content-between">
                    <div className="d-flex gap-2">
                      <div>
                        <input
                          type={"checkbox"}
                          className="mt-3"
                          onClick={() => handleClick(item?.id)}
                          checked={
                            listWarehouseCheck?.includes(item?.id)
                              ? true
                              : false
                          }
                        />
                      </div>
                      <div className="mx-3">
                        <label>{item?.warehouse?.name}</label>
                        <p>Available Stock : {item?.available_stock}</p>
                      </div>
                    </div>
                    <div>
                      <input
                        className="mt-2"
                        type={"number"}
                        value={stockValue?.[item?.po_no]?.[item?.id]}
                        onChange={(e) =>
                          handleChanges(
                            e.target.value,
                            ind,
                            item?.available_stock,
                            item?.id,
                            item
                          )
                        }
                        disabled={
                          listWarehouseCheck?.includes(item?.id) ? false : true
                        }
                      />
                      {+item.error == 1 && (
                        <p className="err" style={{ fontSize: "10px" }}>
                          {" "}
                          Exceeded The Quantity
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={() => setModalShow(!modalShow)}>
            Cancel
          </Button>
          <Button
            className="btn btn-primary"
            onClick={submitStock}
            variant="primary"
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default IsmMaterialReturnScreen;
