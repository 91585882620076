import React from "react";
import { Col } from "react-bootstrap";

const LbMasPersonalDetails = () => {
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-0 text-center">
      <h4 className="w-95 ms-xl-0">Personal Details</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Father Name</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Father Name"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Mother Name </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Mother Name"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Spouse Name </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Spouse Name"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label>Material Status</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Material Status"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Blood Group<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Blood Group"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Emergency Contact Person<span className="err">*</span>
          </label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Emergency Contact Person"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Emergency Phone No<span className="err">*</span>
          </label>
          <input
            type="file"
            className="custom-file-input border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Emergency Phone No"
          />
        </Col>
        <Col sm={12} className="inputinner-box text-start">
          <button className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
            Submit
          </button>
          <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3">
            cancel
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default LbMasPersonalDetails;
