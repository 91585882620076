// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import {
  addressIcon,
  bankIcon,
  currencyIcon,
  personalIcon,
  qualifWorkIcon,
  uploadIcon,
} from "../../assets/img";
import AddressTab from "./AddressTab";
import BankDetails from "./BankDetails";
import PersonalDetails from "./PersonalDetails";
import QualifWork from "./QualifWork";
import SalaryEpf from "./SalaryEpf";
import EmDocument from "./DocumentTab";

const EmployeeMasterTab = ({ employeeDetail, setReset, reset }) => {
  const [tab, setTab] = useState("address");
  const [allowAccess, setAllowAccess] = useState([]);
  const [tempFormData, setTempFormData] = useState({});

  // console.log("submitting-form-data", tempFormData);

  const tabonClickHandler = (e) => {
    // console.log("allowAccess", allowAccess);
    if (allowAccess.includes(e)) {
      // console.log("e", e);
      setTab(e);
    }
    //
  };

  return (
    <>
      <Col className="emply-master-tab px-3 py-2 mt-1 text-center">
        <Col className="d-flex flex-wrap">
          <button
            onClick={(e) => tabonClickHandler((e = "address"))}
            className={
              tab === "address"
                ? "bg-gradient tab-list border-0"
                : "bg-gray tab-list border-0"
            }
          >
            <img src={addressIcon} className="tabIcon" alt="address" />
            <p>Address</p>
          </button>
          <button
            onClick={(e) => tabonClickHandler((e = "person"))}
            className={
              tab === "person"
                ? "bg-gradient tab-list border-0"
                : "bg-gray tab-list border-0"
            }
          >
            <img
              src={personalIcon}
              className="tabIcon"
              alt="personal_details"
            />
            <p>Personal Details</p>
          </button>
          <button
            onClick={(e) => tabonClickHandler((e = "qw"))}
            className={
              tab === "qw"
                ? "bg-gradient tab-list border-0"
                : "bg-gray tab-list border-0"
            }
          >
            <img src={qualifWorkIcon} className="tabIcon" alt="qualification" />
            <p>Qualif & Working</p>
          </button>
          <button
            onClick={(e) => tabonClickHandler((e = "salary"))}
            className={
              tab === "salary"
                ? "bg-gradient tab-list border-0"
                : "bg-gray tab-list border-0"
            }
          >
            <img src={currencyIcon} className="tabIcon" alt="salary" />
            <p>Salary, EPF</p>
          </button>
          <button
            onClick={(e) => tabonClickHandler((e = "bank"))}
            className={
              tab == "bank"
                ? "bg-gradient tab-list border-0"
                : "bg-gray tab-list border-0"
            }
          >
            <img src={bankIcon} className="tabIcon" alt="bank_details" />
            <p>Bank Details</p>
          </button>
          <button
            onClick={(e) => tabonClickHandler((e = "document"))}
            className={
              tab === "document"
                ? "bg-gradient tab-list border-0"
                : "bg-gray tab-list border-0"
            }
          >
            <img src={uploadIcon} className="tabIcon" alt="document-update" />
            <p>Documents</p>
          </button>
        </Col>
      </Col>
      <Col xs={12}>
        {tab === "address" && (
          <AddressTab
            reset={reset}
            allowAccess={allowAccess}
            setAllowAccess={setAllowAccess}
            setTab={setTab}
            setTempFormData={setTempFormData}
            tempFormData={tempFormData}
          />
        )}
        {tab === "person" && (
          <PersonalDetails
            reset={reset}
            allowAccess={allowAccess}
            setAllowAccess={setAllowAccess}
            setTab={setTab}
            setTempFormData={setTempFormData}
            tempFormData={tempFormData}
          />
        )}
        {tab === "qw" && (
          <QualifWork
            reset={reset}
            allowAccess={allowAccess}
            setAllowAccess={setAllowAccess}
            setTab={setTab}
            setTempFormData={setTempFormData}
            tempFormData={tempFormData}
          />
        )}
        {tab === "salary" && (
          <SalaryEpf
            reset={reset}
            allowAccess={allowAccess}
            setAllowAccess={setAllowAccess}
            setTab={setTab}
            setTempFormData={setTempFormData}
            tempFormData={tempFormData}
          />
        )}
        {tab === "bank" && (
          <BankDetails
            reset={reset}
            allowAccess={allowAccess}
            setAllowAccess={setAllowAccess}
            setTab={setTab}
            setTempFormData={setTempFormData}
            tempFormData={tempFormData}
          />
        )}
        {tab === "document" && (
          <EmDocument
            setAllowAccess={setAllowAccess}
            setReset={setReset}
            setTab={setTab}
            setTempFormData={setTempFormData}
            tempFormData={tempFormData}
            employee_id={employeeDetail}
          />
        )}
      </Col>
    </>
  );
};

export default EmployeeMasterTab;
