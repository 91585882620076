import React from "react";
import { employeeIcon, projectIcon, discountIcon } from "../../assets/img";

const Header = () => {
  return (
    <div className=" px-3 py-3 w-100">
      <div className="d-flex align-items-center justify-content-around dash-board-box top-dash-board rounded p-2">
        <div className="dash-left">
          <h3>Statistics Card</h3>
          <h4>Monthly/yearly Statistics</h4>
        </div>
        <div className="d-flex align-items-center">
          <img src={discountIcon} className="dash-board-icon" alt="" />
          <div className="rt-img">
            <p>Revenue</p>
            <h5>₹ 4567K</h5>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <img src={employeeIcon} className="dash-board-icon" alt="" />
          <div className="rt-img">
            <p>Employee</p>
            <h5>₹ 5678</h5>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <img src={projectIcon} className="dash-board-icon" alt="" />
          <div className="rt-img">
            <p>Projects</p>
            <h5>₹ 5678</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
