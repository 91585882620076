// @ts-nocheck
import React from "react";
import { logo } from "../assets/img";

const NavBarTop = () => {
  return (
    <div className="top-navbar w-100 text-center">
      <img src={logo} alt="logo" />
      <h5>THE EPC COMPANY</h5>
    </div>
  );
};

export default NavBarTop;
