import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../../constants/Services";

const IsmMaterialIssueFirstForm = ({
  projectList,
  setWareHouse,
  setMaterialType,
  setMaterialIssue,
  setMiDate,
  setProject,
  setMaterialRequest,
  setWareHouseErr,
  setMaterialTypeErr,
  setProjectErr,
  WareHouse,
  materialType,
  materialIssue,
  miDate,
  project,
  MaterialRequest,
  WareHouseErr,
  materialTypeErr,
  materialIssueErr,
  projectErr,
  MaterialRequestErr,
  miDateErr,
  miIssueDateErr,
  miIssueDate,
  setMiIssueDate,
  warehouseSelector,
  setMaterialTab,
  setMaterials,
  setCompany_id,
  company_id,
  company_idErr,
}) => {
  const [list, setList] = useState([]);
  const dateHandler = (date) => {
    const now = new Date();
    const enter_date = new Date(date);

    if (enter_date > now) {
    } else {
      setMiDate(date);
    }
  };

  // WARE HOUSE LIST
  const getWareHouseList = () => {
    api
      .warehousePojectView(project)
      .then((res) => {
        setList(res?.warehouse_project);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const filtered_date = (sel_date, mi_date) => {
    let today = new Date();
    let selected_date = new Date(sel_date);
    let now = new Date(mi_date);
    var defaultDate = now - 1000 * 60 * 60 * 24 * 1;
    defaultDate = new Date(defaultDate);
    if (selected_date > defaultDate || selected_date == defaultDate) {
      setMiIssueDate(sel_date);
    }
  };

  const [listCompany, setCompanyList] = useState([]);
  const getCompanies = () => {
    api
      .getCompanies()
      .then((res) => {
        if (res?.status == "success") {
          let finder = res?.companies?.filter((i) => i?.status == 1);
          if (finder?.length > 0) {
            setCompanyList(finder);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (project) {
      setMaterialTab([]);
      setMaterials({});
      getWareHouseList();
    }
  }, [project]);

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">Material Issue</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <select
            className="border-0 bg-lightBlue px-2 py-2 w-90 mt-1"
            placeholder="Select Company"
            onChange={(e) => setCompany_id(e.target.value)}
            value={company_id}
          >
            <option value={""}>Select Company</option>
            {listCompany?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          {company_id?.length == 0 && company_idErr && (
            <p className="err">Select Company</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>

          <select
            style={{ minHeight: "2.1rem", fontSize: "13px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => setProject(e.target.value)}
            value={project}
          >
            <option hidden>Select Project</option>
            {projectList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>

          {project?.length == 0 && projectErr && (
            <p className="err">Select Project Name</p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">WareHouse</label>
          <select
            className="input-select bg-lightBlue ms-0"
            style={{
              border: "2px solid #f1f7ff",
              width: "90%",
              marginRight: "auto",
              fontSize: "13px",
            }}
            onChange={(e) => warehouseSelector(e.target.value)}
            value={WareHouse}
          >
            <option hidden>Select WareHouse</option>
            {list?.map((item, ind) => {
              return (
                <option value={item?.id} key={ind}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          {WareHouse?.length == 0 && WareHouseErr && (
            <p className="err">Select WareHouse </p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Request Slip No</label>
          <input
            style={{ fontSize: "13px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter your Slip No"
            onChange={(e) => setMaterialRequest(e.target.value)}
            value={MaterialRequest}
          />
          {MaterialRequest?.length == 0 && MaterialRequestErr && (
            <p className="err">Enter Material Request Slip No</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Request Slip Date</label>
          <input
            style={{ fontSize: "13px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            type="date"
            placeholder="Enter your Slip No"
            onChange={(e) => dateHandler(e.target.value)}
            value={miDate}
          />
          {miDate?.length == 0 && miDateErr && (
            <p className="err">Select Material Request Slip Date</p>
          )}
        </Col>

        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Issue No</label>
          <p
            style={{ minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 ms-0 mt-1"
          ></p>
        </Col> */}
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Issue Date</label>
          <input
            style={{ fontSize: "13px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            type="date"
            onChange={(e) => filtered_date(e.target.value, miDate)}
            value={miIssueDate}
          />
          {miIssueDate?.length == 0 && miIssueDateErr && (
            <p className="err">Select Material Issue Date</p>
          )}
        </Col>

        <Col xs={12} sm={12} className="inputinner-box text-start">
          {/* <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={submitHandler}>
            Fetch
          </button> */}
        </Col>
      </Col>
    </Col>
  );
};

export default IsmMaterialIssueFirstForm;
