import React, { useState } from "react";
import { Col } from "react-bootstrap";

const SalaryEpf = ({
  setTab,
  setTempFormData,
  tempFormData,
  setAllowAccess,
  allowAccess,
}) => {
  const [error, setError] = useState([]);
  const [salary, setSalary] = useState({
    cug: tempFormData?.cug || "",
    mgs: tempFormData?.mgs || "",
    insurence: tempFormData?.insurence || "",
    esi: tempFormData?.esic || "",
  });
  const [salaryRequiredFields, setSalaryRequiredFields] = useState({
    uan: tempFormData?.uan || "",
    epf: tempFormData?.epf || "",
    aadhar: tempFormData?.aadhar || "",
    pan: tempFormData?.pan || "",
  });

  const resetData = () => {
    setSalary({
      cug: "",
      mgs: "",
      insurence: "",
      esi: "",
    });

    setSalaryRequiredFields({
      uan: "",
      epf: "",
      aadhar: "",
      pan: "",
    });
  };

  const empMasterNextClickHandler = () => {
    let temp = [];
    Object.entries(salaryRequiredFields).forEach((item) => {
      console.log("each-value", item);
      if (item[1] === "") {
        temp.push(item[0]);
      }
    });
    setError(temp);
    if (Object.values(salaryRequiredFields).every((x) => x !== "")) {
      setTempFormData({ ...tempFormData, ...salary, ...salaryRequiredFields });
      setTab("bank");
      setAllowAccess([...allowAccess, "salary"]);
      resetData();
    }
  };
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-0 text-center">
      <h4>Salary, EPF & Insurance</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">CTC</label>
          <input
            value={salary.cug}
            onChange={(e) =>
              setSalary({
                ...salary,
                cug: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter CTC"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">MGS</label>
          <input
            value={salary.mgs}
            onChange={(e) =>
              setSalary({
                ...salary,
                mgs: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter MGS"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            UAN<span className="err">*</span>
          </label>
          <input
            value={salaryRequiredFields.uan}
            onChange={(e) =>
              setSalaryRequiredFields({
                ...salaryRequiredFields,
                uan: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter UAN"
          />
          {Object.values(error)?.includes("uan") && (
            <p className="err">UAN Field Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            EPF No<span className="err">*</span>
          </label>
          <input
            value={salaryRequiredFields.epf}
            onChange={(e) =>
              setSalaryRequiredFields({
                ...salaryRequiredFields,
                epf: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter EPF No"
          />
          {Object.values(error)?.includes("epf") && (
            <p className="err">EPF Field Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Insurance</label>
          <input
            value={salary.insurence}
            onChange={(e) =>
              setSalary({
                ...salary,
                insurence: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Insurance"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">ESIC</label>
          <input
            value={salary.esi}
            onChange={(e) =>
              setSalary({
                ...salary,
                esi: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter ESIC"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Aadhar No<span className="err">*</span>
          </label>
          <input
            value={salaryRequiredFields.aadhar}
            onChange={(e) =>
              setSalaryRequiredFields({
                ...salaryRequiredFields,
                aadhar: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Aadhar No"
          />
          {Object.values(error)?.includes("aadhar") && (
            <p className="err">Aadhar Field Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Pan No<span className="err">*</span>
          </label>
          <input
            value={salaryRequiredFields.pan}
            onChange={(e) =>
              setSalaryRequiredFields({
                ...salaryRequiredFields,
                pan: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Pan"
          />
          {Object.values(error)?.includes("pan") && (
            <p className="err">UAN Field Required</p>
          )}
        </Col>
        <Col sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={empMasterNextClickHandler}>
            Next
          </button>
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
            onClick={resetData}>
            cancel
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default SalaryEpf;
