import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";

const EmDocument = ({
  setTab,
  setAllowAccess,
  setTempFormData,
  tempFormData,
  employee_id,
  setReset,
}) => {
  const [docError, setDocError] = useState(false);
  const [document, setDocument] = useState();

  console.log(employee_id, "employee_id");

  const empMasterFinalSubmitHandler = () => {
    let formData = new FormData();
    // if (document?.files?.length !== 0) {
    formData.append("employee_id", employee_id?.id);
    formData.append("offer_letter", 1);
    formData.append("proof_document", document);
    formData.append("emp_type", 1);
    formData.append("id_card", 1);
    formData.append("employee_level", 0);
    formData.append("designation", employee_id?.designation);
    formData.append("department", employee_id?.department);
    setTempFormData({ ...tempFormData, document });
    for (let [key, val] of Object.entries(tempFormData)) {
      // console.log("key-1", key);
      // console.log("value-1", val);
      formData.append(key, val);
    }

    api.employee_detail_save(formData).then((res) => {
      console.log("emp-final-api-res", res);
      alert(res?.message);
      setReset(true);
      setTab("address");
      setTempFormData({});
      setAllowAccess([]);
    });
    // setDocError(false);
    // } else {
    // setDocError(true);
    // }
  };

  return (
    <Col className="emply-master-form px-3 py-2 d-flex justify-content-center flex-column py-md-4 bg-white mt-0 text-center">
      <h4 className="w-100 my-1 py-1">Document</h4>
      <Col
        xs={12}
        sm={6}
        xl={6}
        xxl={5}
        className="inputinner-box my-3 mx-auto text-start positon-relative"
      >
        <div className="d-sm-flex w-90 ms-0 emply-mas-doc-box accordion-body bg-lightBlue">
          <p className="bg-lightBlue emp-doc-text-box">
            {document ? document?.name : "Upload Document"}
          </p>
          <input
            type="file"
            onChange={(e) => setDocument(e.target.files[0])}
            className="border-0 px-1"
          />
          {docError && <p className="err">Please Upload Document</p>}
        </div>
      </Col>
      <div>
        <button
          className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
          onClick={empMasterFinalSubmitHandler}
        >
          Submit
        </button>
      </div>
    </Col>
  );
};

export default EmDocument;
