import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
import { InvoiceScrutinyTableData } from "../../constants/StaticData";

const InvoiceBooking = ({
  setRefInvoiceNo,
  setRefInvoiceNoErr,
  refInvoiceNoErr,
  refInvoiceNo,
  projectList,
  setInvoiceNo,
  InvoiceNo,
  setProjectName,
  projectName,
  setGrnNo,
  grnNo,
  setInvoiceDate,
  InvoiceDate,
  setInvoiceNoErr,
  InvoiceNoErr,
  projectNameErr,
  setProjectNameErr,
  InvoiceDateErr,
  setInvoiceDateErr,
  submitHandler,
  projectSelect,
  grnList,
  grnSelect,
  grnDate,
  vendorName,
  setVendorName,
  grnNoErr,
  vendorNameErr,
  file,
  setFile,
  fileErr,
  setFileErr,
  setCompany_id,
  company_id,
  company_idErr,
  companyHandle,
  listCompany,
  setVendorData,
}) => {
  // console.log(projectList);
  const [vendorList, setVendorList] = useState([]);

  const getVendor = () => {
    api
      .get_vendors()
      .then((res) => {
        setVendorList(res?.vendors);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getVendor();
  }, []);

  const filtered_date = (sel_date, grn_date) => {
    setInvoiceDate(sel_date);
    // let today = new Date();
    // let selected_date = new Date(sel_date);
    // let now = new Date(grn_date);
    // var defaultDate = now - 1000 * 60 * 60 * 24 * 1;
    // defaultDate = new Date(defaultDate);
    // if (selected_date > defaultDate || selected_date == defaultDate) {
    //   setInvoiceDate(sel_date);
    // }
  };

  const vendorHadler = (id) => {
    let finder = vendorList?.find((i) => i?.id == id);
    setVendorData(finder);
    setVendorName(id);
  };

  return (
    <Col
      xs={12}
      className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center"
    >
      <h4 className="w-95 ms-xl-0">Invoice Booking</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <select
            className="border-0 bg-lightBlue px-2 py-2 w-90 mt-1"
            placeholder="Select Company"
            onChange={(e) => companyHandle(e.target.value)}
            value={company_id}
          >
            <option value={""}>Select Company</option>
            {listCompany?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          {company_id?.length == 0 && company_idErr && (
            <p className="err">Select Company</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>

          <select
            style={{ minHeight: "2.1rem", fontSize: "12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => projectSelect(e.target.value)}
            value={projectName}
          >
            <option hidden>Select Project</option>
            {projectList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.name}
              </option>
            ))}
          </select>

          {projectName?.length == 0 && projectNameErr && (
            <p className="err">Select Project Name</p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GRN No</label>

          <select
            style={{ minHeight: "2.1rem", fontSize: "12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => grnSelect(e.target.value)}
            value={grnNo}
          >
            <option hidden>Select GRN</option>
            {grnList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.grn_no}
              </option>
            ))}
          </select>

          {grnNo?.length == 0 && grnNoErr && (
            <p className="err">Select GRN No.</p>
          )}
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GRN Date</label>
          <p
            style={{
              minHeight: "2.1rem",
              width: "90%",
              lineHeight: "2.1rem",
              fontSize: "12px",
            }}
            className="input-select bg-lightBlue ms-0 ps-3 my-1"
          >
            {grnDate}
          </p>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Vendor Name</label>
          <select
            style={{ minHeight: "2.1rem", fontSize: "12px" }}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={(e) => vendorHadler(e.target.value)}
            value={vendorName}
          >
            <option hidden>Select Vendor Name</option>
            {vendorList?.map((item, index) => (
              <option key={index} value={item?.id}>
                {item?.company_name}
              </option>
            ))}
          </select>
          {vendorName?.length == 0 && vendorNameErr && (
            <p className="err">Select Vendor Name</p>
          )}
        </Col>

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Ref Invoice No</label>
          <input
            onChange={(e) => setRefInvoiceNo(e.target.value)}
            type="number"
            value={refInvoiceNo}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            style={{ fontSize: "12px" }}
          />
          {refInvoiceNo?.length == 0 && refInvoiceNoErr && (
            <p className="err">Enter Ref Invoice No.</p>
          )}
        </Col>

        {/* <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Invoice No</label>
          <input
            onChange={(e) => setInvoiceNo(e.target.value)}
            type="number"
            value={InvoiceNo}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            style={{fontSize:"12px"}}
          />
          {InvoiceNo?.length == 0 && InvoiceNoErr && (
            <p className="err">Enter Invoice No.</p>
          )}
        </Col> */}

        <Col xs={12} sm={6} lg={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Invoice Date</label>
          <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Invoice Date"
            onChange={(e) => filtered_date(e.target.value, grnDate)}
            value={InvoiceDate}
            style={{ fontSize: "12px" }}
          />
          {InvoiceDate?.length == 0 && InvoiceDateErr && (
            <p className="err">Enter Invoice Date</p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box  text-start">
          <label className="w-100 py-1">Document Upload</label>
          <div className="d-sm-flex w-90 ms-0 emply-mas-doc-box accordion-body bg-lightBlue">
            <input
              type="file"
              className="border-0 px-1"
              accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>
          {file?.length == 0 && fileErr && (
            <p className="err">Upload Document</p>
          )}
        </Col>
        {/* <Col xs={12} sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={submitHandler}>
            Fetch
          </button>
        </Col> */}
      </Col>
    </Col>
  );
};

export default InvoiceBooking;
