import React from "react";
import { Link } from "react-router-dom";

const BillingBudget = () => {
  return (
    <div className="pe-3 w-100">
      <div className="user-mange-table bg-white mt-0 rounded top-dash-board1 position-relative">
        <table>
          <thead>
            <tr>
              <th className="text-center">S.No</th>
              <th className="text-center">Project Name</th>
              <th className="text-center">Project Number</th>
              <th className="text-center">Start</th>
              <th className="text-center">End</th>
              <th className="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center fs-13">1</td>
              <td className="text-center fs-13">Project Name -1</td>
              <td className="text-center fs-13">RTYU98765</td>
              <td className="text-center fs-13">12-jan-2022</td>
              <td className="text-center fs-13">06-May-2023</td>
              <td className="text-center fs-13">Completed</td>
            </tr>
            <tr>
              <td className="text-center fs-13">2</td>
              <td className="text-center fs-13">Project Name -1</td>
              <td className="text-center fs-13">RTYU98765</td>
              <td className="text-center fs-13">12-jan-2022</td>
              <td className="text-center fs-13">06-May-2023</td>
              <td className="text-center fs-13">Completed</td>
            </tr>
            <tr>
              <td className="text-center fs-13">3</td>
              <td className="text-center fs-13">Project Name -1</td>
              <td className="text-center fs-13">RTYU98765</td>
              <td className="text-center fs-13">12-jan-2022</td>
              <td className="text-center fs-13">06-May-2023</td>
              <td className="text-center fs-13">Completed</td>
            </tr>
          </tbody>
        </table>
        <Link className="cust-btn position-absolute end-0 mb-3 me-3 px-2 py-1 bg-gradient text-white   bottom-0">
          Read More
        </Link>
      </div>
    </div>
  );
};

export default BillingBudget;
