// @ts-nocheck
import React from "react";
import { tickIcon } from "../../assets/img";
import { dprTable } from "../../constants/StaticData";

const DprTable = () => {
  return (
    <div className="user-mange-table">
      <table>
        <thead>
          <th>S.no</th>
          <th>BOQ code</th>
          <th>Description</th>
          <th>Item Wise</th>
          <th>Measurement</th>
          <th>Remarks</th>
        </thead>
        <tbody>
          {dprTable.map((item, ind) => {
            return (
              <tr key={ind}>
                <td>{item?.id}</td>
                <td>{item?.b_code}</td>
                <td>{item?.des}</td>
                <td>{item?.eId}</td>
                <td>{item?.role}</td>
                <td>
                  <div className="checkbox">
                    {item?.check && <img src={tickIcon} />}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DprTable;
