// @ts-nocheck
import React from "react";
import { useNavigate } from "react-router-dom";
import { tickIcon } from "../../../assets/img";
import { materialReturnTable } from "../../../constants/StaticData";

const MaterialReturnTable = ({
  getMatrialStock,
  projectName,
  tableSel,
  setTableSel,
  tableStaticData,
  setTableStaticTable,
  grnDetailsList,
  setGrnDetailsList,
}) => {
  const navigate = useNavigate();

  const handleChange = (
    evnt,
    ind,
    type,
    id,
    purchase_order_net_value,
    available_stock,
    po_order_net_value,
    total_stock,
    net_value_no,
    net_value,
    per_net_value
  ) => {
    let temp = [...grnDetailsList];
    let arr = [...tableSel];
    let inx = arr.findIndex((i) => i.id == id);

    // if (type == "invoice_amount") {
    if (type == "received_qtys") {
      console.log("received_qtys", +evnt * +net_value);

      if (+evnt <= 0) {
        temp[ind] = {
          ...temp[ind],
          [type]: 0,
          // ["purchase_order_net_value"]: po_order_net_value,
          ["net_value"]: net_value_no,
          ["error"]:
            +evnt > Math.abs(+temp[ind]?.return_qty - +temp[ind]?.qty) ? 1 : 0,
        };
        if (arr.length > 0) {
          arr[inx ? inx : 0] = {
            ...arr[inx ? inx : 0],
            [type]: 0,
            // [purchase_order_net_value]: +evnt * +purchase_order_net_value,
            ["net_value"]: net_value_no,
            ["error"]:
              +evnt >
              Math.abs(
                +arr[inx ? inx : 0]?.return_qty + arr[inx ? inx : 0]?.qty
              )
                ? 1
                : 0,
          };
        }
      } else {
        temp[ind] = {
          ...temp[ind],
          [type]: +evnt,
          // ["purchase_order_net_value"]: +evnt * +purchase_order_net_value,
          ["net_value"]: Number(+evnt * +per_net_value).toFixed(2),
          ["error"]:
            +evnt > Math.abs(+temp[ind]?.return_qty - +temp[ind]?.qty) ? 1 : 0,
        };
        if (arr.length > 0) {
          arr[inx ? inx : 0] = {
            ...arr[inx ? inx : 0],
            [type]: +evnt,
            // [purchase_order_net_value]: +evnt * +purchase_order_net_value,
            ["net_value"]: Number(+evnt * +per_net_value).toFixed(2),
            ["error"]:
              +evnt >
              Math.abs(
                +arr[inx ? inx : 0]?.return_qty + arr[inx ? inx : 0]?.qty
              )
                ? 1
                : 0,
          };
        }
      }
    } else {
      temp[ind] = {
        ...temp[ind],
        [type]: evnt,
      };

      if (arr.length > 0) {
        arr[inx ? inx : 0] = {
          ...arr[inx ? inx : 0],
          [type]: evnt,
        };
      }
    }

    setGrnDetailsList(temp);
    // setTableSel(arr);
  };

  const checkbox = (item) => {
    let temp = [...tableSel];
    let inx = temp.indexOf(item);

    if (temp.includes(item)) {
      temp.splice(inx, 1);
    } else {
      temp.push(item);
    }
    setTableSel(temp);
  };

  // console.log("tableSel", tableSel);

  // console.log("grnDetailsList", grnDetailsList);

  return (
    <div
      style={{ overflowX: "scroll" }}
      className="user-mange-table bg-white p-2"
    >
      <table>
        <thead>
          <th className="text-center text-nowrap">S.No</th>
          <th className="text-center text-nowrap">Select</th>
          {/* <th className="text-center text-nowrap">BOQ</th> */}
          <th className="text-center text-nowrap">Material Code</th>
          <th className="text-nowrap">Material Description</th>
          <th className="text-center text-nowrap">UOM</th>
          <th className="text-center text-nowrap">Po Quantity</th>
          <th className="text-center text-nowrap">Pervious Return Qty</th>
          <th className="text-center text-nowrap">Return Quantity</th>
          <th className="text-center text-nowrap">Invoice Amount</th>
          <th className="text-center text-nowrap">Remarks</th>
        </thead>
        <tbody>
          {grnDetailsList?.map((item, ind) => {
            return (
              <tr key={ind}>
                <td className="text-center">{ind + 1}</td>
                <td className="ps-2">
                  {/* {item?.received_qty ? ( */}
                  <input
                    type={"checkbox"}
                    className="text-center"
                    onClick={() => checkbox(item?.id)}
                    checked={tableSel?.includes(item?.id) ? true : false}
                  />
                  {/* ) : (
                    <input disabled type={"checkbox"} className="text-center" />
                  )} */}
                </td>
                {/* <td className="">{item?.boq}</td> */}
                <td
                  className=""
                  role={"button"}
                  onClick={() =>
                    getMatrialStock(
                      projectName,
                      item?.material?.id,
                      ind,
                      item?.id
                    )
                  }
                >
                  {item?.material?.material_code}
                </td>
                <td className="">{item?.material?.name}</td>
                <td className="">{item?.material?.uom}</td>
                {/* <td className="text-center">{item?.available_stock}</td> */}
                <td className="text-center">{item?.qty}</td>
                <td className="text-center">{item?.return_qty}</td>
                <td className="text-center">
                  <input
                    className="border-0 w-100 text-center"
                    type="number"
                    value={item?.received_qtys}
                    onChange={(e) =>
                      handleChange(
                        e.target.value,
                        ind,
                        "received_qtys",
                        item?.id,
                        item?.purchase_order_net_value,
                        item?.available_stock,
                        item?.po_order_net_value,
                        item?.total_stock,
                        item?.net_value_no,
                        item?.net_value,
                        item?.per_net_value
                      )
                    }
                    readOnly
                  />
                  {+item.error == 1 && (
                    <p className="err" style={{ fontSize: "10px" }}>
                      {" "}
                      Exceeded The Quantity
                    </p>
                  )}
                </td>
                <td className="text-center">
                  <input
                    className="border-0 w-100 text-center"
                    type="number"
                    // value={item?.purchase_order_net_value}
                    value={item?.net_value}
                    onChange={(e) =>
                      handleChange(+e.target.value, ind, "net_value", item?.id)
                    }
                    readOnly
                  />
                  {/* <input
                    className="border-0 w-100 text-center"
                    type="number"
                    value={item?.invoice_amount}
                    onChange={(e) =>
                      handleChange(
                        +e.target.value,
                        ind,
                        "invoice_amount",
                        item?.id
                      )
                    }
                  /> */}
                  {/* {+item.invoice_amount > +item?.received_qty && (
                    <p className="err" style={{ fontSize: "10px" }}>
                      {" "}
                      Exceeded The Quantity
                    </p>
                  )} */}
                  {/* {+item.error == 1 && (
                    <p className="err" style={{ fontSize: "10px" }}>
                      {" "}
                      Exceeded The Quantity
                    </p>
                  )} */}
                </td>
                <td>
                  <input
                    className="border-0 w-100 text-center"
                    type="text"
                    value={item?.remarks}
                    onChange={(e) =>
                      handleChange(e.target.value, ind, "remarks", item?.id)
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MaterialReturnTable;
