import React, { useCallback, useState } from "react";
import StatusModal from "../StatusModal";
import { Col } from "react-bootstrap";

function PCProjectDetailsTab({
  disableSubmit,
  setProjectDetails,
  projectDetails,
  ProjectCreationFormSubmitHandler,
}) {
  const [error, setError] = useState([]);
  const [dateError, setDateError] = useState(false);

  const date1 = new Date(projectDetails.start_date);
  const date2 = new Date(projectDetails.end_date);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) || "";

  const projectStartDateHandler = (e) => {
    let date = new Date(e.target.value);
    let today = new Date();

    if (date == today || date < today) {
      setProjectDetails({
        ...projectDetails,
        start_date: e.target.value,
      });
    }
  };

  const projectEndDateHandler = (e) => {
    let date = new Date(e.target.value);
    let today = new Date();
    // if (date == today || date > today) {
    setProjectDetails({
      ...projectDetails,
      end_date: e.target.value,
    });
    // }
  };

  // console.log(diffDays + " days");
  const ProjectCreationSubmitHandler = () => {
    let temp = [];
    Object.entries(projectDetails).forEach((item) => {
      item[1] === ""
        ? item[0] !== "eot" && temp.push(item[0])
        : error.splice(error.indexOf(item[0], 1));
    });
    setError(temp);
    if (error.length === 0) {
      ProjectCreationFormSubmitHandler();
    }
  };

  const dateErrorHandler = useCallback(() => {
    let date = new Date(projectDetails.end_date);
    let today = new Date();

    // console.log("date", date, "today", today);

    if (date == today || date > today) {
      setDateError(false);
    } else {
      setDateError(true);
    }
  }, [projectDetails.end_date]);

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-0 text-center">
      <h4 className="w-95 ms-xl-0">Project Details</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Project Awarded Date <span className="err">*</span>
          </label>
          <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Awarded Date"
            value={projectDetails.awarded_date}
            onChange={(e) =>
              setProjectDetails({
                ...projectDetails,
                awarded_date: e.target.value,
              })
            }
          />
          {error.includes("awarded_date") && (
            <p className="err">Please Enter Awarded Date</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            LOI Date<span className="err">*</span>
          </label>
          <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter LOI Date"
            value={projectDetails.loi_date}
            onChange={(e) =>
              setProjectDetails({
                ...projectDetails,
                loi_date: e.target.value,
              })
            }
          />
          {error.includes("loi_date") && (
            <p className="err">Please Enter LOI Date</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Project Start Date<span className="err">*</span>
          </label>
          <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Project Start Date"
            value={projectDetails.start_date}
            onChange={(e) => projectStartDateHandler(e)}
            // onChange={(e) =>
            //   setProjectDetails({
            //     ...projectDetails,
            //     start_date: e.target.value,
            //   })
            // }
          />
          {error.includes("start_date") && (
            <p className="err">Please Enter Project Start Date</p>
          )}
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Project End Date<span className="err">*</span>
          </label>
          <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Project End Date"
            value={projectDetails.end_date}
            min={projectDetails.start_date}
            onChange={(e) => projectEndDateHandler(e)}
            // onKeyUp={dateErrorHandler}
            // onChange={(e) =>
            //   setProjectDetails({
            //     ...projectDetails,
            //     end_date: e.target.value,
            //   })
            // }
          />
          {error.includes("end_date") && (
            <p className="err">Please Enter Project End Date</p>
          )}
          {dateError && (
            <p className="err">Can not insert Current & Past Date</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Total No of Days</label>
          <p
            style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            {diffDays}
          </p>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Project Value<span className="err">*</span>
          </label>
          <input
            className="custom-file-input border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Project Value"
            value={projectDetails.value}
            onChange={(e) =>
              setProjectDetails({
                ...projectDetails,
                value: e.target.value,
              })
            }
          />
          {error.includes("value") && (
            <p className="err">Please Enter Project Value</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">EOT</label>
          <input
            type="date"
            className="border-0 bg-lightBlue px-2 py-2 w-90 mt-1 fs-13"
            placeholder="Enter Project From"
            value={projectDetails.eot_date}
            onChange={(e) =>
              setProjectDetails({
                ...projectDetails,
                eot_date: e.target.value,
              })
            }
          />
        </Col>

        <Col sm={12} className="inputinner-box text-start">
          <button
            disabled={disableSubmit}
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={ProjectCreationSubmitHandler}
          >
            Save
          </button>
          <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3">
            cancel
          </button>
        </Col>
      </Col>
    </Col>
  );
}

export default PCProjectDetailsTab;
