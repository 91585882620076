import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { api } from "../../constants/Services";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";

function EmployeeView() {
  const location = useLocation();
  const [empDetails, setEmpDetails] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  const [designationList, setDesinationList] = useState([]);

  const emp_id = location?.state?.id;
  // console.log("🚀 ~ file: EmployeeView.js:22 ~ EmployeeView ~ emp_id:", emp_id);
  let department;
  let designation;

  // department = departmentList
  //   ?.filter((depart) => depart?.id === empDetails?.designation)
  //   .reduce((acc, fil_depar) => fil_depar?.name, null);
  // console.log(
  //   "🚀 ~ file: EmployeeView.js:27 ~ EmployeeView ~  department:",
  //   department
  // );
  department = empDetails?.department_detail?.name;
  designation = empDetails?.designation_detail?.name;
  console.log("working");
  // designation = designationList
  //   ?.filter((design) => design?.id === empDetails?.department)
  //   .reduce((acc, fil_design) => fil_design?.name, null);

  useEffect(() => {
    api.employee_create_data().then((res) => {
      if (res?.status === "success") {
        setDepartmentList(res?.departments);
        setDesinationList(res?.designations);
      }
    });
  }, []);

  useEffect(() => {
    api.employee_view(emp_id).then((res) => {
      setEmpDetails(res?.employee);
    });
  }, [emp_id]);
  return (
    <Col xs={12} className="p-3">
      <Header />

      <Col
        xs={12}
        className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <h4> Employee View </h4>
        <Col xs={12} xxl={8} md={10} className="d-flex flex-wrap mx-auto">
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Name</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.name}
            </p>
          </Col>

          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Employee Id</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.emp_id}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Department</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {department ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100"> Designation</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {designation ?? "-"}
            </p>
          </Col>
          {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Experience</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.details?.prev_exp ?? "-"}
            </p>
          </Col> */}
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">District</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.address?.district}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Address</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.address?.address_1}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">State</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.address?.state}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Taluk</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.address?.taluk ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Pincode</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.address?.pin_code ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Telephone No</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {" "}
              {empDetails?.details?.telephone ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Mobile No</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.mobile ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">E-Mail</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.email_id}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Father Name</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.details?.father ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Mother Name </label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.details?.mother ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Spouse Name </label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.details?.spouse_name ?? "-"}
            </p>
          </Col>

          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Blood Group</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.details?.blood_group ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Emergency Contact Person</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.details?.emergency_contact_name ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Emergency Phone No</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.details?.emergency_contact ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Education Qualification</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.details?.qualification ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label>Specialization</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.details?.specialisation ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Previous Experience</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.details?.prev_exp ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Date of Join</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.doj}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Date of Resignation</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.resignation_date}
            </p>
          </Col>
          {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">CPS Working Experience</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"></p>
          </Col> */}
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Name of Bank</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.bank_details?.bank_name}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Account No</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {" "}
              {empDetails?.bank_details?.account_no ?? "-"}
            </p>
          </Col>
          {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Account Type</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {" "}
              {empDetails?.bank_details?.bank_name}
            </p>
          </Col> */}
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">IFSC Code</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.bank_details?.ifsc ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Branch Name</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.bank_details?.branch ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">CTC</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.details?.cug ?? " "}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">MGS</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {" "}
              {empDetails?.details?.mgs ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">UAN</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.proofs?.uan ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">EPF No</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.proofs?.epf ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Insurance</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.proofs?.insurence ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">ESIC</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.proofs?.esi ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Aadhar No</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {" "}
              {empDetails?.proofs?.aadhar ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Pan No</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.proofs?.pan ?? "-"}
            </p>
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            {/* <label className="w-100">Pan No</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.proofs?.pan ?? "-"}
            </p> */}
          </Col>

          <Col
            xs={12}
            sm={6}
            xl={6}
            className="inputinner-box text-start emp-view-doc-box">
            <label className="w-100 my-2">Employee Document</label>
            <iframe
              title="emp_document"
              src={empDetails?.proofs?.proof_document}
            />
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start ">
            <label className="w-100 my-2">Employee Photo</label>
            <img
              style={{ maxWidth: "110px" }}
              src={empDetails?.image ?? null}
              alt="emp_photo"
            />
          </Col>
        </Col>
        <div className="d-flex">
          <Link
            to="/hrm/employee-management"
            className="bg-gray border-0 ms-auto me-3 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2">
            Close
          </Link>
        </div>
      </Col>
    </Col>
  );
}

export default EmployeeView;
