import React, { useState, useEffect } from "react";
import { CirclesWithBar } from "react-loader-spinner";
// import eye_icon from "../../assets/icons/common/Eye.svg";
import edit_icon from "../../assets/icons/common/Edit.svg";
// import delete_icon from "../../assets/icons/common/Delete.svg";
import { PaginationControl } from "react-bootstrap-pagination-control";

import { useNavigate } from "react-router-dom";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";

const WareHousePage = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [btn, setBtn] = useState(false);
  const [input, setInput] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [ID, setID] = useState("");
  const [search, setSearch] = useState();
  const [uploads, setUpload] = useState();
  const [loading, setLoading] = useState(false);
  const [projectList, setProjectLists] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState({
    start: 0,
    end: 100,
  });
  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 33333,
  };

  useEffect(() => {
    // console.log("page-test", page);
    let start = `${page}00`;
    start = +start;
    let end = start + 100;
    setPageLimit({
      start,
      end,
    });
  }, [page]);

  const handleChage = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInput((pre) => ({ ...pre, [name]: value }));
  };

  const handleChages = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputs((pre) => ({ ...pre, [name]: value }));
  };

  const modelHandler = (item) => {
    let obj = {
      project: item?.project?.id,
      address: item?.address,
      warehouse: item?.name,
    };
    setID(item?.id);
    setInputs(obj);
    setShows(true);
  };

  const submitHandler = (type) => {
    if (type == "update") {
      if (inputs?.warehouse && inputs?.address && inputs?.project) {
        let formdata = new FormData();
        formdata.append("project_id", inputs?.project);
        formdata.append("name", inputs?.warehouse);
        formdata.append("address", inputs?.address);
        formdata.append("ware_house_id", ID);
        api
          .warehouseUpdate(formdata)
          .then((res) => {
            alert(res?.message);
            setInputs("");
            setShows(false);
            setBtn(false);
            getList();
          })
          .catch((err) => {
            console.log("err");
            setInputs("");
            // setShow(false);
            setBtn(false);
          });
      } else {
        alert("Please Fill All Details");
      }
    } else {
      if (input?.warehouse && input?.address && input?.project) {
        let formdata = new FormData();

        formdata.append("project_id", input?.project);
        formdata.append("name", input?.warehouse);
        formdata.append("address", input?.address);
        setBtn(true);

        api
          .warehouseAdd(formdata)
          .then((res) => {
            alert(res?.message);
            setInput("");
            setShow(false);
            setBtn(false);
            getList();
          })
          .catch((err) => {
            console.log("err");
            setInput("");
            // setShow(false);
            setBtn(false);
          });
      } else {
        alert("Please Fill All Details");
      }
    }
  };

  const submitHandlers = () => {
    if (
      inputs?.hsn_code &&
      inputs?.description &&
      inputs?.sgst &&
      inputs?.cgst &&
      inputs?.igst
    ) {
      let formdata = new FormData();

      formdata.append("codes", inputs?.hsn_code);
      formdata.append("description", inputs?.description);
      formdata.append("sgst", inputs?.sgst);
      formdata.append("cgst", inputs?.cgst);
      formdata.append("igst", inputs?.igst);

      formdata.append("hsn_code_id", ID);
      setBtn(true);
      api.updateHSN(formdata).then((res) => {
        alert(res?.message);
        setBtn(false);
        setShows(false);
        getList();
      });
    } else {
      alert("Please Fill All Details");
    }
  };

  const getList = () => {
    api
      .warehouseList()
      .then((res) => {
        setList(res?.warehouses);
      })
      .catch((err) => {
        console.log("err", err);
      });

    api
      .project_list_without_boq()
      .then((res) => {
        setProjectLists(res?.projects);
      })
      .catch((err) => {
        console.log("err", err);
      });

    // api
    //   .project_list()
    //   .then((res) => {})
    //   .catch((err) => {
    //     console.log("err", err);
    //   });
  };

  const searchHsn = () => {
    let data = {
      code: search,
    };
    api.searchHSN(data).then((res) => {
      let temp = [];
      temp.push(res?.hsn_code);
      // setList(temp);
    });
  };

  const uploadFile = () => {
    let data = {
      import_excel: uploads,
    };

    // console.log("🚀 ~ file: HsnPage.js:127 ~ uploadFile ~ data:", data);
    if (uploads) {
      setLoading(true);
      api.hsnMaterialImport(data).then((res) => {
        alert(res?.message);
        setUpload("");
        setLoading(false);
      });
    } else {
      alert("Please Upload File");
    }
  };

  useEffect(() => {
    if (search) {
      searchHsn();
    } else {
      getList();
    }
  }, [search]);

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <CirclesWithBar
        visible={loading}
        height="110"
        width="110"
        color="#4fa94d"
        ariaLabel="circles-with-bar-loading"
        wrapperStyle={style}
      />

      <Col className="emply-master-form  px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <h4>WareHouse Details</h4>
        <div className="d-flex justify-content-end align-items-center">
          <input
            placeholder="search warehouse"
            className="border-0 bg-lightBlue px-2 py-1  mt-1 mx-3"
            style={{ height: "40px" }}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <button
            type="button"
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={() => setShow(true)}
          >
            Add WareHouse
          </button>
        </div>
        {/* <Col xs={12} className="inputinner-box text-start">
          <div className="d-flex justify-content-end my-3">
            <a
              href="http://erp-script.cresconprojects.com/public/excel/cresscon-hsn.xlsx"
              target="_blank"
              rel="noreferrer"
              className="bg-primar border-0 rounded-1 text-white f-1 me-auto fs-14 px-4 py-2 pointer"
            >
              Sample Download
            </a>
            <div class="input-group  w-50">
              <input
                type="file"
                class="form-control border-0 bg-lightBlue w-90"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                onChange={(e) => setUpload(e.target.files[0])}
                aria-describedby="button-addon2"
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={uploadFile}
              >
                Upload
              </button>
            </div>
          </div>
        </Col> */}
        <div className="user-mange-table">
          <table className="table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>WareHouse</th>
                <th>Project Name</th>
                <th>Address</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, ind) => (
                <tr>
                  <td className="fs-14px">{ind + 1}</td>
                  <td className="fs-14px">{item?.name}</td>
                  <td className="text-wrap fs-14px">{item?.project?.name}</td>
                  <td className="fs-14px">{item?.address}</td>
                  <td>
                    <div className="d-flex">
                      <img
                        onClick={() => modelHandler(item)}
                        style={{ width: "20px" }}
                        className="pointer ms-2"
                        src={edit_icon}
                        alt="edit-icon"
                      />
                      {/* <img
                        style={{ width: "15px" }}
                        className="pointer"
                        src={edit_icon}
                        alt="eye-icon"
                      />
                      <img
                        style={{ width: "15px" }}
                        className="pointer"
                        src={delete_icon}
                        alt="eye-icon"
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add WareHouse</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} className="my-2">
              <label className="w-100">Project</label>
              <select
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                name="project"
                onChange={handleChage}
                value={input?.project}
              >
                <option hidden>Select Project Name</option>
                {projectList?.map((item, ind) => {
                  return (
                    <option value={item?.id} key={ind}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col sm={12} className="my-2">
              <label className="w-100">WareHouse Name</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="WareHouse Name"
                name="warehouse"
                onChange={handleChage}
                value={input?.warehouse}
              />
            </Col>
            <Col sm={12} className="my-2">
              <label className="w-100">Address</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Address"
                name="address"
                onChange={handleChage}
                value={input?.address}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
            onClick={() => setShow(false)}
          >
            Close
          </button>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={submitHandler}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={shows} onHide={() => setShows(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit WareHouse</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} className="my-2">
              <label className="w-100">Project</label>
              <select
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                name="project"
                onChange={handleChages}
                value={inputs?.project}
              >
                <option hidden>Select Project Name</option>
                {projectList?.map((item, ind) => {
                  return (
                    <option value={item?.id} key={ind}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col sm={12} className="my-2">
              <label className="w-100">WareHouse Name</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="WareHouse Name"
                name="warehouse"
                onChange={handleChages}
                value={inputs?.warehouse}
              />
            </Col>
            <Col sm={12} className="my-2">
              <label className="w-100">Address</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Address"
                name="address"
                onChange={handleChages}
                value={inputs?.address}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
            onClick={() => setShows(false)}
          >
            Close
          </button>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={() => submitHandler("update")}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
      <div className="my-1 py-1 d-flex justify-content-end">
        <PaginationControl
          page={page}
          between={4}
          total={list?.length}
          limit={100}
          changePage={(page) => {
            setPage(page);
            console.log(page);
          }}
          ellipsis={1}
        />
      </div>
    </Col>
  );
};

export default WareHousePage;
