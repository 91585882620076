import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { api } from "../../constants/Services";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";

function EmployeeEdit() {
  const [departmentList, setDepartmentList] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [designationList, setDesinationList] = useState([]);

  const [Name, setName] = useState("");
  const [EmployeeId, setEmployeeId] = useState("");
  const [Department, setDepartment] = useState("");
  const [Designation, setDesignation] = useState("");
  const [District, setDistrict] = useState("");
  const [Address, setAddress] = useState("");
  const [Address2, setAddress2] = useState("");
  const [State, setState] = useState("");
  const [Taluk, setTaluk] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Telephone_No, setTelephone_No] = useState("");
  const [Mobile_No, setMobileNo] = useState("");
  const [Mail, setMail] = useState("");
  const [FatherName, setFatherName] = useState("");
  const [MotherName, setMotherName] = useState("");
  const [SpouseName, setSpouseName] = useState("");
  const [BloodGroup, setBloodGroup] = useState("");
  const [Emergency_Contact_Person, setEmergency_Contact_Person] = useState("");
  const [Emergency_Phone_No, setEmergency_Phone_No] = useState("");
  const [Education_Qualification, setEducation_Qualification] = useState("");
  const [Specialization, setSpecialization] = useState("");
  const [Previous_Experience, setPrevious_Experience] = useState("");
  const [Date_of_Join, setDate_of_Join] = useState("");
  const [Date_of_Resignation, setDate_of_Resignation] = useState("");
  const [Name_of_Bank, setName_of_Bank] = useState("");
  const [Account_No, setAccount_No] = useState("");
  const [IFSC_Code, setIFSC_Code] = useState("");
  const [BranchName, setBranchName] = useState("");
  const [CTC, setCTC] = useState("");
  const [MGS, setMGS] = useState("");
  const [UAN, setUAN] = useState("");
  const [EPF_No, setEPF_No] = useState("");
  const [Insurance, setInsurance] = useState("");
  const [ESIC, setESIC] = useState("");
  const [Aadhar_No, setAadhar_No] = useState("");
  const [Pan_No, setPan_No] = useState("");
  const [Employee_Photo, setEmployee_Photo] = useState("");
  const [Employee_Documnet, setEmployee_Documnet] = useState("");
  const [employeeLevel, setEmployeeLevel] = useState("");

  const [NameErr, setNameErr] = useState(false);
  const [EmployeeIdErr, setEmployeeIdErr] = useState(false);
  const [DepartmentErr, setDepartmentErr] = useState(false);
  const [DesignationErr, setDesignationErr] = useState(false);
  const [DistrictErr, setDistrictErr] = useState(false);
  const [AddressErr, setAddressErr] = useState(false);
  const [Address2Err, setAddress2Err] = useState(false);
  const [StateErr, setStateErr] = useState(false);
  const [TalukErr, setTalukErr] = useState(false);
  const [PincodeErr, setPincodeErr] = useState(false);
  const [Telephone_NoErr, setTelephone_NoErr] = useState(false);
  const [Mobile_NoErr, setMobileNoErr] = useState(false);
  const [MailErr, setMailErr] = useState(false);
  const [FatherNamErr, setFatherNameErr] = useState(false);
  const [MotherNameErr, setMotherNameErr] = useState(false);
  const [SpouseNameErr, setSpouseNameErr] = useState(false);
  const [BloodGroupErr, setBloodGroupErr] = useState(false);
  const [Emergency_Contact_PersonErr, setEmergency_Contact_PersonErr] =
    useState(false);
  const [Emergency_Phone_NoErr, setEmergency_Phone_NoErr] = useState(false);
  const [Education_QualificationErr, setEducation_QualificationErr] =
    useState(false);
  const [SpecializationErr, setSpecializationErr] = useState(false);
  const [Previous_ExperienceErr, setPrevious_ExperienceErr] = useState(false);
  const [Date_of_JoinErr, setDate_of_JoinErr] = useState(false);
  const [Date_of_ResignationErr, setDate_of_ResignationErr] = useState(false);
  const [Name_of_BankErr, setName_of_BankErr] = useState(false);
  const [Account_NoErr, setAccount_NoErr] = useState(false);
  const [IFSC_CodeErr, setIFSC_CodeErr] = useState(false);
  const [BranchNameErr, setBranchNameErr] = useState(false);
  const [CTCErr, setCTCErr] = useState(false);
  const [MGSErr, setMGSErr] = useState(false);
  const [UANErr, setUANErr] = useState(false);
  const [EPF_NoErr, setEPF_NoErr] = useState(false);
  const [InsuranceErr, setInsuranceErr] = useState(false);
  const [ESICErr, setESICErr] = useState(false);
  const [Aadhar_NoErr, setAadhar_NoErr] = useState(false);
  const [Pan_NoErr, setPan_NoErr] = useState(false);
  const [Employee_PhotoErr, setEmployee_PhotoErr] = useState(false);
  const [Employee_DocumnetErr, setEmployee_DocumnetErr] = useState(false);

  const [empDetails, setEmpDetails] = useState({
    name: "",
    emp_id: "",
    department: "",
    designation: "",
    district: "",
    address_1: "",
    state: "",
    taluk: "",
    pin_code: "",
    telephone: "",
    mobile: "",
    email_id: "",
    father: "",
    mother: "",
    spouse_name: "",
    blood_group: "",
    emergency_contact_name: "",
    emergency_contact: "",
    qualification: "",
    specialisation: "",
    prev_exp: "",
    doj: "",
    resignation_date: "",
    bank_name: "",
    account_no: "",
    ifsc: "",
    branch: "",
    cug: "",
    mgs: "",
    uan: "",
    epf: "",
    insurence: "",
    esi: "",
    aadhar: "",
    pan: "",
  });
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  const emp_id = location?.state?.id;

  // department = departmentList
  //   ?.filter((depart) => depart?.id === empDetails?.designation)
  //   .reduce((acc, fil_depar) => fil_depar?.name, null);
  // console.log(
  //   "🚀 ~ file: EmployeeView.js:27 ~ EmployeeView ~  department:",
  //   department
  // );

  // designation = designationList
  //   ?.filter((design) => design?.id === empDetails?.department)
  //   .reduce((acc, fil_design) => fil_design?.name, null);

  useEffect(() => {
    api.employee_create_data().then((res) => {
      if (res?.status === "success") {
        setDepartmentList(res?.departments);
        setDesinationList(res?.designations);
      }
    });
  }, []);

  const getView = () => {
    api.employee_view(emp_id).then((res) => {
      let data = res?.employee;
      // console.log(data?.designation_detail?.id);
      setDepartment(data?.department_detail?.id);
      setDesignation(data?.designation_detail?.id);
      setDistrict(data?.address?.district);
      setAddress(data?.address?.address_1);
      setAddress2(data?.address?.address_2);
      setState(data?.address?.state);
      setTaluk(data?.address?.taluk);
      setPincode(data?.address?.pin_code);
      setTelephone_No(data?.details?.telephone);
      setMobileNo(data?.mobile);
      setMail(data?.email_id);
      setFatherName(data?.details?.father);
      setMotherName(data?.details?.mother);
      setSpouseName(data?.details?.spouse_name);
      setBloodGroup(data?.details?.blood_group);
      setEmergency_Contact_Person(data?.details?.emergency_contact_name);
      setEmergency_Phone_No(data?.details?.emergency_contact);
      setEducation_Qualification(data?.details?.qualification);
      setSpecialization(data?.details?.specialisation);
      setPrevious_Experience(data?.details?.prev_exp);
      setDate_of_Join(data?.doj);
      setDate_of_Resignation(data?.resignation_date);
      setName_of_Bank(data?.bank_details?.bank_name);
      setAccount_No(data?.bank_details?.account_no);
      setIFSC_Code(data?.bank_details?.ifsc);
      setCTC(data?.details?.cug);
      setMGS(data?.details?.mgs);
      setUAN(data?.proofs?.uan);
      setEPF_No(data?.proofs?.epf);
      setBranchName(data?.bank_details?.branch);
      setInsurance(data?.proofs?.insurence);
      setESIC(data?.proofs?.esi);
      setAadhar_No(data?.proofs?.aadhar);
      setPan_No(data?.proofs?.pan);
      setEmpDetails(res?.employee);
      setName(data?.name);
      setEmployeeId(data?.emp_id);
      setEmployee_Photo(data?.image);
      setEmployee_Documnet(data?.proofs?.proof_document);
      setEmployeeLevel(data?.employee_level);
    });
  };

  console.log("employeeLevel", employeeLevel);

  useEffect(() => {
    getView();
  }, [emp_id]);

  const SubmitHandle = () => {
    let formdata = new FormData();

    formdata.append("employee_id", emp_id);
    formdata.append("address1", Address);
    formdata.append("address2", Address2);
    formdata.append("taluk", Taluk);
    formdata.append("district", District);
    formdata.append("state", State);
    formdata.append("pincode", Pincode);
    formdata.append("blood_group", BloodGroup);
    formdata.append("emergency_contact", Emergency_Phone_No);
    formdata.append("emergency_contact_name", Emergency_Contact_Person);
    formdata.append("qualification", Education_Qualification);
    formdata.append("previous_exp", Previous_Experience);
    formdata.append("doj", Date_of_Join);
    formdata.append("uan", UAN);
    formdata.append("epf", EPF_No);
    formdata.append("aadhar", Aadhar_No);
    formdata.append("pan", Pan_No);
    formdata.append("bank_name", Name_of_Bank);
    formdata.append("ifsc", IFSC_Code);
    formdata.append("account_no", Account_No);
    formdata.append("id_card", empDetails?.id_card);
    formdata.append("offer_letter", empDetails?.offer_letter);
    formdata.append("emp_type", empDetails?.emp_type);
    formdata.append("designation", Designation);
    formdata.append("department", Department);
    formdata.append("esi", ESIC);
    formdata.append("insurence", Insurance);
    formdata.append("mgs", MGS);
    formdata.append("cug", CTC);
    formdata.append("branch", BranchName);
    formdata.append("specialisation", Specialization);
    formdata.append("spouse_name", SpouseName);
    formdata.append("mother", MotherName);
    formdata.append("father", FatherName);
    formdata.append("email_id", Mail);
    formdata.append("mobile", Mobile_No);
    formdata.append("telephone", Telephone_No);

    if (employeeLevel) {
      formdata.append("employee_level", employeeLevel);
    } else {
      formdata.append("employee_level", 0);
    }

    if (Date_of_Resignation) {
      formdata.append("resignation_date", Date_of_Resignation);
    }

    if (Employee_Photo?.name) {
      formdata.append("profile_image", Employee_Photo);
    }

    if (Employee_Documnet?.name) {
      formdata.append("proof_document", Employee_Documnet);
    }

    // formdata.append("projects", IFSC_Code);
    // formdata.append("profile_image", IFSC_Code);

    if (
      Name?.length == 0 ||
      Mail?.length == 0 ||
      EmployeeId?.length == 0 ||
      Department?.length == 0 ||
      Designation?.length == 0 ||
      District?.length == 0 ||
      Address?.length == 0 ||
      Address2?.length == 0 ||
      State?.length == 0 ||
      Taluk?.length == 0 ||
      Pincode?.length == 0 ||
      // Telephone_No?.length == 0 ||
      Mobile_No?.length == 0 ||
      Mail?.length == 0 ||
      // FatherName?.length == 0 ||
      // MotherName?.length == 0 ||
      // SpouseName?.length == 0 ||
      BloodGroup?.length == 0 ||
      Emergency_Contact_Person?.length == 0 ||
      Education_Qualification?.length == 0 ||
      // Specialization?.length == 0 ||
      Previous_Experience?.length == 0 ||
      Date_of_Join?.length == 0 ||
      Date_of_Resignation?.length == 0 ||
      Name_of_Bank?.length == 0 ||
      Account_No?.length == 0 ||
      IFSC_Code?.length == 0 ||
      BranchName?.length == 0 ||
      // CTC?.length == 0 ||
      // MGS?.length == 0 ||
      UAN?.length == 0 ||
      EPF_No?.length == 0 ||
      // Insurance?.length == 0 ||
      // ESIC?.length == 0 ||
      Aadhar_No?.length == 0 ||
      Pan_No?.length == 0
      // ||
      // Employee_Documnet?.length == 0 ||
      // Employee_Photo?.length == 0
    ) {
      setNameErr(true);
      setEmployeeIdErr(true);
      setDepartmentErr(true);
      setDesignationErr(true);
      setDistrictErr(true);
      setAddressErr(true);
      setAddress2Err(true);
      setStateErr(true);
      setTalukErr(true);
      setPincodeErr(true);
      // setTelephone_NoErr(true);
      setMobileNoErr(true);
      setMailErr(true);
      // setFatherNameErr(true);
      // setMotherNameErr(true);
      // setSpouseNameErr(true);
      setBloodGroupErr(true);
      setEmergency_Contact_PersonErr(true);
      setEmergency_Phone_NoErr(true);
      setEducation_QualificationErr(true);
      // setSpecializationErr(true);
      setPrevious_ExperienceErr(true);
      setDate_of_JoinErr(true);
      setDate_of_ResignationErr(true);
      setName_of_BankErr(true);
      setAccount_NoErr(true);
      setIFSC_CodeErr(true);
      setBranchNameErr(true);
      // setCTCErr(true);
      // setMGSErr(true);
      setUANErr(true);
      // setESICErr(true);
      setEPF_NoErr(true);
      setAadhar_NoErr(true);
      setPan_NoErr(true);
      // setEmployee_DocumnetErr(true);
      // setEmployee_PhotoErr(true);
    } else {
      api
        .employee_detail_save(formdata)
        .then((res) => {
          if (res?.status == "success") {
            alert(res?.message);
            navigate("/hrm/employee-management");
          }
        })
        .catch((err) => console.log("err", err));
    }
  };

  return (
    <Col xs={12} className="p-3">
      <Header />
      <Col
        xs={12}
        className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <h4> Employee Edit </h4>
        <Col xs={12} xxl={8} md={10} className="d-flex flex-wrap mx-auto">
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Name</label>
            <input
              type="text"
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              placeholder="Enter Name"
              value={Name}
              onChange={(e) => setName(e.target.value)}
              disabled
            />
            {Name?.length == 0 && NameErr && <p className="err">Enter Name</p>}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Employee Id</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={EmployeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
              disabled
            />
            {EmployeeId?.length == 0 && EmployeeIdErr && (
              <p className="err">Enter Employee Id</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Department</label>

            <select
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              value={Department}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              onChange={(e) => setDepartment(e.target.value)}>
              <option value={null}>Select Department</option>
              {departmentList &&
                departmentList?.map((department) => (
                  <option key={department?.id} value={department?.id}>
                    {department?.name}
                  </option>
                ))}
            </select>
            {Department?.length == 0 && DepartmentErr && (
              <p className="err">Select Department</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100"> Designation</label>
            <select
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              value={Designation}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              onChange={(e) => setDesignation(e.target.value)}>
              <option value={null}>Select Designation</option>
              {designationList &&
                designationList?.map((designation) => (
                  <option key={designation?.id} value={designation?.id}>
                    {designation?.name}
                  </option>
                ))}
            </select>
            {Designation?.length == 0 && DesignationErr && (
              <p className="err">Select Designation</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">District</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={District}
              onChange={(e) => {
                setDistrict(e.target.value);
              }}
            />
            {District?.length == 0 && DistrictErr && (
              <p className="err">Enter District</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Address 1</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            {Address?.length == 0 && AddressErr && (
              <p className="err">Enter Address 1</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Address 2</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Address2}
              onChange={(e) => {
                setAddress2(e.target.value);
              }}
            />
            {Address2?.length == 0 && Address2Err && (
              <p className="err">Enter Address 2</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">State</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={State}
              onChange={(e) => {
                setState(e.target.value);
              }}
            />
            {State?.length == 0 && StateErr && (
              <p className="err">Enter State</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Taluk</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Taluk}
              onChange={(e) => {
                setTaluk(e.target.value);
              }}
            />
            {Taluk?.length == 0 && TalukErr && (
              <p className="err">Enter Taluk</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Pincode</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Pincode}
              type="number"
              onChange={(e) => {
                setPincode(e.target.value);
              }}
            />
            {Pincode?.length == 0 && PincodeErr && (
              <p className="err">Enter Pincode</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Telephone No</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Telephone_No}
              onChange={(e) => {
                setTelephone_No(e.target.value);
              }}
            />
            {Telephone_No?.length == 0 && Telephone_NoErr && (
              <p className="err">Enter Telephone No</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Mobile No</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Mobile_No}
              onChange={(e) => {
                setMobileNo(e.target.value);
              }}
            />
            {Mobile_No?.length == 0 && Mobile_NoErr && (
              <p className="err">Enter Mobile No</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">E-Mail</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Mail}
              onChange={(e) => {
                setMail(e.target.value);
              }}
            />
            {Mail?.length == 0 && MailErr && <p className="err">Enter Mail</p>}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Father Name</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={FatherName}
              onChange={(e) => {
                setFatherName(e.target.value);
              }}
            />
            {FatherName?.length == 0 && FatherNamErr && (
              <p className="err">Enter Father Name</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Mother Name </label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={MotherName}
              onChange={(e) => {
                setMotherName(e.target.value);
              }}
            />
            {MotherName?.length == 0 && MotherNameErr && (
              <p className="err">Enter Mother Name</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Spouse Name </label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={SpouseName}
              onChange={(e) => {
                setSpouseName(e.target.value);
              }}
            />
            {SpouseName?.length == 0 && SpouseNameErr && (
              <p className="err">Enter Spouse Name</p>
            )}
          </Col>

          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Blood Group</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={BloodGroup}
              onChange={(e) => {
                setBloodGroup(e.target.value);
              }}
            />
            {BloodGroup?.length == 0 && BloodGroupErr && (
              <p className="err">Enter Blood Group</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Emergency Contact Person</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Emergency_Contact_Person}
              onChange={(e) => {
                setEmergency_Contact_Person(e.target.value);
              }}
            />
            {Emergency_Contact_Person?.length == 0 &&
              Emergency_Contact_PersonErr && (
                <p className="err">Enter Emergency Contact Person</p>
              )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Emergency Phone No</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Emergency_Phone_No}
              type="number"
              onChange={(e) => {
                setEmergency_Phone_No(e.target.value);
              }}
            />
            {Emergency_Phone_No?.length == 0 && Emergency_Phone_NoErr && (
              <p className="err">Enter Emergency Phone No</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Education Qualification</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Education_Qualification}
              onChange={(e) => {
                setEducation_Qualification(e.target.value);
              }}
            />
            {Education_Qualification?.length == 0 &&
              Education_QualificationErr && (
                <p className="err">Enter Education Qualification</p>
              )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label>Specialization</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Specialization}
              onChange={(e) => {
                setSpecialization(e.target.value);
              }}
            />
            {Specialization?.length == 0 && SpecializationErr && (
              <p className="err">Enter Specialization</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Previous Experience</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Previous_Experience}
              onChange={(e) => {
                setPrevious_Experience(e.target.value);
              }}
            />
            {Previous_Experience?.length == 0 && Previous_ExperienceErr && (
              <p className="err">Enter Previous Experience</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Date of Join</label>
            <input
              type={"date"}
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Date_of_Join}
              onChange={(e) => {
                setDate_of_Join(e.target.value);
              }}
            />
            {Date_of_Join?.length == 0 && Date_of_JoinErr && (
              <p className="err">Enter Date of Join</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Date of Resignation</label>
            <input
              type={"date"}
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Date_of_Resignation}
              onChange={(e) => {
                setDate_of_Resignation(e.target.value);
              }}
            />
            {Date_of_Resignation?.length == 0 && Date_of_ResignationErr && (
              <p className="err">Enter Date of Resignation</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Name of Bank</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Name_of_Bank}
              onChange={(e) => {
                setName_of_Bank(e.target.value);
              }}
            />
            {Name_of_Bank?.length == 0 && Name_of_BankErr && (
              <p className="err">Enter Name of Bank</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Account No</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Account_No}
              type="number"
              onChange={(e) => {
                setAccount_No(e.target.value);
              }}
            />
            {Account_No?.length == 0 && Account_NoErr && (
              <p className="err">Enter Account No</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">IFSC Code</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={IFSC_Code}
              onChange={(e) => {
                setIFSC_Code(e.target.value);
              }}
            />
            {IFSC_Code?.length == 0 && IFSC_CodeErr && (
              <p className="err">Enter IFSC Code</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Branch Name</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={BranchName}
              onChange={(e) => {
                setBranchName(e.target.value);
              }}
            />
            {BranchName?.length == 0 && BranchNameErr && (
              <p className="err">Enter Branch Name</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">CTC</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={CTC}
              type="number"
              onChange={(e) => {
                setCTC(e.target.value);
              }}
            />
            {CTC?.length == 0 && CTCErr && <p className="err">Enter CTC</p>}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">MGS</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={MGS}
              onChange={(e) => {
                setMGS(e.target.value);
              }}
            />
            {MGS?.length == 0 && MGSErr && <p className="err">Enter MGS</p>}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">UAN</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={UAN}
              onChange={(e) => {
                setUAN(e.target.value);
              }}
            />
            {UAN?.length == 0 && UANErr && <p className="err">Enter UAN</p>}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">EPF No</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={EPF_No}
              type="text"
              // type="number"
              onChange={(e) => {
                setEPF_No(e.target.value);
              }}
            />
            {EPF_No?.length == 0 && EPF_NoErr && (
              <p className="err">Enter EPF</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Insurance</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Insurance}
              onChange={(e) => {
                setInsurance(e.target.value);
              }}
            />
            {Insurance?.length == 0 && InsuranceErr && (
              <p className="err">Enter Insurance</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">ESIC</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={ESIC}
              onChange={(e) => {
                setESIC(e.target.value);
              }}
            />
            {ESIC?.length == 0 && ESICErr && <p className="err">Enter ESIC</p>}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Aadhar No</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Aadhar_No}
              type="text"
              onChange={(e) => {
                setAadhar_No(e.target.value);
              }}
            />
            {Aadhar_No?.length == 0 && Aadhar_NoErr && (
              <p className="err">Enter Aadhar</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Pan No</label>
            <input
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1"
              value={Pan_No}
              onChange={(e) => {
                setPan_No(e.target.value);
              }}
            />
            {Pan_No?.length == 0 && Pan_NoErr && (
              <p className="err">Enter Pan No</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            {/* <label className="w-100">Pan No</label>
            <p
              style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
              className="border-0 bg-lightBlue px-2 py-1 mt-1">
              {empDetails?.proofs?.pan ?? "-"}
            </p> */}
          </Col>

          <Col
            xs={12}
            sm={6}
            xl={6}
            className="inputinner-box text-start emp-view-doc-box">
            <label className="w-100 my-2">Employee Document</label>
            <iframe
              title="emp_document"
              src={
                Employee_Documnet?.name
                  ? window.URL.createObjectURL(Employee_Documnet)
                  : Employee_Documnet
              }
            />
            <input
              type="file"
              onChange={(e) => setEmployee_Documnet(e.target.files[0])}
            />
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start ">
            <label className="w-100 my-2">Employee Photo</label>
            <img
              style={{ maxWidth: "110px" }}
              src={
                Employee_Photo?.name
                  ? window.URL.createObjectURL(Employee_Photo)
                  : Employee_Photo
              }
              alt="emp_photo"
            />
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={(e) => setEmployee_Photo(e.target.files[0])}
            />
          </Col>
        </Col>
        <div className="d-flex">
          <button
            className="bg-primary border-0 ms-auto me-3 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2"
            onClick={SubmitHandle}>
            Submit
          </button>
        </div>
      </Col>
    </Col>
  );
}

export default EmployeeEdit;
