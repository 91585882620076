import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { api } from "../../constants/Services";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";

function PurchaseOrderActionPage() {
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const { id, po_no, po_date } = location?.state;

  useEffect(() => {
    api.purchaseOrderView(id).then((res) => {
      if (res?.status == "success") {
        setPurchaseOrderDetails(res?.purchase_orders?.purchase_order_details);
      }
    });
  }, [id]);

  return (
    <Col xs={12} lg={9} className="p-3">
      <Header />

      <Col
        style={{ overflowX: "scroll" }}
        className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center ">
        <h4>Purchase Order Details</h4>
        <Col className="emply-master-form py-2  mt-1 text-center">
          <div style={{ overflowX: "scroll" }} className="boq-table bg-white">
            <table className="table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>PO Number</th>
                  <th>PO Date</th>
                  {/* <th>Created At</th>  */}
                  <th>BOQ Code</th>
                  <th>Instruction</th>
                  <th>MISC</th>
                  <th>Net Value</th>
                  <th>Tolerance</th>
                  <th>Total Value</th>
                  <th>Material Name</th>
                  <th>Material Code</th>
                  {/* <th>Material Group</th> */}
                  <th>Material Type</th>
                  <th>Make</th>
                  <th>Modal</th>
                  <th>specification1</th>
                  <th>specification2</th>
                  <th>specification3</th>
                  <th>specification4</th>
                  <th>UOM</th>
                  <th>Required Quantity</th>

                  <th>Remarks</th>
                </tr>
              </thead>
              {purchaseOrderDetails?.length > 0 &&
                purchaseOrderDetails?.map((item, ind) => {
                  return (
                    <tr key={ind}>
                      <td>{ind + 1}</td>
                      <td>{po_no}</td>
                      <td>{po_date}</td>
                      {/* <td>
                        {moment(item?.material?.updated_at).format(
                          "DD MMMM yyy"
                        ) ?? "-"}
                      </td> */}
                      <td>{item?.boq_code ?? "-"}</td>
                      <td>{item?.instruction ?? "-"}</td>
                      <td>{item?.misc ?? "-"}</td>
                      <td>{item?.net_value ?? "-"}</td>
                      <td>{item?.tolerance ?? "-"}</td>
                      <td>{item?.total_value ?? "-"}</td>
                      <td>{item?.material?.name ?? "-"}</td>
                      <td>{item?.material?.material_code ?? "-"}</td>
                      <td>{item?.material?.group ?? "-"}</td>
                      {/* <td>{item?.material?.material_type ?? "-"}</td> */}
                      <td>{item?.material?.attribute?.make ?? "-"}</td>
                      <td>{item?.material?.attribute?.model ?? "-"}</td>
                      <td>
                        {item?.material?.attribute?.specification1 ?? "-"}
                      </td>
                      <td>
                        {item?.material?.attribute?.specification2 ?? "-"}
                      </td>
                      <td>
                        {item?.material?.attribute?.specification3 ?? "-"}
                      </td>
                      <td>
                        {item?.material?.attribute?.specification4 ?? "-"}
                      </td>
                      <td>{item?.uom ?? "-"}</td>
                      <td>{item?.qty ?? "-"}</td>

                      <td>{item?.remarks}</td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </Col>
        <div className="d-flex">
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-auto"
            onClick={() => navigate(-1)}>
            Close
          </button>
        </div>
      </Col>
    </Col>
  );
}

export default PurchaseOrderActionPage;
