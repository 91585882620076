import { Col } from "react-bootstrap";
import React from "react";
import DprTable from "./DprTable";
function DprTableSection() {
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center ">
      <DprTable />
      <Col sm={12} className="inputinner-box text-start">
        <button className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
          Submit
        </button>
        <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3">
          cancel
        </button>
      </Col>
    </Col>
  );
}

export default DprTableSection;
