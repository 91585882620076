import React, { useState, useEffect } from "react";
import eye_icon from "../../assets/icons/common/Eye.svg";
import moment from "moment";
// import edit_icon from "../../assets/icons/common/Edit.svg";
// import delete_icon from "../../assets/icons/common/Delete.svg";
import { useNavigate } from "react-router-dom";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";

const VendorDetailsScreen = () => {
  const [vendorList, setVendorList] = useState([]);
  const navigate = useNavigate();

  const vendorActionHandler = (sel_ven) => {
    const sel_ven_detail = vendorList?.filter(
      (vendor) => vendor?.id === +sel_ven
    );
    sel_ven_detail &&
      navigate("/procurement/vendor-action", { state: sel_ven_detail });
  };
  useEffect(() => {
    api.get_vendors().then((res) => {
      console.log("res-vendors-list", res);
      if (res?.status == "success") {
        setVendorList(res?.vendors);
      }
    });
  }, []);
  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <h4>Vendor Details</h4>
        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                {/* <th>Company Name</th>  */}
                <th>Vendor Name</th>
                <th>Email</th>
                <th>Code</th>
                <th>Rating</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {vendorList?.map((vendor, ind) => (
                <tr>
                  <td>{ind + 1}</td>
                  {/* <td>{vendor?.company_name}</td>  */}
                  <td>{vendor?.company_name}</td>
                  <td>{vendor?.email}</td>
                  <td>{vendor?.code}</td>
                  <td>{vendor?.rating}</td>
                  <td>
                    <div className="d-flex">
                      <img
                        onClick={() => vendorActionHandler(vendor?.id)}
                        style={{ width: "20px" }}
                        className="pointer ms-2"
                        src={eye_icon}
                        alt="eye-icon"
                      />
                      {/* <img
                        style={{ width: "15px" }}
                        className="pointer"
                        src={edit_icon}
                        alt="eye-icon"
                      />
                      <img
                        style={{ width: "15px" }}
                        className="pointer"
                        src={delete_icon}
                        alt="eye-icon"
                      /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col>
    </Col>
  );
};

export default VendorDetailsScreen;
