// export const BASE_URL = "http://192.168.1.20/crescon-backend-api/public/api";
// export const BASE_URL = "http://192.168.1.25/crescon-backend-api/public/api";
// export const BASE_FILE_URL = "http://192.168.1.25/crescon-backend-api/public/";

// export const BASE_URL = "http://appscript-develop.cresconprojects.com/api/"; // develop
// export const BASE_FILE_URL = "http://appscript-develop.cresconprojects.com/"; // develop

// export const BASE_URL = "http://192.168.1.26/crescon-backend-api/public/api"; // sujith sir
// export const BASE_FILE_URL = "http://192.168.1.26/crescon-backend-api/public/"; // sujith sir
//
// export const BASE_URL = "http://localhost/crescon-backend-api/public/api"; // sujith  sir build
// export const BASE_FILE_URL = "http://localhost/crescon-backend-api/public/"; // sujith  sir build

// export const BASE_URL = "http://202.88.218.163/index.php/api/";
// export const BASE_URL = "http://192.168.1.11/crescon-backend-api/public/api/";

// http://192.168.1.50/cresscon/public/api

// export const BASE_URL = "http://192.168.1.40/crescon-backend-api/public/api/";

//  http://192.168.1.33/crescon-backend-api/public/api/notifications

// http://localhost/crescon-backend-api/public/api/notifications

export const BASE_URL = "http://erp-script.cresconprojects.com/public/api/"; // live
export const BASE_FILE_URL = "http://erp-script.cresconprojects.com/public/"; // live

// export const BASE_URL = "http://192.168.1.40/cresscon/public/api";

// export const BASE_URL = "http://192.168.1.46/cresscon/public/api/";

//erp-script.cresconprojects.com/public/api

// export const BASE_URL = "https://cresscon.premiumfreshfoods.com/public/api/";

// export const BASE_URL = "http://192.168.1.37/cresscon/public/api"; // karthishwari akka
// export const BASE_FILE_URL = "http://192.168.1.37/cresscon/public/"; // karthishwari akka

// export const BASE_URL = "http://192.168.1.54/crescon-backend-api/public/api"; // maha akka

export const URL = {
  // LOGIN
  LOGIN: "login",

  // COMPANY
  COMPANY_LIST: "company-list",
  COMPANY_ADD: "add-company",
  COMPANY_EDIT: "update-company/",
  COMPANY_VIEW: "company-view/",
  COMPANY_STATUS_CHANGE: "company-status-change/",

  // EMPLOYEE
  EMPLOYEE_ADD: "employee/store",
  EXPLOYEE_CREATE_DATA: "employee/create",
  EXPLOYEE_DETAIL_SAVE: "employee/detail-store",
  LOCKED_EMPLOYEES: "locked-employees",
  UNLOCK_EMPLOYEE: "unlock-employee",
  EMPLOYEE_VIEW: "employee/view/",
  EMPLOYEE_ROLE_ASSIGN: "employee/role-assign",
  EMPLOYEE_LIST: "employees",
  EMPLOYEE_STATUS_CHANGE: "employee-status",
  EXPLOYEE_RESIGNATION: "employee-status/",
  EXPLOYEE_CHANGE_PASSWORD: "employee-change-password",

  // EMPLOYEE PROJECTS

  EMPLOYEE_PROJECTS: "employee-projects",

  EMPLOYEE_PROFILE_SELECT: "employee-profile-select",

  // EMPLOYEE PERMISSION
  EMPLOYEE_PERMISSION: "employee/permissions",

  // MODULES
  MODULE_LIST: "modules-list",
  MODULE_PERMISSION: "modules-permissions",

  // PROCUREMENT
  PROJECT_CREATE: "project/create",
  // VENDOR
  VENDOR_VIEW: "vendor/view",
  VENDOR_CREATE: "vendor/create",
  VENDOR_SEARCH: "vendor/search",
  VENDOR_GST: "vendor/gst/create",
  VENDOR_IMPORT: "vendors-import",
  VENDOR_UPDATE: "vendor/create",

  // MATERIAL AND ASSESTS SEARCH
  MATERIAL_ASSESTS_SEARCH: "search",

  // MATERIAL AND ASSESTS SPECIFIC SEARCH
  MATERIAL_ASSESTS_SPECIFIC_SEARCH: "search-result/",

  // MATERIAL AND ASSESTS SPECIFIC SEARCH
  MATERIAL_ASSESTS_ADD: "material",
  // MATERIAL AND ASSESTS SPECIFIC SEARCH
  MATERIAL_ASSESTS_UPDATE: "material/",

  // MATERIAL SHOW
  MATERIAL_SHOW: "material/",

  // MATERIAL_ATTRIBUTE ADD
  MATERIAL_ATTRIBUTE_ADD: "material-attribute",
  MATERIAL_LIST: "material",
  MATERIAL_UPDATE: "material",
  MATERIAL_REQUEST_UPDATE: "material-request/update/",

  //PROJECT
  PROJECT_ADD: "project/create",
  PROJECT_UPDATE: "project-update",
  PROJECT_LIST_WITH_NO_BOQ: "projects",
  PROJECT_LIST: "boq-projects",
  PROJECT_BOQ_ADD: "project-boq",
  PROJECT_SHOW: "project/view/",
  PROJECT_COMPARE: "project-compare/",

  NOTIF_COMPARE: "project-boq-compare/",
  BOQLIST: "all-boq-list/",
  BOQVIEW: "boq-view/",

  PROJECT_STATUS: "project-status/",
  PROJECTS_ASSETS_LIST: "asset-projects",

  //VENDOR

  // MR

  MATRIAL_PRODUCT: "material-requests",
  MATRIAL_PRODUCT_PO: "materials-requests",

  MATRIAL_REQUEST_DETAILS: "material-requests/details",

  // PURCHASE ORDER
  PURCHASE_ORDER_LIST: "purchase-orders",
  PURCHASE_ORDER_CREATE: "purchase-order/create",
  PURCHASE_ORDER_VIEW: "purchase-order/view/",
  PURCHASE_ORDER_ANNEXURE_DOWNLOAD: "annexure-import",
  DIRECT_PO_MATERIAL_REQUEST: "directpo-material-requests",

  //MATERIAL-REQUEST REQUESTION TYPE
  THROUGH_BOQ: "through-boqs/",
  THROUGH_BOQ_WITHOUT_PAGINATION: "through-boqs-detail/",
  THROUGH_MATERIAL: "through-materials",
  THROUGH_DIRECT_PO: "through-direct-pos/",
  // MR CODE VIEW
  BOQ_VIEW: "boq-code-view/",
  MATERIAL_VIEW: "material-code-view/",

  // MATERIAL REQUEST CHECKED
  BOQ_CHECKED_LIST: "through-boq-list",
  MATERIAL_CHECKED_LIST: "through-material-list",

  //MATERIAL REQUEST
  STORE_MATERIAL_REQUEST: "material-request/store",
  PROJECT_MATERIAL_REQUEST: "project/material-requests/",
  MATERIAL_REQUEST_VIEW: "material-request/",
  MATERIAL_REQUEST_LIST: "material-requests",
  MATERIAL_REQUEST_PROJECT_LIST: "boq-projects",

  // NOTIFICATION

  NOTIFICATION: " notifications",
  NOTIFICATION_STATUS: "notifications/status-change",
  NOTIFICATION_REJECT: "rejected-list",
  MESSAGE_LIST: "messages",

  // HSN

  HSN_CODES: "hsn-codes",

  // materials-import

  MATERIAL_IMPORT: "materials-import",

  ASSETS_IMPORT: "assets-import",

  WORK_ORDER_IMPORT: "work_order-annexure-import",

  HSN_MATERIAL_IMPORT: "hsn-codes/import",

  // DIRECT PO

  DIRECT_PO: "directpo-material-requests",

  // GRN
  GRN_LIST: "grn-list",
  GRN_ADD: "grn-create",
  GRN_VIEW: "grn-view/",
  GRN_DETAILS_VIEW: "grn-project-detail/",
  GRN_PO_LIST: "purchase-orders-list",

  // INVOICE
  INVOICE_LIST: "invoice-list",
  INVOICE_CREATE: "invoice-create",
  INVOICE_VIEW: "invoice-view/",
  INVOICE_BOOKING_NBI: "nbi-project-detail/",

  // WAREHOUSE
  WAREHOUSE: "warehouse",
  WAREHOUSE_PROJECT_VIEW: "warehouse-project/",

  // PO METRIALS
  PO_METRIALS: "po-material",

  // ALL PROJECTS
  ALL_PROJECTS: "project-list",

  // MATERIAL ISSUE
  MATERTIAL_ISSUE_LIST: "material-issue-list",
  MATERTIAL_ISSUE_CREATE: "material-issue-create",
  MATERTIAL_ISSUE_VIEW: "material-issue-view/",
  MATERTIAL_ISSUE_VIEW_PROJECT: "project-material-issue/",
  MATERIAL_STOCK_QTY: "get-stock-qty",
  MATERIAL_ISSUE_GET_MATERIAL: "grn-material",
  GET_MATERIAL_CUMULATIVE: "get/cumulative",

  // INTERN RETURN
  INTERN_RETURN_LIST: "internal-return-list",
  INTERN_RETURN_CREATE: "internal-return-create",
  INTERN_RETURN_VIEW: "internal-return-view/",

  // MATERIAL TRANSFER
  MATERTIAL_TRANSFER_LIST: "material-transfer-list",
  MATERTIAL_TRANSFER_CREATE: "material-transfer-create",
  MATERTIAL_TRANSFER_VIEW: "material-transfer-view/",
  MATERIAL_TRANSFER_LISTS: "material-transfer",
  MATERIAL_TRANSFER_PROJECTS: "material-transfer-project/",
  MATERIAL_TRANSFER_PROJECTS_NEW: "material-transfer-projects/",
  MATERIAL_AVAILABLE_STOCK: "get-available-stock",

  // MATERIAL RECEIPT
  MATERTIAL_RECEIPT_LIST: "material-receipt-list",
  MATERTIAL_RECEIPT_CREATE: "material-receipt-create",
  MATERTIAL_RECEIPT_VIEW: "material-receipt-view/",
  MATERIAL_RECEIPT_LISTS: "material-transfer",

  // MATERIAL RETURN
  MATERTIAL_RETURN_LIST: "material-return-list",
  MATERTIAL_RETURN_CREATE: "material-return-create",
  MATERTIAL_RETURN_VIEW: "material-return-view/",
  MATERIAL_RETURN_LISTS: "get-project-grn",
  GET_PROJCET_MATERIAL_STOCK: "get-project-material-stock",

  // DAY END PROCESS
  RUN_MODULE: "run-module",
  EMPLOYEE_STATUS: "employee-status",

  // WORK ORDER
  WORK_ORDER_CREATE: "work-order-create",
  WORK_ORDER_LIST: "work-order-list",
  WORK_ORDER: "work-order",
  WORK_ORDER_UPDATE: "work-order-update",
  WORK_ORDER_VIEW: "work-order-view/",
};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";

export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_NO = /^[6-9]{1}[0-9]{9}$/;

export const NUMBERS_ONLY = /^[0-9\b]+$/;
export const validPassword = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$");
export const validMobilNo = new RegExp("^[6-9]{1}[0-9]{9}$");
export const validText = new RegExp("^[a-zA-Z ]*$");
export const validAadharNumber = new RegExp("^[0-9]{12}$");
