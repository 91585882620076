import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import CompanyManagement from "../../component/UserManagement/CompanyManagement";

const CompanyMaster = () => {
  const [employeeDetail, setEmployeeDetail] = useState();
  const [reset, setReset] = useState(false);
  return (
    <Col xs={12} className="p-3">
      <Header />
      <CompanyManagement />
    </Col>
  );
};

export default CompanyMaster;
