import React from "react";
import { Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Home from "../component/Home/Home";

const HomeScreen = () => {
  return (
    <Col xs={12}>
      <Home />
    </Col>
  );
};

export default HomeScreen;
