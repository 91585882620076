import React from "react";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import IsmMaterialRequestTableSection from "../../component/ism/IsmMaterialRequest/IsmMaterialRequestTableSec";
import IsmMaterialRequestForm from "../../component/ism/IsmMaterialRequest/IsmMaterialRequestForm";
const IsmMaterialRequestScreen = () => {
  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />

      <IsmMaterialRequestForm />
      <IsmMaterialRequestTableSection />
    </Col>
  );
};

export default IsmMaterialRequestScreen;
