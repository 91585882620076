import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import moment from "moment";
import { IoTrashBinOutline } from "react-icons/io5";
import { IoTrashBin } from "react-icons/io5";
import { api } from "../../constants/Services";
import { Col, Tab } from "react-bootstrap";
import Header from "../../component/Header/Header";

function MaterialRequestEditPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [material_type, setMaterial_type] = useState({});
  const [materialRequestDetails, setMaterialRequestDetails] = useState([]);
  const [matId, setMatId] = useState();
  const [list, setList] = useState();

  const material_request_id = location?.state?.id;
  const project_id = location?.state?.proId;

  const getView = (id) => {
    // console.log("id", id);
    let data = {
      material_request_id: id,
    };
    api.matrialRequest(data).then((res) => {
      if (res?.status == "success") {
        // setMaterialRequestDetails(
        //   res?.material_request?.material_request_details
        // );
        // console.log("res", res);
        setMaterial_type(res?.material_request);
        setMaterialRequestDetails(res?.material_request_list);
      }
    });
  };

  useEffect(() => {
    if (material_request_id) {
      getView(material_request_id);
    }
  }, []);

  const getList = () => {
    let data = {
      project_id: searchParams?.get("proId"),
    };
    api.matrialList(data).then((res) => {
      if (res?.status == "success") {
        setList(res?.material_requests);
      }
    });
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      getList();
      getView(searchParams.get("id"));
    }
  }, [searchParams.get("id")]);

  const handleChange = (event, ind, type, mr_quantity) => {
    let temp = [...materialRequestDetails];

    // console.log("mr_quantity", mr_quantity);

    if (material_type?.request_type == 1 && +event > +mr_quantity) {
      temp[ind] = {
        ...temp[ind],
        [type]: event,
        error: 1,
      };
    } else {
      temp[ind] = {
        ...temp[ind],
        [type]: event,
        error: 0,
      };
    }

    setMaterialRequestDetails(temp);
  };

  console.log("material_request_id", material_request_id);

  const [checkbox, setCheckbox] = useState([]);
  const handleCheckbox = (event, ind) => {
    let temp = [...checkbox];
    if (temp.includes(event)) {
      temp.splice(ind, 1);
    } else {
      temp.push(event);
    }
    setCheckbox(temp);
  };

  const saveHanlder = () => {
    let formdata = new FormData();
    let count = 1;

    formdata.append(`project_id`, material_type?.project_id);

    if (checkbox?.length > 0) {
      checkbox?.map((item, ind) => {
        formdata.append(
          `deleted_details[${ind}][material_request_detail_id]`,
          item
        );
      });
    }

    let temp = [];

    if (materialRequestDetails?.length > 0) {
      materialRequestDetails?.map((item, ind) => {
        if (!checkbox?.includes(item?.id)) {
          temp.push(item);
        }
      });
    }

    temp.map((item, ind) => {
      if (item?.error) {
        count = count + 1;
      }
      formdata.append(
        `updated_details[${ind}][material_request_detail_id]`,
        item?.id
      );

      formdata.append(
        `updated_details[${ind}][material_id]`,
        item?.material?.id
      );
      formdata.append(
        `updated_details[${ind}][required_quantity]`,
        item?.required_quantity
      );
    });

    if (count == 1) {
      api
        .materialRequestUpdate(material_request_id, formdata)
        .then((res) => {
          if (res?.status == "success") {
            alert(res?.message);
            navigate("/notification");
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  // console.log("materialRequestDetails", materialRequestDetails);

  return (
    <Col xs={12} className="p-3">
      <Header />
      <Col
        style={{ overflowX: "scroll" }}
        className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center "
      >
        <h4>Material Request Update</h4>
        <Col className="emply-master-form py-2  mt-1 text-center">
          <div style={{ overflowX: "scroll" }} className="boq-table  bg-white">
            <table className="table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>BOQ Code</th>
                  <th>Material Name</th>
                  <th>Material Code</th>
                  <th>Material Type</th>
                  <th>UOM</th>
                  {/* <th>Basic Price</th> */}
                  <th>Required Quantity</th>
                  <th>Created By</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              {materialRequestDetails?.length > 0 &&
                materialRequestDetails?.map((item, ind) => {
                  return (
                    <tr key={ind}>
                      <td style={{ fontSize: "12px" }}>
                        <div className="d-flex  align-items-center gap-2">
                          <p>{ind + 1}</p>
                          {/* <input
                            type={"checkbox"}
                            onChange={() => handleCheckbox(item?.id, ind)}
                            checked={checkbox?.includes(item?.id)}
                          /> */}
                          <div onClick={() => handleCheckbox(item?.id, ind)}>
                            {checkbox?.includes(item?.id) ? (
                              <IoTrashBin fontSize={15} />
                            ) : (
                              <IoTrashBinOutline fontSize={15} />
                            )}
                          </div>
                        </div>
                      </td>
                      <td style={{ fontSize: "12px" }}>
                        {item?.boq_code ?? "-"}
                      </td>
                      <td
                        style={{
                          textAlign: "start",
                          paddingLeft: "5px",
                          fontSize: "12px",
                        }}
                      >
                        {item?.material?.name ?? "-"}
                      </td>
                      <td style={{ fontSize: "12px" }}>
                        {item?.material?.material_code ?? "-"}
                      </td>
                      <td style={{ fontSize: "12px" }}>
                        {material_type?.material_type ?? "-"}
                      </td>
                      <td style={{ fontSize: "12px" }}>{item?.uom ?? "-"}</td>
                      {/* <td style={{fontSize:"12px"}}>{item?.basic_price ?? "-"}</td> */}
                      <td style={{ fontSize: "12px" }}>
                        <input
                          className="border-0 w-100"
                          value={item?.required_quantity}
                          type={"number"}
                          onChange={(e) =>
                            handleChange(
                              e.target.value,
                              ind,
                              "required_quantity",
                              // +item?.mr_quantity < +item?.cumulative_quantity
                              //   ? Math.abs(
                              //       +item?.cumulative_quantity - +item?.qty
                              //     )
                              //   : item?.mr_quantity
                              +item?.total_quantity - +item?.approved_mr_qty
                            )
                          }
                        />
                        {item?.error == 1 && (
                          <p className="err" style={{ color: "#ff0000" }}>
                            More than Avaliable Qty
                          </p>
                        )}
                      </td>
                      <td className="text-center" style={{ fontSize: "12px" }}>
                        {material_type?.created_user?.name}
                      </td>
                      <td style={{ fontSize: "12px" }}>{item?.remarks}</td>
                    </tr>
                  );
                })}
            </table>
          </div>
        </Col>
      </Col>
      <div className="text-end">
        <button
          className="bg-success border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 mx-2"
          // onClick={() => navigate("/notification")}
          onClick={() => saveHanlder()}
        >
          Save
        </button>
        <button
          className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-auto"
          onClick={() => navigate("/notification")}
        >
          Close
        </button>
      </div>
    </Col>
  );
}

export default MaterialRequestEditPage;
