// @ts-nocheck
import React, { useState, useEffect } from "react";
import StatusModal from "../StatusModal";
import { Col } from "react-bootstrap";
import Select from "react-select";
import { validPassword } from "../../constants/Services/constants";
import { tickIcon } from "../../assets/img";
import { api } from "../../constants/Services";
// import { applicationType, department } from "../../constants/StaticData";
import { MdArrowDropDown } from "react-icons/md";
import { IoIosRadioButtonOff } from "react-icons/io";
import { IoIosRadioButtonOn } from "react-icons/io";

const static_attributes = [
  {
    id: 1,
    list: "Size",
    check: <IoIosRadioButtonOn className="primary" />,
    uncheck: <IoIosRadioButtonOff className="primary" />,
  },
  {
    id: 2,
    list: "Color",
    check: <IoIosRadioButtonOn className="primary" />,
    uncheck: <IoIosRadioButtonOff className="primary" />,
  },
  {
    id: 3,
    list: "Material",
    check: <IoIosRadioButtonOn className="primary" />,
    uncheck: <IoIosRadioButtonOff className="primary" />,
  },
  {
    id: 4,
    list: "Style",
    check: <IoIosRadioButtonOn className="primary" />,
    uncheck: <IoIosRadioButtonOff className="primary" />,
  },
];

const RolesRespForm = ({
  employeeId,
  employeeDepartment,
  setEmployeeDepartment,
  employeeDesination,
  setEmployeeDesination,
  password,
  setPassword,
  checkbox,
  setcheckbox,
  setApproveBox,
  approveBox,
  empRole,
  setEmpRole,
  setEmployeeId,
  employeeName,
  setEmployeeName,
  subEmployeeDepartment,
  setSubEmployeeDepartment,
  passErr,
  setPassErr,
  notibox,
  setnotibox,
  setPassAlaErr,
  setEmployeeProjects,
  employeeProjects,
}) => {
  const [employeesList, setEmployeesList] = useState([]);
  const [renderingEmpList, setRenderingEmpList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [showDrop, setShowDrop] = useState(false);
  const [empShowId, setEmpShowId] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesinationList] = useState([]);
  const [showStatus, setShowStatus] = useState(false);
  const [subDepartmentList, setSubDepartmentList] = useState([]);
  const [notify, setNotify] = useState(false);
  const [selectALL, setSelectALL] = useState(true);
  const [selectALLSub, setSelectALLSub] = useState(true);

  const [projectValue, setProjectValue] = useState([]);
  const [showStatAttributes, setShowStatAttributes] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueSub, setSearchValueSub] = useState("");
  const [listProjects, setListProjects] = useState([]);

  useEffect(() => {
    setListProjects(static_attributes);
  }, []);

  const statusModalClose = () => {
    setShowStatus(!showStatus);
  };

  const empIdSearchHandler = (e) => {
    let renderedList = employeesList?.filter((emp) =>
      emp?.emp_id.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setRenderingEmpList(renderedList);
  };

  const subDepartment = [
    "ELECTRICAL",
    "CIVIL",
    "MECHANICAL",
    "HVAC",
    "FIRE FIGHTING",
    "ELV",
    "OTHERS",
    "PHE",
    "PROCESS PIPE",
    "SOLVENT PIPE",
    "ASSETS",
  ];

  useEffect(() => {
    // let temp = [];

    // subDepartment?.map((item, ind) => {
    //   temp.push({ value: item, label: item });
    // });

    setSubDepartmentList(subDepartment);
  }, []);

  const getProjectList = () => {
    api
      .allProjects()
      .then((res) => {
        let temp = [];
        res?.all_projects?.map((item, ind) => {
          temp.push({ value: item?.id, label: item?.name });
        });
        // setProjectList(temp);
        setProjectList(res?.all_projects);
        setListProjects(res?.all_projects);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProjectList();
    getEmployessList();
  }, []);

  const notifyChanger = () => {
    setnotibox({ level_1: false, level_2: false, level_3: false });
    setNotify(!notify);
  };

  const notifiBoxChanger = (type) => {
    if (type == "level_1") {
      setnotibox({ level_1: true, level_2: false, level_3: false });
    } else if (type == "level_2") {
      setnotibox({ level_1: false, level_2: true, level_3: false });
    } else if (type == "level_3") {
      setnotibox({ level_1: false, level_2: false, level_3: true });
    }
  };

  const getEmployessList = () => {
    api.employee_create_data().then((res) => {
      if (res?.status === "success") {
        setEmployeesList(res?.employees);
        setRenderingEmpList(res?.employees);
        setDepartmentList(res?.departments);
        setDesinationList(res?.designations);
      }
    });
  };

  const resRoleSelectHandler = (e) => {
    setEmpRole(e.target.value);
  };

  const getEmployeeDetail = (id) => {
    api.employee_view(id).then((res) => {
      let temp = [];
      let Projects = [];
      let ProjectsName = [];
      console.log("response-emp-detail", res?.employee);
      setEmployeeName(res?.employee?.name);
      setEmpRole(res?.employee?.role);
      setEmployeeDepartment(res?.employee?.department);
      setEmployeeDesination(res?.employee?.designation);
      res?.employee?.material_departments?.map((item, ind) => {
        if (item !== null) {
          // temp.push({
          //   value: item,
          //   label: item,
          // });
          temp.push(item);
        }
      });
      res?.emp_projects?.map((item, ind) => {
        if (item !== null) {
          Projects.push(item?.project?.id);
        }
      });
      res?.emp_projects?.map((item, ind) => {
        if (item !== null) {
          ProjectsName.push(item?.project?.name);
        }
      });

      setSubEmployeeDepartment(temp);
      setEmployeeProjects(Projects);
      setProjectValue(ProjectsName);
      if (res?.employee?.mobile_access === 1 && res?.employee?.status === 1) {
        setcheckbox({ ...checkbox, mobile: true, web: true });
      } else if (
        res?.employee?.mobile_access === 1 ||
        res?.employee?.status === 1
      ) {
        res?.employee?.mobile_access === 1
          ? setcheckbox({ ...checkbox, mobile: true })
          : setcheckbox({ ...checkbox, web: true });
      }
      if (res?.employee?.employee_level == 1) {
        setNotify(true);
        setnotibox({ level_1: true, level_2: false, level_3: false });
      } else if (res?.employee?.employee_level == 2) {
        setNotify(true);
        setnotibox({ level_1: false, level_2: true, level_3: false });
      } else if (res?.employee?.employee_level == 3) {
        setNotify(true);
        setnotibox({ level_1: false, level_2: false, level_3: true });
      }

      setPassAlaErr(false);
    });
  };

  // console.log("projectValue", projectValue);

  const resEmpIdSelHandler = (e) => {
    // console.log("e", e?.id);
    getEmployeeDetail(e?.id);
    setEmployeeId(e?.id);
    setEmpShowId(e?.emp_id);
    setShowDrop(!showDrop);
    setEmployeeName("");
    setEmployeeDepartment("");
    setEmployeeDesination("");
  };

  // console.log("employeeProjects", employeeProjects);

  const allSelect = () => {
    let employeeProject = [];
    let projectValues = [];
    if (selectALL) {
      listProjects?.map((item, ind) => {
        employeeProject.push(item?.id);
        projectValues.push(item?.name);
      });

      setProjectValue(projectValues);
      setEmployeeProjects(employeeProject);
    } else {
      setProjectValue(projectValues);
      setEmployeeProjects(employeeProject);
    }
  };

  const allSelectSub = () => {
    let projectValues = [];
    if (selectALLSub) {
      subDepartment?.map((item, ind) => {
        projectValues.push(item);
      });

      setSubEmployeeDepartment(projectValues);
    } else {
      setSubEmployeeDepartment(projectValues);
    }
  };

  const onStatAttributeSelect = (e, id) => {
    let val = e.length > 15 ? e.slice(0, 15) + "..." : e;
    // console.log("val", val);

    let temp = [...employeeProjects];

    if (temp.includes(id)) {
      const listtemp = temp.indexOf(id);
      const list = [...temp];
      list.splice(listtemp, 1);
      setEmployeeProjects(list);
    } else {
      setEmployeeProjects([...temp, id]);
    }

    if (projectValue.includes(e)) {
      const listtemp = projectValue.indexOf(e);
      const list = [...projectValue];
      list.splice(listtemp, 1);
      setProjectValue(list);
    } else {
      setProjectValue([...projectValue, e]);
    }

    setSearchValue("");
    setListProjects(projectList);
  };

  const onSubSelect = (event) => {
    let temp = [...subEmployeeDepartment];

    if (temp.includes(event)) {
      const listtemp = temp.indexOf(event);
      const list = [...temp];
      list.splice(listtemp, 1);
      setSubEmployeeDepartment(list);
    } else {
      setSubEmployeeDepartment([...temp, event]);
    }
  };

  // console.log("employeeProjects", employeeProjects);

  const searchProject = (event) => {
    let temp = [...projectList];

    let search = temp.filter((value) =>
      value?.name?.toLowerCase()?.includes(event.toLowerCase())
    );

    if (event?.length > 0) {
      setListProjects(search);
    } else {
      setListProjects(projectList);
    }

    // console.log("search", search);
    // console.log("event", event);

    setSearchValue(event);
  };

  const searchSub = (event) => {
    let temp = [...subDepartment];
    let search = temp.filter((value) =>
      value?.toLowerCase()?.includes(event.toLowerCase())
    );

    if (event?.length > 0) {
      setSubDepartmentList(search);
    } else {
      setSubDepartmentList(subDepartment);
    }

    // console.log("search", search);
    // console.log("event", event);

    setSearchValueSub(event);
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4>Roles & Responsibilities</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Employee ID</label>
          <p
            className="border-0 bg-lightBlue py-1 px-2 w-90 mt-1 mb-0 ms-0 pointer"
            onClick={() => setShowDrop(!showDrop)}
          >
            {empShowId ? empShowId : "select Employee"}
          </p>
          {showDrop && (
            <ul
              style={{
                maxHeight: "230px",
                overflow: "scroll",
                width: "90%",
                marginRight: "auto",
              }}
              className="border-0 bg-lightBlue px-2 py-1"
            >
              <li>
                <input
                  onChange={(e) => empIdSearchHandler(e)}
                  style={{ border: "1px solid #c2dcff" }}
                  className="bg-lightBlue rounded-1 px-2 py-1 w-100 my-2"
                />
              </li>
              {renderingEmpList?.map((employee) => (
                <li
                  onClick={() => resEmpIdSelHandler(employee)}
                  key={employee.id}
                  value={employee.id}
                  className="pointer fs-6"
                >
                  {employee.emp_id}
                </li>
              ))}
            </ul>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Name</label>
          <p className="border-0 bg-lightBlue py-1 px-2 w-90 mt-1 ms-0">
            {employeeName ? employeeName : "select Employee id"}
          </p>
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Department</label>
          <select
            style={{ height: "2.1rem", marginRight: "auto", width: "90%" }}
            className="border-0 bg-lightBlue px-2 my-1 py-1"
            value={employeeDepartment}
            onChange={(e) => setEmployeeDepartment(e.target.value)}
          >
            <option>Select Department</option>
            {departmentList?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </Col>
        {/* <Col
          xs={12}
          sm={6}
          xl={6}
          className="inputinner-box text-start"
          style={{ width: "45%" }}
        >
          <label className="w-100">Sub Department</label>
          <Select
            options={subDepartmentList}
            isMulti
            onChange={(e) => setSubEmployeeDepartment(e)}
            value={subEmployeeDepartment}
            className=" border-0 emply-mas-file-input px-1 h-50"
          />
        </Col> */}

        <Col
          xs={12}
          sm={6}
          xl={6}
          className="inputinner-box text-start custom_select"
          style={{ width: "45%" }}
        >
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Sub Department
            </p>
            <div className="position-relative">
              <p
                readOnly
                placeholder="Select Projects"
                type="text"
                // value={projectValue}
                style={{ height: "40px", overflow: "auto" }}
                onClick={() => setShowSub(true)}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust bg-lightBlue px-2 my-1 py-1"
              >
                {subEmployeeDepartment?.length > 0
                  ? subEmployeeDepartment?.toString()
                  : "Select Sub Department"}
              </p>
              <button
                className="drop_down cust-btn w-100 justify-content-end mx-2"
                onClick={() => {
                  setShowSub(true);
                }}
              >
                <MdArrowDropDown />
              </button>
              {showSub && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setShowSub(false)}
                />
              )}
              <div
                className={`${
                  showSub && "submenu_1 h-auto py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchSub(e.target.value)}
                    value={searchValueSub}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      setSelectALLSub(!selectALLSub);
                      allSelectSub();
                    }}
                  >
                    All
                  </button>
                </div>
                {subDepartmentList?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onSubSelect(item);
                      }}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                      >
                        {subEmployeeDepartment?.includes(item) ? (
                          <IoIosRadioButtonOn className="primary" />
                        ) : (
                          <IoIosRadioButtonOff className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </Col>
        {/* 
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Designation</label>
          <select
            style={{ height: "2.1rem", marginRight: "auto", width: "90%" }}
            className="border-0 bg-lightBlue px-2 my-1 py-1"
            value={employeeDesination}
            onChange={(e) => setEmployeeDesination(e.target.value)}
          >
            <option>Select Designation</option>
            {designationList?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </Col> */}
        {/* <Col
          xs={12}
          sm={6}
          xl={6}
          className="inputinner-box text-start custom_select"
          style={{ width: "45%" }}
        >
          <label className="w-100">Projects</label>
          <Select
            options={projectList}
            isMulti
            name="projects"
            onChange={(e) => setEmployeeProjects(e)}
            value={employeeProjects}
            className=" border-0 emply-mas-file-input px-1 h-50"
          />
        </Col> */}

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Designation</label>
          <select
            style={{ height: "2.1rem", marginRight: "auto", width: "90%" }}
            className="border-0 bg-lightBlue px-2 my-1 py-1"
            value={employeeDesination}
            onChange={(e) => setEmployeeDesination(e.target.value)}
          >
            <option>Select Designation</option>
            {designationList?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={6}
          xl={6}
          className="inputinner-box text-start custom_select"
          style={{ width: "45%" }}
        >
          <div className="w-xl-48 w-lg-48 w-md-100 w-sm-100 w-xs-100">
            <p className="black f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mt-1 ">
              Projects
            </p>
            <div className="position-relative">
              <p
                readOnly
                placeholder="Select Projects"
                type="text"
                // value={projectValue}
                style={{ height: "40px", overflow: "auto" }}
                onClick={() => setShowStatAttributes(true)}
                className="editBtn w-100 cp rounded-3 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust bg-lightBlue px-2 my-1 py-1"
              >
                {projectValue?.length > 0
                  ? projectValue?.toString()
                  : "Select Projects"}
              </p>
              <button
                className="drop_down cust-btn w-100 justify-content-end mx-2"
                onClick={() => {
                  setShowStatAttributes(true);
                }}
              >
                <MdArrowDropDown />
              </button>
              {showStatAttributes && (
                <div
                  className="invisible-cont2 z-0"
                  onClick={() => setShowStatAttributes(false)}
                />
              )}
              <div
                className={`${
                  showStatAttributes && "submenu_1 h-auto py-2"
                } submenu_cont_1 overflow-scroll z-3`}
              >
                <div className="d-flex gap-1">
                  <input
                    className="d-flex ac-jb px-2 cust-btn text-start py-1 w-100 border-bottom rounded"
                    placeholder="Search..."
                    onChange={(e) => searchProject(e.target.value)}
                    value={searchValue}
                  />
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => {
                      setSelectALL(!selectALL);
                      allSelect();
                    }}
                  >
                    All
                  </button>
                </div>
                {listProjects?.map((item, ind) => {
                  return (
                    <button
                      className="d-flex cust-btn w-100 as-jb hover-cust ac-jb"
                      onClick={() => {
                        onStatAttributeSelect(item?.name, item?.id);
                      }}
                    >
                      <button className="px-2 cust-btn text-start py-1 w-100 ">
                        {item?.name}
                      </button>
                      <button
                        className="px-2 cust-btn text-start"
                        style={{ fontSize: "large" }}
                      >
                        {projectValue.includes(item?.name) ? (
                          <IoIosRadioButtonOn className="primary" />
                        ) : (
                          <IoIosRadioButtonOff className="primary" />
                        )}
                      </button>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Roles</label>
          <select
            value={empRole}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            onChange={resRoleSelectHandler}
          >
            <option>Select Role</option>
            <option value="User">User</option>
            <option value="Admin">Admin</option>
            <option value="Super Admin">Super Admin</option>
            <option value="Guest">Guest</option>
          </select>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Select the application</label>
          <div className="d-flex mt-2">
            <button className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3">
              <div
                className="checkbox me-1"
                onClick={() => setcheckbox({ ...checkbox, web: !checkbox.web })}
              >
                {checkbox.web && <img src={tickIcon} alt="tick" />}
              </div>
              Web application
            </button>
            <button className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3">
              <div
                className="checkbox me-1"
                onClick={() =>
                  setcheckbox({ ...checkbox, mobile: !checkbox.mobile })
                }
              >
                {checkbox.mobile && <img src={tickIcon} alt="tick" />}
              </div>
              Moblie application
            </button>
          </div>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Password</label>
          <input
            type="password"
            value={password}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter your Password"
            onChange={(e) => setPassword(e.target.value)}
          />
          {passErr && (
            <p className="my-1">Password must be minimum five Charcters</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <div className="d-flex">
            <label className="mx-2 ">Notifications</label>
            <div className="checkbox-noti" onClick={notifyChanger}>
              {notify && <img src={tickIcon} alt="tick" />}
            </div>
          </div>
          {notify && (
            <div className="d-flex mt-2">
              <button className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3">
                <div
                  className="checkbox me-1"
                  onClick={() => notifiBoxChanger("level_1")}
                >
                  {notibox.level_1 && <img src={tickIcon} alt="tick" />}
                </div>
                Level 1
              </button>
              <button className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3">
                <div
                  className="checkbox me-1"
                  onClick={() => notifiBoxChanger("level_2")}
                >
                  {notibox.level_2 && <img src={tickIcon} alt="tick" />}
                </div>
                Level 2
              </button>
              <button className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3">
                <div
                  className="checkbox me-1"
                  onClick={() => notifiBoxChanger("level_3")}
                >
                  {notibox.level_3 && <img src={tickIcon} alt="tick" />}
                </div>
                Level 3
              </button>
            </div>
          )}
        </Col>
      </Col>
      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
        />
      )}
    </Col>
  );
};

export default RolesRespForm;
