// @ts-nocheck
import React, { useEffect, useState } from "react";
import { tickIcon } from "../../../assets/img";
import { invoceBookingTable } from "../../../constants/StaticData";

const InternalReceiptTable = ({
  tableSel,
  setTableSel,
  mtTableStaticData,
  setMtTableStaticTable,
  materialList,
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    let temp = [];
    materialList?.material_issue_detail?.map((item, ind) => {
      if (Math.abs(+item?.issued_qty - +item?.return_qty) !== 0) {
        temp.push({
          ...item,
          return_qty: "",
          return_qty_custom: item?.return_qty,
        });
      }
    });
    setList(temp);
  }, [materialList]);

  const handleChange = (item, ind, type, id) => {
    let temp = [...list];

    let obj = temp[ind];

    let arr = [...tableSel];
    let inx = arr.findIndex((i) => i.id == id);

    if (type == "return_qty") {
      let final = {
        ...obj,
        [type]: +item,
        error:
          +item > Math.abs(+obj?.issued_qty - +obj?.return_qty_custom) ? 1 : 0,
      };

      temp[ind] = final;
      arr[inx ? inx : 0] = final;
      setList(temp);
      setTableSel(arr);
    } else {
      let final = {
        ...obj,
        [type]: item,
      };

      temp[ind] = final;
      arr[inx ? inx : 0] = final;
      setList(temp);
      setTableSel(arr);
    }
  };

  const checkBox = (item) => {
    let temp = [...tableSel];

    let inx = temp.indexOf(item);

    if (temp.includes(item)) {
      temp.splice(inx, 1);
    } else {
      temp.push(item);
    }

    setTableSel(temp);
  };

  // console.log("list", list);
  // console.log("tableSel", tableSel);

  return (
    <div className="user-mange-table bg-white p-2">
      <table>
        <thead>
          <tr>
            <th className="text-nowrap">S.No</th>
            <th className="text-nowrap">Material Code</th>
            <th className="text-nowrap">Material Description</th>
            <th className="text-nowrap">UOM</th>
            <th className="text-nowrap">Issued Quantity</th>
            <th className="text-nowrap">Returnable Quantity</th>
            <th className="text-nowrap text-center">Return Quantity</th>
            <th className="text-nowrap text-center">Remarks</th>
          </tr>
        </thead>
        <tbody>
          {list?.map((item, ind) => {
            return (
              <tr key={ind}>
                <td className="text-center d-flex gap-1" style={{ fontSize: "12px" }}>
                  {ind + 1}
                  {item?.return_qty ? (
                    <input
                      type="checkbox"
                      className="ms-2"
                      onClick={() => checkBox(item)}
                      checked={tableSel?.includes(item) ? true : false}
                    />
                  ) : (
                    <input checked type={"checkbox"} disabled />
                  )}
                </td>
                <td className="text-center" style={{ fontSize: "12px" }}>{item?.material?.material_code}</td>
                <td className="text-start" style={{ fontSize: "12px" }}>{item?.material?.name}</td>
                <td className="text-center" style={{ fontSize: "12px" }}>{item?.material?.uom}</td>
                <td className="text-center" style={{ fontSize: "12px" }}>{item?.issued_qty}</td>
                <td className="text-center" style={{ fontSize: "12px" }}>
                  {+item?.issued_qty - +item?.return_qty_custom}
                </td>
                <td>
                  <input style={{ fontSize: "12px" }}
                    type="number"
                    className="border-0 w-100 text-center"
                    value={item?.return_qty}
                    onChange={(e) =>
                      handleChange(e.target.value, ind, "return_qty", item?.id)
                    }
                  />
                  <p>
                    {item?.error == 1 && (
                      <p className="err" style={{ fontSize: "10px" }}>
                        Exceeded The Quantity
                      </p>
                    )}
                  </p>
                </td>
                <td>
                  <input style={{ fontSize: "12px" }}
                    className="border-0 w-100 text-center"
                    value={item?.remarks}
                    onChange={(e) =>
                      handleChange(e.target.value, ind, "remarks", item?.id)
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default InternalReceiptTable;
