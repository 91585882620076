// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { qualifWorkIcon, report, respIcon } from "../../assets/img";
import BankDetails from "./BankDetailsTab";
import AddressTab from "./AddressTab";
// import ProductTypeTab from "./ProductTypeTab";
import RatingTab from "./RatingTab";
import TaxationTab from "./TaxationTab";

const VendorMasterTab = ({
  prodectType,
  companyName,
  vendorType,
  subVendorType,
}) => {
  const [tab, setTab] = useState("address");
  const [btn, setBtn] = useState(0);
  const [btn2, setBtn2] = useState(0);
  const [btn3, setBtn3] = useState(0);
  const [add, setAdd] = useState([]);
  const [tax, setTax] = useState([]);
  const [bank, setBank] = useState([]);

  const tabonClickHandler = (e) => {
    console.log(e);
    setTab(e);
  };

  return (
    <Col className="roles-resp-tab py-2 px-1 px-md-3 py-2 mt-1 text-center">
      <Col className="d-flex flex-wrap justify-content-center">
        <button
          onClick={(e) => tabonClickHandler((e = "address"))}
          className={
            tab == "address"
              ? "bg-gradient tab-list border-0"
              : "bg-gray tab-list border-0"
          }>
          <img src={respIcon} className="tabIcon" alt="resp-icon" />
          <p>Address</p>
        </button>
        <button
          onClick={(e) => tabonClickHandler((e = "taxation"))}
          className={
            tab == "taxation"
              ? "bg-gradient tab-list border-0"
              : "bg-gray tab-list border-0"
          }
          disabled={btn == 0 ? true : false}>
          <img src={report} className="tabIcon reporttab" />
          <p>Taxation</p>
        </button>
        <button
          onClick={(e) => tabonClickHandler((e = "bank"))}
          className="tab-list bg-gradient border-0"
          className={
            tab == "bank"
              ? "bg-gradient tab-list border-0"
              : "bg-gray tab-list border-0"
          }
          disabled={btn2 == 0 ? true : false}>
          <img src={qualifWorkIcon} className="tabIcon" />
          <p>Bank Details</p>
        </button>
        <button
          onClick={(e) => tabonClickHandler((e = "rating"))}
          className="tab-list bg-gradient border-0"
          className={
            tab == "rating"
              ? "bg-gradient tab-list border-0"
              : "bg-gray tab-list border-0"
          }
          disabled={btn3 == 0 ? true : false}>
          <img src={qualifWorkIcon} className="tabIcon" />
          <p>Rating</p>
        </button>
        {/* <button
          onClick={(e) => tabonClickHandler((e = "pt"))}
          className="tab-list bg-gradient border-0"
          className={
            tab == "pt"
              ? "bg-gradient tab-list border-0"
              : "bg-gray tab-list border-0"
          }
        >
          <img src={qualifWorkIcon} className="tabIcon" />
          <p>Product Type</p>
        </button> */}
      </Col>
      <Col xs={12}>
        {tab == "address" && (
          <AddressTab
            setAdd={setAdd}
            add={add}
            setBtn={setBtn}
            tabonClickHandler={tabonClickHandler}
          />
        )}
        {tab == "taxation" && (
          <TaxationTab
            setTax={setTax}
            tax={tax}
            setBtn2={setBtn2}
            tabonClickHandler={tabonClickHandler}
          />
        )}
        {tab == "bank" && (
          <BankDetails
            setBank={setBank}
            bank={bank}
            setBtn3={setBtn3}
            tabonClickHandler={tabonClickHandler}
          />
        )}
        {tab == "rating" && (
          <RatingTab
            prodectType={prodectType}
            // supplierName={supplierName}
            companyName={companyName}
            add={add}
            tax={tax}
            bank={bank}
            tabonClickHandler={tabonClickHandler}
            vendorType={vendorType}
            subVendorType={subVendorType}
          />
        )}
      </Col>
    </Col>
  );
};

export default VendorMasterTab;
