import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { tickIcon } from "../../assets/img";
import StatusModal from "../../component/StatusModal";
// import moment from "moment";
import { api } from "../../constants/Services";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";

function AssetsActionPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [materialDetails, setMaterialDetails] = useState({});
  const [MaterialFormDetails, setMaterialFormDetails] = useState({
    group: "",
    uom: "",
    parent_name: "",
    level: "",
    name: "",
    code: "",
    make: "",
    color: "",
    model: "",
    attribute: "",
    attribute_type: "",
    material_type: "",
    is_child: "",
    specification1: "",
    specification2: "",
    specification3: "",
    specification4: "",

    quantity: "",
    total_value: "",
    per_asset_value: "",
  });
  const [showStatus, setShowStatus] = useState(false);
  const statusModalClose = () => {
    setShowStatus(!showStatus);
    navigate(-1);
    // navigate("procument/material-list");
  };

  const { id, action } = location?.state;

  useEffect(() => {
    api.material_show(id).then((res) => {
      if (res?.status == "success") {
        setMaterialDetails(res?.material);

        if (res?.material) {
          setMaterialFormDetails({
            group: res?.material?.group,
            uom: res?.material?.uom,
            parent_name: res?.material?.parent?.id,
            level: res?.material?.level,
            name: res?.material?.name,
            code: res?.material?.material_code,
            make: res?.material?.attribute?.make,
            color: res?.material?.attribute?.color,
            model: res?.material?.attribute?.model,
            attribute: res?.material?.attribute == null ? 0 : 1,
            attribute_type: res?.material?.attribute?.type,
            material_type: res?.material?.material_type,
            is_child: res?.material?.is_child,
            specification1: res?.material?.attribute?.specification1,
            specification2: res?.material?.attribute?.specification2,
            specification3: res?.material?.attribute?.specification3,
            specification4: res?.material?.attribute?.specification4,

            quantity: res?.material?.quantity,
            total_value: res?.material?.total_value,
            per_asset_value: res?.material?.per_asset_value,
          });
        }
      }
    });
  }, [id]);

  const materialMasActionHandler = () => {
    let data = {
      ...MaterialFormDetails,
      type: 1,
      _method: "PUT",
    };

    api.material_assests_update(id, data).then((res) => {
      if (res?.status === "success") {
        statusModalClose();
      }
    });
  };

  return (
    <Col xs={12} className="p-3">
      <Header />
      <Col
        xs={12}
        className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center"
      >
        <h4>Assets Details</h4>

        {action == "view" ? (
          <Col xs={12} xxl={8} md={10} className="d-flex flex-wrap mx-auto">
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Assets Name</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.name}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Assets Code</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.material_code}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Assets Type</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.material_type ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Assets Group</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.group}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Has Child Material</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.is_child == 1 ? "Yes" : "No"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">UOM</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.uom}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Make</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.attribute?.make ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Parent ID</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.parent_id ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Quantity</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.quantity ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Per Value</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.per_asset_value ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Value</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.total_value ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Color</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.attribute?.color ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Model </label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.attribute?.model ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Attribute Type</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.attribute?.type ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Specialization 1</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.attribute?.specification1 ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label>Specialization 2</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.attribute?.specification2 ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label>Specialization 3</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.attribute?.specification3 ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label>Specialization 4</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.attribute?.specification4 ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label>Specialization 5</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.attribute?.specification5 ?? "-"}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label>Specialization 6</label>
              <p
                style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {materialDetails?.attribute?.specification6 ?? "-"}
              </p>
            </Col>
            <div className="d-flex w-100">
              <button
                className="bg-gray border-0 ms-auto me-5 rounded-1  my-3 lightBlue f-1 fs-14 px-4 py-2"
                onClick={() => navigate(-1)}
              >
                Close
              </button>
            </div>
          </Col>
        ) : (
          <Col className="d-flex flex-wrap">
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Assets Name</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Assets name"
                value={MaterialFormDetails.name}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    name: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Assets Group</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Assets Group"
                value={MaterialFormDetails.group}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    group: e.target.value,
                  })
                }
              />
            </Col>

            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Assets Type</label>

              <select
                className="input-select bg-lightBlue ms-0"
                style={{
                  border: "2px solid #f1f7ff",
                  width: "90%",
                  marginRight: "auto",
                }}
                value={MaterialFormDetails.material_type}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    material_type: e.target.value,
                  })
                }
              >
                <option value={""}>select assets type</option>
                <option value={"QUANTITY"}>Quantity</option>
                <option value={"ITEM BASE"}>Item Base</option>
                {/* <option value={"ELECTRICAL"}>ELECTRICAL</option>
                                <option value={"CIVIL"}>CIVIL</option>
                                <option value={"MECHANICAL"}>MECHANICAL</option>
                                <option value={"HVAC"}>HVAC</option>
                                <option value={"FIRE FIGHTING"}>FIRE FIGHTING</option>
                                <option value={"ELV"}>ELV</option>
                                <option value={"OTHERS"}>OTHERS</option>
                                <option value={"PHE"}>PHE</option>
                                <option value={"PROCESS PIPE"}>PROCESS PIPE</option>
                                <option value={"SOLVENT PIPE"}>SOLVENT PIPE</option> */}
              </select>
            </Col>

            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Assets Code</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Assets Code"
                value={MaterialFormDetails.code}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    code: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">UOM</label>
              <input
                className="input-select bg-lightBlue ms-0"
                style={{
                  border: "2px solid #f1f7ff",
                  width: "90%",
                  marginRight: "auto",
                }}
                value={MaterialFormDetails.uom}
                placeholder="Enter UOM"
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    uom: e.target.value,
                  })
                }
              />
              {/* <select
                className="input-select bg-lightBlue ms-0"
                style={{
                  border: "2px solid #f1f7ff",
                  width: "90%",
                  marginRight: "auto",
                }}
                value={MaterialFormDetails.uom}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    uom: e.target.value,
                  })
                }
              >
                <option value={""}>select UOM</option>
                <option value={"rmt"}>RMT</option>
                <option value={"mtr"}>MTR</option>
                <option value={"kg"}>KG</option>
                <option value={"grm"}>GRM</option>
                <option value={"lot"}>LOT</option>
                <option value={"ELV"}>ELV</option>
                <option value={"pair"}>PAIR</option>
                <option value={"set"}>SET</option>
                <option value={"each"}>EACH</option>
                <option value={"MT"}>MT</option>
                <option value={"itr"}>ITR</option>
                <option value={"job"}>JOB</option>
                <option value={"nos."}>NOS</option>
                <option value={"cum"}>CUM</option>
                <option value={"sqm"}>SQM</option>
                <option value={"cm"}>CM</option>
                <option value={"dia"}>DIA</option>
                <option value={"deg"}>DEG</option>
                <option value={"S"}>S</option>
                <option value={"mole"}>MOLE</option>
                <option value={"K"}>K</option>
                <option value={"A"}>A</option>
                <option value={"volt"}>VOLT</option>
                <option value={"iv"}>IV</option>
                <option value={"temp"}>TEMP</option>
                <option value={"cel"}>CEL</option>
                <option value={"HZ"}>HZ</option>
                <option value={"W"}>W</option>
                <option value={"A"}>A</option>
                <option value={"mm"}>MM</option>
                <option value={"inch"}>INCH</option>
                <option value={"ft"}>FT</option>
                <option value={"kw"}>KW</option>
                <option value={"L/S"}>L/S</option>
              </select> */}
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Parent Name</label>
              <p
                style={{ minHeight: "2rem", width: "90%", marginRight: "auto" }}
                className="border-0 bg-lightBlue px-2 py-1 mt-1"
              >
                {MaterialFormDetails.parent_name}
              </p>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Level</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Level"
                value={MaterialFormDetails.level}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    level: e.target.value,
                  })
                }
              />
            </Col>

            {MaterialFormDetails?.material_type
              ?.toLowerCase()
              .startsWith("item") && (
              <>
                <Col
                  xs={12}
                  sm={6}
                  xl={6}
                  className="inputinner-box text-start"
                >
                  <label className="w-100">Quantity</label>
                  <input
                    className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                    placeholder="Quantity"
                    onChange={(e) =>
                      setMaterialFormDetails({
                        ...MaterialFormDetails,
                        quantity: e.target.value,
                      })
                    }
                    value={MaterialFormDetails?.quantity}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  xl={6}
                  className="inputinner-box text-start"
                >
                  <label className="w-100">Per Value</label>
                  <input
                    className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                    placeholder="Per Value"
                    onChange={(e) =>
                      setMaterialFormDetails({
                        ...MaterialFormDetails,
                        per_asset_value: e.target.value,
                      })
                    }
                    value={MaterialFormDetails?.per_asset_value}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  xl={6}
                  className="inputinner-box text-start"
                >
                  <label className="w-100">Value</label>
                  <input
                    className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                    placeholder="Value"
                    onChange={(e) =>
                      setMaterialFormDetails({
                        ...MaterialFormDetails,
                        total_value: e.target.value,
                      })
                    }
                    value={MaterialFormDetails?.total_value}
                  />
                </Col>
              </>
            )}

            {MaterialFormDetails?.material_type
              ?.toLowerCase()
              .startsWith("quan") && (
              <>
                <Col
                  xs={12}
                  sm={6}
                  xl={6}
                  className="inputinner-box text-start"
                >
                  <label className="w-100">Quantity</label>
                  <input
                    className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                    placeholder="Quantity"
                    onChange={(e) =>
                      setMaterialFormDetails({
                        ...MaterialFormDetails,
                        quantity: e.target.value,
                      })
                    }
                    value={MaterialFormDetails?.quantity}
                  />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  xl={6}
                  className="inputinner-box text-start"
                >
                  <label className="w-100">Value</label>
                  <input
                    className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                    placeholder="Value"
                    onChange={(e) =>
                      setMaterialFormDetails({
                        ...MaterialFormDetails,
                        total_value: e.target.value,
                      })
                    }
                    value={MaterialFormDetails?.total_value}
                  />
                </Col>
              </>
            )}

            <Col xs={12} className="inputinner-box text-start">
              <label className="w-100">Select the application</label>
              <div className="d-flex mt-2">
                <button
                  className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3"
                  onClick={() =>
                    setMaterialFormDetails({
                      ...MaterialFormDetails,
                      is_child: 1,
                    })
                  }
                >
                  <div className="checkbox me-1">
                    {MaterialFormDetails.is_child === 1 && (
                      <img src={tickIcon} alt="tick-image" />
                    )}
                  </div>
                  Has Child
                </button>
                <button
                  className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3"
                  onClick={() =>
                    setMaterialFormDetails({
                      ...MaterialFormDetails,
                      is_child: 0,
                    })
                  }
                >
                  <div className="checkbox me-1">
                    {MaterialFormDetails.is_child === 0 && (
                      <img src={tickIcon} alt="tick-image" />
                    )}
                  </div>
                  No Child
                </button>
              </div>
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Make</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Make"
                value={MaterialFormDetails.make}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    make: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Color</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Color"
                value={MaterialFormDetails.color}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    color: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Modal</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Modal"
                value={MaterialFormDetails.model}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    model: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Type</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Type"
                value={MaterialFormDetails?.attribute_type}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    attribute_type: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Specification 1</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Specification"
                value={MaterialFormDetails.specification1}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    specification1: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Specification 2</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Specification"
                value={MaterialFormDetails.specification2}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    specification2: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Specification 3</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Specification"
                value={MaterialFormDetails.specification3}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    specification3: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Specification 4</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Specification"
                value={MaterialFormDetails.specification4}
                onChange={(e) =>
                  setMaterialFormDetails({
                    ...MaterialFormDetails,
                    specification4: e.target.value,
                  })
                }
              />
            </Col>

            <Col sm={12} className="inputinner-box text-start">
              <button
                className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
                onClick={() => materialMasActionHandler()}
              >
                Save
              </button>
            </Col>
          </Col>
        )}
      </Col>
      {showStatus && (
        <StatusModal
          statusModalClose={statusModalClose}
          showStatus={showStatus}
        />
      )}
    </Col>
  );
}

export default AssetsActionPage;
