// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import {
  addressIcon,
  bankIcon,
  currencyIcon,
  personalIcon,
  uploadIcon,
} from "../../assets/img";
import LbAddressTab from "./LbMasAddress";
import LbBankDetails from "./LbMasBankDetails";
import LbPersonalDetails from "./LbMasPersonalDetails";
import LbSalaryEpf from "./LbMasSalaryDetails";
import LabourDocument from "./LabourDocument";

const LabourMasterTab = () => {
  const [tab, setTab] = useState("address");

  const tabonClickHandler = (e) => {
    console.log(e);
    setTab(e);
  };

  return (
    <>
      <Col className="emply-master-tab px-3 py-2 mt-1 text-center">
        <Col className="d-flex flex-wrap">
          <button
            onClick={(e) => tabonClickHandler((e = "address"))}
            className={
              tab == "address"
                ? "bg-gradient tab-list border-0"
                : "bg-gray tab-list border-0"
            }
          >
            <img src={addressIcon} className="tabIcon" />
            <p>Address</p>
          </button>
          <button
            onClick={(e) => tabonClickHandler((e = "person"))}
            className={
              tab == "person"
                ? "bg-gradient tab-list border-0"
                : "bg-gray tab-list border-0"
            }
          >
            <img src={personalIcon} className="tabIcon" />
            <p>Personal Details</p>
          </button>
          <button
            onClick={(e) => tabonClickHandler((e = "salary"))}
            className={
              tab == "salary"
                ? "bg-gradient tab-list border-0"
                : "bg-gray tab-list border-0"
            }
          >
            <img src={currencyIcon} className="tabIcon" />
            <p>Salary, EPF</p>
          </button>
          <button
            onClick={(e) => tabonClickHandler((e = "bank"))}
            className={
              tab == "bank"
                ? "bg-gradient tab-list border-0"
                : "bg-gray tab-list border-0"
            }
          >
            <img src={bankIcon} className="tabIcon" />
            <p>Bank Details</p>
          </button>
          <button
            onClick={(e) => tabonClickHandler((e = "document"))}
            className={
              tab == "document"
                ? "bg-gradient tab-list border-0"
                : "bg-gray tab-list border-0"
            }
          >
            <img src={uploadIcon} className="tabIcon" />
            <p>Documents</p>
          </button>
        </Col>
      </Col>
      <Col xs={12}>
        {tab == "address" && <LbAddressTab />}
        {tab == "person" && <LbPersonalDetails />}
        {tab == "bank" && <LbBankDetails />}
        {tab == "salary" && <LbSalaryEpf />}
        {tab == "document" && <LabourDocument />}
      </Col>
    </>
  );
};

export default LabourMasterTab;
