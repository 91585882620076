import React from "react";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/App.scss";
import "./assets/sass/Responsive.scss";
import Login from "./screens/Login";
import Layout from "./Layout/Layout..js";
import Register from "./screens/Register";
import HomeScreen from "./screens/HomeScreen";
import ProjectCreation from "./screens/ProjectCreation";
import ProjectsList from "./screens/BuillingBudget/ProjectsList";
import ProjectActionPage from "./component/ProjectActionComponent";
import BoqCreation from "./screens/BuillingBudget/BoqCreation";
import BoqAmendment from "./screens/BuillingBudget/BoqAmendment";
import DprScreen from "./screens/BuillingBudget/DprScreen";
import EmployeeMaster from "./screens/HrManagement/EmployeeMaster";
import EmployeeView from "./screens/HrManagement/EmployeeView";
import NotificationScreen from "./component/NotificationComponent";
import LabourMaster from "./screens/HrManagement/LabourMaster";
import EmployeeManagementscreen from "./screens/HrManagement/EmployeeManagement";
import UserManagementscreen from "./screens/HrManagement/UserManagementscreen";
import RolesRespScreen from "./screens/HrManagement/RolesRespScreen";
import VendorMasterScreen from "./screens/Procurement/VendorMasterScreen";
import VendorActionPage from "./screens/Procurement/VendorView";
import SampleCharts from "./component/Chart/EmployeePieChart";
import VendorDetailsTableScreen from "./screens/Procurement/VendorDetailsTableScreen";
import GstRegistrationScreen from "./screens/Procurement/GstRegistrationScreen";
import AttributeMasterScreen from "./screens/Procurement/AttributeMasterScreen";
import AssetsMasterScreen from "./screens/Procurement/AssestMasterScreen";
import MaterialMasterScreen from "./screens/Procurement/MaterialMasterScreen";
import MaterialListScreen from "./screens/Procurement/MaterialList";
import MaterialActionPage from "./screens/Procurement/MaterialActionPage";
import MaterialRequestScreen from "./screens/Procurement/MaterialRequestScreen";
import MaterialRequestView from "./screens/Execution/MaterialRequestViewScreen";
import MaterialRequestListScreen from "./screens/Execution/MaterialRequestList";
import MaterialRequestAmendmentScreen from "./screens/Procurement/MaterialRequestAmendmentScreen";
import PoCreationScreen from "./screens/Procurement/PoCreationScreen";
import WorkOrderPrint from "./screens/Procurement/WorkOrderPrint";
import PurchaseOrderList from "./screens/Procurement/PurchaseOrderListScreen";
import PoTermsAndCondition from "./screens/Procurement/PoTerms&Condition";
import PoOutputScreen from "./screens/Procurement/PoOutputScreen";
import WorkOrderMasterScreen from "./screens/Procurement/WorkOrderMasterScreen";
import PoCreationAmendmentScreen from "./screens/Procurement/PoCreationAmendmentScreen";
import IsmGinScreen from "./screens/Inventory/IsmGinScreen";
import IsmInvoiceScreen from "./screens/Inventory/IsmInvoiceScreen";
import IsmInvoiceScrutinyScreen from "./screens/Inventory/IsmInvoiceScrutinyScreen";
import IsmMaterialRequestScreen from "./screens/Inventory/IsmMaterialRequest";
import IsmMaterialRequestAmendmentScreen from "./screens/Inventory/IsmMaterialRequestAmendmentScreen";
import InternalReceiptScreen from "./screens/Inventory/InternalReceiptScreen";
import InternalReceiptList from "./screens/Inventory/InternalReceiptList";
import IsmMaterialIssueScreen from "./screens/Inventory/IsmMaterialIssueScreen";
import IsmMaterialReturnScreen from "./screens/Inventory/IsmMaterialReturnScreen";
import IsmMaterialReceiptScreen from "./screens/Inventory/IsmMaterialReceiptScreen";
import MaterialIssueList from "./screens/Inventory/MaterialIssueList";
import MaterialTransferList from "./screens/Inventory/MaterialTransferList";
import MaterialReceiptList from "./screens/Inventory/MaterialReceiptList";
import MaterialReturnList from "./screens/Inventory/MaterialReturnList";
import GrnList from "./screens/Inventory/GrnList";
import InvoiceBookingList from "./screens/Inventory/InvoiceBookingList";
import InvoiceScrutinyList from "./screens/Inventory/InvoiceScrutinyList";
import IsmMaterialTransferScreen from "./screens/Inventory/IsmMaterialTransferScreen";
import IsmDayEndScreen from "./screens/Inventory/IsmDayEndScreen";
import DashBoard from "./screens/Dashboard";
import IsmPrintScreen from "./screens/Inventory/IsmPrint";
import PurchaseOrderActionPage from "./screens/Procurement/PurchaseOrderView";
import HSNPage from "./screens/Procurement/HsnPage";
import useToken from "./constants/Storage/useToken";
import PrivateRoutes from "./constants/Services/PrivateRoutes";
import WareHousePage from "./screens/Inventory/WareHousePage";
import EmployeeEdit from "./screens/HrManagement/EmployeeEdit";
import AssetsRequestListScreen from "./screens/Procurement/AssetsList";
import AssetsActionPage from "./screens/Procurement/AssetsActionPage";
import NotificationRequestScreen from "./screens/NotificationRequestScreen.jsx";
import BOQAmendmentList from "./screens/BuillingBudget/BOQAmendmentList.js";
import BoqAmendView from "./screens/BuillingBudget/BoqAmendView.js";
import WorkOrderAmendmentScreen from "./screens/Procurement/WorkOrderAmendmentScreen.js";
import WorkOrderListScreen from "./screens/Procurement/WorkOrderListScreen.js";
import GRNOutputScreen from "./screens/Procurement/GRNOutputScreen";
import MaterialOutputScreen from "./screens/Procurement/MaterialOutputScreen";
import MaterialTransferOutputScreen from "./screens/Procurement/MaterialTransferOutputScreen";
import MaterialReturnOutputScreen from "./screens/Procurement/MaterialReturnOutputScreen";
import WorkOrderOutputScreen from "./screens/Procurement/workOrderOutputScreen";
import PoOutputScreenCopy from "./screens/Procurement/PoOutputScreenCopy.js";
import CompanyMaster from "./screens/HrManagement/CompanyMaster";
import CompanyManagentView from "./screens/HrManagement/CompanyManagentView";
import MaterialRequestEditPage from "./screens/Execution/MaterialRequestEditPage";

const ReactRoutes = () => {
  const { token } = useToken();

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route element={<PrivateRoutes token={token} />}>
        {/* <Route path="/procurement/po-output" element={<PoOutputScreenCopy />} /> */}
        <Route element={<Layout />}>
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/dashbord" element={<DashBoard />} />
          <Route path="/home/project-creation" element={<ProjectCreation />} />
          <Route path="/home/projects-list" element={<ProjectsList />} />
          <Route path="/home/project-action" element={<ProjectActionPage />} />
          <Route path="/home/boq-creation" element={<BoqCreation />} />
          <Route path="/home/boq-amendment" element={<BoqAmendment />} />
          <Route path="/home/company-masters" element={<CompanyMaster />} />
          <Route
            path="/home/company-master"
            element={<CompanyManagentView />}
          />
          <Route
            path="/home/boq-amendment-list"
            element={<BOQAmendmentList />}
          />
          <Route
            path="/home/boq-amendment-list/boq-amendment-view"
            element={<BoqAmendView />}
          />
          <Route path="/home/dpr" element={<DprScreen />} />
          <Route path="/hrm/employee-master" element={<EmployeeMaster />} />
          <Route path="/hrm/labour-master" element={<LabourMaster />} />
          <Route
            path="/hrm/employee-management"
            element={<EmployeeManagementscreen />}
          />
          <Route path="/hrm/employee-view" element={<EmployeeView />} />
          <Route path="/hrm/employee-edit" element={<EmployeeEdit />} />
          <Route
            path="/hrm/user-management"
            element={<UserManagementscreen />}
          />
          <Route
            path="/hrm/roles-responsibility"
            element={<RolesRespScreen />}
          />
          <Route
            path="/procurement/vendor-master"
            element={<VendorMasterScreen />}
          />
          <Route
            path="/procurement/vendor-details"
            element={<VendorDetailsTableScreen />}
          />
          <Route path="/procurement/hsn" element={<HSNPage />} />
          <Route
            path="/procurement/vendor-action"
            element={<VendorActionPage />}
          />
          <Route
            path="/procurement/purchase-order-action"
            element={<PurchaseOrderActionPage />}
          />
          <Route
            path="/procurement/gst-registration"
            element={<GstRegistrationScreen />}
          />
          <Route
            path="/procurement/material-master"
            element={<MaterialMasterScreen />}
          />
          {/* naren start  */}
          <Route
            path="/procurement/material-list"
            element={<MaterialListScreen />}
          />
          <Route
            path="/procurement/assets-list"
            element={<AssetsRequestListScreen />}
          />
          <Route
            path="/procurement/material-action"
            element={<MaterialActionPage />}
          />
          <Route
            path="/procurement/assets-action"
            element={<AssetsActionPage />}
          />
          <Route
            path="/execution/material-request"
            element={<MaterialRequestScreen />}
          />
          <Route
            path="/execution/material-request-amendment"
            element={<MaterialRequestAmendmentScreen />}
          />
          <Route
            path="/execution/material-request-list"
            element={<MaterialRequestListScreen />}
          />
          <Route
            path="/execution/material-request-view"
            element={<MaterialRequestView />}
          />
          <Route
            path="/execution/material-request-edit"
            element={<MaterialRequestEditPage />}
          />

          <Route path="/notification" element={<NotificationScreen />} />
          <Route
            path="/notification-request"
            element={<NotificationRequestScreen />}
          />

          <Route path="/ism/ism-gin-entry" element={<IsmGinScreen />} />
          {/* <Route path="/ism/ism-gin-entry" element={<IsmGinScreen />} />  */}
          <Route
            path="/ism/ism-material-issue"
            element={<IsmMaterialIssueScreen />}
          />
          <Route
            path="/procurement/assets-master"
            element={<AssetsMasterScreen />}
          />

          <Route
            path="/procurement/work-order-print"
            element={<WorkOrderPrint />}
          />
          <Route
            path="/procurement/po-creation"
            element={<PoCreationScreen />}
          />
          <Route
            path="/procurement/work-order"
            element={<WorkOrderMasterScreen />}
          />
          <Route
            path="/procurement/work-order-amendment"
            element={<WorkOrderAmendmentScreen />}
          />
          <Route
            path="/procurement/work-order-list"
            element={<WorkOrderListScreen />}
          />
          <Route path="/procurement/po-output" element={<PoOutputScreen />} />

          <Route
            path="/procurement/work-order-output"
            element={<WorkOrderOutputScreen />}
          />
          <Route path="/ism/grn-output" element={<GRNOutputScreen />} />
          <Route path="/ism/output" element={<MaterialOutputScreen />} />
          <Route
            path="/ism/output/material-return"
            element={<MaterialReturnOutputScreen />}
          />
          <Route
            path="/ism/matrial/output"
            element={<MaterialTransferOutputScreen />}
          />
          <Route
            path="/procurement/puchase-order-list"
            element={<PurchaseOrderList />}
          />
          <Route
            path="/procurement/terms&conditions"
            element={<PoTermsAndCondition />}
          />
          <Route
            path="/procurement/po-creation-amendment"
            element={<PoCreationAmendmentScreen />}
          />
          {/* PurchaseOrderList  */}
          <Route
            path="/ism/internal-receipt"
            element={<InternalReceiptScreen />}
          />
          <Route
            path="/ism/internal-receipt-list"
            element={<InternalReceiptList />}
          />
          <Route
            path="/ism/invoice-scrutiny"
            element={<IsmInvoiceScrutinyScreen />}
          />
          <Route path="/ism/print" element={<IsmPrintScreen />} />
          <Route
            path="/ism/material-issue-list"
            element={<MaterialIssueList />}
          />
          <Route path="/ism/warehouse" element={<WareHousePage />} />
          <Route
            path="/ism/material-transfer-list"
            element={<MaterialTransferList />}
          />
          <Route path="/sample-chart" element={<SampleCharts />} />
          <Route
            path="/ism/material-receipt-list"
            element={<MaterialReceiptList />}
          />
          <Route
            path="/ism/material-return-list"
            element={<MaterialReturnList />}
          />
          <Route
            path="/ism/invoice-booking-list"
            element={<InvoiceBookingList />}
          />
          <Route
            path="/ism/invoice-scrutiny-list"
            element={<InvoiceScrutinyList />}
          />
          <Route path="/ism/grn-list" element={<GrnList />} />
          {/* naren end */}
          <Route
            path="/procurement/attribute-master"
            element={<AttributeMasterScreen />}
          />
          <Route
            path="/ism/ism-material-request"
            element={<IsmMaterialRequestScreen />}
          />
          <Route
            path="/ism/ism-material-transfer"
            element={<IsmMaterialTransferScreen />}
          />
          <Route
            path="/ism/ism-material-request-amendment"
            element={<IsmMaterialRequestAmendmentScreen />}
          />
          <Route
            path="/ism/ism-material-receipt"
            element={<IsmMaterialReceiptScreen />}
          />
          <Route
            path="/ism/ism-material-return"
            element={<IsmMaterialReturnScreen />}
          />
          <Route path="/ism/invoice-booking" element={<IsmInvoiceScreen />} />
          <Route path="/ism/day-end-process" element={<IsmDayEndScreen />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default ReactRoutes;
