// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";

const InvoiceScrutinyLastForm = ({ user, setModal }) => {
  const [Prepared, setPrepared] = useState("");
  const [Remarks, setRemarks] = useState("");

  const [PreparedErr, setPreparedErr] = useState(false);
  const [RemarksErr, setRemarksErr] = useState(false);

  const submitHandler = () => {
    setModal(true);
    // if (Prepared?.length == 0 || Remarks?.length == 0) {
    //   setPreparedErr(true);
    //   setRemarksErr(true);
    // } else {
    //   alert("saved");
    // }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <Col className="d-flex flex-column flex-wrap">
        {/* <Col xs={12} sm={6} className="inputinner-box text-start">
          <label className="w-100">Prepared</label>
          <p
            style={{ minHeight: "2.1rem", width: "90%", lineHeight: "2.1rem" }}
            className="input-select bg-lightBlue ms-0 ps-3 my-1"
          >
            {user}
          </p>
        </Col> */}
        <Col xs={12} sm={6} className="inputinner-box text-start">
          <label className="w-100">Remarks</label>
          <textarea
            rows="5"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            placeholder="Enter Remarks"
            onChange={(e) => setRemarks(e.target.value)}
            value={Remarks}
          />
          {Remarks?.length == 0 && RemarksErr && (
            <p className="err">enter remarks</p>
          )}
        </Col>
      </Col>
      <Col className="d-flex justify-content-between">
        <div>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2 me-3"
            onClick={submitHandler}>
            Save
          </button>
          <button className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
            Cancel
          </button>
        </div>
      </Col>
    </Col>
  );
};

export default InvoiceScrutinyLastForm;
