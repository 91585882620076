// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { gin_data } from "../../../constants/StaticData";

const IsmGinTable = ({
  purchaseOrderDetails,
  setPoTableStaticFields,
  poTableStaticFields,
  setDetails,
  details,
  projectType,
  setMatrial,
  matrial,
}) => {
  const [error, setError] = useState(false);
  const [poMatrials, setPoMatrials] = useState([]);
  const [allowRow, setAllowRow] = useState(1);
  const [rows, setRows] = useState([1]);
  const [dropDown, setDropDown] = useState(null);
  const [dropDowns, setDropDowns] = useState(null);
  const [filter, setFilter] = useState("");
  const ginTableBasicPriceHandler = (id, basic_rate) => {
    setPoTableStaticFields({
      ...poTableStaticFields,
      basic_rate: {
        ...poTableStaticFields?.basic_rate,
        [id]: +basic_rate,
      },
    });
  };

  const ginTableQtyReceivedHandler = (id, rec_qty, qty) => {
    let total_qty = qty;
    let received_qty = rec_qty;
    if (+rec_qty > +qty) {
      setError(true);
    } else {
      setError(false);
      setPoTableStaticFields({
        ...poTableStaticFields,
        rec_qty: {
          ...poTableStaticFields?.rec_qty,
          [id]: +rec_qty,
        },
      });
    }
  };

  useEffect(() => {
    if (purchaseOrderDetails?.purchase_order_details?.length > 0) {
      setPoMatrials(purchaseOrderDetails?.purchase_order_details);
      let temp = [...details];
      purchaseOrderDetails?.purchase_order_details?.map((item, ind) => {
        temp.push(item?.id);
      });

      setDetails(temp);
      setMatrial({});
      setRows([1]);
    }
  }, [purchaseOrderDetails]);

  useEffect(() => {
    if (
      purchaseOrderDetails?.purchase_order_details?.length > 0 &&
      projectType == 1
    ) {
      let temp = {};
      purchaseOrderDetails?.purchase_order_details?.map((data, ind) => {
        // console.log(data?.basic_price, "basic_price");
        let obj = {
          matrial_code: data?.material_code || data?.material?.material_code,
          material_id: data?.material_id,
          boq_code: data?.boq_code,
          project_boq_id: data?.project_boq_id,
          material: data?.material,
          id: data?.id,
          tolerance: +data?.tolerance ? +data?.tolerance : 0,
          material_description: data?.material_description || data?.name,
          uom: data?.uom,
          test_certificate: 0,
          waranty_certificate: 0,
          MRIR_certificate: 0,
          raised_qty: +data?.qty,
          //  data?.po_stocks
          //   ? data?.po_stocks
          //   : data?.qty
          //   ? +data?.qty
          //   : data?.quantity
          //   ? +data?.quantity
          //   : 0,
          grn_qty: +data?.grn_qty,
          qty_received: "",
          basic_rate: +data?.basic_price,
          remarks: "",
          total_value: +data?.qty * +data?.basic_price,
        };

        temp[ind] = obj;

        console.log("obj1", obj);
      });

      setMatrial(temp);
    } else if (projectType == 2) {
      setMatrial({});
    }
  }, [purchaseOrderDetails, projectType]);

  const [materialDrop, setMaterialDrop] = useState(false);
  const MateriaDropHandle = () => {
    setMaterialDrop(!materialDrop);
  };

  useEffect(() => {
    let temp = new Array(+allowRow);
    temp.fill(1);
    setRows(temp);
  }, [allowRow]);

  const dropHandler = (ind, type) => {
    if (type == "name") {
      if (dropDowns == ind) {
        setDropDowns(null);
      } else {
        setDropDowns(ind);
      }
    } else {
      if (dropDown == ind) {
        setDropDown(null);
      } else {
        setDropDown(ind);
      }
    }
  };

  const getMatrialDetails = (data, ind) => {
    console.log("data", data);
    let obj = {
      matrial_code: data?.material_code || data?.material?.material_code,
      material_id: data?.material_id || data?.id,
      boq_code: data?.boq_code,
      project_boq_id: data?.project_boq_id,
      material: data?.material,
      id: data?.id,
      material_description: data?.material_description || data?.name,
      uom: data?.uom,
      tolerance: +data?.tolerance ? +data?.tolerance : 0,
      grn_qty: +data?.grn_qty,
      test_certificate: 0,
      waranty_certificate: 0,
      MRIR_certificate: 0,
      qty_received: +data?.qty || +data?.quantity,
      basic_rate: "",
      total_value: "",
      remarks: "",
    };

    console.log("obj", obj);

    setMatrial((val) => ({ ...val, [ind]: obj }));
    setDropDown(null);
    setDropDowns(null);
    let temp = [...details];
    temp.push(obj?.id);
    setDetails(temp);

    // let temp = [...poMatrials];
    // if (temp.includes(data)) {
    //   let index = temp.indexOf(data);
    //   temp.splice(index, 1);
    // }
    // setPoMatrials(temp);
  };

  const handleChange = (event, ind, type, id) => {
    let temp = { ...matrial[ind] };
    let arr = [...details];
    let inx = arr.findIndex((obj) => obj.id == id);

    let qty = temp?.qty_received;
    let basic_rate = temp?.basic_rate;

    if (type == "basic_rate") {
      let obj = {
        ...temp,
        [type]: event,
        ["total_value"]: Number(qty * event).toFixed(2),
      };

      setMatrial((val) => ({ ...val, [ind]: obj }));
      arr[inx] = obj;
    } else if (type == "qty_received") {
      let obj = {
        ...temp,
        [type]: event,
        ["total_value"]: Number(basic_rate * event).toFixed(2),
        // error: +matrial[ind]?.tolerance
        //   ? +event >
        //     +matrial[ind]?.tolerance +
        //       +matrial[ind]?.raised_qty -
        //       +matrial[ind]?.grn_qty
        //     ? 1
        //     : 0
        //   : +event > +matrial[ind]?.raised_qty - +matrial[ind]?.grn_qty
        //   ? 1
        //   : 0,
        error:
          +event >
          +matrial[ind]?.tolerance + // tolerance
            +matrial[ind]?.raised_qty - // qty
            +matrial[ind]?.grn_qty //grn_qty
            ? 1
            : 0,
        // error: +event > +matrial[ind]?.tolerance ? 1 : 0,
      };

      setMatrial((val) => ({ ...val, [ind]: obj }));
      arr[inx] = obj;
    } else {
      let obj = {
        ...temp,
        [type]: event,
      };

      setMatrial((val) => ({ ...val, [ind]: obj }));
      // console.log("inx", inx);

      arr[inx] = obj;
    }
    // setDetails(arr);
  };

  const selectBox = (data) => {
    let temp = [...details];

    if (temp.includes(data)) {
      let index = temp.indexOf(data);
      temp.splice(index, 1);
    } else {
      temp.push(data);
    }
    setDetails(temp);
  };

  // console.log("matrial", matrial);
  console.log("poMatrials", poMatrials);

  return (
    <>
      <Col xs={12} className="d-flex">
        {projectType == 2 && (
          <button
            className="bg-primar border-0 rounded-1 my-1 text-white f-1 fs-14 px-4 py-2 ms-auto "
            onClick={() => setAllowRow(+allowRow + 1)}
          >
            Add
          </button>
        )}
      </Col>
      <div style={{ overflowX: "scroll" }} className="user-mange-table w-100">
        <table className="table w-100">
          {projectType == 2 ? (
            <thead>
              <tr>
                <th>S.No</th>
                <th className="text-nowrap">Material Code</th>
                <th style={{ minWidth: "210px" }}>Material Name</th>

                <th>UOM</th>
                {/* <th className="text-nowrap">QTY Indent</th> */}
                {/* <th className="text-nowrap">QTY Received</th> */}
                <th className="text-nowrap">Test Certificate</th>
                <th className="text-nowrap">Waranty Certificate</th>
                <th className="text-nowrap">MRIR Certificate</th>
                {/* <th className="text-nowrap">Raised Qty</th> */}
                <th className="text-nowrap">Received Qty</th>
                <th className="text-nowrap">Basic Rate</th>
                <th className="text-nowrap">Total Value</th>
                {/* <th>MISC</th> */}
                {/* <th className="text-nowrap">Net Value</th> */}
                <th>Remarks</th>
              </tr>
            </thead>
          ) : (
            <thead>
              <tr>
                <th>S.No</th>
                <th className="text-nowrap">Material Code</th>
                <th style={{ minWidth: "210px" }}>Material Name</th>

                <th>UOM</th>
                {/* <th className="text-nowrap">QTY Indent</th> */}
                {/* <th className="text-nowrap">QTY Received</th> */}
                <th className="text-nowrap">Test Certificate</th>
                <th className="text-nowrap">Waranty Certificate</th>
                <th className="text-nowrap">MRIR Certificate</th>
                <th className="text-nowrap">Raised Qty</th>
                <th className="text-nowrap">Previous Received Qty</th>
                <th className="text-nowrap">Received Qty</th>
                <th className="text-nowrap">Basic Rate</th>
                <th className="text-nowrap">Total Value</th>
                {/* <th>MISC</th> */}
                {/* <th className="text-nowrap">Net Value</th> */}
                <th>Remarks</th>
              </tr>
            </thead>
          )}
          {projectType == 2 ? (
            <tbody>
              {rows?.map((item, ind) => {
                return (
                  ind <= allowRow && (
                    <tr key={ind}>
                      <td>
                        <div className="d-flex gap-1">
                          {ind + 1}
                          {matrial && matrial[ind]?.id ? (
                            <input style={{fontSize:"12px"}}
                              type={"checkbox"}
                              checked={details?.includes(matrial[ind]?.id)}
                              onClick={() => selectBox(matrial[ind]?.id)}
                            />
                          ) : (
                            <input type={"checkbox"} disabled />
                          )}
                        </div>
                      </td>
                      <td>
                        <p
                          style={{ minWidth: "140px",fontSize:"12px" }}
                          className="pointer"
                          onClick={() => dropHandler(ind, "code")}
                        >
                          {matrial && matrial[ind]?.matrial_code
                            ? matrial[ind]?.matrial_code
                            : "Select Material Code"}
                        </p>

                        {dropDown == ind && (
                          <ul
                            className="drop-cont"
                            style={{ height: "200px", overflow: "scroll" }}
                          >
                            <li>
                              <input style={{fontSize:"12px"}}
                                className="border-0 bg-lightBlue px-2 py-1 mt-1"
                                value={filter?.boq_code}
                                onChange={(e) =>
                                  setFilter({
                                    ...filter,
                                    boq_code: e.target.value,
                                  })
                                }
                              />
                            </li>
                            {poMatrials?.map((item, index) => (
                              <li
                                key={index}
                                onClick={() => getMatrialDetails(item, ind)}
                              >
                                {item?.material_code ||
                                  item?.material?.material_code}
                              </li>
                            ))}
                          </ul>
                        )}
                      </td>
                      <td>
                        <p
                          style={{ minWidth: "140px",fontSize:"12px" }}
                          className="pointer"
                          onClick={() =>
                            matrial[ind]?.matrial_code
                              ? ""
                              : dropHandler(ind, "name")
                          }
                        >
                          {matrial && matrial[ind]?.purchase_order_details?.material?.name
                            ? matrial[ind]?.name
                            : "Select Material Name"}
                        </p>

                        {dropDowns == ind && (
                          <ul
                            className="drop-cont"
                            style={{ height: "200px", overflow: "scroll" }}
                          >
                            <li>
                              <input
                                className="border-0 bg-lightBlue px-2 py-1 mt-1"
                                value={filter?.boq_code}
                                onChange={(e) =>
                                  setFilter({
                                    ...filter,
                                    boq_code: e.target.value,
                                  })
                                }
                              />
                            </li>
                            {poMatrials?.map((item, index) => (
                              <li
                                key={index}
                                onClick={() => getMatrialDetails(item, ind)}
                              >
                                {item?.name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </td>
                      <td>{matrial && matrial[ind]?.uom}</td>
                      <td>
                        <select
                          className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                          value={matrial && matrial[ind]?.test_certificate}
                          onChange={(e) =>
                            handleChange(
                              +e.target.value,
                              ind,
                              "test_certificate",
                              matrial[ind]
                            )
                          }
                          disabled={matrial[ind]?.id ? false : true}
                        >
                          <option style={{fontSize:"12px"}} value={1}>YES</option>
                          <option style={{fontSize:"12px"}} value={0}>NO</option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                          value={matrial && matrial[ind]?.waranty_certificate}
                          onChange={(e) =>
                            handleChange(
                              +e.target.value,
                              ind,
                              "waranty_certificate",
                              matrial[ind]?.id
                            )
                          }
                          disabled={matrial[ind]?.id ? false : true}
                        >
                          <option style={{fontSize:"12px"}} value={1}>YES</option>
                          <option style={{fontSize:"12px"}} value={0}>NO</option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                          value={matrial && matrial[ind]?.MRIR_certificate}
                          onChange={(e) =>
                            handleChange(
                              +e.target.value,
                              ind,
                              "MRIR_certificate",
                              matrial[ind]?.id
                            )
                          }
                          disabled={matrial[ind]?.id ? false : true}
                        >
                          <option style={{fontSize:"12px"}} value={1}>YES</option>
                          <option style={{fontSize:"12px"}} value={0}>NO</option>
                        </select>
                      </td>
                      <td>
                        <input style={{fontSize:"12px"}}
                          className="border-0 w-100"
                          type={"number"}
                          value={matrial && matrial[ind]?.qty_received}
                          onChange={(e) =>
                            handleChange(
                              +e.target.value,
                              ind,
                              "qty_received",
                              matrial[ind]?.id
                            )
                          }
                          disabled={matrial[ind]?.id ? false : true}
                        />
                      </td>
                      <td className="text-nowrap">
                        <input
                          className="border-0 w-100"
                          type={"number"}
                          value={matrial && matrial[ind]?.basic_rate}
                          onChange={(e) =>
                            handleChange(
                              +e.target.value,
                              ind,
                              "basic_rate",
                              matrial[ind]?.id
                            )
                          }
                          disabled={matrial[ind]?.id ? false : true}
                        />
                      </td>
                      <td className="text-nowrap">
                        {matrial && matrial[ind]?.total_value}
                      </td>
                      <td>
                        <input
                          className="border-0 w-100"
                          value={matrial && matrial[ind]?.remarks}
                          onChange={(e) =>
                            handleChange(
                              e.target.value,
                              ind,
                              "remarks",
                              matrial[ind]?.id
                            )
                          }
                          disabled={matrial[ind]?.id ? false : true}
                        />
                      </td>
                    </tr>
                  )
                );
              })}
            </tbody>
          ) : (
            <tbody>
              {poMatrials?.map((item, ind) => {
                return (
                  <tr>
                    <td>
                      {" "}
                      <div className="d-flex gap-1">
                        {ind + 1}
                        {matrial && matrial[ind]?.id ? (
                          <input
                            type={"checkbox"}
                            checked={details?.includes(matrial[ind]?.id)}
                            onClick={() => selectBox(matrial[ind]?.id)}
                          />
                        ) : (
                          <input type={"checkbox"} disabled />
                        )}
                      </div>
                    </td>
                    <td>{item?.material?.material_code}</td>
                    <td>
                      {item?.material?.name}
                    </td>
                    <td>
                      {
                        item?.uom
                          ? item?.uom
                          : item?.material?.uom
                          ? item?.material?.uom
                          : null
                        // : (
                        //   <select
                        //     className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                        //     value={matrial && matrial[ind]?.uom}
                        //     onChange={(e) =>
                        //       handleChange(+e.target.value, ind, "uom")
                        //     }
                        //   >
                        //     <option value={1}>YES</option>
                        //     <option value={0}>NO</option>
                        //   </select>
                        // )
                      }
                    </td>
                    <td>
                      <select
                        className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                        value={matrial && matrial[ind]?.test_certificate}
                        onChange={(e) =>
                          handleChange(
                            +e.target.value,
                            ind,
                            "test_certificate",
                            matrial[ind]?.id
                          )
                        }
                      >
                       <option  value={1}><p style={{fontSize:"12px"}}>YES</p></option>
                        <option  value={0}><p style={{fontSize:"12px"}}>NO</p></option>
                      </select>
                    </td>
                    <td>
                      <select
                        className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                        value={matrial && matrial[ind]?.waranty_certificate}
                        onChange={(e) =>
                          handleChange(
                            +e.target.value,
                            ind,
                            "waranty_certificate",
                            matrial[ind]?.id
                          )
                        }
                      >
                 <option  value={1}><p style={{fontSize:"12px"}}>YES</p></option>
                        <option  value={0}><p style={{fontSize:"12px"}}>NO</p></option>
                      </select>
                    </td>
                    <td>
                      <select
                        className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                        value={matrial && matrial[ind]?.MRIR_certificate}
                        onChange={(e) =>
                          handleChange(
                            +e.target.value,
                            ind,
                            "MRIR_certificate",
                            matrial[ind]?.id
                          )
                        }
                      >
                        <option  value={1}><p style={{fontSize:"12px"}}>YES</p></option>
                        <option  value={0}><p style={{fontSize:"12px"}}>NO</p></option>
                      </select>
                    </td>
                    <td className=" text-center">{matrial && matrial[ind]?.raised_qty}</td>
                    <td className=" text-center">{matrial && matrial[ind]?.grn_qty}</td>
                    <td>
                      <input style={{textAlign:"center", fontSize:"12px"}}
                        className="border-0 w-100"
                        type={"number"}
                        value={matrial && matrial[ind]?.qty_received}
                        onChange={(e) =>
                          handleChange(
                            +e.target.value,
                            ind,
                            "qty_received",
                            matrial[ind]?.id
                          )
                        }
                      />
                      {matrial && + matrial[ind]?.error == 1 && (
                        <p className="err" style={{ fontSize: "10px" }}>
                          {" "}
                          Exceeded The Quantity
                        </p>
                      )}
                    </td>
                    <td className=" text-center text-center">
                      {/* <input
                        className="border-0 w-100"
                        type={"number"}
                        value={matrial && matrial[ind]?.basic_rate}
                        onChange={(e) =>
                          handleChange(+e.target.value, ind, "basic_rate")
                        }
                      /> */}
                      {matrial && matrial[ind]?.basic_rate}
                    </td>
                    <td className="text-nowrap text-center" >
                      {matrial && matrial[ind]?.total_value}
                    </td>
                    <td>
                      <input
                        className="border-0 w-100  text-center"
                        value={matrial && matrial[ind]?.remarks}
                        onChange={(e) =>
                          handleChange(
                            e.target.value,
                            ind,
                            "remarks",
                            matrial[ind]?.id
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default IsmGinTable;
