import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
import moment from "moment";

const MaterialRequestAmendForm = ({
  setFinalDetails,
  finalDetails,
  setCurrentTable,
  projetList,
  setCurrentTableDetails,
}) => {
  const [showMaterialDetails, setShowMaterialDetails] = useState(false);
  // const [headOffice, setHeadOffice] = useState("");
  const [selMatId, setSelMatId] = useState(null);
  const [materialList, setMaterialList] = useState([]);
  const [materialType, setMaterialType] = useState("");
  const [projectName, setProjectName] = useState("");
  const [prNo, setPrNo] = useState("");
  const [requestionType, setRequestionType] = useState("");
  const [pRDDate, setPRDDate] = useState("");
  const [materialTypeErr, setMaterialTypeErr] = useState(false);
  const [projectNameErr, setProjectNameErr] = useState(false);
  const [companyErr, setCompanyErr] = useState(false);
  const [prNoErr, setPrNoErr] = useState(false);
  const [requestionTypeErr, setRequestionTypeErr] = useState(false);
  const [pRDDateErr, setPRDDateErr] = useState(false);
  const [company_id, setcompany_id] = useState("");

  const requestionTypeHandler = (e) => {
    let sel_ele = +e.target.value;
    setRequestionType(e.target.value);
  };

  const materialSelectHandler = () => {
    const selectedMaterial = materialList?.filter(
      (material) => material?.id === +selMatId
    );

    let data = {
      material_request_id: +selMatId,
    };
    api.matrialRequest(data).then((res) => {
      if (res?.status == "success") {
        setCurrentTable("final");
        setCurrentTableDetails(res?.material_request);
        console.log("res:", res);
      }
    });

    // UNCOMMEDED
    // let data = {
    //   material_request_id: +selMatId,
    // };
    // api.material_request_view(+selMatId).then((res) => {
    //   if (res?.status == "success") {
    //     console.log("res:", res);
    //   }
    // });

    setFinalDetails({
      ...finalDetails,
      mr_date: selectedMaterial[0]?.mr_date,
      Request_type: selectedMaterial[0]?.request_type,
      mr_no: selectedMaterial[0]?.mr_no,
      Parent_id: +selMatId,
      company_id: company_id,
    });
    setPrNo(selectedMaterial[0]?.mr_no);
    setPRDDate(selectedMaterial[0]?.mr_date);

    setRequestionType(selectedMaterial[0]?.request_type);

    api.material_show(+selMatId).then((res) => {
      console.log("material_detail_amend-11", res);
      if (res?.status == "success") {
        if (res?.material?.length > 0) {
          setCurrentTableDetails(res?.material);
          setMaterialType(res?.material?.material_type);
          setFinalDetails({ ...finalDetails, Parent_id: +selMatId });
        }
      }
    });

    setShowMaterialDetails(true);
  };

  const getMaterialList = (pro_id) => {
    let data = {
      project_id: +pro_id,
    };
    api.matrialList(data).then((res) => {
      if (res?.status == "success") {
        console.log("ss", res);
        setMaterialList(res?.material_requests);
        setFinalDetails({
          ...finalDetails,
          Project_id: pro_id,
        });
      }
    });
  };

  const getMaterials = (pro_id) => {
    api.project_material_request_view(pro_id).then((res) => {
      console.log("res-project-mater-amend", res);
      let projetList = res?.project_material_requests;
      if (res?.status === "success") {
        setMaterialList(projetList);
        setFinalDetails({
          ...finalDetails,
          Project_id: pro_id,
        });
      }
    });
  };

  const projectSelectionHandler = (e) => {
    let projec_id = +e.target.value;
    setProjectName(projec_id);
    getMaterialList(projec_id);
  };

  const submitHandler = () => {
    if (
      projectName?.length == 0 ||
      requestionType?.length == 0 ||
      pRDDate?.length == 0 ||
      prNo?.length == 0
    ) {
      setProjectNameErr(true);
      setPrNoErr(true);
      setRequestionTypeErr(true);
      setPRDDateErr(true);
    } else {
      if (+requestionType === 1) {
        setMaterialTypeErr(false);
        api.material_request_through_boq(+projectName).then((res) => {
          console.log("boq_res_through", res?.boq_details);
          setCurrentTable("boq");
          setCurrentTableDetails(res?.boq_details);
        });
      } else {
        let id = `?material_type=${materialType}`;
        setMaterialTypeErr(false);
        api.material_request_through_material(id).then((res) => {
          console.log("material_res_through", res);
          if (res?.status === "success") {
            setCurrentTable("material");
            setCurrentTableDetails(res?.materials);
          }
        });
      }
    }
  };

  const [listCompany, setCompanyList] = useState([]);
  const getCompanies = () => {
    api
      .getCompanies()
      .then((res) => {
        if (res?.status == "success") {
          let finder = res?.companies?.filter((i) => i?.status == 1);
          if (finder?.length > 0) {
            setCompanyList(finder);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  console.log("materialList", materialList);

  const handleCompany = (event) => {
    setcompany_id(event);
    setFinalDetails({
      ...finalDetails,
      company_id: event,
    });
  };

  // console.log("finalDetails", finalDetails, company_id);

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">Material Request Amendment</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          <select
            className="border-0 bg-lightBlue px-2 py-2 w-90 mt-1"
            placeholder="Select Company"
            value={company_id}
            onChange={(e) => handleCompany(e.target.value)}
          >
            <option value={""}>Select Company</option>
            {listCompany?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          {company_id?.length == 0 && projectNameErr && (
            <p className="err">Select Company</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <select
            onChange={(e) => projectSelectionHandler(e)}
            value={projectName}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
          >
            <option>Select Project</option>
            {projetList?.map((project) => (
              <option key={project?.id} value={project?.id}>
                {project?.name}
              </option>
            ))}
          </select>

          {projectName?.length == 0 && projectNameErr && (
            <p className="err">enter project name</p>
          )}
        </Col>
        {!showMaterialDetails && (
          <div className="user-mange-table">
            <table>
              <thead>
                <tr>
                  <th className="nowrap">S.no</th>
                  <th className="nowrap"></th>
                  <th className="nowrap">Head Office</th>
                  <th className="nowrap">Parent MR No</th>
                  <th className="nowrap">MR Date</th>
                  <th className="nowrap">MR No</th>
                  <th className="nowrap">Requested Type</th>
                  <th className="nowrap">Created At</th>
                </tr>
              </thead>
              <tbody>
                {materialList?.map((item, ind) => {
                  return (
                    // item?.approved_by != null &&
                    // item?.status == 1 &&
                    item?.status == 2 && (
                      <tr key={ind}>
                        <td>0{ind + 1}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={selMatId == item?.id}
                            value={item?.id}
                            className="pointer"
                            onChange={(e) => setSelMatId(e.target.value)}
                          />
                        </td>
                        <td>{item.head_office}</td>
                        <td>{item?.parent_request?.mr_no}</td>
                        <td>{item.mr_date}</td>
                        <td>{item.mr_no}</td>
                        <td>{item.request_type == 1 ? "BOQ" : "Material"}</td>
                        <td>
                          {moment(item?.updated_at).format("MMM Do YYYY")}
                        </td>
                      </tr>
                    )
                  );
                })}
              </tbody>
            </table>
            <Col xs={12} sm={12} className="inputinner-box text-start">
              <button
                className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
                disabled={materialList.length == 0}
                onClick={materialSelectHandler}
              >
                Fetch
              </button>
            </Col>
          </div>
        )}

        {showMaterialDetails && (
          <>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Material Type</label>
              <select
                className="input-select bg-lightBlue ms-0"
                style={{
                  border: "2px solid #f1f7ff",
                  width: "90%",
                  marginRight: "auto",
                }}
                onChange={(e) => setMaterialType(e.target.value)}
                value={materialType}
              >
                <option value={""}>select Material type</option>
                <option value={"ELECTRICAL"}>ELECTRICAL</option>
                <option value={"CIVIL"}>CIVIL</option>
                <option value={"MECHANICAL"}>MECHANICAL</option>
                <option value={"HVAC"}>HVAC</option>
                <option value={"FIRE FIGHTING"}>FIRE FIGHTING</option>
                <option value={"ELV"}>ELV</option>
                <option value={"OTHERS"}>OTHERS</option>
                <option value={"PHE"}>PHE</option>
                <option value={"PROCESS PIPE"}>PROCESS PIPE</option>
                <option value={"SOLVENT PIPE"}>SOLVENT PIPE</option>
              </select>
              {materialType?.length == 0 && materialTypeErr && (
                <p className="err">enter material type</p>
              )}
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">MR No</label>
              <input
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter PR No"
                onChange={(e) => setPrNo(e.target.value)}
                value={prNo}
              />
              {prNo?.length == 0 && prNoErr && (
                <p className="err">enter MR no</p>
              )}
            </Col>

            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">Requestion Type</label>
              <select
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                value={requestionType}
                onChange={(e) => requestionTypeHandler(e)}
              >
                <option>Select Requestion Type</option>
                <option value={1}>BOQ</option>
                <option value={2}>Direct PO</option>
              </select>
              {requestionType?.length == 0 && requestionTypeErr && (
                <p className="err">enter requestion type</p>
              )}
            </Col>
            <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
              <label className="w-100">MR Date</label>
              <input
                type="date"
                className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
                placeholder="Enter Date"
                onChange={(e) => setPRDDate(e.target.value)}
                value={pRDDate}
              />
              {pRDDate?.length == 0 && pRDDateErr && (
                <p className="err">enter MR date</p>
              )}
            </Col>
            <Col xs={12} sm={12} className="inputinner-box text-start">
              <button
                className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
                onClick={submitHandler}
              >
                Fetch
              </button>
            </Col>
          </>
        )}
      </Col>
    </Col>
  );
};

export default MaterialRequestAmendForm;
