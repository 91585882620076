import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { logo } from "../../assets/img";
import { api } from "../../constants/Services";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import Header from "../../component/Header/Header";
import useUser from "../../constants/Storage/userData";
// import html2PDF from "jspdf-html2canvas";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { type } from "@testing-library/user-event/dist/type";

function GRNOutputScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const types = searchParams?.get("type");
  const { user } = useUser();
  const [poPrintDetails, setPOPrintDetails] = useState([]);
  const [terms, setTerms] = useState([]);
  const [directPoAttributes, setDirectPoAttributes] = useState([]);
  const [poAnnex, setPOAnnex] = useState([]);
  const [numToWords, setNumToWords] = useState("");
  const [poTable, setPoTable] = useState([]);
  const [approval, setapproval] = useState("");

  // poPrintDetails?.material_request?.request_type == 1
  // console.log("location", location);
  function doConvert(numberInput) {
    let oneToTwenty = [
      "",
      "one ",
      "two ",
      "three ",
      "four ",
      "five ",
      "six ",
      "seven ",
      "eight ",
      "nine ",
      "ten ",
      "eleven ",
      "twelve ",
      "thirteen ",
      "fourteen ",
      "fifteen ",
      "sixteen ",
      "seventeen ",
      "eighteen ",
      "nineteen ",
    ];
    let tenth = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    let numToWord = numberInput.toString();
    if (numToWord.includes(".")) numToWord = numToWord.split(".")[0];

    let num = ("000000000" + numToWord)
      .slice(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);

    if (!num) return;

    let outputText =
      num[1] != 0
        ? (oneToTwenty[Number(num[1])] ||
            `${tenth[num[1][0]]} ${oneToTwenty[num[1][1]]}`) + " crore "
        : "";
    outputText +=
      num[2] != 0
        ? (oneToTwenty[Number(num[2])] ||
            `${tenth[num[2][0]]} ${oneToTwenty[num[2][1]]}`) + " lakh "
        : "";

    outputText +=
      num[3] != 0
        ? (oneToTwenty[Number(num[3])] ||
            `${tenth[num[3][0]]} ${oneToTwenty[num[3][1]]}`) + "thousand "
        : "";
    outputText +=
      num[4] != 0
        ? (oneToTwenty[Number(num[4])] ||
            `${tenth[num[4][0]]} ${oneToTwenty[num[4][1]]}`) + "hundred "
        : "";
    outputText +=
      num[5] != 0
        ? (oneToTwenty[Number(num[5])] ||
            `${tenth[num[5][0]]} ${oneToTwenty[num[5][1]]}`) + " "
        : "";
    // console.log("Result-1 = ", outputText);

    outputText = outputText?.charAt(0).toUpperCase() + outputText?.slice(1);
    // console.log("Result = ", outputText);

    setNumToWords(outputText);
  }

  const getList = (paramId) => {
    api.grnView(paramId ? paramId : location.state?.id).then((res) => {
      if (res?.status == "success") {
        setPOPrintDetails(res?.grn);
        const totalValue = res?.grn?.grn_detail?.reduce(
          (acc, po) => acc + +po?.total_price,
          0
        );
        setapproval(res?.grn?.approval[0]?.employee?.name);
        if (totalValue) {
          doConvert(totalValue);
        }
      }
    });
  };

  const getInvoiceList = () => {
    api.invoiceView(searchParams?.get("id")).then((res) => {
      if (res?.status == "success") {
        setPOPrintDetails(res?.invoice);
        const totalValue = res?.invoice?.invoice_detail?.reduce(
          (acc, po) => acc + po?.net_price,
          0
        );
        setapproval(res?.invoice?.approval[0]?.employee?.name);
        if (totalValue) {
          doConvert(totalValue);
        }
      }
    });
  };

  // console.log("approval", approval);

  useEffect(() => {
    if (searchParams?.get("type") == "invoice") {
      getInvoiceList();
    } else if (searchParams?.get("id")) {
      getList(searchParams?.get("id"));
    } else {
      getList();
    }
  }, [searchParams?.get("id")]);

  // console.log("searchParams", searchParams?.get("id"));

  const downloadPdfDocument = (div_id) => {
    const divToPrint = document.getElementById(div_id);

    // return false;
    window.scrollTo(0, 0);
    html2canvas(divToPrint).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 190;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      // return false;
      let heightLeft = imgHeight;
      const doc = new JsPDF("pt", "mm");
      let position = 0;
      doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight + 24);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight + 24);
        heightLeft -= pageHeight;
      }
      doc.save("PoInvoice.pdf");
    });
  };

  // console.log("types", types);

  return (
    <Col
      xs={12}
      className="py-2 px-1 p-md-3 w-lg-90"
      // style={{ width: "1039px", height: "1216px" }}
    >
      <Header />
      <div
        // style={{ height: "100vh", overflow: "hidden" }}
        id="po-out-main-cont"
        className="po-main-box my-3 avoid-break"
      >
        <section className="p-3">
          <header className="d-flex ">
            <Col xs={1} className="d-flex my-3 ">
              <img
                className="ms-4"
                style={{ width: "70px", height: "70px" }}
                src={logo}
                alt="logo"
              />
            </Col>
            <Col
              xs={7}
              className="d-flex flex-column po-vendor-detail-invoice-box my-3"
            >
              <h5 className="mx-auto py-1">
                Crescon Projects and Service Pvt Ltd
              </h5>
              <p className="py-1 mx-auto ">
                Corp. Off: No. 158, 3rd Floor, Gulecha Towers, Arcot Road,
              </p>
              <p className="py-1 mx-auto ">Vadapalani Chennai – 600 026</p>

              <h6 className="m-auto py-1">
                {searchParams?.get("type") == "invoice" ? "Invoice" : "GRN"}
              </h6>
            </Col>
            <Col xs={4} className="my-auto ">
              <p className="py-1 fs-13px">
                ISO 9001:2015 : Quality Management System
              </p>
              <p className="py-1 fs-13px">
                ISO 14001:2015 : Environmental Management System
              </p>
              <p className="py-1 fs-13px">
                ISO 45001:2018 : Occupational Health & Safety Management System
              </p>
            </Col>
          </header>
          <main className="">
            <section className="d-flex">
              <Col xs={6} className="po-vendor-detail-box p-3">
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>Project Name</label>
                  </Col>
                  <Col md={8}>
                    <p>{poPrintDetails?.project?.name}</p>
                  </Col>
                </div>
                <div className="d-flex  my-1 py-1">
                  <Col md={4}>
                    <label>Vendor Name</label>
                  </Col>
                  <Col md={8}>
                    <p>
                      {poPrintDetails?.vendor?.company_name
                        ? poPrintDetails?.vendor?.company_name
                        : poPrintDetails?.vendor?.name}
                    </p>
                  </Col>
                </div>
                <div className="d-flex ">
                  <Col md={4}>
                    <label>GRN No.</label>
                  </Col>
                  <Col md={8}>
                    <p>
                      {poPrintDetails?.grn_no || poPrintDetails?.grn?.grn_no}
                    </p>
                  </Col>
                </div>
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label>GRN Date</label>
                  </Col>
                  <Col md={8}>
                    <p>
                      {poPrintDetails?.grn_date ||
                        poPrintDetails?.grn?.grn_date}
                    </p>
                  </Col>
                </div>
                <div className="d-flex ">
                  <Col md={4}>
                    <label>WareHouse</label>
                  </Col>

                  <Col md={8}>
                    <p>
                      {poPrintDetails?.warehouse?.name ||
                        poPrintDetails?.grn?.warehouse?.name}
                    </p>
                    {/* <p>
                      {poPrintDetails?.warehouse?.address ||
                        poPrintDetails?.grn?.warehouse?.address}
                    </p> */}
                  </Col>
                </div>
              </Col>
              <Col xs={6} className="po-vendor-detail-invoice-box p-3">
                {types !== "invoice" && (
                  <div className="d-flex ">
                    <label>Type</label>
                    <p>{poPrintDetails?.type == 2 ? "Cash Purchase" : "PO"}</p>
                  </div>
                )}
                <div className="d-flex ">
                  <label>Po No.</label>
                  <p>
                    {poPrintDetails?.po_no
                      ? poPrintDetails?.po_no
                      : poPrintDetails?.grn?.purchase_order?.po_no
                      ? poPrintDetails?.grn?.purchase_order?.po_no
                      : poPrintDetails?.purchase_order?.po_no
                      ? poPrintDetails?.purchase_order?.po_no
                      : "-"}
                  </p>
                </div>
                <div className="d-flex ">
                  <label>Po Date</label>
                  <p>
                    {poPrintDetails?.po_date
                      ? poPrintDetails?.po_date
                      : poPrintDetails?.grn?.purchase_order?.po_date
                      ? poPrintDetails?.grn?.purchase_order?.po_date
                      : poPrintDetails?.purchase_order?.po_date
                      ? poPrintDetails?.purchase_order?.po_date
                      : "-"}
                  </p>
                </div>
                {types !== "invoice" && (
                  <div>
                    <div className="d-flex ">
                      <Col md={4}>
                        <label>Dc No.</label>
                      </Col>
                      <Col md={8}>
                        <p>
                          {poPrintDetails?.dc_no
                            ? poPrintDetails?.dc_no
                            : poPrintDetails?.grn?.dc_no
                            ? poPrintDetails?.grn?.dc_no
                            : "-"}
                        </p>
                      </Col>
                    </div>
                    <div className="d-flex  py-1 my-1">
                      <Col md={4}>
                        <label>Dc Date</label>
                      </Col>
                      <Col md={8}>
                        <p>
                          {poPrintDetails?.dc_date
                            ? poPrintDetails?.dc_date
                            : poPrintDetails?.grn?.dc_date
                            ? poPrintDetails?.grn?.dc_date
                            : "-"}
                        </p>
                      </Col>
                    </div>
                  </div>
                )}
                {/* <div className="d-flex ">
                  <Col md={4}>
                    <label>Invoice No.</label>
                  </Col>
                  <Col md={8}>
                    <p>
                      {poPrintDetails?.invoice_no || poPrintDetails?.grn?.dc_no}
                    </p>
                  </Col>
                </div>
            
                <div className="d-flex  py-1 my-1">
                  <Col md={4}>
                    <label> Invoice Date</label>
                  </Col>
                  <Col md={8}>
                    <p>
                      {poPrintDetails?.invoice_date ||
                        poPrintDetails?.grn?.dc_date}
                    </p>
                  </Col>
                </div> */}
                {types == "invoice" && (
                  <div className="d-flex ">
                    <Col md={4}>
                      <label> REF Invoice No.</label>
                    </Col>
                    <Col md={8}>
                      <p>
                        {poPrintDetails?.grn_invoice_no ||
                          poPrintDetails?.grn?.dc_no}
                      </p>
                    </Col>
                  </div>
                )}
                {types == "invoice" && (
                  <div className="d-flex  py-1 my-1">
                    <Col md={4}>
                      <label>Invoice No</label>
                    </Col>
                    <Col md={8}>
                      <p>
                        {poPrintDetails?.invoice_no ||
                          poPrintDetails?.grn?.dc_date}
                      </p>
                    </Col>
                  </div>
                )}
                {types == "invoice" && (
                  <div className="d-flex  py-1 my-1">
                    <Col md={4}>
                      <label>Invoice Date</label>
                    </Col>
                    <Col md={8}>
                      <p>
                        {poPrintDetails?.invoice_date ||
                          poPrintDetails?.grn?.dc_date}
                      </p>
                    </Col>
                  </div>
                )}
              </Col>
            </section>
            {/* <section className="d-flex">
              <Col
                style={{ borderRight: "1px solid black" }}
                xs={6}
                className="po-vendor-detail-invoice-box p-3"
              >
                <h6>Invoicing Address:</h6>

                <div className="d-flex flex-wrap">
                  <Col md={4}>
                    <label>Head Office</label>
                  </Col>
                  <Col md={8}>
                    <p>Crescon Projects and Services Pvt Ltd</p>
                  </Col>
                  <Col md={4}>
                    <label>Address</label>
                  </Col>
                  <Col md={8}>
                    <p>
                      3rd Floor, Gulecha Towers 158, Arcot Road, Vadapalani
                      Chennai – 600 026
                    </p>
                  </Col>

                  <Col md={4} className="mt-4">
                    <label>Contact Person</label>
                  </Col>
                  <Col md={8} className="mt-4">
                    <p>M. Janakiraman</p>
                  </Col>
                  <Col md={4}>
                    <label>Contact No</label>
                  </Col>
                  <Col md={8}>
                    <p>044 - 2365 2551</p>
                  </Col>
                  <Col md={4}>
                    <label>Mail ID</label>
                  </Col>
                  <Col md={8}>
                    <p>purchase@cresconprojects.com</p>
                  </Col>
                  <Col md={4}>
                    <label>Gst No</label>
                  </Col>
                  <Col md={8}>
                    <p>33AAECC6175F1ZT</p>
                  </Col>
                </div>
              </Col>
            </section> */}
            <Col className="p-2  m-1 text-center">
              <div
                style={{ overflowX: "scroll" }}
                className="po-out-table bg-white"
              >
                <table
                  id="po-output-table"
                  className="table table-responsive-xl"
                >
                  <thead>
                    {types == "invoice" ? (
                      <tr>
                        <th>Sl.No</th>
                        <th>Material Code</th>
                        <th>Material Description</th>
                        <th>Quantity</th>
                        <th>Basic Rate</th>
                        <th>Total Value</th>
                        {/* <th>TDS(%)</th>
                        <th>TCS(%)</th> */}
                        <th>MISC</th>
                        <th>Net Value</th>
                      </tr>
                    ) : (
                      <tr>
                        <th>Sl.No</th>
                        {/* <th>Material Code</th> */}
                        <th style={{ width: "300px" }}>Material Name</th>
                        <th>UOM</th>
                        <th
                          style={{
                            overflowWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Test Certificate
                        </th>
                        <th
                          style={{
                            overflowWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          Waranty Certificate
                        </th>
                        <th
                          style={{
                            overflowWrap: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          MRIR Certificate
                        </th>
                        <th>QTY Received</th>
                        <th>Basic Rate</th>
                        <th>Total Value</th>
                        {/* <th>Remarks</th> */}
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {types == "invoice"
                      ? poPrintDetails?.invoice_detail?.map((item, ind) => {
                          return (
                            <tr>
                              <td style={{ fontSize: "12px" }}>{ind + 1}</td>
                              <td
                                style={{ fontSize: "12px", textAlign: "start" }}
                              >
                                {item?.material?.material_code}
                              </td>
                              <td style={{ fontSize: "12px" }}>
                                {item?.material?.name}
                              </td>
                              <td style={{ fontSize: "12px" }}>
                                {item?.received_qty}
                              </td>
                              <td style={{ fontSize: "12px" }}>
                                {item?.basic_price}
                              </td>
                              <td style={{ fontSize: "12px" }}>
                                {item?.total_price}
                              </td>
                              {/* <td>{item?.tds_percent}</td>
                              <td>{item?.tcs_percent}</td> */}
                              <td style={{ fontSize: "12px" }}>
                                {item?.gst_value}
                              </td>
                              <td style={{ fontSize: "12px" }}>
                                {item?.net_price}
                              </td>
                            </tr>
                          );
                        })
                      : poPrintDetails?.grn_detail?.map((item, ind) => {
                          return (
                            <>
                              <tr key={item?.id}>
                                <td style={{ fontSize: "12px" }}>{ind + 1}</td>
                                {/* <td>{item?.material?.material_code}</td> */}
                                <td style={{ fontSize: "12px" }}>
                                  {" "}
                                  {item?.material?.name}
                                </td>
                                <td style={{ fontSize: "12px" }}>
                                  {" "}
                                  {item?.material?.uom}
                                </td>
                                <td style={{ fontSize: "12px" }}>
                                  {item?.test_certificate == 1 ? "Yes" : "No"}
                                </td>
                                <td style={{ fontSize: "12px" }}>
                                  {item?.warranty_certificate == 1
                                    ? "Yes"
                                    : "No"}
                                </td>
                                <td style={{ fontSize: "12px" }}>
                                  {item?.mrir_certificate == 1 ? "Yes" : "No"}
                                </td>
                                <td style={{ fontSize: "12px" }}>
                                  {item?.received_qty}
                                </td>
                                <td style={{ fontSize: "12px" }}>
                                  {item?.basic_price}
                                </td>
                                <td style={{ fontSize: "12px" }}>
                                  {item?.total_price}
                                </td>
                                {/* <td>{item?.remarks}</td> */}
                              </tr>
                            </>
                          );
                        })}
                  </tbody>
                </table>
              </div>
            </Col>

            <div className="d-flex gap-2 m-3 page_break_before_after">
              <label>
                <p style={{ fontSize: "14px" }}>Amount In Words</p>
              </label>
              <p className="" style={{ fontSize: "14px" }}>
                ({numToWords ? `Rupees ${numToWords} Only` : "-"})
              </p>
            </div>

            <section className="po-terms-box page_break_before_after">
              <h6>Remarks</h6>
              <div>{poPrintDetails?.remarks}</div>
            </section>

            <section>
              <div className="d-flex justify-content-between w-90 page_break_before_after">
                <div>
                  <p className="text-capitalize">
                    {poPrintDetails?.created_user?.name ||
                      poPrintDetails?.employee?.name}
                  </p>
                  <label>Prepared</label>
                </div>
                <div>
                  <p className="text-capitalize">{approval ? approval : "-"}</p>
                  <label>Approved</label>
                </div>
              </div>
            </section>
          </main>
          <footer id="page-break" className="d-flex">
            <Col
              xs={6}
              style={{ borderRight: "1px solid black", minHeight: "6rem" }}
              className="d-flex flex-column"
            >
              <p className="">
                Please Sign and Return Duplicate Copy as a Token of Acceptance
              </p>
              <p className="mt-auto mb-1">Vendor Acceptance</p>
            </Col>
            <Col xs={6} className="d-flex flex-column">
              <p className="ms-auto me-2 pe-3">
                For Crescon Projects and Service Pvt Ltd
              </p>
              <p className="mt-auto ms-auto me-4 mb-1">Authorised Signatory</p>
            </Col>
          </footer>
        </section>
      </div>{" "}
      <div className="d-flex justify-content-between">
        <div>
          {poPrintDetails?.status == 2 && (
            <button
              class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
              onClick={() => downloadPdfDocument("po-out-main-cont")}
            >
              Print
            </button>
          )}
        </div>
        <div className="">
          {/* {searchParams?.get("id") ? (
            <Link
              class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2 "
              to={`/procurement/puchase-order-list?projectname=${searchParams?.get(
                "projectname"
              )}&project=${searchParams?.get("project")}`}
            >
              Close
            </Link>
          ) : (
          <button
            class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
            onClick={() =>
              types == "invoice"
                ? navigate("/ism/invoice-booking-list")
                : navigate("/ism/grn-list")
            }
          >
            Close
          </button>
         
          )} */}
          {searchParams?.get("id") &&
          searchParams?.get("method") !== "noti" &&
          searchParams?.get("type") !== "invoice" ? (
            <Link
              class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2 "
              to={`/ism/grn-list?projectID=${searchParams?.get("projectID")}`}
            >
              Close
            </Link>
          ) : searchParams?.get("id") &&
            searchParams?.get("method") == "noti" &&
            searchParams?.get("type") !== "invoice" ? (
            <button
              class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
              onClick={() => navigate("/notification")}
            >
              Close
            </button>
          ) : searchParams?.get("id") &&
            searchParams?.get("type") == "invoice" &&
            searchParams?.get("method") !== "noti" ? (
            <Link
              class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2 "
              to={`/ism/invoice-booking-list?projectID=${searchParams?.get(
                "projectID"
              )}`}
            >
              Close
            </Link>
          ) : (
            searchParams?.get("id") &&
            searchParams?.get("type") == "invoice" &&
            searchParams?.get("method") == "noti" && (
              <button
                class="bg-primar border-0 rounded-1 m-3 text-white f-1 fs-14 px-4 py-2"
                onClick={() => navigate("/notification")}
              >
                Close
              </button>
            )
          )}
        </div>
      </div>
    </Col>
  );
}

export default GRNOutputScreen;
