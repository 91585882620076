import React, { Component } from "react";
import Chart from "react-apexcharts";

function RevenueLineChart({ showSelect }) {
  console.log(showSelect);
  const chart_one = {
    // tooltip: {
    //   onDatasetHover: {
    //     highlightDataSeries: false,
    //   },
    // },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    series: [
      {
        name: "Yearly / K",
        data: [5000, 59870, 55930, 66970, 80970, 70120, 82110],
      },
    ],
    options: {
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      },
      colors: ["#008920", "#2E93fA"],
    },
  };

  return (
    <Chart
      options={chart_one.options}
      series={chart_one.series}
      type="line"
      width="400"
      height="300"
    />
  );
}

export default RevenueLineChart;
