import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";
import { stateList } from "../../constants/StaticData";

const CompanyManagentView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [btn, setBtn] = useState(false);

  const [name, setName] = useState("");
  const [GSTNo, setGSTNo] = useState("");
  const [stateName, setStateName] = useState("");
  const [address, setAddress] = useState("");
  const [person, setPerson] = useState("");
  const [personNo, setPersonNo] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [personEmailErr, setPersonEmailErr] = useState(false);
  const [personErr, setPersonErr] = useState(false);
  const [personNoErr, setPersonNoErr] = useState(false);
  const [GSTNoErr, setGSTNoErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [stateNameErr, setStateNameErr] = useState(false);
  const [addressErr, setAddressErr] = useState(false);

  const submitHandler = () => {
    if (
      name?.length == 0 ||
      GSTNo?.length == 0 ||
      stateName?.length == 0 ||
      address?.length == 0 ||
      person?.length == 0 ||
      personEmail?.length == 0 ||
      personNo?.length == 0
    ) {
      setNameErr(true);
      setGSTNoErr(true);
      setStateNameErr(true);
      setAddressErr(true);
      setPersonEmailErr(true);
      setPersonNoErr(true);
      setPersonErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("name", name);
      formdata.append("address", address);
      formdata.append("state", stateName);
      formdata.append("gst_no", GSTNo);
      formdata.append("contact_person", person);
      formdata.append("contact_no", personNo);
      formdata.append("contact_email", personEmail);

      setBtn(true);
      if (location?.state?.type == "edit") {
        api
          .updtateCompanies(location?.state?.id, formdata)
          .then((res) => {
            if (res?.status == "success") {
              navigate("/home/company-masters");
            } else {
              console.log("res", res);
            }
            setBtn(false);
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      } else {
        api
          .addCompanies(formdata)
          .then((res) => {
            if (res?.status == "success") {
              navigate("/home/company-masters");
            } else {
              console.log("res", res);
            }
            setBtn(false);
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  const getViewAPi = () => {
    api
      .getCompaniesView(location?.state?.id)
      .then((res) => {
        let data = res?.company;
        if (data) {
          setName(data?.name);
          setGSTNo(data?.gst_no);
          setStateName(data?.state);
          setAddress(data?.address);
          setPerson(data?.contact_person);
          setPersonEmail(data?.contact_email);
          setPersonNo(data?.contact_no);
        }
      })
      .catch((Err) => {
        console.log("Err", Err);
      });
  };

  useEffect(() => {
    if (location?.state?.id) {
      getViewAPi();
    }
  }, []);

  return (
    <Col xs={12} className={`py-2 px-1 p-md-3  m-0 w-100 m-0`}>
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <h4 className="w-100 text-start mb-5">
          Company Master {location?.state?.type == "edit" ? "Edit" : "Add"}
        </h4>
        <Col className="d-flex flex-wrap mx-lg-3">
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Name</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            {name?.length == 0 && nameErr && <p className="err">Enter Name</p>}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">GST No.</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="GST No"
              onChange={(e) => setGSTNo(e.target.value)}
              value={GSTNo}
            />
            {GSTNo?.length == 0 && GSTNoErr && (
              <p className="err">Enter GST No</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Contact Person</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Contact Person"
              onChange={(e) => setPerson(e.target.value)}
              value={person}
            />
            {person?.length == 0 && personErr && (
              <p className="err">Enter Contact Person</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Contact Number</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Contact Number"
              onChange={(e) => setPersonNo(e.target.value)}
              value={personNo}
            />
            {personNo?.length == 0 && personNoErr && (
              <p className="err">Enter Contact Number</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Email</label>
            <input
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Email"
              onChange={(e) => setPersonEmail(e.target.value)}
              value={personEmail}
            />
            {personEmail?.length == 0 && personEmailErr && (
              <p className="err">Enter Email</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">State</label>
            <select
              id="state-dropdown"
              onChange={(e) => setStateName(e.target.value)}
              value={stateName}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 pointer"
            >
              <option>Select State</option>
              {stateList?.map((item, ind) => {
                return (
                  <option value={item?.name} key={ind}>
                    {item?.name}
                  </option>
                );
              })}
            </select>
            {stateName?.length == 0 && stateNameErr && (
              <p className="err">Select State</p>
            )}
          </Col>
          <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
            <label className="w-100">Address</label>
            <textarea
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Address"
              onChange={(e) => setAddress(e.target.value)}
              value={address}
            />
            {address?.length == 0 && addressErr && (
              <p className="err">Enter Address</p>
            )}
          </Col>
        </Col>
        <div className="d-flex justify-content-start mt-2 mx-3">
          <button
            className={`bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2 ${
              btn ? "opacity-50" : ""
            }`}
            onClick={() => submitHandler()}
            disabled={btn}
          >
            Save
          </button>
        </div>
      </Col>
    </Col>
  );
};

export default CompanyManagentView;
