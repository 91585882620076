import { Modal } from "react-bootstrap";
import React from "react";
import { tickIcon } from "../assets/img";

function StatusModal({ statusModalClose, showStatus, statusMessage }) {
  return (
    <Modal
      show={showStatus}
      onHide={statusModalClose}
      backdrop="static"
      centered
      keyboard={false}
    >
      <Modal.Header className="border-0"></Modal.Header>
      <Modal.Body>
        <div className="w-50 d-flex gap-3 align-items-center ms-auto me-5">
          <p className="fs-5 fw-bold">Saved</p>
          <img style={{ width: "20px" }} src={tickIcon} alt="tick_icon" />
        </div>
        <p className="fs-6 w-50 my-2 mx-auto">
          {statusMessage?.work_order?.order_no}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 d-flex pt-0">
        <button
          className="ms-auto me-3 border-0 pointer px-3 py-1 rounded-1 text-white bg-primary"
          onClick={() => {
            statusModalClose();
            window.location.reload();
          }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default StatusModal;
