import React, { useState } from "react";
import { Col } from "react-bootstrap";
import Header from "../component/Header/Header";
import ProjectCreationForm from "../component/ProjectCreation/ProjectCreationForm";
import ProjectCreationTab from "../component/ProjectCreation/ProjectCreationTabSec";

const ProjectCreation = () => {
  const [projectForm, setProjectForm] = useState({
    company_id: "",
    name: "",
    project_no: "",
    date: "",
  });
  const [formError, setFormError] = useState([]);

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <ProjectCreationForm
        formError={formError}
        projectForm={projectForm}
        setProjectForm={setProjectForm}
      />

      <ProjectCreationTab
        setFormError={setFormError}
        projectForm={projectForm}
        setProjectForm={setProjectForm}
      />
    </Col>
  );
};

export default ProjectCreation;
