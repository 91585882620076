import React, { useState, useEffect } from "react";
import eye_icon from "../../assets/icons/common/Eye.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";
import { PaginationControl } from "react-bootstrap-pagination-control";

function MaterialReturnList() {
  const [selectedProject, setSelectedProject] = useState("");
  const [MrList, setMrList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const getProjects = () => {
    api.project_list().then((res) => {
      setProjectList(res?.projects);
    });
  };
  useEffect(() => {
    getProjects();
  }, []);

  const getMaterialsReturnList = (id, page) => {
    let param = `?page=${page}`;
    let obj = {
      project_id: id,
    };
    api
      .matertialReturnList(param, obj)
      .then((res) => {
        if (res?.status == "success") {
          let temp = [];

          res?.material_return?.map((item, ind) => {
            item?.material_return_detail?.map((rtn, inx) => {
              temp.push({
                ...rtn,
                employee: item?.employee,
                purchase_order: item?.purchase_order,
                material_return_id: item?.id,
                material_return_status: item?.status,
                return_no: item?.return_no,
                return_date: item?.return_date,
                purchase_order_net_value:
                  item?.purchase_order?.purchase_order_details?.[0]?.net_value,
                // available_stock:item?.available_stock
              });
            });
          });

          console.log("temp", temp);

          setMrList({ material_return: temp, pagination: res?.pagination });
        } else {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // useEffect(() => {
  //   getMaterialsReturnList();
  // }, [selectedProject, page]);

  useEffect(() => {
    if (searchParams?.get("projectID")) {
      setSelectedProject(searchParams?.get("projectID"));
      getMaterialsReturnList(searchParams?.get("projectID"), 1);
    }
  }, [searchParams?.get("projectID")]);

  const materialTransferDetailHandler = (sel_mt, mt_colde, mt_date) => {
    navigate("/ism/output/material-return", {
      state: { id: sel_mt },
    });
  };

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white my-3 text-center">
        <header>
          <Col xs={12} className="d-flex my-2">
            <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 ms-auto pointer"
              value={selectedProject}
              onChange={(e) => {
                setSelectedProject(e.target.value);
                setPage(1);
                getMaterialsReturnList(e.target.value, 1);
              }}
            >
              ,<option>Select Project</option>
              {projectList?.map((project) => (
                <option key={project?.id} value={project?.id}>
                  {project?.name}
                </option>
              ))}
            </select>
          </Col>
        </header>
        <h4 className="my-1 py-1">Material Return List</h4>
        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                {/* <th>Project Name</th> */}
                {/* <th className="text-center">BOQ</th> */}
                <th className="text-center">Return No</th>
                <th className="text-center">Material Code</th>
                {/* <th>Material Description</th> */}
                <th className="text-center">UOM</th>
                <th className="text-center">Stock Quantity</th>
                <th className="text-center">Return Quantity</th>
                <th className="text-center">Invoice Amount</th>
                <th className="text-center">Created By</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {MrList?.material_return?.map((material, ind) => {
                return (
                  <tr className="text-center">
                    <td>{ind + 1}</td>
                    <td className="text-center">{material?.return_no}</td>
                    <td>
                      {material?.grn_detail?.material?.material_code ||
                        material?.purchase_order_detail?.material
                          ?.material_code}
                    </td>
                    <td>
                      {material?.grn_detail?.material?.uom ||
                        material?.purchase_order_detail?.material?.uom}
                    </td>
                    <td className="text-center">{material?.available_stock}</td>
                    <td className="text-center">{material?.return_qty}</td>
                    <td className="text-center">
                      {material?.purchase_order_net_value}
                    </td>
                    <td>{material?.employee?.name}</td>
                    <td>
                      {material?.material_return_status == 1
                        ? "Pending"
                        : material?.material_return_status == 2
                        ? "Accepted"
                        : "Rejected"}
                    </td>
                    <td>
                      <div className="d-flex">
                        <Link
                          to={`/ism/output/material-return?id=${
                            material?.material_return_id
                          }&projectID=${+selectedProject}&path=mrl`}
                        >
                          <img
                            onClick={() =>
                              materialTransferDetailHandler(
                                material?.material_return_id,
                                material?.po_no,
                                material?.po_date
                              )
                            }
                            style={{ width: "20px" }}
                            className="pointer ms-2"
                            src={eye_icon}
                            alt="eye-icon"
                          />
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="my-1 py-1 d-flex justify-content-end">
          <PaginationControl
            page={page}
            between={4}
            total={MrList?.pagination?.total}
            limit={10}
            changePage={(page) => {
              setPage(page);
              getMaterialsReturnList(selectedProject, page);
            }}
            ellipsis={1}
          />
        </div>
      </Col>
    </Col>
  );
}

export default MaterialReturnList;
