// @ts-nocheck
import React, { useState } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import moment from "moment";
import StatusModal from "../StatusModal";
import { tickIcon } from "../../assets/img";
import { api } from "../../constants/Services";
import { ratings } from "../../constants/StaticData";
import VendorGSTR from "./VendorGst";

const RatingTab = ({
  input,
  add,
  tax,
  bank,
  prodectType,
  // supplierName,
  companyName,
  tabonClickHandler,
  vendorType,
  subVendorType,
}) => {
  console.log("🚀 ~ file: RatingTab.js:21 ~ prodectType:", prodectType);
  console.log("🚀 ~ file: RatingTab.js:12 ~ RatingTab ~ add:", tax);
  const [showDrop, setShowDrop] = useState(false);
  const [tempForm, setTempForm] = useState({
    supplier_name: "",
    supplier_type: "",
  });

  const [gstFormDetails, setGstFormDetails] = useState({
    pan: tax?.pan,
    temp: "",
    gst_state: "",
    gst_type: "",
    gst_date: moment().format("YYYY-MM-DD"),
  });

  const [gst, setGst] = useState(0);
  const [show, setShow] = useState(false);
  const [checkBox, setCheckBox] = useState([]);
  const [showStatus, setShowStatus] = useState(false);
  const statusModalClose = () => {
    setShowStatus(!showStatus);
  };

  const checkBoxHandler = (ind) => {
    let checks = [];
    if (checks.includes(ind)) {
      let indx = checks.indexOf(ind);
      checks.splice(indx, 1);
      setCheckBox(checks);
    } else {
      checks.push(ind);
      setCheckBox(checks);
    }
  };

  console.log(prodectType);

  const submit = () => {
    console.log("comign to function");
    let formdata = new FormData();
    let rate;
    if (checkBox == 0) {
      rate = "a";
    } else if (checkBox == 1) {
      rate = "b";
    } else if (checkBox == 2) {
      rate = "c";
    } else if (checkBox == 3) {
      rate = "d";
    } else if (checkBox == 4) {
      rate = "e";
    }
    formdata.append("type", input?.supplierType);
    formdata.append("name", "static_name");
    formdata.append("company_name", companyName);
    formdata.append("vendor_type", vendorType);
    {
      vendorType === "Debitor"
        ? formdata.append("debtor_type", subVendorType)
        : formdata.append("creditor_type", subVendorType);
    }
    formdata.append("code", "temp-backend-correction-needed");
    formdata.append("product_type", prodectType);
    formdata.append("rating", rate);
    formdata.append("email", add?.mail);
    formdata.append("mobile", add?.mobile);
    formdata.append("phone_no", add?.phone);
    formdata.append("door_no", add?.no);
    formdata.append("district", add?.district);
    formdata.append("address", add?.address);
    formdata.append("state", add?.states);
    formdata.append("taluk", add?.taluk);
    formdata.append("pincode", add?.pincode);
    formdata.append("bank_name", bank?.bankName);
    formdata.append("account_no", bank?.accountNo);
    formdata.append("account_type", bank?.accountType);
    formdata.append("branch", bank?.branch);
    formdata.append("ifsc", bank?.IFSC);
    formdata.append("pan", tax?.pan);
    formdata.append("pan_status", tax?.panstatus);
    formdata.append("gst_status", gst);
    if (gst == 1) {
      let gstNo = `${gstFormDetails?.gst_state}${gstFormDetails?.pan}${gstFormDetails?.temp}`;
      formdata.append("gst_state", gstFormDetails?.gst_state);
      formdata.append("gst_type", gstFormDetails?.gst_type);
      formdata.append("gst_date", gstFormDetails?.gst_date);
      formdata.append("gst_no", gstNo);
    }
    api.vendorCreate(formdata).then((res) => {
      console.log("🚀 ~ file: RatingTab.js:96 ~ api.vendorCreate ~ res):", res);
      if (res?.status == "success") {
        statusModalClose();
        setShow(!show);
        tabonClickHandler("address");
        alert(res?.message);
        window.location.reload();
      }
    });
  };

  let displayContent;

  if (gst == 1) {
    displayContent = (
      <VendorGSTR
        showDrop={showDrop}
        setShowDrop={setShowDrop}
        submit={submit}
        gstFormDetails={gstFormDetails}
        setGstFormDetails={setGstFormDetails}
        prodectType={prodectType}
        companyName={companyName}
      />
    );
  } else {
    displayContent = (
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <h4>Ratings</h4>
        <div className="d-flex flex-wrap justify-content-md-around">
          <div className="roles-box text-start mt-3">
            {ratings.map((item, ind) => {
              return (
                <button
                  onClick={() => checkBoxHandler(ind)}
                  key={ind}
                  className="border-0 cust-btn d-flex fs-12 align-items-center mb-2">
                  <div className="checkboxs me-2">
                    {checkBox.includes(ind) && (
                      <img src={tickIcon} alt="tick-img" />
                    )}
                  </div>
                  {item?.rate}
                </button>
              );
            })}
          </div>
        </div>
        <Col sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={() => setShow(!show)}>
            Submit
          </button>
          <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3">
            cancel
          </button>
        </Col>

        <Modal centered show={show} onHide={() => setShow(!show)}>
          <Modal.Header className="border-0" closeButton>
            <label className="w-100 text-center">GST Confirmation</label>
          </Modal.Header>
          <Modal.Body>
            <Col xs={12} className="inputinner-box d-flex text-start">
              <div className="d-sm-flex my-1 py-1 w-50">
                <Col xs={6} className="d-sm-flex align-items-center ">
                  <label className="mt-0 me-1 pe-1">Yes</label>
                  <input
                    type="radio"
                    onClick={() => {
                      setGst(1);
                    }}
                    className="border-0 bg-lightBlue px-2 py-1 ms-1 ps-1 mt-1"
                    placeholder="Enter your Supplier Code"
                    value={gst}
                  />
                </Col>
                <Col xs={6} className="d-sm-flex">
                  <label className="mt-0 me-1 pe-1">No</label>
                  <input
                    type="radio"
                    className="border-0 bg-lightBlue px-2 py-1 ms-1 ps-1 mt-1"
                    placeholder="Enter your Supplier Code"
                    onClick={() => setGst(0)}
                    value={gst}
                  />
                </Col>
              </div>
            </Col>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <Button variant="secondary" onClick={() => setShow(!show)}>
              Close
            </Button>
            <Button variant="primary" onClick={submit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        {showStatus && (
          <StatusModal
            statusModalClose={statusModalClose}
            showStatus={showStatus}
          />
        )}
      </Col>
    );
  }

  return displayContent;
};

export default RatingTab;
