// @ts-nocheck
import React, { useEffect, useState } from "react";
import { tickIcon } from "../../assets/img";
import { invoceBookingTable } from "../../constants/StaticData";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";

const UserManagementTable = ({
  miscModalHandler,
  handleChange,
  list,
  addTab,
  setList,
  setMaterialList,
  materialList,
}) => {
  const [dropDown, setDropDown] = useState(null);
  const UOMLIST = [
    { uom: "Meter", uom_code: "mtr" },
    { uom: "Kilometer", uom_code: "km" },
    { uom: "Centimeter", uom_code: "cm" },
    { uom: "Millimeter", uom_code: "mm" },
    { uom: "Length", uom_code: "l" },
    { uom: "Feet", uom_code: "ft" },
    { uom: "Square Meter", uom_code: "sqm" },
    { uom: "Running Meter", uom_code: "rmt" },
    { uom: "Each", uom_code: "each" },
    { uom: "Pair", uom_code: "pair" },
    { uom: "Set", uom_code: "set" },
    { uom: "Bundle", uom_code: "bundle" },
    { uom: "Box", uom_code: "box" },
    { uom: "Number", uom_code: "nos" },
    { uom: "Seconds", uom_code: "s" },
    { uom: "Milliseconds", uom_code: "ms" },
    { uom: "Hour", uom_code: "hr" },
    { uom: "Minutes", uom_code: "min" },
    { uom: "Ampere", uom_code: "amp" },
    { uom: "Volts", uom_code: "v" },
    { uom: "Kelvin", uom_code: "kel" },
    { uom: "Gram", uom_code: "g" },
    { uom: "Kilogram", uom_code: "kg" },
    { uom: "Liter", uom_code: "lit" },
    { uom: "Inches", uom_code: "inch" },
    { uom: "Yards", uom_code: "yards" },
    { uom: "Miles", uom_code: "lb" },
    { uom: "Pounds", uom_code: "mi" },
    { uom: "Gallons", uom_code: "gal" },
    { uom: "Ounces", uom_code: "oz" },
    { uom: "Milliliter", uom_code: "ml" },
    { uom: "Cubic Meter", uom_code: "cum" },
    { uom: "Mole", uom_code: "mol" },
    { uom: "Candela", uom_code: "cd" },
    { uom: "Hertz", uom_code: "Hz" },
    { uom: "Decibel", uom_code: "db" },
    { uom: "Ohms", uom_code: "ohm" },
    { uom: "Watts", uom_code: "w" },
    { uom: "Lumen", uom_code: "Iv" },
    { uom: "Radius", uom_code: "r" },
    { uom: "Barrel", uom_code: "bbl" },
    { uom: "Lot", uom_code: "lot" },
    { uom: "Lupsum", uom_code: "lupsum" },
    { uom: "Kilowatt", uom_code: "kw" },
    { uom: "Celsius", uom_code: "cel" },
    { uom: "Fahrenheit", uom_code: "fn" },
    { uom: "Temperature", uom_code: "deg" },
    { uom: "Ton", uom_code: "MT" },
  ];

  const getMaterialsList = () => {
    let params = "?type=2";
    api
      .getMaterialsLists(params)
      .then((res) => {
        if (res?.status == "success") {
          // setMaterialList(res?.materials);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // useEffect(() => {
  //   getMaterialsList();
  // }, []);

  const dropHandler = (ind, type) => {
    if (dropDown == ind) {
      setDropDown(null);
    } else {
      setDropDown(ind);
    }
  };

  const getMatrialDetails = (mat, ind, item) => {
    let temp = [...list];

    temp[ind] = {
      ...item,
      ...mat,
    };
    setDropDown(null);
    setList(temp);
  };

  console.log("materialList", materialList);

  return (
    <div className="user-mange-table bg-white p-2" style={{ width: "100%" }}>
      <Col className="d-flex justify-content-end mx-2 mb-2">
        <button
          className="bg-primar border-0 rounded-1 my-1 text-white f-1 fs-14 px-4 py-2 ms-auto"
          onClick={() => addTab()}
        >
          Add
        </button>
      </Col>
      <div style={{ width: "100%", overflowX: "scroll" }}>
        <table>
          <thead className="text-center">
            <tr>
              <th className="ps-2 nowrap">S.No</th>
              {/* <th className="ps-2">Select</th>  */}
              <th className="ps-2 text-nowrap"> Material Description</th>
              <th className="ps-2">UOM</th>

              <th className="ps-2">Quantity</th>
              <th className="ps-2 text-nowrap">Basic Rate</th>
              <th className="text-nowrap">Sum of Value</th>
              {/* <th>Freight</th> */}
              <th>TDS(%)</th>
              <th>TCS(%)</th>
              <th className="ps-2">MISC</th>
              <th className="ps-2 text-nowrap">Total Value</th>
              {/* <th className="text-center">Remark</th> */}
            </tr>
          </thead>
          <tbody>
            {list?.map((item, ind) => {
              return (
                <tr key={ind}>
                  <td className="ps-2 text-center" style={{ fontSize: "12px" }}>
                    {ind + 1}
                  </td>
                  <td className="text-center" style={{ fontSize: "12px" }}>
                    {item?.custom == "custom" ? (
                      <div>
                        <p
                          style={{ minWidth: "140px" }}
                          className="pointer"
                          onClick={() => dropHandler(ind, "code")}
                        >
                          {item?.boq_code
                            ? item?.boq_code
                            : "Select Material Code"}
                        </p>

                        {dropDown == ind && (
                          <ul
                            className="drop-cont"
                            style={{ height: "200px", overflow: "scroll" }}
                          >
                            <li>
                              <input
                                className="border-0 bg-lightBlue px-2 py-1 mt-1"
                                // value={filter?.boq_code}
                                // onChange={(e) =>
                                //   setFilter({
                                //     ...filter,
                                //     boq_code: e.target.value,
                                //   })
                                // }
                              />
                            </li>
                            {materialList?.map((mat, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  getMatrialDetails(mat, ind, item)
                                }
                              >
                                {mat?.boq_code}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ) : (
                      item?.material?.name
                    )}
                  </td>
                  <td
                    className="text-nowrap text-center"
                    style={{ fontSize: "12px" }}
                  >
                    {item?.custom == "custom"
                      ? item?.uom
                        ? item?.uom
                        : "-"
                      : // item?.uom ? (
                        //   item?.uom
                        // ) : (
                        //   <select
                        //     className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 "
                        //     value={item?.uom}
                        //     onChange={(e) =>
                        //       handleChange(e.target.value, ind, "uom")
                        //     }
                        //     disabled={item?.name ? false : true}
                        //   >
                        //     <option value={""} hidden>
                        //       Select UOM
                        //     </option>
                        //     {UOMLIST.map((uom, uinx) => {
                        //       return (
                        //         <option value={uom?.uom_code} key={uinx}>
                        //           {uom?.uom}
                        //         </option>
                        //       );
                        //     })}
                        //   </select>
                        // )
                        item?.material?.uom}
                  </td>
                  <td style={{ fontSize: "12px" }}>
                    <input
                      onChange={(e) =>
                        handleChange(e.target.value, ind, "received_qty", item)
                      }
                      value={item?.received_qty}
                      type={"number"}
                      className="border-0 text-center w-100"
                      disabled={
                        item?.custom == "custom" && !item?.boq_code
                          ? true
                          : false
                      }
                    />
                  </td>
                  <td className="text-center" style={{ fontSize: "12px" }}>
                    {" "}
                    <input
                      onChange={(e) =>
                        handleChange(e.target.value, ind, "basic_price", item)
                      }
                      value={item?.basic_price}
                      type={"number"}
                      className="border-0 text-center w-100"
                      disabled={
                        item?.custom == "custom" && !item?.boq_code
                          ? true
                          : false
                      }
                    />
                    {item?.error == 1 && (
                      <p className="err">More than Avaliable Price</p>
                    )}
                  </td>
                  <td className="text-center" style={{ fontSize: "12px" }}>
                    {item?.total_price}
                  </td>
                  <td className="text-center">
                    <input
                      onChange={(e) =>
                        handleChange(e.target.value, ind, "tds", item)
                      }
                      value={item?.tds}
                      disabled={
                        item?.tcs
                          ? true
                          : item?.custom == "custom" && !item?.boq_code
                          ? true
                          : false
                      }
                      style={{
                        fontSize: "12px",
                        textAlign: "center",
                        width: "70px",
                      }}
                      type={"number"}
                      className="border-0"
                    />{" "}
                  </td>
                  <td className="text-center">
                    <input
                      onChange={(e) =>
                        handleChange(e.target.value, ind, "tcs", item)
                      }
                      value={item?.tcs}
                      disabled={
                        item?.tds
                          ? true
                          : item?.custom == "custom" && !item?.boq_code
                          ? true
                          : false
                      }
                      style={{
                        fontSize: "12px",
                        textAlign: "center",
                        width: "70px",
                      }}
                      type={"number"}
                      className="border-0"
                    />{" "}
                  </td>
                  <td className="text-center" style={{ cursor: "pointer" }}>
                    {item?.misc_value ? (
                      item?.misc_value
                    ) : (
                      <button
                        className="border-0 bg-primary1 rounded-1 text-white"
                        onClick={() =>
                          miscModalHandler(item.id, item?.total_price, ind)
                        }
                        disabled={
                          item?.custom == "custom" && !item?.boq_code
                            ? true
                            : false
                        }
                      >
                        Misc
                      </button>
                    )}
                  </td>
                  <td className="text-center" style={{ fontSize: "12px" }}>
                    {item?.net_value}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserManagementTable;
