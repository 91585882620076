// @ts-nocheck
import React from "react";
import { tickIcon } from "../../../assets/img";
import { invoceBookingTable } from "../../../constants/StaticData";

const MaterialReceiptTable = ({
  tableSel,
  setTableSel,
  mrTableStaticData,
  setMrTableStaticTable,
  materialDetailList,
  setMaterialDetailList,
}) => {
  const handleChange = (event, ind, type) => {
    let temp = [...materialDetailList];

    if (type == "accept_qty") {
      temp[ind] = {
        ...temp[ind],
        [type]: +event,
        ["error"]:
          +event > Math.abs(+temp[ind]?.transfer_qty - +temp[ind]?.receipt_qty)
            ? 1
            : 0,
      };
      setMaterialDetailList(temp);
    } else {
      temp[ind] = {
        ...temp[ind],
        [type]: event,
      };
      setMaterialDetailList(temp);
    }
  };

  console.log("materialDetailList", materialDetailList);

  return (
    <div className="user-mange-table bg-white p-2">
      <table>
        <thead>
          <tr className="text-center">
            <th className="">S.No</th>
            <th>Material Code</th>
            <th>Material Description</th>
            <th className="text-center">UOM</th>
            <th className="text-center text-nowrap">Transfer Quantity</th>
            <th className=" text-nowrap">Accept Quantity</th>
            <th className="">Remarks</th>
          </tr>
        </thead>
        <tbody>
          {materialDetailList?.map((item, ind) => {
            return (
              <tr key={ind}>
                <td className="text-center">{ind + 1}</td>
                <td className="text-center">{item?.material?.material_code}</td>
                <td className="text-start">{item?.material?.name}</td>
                <td className="text-center">{item?.material?.uom}</td>
                <td className="text-center">{item?.transfer_qty}</td>
                <td className="text-center">
                  <input
                    type="number"
                    className="border-0 w-100 text-center"
                    value={item?.accept_qty}
                    onChange={(e) =>
                      handleChange(e.target.value, ind, "accept_qty")
                    }
                  />
                  {item?.error == 1 && (
                    <p className="err" style={{ fontSize: "10px" }}>
                      Exceeded The Quantity
                    </p>
                  )}
                </td>
                <td className="text-center">
                  <input
                    type="text"
                    className="border-0 w-100 text-center"
                    value={item?.remarks}
                    onChange={(e) =>
                      handleChange(e.target.value, ind, "remarks")
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MaterialReceiptTable;
