// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { api } from "../../../constants/Services";

const MaterialInstruction = ({
  user,
  setModal,
  project,
  TransferType,
  projectTo,
  transferDate,
  files,
  wareHouse,
  wareHouseTo,
  setProjectToErr,
  setProjectErr,
  setTransferDateErr,
  setFilesErr,
  setWarehouseErr,
  setWarehouseToErr,
  setTransferTyperErr,
  tableSel,
  setShowStatus,
  setStatusMessage,
  company_id,
  setCompany_idErr,
}) => {
  const navigate = useNavigate();
  const [btn, setBtn] = useState(false);
  const [PreparedBy, setPreparedBy] = useState("");
  const [TransportNameBy, setTransportNameBy] = useState("");
  const [ApprovedBy, setApprovedBy] = useState("");
  const [DriverName, setDriverName] = useState("");
  const [Frieght, setFrieght] = useState("");
  const [Contact, setContact] = useState("");
  const [Remarks, setRemarks] = useState("");

  const [PreparedByErr, setPreparedByErr] = useState(false);
  const [TransportNameByErr, setTransportNameByErr] = useState(false);
  const [ApprovedByErr, setApprovedByErr] = useState(false);
  const [DriverNameErr, setDriverNameErr] = useState(false);
  const [FrieghtErr, setFrieghtErr] = useState(false);
  const [ContactErr, setContactErr] = useState(false);
  const [RemarksErr, setRemarksErr] = useState(false);

  console.log("tableSel", tableSel);

  const submitHandler = () => {
    if (
      (TransferType == 2 && projectTo?.length == 0) ||
      project?.length == 0 ||
      company_id?.length == 0 ||
      // files?.length == 0 ||
      transferDate?.length == 0 ||
      wareHouse?.length == 0 ||
      TransferType?.length == 0 ||
      (TransferType == 1 && wareHouseTo?.length == 0) ||
      TransportNameBy?.length == 0 ||
      Contact?.length == 0 ||
      DriverName?.length == 0
      // ||Remarks?.length == 0
    ) {
      TransferType == 2 && setProjectToErr(true);
      setTransferDateErr(true);
      setTransferTyperErr(true);
      TransferType == 1 && setWarehouseToErr(true);
      setWarehouseErr(true);
      setCompany_idErr(true);
      // setFilesErr(true);
      setProjectErr(true);
      // setRemarksErr(true);
      setTransportNameByErr(true);
      setContactErr(true);
      setDriverNameErr(true);
    } else {
      let formdata = new FormData();

      formdata.append("image", files);
      formdata.append("project_id", project);
      formdata.append("company_id", company_id);
      formdata.append("type", TransferType);
      formdata.append("driver_name", DriverName);
      formdata.append("transport_name", TransportNameBy);
      formdata.append("contact", Contact);
      formdata.append("from_ware_house_id", wareHouse);
      formdata.append("remarks", Remarks);
      formdata.append("transfer_date", transferDate);

      if (TransferType == 1) {
        formdata.append("to_ware_house_id", wareHouseTo);
      } else {
        formdata.append("to_project_id", projectTo);
      }

      let count = 1;

      if (tableSel?.length > 0) {
        tableSel?.map((item, ind) => {
          if (item?.error == 1) {
            count = count + 1;
          }
          formdata.append(
            `material_transfer_details[${ind}][transfer_qty]`,
            item?.transfer_qty
          );

          formdata.append(
            `material_transfer_details[${ind}][remarks]`,
            item?.remark
              ? item?.remark
              : item?.remarks !== undefined
              ? item?.remarks
              : item?.remarks !== "undefined"
              ? item?.remarks
              : ""
          );

          console.log(
            item?.remark
              ? item?.remark
              : item?.remarks !== undefined
              ? item?.remarks
              : item?.remarks !== "undefined"
              ? item?.remarks
              : ""
          );

          formdata.append(
            `material_transfer_details[${ind}][material_id]`,
            item?.material_id ? item?.material_id : item?.id
          );
        });
      }

      if (count == 1) {
        setBtn(true);
        api
          .matertialTransferAdd(formdata)
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              setShowStatus(true);
              setStatusMessage(res?.message + " " + res?.material_transfer_no);
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <Col className="d-flex flex-wrap">
        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Prepared By</label>
          <p
            style={{ minHeight: "2.1rem", width: "90%", lineHeight: "2.1rem" }}
            className="input-select bg-lightBlue ms-0 ps-3 my-1"
          >
            {user}
          </p>
        </Col> */}
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Transport Name By</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            placeholder="Enter Transport Name"
            onChange={(e) => setTransportNameBy(e.target.value)}
            value={TransportNameBy}
          />
          {TransportNameBy?.length == 0 && TransportNameByErr && (
            <p className="err">Enter Transport Name By </p>
          )}
        </Col>
        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Approved By</label>
          <p
            style={{ minHeight: "2.1rem", width: "90%", lineHeight: "2.1rem" }}
            className="input-select bg-lightBlue ms-0 ps-3 my-1"
          >
            {}
          </p>
        </Col> */}
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Driver Name</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            placeholder="Enter Driver Name"
            onChange={(e) => setDriverName(e.target.value)}
            value={DriverName}
          />
          {DriverName?.length == 0 && DriverNameErr && (
            <p className="err">Enter Driver Name </p>
          )}
        </Col>
        {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Frieght</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            placeholder="Enter Frieght"
            onChange={(e) => setFrieght(e.target.value)}
            value={Frieght}
          />
          {Frieght?.length == 0 && FrieghtErr && (
            <p className="err">enter frieght </p>
          )}
        </Col> */}
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Contact</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            placeholder="Enter Contact"
            onChange={(e) => setContact(e.target.value)}
            value={Contact}
          />
          {Contact?.length == 0 && ContactErr && (
            <p className="err">Enter Contact </p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start ps-0">
          <label className="w-100">Remarks</label>
          <textarea
            rows="4"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 fs-14"
            placeholder="Enter Remarks"
            onChange={(e) => setRemarks(e.target.value)}
            value={Remarks}
          />
          {Remarks?.length == 0 && RemarksErr && (
            <p className="err">Enter Remarks </p>
          )}
        </Col>
      </Col>
      <Col className="d-flex justify-content-between">
        <div>
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2 me-3"
            onClick={submitHandler}
            disabled={btn ? true : false}
          >
            Save
          </button>
          <button className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
            Cancel
          </button>
        </div>
        {/* <button className="bg-gray border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
          Export
        </button> */}
      </Col>
    </Col>
  );
};

export default MaterialInstruction;
