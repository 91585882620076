// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import { tickIcon } from "../../../assets/img";
import { api } from "../../../constants/Services";
import { invoceBookingTable } from "../../../constants/StaticData";

const MaterialTransferTable = ({
  project,
  wareHouse,
  tableSel,
  TransferType,
  setTableSel,
  mtTableStaticData,
  setMtTableStaticTable,
  mCode,
  mDisc,
  materialDiscDrop,
  materialCodeDrop,
  setMaterialList,
  materialList,
  setRow,
  row,
}) => {
  const [showMisc, setShowMisc] = useState(false);
  const [materials, setMaterials] = useState({});
  const materialTransferSelHandler = (sel_id) => {
    let temp = [...tableSel];
    temp?.includes(+sel_id)
      ? temp?.splice(temp?.indexOf(+sel_id), 1)
      : temp.push(+sel_id);

    setTableSel(temp);
  };

  const [matCode, setMatCode] = useState([]);

  const [dropDown, setDropDown] = useState({
    boq: "",
    m_code: "",
    m_name: "",
  });

  const [filter, setFilter] = useState({
    material_code: "",
    material_name: "",
  });

  useEffect(() => {
    if (materialList?.length > 0) {
      setMatCode(materialList);
    }
  }, [materialList]);

  const mtTableStaticDataHandler = (sel_id, value, action) => {};
  let table_headings = [
    "Sl.No",
    "Material Code",
    "Material Description",
    "UOM",
    "Stock Quantity",
    "Transfer Quantity",
    "Remarks",
  ];

  const getMaterialsList = () => {
    let params = "?type=2";
    api
      .getMaterialsLists(params)
      .then((res) => {
        if (res?.status == "success") {
          setMaterialList(res?.materials);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // useEffect(() => {
  //   // getMaterialsList();
  // }, []);

  const getMaterial = (item) => {
    let split = item?.split(",");
    let finders = materialList?.find((i) => i?.id == +split[0]);

    setMaterials((val) => ({ ...val, [+split[1]]: finders }));
    setDropDown({ ...dropDown, boq: "", m_code: "" });

    let formdata = new FormData();
    formdata.append("project_id", +project);
    formdata.append("ware_house_id", +wareHouse);
    // formdata.append("material_id", +split[0]);
    formdata.append("material_id", +finders?.material_id);

    // if (project?.length == 0) {
    //   alert("Select Projct");
    // } else if (wareHouse?.length == 0) {
    //   alert("Select Warehouse");
    // } else {
    //   api
    //     .matertialAvailableStock(formdata)
    //     .then((res) => {
    //       if (res?.status == "success") {
    //         let obj = {
    //           ...res?.available_stock,
    //           remarks: "",
    //           issued_qty: "",
    //         };

    //         // setTableSel([]);
    //         setMaterials((val) => ({ ...val, [+split[1]]: obj }));
    //         setDropDown({ ...dropDown, boq: "", m_code: "" });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // }

    let finder = materialList?.find((i) => i?.id == +split[0]);

    let obj = {
      ...finder,
      remarks: "",
      issued_qty: "",
    };

    // setTableSel([]);
    // setMaterials((val) => ({ ...val, [+split[1]]: obj }));
  };

  const handleChange = (event, ind, type, id) => {
    let obj = { ...materials };
    let temp = obj[ind];
    let arr = [...tableSel];
    let inx = arr.findIndex((i) => i.id == id);

    if (type == "transfer_qty") {
      let final = {
        ...temp,
        [type]: event,
        ["error"]: +event > +temp?.available_stock ? 1 : 0,
      };
      setMaterials((val) => ({ ...val, [ind]: final }));
      arr[inx ? inx : 0] = final;
    } else {
      let final = {
        ...temp,
        [type]: event,
      };
      setMaterials((val) => ({ ...val, [ind]: final }));
      arr[inx ? inx : 0] = final;
    }

    setTableSel(arr);
  };

  const addRow = () => {
    let temp = [...row];
    temp.push(1);
    setRow(temp);
  };

  const checkBox = (item) => {
    let temp = [...tableSel];

    if (temp.includes(item)) {
      let inx = temp.indexOf(item);
      temp.splice(inx, 1);
    } else {
      temp.push(item);
    }

    setTableSel(temp);
  };

  console.log("tableSel", tableSel);

  console.log("materials", materials);

  // console.log("materialList", materialList);

  const dropHandler = (id, sel_drop) => {
    // console.log("id", id);
    // console.log("sel_drop", sel_drop);
    if (sel_drop == "boq") {
      dropDown?.boq == id
        ? setDropDown({ ...dropDown, boq: "" })
        : setDropDown({ ...dropDown, boq: id });
    } else if (sel_drop == "m_code") {
      dropDown?.m_code == id
        ? setDropDown({ ...dropDown, m_code: "" })
        : setDropDown({ ...dropDown, m_code: id });
    } else {
      dropDown?.m_name == id
        ? setDropDown({ ...dropDown, m_name: "" })
        : setDropDown({ ...dropDown, m_name: id });
    }
  };

  const selectFilter = (event, type) => {
    if (type == "code") {
      setFilter({
        ...filter,
        material_code: event,
      });

      if (event?.length > 0) {
        let finder = materialList?.filter((i) =>
          i?.material?.material_code
            ?.toLocaleLowerCase()
            ?.includes(event.toLocaleLowerCase())
        );

        setMatCode(finder);
      } else {
        setMatCode(materialList);
      }
    } else {
      setFilter({
        ...filter,
        material_name: event,
      });

      if (event?.length > 0) {
        let finder = materialList?.filter((i) =>
          i?.material?.name
            ?.toLocaleLowerCase()
            ?.includes(event.toLocaleLowerCase())
        );

        setMatCode(finder);
      } else {
        setMatCode(materialList);
      }
    }
  };

  return (
    <>
      <div className="user-mange-table bg-white p-2">
        <div className="d-flex justify-content-end mb-3">
          <button
            className="bg-primar border-0 rounded-1 my-1 text-white f-1 fs-14 px-4 py-2 ms-auto"
            onClick={() => addRow()}
          >
            Add
          </button>
        </div>
        <table>
          <thead>
            <tr>
              {table_headings?.map((item) => (
                <th className="text-nowrap text-center">{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {row?.map((item, ind) => {
              return (
                <tr key={ind}>
                  <td className="text-center">
                    {" "}
                    <div className="d-flex gap-1 align-items-center">
                      <div>{ind + 1}</div>{" "}
                      <div  style={{fontSize:"12px" }}> 
                        {materials &&
                        materials[ind]?.material_code &&
                        materials[ind]?.transfer_qty ? (
                          <input
                            type={"checkbox"}
                            className="mt-1"
                            onClick={() => checkBox(materials[ind])}
                            checked={tableSel?.includes(materials[ind])}
                          />
                        ) : materials &&
                          materials[ind]?.material?.material_code &&
                          materials[ind]?.transfer_qty ? (
                          <input
                            type={"checkbox"}
                            className="mt-1"
                            onClick={() => checkBox(materials[ind])}
                            checked={tableSel?.includes(materials[ind])}
                          />
                        ) : (
                          <input type={"checkbox"} className="mt-1" disabled />
                        )}
                      </div>
                    </div>
                  </td>
                  <td>
                    {/* {materials && materials[ind]?.material_code ? (
                      materials[ind]?.material_code
                    ) : materials && materials[ind]?.material?.material_code ? (
                      materials[ind]?.material?.material_code
                    ) : (
                      <div>
                        <select
                          className="border-0 bg-lightBlue px-2 py-1 mt-1 pointer"
                          onChange={(e) => getMaterial(e.target.value)}
                        >
                          <option hidden>Select Material Code</option>
                          {materialList?.map((item, index) => {
                            return (
                              <option key={index} value={[item?.id, ind]}>
                                {item?.material?.material_code
                                  ? item?.material?.material_code
                                  : item?.material_code}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )} */}

                    <p
                      style={{ minWidth: "110px" ,fontSize:"12px"}}
                      className="pointer"
                      onClick={() => dropHandler(`${ind}_boq`, "boq")}
                    >
                      {materials && materials[ind]?.material_code
                        ? materials[ind]?.material_code
                        : materials && materials[ind]?.material?.material_code
                        ? materials[ind]?.material?.material_code
                        : "Select Material Code"}
                    </p>

                    {dropDown?.boq == `${ind}_boq` && (
                      <ul className="drop-cont">
                        <li>
                          <input  style={{fontSize:"12px" }}
                            className="border-0 bg-lightBlue px-2 py-1 mt-1"
                            value={filter?.material_code}
                            onChange={(e) =>
                              selectFilter(e.target.value, "code")
                            }
                          />
                        </li>
                        {matCode?.map((item, index) => (
                          <li style={{fontSize:"12px" }}
                            key={index}
                            value={[item?.id, ind]}
                            onClick={(e) =>
                              getMaterial([item?.id, ind].toString())
                            }
                          >
                            {item?.material?.material_code
                              ? item?.material?.material_code
                              : item?.material_code}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>

                  <td>
                    {/* {materials && materials[ind]?.name ? (
                      materials[ind]?.name
                    ) : materials && materials[ind]?.material?.name ? (
                      materials[ind]?.material?.name
                    ) : (
                      <div>
                        <select
                          className="border-0 bg-lightBlue px-2 py-1 mt-1 pointer"
                          onChange={(e) => getMaterial(e.target.value)}
                          style={{ width: "50%" }}
                        >
                          <option hidden>Select Material Description</option>
                          {materialList?.map((item, index) => {
                            return (
                              <option key={index} value={[item?.id, ind]}>
                                {item?.material?.name
                                  ? item?.material?.name
                                  : item?.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )} */}

                    <p
                      style={{ minWidth: "110px" ,fontSize:"12px" }}
                      className="pointer"
                      onClick={() => dropHandler(`${ind}_m_code`, "boq")}
                    >
                      {materials && materials[ind]?.name
                        ? materials[ind]?.name
                        : materials && materials[ind]?.material?.name
                        ? materials[ind]?.material?.name
                        : "Select Material Code"}
                    </p>

                    {dropDown?.boq == `${ind}_m_code` && (
                      <ul className="drop-cont">
                        <li>
                          <input style={{fontSize:"12px" }}
                            className="border-0 bg-lightBlue px-2 py-1 mt-1"
                            value={filter?.material_name}
                            onChange={(e) =>
                              selectFilter(e.target.value, "name")
                            }
                          />
                        </li>
                        {matCode?.map((item, index) => (
                          <li
                            key={index}
                            value={[item?.id, ind]}
                            onClick={(e) =>
                              getMaterial([item?.id, ind].toString())
                            }
                          >
                            {item?.material?.name
                              ? item?.material?.name
                              : item?.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                  <td className="" style={{fontSize:"12px"  ,textAlign:"center"}}>
                    {(materials && materials[ind]?.uom) ||
                      (materials && materials[ind]?.material?.uom)}
                  </td>
                  <td className="" style={{fontSize:"12px" ,textAlign:"center"}}>
                    {materials && materials[ind]?.available_stock}
                  </td>
                  <td>
                    <input style={{fontSize:"12px" }}
                      className="border-0 text-center"
                      type="number"
                      value={materials && materials[ind]?.transfer_qty}
                      onChange={(e) =>
                        handleChange(
                          e.target.value,
                          ind,
                          "transfer_qty",
                          materials[ind]?.id
                        )
                      }
                      disabled={
                        materials && materials[ind]?.material_code
                          ? false
                          : materials && materials[ind]?.material?.material_code
                          ? false
                          : true
                      }
                    />
                    {/* {materials &&
                      +materials[ind]?.transfer_qty >
                        +materials[ind]?.available_stock && (
                        <p className="err" style={{ fontSize: "10px" }}>
                          Exceeded The Quantity
                        </p>
                      )} */}
                    {materials && +materials[ind]?.error == 1 && (
                      <p className="err text-center " style={{ fontSize: "10px" }}>
                        Exceeded The Quantity
                      </p>
                    )}
                  </td>
                  <td>
                    <input style={{fontSize:"12px" ,textAlign:"center" }}
                      className="border-0 w-100"
                      value={materials && materials[ind]?.remark}
                      onChange={(e) =>
                        handleChange(
                          e.target.value,
                          ind,
                          "remark",
                          materials[ind]?.id
                        )
                      }
                      disabled={
                        materials && materials[ind]?.material_code
                          ? false
                          : materials && materials[ind]?.material?.material_code
                          ? false
                          : true
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Modal
          show={showMisc}
          centered
          onHide={() => {
            setShowMisc(!showMisc);
          }}
        >
          <Modal.Header closeButton className="border-0">
            <h5 className="mx-auto">Miscellaneous Screen</h5>
          </Modal.Header>
          <Modal.Body>
            <div className="w-100 my-1 py-1">
              <label style={{ width: "140px" }} className="fs-14 me-2">
                HSN Code
              </label>
              <p></p>
            </div>
            <div className="w-100 my-1 py-1">
              <label style={{ width: "140px" }} className="fs-14 me-2">
                HSN Description
              </label>
              <p></p>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  Total Value
                </label>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input className="w-50" readOnly value={""} />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  Discount
                </label>
                <input className="w-25" readOnly value={""} />
                <span className="ms-1">%</span>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input className="w-50" value={" "} />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  IGST
                </label>

                <input className="w-25" value={" "} />
                <span className="ms-1">%</span>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input className="w-50" readOnly value={" "} />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  SGST
                </label>
                <input className="w-25" readOnly value={" "} />
                <span className="ms-1">%</span>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input className="w-50" readOnly value={" "} />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  CGST
                </label>
                <input className="w-25" readOnly value={" "} />
                <span className="ms-1">%</span>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input className="w-50" readOnly value={" "} />
              </Col>
            </div>
            <div className="w-100 d-flex my-1 py-1"></div>

            <div className="w-100 d-flex my-1 py-1">
              <Col xs={8}>
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  Net Value
                </label>
              </Col>
              <Col xs={4}>
                <label className="fs-14 me-2">Value</label>
                <input className="w-50" readOnly type="text" value={" "} />
              </Col>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 w-90">
            <button
              className="bg-primar border-0 rounded-1 my-1 text-white f-1 fs-14 px-4 py-2"
              onClick={() => setShowMisc(!showMisc)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default MaterialTransferTable;
