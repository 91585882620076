const auth = (state = "", action) => {
  switch (action.type) {
    case "saveAuth":
      return (state = action.payload);
    case "deleteAuth":
      return (state = "");
    default:
      return state;
  }
};
export default auth;
