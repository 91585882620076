import React, { useState, useEffect } from "react";
import eye_icon from "../../assets/icons/common/Eye.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { api } from "../../constants/Services";
import { PaginationControl } from "react-bootstrap-pagination-control";

function MaterialTransferList() {
  const [selectedProject, setSelectedProject] = useState("");
  const [MtList, setMtList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const getProjects = () => {
    api
      .project_list()
      .then((res) => {
        setProjectList(res?.projects);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (searchParams?.get("projectID")) {
      setSelectedProject(searchParams?.get("projectID"));
      getList(searchParams?.get("projectID"), 1);
    }
  }, [searchParams?.get("projectID")]);

  const getList = (id, page) => {
    let param = `?page=${page}`;
    let obj = {
      project_id: id,
    };
    api
      .matertialTransferList(param, obj)
      .then((res) => {
        let temp = [];

        res?.material_transfer?.map((item, ind) => {
          item?.material_transfer_detail?.map((mat, ind) => {
            let obj = {
              ...mat,
              ...item,
            };

            delete obj?.material_transfer_detail;

            temp.push(obj);
          });
        });

        setMtList({ material_transfer: temp, pagination: res?.pagination });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // useEffect(() => {
  //   getList();
  // }, [selectedProject, page]);

  const materialTransferDetailHandler = (sel_mt, mt_colde, mt_date) => {
    navigate("/ism/matrial/output", {
      state: { id: sel_mt },
    });
  };

  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white my-3 text-center">
        <header>
          <Col xs={12} className="d-flex my-2">
            <select
              style={{ minHeight: "2.1rem" }}
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 ms-auto pointer"
              value={selectedProject}
              onChange={(e) => {
                setSelectedProject(e.target.value);
                setPage(1);
                getList(e.target.value, 1);
              }}
            >
              <option value={""}>Select Project</option>
              {projectList?.map((project) => (
                <option key={project?.id} value={project?.id}>
                  {project?.name}
                </option>
              ))}
            </select>
          </Col>
        </header>
        <h4 className="my-1 py-1">Material Transfer List</h4>
        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Transfer No</th>
                <th>Material Code</th>
                {/* <th>Material Description</th> */}
                <th>UOM</th>
                <th>Stock Quantity</th>
                <th>Transfer Quantity</th>
                {/* <th>Created By Name</th> */}
                <th>Created By</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {MtList?.material_transfer?.length > 0 &&
                MtList?.material_transfer?.map((material, ind) => (
                  <tr>
                    <td>{ind + 1}</td>
                    <td>{material?.transfer_no}</td>
                    <td>{material?.material?.material_code}</td>
                    <td>{material?.material?.uom}</td>
                    <td>
                      {
                        material?.available_stock
                        // material?.material?.quantity
                      }
                    </td>
                    <td>{material?.transfer_qty}</td>
                    <td>{material?.employee?.name}</td>
                    <td>
                      {material?.status == 1
                        ? "Pending"
                        : material?.status == 2
                        ? "Accepted"
                        : "Rejected"}
                    </td>
                    <td>
                      <div className="d-flex">
                        <Link
                          to={`/ism/matrial/output?id=${
                            material?.id
                          }&projectID=${+selectedProject}&path=mt`}
                        >
                          <img
                            onClick={() =>
                              materialTransferDetailHandler(
                                material?.id,
                                material?.po_no,
                                material?.po_date
                              )
                            }
                            style={{ width: "20px" }}
                            className="pointer ms-2"
                            src={eye_icon}
                            alt="eye-icon"
                          />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="my-1 py-1 d-flex justify-content-end">
          <PaginationControl
            page={page}
            between={4}
            total={MtList?.pagination?.total}
            limit={10}
            changePage={(page) => {
              setPage(page);
              getList(selectedProject, page);
            }}
            ellipsis={1}
          />
        </div>
      </Col>
    </Col>
  );
}

export default MaterialTransferList;
