// @ts-nocheck
import React, { useState, useEffect } from "react";
import StatusModal from "../StatusModal";
import { Col } from "react-bootstrap";
import { tickIcon } from "../../assets/img";
import { api } from "../../constants/Services";
import { Link } from "react-router-dom";
import { BASE_FILE_URL, BASE_URL } from "../../constants/Services/constants";

const MaterialMasterForm = ({
  showAttribute,
  setShowAttribute,
  error,
  matId,
  setMatId,
  setError,
  MaterialMasFormDetails,
  setMaterialMasFormDetails,
  materialMasRequiredFields,
  setMaterialMasRequiredFields,
  materialQuantity,
  setMaterialQuantity,
}) => {
  const [showStatus, setShowStatus] = useState(false);
  const [uploads, setUpload] = useState();
  const statusModalClose = () => {
    setShowStatus(!showStatus);
  };
  const materialMasActionHandler = (action) => {
    // console.log("coming to function");
    if (action === "attribute") {
      setShowAttribute(!showAttribute);
    } else {
      let filteredList = Object.entries(materialMasRequiredFields)
        .filter((material) => material[1] === "" && material.splice(1, 1))
        .reduce(
          (acc, cur_material) => acc.concat("," + cur_material),
          "dummy_data"
        );
      filteredList = filteredList.split(",");
      setError(filteredList);
      // console.log(
      // "🚀 ~ file: MaterialMasterForm.js:39 ~ materialMasActionHandler ~ error:",
      // error
      // );

      if (error.length === 1) {
        // console.log("coming to error condition");
        let data = {};
        if (matId) {
          data = {
            ...materialMasRequiredFields,
            ...MaterialMasFormDetails,
            type: 2,
            parent_id: matId,
            attribute: 0,
            quantity: materialQuantity?.value,
          };
        } else {
          data = {
            ...materialMasRequiredFields,
            ...MaterialMasFormDetails,
            type: 2,
            attribute: 0,
            quantity: materialQuantity?.value,
          };
        }

        api.material_assests_add(data).then((res) => {
          // console.log();
          if (res?.status === "success") {
            statusModalClose();
            setMaterialMasFormDetails({
              group: "",
              uom: "",
              parent_name: "",
              level: "",
            });
            setMaterialMasRequiredFields({
              name: "",
              code: "",
              // material_type: "",
            });
            setMaterialQuantity({
              name: "",
              value: "",
            });
            setError([]);
            matId && setMatId("");
          }
        });
      }
    }
  };

  const uploadFile = () => {
    let data = {
      import_excel: uploads,
    };
    if (uploads) {
      api
        .materialImport(data)
        .then((res) => {
          alert(res?.message);
          setUpload("");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      alert("Please Upload File");
    }
  };

  return (
    // justify-content-end
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="ms-xl-0 w-95">Material Master</h4>
      <Col xs={12} className="inputinner-box text-start">
        <div className="d-flex justify-content-between">
          <div className="my-3">
            <Link
              class="bg-primar border-0 rounded-1 text-white f-1 me-auto fs-14 px-4 py-2 pointer"
              type="button"
              id="button-addon2"
              to={`${BASE_FILE_URL}excel/cresscon-material-sample.xlsx`}
              target="_blank"
            >
              Sample File
            </Link>
          </div>
          <div className="px-5 my-3">
            <div class="input-group px-2">
              <input
                type="file"
                class="form-control border-0 bg-lightBlue w-90"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                onChange={(e) => setUpload(e.target.files[0])}
                aria-describedby="button-addon2"
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={uploadFile}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </Col>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Name</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Material name"
            value={materialMasRequiredFields.name}
            onChange={(e) =>
              setMaterialMasRequiredFields({
                ...materialMasRequiredFields,
                name: e.target.value,
              })
            }
          />
          {error.includes("name") && (
            <p className="err">Please Enter Material Name</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Group</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Material Group"
            value={MaterialMasFormDetails.group}
            onChange={(e) =>
              setMaterialMasFormDetails({
                ...MaterialMasFormDetails,
                group: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Type</label>

          <select
            className="input-select bg-lightBlue ms-0"
            style={{
              border: "2px solid #f1f7ff",
              width: "90%",
              marginRight: "auto",
            }}
            value={materialMasRequiredFields.material_type}
            onChange={(e) =>
              setMaterialMasRequiredFields({
                ...materialMasRequiredFields,
                material_type: e.target.value,
              })
            }
          >
            <option value={""}>select Material type</option>
            <option value={"ELECTRICAL"}>ELECTRICAL</option>
            <option value={"CIVIL"}>CIVIL</option>
            <option value={"MECHANICAL"}>MECHANICAL</option>
            <option value={"HVAC"}>HVAC</option>
            <option value={"FIRE FIGHTING"}>FIRE FIGHTING</option>
            <option value={"ELV"}>ELV</option>
            <option value={"OTHERS"}>OTHERS</option>
            <option value={"PHE"}>PHE</option>
            <option value={"PROCESS PIPE"}>PROCESS PIPE</option>
            <option value={"SOLVENT PIPE"}>SOLVENT PIPE</option>
          </select>
          {error.includes("material_type") && (
            <p className="err">Please Enter Material Type</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Material Code</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Material Code"
            value={materialMasRequiredFields.code}
            onChange={(e) =>
              setMaterialMasRequiredFields({
                ...materialMasRequiredFields,
                code: e.target.value,
              })
            }
          />
          {error.includes("code") && (
            <p className="err">Please Enter Material Code</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">UOM</label>
          <select
            className="input-select bg-lightBlue ms-0"
            style={{
              border: "2px solid #f1f7ff",
              width: "90%",
              marginRight: "auto",
            }}
            value={MaterialMasFormDetails.uom}
            onChange={(e) =>
              setMaterialMasFormDetails({
                ...MaterialMasFormDetails,
                uom: e.target.value,
              })
            }
          >
            <option value={""}>select UOM</option>
            <option value={"rmt"}>RMT</option>
            <option value={"mtr"}>MTR</option>
            <option value={"kg"}>KG</option>
            <option value={"grm"}>GRM</option>
            <option value={"lot"}>LOT</option>
            <option value={"ELV"}>ELV</option>
            <option value={"pair"}>PAIR</option>
            <option value={"set"}>SET</option>
            <option value={"each"}>EACH</option>
            <option value={"MT"}>MT</option>
            <option value={"itr"}>ITR</option>
            <option value={"job"}>JOB</option>
            <option value={"nos."}>NOS</option>
            <option value={"cum"}>CUM</option>
            <option value={"sqm"}>SQM</option>
            <option value={"cm"}>CM</option>
            <option value={"dia"}>DIA</option>
            <option value={"deg"}>DEG</option>
            <option value={"S"}>S</option>
            <option value={"mole"}>MOLE</option>
            <option value={"K"}>K</option>
            <option value={"A"}>A</option>
            <option value={"volt"}>VOLT</option>
            <option value={"iv"}>IV</option>
            <option value={"temp"}>TEMP</option>
            <option value={"cel"}>CEL</option>
            <option value={"HZ"}>HZ</option>
            <option value={"W"}>W</option>
            <option value={"A"}>A</option>
            <option value={"mm"}>MM</option>
            <option value={"inch"}>INCH</option>
            <option value={"ft"}>FT</option>
            <option value={"kw"}>KW</option>
            <option value={"L/S"}>L/S</option>
          </select>
          {error.includes("uom") && <p className="err">Please Enter Uom</p>}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Parent Name</label>
          <p
            style={{ minHeight: "2rem", width: "90%", marginRight: "auto" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            {MaterialMasFormDetails.parent_name}
          </p>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Level</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Level"
            value={MaterialMasFormDetails.level}
            onChange={(e) =>
              setMaterialMasFormDetails({
                ...MaterialMasFormDetails,
                level: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Quantity</label>
          <input
            type="number"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Quantity"
            value={materialQuantity.value}
            onChange={(e) =>
              setMaterialQuantity({
                value: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={12} className="inputinner-box text-start">
          <label className="w-100">Select the application</label>
          <div className="d-flex mt-2">
            <button
              className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3"
              onClick={() => materialMasActionHandler("attribute")}
            >
              <div className="checkbox me-1">
                {showAttribute && <img src={tickIcon} alt="tick-image" />}
              </div>
              Add Attribute
            </button>
            <button
              className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3"
              onClick={() =>
                setMaterialMasRequiredFields({
                  ...materialMasRequiredFields,
                  is_child: 1,
                })
              }
            >
              <div className="checkbox me-1">
                {materialMasRequiredFields.is_child === 1 && (
                  <img src={tickIcon} alt="tick-image" />
                )}
              </div>
              Has Child
            </button>
            <button
              className="d-flex cust-btn fs-12 align-items-center me-1 me-lg-3"
              onClick={() =>
                setMaterialMasRequiredFields({
                  ...materialMasRequiredFields,
                  is_child: 0,
                })
              }
            >
              <div className="checkbox me-1">
                {materialMasRequiredFields.is_child === 0 && (
                  <img src={tickIcon} alt="tick-image" />
                )}
              </div>
              No Child
            </button>
          </div>
          {error.includes("is_child") && (
            <p className="err my-1 py-1">
              Please choose you wand child or not for adding element
            </p>
          )}
        </Col>
        <Col sm={12} className="inputinner-box text-start">
          {!showAttribute && (
            <button
              className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
              onClick={() => materialMasActionHandler("no-attribute")}
            >
              {matId ? "Update" : "Save"}
            </button>
          )}
        </Col>
      </Col>
    </Col>
  );
};

export default MaterialMasterForm;
