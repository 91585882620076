// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
// import { tickIcon } from "../../assets/img";

const AttributeForm = ({
  MaterialMasFormDetails,
  matId,
  attributeOptionalFields,
  setAttributeOptionalFields,
  setShowAttribute,
  setMatId,
  attributeRequiredDetails,
  setAttributeRequiredDetails,
  setMaterialMasFormDetails,
  materialMasRequiredFields,
  setMaterialMasRequiredFields,
  setError,
  error,
}) => {
  const [AttributeError, setAttributeError] = useState([]);

  const masterAttributeActionHandler = () => {
    let filteredList = Object.entries(materialMasRequiredFields)
      .filter((material) => material[1] === "" && material.splice(1, 1))
      .reduce(
        (acc, cur_material) => acc.concat("," + cur_material),
        "dummy_data"
      );
    filteredList = filteredList.split(",");
    setError(filteredList);
    let error_list = Object.entries(attributeRequiredDetails)
      .filter((attribute) => attribute[1] === "" && attribute.splice(1, 1))
      .reduce(
        (acc, cur_attribute) => acc.concat(`,${cur_attribute}`),
        "dummyData"
      );
    error_list = error_list.split(",");
    setAttributeError(error_list);
    if (AttributeError.length === 1 && error.length === 1) {
      // if (matId) {
      //   let data = {
      //     ...attributeRequiredDetails,
      //     ...attributeOptionalFields,
      //     ...MaterialMasFormDetails,
      //     ...materialMasRequiredFields,
      //     attribute: 1,
      //     type: 2,
      //     _method: "PUT",
      //   };
      //   api.material_assests_update(matId, data).then((res) => {
      //     console.log("attribute-ass-update-res", res);
      //     if (res?.status === "success") {
      //       setShowAttribute(false);
      //       setAttributeOptionalFields({
      //         specification1: "",
      //         specification2: "",
      //         specification3: "",
      //         specification4: "",
      //       });

      //       setAttributeRequiredDetails({
      //         make: "",
      //         color: "",
      //         model: "",
      //         attribute_type: "",
      //       });
      //       setMaterialMasFormDetails({
      //         group: "",
      //         uom: "",
      //         parent_name: "",
      //         level: "",
      //       });
      //       setMaterialMasRequiredFields({
      //         name: "",
      //         code: "",
      //         material_type: "",
      //       });
      //       setMatId("");
      //       setError([]);
      //     }
      //   });
      // } else {

      let data = {};

      if (matId) {
        data = {
          ...attributeRequiredDetails,
          ...attributeOptionalFields,
          ...MaterialMasFormDetails,
          ...materialMasRequiredFields,
          attribute: 1,
          type: 2,
          parent_id: matId,
        };
      } else {
        data = {
          ...attributeRequiredDetails,
          ...attributeOptionalFields,
          ...MaterialMasFormDetails,
          ...materialMasRequiredFields,
          attribute: 1,
          type: 2,
        };
      }

      api.material_assests_add(data).then((res) => {
        console.log("mater-ass", res);
        if (res?.status === "success") {
          setAttributeOptionalFields({
            specification1: "",
            specification2: "",
            specification3: "",
            specification4: "",
          });

          setShowAttribute(false);

          setAttributeRequiredDetails({
            make: "",
            color: "",
            model: "",
            attribute_type: "",
          });
          setMaterialMasFormDetails({
            group: "",
            uom: "",
            parent_name: "",
            level: "",
          });
          setMaterialMasRequiredFields({
            name: "",
            code: "",
            material_type: "",
          });
          setError([]);
          matId && setMatId("");
        }
      });
      // }
    }
  };
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="ms-xl-0 w-95">Attribute</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Make</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Make"
            value={attributeRequiredDetails.make}
            onChange={(e) =>
              setAttributeRequiredDetails({
                ...attributeRequiredDetails,
                make: e.target.value,
              })
            }
          />
          {AttributeError.includes("make") && (
            <p className="err">Please Enter Attribute Make</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Color</label>
          <div className="color-input w-90 ms-0 bg-lightBlue px-2 py-1 mt-1 d-flex">
            <input
              type="color"
              className="border-0"
              placeholder="Enter Color"
              value={attributeRequiredDetails.color}
              onChange={(e) =>
                setAttributeRequiredDetails({
                  ...attributeRequiredDetails,
                  color: e.target.value,
                })
              }
            />
            <p className="mt-1 ms-2">{attributeRequiredDetails.color}</p>
          </div>
          {AttributeError.includes("color") && (
            <p className="err">Please Enter Attribute Color</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Modal</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Modal"
            value={attributeRequiredDetails.model}
            onChange={(e) =>
              setAttributeRequiredDetails({
                ...attributeRequiredDetails,
                model: e.target.value,
              })
            }
          />
          {AttributeError.includes("model") && (
            <p className="err">Please Enter Attribute Model</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Type</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Type"
            value={attributeRequiredDetails.attribute_type}
            onChange={(e) =>
              setAttributeRequiredDetails({
                ...attributeRequiredDetails,
                attribute_type: e.target.value,
              })
            }
          />
          {AttributeError.includes("attribute_type") && (
            <p className="err">Please Enter Attribute Type</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Specification 1</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Specification"
            value={attributeOptionalFields.specification1}
            onChange={(e) =>
              setAttributeOptionalFields({
                ...attributeOptionalFields,
                specification1: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Specification 2</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Specification"
            value={attributeOptionalFields.specification2}
            onChange={(e) =>
              setAttributeOptionalFields({
                ...attributeOptionalFields,
                specification2: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Specification 3</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Specification"
            value={attributeOptionalFields.specification3}
            onChange={(e) =>
              setAttributeOptionalFields({
                ...attributeOptionalFields,
                specification3: e.target.value,
              })
            }
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Specification 4</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Specification"
            value={attributeOptionalFields.specification4}
            onChange={(e) =>
              setAttributeOptionalFields({
                ...attributeOptionalFields,
                specification4: e.target.value,
              })
            }
          />
        </Col>

        <Col sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
            onClick={masterAttributeActionHandler}>
            {matId ? "Update" : "Save"}
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default AttributeForm;
