import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import Header from "../../component/Header/Header";
import DayEnd from "../../component/inventory/DayEnd";
import { api } from "../../constants/Services";

const IsmDayEndScreen = () => {
  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <DayEnd />
    </Col>
  );
};

export default IsmDayEndScreen;
