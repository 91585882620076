import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
import { useNavigate } from "react-router-dom";
import eye_icon from "../../assets/icons/common/Eye.svg";
import edit_icon from "../../assets/icons/common/Edit.svg";
import moment from "moment";
import Header from "../../component/Header/Header";

const ProjectsList = () => {
  const [projectList, setProjectList] = useState([]);
  const [projectSearchedList, setProjectSearchList] = useState();
  const navigate = useNavigate();

  const projectSearchHandler = (searched_target) => {
    let filteredList = projectList?.filter((project) =>
      project?.name.toLowerCase().includes(searched_target.toLowerCase())
    );
    setProjectSearchList(filteredList);
  };
  useEffect(() => {
    api.allProjects().then((res) => {
      // console.log("project-list", res);
      setProjectList(res?.all_projects);
      setProjectSearchList(res?.all_projects);
    });
  }, []);
  return (
    <Col xs={12} xl={12} className="py-2 px-1 boq-cont-res p-md-3 w-100">
      <Header />
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
        <h4 className="my-1 py-1">Projects List</h4>
        <input
          className="border-0 bg-lightBlue px-2 py-1 w-50 mt-1"
          placeholder="Search...,"
          onChange={(e) => projectSearchHandler(e.target.value)}
        />
        <div className="user-mange-table ">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Project Name</th>
                <th>Project No</th>
                <th>Started At </th>
                <th>Awarded At</th>
                <th>Ended In</th>
                <th>status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {projectSearchedList &&
                projectSearchedList?.map((project, ind) => {
                  return (
                    <tr key={project?.id}>
                      <td>{ind + 1}</td>
                      <td>{project?.name}</td>
                      <td>{project?.project_no}</td>
                      <td>
                        {moment(project?.start_date).format("DD-MM-YYYY")}
                      </td>
                      <td>
                        {moment(project?.awarded_date).format("DD-MM-YYYY")}
                      </td>
                      <td>{moment(project?.end_date).format("DD-MM-YYYY")}</td>
                      <td>
                        {project?.status == 1
                          ? "Started"
                          : project?.status == 2
                          ? "In Progress"
                          : "Completed"}
                      </td>
                      <td>
                        <div className="d-flex">
                          <img
                            className="pointer me-1"
                            style={{ width: "20px" }}
                            src={eye_icon}
                            alt="eye-icon"
                            onClick={() =>
                              navigate("/home/project-action", {
                                state: {
                                  project_id: project?.id,
                                  action: "view",
                                },
                              })
                            }
                          />
                          <img
                            className="pointer ms-1"
                            style={{ width: "20px" }}
                            src={edit_icon}
                            alt="eye-icon"
                            onClick={() =>
                              navigate("/home/project-action", {
                                state: {
                                  project_id: project?.id,
                                  action: "edit",
                                },
                              })
                            }
                          />
                        </div>
                      </td>
                      <td className="emp-manage-toggle-switch-box"></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Col>
    </Col>
  );
};

export default ProjectsList;
