import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import { api } from "../../constants/Services";
import { useNavigate } from "react-router-dom";
import Header from "../../component/Header/Header";
import PoCreationAmendForm from "../../component/PoCreationAmendment/PocreationAmendForm";
import PoCreationtAmendTableSection from "../../component/PoCreationAmendment/PoCreationAmendTableSec";

const PoCreationAmendmentScreen = () => {
  const navigate = useNavigate();
  const [projectList, setProjectList] = useState([]);
  const [miscSaveDetails, setMiscSaveDetails] = useState([]);
  const [miscModalOpen, setMiscModalOpen] = useState(false);
  const [updatMisc, setUpdateMisc] = useState({});
  const [modal, setModal] = useState(false);
  const [masterType, setMasterType] = useState("");
  const [department, setDepartment] = useState("");
  const [hsnDetail, setHsnDetail] = useState({
    hsn_code: "",
    hsn_id: "",
    hsn_des: "",
    igst: "",
    sgst: "",
    cgst: "",
    igst_value: "",
    sgst_value: "",
    cgst_value: "",
  });
  const [poDetail, setPoDetail] = useState([]);
  const subDepartment = [
    "ELECTRICAL",
    "CIVIL",
    "MECHANICAL",
    "HVAC",
    "FIRE FIGHTING",
    "ELV",
    "OTHERS",
    "PHE",
    "PROCESS PIPE",
    "SOLVENT PIPE",
    "ASSETS",
  ];
  const [purchaseOrderDetails, setPurchseOrderDetails] = useState({
    po_id: "",
    company_id: "",
    company: "",
    project_no: "",
    project_id: "",
    mr_no: "",
    mr_date: "",
    is_gst: null,
    requestion_type: "",
    procure_type: "",
    terms: "",
    material_type: "",
    material_request_id: "",
    po_no: "",
    po_date: "",
    company_name: "",
    gst_status: "",
    vendor_id: "",
    vendor_address: "",
    currency: "",
  });
  const [purchaseOrderTableDetails, setPurchaseOrderTableDetails] = useState(
    []
  );
  const [qty, setQty] = useState({});
  const [qtyErr, setQtyErr] = useState({});
  const [basicPrice, setBasicPrice] = useState({});
  const [tolorance, setTolorance] = useState({});
  const [miscPrice, setMiscPrice] = useState({});
  const [checkBox, setCheckBox] = useState([]);
  const [miscModalOpens, setMiscModalOpens] = useState({});
  const [miscModals, setMiscModals] = useState({});
  const poPrintHandler = () => {
    setModal(!modal);
    navigate("/procurement/po-output", { state: poDetail });
  };

  console.log("updatMisc", basicPrice, qty);

  const poAmendSubmit = () => {
    let formdata = new FormData();
    formdata.append("project_id", purchaseOrderDetails?.project_id);
    formdata.append("company_id", purchaseOrderDetails?.company_id);
    formdata.append("purchase_order_id", purchaseOrderDetails?.po_id);
    formdata.append("terms_conditions", purchaseOrderDetails?.terms || "");
    formdata.append(
      "material_request_id",
      purchaseOrderDetails?.material_request_id ?? ""
    );
    formdata.append("vendor_id", purchaseOrderDetails?.vendor_id);
    formdata.append("head_office", "Crescon Projects and Service Pvt Ltd");
    formdata.append("project_no", purchaseOrderDetails?.project_no);
    formdata.append("material_type", purchaseOrderDetails?.material_type);
    formdata.append("po_date", purchaseOrderDetails?.po_date);
    formdata.append("is_gst", purchaseOrderDetails?.gst_status);
    formdata.append("remarks", "temp till backend update of removing remarks");
    formdata.append("spares", 0);
    formdata.append("procure_type", purchaseOrderDetails?.procure_type);
    formdata.append("currency", purchaseOrderDetails?.currency);

    if (masterType == "Assets") {
      formdata.append("type", 1);
    } else {
      formdata.append("type", 2);
      formdata.append("department", department);
    }

    let count = 1;

    purchaseOrderTableDetails &&
      purchaseOrderTableDetails?.map((item, index) => {
        if (checkBox?.includes(item?.id)) {
          formdata.append(
            `purchase_details[${index}][boq_code]`,
            item?.boq_code
          );
          formdata.append(
            `purchase_details[${index}][material_id]`,
            item?.material?.id ?? 0
          );

          if (+qtyErr[item?.id] == 1 || +qtyErr[item?.id] == 2) {
            count = count + 1;
          }

          if (miscModals[item?.id]) {
            formdata.append(
              `purchase_details[${index}][basic_price]`,
              updatMisc[item?.id]?.basic_price ?? ""
            );
          } else {
            formdata.append(
              `purchase_details[${index}][basic_price]`,
              basicPrice[item?.id] ?? ""
            );
          }

          if (+tolorance[item?.id] > 15) {
            count = count + 1;
          } else {
            formdata.append(
              `purchase_details[${index}][tolerance]`,
              tolorance[item?.id] !== undefined ? tolorance[item?.id] : "0000"
            );
          }

          if (purchaseOrderDetails?.requestion_type == 2) {
            formdata.append(
              `purchase_details[${index}][attributes]`,
              item?.attributes
            );
          }

          formdata.append(
            `purchase_details[${index}][specifications]`,
            item?.specifications !== undefined ? item?.specifications : []
          );

          item?.specifications?.map((ite, inde) => {
            formdata.append(
              `purchase_details[${index}][new_specifications][${inde}]`,
              item?.specifications !== undefined ? ite : []
            );
          });

          if (miscModals[item?.id]) {
            formdata.append(
              `purchase_details[${index}][hsn_code_id]`,
              updatMisc[item?.id]?.hsn_id
            );
          } else {
            // formdata.append(
            //   `purchase_details[${index}][hsn_code_id]`,
            //   item?.hsn_code?.code_name !== undefined
            //     ? item?.hsn_code?.code_name
            //     : "000"
            // );
            formdata.append(
              `purchase_details[${index}][hsn_code_id]`,
              item?.hsn_code?.id ?? "000"
            );
          }

          if (miscModals[item?.id]) {
            formdata.append(
              `purchase_details[${index}][qty]`,
              updatMisc[item?.id]?.qty ? updatMisc[item?.id]?.qty : "000"
            );
          } else {
            formdata.append(
              `purchase_details[${index}][qty]`,
              item?.qty !== undefined ? item?.qty : "000"
            );
          }

          if (miscModals[item?.id]) {
            formdata.append(
              `purchase_details[${index}][sgst_value]`,
              updatMisc[item?.id]?.sgst_value
            );
          } else {
            formdata.append(
              `purchase_details[${index}][sgst_value]`,
              item?.hsn_code?.sgst_value !== undefined
                ? item?.hsn_code?.sgst_value
                : "000"
            );
          }

          if (miscModals[item?.id]) {
            formdata.append(
              `purchase_details[${index}][igst_value]`,
              updatMisc[item?.id]?.igst_value
            );
            // console.log('updatMisc[item?.id]?.igst_value', updatMisc[item?.id]?.igst_value);
          } else {
            formdata.append(
              `purchase_details[${index}][igst_value]`,
              item?.hsn_code?.igst_value !== undefined
                ? item?.hsn_code?.igst_value
                : "000"
            );

            // console.log('updatMisc[item?.id]?.igst_value',item?.id, updatMisc[item?.id]?.igst_value);
          }

          if (miscModals[item?.id]) {
            formdata.append(
              `purchase_details[${index}][cgst_value]`,
              updatMisc[item?.id]?.cgst_value
            );
          } else {
            formdata.append(
              `purchase_details[${index}][cgst_value]`,
              item?.hsn_code?.cgst_value !== undefined
                ? item?.hsn_code?.cgst_value
                : "000"
            );
          }

          // formdata.append(
          //   `purchase_details[${index}][cgst_value]`,
          //   item?.cgst_value !== undefined ? item?.hsn_code?.cgst_value : "000"
          // );

          if (miscModals[item?.id]) {
            formdata.append(
              `purchase_details[${index}][discount_percent]`,
              updatMisc[item?.id]?.discount_percent
            );
          } else {
            formdata.append(
              `purchase_details[${index}][discount_percent]`,
              item?.discount_percent !== undefined
                ? item?.discount_percent
                : "000"
            );
          }

          if (miscModals[item?.id]) {
            formdata.append(
              `purchase_details[${index}][discount_value]`,
              updatMisc[item?.id]?.discount_value
            );
          } else {
            formdata.append(
              `purchase_details[${index}][discount_value]`,
              item?.discount_value !== undefined ? item?.discount_value : "000"
            );
          }

          if (miscModals[item?.id]) {
            formdata.append(
              `purchase_details[${index}][net_value]`,
              updatMisc[item?.id]?.net_value
            );
          } else {
            formdata.append(
              `purchase_details[${index}][net_value]`,
              +basicPrice[item?.id] * +qty[item?.id] + +item?.misc
            );
          }

          if (miscModals[item?.id]) {
            formdata.append(
              `purchase_details[${index}][misc]`,
              updatMisc[item?.id]?.misc
                ? Number(updatMisc[item?.id]?.misc).toFixed(2)
                : 1
            );
          } else {
            formdata.append(
              `purchase_details[${index}][misc]`,
              item?.misc !== undefined ? item?.misc : "0000"
            );
          }

          if (miscModals[item?.id]) {
            formdata.append(
              `purchase_details[${index}][total_value]`,
              updatMisc[item?.id]?.total_value
            );
          } else {
            formdata.append(
              `purchase_details[${index}][total_value]`,
              +basicPrice[item?.id] * +qty[item?.id]
            );
          }

          formdata.append(
            `purchase_details[${index}][remarks]`,
            item?.remarks ? item?.remarks : ""
          );

          formdata.append(
            `purchase_details[${index}][instruction]`,
            item?.instruction !== undefined ? item?.instruction : "instruction"
          );
        }
      });

    if (count == 1) {
      api.purchaseOrderCreate(formdata).then((res) => {
        if (res?.status == "success") {
          setPoDetail(res?.purchase_order);
          setModal(true);
        } else {
          alert(res?.message);
        }
      });
    }
  };

  // console.log('purchaseOrderDetails', purchaseOrderDetails);

  const getProjects = () => {
    if (masterType == "Assets") {
      api.project_list_assets().then((res) => {
        setProjectList(res?.projects);
      });
    } else {
      api.project_list().then((res) => {
        setProjectList(res?.projects);
      });
    }
  };

  // console.log("purchaseOrderTableDetails", miscSaveDetails);
  console.log("miscModalOpens", miscModalOpens);

  const calcu_percentage_value = (percent, total_value) => {
    const percentate_value = (percent / 100) * total_value;
    console.log("percentate_value", percentate_value);
    return percentate_value;
  };

  const searchHsn = (hsn_code, total_val) => {
    let data = {
      code: hsn_code,
    };
    api.searchHSN(data).then((res) => {
      if (res?.status === "success") {
        if (purchaseOrderDetails?.is_gst == 1) {
          console.log("res", res);
          console.log("tes", res?.hsn_code?.igst, total_val);
          setHsnDetail({
            hsn_code: res?.hsn_code?.code_name,
            hsn_id: res?.hsn_code?.id,
            hsn_des: res?.hsn_code?.description,
            igst: res?.hsn_code?.igst,
            igst_value: calcu_percentage_value(res?.hsn_code?.igst, total_val),
            sgst: "-",
            cgst: "-",
            sgst_value: "-",
            cgst_value: "-",
          });
        } else {
          setHsnDetail({
            hsn_code: res?.hsn_code?.code_name,
            hsn_id: res?.hsn_code?.id,
            hsn_des: res?.hsn_code?.description,
            igst: "-",
            igst_value: "-",
            sgst: res?.hsn_code?.sgst,
            sgst_value: calcu_percentage_value(res?.hsn_code?.sgst, total_val),
            cgst: res?.hsn_code?.cgst,
            cgst_value: calcu_percentage_value(res?.hsn_code?.cgst, total_val),
          });
        }
      }
    });
  };

  console.log("hsnDetail", hsnDetail);

  const purchaseOrderTableHandler = (po_table_details) => {
    setPurchaseOrderTableDetails(po_table_details);
    let qtyObj = {};
    let qtyObjErr = {};
    let bsPriceObj = {};
    let miscObj = {};
    let miscValObj = {};
    let miscValOpen = {};
    let toloranceObj = {};
    console.log("po_table_details", po_table_details);
    po_table_details?.map((poDetail) => {
      bsPriceObj[poDetail?.id] = poDetail?.basic_price;
      miscObj[poDetail?.id] = {
        hsn_code: poDetail?.hsn_code?.code_name,
        igst_value: poDetail?.cgst_value,
        sgst_value: poDetail?.sgst_value,
        cgst: poDetail?.cgst_value,
        discount_percent: poDetail?.discount_percent,
        discount_value: poDetail?.discount_value,
        misc: poDetail?.misc,
        net_value: poDetail?.net_value,
        id: poDetail?.id,
      };
      qtyObj[poDetail?.id] = poDetail?.qty;
      qtyObjErr[poDetail?.id] = 0;
      miscValObj[poDetail?.id] = poDetail?.misc;
      // toloranceObj[poDetail?.id] = poDetail?.tolerance;
      miscValOpen[poDetail?.id] = false;
    });
    setMiscModalOpens(miscValOpen);
    setMiscModals(miscValOpen);
    setBasicPrice(bsPriceObj);
    setMiscPrice(miscValObj);
    // setQty(qtyObj);
    setTolorance(toloranceObj);
    setQty({});
    setQtyErr(qtyObjErr);
    setMiscSaveDetails(miscObj);
  };

  const PurchaseOrderDetailsHandler = (sel_po) => {
    api.purchaseOrderView(+sel_po).then((res) => {
      if (res?.status == "success") {
        setPurchseOrderDetails({
          po_id: res?.purchase_orders?.id,
          mr_no: res?.purchase_orders?.material_request?.mr_no,
          mr_date: res?.purchase_orders?.material_request?.mr_date,
          is_gst: res?.purchase_orders?.is_gst,
          material_request_id: res?.purchase_orders?.material_request?.id,
          project_id:
            res?.purchase_orders?.project?.id ||
            res?.purchase_orders?.project_id,
          project_no: res?.purchase_orders?.project?.project_no,
          vendor_id: res?.purchase_orders?.vendor?.id,
          terms: res?.purchase_orders?.terms_conditions,
          requestion_type: res?.purchase_orders?.material_request?.request_type,
          po_no: res?.purchase_orders?.po_no,
          material_type:
            res?.purchase_orders?.vendor?.product_type ||
            res?.purchase_orders?.material_request?.material_type,
          // po_date: res?.purchase_orders?.po_date,
          company_name: res?.purchase_orders?.vendor?.company_name,
          gst_status: res?.purchase_orders?.vendor?.gst_status,
          vendor_address: res?.purchase_orders?.vendor?.address,
          currency: res?.purchase_orders?.currency,
          procure_type: res?.purchase_orders?.procure_type,
          company_id: res?.purchase_orders?.company_id,
          company: res?.purchase_orders?.company,
        });
        setDepartment(res?.purchase_orders?.department);

        purchaseOrderTableHandler(res?.purchase_orders?.purchase_order_details);
        // console.log("da", res?.purchase_orders?.purchase_order_details);
      }
    });
  };

  const selectCheckBox = (id) => {
    let temp = [...checkBox];
    let inx = temp.indexOf(id);

    if (temp.includes(id)) {
      temp.splice(inx, 1);
    } else {
      temp.push(id);
    }

    setCheckBox(temp);
  };

  // console.log("checkBox", checkBox);

  useEffect(() => {
    if (masterType?.length > 0) {
      getProjects();
    }
  }, [masterType]);
  const [retrunSlipDate, setRetrunSlipDate] = useState("");
  const [materialIssueDate, setMaterialIssueDate] = useState("");
  return (
    <Col xs={12} className="py-2  px-1 p-md-3">
      <Header />
      <PoCreationAmendForm
        PurchaseOrderDetailsHandler={PurchaseOrderDetailsHandler}
        projectList={projectList}
        setMasterType={setMasterType}
        masterType={masterType}
        purchaseOrderDetails={purchaseOrderDetails}
        setPurchseOrderDetails={setPurchseOrderDetails}
        setPurchaseOrderTableDetails={setPurchaseOrderTableDetails}
        setDepartment={setDepartment}
        department={department}
        subDepartment={subDepartment}
        retrunSlipDate={retrunSlipDate}
        setRetrunSlipDate={setRetrunSlipDate}
        materialIssueDate={materialIssueDate}
        setMaterialIssueDate={setMaterialIssueDate}
      />
      {purchaseOrderTableDetails.length > 0 && (
        <PoCreationtAmendTableSection
          miscSaveDetails={miscSaveDetails}
          miscModalOpen={miscModalOpen}
          setMiscModalOpen={setMiscModalOpen}
          setMiscSaveDetails={setMiscSaveDetails}
          hsnDetail={hsnDetail}
          setHsnDetail={setHsnDetail}
          searchHsn={searchHsn}
          requestionType={purchaseOrderDetails?.requestion_type}
          setBasicPrice={setBasicPrice}
          basicPrice={basicPrice}
          qty={qty}
          setQty={setQty}
          tolorance={tolorance}
          setTolorance={setTolorance}
          setQtyErr={setQtyErr}
          qtyErr={qtyErr}
          purchaseOrderTableDetails={purchaseOrderTableDetails}
          setUpdateMisc={setUpdateMisc}
          masterType={masterType}
          selectCheckBox={selectCheckBox}
          checkBox={checkBox}
          miscPrice={miscPrice}
          setMiscPrice={setMiscPrice}
          setMiscModalOpens={setMiscModalOpens}
          setMiscModals={setMiscModals}
          miscModalOpens={miscModalOpens}
        />
      )}

      <Col sm={12} className="inputinner-box text-start">
        <button
          className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
          onClick={poAmendSubmit}
        >
          Save
        </button>
        <button className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3">
          cancel
        </button>
      </Col>
      <Modal show={modal} centered onHide={() => setModal(!modal)}>
        <Modal.Header closeButton className="border-0">
          <label className="w-100 mx-auto my-1 py-1">
            PO Number : {poDetail?.po_no}
          </label>
        </Modal.Header>
        {/* <Modal.Body className="border-0">
          <p className="text-center fs-6">Do you want to Print the Output?</p>
        </Modal.Body> */}
        <Modal.Footer className="border-0">
          <Button
            className="btn btn-primary"
            // onClick={poPrintHandler}
            onClick={() => window.location.reload()}
            variant="primary"
          >
            Submit
          </Button>
          <Button variant="secondary" onClick={() => setModal(!modal)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default PoCreationAmendmentScreen;
