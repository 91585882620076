import React, { useState } from "react";
import { Chart } from "react-google-charts";
import { arwIcon } from "../../assets/img";
import ChartFile from "../Chart/ChartFile";
import EmployeePieChart from "../Chart/EmployeePieChart";
import ProjectPieChart from "../Chart/ProjectPieChart";
import RevenueLineChart from "../Chart/RevenueLineChart";

const RevenueChart = () => {
  const [drop, setDrop] = useState(false);
  const [showSelect, setShowSelect] = useState("Month");
  const clickHandler = (e) => {
    setDrop(!drop);
    if (e == "Year") {
      setShowSelect("Year");
    } else if (e == "Month") {
      setShowSelect("Month");
    }
  };
  const data = [
    ["Task", "Employee"],
    ["New Employee", 407],
    ["Total Employee", 654],
  ];
  return (
    <div className="pe-3 w-100">
      <div className="align-items-center justify-content-around bg-white top-dash-board2 position-relative rounded px-3 pt-3 pb-5">
        <div className="chart-box-detail-top w-100 d-flex align-items-center justify-content-center ">
          <div className="char-box-detail">
            <h4>Revenue Detail</h4>
          </div>
          <div className="drop-down-custom ms-3">
            <div onClick={(e) => clickHandler(e)} className="show">
              <p className="p-show">{showSelect}</p>
              <img src={arwIcon} className="arwIcon" />
            </div>
            {drop && (
              <div className="hid-drop">
                <p onClick={() => clickHandler("Year")}>2022</p>
                <p onClick={() => clickHandler("Month")}>2023</p>
              </div>
            )}
          </div>
        </div>
        <div className="mt-5 d-flex justify-content-center">
          <RevenueLineChart showSelect={showSelect} />
        </div>
        <div className="chart-box-detail-btm d-flex flex-wrap justify-content-center w-100">
          <div className="d-flex char-box-detail align-items-center mb-3 w-50 justify-content-center">
            <div className="squar-char-box bg-primar"></div>
            <h5>Last Year - 654000L</h5>
          </div>
          <div className="d-flex char-box-detail align-items-center mb-3 w-50 justify-content-center">
            <div className="squar-char-box bg-darkBlue"></div>
            <h5>This Year - 400700L</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueChart;
