import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { tickIcon } from "../../assets/img";

const RolesTab = ({
  roleModules,
  permissions,
  setEmpPermissions,
  setPermissions,
  setTab,
  setAllowAccess,
  allowAccess,
}) => {
  const checkBoxHandler = (id) => {
    let temp = [...permissions];
    if (temp?.includes(id)) temp?.splice(temp?.indexOf(+id), 1);
    else temp.push(+id);
    setPermissions(temp);
  };

  useEffect(() => {
    let temp = [];
    if (roleModules) {
      roleModules?.map((item) =>
        item?.permissions.filter((per) => {
          return permissions.includes(per?.id) && temp.push(per?.id);
        })
      );
    }
    setPermissions(temp);
  }, [roleModules]);

  const resScreenReportNextClickHandler = () => {
    if (permissions.length > 0) {
      setEmpPermissions(permissions);
      setTab("finance");
      setAllowAccess([...allowAccess, "report"]);
    }
  };
  return (
    <Col className="emply-master-form px-1 py-2 px-md-3 py-md-3 bg-white mt-3 text-center">
      <h4 className="mb-2">Screen & Report</h4>
      <div className="d-flex flex-wrap justify-content-xxl-around">
        {roleModules.length > 0 &&
          roleModules?.map((role) => (
            <div
              key={role?.id}
              className="roles-box text-start me-0 me-md-3 me-lg-4">
              <h5>{role?.code}</h5>
              {role?.permissions?.map((item) => {
                return (
                  <button
                    onClick={() => checkBoxHandler(item?.id)}
                    key={item?.id}
                    className="border-0 cust-btn d-flex fs-12 align-items-center mb-2">
                    <div className="checkboxs me-2">
                      {permissions.includes(item?.id) && (
                        <img src={tickIcon} alt="tick-icon" />
                      )}
                    </div>
                    {item?.code}
                  </button>
                );
              })}
            </div>
          ))}
      </div>
      <button
        className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
        onClick={resScreenReportNextClickHandler}>
        Next
      </button>
    </Col>
  );
};

export default RolesTab;
