// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import Header from "../../component/Header/Header";
import { useNavigate } from "react-router-dom";
import { api } from "../../constants/Services";
import { TbAlertTriangleFilled } from "react-icons/tb";
import PoTermsAndCondition from "./PoTerms&Condition";
import PoCreationForm from "../../component/PoCreation/PoCreationForm";
import PoCreationtTableSection from "../../component/PoCreation/PocreationTableSec";
import PoCreationFormCopy from "../../component/PoCreation/PoCreationFormCopy";

const PoCreationScreen = () => {
  const navigate = useNavigate();
  const [requestionType, setRequestionType] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const [modal, setModal] = useState(false);
  const [miscEnable, setMiscEnable] = useState(false);
  const [btn, setBtn] = useState(false);
  const [uploads, setUpload] = useState();
  const [selId, setSelId] = useState(null);
  const [poSavedetails, setPoSaveDetails] = useState();
  const [modelDisplay, setModelDisplay] = useState("");
  const [qunChange, setQuanChange] = useState({});
  const [MaterialLevelDetails, setMaterialLevelDetails] = useState([]);
  const [directPoValue, setDirectPoValue] = useState({});
  const [listCompany, setCompanyList] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [address, setAddress] = useState("");
  const [addressDisable, setAddressDisable] = useState(true);
  const [poFormDetails, setPoFormDetails] = useState({
    company_id: "",
    project_id: "",
    vendor_id: "",
    po_date: "",
    procure_type: "",
    currency: "",
    material_request_id: "",
    terms_conditions: "",
    project_no: "12345678",
    material_type: "",
    type_of_material: "",
    is_gst: null,
  });

  const [vendorDetail, setVendorDetail] = useState({
    company_name: "",
    company_address: "",
    state: "",
    drop: false,
  });

  const [vendorList, setVendorList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [department, setDepartment] = useState("");

  const [tableError, setTableError] = useState({
    basic_rate: [],
    total_value: [],
  });
  const [commonFields, setCommonFields] = useState({
    basic_price: {},
    total_price: {},
    remarks: {},
  });
  const [miscDetails, setMiscDetails] = useState({
    discount_value: "",
    dis_percent: "",
    total_val_af_dis: "",
    net_value: "",
  });

  const [boqFields, setBoqFields] = useState({
    misc: {},
    tolerance: {},
    net_value: {},
    attribute: [],
    show_attribute: [],
  });
  const [directPoFields, setDirectPoFields] = useState({
    entered_value: 0,
    attribute: "",
    total_val: 0,
  });
  const [hsnDetail, setHsnDetail] = useState({
    hsn_code: "",
    hsn_des: "",
    igst: "",
    sgst: "",
    cgst: "",
    igst_value: "",
    sgst_value: "",
    cgst_value: "",
  });
  const [error, setError] = useState([]);
  const [check, setCheck] = useState(false);
  const [currentTable, setCurrentTable] = useState(null);
  const [poTableData, setPoTableData] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState([]);

  const [newSpecifs, setNewSpecifs] = useState({});
  const [specLength, setSpecLength] = useState();

  const newSpecChangeHandler = (e, ind) => {
    const outerArr = { ...newSpecifs };
    const tmp = {
      [ind]: e.target.value,
    };
    outerArr[selId] = { ...outerArr[selId], ...tmp };

    setNewSpecifs(outerArr);

    // setNewSpecifs((val) => ({ ...val, [ind]: e.target.value }));
    // setNewSpecif({ ...newSpecif, [ind]: e.target.value });
  };

  // console.log("newSpecif", newSpecifs);
  // for (let j = 0; j < 0; j++) {
  //   specLength?.push(j);
  // }

  useEffect(() => {
    let len = [];
    if (boqFields?.show_attribute?.length > 0) {
      let length = 6 - boqFields?.show_attribute?.length;
      if (length > 0) {
        for (let i = 0; i < length; i++) {
          len?.push(i);
        }
      }
    } else if (boqFields?.show_attribute?.length == 0) {
      for (let i = 0; i < 6; i++) {
        len?.push(i);
      }
    }
    // console.log(len);
    setSpecLength(len);
  }, [boqFields?.show_attribute]);

  // console.log(specLength);

  const revisedTotalCalc = (tot_val, dis_val) => tot_val - dis_val;

  const miscDiscountHandler = async (percent, total_price) => {
    const disVal = calcu_percentage_value(+percent, +total_price);
    const revised_total = revisedTotalCalc(+total_price, +disVal);
    let net_value;

    if (disVal && revised_total) {
      if (poFormDetails?.is_gst == 1) {
        let igst = calcu_percentage_value(
          hsnDetail?.igst[selId],
          revised_total
        );
        setHsnDetail({
          ...hsnDetail,
          igst_value: { [selId]: igst },
        });
        net_value = igst;
      } else {
        const cgst = calcu_percentage_value(
          hsnDetail.cgst[selId],
          revised_total
        );
        const sgst = calcu_percentage_value(
          hsnDetail.sgst[selId],
          revised_total
        );
        setHsnDetail({
          ...hsnDetail,
          sgst_value: { [selId]: cgst },
          cgst_value: { [selId]: sgst },
        });
        net_value = cgst + sgst;
      }
      setMiscDetails({
        discount_value: disVal,
        dis_percent: percent,
        total_val_af_dis: revised_total,
        net_value: net_value,
      });
    }
  };
  const boqAttributeModalHandler = (mat_id, attribute) => {
    // setSelectedMaterial([]);
    console.log("attribute", attribute);
    let temp = [];
    if (attribute) {
      Object?.entries(attribute).forEach((item) => {
        const [key, value] = item;
        if (key.includes("specification") && value) temp.push(value);
      });
    }
    setSelId(+mat_id);
    setBoqFields({ ...boqFields, show_attribute: temp });
    setModal(true);
    setModelDisplay("boq-att");
  };

  const basicPriceHandler = (
    sel_id,
    basic_rate,
    req_qty,
    tol_price, // basic price
    b_material_1
  ) => {
    // setSelectedMaterial([]);
    const max_price = +tol_price / +req_qty;
    const totalPrice = +req_qty * +basic_rate;
    let error = [...tableError?.basic_rate];

    console.log("error", error);

    // console.log(
    //   "basic_rate",
    //   +basic_rate,
    //   "tol_price",
    //   +tol_price,
    //   "max_price",
    //   max_price
    // );
    // if (+basic_rate > +max_price) {
    if (+basic_rate > +b_material_1) {
      if (!error.includes(+sel_id)) {
        error.push(+sel_id);
      }
      setMiscEnable(false);
    } else {
      error.splice(error?.indexOf(+sel_id), 1);
      setMiscEnable(true);
    }

    setCommonFields({
      ...commonFields,
      basic_price: { ...commonFields?.basic_price, [+sel_id]: basic_rate },
      total_price: { ...commonFields?.total_price, [+sel_id]: totalPrice },
    });
    setTableError({ ...tableError, basic_rate: error });
  };

  console.log("directPoValue", directPoValue);

  const tableSelHandler = (sel_mat) => {
    console.log("se", sel_mat);
    let temp = [...selectedMaterial];
    if (temp && temp?.includes(+sel_mat))
      temp.splice(temp.indexOf(+sel_mat), 1);
    else temp.push(+sel_mat);
    console.log("temp", temp);
    setSelectedMaterial(temp);
  };

  function raiseALert() {
    setModelDisplay("alert");
    setModal(true);
  }

  const quantityHandler = (value, id) => {
    // setSelectedMaterial([]);
    setQuanChange((val) => ({ ...val, [id]: value }));
  };

  const directPoBasicPriceHandler = (
    id,
    req_qty,
    basic_price,
    custom_value
  ) => {
    // setSelectedMaterial([]);
    // console.log("hhh");
    let tot_val = +req_qty * +basic_price;
    let alert_price = calcu_percentage_value(90, +directPoFields?.total_val);
    let temp = [...tableError?.basic_rate];
    let total_entered_price = Object.values(commonFields?.total_price).reduce(
      (val, acc) => acc + val,
      0
    );

    console.log("total_entered_price", total_entered_price);

    // if (tot_val > +directPoFields?.total_val) {
    if (+tot_val > +custom_value) {
      temp?.push(id);
      // console.log("<<<<<");
    } else {
      temp?.includes(id) && temp.splice(temp.indexOf(id), 1);
      // console.log(">>>>>>");
    }
    setCommonFields({
      ...commonFields,
      basic_price: { ...commonFields?.basic_price, [id]: +basic_price },
      total_price: { ...commonFields?.total_price, [id]: tot_val },
    });
    setTableError({ ...tableError, basic_rate: temp });

    total_entered_price = total_entered_price + tot_val;
    if (total_entered_price > alert_price) {
      let count = alertCount;
      count += 1;
      setAlertCount(count);
      if (count < 2) {
        raiseALert();
      }
    }
  };

  const directPoBasicPriceHandlerNew = (
    id,
    req_qty,
    basic_price,
    custom_value,
    boq_code
  ) => {
    // setSelectedMaterial([]);
    // console.log("hhh");
    // console.log("boq_code", boq_code);
    let tot_val = +req_qty * +basic_price;
    let alert_price = calcu_percentage_value(90, +directPoFields?.total_val);
    let temp = [...tableError?.basic_rate];
    let total_entered_price = Object.values(commonFields?.total_price).reduce(
      (val, acc) => acc + val,
      0
    );

    console.log("tot_val", tot_val);

    let boq_val = directPoValue[boq_code]?.value;
    let boq_cus_val = directPoValue[boq_code]?.custom;
    let sumed_value = +tot_val - +boq_cus_val;

    let custom_total_value =
      directPoValue[boq_code]?.custom <= directPoValue[boq_code]?.value;

    console.log("boq_val", boq_val);
    console.log("boq_cus_val", boq_cus_val);
    console.log("sumed_value", sumed_value);
    console.log("custom_total_value", custom_total_value);

    if (+sumed_value > +boq_val) {
      temp?.push(id);
      // console.log("<<<<<");
    } else {
      let obj = { ...directPoValue };

      obj[boq_code]["custom"] = tot_val;

      // console.log("obj", obj, tot_val);

      temp?.includes(id) && temp.splice(temp.indexOf(id), 1);
      // console.log(">>>>>>");
    }
    setCommonFields({
      ...commonFields,
      basic_price: { ...commonFields?.basic_price, [id]: +basic_price },
      total_price: { ...commonFields?.total_price, [id]: tot_val },
    });
    setTableError({ ...tableError, basic_rate: temp });

    total_entered_price = total_entered_price + tot_val;
    // if (total_entered_price > alert_price) {
    //   let count = alertCount;
    //   count += 1;
    //   setAlertCount(count);
    //   if (count < 2) {
    //     raiseALert();
    //   }
    // }
  };

  let table_headings;
  let tableFields;

  // console.log("poTableData", poTableData);

  if (currentTable === "boq" && poTableData?.length > 0) {
    table_headings = [
      "S No",
      "",
      "Boq Code",
      "Material Code",
      "Material Description",
      "UOM",
      "Attributes",
      "Quantity",
      "Basic Price",
      "Tolerance",
      "Total Value",
      "Misc",
      "Net Value",
      "Remarks",
    ];
    tableFields = poTableData?.map((item, ind) => {
      console.log(
        "item",
        (+boqFields?.tolerance[item?.id] * +item?.required_quantity) / 100
      );
      return (
        <tr key={ind}>
          <td>{ind + 1}</td>
          <td className="text-center">
            <input
              type="checkbox"
              value={item?.id}
              className="pointer"
              onChange={(e) => tableSelHandler(e.target.value)}
              checked={selectedMaterial?.includes(item?.id)}
            />
          </td>
          <td>{item?.boq_code}</td>
          <td>{item?.material?.material_code}</td>
          <td style={{ minWidth: "210px" }}>{item?.material?.name}</td>
          <td>{item?.uom ?? item?.material?.uom}</td>
          <td style={{ minWidth: "150px" }}>
            <button
              className="border-0 bg-primary1 rounded-1 text-white"
              onClick={() =>
                boqAttributeModalHandler(item?.id, item?.material?.attribute)
              }
            >
              Show Attributes
            </button>
          </td>
          <td>{item?.required_quantity}</td>
          <td className="text-center">
            <input
              style={{ border: "0", width: "70px" }}
              value={commonFields && commonFields?.basic_price[+item?.id]}
              // disabled={!boqFields?.attribute[item?.id]}
              disabled={
                boqFields?.attribute[item?.id]?.length == undefined &&
                newSpecifs?.[item?.id]?.[0]?.length == undefined
              }
              onChange={(e) =>
                basicPriceHandler(
                  item?.id,
                  e.target.value,
                  item?.required_quantity,
                  item?.basic_price,
                  item?.b_material_1
                )
              }
              type={"number"}
            />
            {/* TEST */}
            {tableError?.basic_rate?.includes(item?.id) && (
              <p className="err">Exceeding Max Price</p>
            )}
          </td>
          <td>
            <input
              className="border-0 w-100 text-center"
              type={"number"}
              value={
                boqFields?.tolerance[item?.id] && boqFields?.tolerance[item?.id]
              }
              onChange={(e) =>
                setBoqFields({
                  ...boqFields,
                  tolerance: { [item?.id]: e.target.value },
                })
              }
            />
            {/* {(+boqFields?.tolerance[item?.id] * +item?.required_quantity) /
              100 >
              15 && <p className="err">Exceeding Max Value</p>} */}
            {+boqFields?.tolerance[item?.id] > 15 && (
              <p className="err">Exceeding Max Value</p>
            )}
          </td>
          <td className="text-center">
            {commonFields && commonFields?.total_price[+item?.id]}
            {/* {+commonFields?.total_price[+item?.id] >
              +item?.required_quantity * +item?.b_material_1 && (
              <p className="err">Exceeding Max Price</p>
            )} */}
          </td>
          <td>
            {boqFields && boqFields?.misc[item?.id] && !miscEnable ? (
              <p className="text-center">
                {Number(boqFields?.misc[item?.id]).toFixed(2)}
              </p>
            ) : (
              <button
                className="border-0 bg-primary1 rounded-1 text-white"
                onClick={() => miscModalHandler(item?.id)}
              >
                Misc
              </button>
            )}
          </td>
          <td className="text-center">
            {boqFields?.misc[item?.id] &&
              Number(
                +boqFields?.misc[item?.id] +
                  +commonFields?.total_price[item?.id]
              ).toFixed(2)}
          </td>
          <td className="text-center">
            <input
              className="border-0 w-100"
              value={
                commonFields?.remarks[item?.id] &&
                commonFields?.remarks[item?.id]
              }
              onChange={(e) =>
                setCommonFields({
                  ...commonFields,
                  remarks: { [item?.id]: e.target.value },
                })
              }
            />
          </td>
        </tr>
      );
    });
  } else if (currentTable === "direct_po" && poTableData?.length > 0) {
    table_headings = [
      "S No",
      "Select Material",
      "Boq Code",
      "Material Code",
      "Material Description",
      "UOM",
      "Attributes",
      "Quantity",
      "Basic Price",
      "Total Value",
      "Remarks",
    ];

    tableFields = poTableData?.map((item, ind) => {
      return (
        <tr key={ind}>
          <td>{ind + 1}</td>
          <td className="text-center">
            <input
              type="checkbox"
              value={item?.id}
              className="pointer"
              onChange={(e) => tableSelHandler(e.target.value)}
              checked={selectedMaterial?.includes(item?.id)}
            />
          </td>
          <td>{item?.boq_code}</td>
          <td>{item?.material?.material_code}</td>
          <td>{item?.material?.name}</td>
          <td>{item?.uom ?? item?.material?.uom}</td>
          <td>
            <input
              // value={directPoFields?.attribute}
              onChange={(e) =>
                setDirectPoFields({
                  ...directPoFields,
                  attribute: {
                    ...directPoFields?.attribute,
                    [item?.id]: e.target.value,
                  },
                })
              }
            />
          </td>
          <td>
            {/* {item?.required_quantity} */}
            <input
              value={qunChange[item?.id]}
              onChange={(e) => {
                quantityHandler(e.target.value, item?.id);
                setCommonFields({
                  ...commonFields,
                  basic_price: { ...commonFields?.basic_price, [item?.id]: "" },
                  total_price: { ...commonFields?.total_price, [item?.id]: "" },
                });
              }}
            />
          </td>
          <td>
            <input
              placeholder="enter required basic price"
              className="border-1 py-2"
              onChange={(e) =>
                directPoBasicPriceHandlerNew(
                  item?.id,
                  // item?.required_quantity,
                  qunChange[item?.id],
                  e.target.value,
                  // item?.total_value_custom
                  item?.nbi_value,
                  item?.boq_code
                )
              }
              value={
                commonFields?.basic_price[item?.id] &&
                commonFields?.basic_price[item?.id]
              }
            />
            {tableError?.basic_rate?.includes(item?.id) && (
              <p className="err">Exceeding Max Price</p>
            )}
          </td>
          <td>
            {commonFields?.total_price[item?.id] &&
              commonFields?.total_price[item?.id]}
          </td>

          <td>
            <input
              className="border-0 w-100"
              value={
                commonFields?.remarks[item?.id] &&
                commonFields?.remarks[item?.id]
              }
              onChange={(e) =>
                setCommonFields({
                  ...commonFields,
                  remarks: {
                    ...commonFields?.remarks,
                    [item?.id]: e.target.value,
                  },
                })
              }
            />
          </td>
        </tr>
      );
    });
  } else if (currentTable === "assets_type" && poTableData?.length > 0) {
    table_headings = [
      "S No",
      "Select Material",
      "Material Code",
      "UOM",
      "Attributes",
      "Quantity",
      "Basic Price",
      "Total Value",
      "Remarks",
    ];

    tableFields = poTableData?.map((item, ind) => {
      return (
        <tr key={ind}>
          <td>{ind + 1}</td>
          <td className="text-center">
            <input
              type="checkbox"
              value={item?.id}
              className="pointer"
              onChange={(e) => tableSelHandler(e.target.value)}
              checked={selectedMaterial?.includes(item?.id)}
            />
          </td>
          <td>{item?.boq_code}</td>
          <td>{item?.material?.material_code}</td>
          <td>{item?.material?.name}</td>
          <td>{item?.uom ?? item?.material?.uom}</td>
          <td>
            <input
              // value={directPoFields?.attribute}
              onChange={(e) =>
                setDirectPoFields({
                  ...directPoFields,
                  attribute: {
                    ...directPoFields?.attribute,
                    [item?.id]: e.target.value,
                  },
                })
              }
            />
          </td>
          <td>{item?.required_quantity}</td>
          <td>
            <input
              placeholder="enter required basic price"
              className="border-1 py-2"
              onChange={(e) =>
                directPoBasicPriceHandler(
                  item?.id,
                  item?.required_quantity,
                  e.target.value
                )
              }
              value={
                commonFields?.basic_price[item?.id] &&
                commonFields?.basic_price[item?.id]
              }
            />
            {tableError?.basic_rate?.includes(item?.id) && (
              <p className="err">Exceeding Max Price</p>
            )}
          </td>
          <td>
            {commonFields?.total_price[item?.id] &&
              commonFields?.total_price[item?.id]}
          </td>

          <td>
            <input
              className="border-0 w-100"
              value={
                commonFields?.remarks[item?.id] &&
                commonFields?.remarks[item?.id]
              }
              onChange={(e) =>
                setCommonFields({
                  ...commonFields,
                  remarks: {
                    ...commonFields?.remarks,
                    [item?.id]: e.target.value,
                  },
                })
              }
            />
          </td>
        </tr>
      );
    });
  }

  const get_vendors = () => {
    api.get_vendors().then((res) => {
      if (res?.status == "success") {
        setVendorList(res?.vendors);
      }
    });
  };

  const getProjects = () => {
    if (poFormDetails?.type_of_material == "Assets") {
      api.project_list_assets().then((res) => {
        setProjectList(res?.projects);
      });
    } else {
      api.project_list().then((res) => {
        setProjectList(res?.projects);
      });
    }
  };

  useEffect(() => {
    getProjects();
  }, [poFormDetails?.type_of_material]);

  useEffect(() => {
    get_vendors();
  }, []);

  const boqAttributeSelHandler = (sel_att) => {
    console.log("sel_att", sel_att);
    let temp = [];
    if (boqFields?.attribute[selId]) {
      temp = [...boqFields?.attribute[selId]];
    }

    temp.includes(sel_att)
      ? temp.splice(temp?.indexOf(sel_att), 1)
      : temp.push(sel_att);

    console.log("temp", temp);

    setBoqFields({
      ...boqFields,
      attribute: { ...boqFields?.attribute, [selId]: temp },
    });
  };

  const roundOffActionHandler = (action) => {
    let temp;
    if (action == "-") temp = Math.floor(miscDetails?.net_value);
    else temp = Math.ceil(miscDetails?.net_value);
    setMiscDetails({ ...miscDetails, net_value: temp });
  };

  const calcu_percentage_value = (percent, total_value) => {
    const percentate_value = (percent / 100) * total_value;
    return percentate_value;
  };

  const searchHsn = (hsn_code, total_val) => {
    let data = {
      code: hsn_code,
    };
    api.searchHSN(data).then((res) => {
      if (res?.status === "success") {
        // if (poFormDetails?.is_gst == 1) {
        if (vendorDetail?.state !== companyData?.state) {
          console.log("state!");
          setHsnDetail({
            hsn_code: {
              ...hsnDetail?.hsn_code,
              // [selId]: res?.hsn_code?.code_name,
              [selId]: res?.hsn_code?.id,
            },
            hsn_des: {
              ...hsnDetail?.hsn_des,
              [selId]: res?.hsn_code?.description,
            },
            igst: { ...hsnDetail?.igst, [selId]: res?.hsn_code?.igst },
            igst_value: {
              ...hsnDetail?.igst_value,
              [selId]: calcu_percentage_value(res?.hsn_code?.igst, total_val),
            },
            sgst: { ...hsnDetail?.sgst, [selId]: "-" },
            cgst: { ...hsnDetail?.cgst, [selId]: "-" },
            sgst_value: { ...hsnDetail?.sgst_value, [selId]: "-" },
            cgst_value: { ...hsnDetail?.cgst_value, [selId]: "-" },
          });
          setMiscDetails({
            ...miscDetails,
            net_value: calcu_percentage_value(res?.hsn_code?.igst, total_val),
          });
        } else {
          console.log("state");
          setHsnDetail({
            ...hsnDetail,
            hsn_code: {
              ...hsnDetail?.hsn_code,
              // [selId]: res?.hsn_code?.code_name,
              [selId]: res?.hsn_code?.id,
            },
            hsn_des: {
              ...hsnDetail?.hsn_des,
              [selId]: res?.hsn_code?.description,
            },
            igst: { ...hsnDetail?.igst, [selId]: "-" },
            igst_value: { ...hsnDetail?.igst_value, [selId]: "-" },
            sgst: { ...hsnDetail?.sgst, [selId]: res?.hsn_code?.sgst },
            sgst_value: {
              ...hsnDetail?.sgst_value,
              [selId]: calcu_percentage_value(res?.hsn_code?.sgst, total_val),
            },
            cgst: { ...hsnDetail?.cgst, [selId]: res?.hsn_code?.cgst },
            cgst_value: {
              ...hsnDetail?.cgst_value,
              [selId]: calcu_percentage_value(res?.hsn_code?.cgst, total_val),
            },
          });

          setMiscDetails({
            ...miscDetails,
            net_value:
              calcu_percentage_value(res?.hsn_code?.sgst, total_val) +
              calcu_percentage_value(res?.hsn_code?.cgst, total_val),
          });
        }
      }
    });
  };

  const poDateHandler = (po_date) => {
    const now = new Date();
    const enter_date = new Date(po_date);
    if (enter_date > now) {
      setError([...error, "po_date"]);
    } else {
      setPoFormDetails({ ...poFormDetails, po_date: po_date });
      const remove_error = error?.filter((error) => error != "po_date");
      setError(remove_error);
    }
  };

  const currenyHandler = (sel_currency) => {
    if (sel_currency == "India") {
      setPoFormDetails((prevState) => {
        return {
          ...prevState,
          procure_type: "domestic",
          currency: sel_currency,
        };
      });
    } else {
      setPoFormDetails((prevState) => {
        return {
          ...prevState,
          currency: sel_currency,
        };
      });
    }
  };

  const procureSelectHandler = (proc_type) => {
    if (proc_type == "domestic") {
      setPoFormDetails((prevState) => {
        return {
          ...prevState,
          procure_type: "domestic",
          currency: "India",
        };
      });
    } else {
      setPoFormDetails((prevState) => {
        return {
          ...prevState,
          procure_type: proc_type,
        };
      });
    }
    setPoFormDetails((prevState) => {
      return { ...prevState, procure_type: proc_type };
    });
  };

  const matReqTableDetailsHandler = (req_type, mr_id) => {
    let data = {
      material_request_id: mr_id,
    };
    if (req_type == 1) {
      api.matrialRequest(data).then((res) => {
        if (res?.status == "success") {
          setPoFormDetails({
            ...poFormDetails,
            material_type: res?.material_request?.material_type,
            material_request_id: mr_id,
          });
          setDepartment(res?.material_request?.department);
          console.log("res", res);
          let finder = res?.material_request_list?.filter(
            (i) => i?.cumulative_quantity == 0
          );
          // console.log("finder", finder);
          // setPoTableData(res?.material_request_list || res?.material_requests);
          setPoTableData(finder);
          setCurrentTable("boq");
        }
      });
    } else {
      api.matrialRequestDirectPo(data).then((res) => {
        if (res?.status == "success") {
          setPoFormDetails({
            ...poFormDetails,
            material_type: res?.material_request?.material_type,
            material_request_id: mr_id,
          });
          res?.material_request_list?.map((item, ind) => {
            setQuanChange((val) => ({
              ...val,
              [item?.id]: item?.required_quantity,
            }));
          });
          res?.material_requests?.map((item, ind) => {
            setQuanChange((val) => ({
              ...val,
              [item?.id]: item?.required_quantity,
            }));
          });
          setDepartment(res?.material_request?.department);

          let temp = [];
          if (res?.material_request_list?.length > 0) {
            res?.material_request_list?.map((item, ind) => {
              temp.push({
                ...item,
                total_value_custom: Number(
                  res?.total_value / res?.material_request_list?.length
                ).toFixed(2),
              });
            });
          }

          if (res?.material_requests?.length > 0) {
            res?.material_request_list?.map((item, ind) => {
              temp.push({
                ...item,
                total_value_custom: Number(
                  res?.total_value / res?.material_requests?.length
                ).toFixed(2),
              });
            });
          }
          const ids = temp.map(({ boq_code }) => boq_code);
          const filtered = temp.filter(
            ({ boq_code }, index) => !ids.includes(boq_code, index + 1)
          );

          let obj = {};

          filtered?.map((item, ind) => {
            obj[item?.boq_code] = {
              value: +item?.nbi_value > 0 ? +item?.nbi_value : 0,
              custom: 0,
            };
          });

          setDirectPoValue(obj);

          // console.log("filtered", filtered);

          setPoTableData(temp);

          // console.log("temp", temp);
          setDirectPoFields({
            ...directPoFields,
            total_val: res?.total_value ? res?.total_value : 0,
          });
          setCurrentTable("direct_po");
        }
      });
    }
  };

  const assetReqSelectHandler = (assets) => {
    setCurrentTable("assets_type");
    api.material_assests_search("?type=1").then((res) => {
      if (res?.status === "success") {
        let temp = [];
        res?.parent_suggestions?.map((item) => {
          temp.push(item);
        });
        res?.other_level_sugestions?.map((item) => {
          temp.push(item);
        });
        setPoTableData(temp);
      }
    });
  };

  const miscModalHandler = (sel_id) => {
    // console.log("comint, sel_id", sel_id);
    // setSelectedMaterial([]);
    setSelId(sel_id);
    setModelDisplay("misc");
    setModal(!modal);
  };

  const uploadFile = (item) => {
    let data = {
      import_excel: uploads,
      purchase_order_id: item?.id,
    };
    if (uploads) {
      api.purchaseOrderOutputAnnexure(data).then((res) => {
        alert(res?.message);
        setUpload("");
        setModelDisplay("print");
      });
    } else {
      alert("Please Upload File");
    }
  };

  const miscSubmitHandler = () => {
    setBoqFields({
      ...boqFields,
      misc: {
        ...boqFields?.misc,
        [selId]: Number(miscDetails?.net_value).toFixed(2),
      },
    });
    setMiscEnable(false);
    setModal(false);
  };
  const submitHandler = (method) => {
    let temp = [];
    // console.log("poFormDetails", poFormDetails);
    // console.log("newSpecifs", newSpecifs);
    Object.entries(poFormDetails).forEach((item) => {
      const [key, value] = item;
      if (
        !value &&
        key != "terms_conditions" &&
        !value &&
        key != "material_request_id" &&
        !value &&
        key != "material_type"
      ) {
        temp.push(key);
      }
    });

    // console.log("te", temp);

    setError(temp);

    if (temp.length == 0) {
      let formdata = new FormData();
      Object.entries(poFormDetails).forEach((item) => {
        const [key, value] = item;
        // console.log("key:", key, " value:", value);
        formdata.append(key, value);
      });
      formdata.append("head_office", "Crescon Projects and Service Pvt Ltd");

      if (addressDisable == false) {
        if (address?.length > 0) {
          formdata.append("po_address", address);
        } else {
          alert("Please Enter Address");
        }
      }

      if (poFormDetails?.type_of_material == "Assets") {
        formdata.append("type", 1);
      } else {
        formdata.append("type", 2);
      }

      if (department) {
        formdata.append("department", department);
      }

      formdata.append(
        "remarks",
        "temp till backend update of removing remarks"
      );
      formdata.append("spares", check ? 1 : 0);

      let filter_data = poTableData?.filter((item) =>
        selectedMaterial?.includes(item?.id)
      );

      var newSpac = [];
      for (const [key, val] of Object.entries(newSpecifs)) {
        const tmp = Object?.values(val);
        newSpac.push(val);
        // newSpac = [...newSpac, ...tmp];
        // console.log("tmp", tmp);
      }
      // console.log("newSpac", newSpac);

      let count = 1;

      filter_data?.length > 0 &&
        filter_data?.map((item, index) => {
          if (
            boqFields?.tolerance[item?.id] > 15 &&
            tableError?.basic_rate?.length == 0
          ) {
            // console.log("co");
            count = count + 1;
          }
          console.log("itemFil", item);
          formdata.append(
            `purchase_details[${index}][boq_code]`,
            item?.boq_code ?? 0
          );

          if (requestionType == 2) {
            formdata.append(
              `purchase_details[${index}][attributes]`,
              directPoFields?.attribute[item?.id] !== undefined
                ? directPoFields?.attribute[item?.id]
                : ""
            );

            formdata.append(`purchase_details[${index}][specifications]`, []);
            formdata.append(
              `purchase_details[${index}][new_specifications][${index}]`,
              []
            );

            formdata.append(
              `purchase_details[${index}][net_value]`,
              commonFields?.total_price[item?.id] ?? "0000"
            );
          }

          formdata.append(
            `purchase_details[${index}][material_id]`,
            item?.material?.id || item?.id || 0
          );

          if (commonFields?.basic_price[item?.id] <= 0) {
            count = count + 1;
            console.log("cou");
            alert("Basic Price Should not be Zero");
          }

          formdata.append(
            `purchase_details[${index}][basic_price]`,
            commonFields?.basic_price[item?.id] ?? "0000"
          );

          formdata.append(
            `purchase_details[${index}][tolerance]`,
            (+boqFields?.tolerance[item?.id] * +item?.required_quantity) / 100
              ? (+boqFields?.tolerance[item?.id] * +item?.required_quantity) /
                  100
              : "0"
          );

          formdata.append(
            `purchase_details[${index}][misc]`,
            boqFields?.misc[item?.id] ?? "0"
          );

          // console.log("miscDetails", miscDetails);

          // return false;
          if (
            requestionType == 1 ||
            poFormDetails?.type_of_material == "Assets"
          ) {
            console.log("newSpecifs", newSpecifs);
            formdata.append(
              `purchase_details[${index}][specifications]`,
              boqFields?.attribute[item?.id]?.map((item, ind) => item) ?? ""
            );

            let tmp;

            if (
              newSpac?.length > 0 &&
              Object.values(newSpac[index])?.length > 0
            ) {
              tmp = Object.values(newSpac[index]);
            }

            if (tmp?.length > 0) {
              tmp?.map((el, ind) => {
                formdata.append(
                  `purchase_details[${index}][new_specifications][${ind}]`,
                  el
                );
              });
            } else {
              formdata.append(
                `purchase_details[${0}][new_specifications][${0}]`,
                " "
              );
            }

            formdata.append(
              `purchase_details[${index}][hsn_code_id]`,
              hsnDetail?.hsn_code[item?.id] ?? "000"
            );
            formdata.append(
              `purchase_details[${index}][sgst_value]`,
              hsnDetail?.sgst_value[item?.id] ?? "00"
            );

            formdata.append(
              `purchase_details[${index}][igst_value]`,
              hsnDetail?.igst_value[item?.id] ?? "00"
            );
            formdata.append(
              `purchase_details[${index}][cgst_value]`,
              hsnDetail?.cgst_value[item?.id] ?? "000"
            );
            formdata.append(
              `purchase_details[${index}][discount_percent]`,
              miscDetails?.dis_percent[item?.id] ?? "0000"
            );
            formdata.append(
              `purchase_details[${index}][discount_value]`,
              miscDetails?.discount_value[item?.id] ?? "0000"
            );
            formdata.append(
              `purchase_details[${index}][net_value]`,
              +commonFields?.total_price[item?.id] + +boqFields?.misc[item?.id]
                ? +commonFields?.total_price[item?.id] +
                    +boqFields?.misc[item?.id]
                : "0000"
            );
          }

          if (currentTable === "direct_po") {
            console.log("kkkkk");
            formdata.append(
              `purchase_details[${index}][qty]`,
              qunChange[item?.id]
            );
          } else {
            console.log("ssss");
            formdata.append(
              `purchase_details[${index}][qty]`,
              item?.required_quantity !== undefined
                ? item?.required_quantity
                : item?.quantity !== undefined
                ? item?.quantity
                : "000"
            );
          }
          // if (+commonFields?.total_price[item?.id] > +item?.basic_price) {
          //   count = count + 1;
          //   console.log('counter');
          //   alert('Total price amount greater than basic price')
          // } else {

          formdata.append(
            `purchase_details[${index}][total_value]`,
            commonFields?.total_price[item?.id] ?? "0000"
          );
          // }
          formdata.append(
            `purchase_details[${index}][remarks]`,
            commonFields?.remarks[item?.id] ?? ""
          );
          formdata.append(
            `purchase_details[${index}][instruction]`,
            item?.instruction !== undefined ? item?.instruction : "instruction"
          );
        });
      // return false;
      setBtn(true);
      // console.log("count", count);
      if (count == 1) {
        if (poSavedetails?.id) {
          uploadFile({ id: poSavedetails?.id });
        } else {
          api.purchaseOrderCreate(formdata).then((res) => {
            if (res?.status == "success") {
              setBtn(false);
              setPoSaveDetails(res?.purchase_order);
              // setModelDisplay("print");
              // setModelDisplay("annex");
              // setModal(true);
              if (method !== 1) {
                uploadFile(res?.purchase_order);
              } else {
                setModelDisplay("print");
              }
            } else {
              setBtn(false);
            }
          });
        }
      }
    }
  };

  const submitPop = () => {
    setModelDisplay("annex");
    setModal(true);
  };

  // console.log("boqFields", boqFields);

  // console.log("poTableData", poTableData);

  const getCompanies = () => {
    api
      .getCompanies()
      .then((res) => {
        if (res?.status == "success") {
          let finder = res?.companies?.filter((i) => i?.status == 1);
          if (finder?.length > 0) {
            setCompanyList(finder);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const handleCompanyChange = (id) => {
    let finder = listCompany?.find((i) => i?.id == id);
    setCompanyData(finder);
    setPoFormDetails({ ...poFormDetails, company_id: id });
  };

  // console.log("vendorDetail", vendorDetail, companyData);

  return (
    // <Col xs={12} className="py-2  m-0 px-1 p-md-3">
    <Col
      xs={12}
      className={`py-2 px-1 p-md-3  m-0 ${
        tableFields?.length > 0 && vendorDetail?.state && "w-80 m-0"
      }`}
    >
      <Header />
      <PoCreationForm
        setCheck={setCheck}
        check={check}
        error={error}
        poDateHandler={poDateHandler}
        currenyHandler={currenyHandler}
        procureSelectHandler={procureSelectHandler}
        matReqTableDetailsHandler={matReqTableDetailsHandler}
        vendorList={vendorList}
        projectList={projectList}
        setPoFormDetails={setPoFormDetails}
        poFormDetails={poFormDetails}
        requestionType={requestionType}
        setRequestionType={setRequestionType}
        setPoTableData={setPoTableData}
        assetReqSelectHandler={assetReqSelectHandler}
        department={department}
        setDepartment={setDepartment}
        listCompany={listCompany}
        handleCompanyChange={handleCompanyChange}
        vendorDetail={vendorDetail}
        setVendorDetail={setVendorDetail}
        address={address}
        setAddress={setAddress}
        addressDisable={addressDisable}
        setAddressDisable={setAddressDisable}
      />
      {/* <PoCreationFormCopy /> */}
      {/* <PoCreationtTableSection /> 
      <PoTermsAndCondition /> */}
      <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center ">
        {tableFields?.length > 0 &&
        currentTable == "assets_type" &&
        vendorDetail?.state ? (
          <PoCreationtTableSection
            tableFields={poTableData}
            boqAttributeModalHandler={boqAttributeModalHandler}
            tableSelHandler={tableSelHandler}
            selectedMaterial={selectedMaterial}
            setSelectedMaterial={setSelectedMaterial}
            tableHeadings={table_headings}
            currentTable={currentTable}
            commonFields={commonFields}
            boqFields={boqFields}
            basicPriceHandler={basicPriceHandler}
            tableError={tableError}
            miscModalHandler={miscModalHandler}
            miscEnable={miscEnable}
            setCommonFields={setCommonFields}
            newSpecifs={newSpecifs}
          />
        ) : vendorDetail?.state ? (
          <PoCreationtTableSection
            tableFields={tableFields}
            tableHeadings={table_headings}
          />
        ) : null}
      </Col>

      <Col xs={12} sm={12} className="inputinner-box  bg-white">
        <div className="w-90">
          <button
            className="bg-primar border-0 rounded-1  mt-0  mb-3 text-white f-1 fs-14 px-4 py-2"
            // onClick={submitHandler}
            onClick={submitPop}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Col>
      <Modal show={modal} centered onHide={() => setModal(!modal)}>
        {modelDisplay == "misc" ? (
          <>
            <Modal.Header className="border-0 w-100">
              <div className="d-flex justify-content-between w-100">
                <label className=" my-1 py-1"> Miscellaneous Screen </label>
                <button
                  className="border-0 bg-transparent"
                  onClick={() => {
                    setBtn(false);
                    setModal(false);
                  }}
                >
                  X
                </button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="w-100 my-1 py-1">
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  HSN Code
                </label>
                <input
                  onChange={(e) =>
                    searchHsn(e.target.value, commonFields?.total_price[selId])
                  }
                />
              </div>
              <div className="w-100 my-1 py-1">
                <label style={{ width: "140px" }} className="fs-14 me-2">
                  HSN Description
                </label>
                <input value={hsnDetail?.hsn_des[selId] ?? " "} />
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Total Value
                  </label>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={commonFields?.total_price[selId]}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Total Value After Discount
                  </label>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={miscDetails?.total_val_af_dis}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Discount
                  </label>
                  <input
                    className="w-25"
                    onChange={(e) =>
                      miscDiscountHandler(
                        e.target.value,
                        commonFields?.total_price[selId]
                      )
                    }
                  />
                  <span className="ms-1">%</span>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={miscDetails?.discount_value}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    IGST
                  </label>
                  <input
                    className="w-25"
                    readOnly
                    value={hsnDetail?.igst[selId]}
                  />
                  <span className="ms-1">%</span>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={hsnDetail?.igst_value[selId] ?? ""}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    SGST
                  </label>
                  <input
                    className="w-25"
                    readOnly
                    value={hsnDetail?.sgst[selId]}
                  />
                  <span className="ms-1">%</span>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={hsnDetail?.sgst_value[selId]}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    CGST
                  </label>
                  <input
                    className="w-25"
                    readOnly
                    value={hsnDetail?.cgst[selId]}
                  />
                  <span className="ms-1">%</span>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    value={hsnDetail?.cgst_value[selId]}
                  />
                </Col>
              </div>
              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Round Off
                  </label>
                  <button
                    className="border-0 me-1 bg-primary1"
                    onClick={() => roundOffActionHandler("+")}
                  >
                    +
                  </button>
                  <button
                    className="border-0 ms-1 bg-primary2"
                    onClick={() => roundOffActionHandler("-")}
                  >
                    -
                  </button>
                </Col>
              </div>

              <div className="w-100 d-flex my-1 py-1">
                <Col xs={8}>
                  <label style={{ width: "140px" }} className="fs-14 me-2">
                    Net Value
                  </label>
                </Col>
                <Col xs={4}>
                  <label className="fs-14 me-2">Value</label>
                  <input
                    className="w-50"
                    readOnly
                    type="text"
                    value={
                      miscDetails?.net_value
                        ? Number(miscDetails?.net_value).toFixed(2)
                        : ""
                    }
                  />
                </Col>
              </div>
            </Modal.Body>
            <Modal.Footer className="border-0">
              <button
                onClick={() => setModal(!modal)}
                className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
              >
                Cancel
              </button>
              <button
                className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
                onClick={miscSubmitHandler}
              >
                Save
              </button>
            </Modal.Footer>
          </>
        ) : modelDisplay === "boq-att" ? (
          <>
            <Modal.Header className="border-0 w-100">
              <div className="d-flex justify-content-between w-100">
                <label className=" my-1 py-1">
                  {" "}
                  {boqFields?.show_attribute?.length > 0
                    ? "Specfications"
                    : "No Specifications"}{" "}
                </label>
                <button
                  className="border-0 bg-transparent"
                  onClick={() => {
                    setBtn(false);
                    setModal(false);
                  }}
                >
                  X
                </button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <>
                <ul className="w-90">
                  {boqFields?.show_attribute.length > 0 &&
                    boqFields?.show_attribute?.map((att, ind) => {
                      return (
                        <li key={ind} className="d-flex my-1 py-1">
                          <input
                            type="checkbox"
                            checked={
                              boqFields?.attribute[selId] &&
                              boqFields?.attribute[selId].includes(
                                `specification${ind + 1}`
                              )
                            }
                            value={`specification${ind + 1}`}
                            onChange={(e) =>
                              boqAttributeSelHandler(`specification${ind + 1}`)
                            }
                            className="pointer me-1 pe-1"
                          />
                          <p className="ms-1 ps-1">{att}</p>
                        </li>
                      );
                    })}
                </ul>
                {specLength?.length > 0 &&
                  specLength?.map((it, ind) => {
                    // console.log(specLength);
                    return (
                      <div className="w-90 m-auto d-flex flex-wrap mb-2">
                        {/* <h6 className="mb-1 ">
                          Specification{" "}
                          {ind + 1 + boqFields?.show_attribute?.length}
                        </h6> */}
                        <input
                          onChange={(e) => newSpecChangeHandler(e, ind)}
                          type="text"
                          className="w-100"
                          value={newSpecifs?.[selId]?.[ind]}
                        />
                      </div>
                    );
                  })}
              </>
            </Modal.Body>
            <Modal.Footer className="border-0">
              <button
                className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
                onClick={() => setModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2"
                onClick={() => setModal(false)}
              >
                Save
              </button>
            </Modal.Footer>
          </>
        ) : modelDisplay == "print" ? (
          <>
            <Modal.Header className="border-0 w-100">
              <div className="d-flex justify-content-between w-100">
                <label className=" my-1 py-1">
                  {" "}
                  Po Number: {poSavedetails?.po_no ? poSavedetails?.po_no : "-"}
                </label>
                <button
                  className="border-0 bg-transparent"
                  onClick={() => {
                    setBtn(false);
                    setModal(false);
                  }}
                >
                  X
                </button>
              </div>
            </Modal.Header>
            {/* <Modal.Body className="border-0">
              <p className="text-center fs-6">
                Do you want to Print the Output?
              </p>
            </Modal.Body> */}
            <Modal.Footer className="border-0">
              <Button
                className="btn btn-primary"
                // onClick={() => setModelDisplay("annex")}
                onClick={() => window.location.reload()}
                variant="primary"
              >
                {/* Print */}
                Submit
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setModal(!modal);
                  window.location.reload();
                  setBtn(false);
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </>
        ) : modelDisplay === "annex" ? (
          <>
            <Modal.Header className="border-0 w-100">
              <div className="d-flex justify-content-between w-100">
                <label className=" my-1 py-1">Annexure</label>
                <button
                  className="border-0 bg-transparent"
                  onClick={() => {
                    setBtn(false);
                    setModal(false);
                  }}
                >
                  X
                </button>
              </div>
            </Modal.Header>
            <Modal.Body className="border-0">
              <div className="d-flex justify-content-end flex-column my-3">
                <a
                  href="http://erp-script.cresconprojects.com/public/excel/cresscon-annexure-sample.xlsx"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-primar border-0 rounded-1 text-white f-1 mx-auto fs-14 px-4 my-1 py-2 pointer"
                >
                  Sample Download
                </a>
                <div class="input-group">
                  <input
                    type="file"
                    class="form-control border-0 bg-lightBlue my-1 w-90"
                    placeholder="Recipient's username"
                    aria-label="Recipient's username"
                    onChange={(e) => setUpload(e.target.files[0])}
                    aria-describedby="button-addon2"
                  />
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                    // onClick={uploadFile}
                    onClick={submitHandler}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="border-0">
              <Button
                className="btn btn-primary"
                // onClick={() =>
                //   // navigate("/procurement/po-output", {
                //   //   state: { id: poSavedetails?.id },
                //   // })

                //   window.location.reload()
                // }
                onClick={() => submitHandler(1)}
                variant="primary"
              >
                Submit
              </Button>
              <Button
                variant="secondary"
                // onClick={() => setModelDisplay("print")}
                onClick={() => {
                  window.location.reload();
                  setBtn(false);
                }}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </>
        ) : modelDisplay == "alert" ? (
          <Modal.Body>
            <div className="d-flex gap-2 mt-3 justify-content-center align-items-center">
              <h5 className="err">Only 10% Avalible in Total Price</h5>
              <TbAlertTriangleFilled className="fs-6" />
            </div>
            <div className="d-flex">
              <button
                className="bg-primar border-0 rounded-1 mt-3 text-white ms-auto f-1 fs-14 px-4 py-2"
                onClick={() => setModal(false)}
              >
                Ok
              </button>
            </div>
          </Modal.Body>
        ) : null}
      </Modal>
    </Col>
  );
};

export default PoCreationScreen;
