import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
function ProjectCreationForm({ projectForm, setProjectForm, formError }) {
  const [list, setList] = useState([]);
  const getList = () => {
    api
      .getCompanies()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.companies);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getList();
  }, []);
  return (
    <Col className="emply-master-form px-4 py-2 py-md-4 bg-white mt-3 text-center">
      <h4 className="ms-xl-0 w-95">Project Creation</h4>
      <Col className="d-flex flex-wrap mx-lg-3">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Head Office</label>
          {/* <p
            style={{ minHeight: "2rem", width: "90%", marginRight: "auto" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            Crescon Projects and Service Pvt Ltd
          </p> */}

          <select
            className="border-0 bg-lightBlue px-2 py-2 w-90 mt-1"
            placeholder="Enter Project Name"
            value={projectForm.company_id}
            onChange={(e) =>
              setProjectForm({
                ...projectForm,
                company_id: e.target.value,
              })
            }
          >
            <option value={""}>Select Company</option>
            {list?.map((item, ind) => {
              return (
                <option key={ind} value={item?.id}>
                  {item?.name}
                </option>
              );
            })}
          </select>
          {formError.includes("company_id") && (
            // projectForm.company_id?.length == 0 &&
            <p className="err"> Please Select Company</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Project Name"
            value={projectForm.name}
            onChange={(e) =>
              setProjectForm({
                ...projectForm,
                name: e.target.value,
              })
            }
          />
          {formError.includes("name") && (
            <p className="err"> Please Enter Project Name</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project No</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Project No"
            value={projectForm.project_no}
            onChange={(e) =>
              setProjectForm({
                ...projectForm,
                project_no: e.target.value,
              })
            }
          />
          {formError.includes("project_no") && (
            <p className="err"> Please Enter Project Number</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Date</label>
          <input
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Project Date"
            type="date"
            value={projectForm.date}
            onChange={(e) =>
              setProjectForm({
                ...projectForm,
                date: e.target.value,
              })
            }
          />
          {formError.includes("date") && (
            <p className="err"> Please Enter Project Date</p>
          )}
        </Col>
      </Col>
    </Col>
  );
}

export default ProjectCreationForm;
