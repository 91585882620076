// @ts-nocheck
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { dotIcon, tickIcon } from "../../assets/img";
import moment from "moment";
import { api } from "../../constants/Services";

const DayEnd = () => {
  const [dete, setDate] = useState("");
  const [po, setpo] = useState("");
  const [materialReq, setmaterialReq] = useState("");
  const [boq, setboq] = useState("");
  const [grn, setgrn] = useState("");
  const [invoice, setinvoice] = useState("");
  const [materialIss, setmaterialIss] = useState("");
  const [internalRet, setinternalRet] = useState("");
  const [materialTrans, setmaterialTrans] = useState("");
  const [materialRece, setmaterialRece] = useState("");
  const [materialRet, setmaterialRet] = useState("");

  const [poDete, setPoDate] = useState("");
  const [matrialRequsetDete, setMatrialRequsetDate] = useState("");
  const [boqDete, setBoqDate] = useState("");
  const [grnDete, setGrnDate] = useState("");
  const [invoiceDete, setInvoiceDate] = useState("");
  const [materialIssueDete, setMaterialIssueDate] = useState("");
  const [internalReturnDete, setinternalReturnDate] = useState("");
  const [materialTransferDete, setMaterialTransferDate] = useState("");
  const [matrialReceiptDete, setMatrialReceiptDate] = useState("");
  const [matrialReturnDete, setMatrialReturnDate] = useState("");

  const [deteErr, setDateErr] = useState(false);
  const [poDeteErr, setPoDateErr] = useState(false);
  const [matrialRequsetDeteErr, setMatrialRequsetDateErr] = useState(false);
  const [boqDeteErr, setBoqDateErr] = useState(false);
  const [grnDeteErr, setGrnDateErr] = useState(false);
  const [invoiceDeteErr, setInvoiceDateErr] = useState(false);
  const [materialIssueDeteErr, setMaterialIssueDateErr] = useState(false);
  const [internalReturnDeteErr, setinternalReturnDateErr] = useState(false);
  const [materialTransferDeteErr, setMaterialTransferDateErr] = useState(false);
  const [matrialReceiptDeteErr, setMatrialReceiptDateErr] = useState(false);
  const [matrialReturnDeteErr, setMatrialReturnDateErr] = useState(false);

  const runProcess = (type) => {
    setDate("");
    setpo("");
    setmaterialReq("");
    setboq("");
    setgrn("");
    setinvoice("");
    setmaterialIss("");
    setinternalRet("");
    setmaterialTrans("");
    setmaterialRece("");
    setmaterialRet("");
    let formdata = new FormData();
    if (type == 1) {
      setMatrialRequsetDate("");
      setBoqDate("");
      setGrnDate("");
      setInvoiceDate("");
      setMaterialIssueDate("");
      setinternalReturnDate("");
      setMaterialTransferDate("");
      setMatrialReceiptDate("");
      setMatrialReturnDate("");
      if (poDete?.length > 0) {
        formdata.append("processed_date", poDete);
        formdata.append("module", type);
      } else {
        setPoDateErr(true);
      }
    } else if (type == 2) {
      setPoDate("");
      setBoqDate("");
      setGrnDate("");
      setInvoiceDate("");
      setMaterialIssueDate("");
      setinternalReturnDate("");
      setMaterialTransferDate("");
      setMatrialReceiptDate("");
      setMatrialReturnDate("");
      if (matrialRequsetDete?.length > 0) {
        formdata.append("processed_date", matrialRequsetDete);
        formdata.append("module", type);
      } else {
        setMatrialRequsetDateErr(true);
      }
    } else if (type == 3) {
      setPoDate("");
      setMatrialRequsetDate("");
      setGrnDate("");
      setInvoiceDate("");
      setMaterialIssueDate("");
      setinternalReturnDate("");
      setMaterialTransferDate("");
      setMatrialReceiptDate("");
      setMatrialReturnDate("");
      if (boqDete?.length > 0) {
        formdata.append("processed_date", boqDete);
        formdata.append("module", type);
      } else {
        setBoqDateErr(true);
      }
    } else if (type == 4) {
      setPoDate("");
      setMatrialRequsetDate("");
      setBoqDate("");
      setInvoiceDate("");
      setMaterialIssueDate("");
      setinternalReturnDate("");
      setMaterialTransferDate("");
      setMatrialReceiptDate("");
      setMatrialReturnDate("");
      if (grnDete?.length > 0) {
        formdata.append("processed_date", grnDete);
        formdata.append("module", type);
      } else {
        setGrnDateErr(true);
      }
    } else if (type == 5) {
      setPoDate("");
      setMatrialRequsetDate("");
      setBoqDate("");
      setGrnDate("");
      setMaterialIssueDate("");
      setinternalReturnDate("");
      setMaterialTransferDate("");
      setMatrialReceiptDate("");
      setMatrialReturnDate("");
      if (invoiceDete?.length > 0) {
        formdata.append("processed_date", invoiceDete);
        formdata.append("module", type);
      } else {
        setInvoiceDateErr(true);
      }
    } else if (type == 6) {
      setPoDate("");
      setMatrialRequsetDate("");
      setBoqDate("");
      setGrnDate("");
      setInvoiceDate("");
      setinternalReturnDate("");
      setMaterialTransferDate("");
      setMatrialReceiptDate("");
      setMatrialReturnDate("");
      if (materialIssueDete?.length > 0) {
        formdata.append("processed_date", materialIssueDete);
        formdata.append("module", type);
      } else {
        setMaterialIssueDateErr(true);
      }
    } else if (type == 7) {
      setPoDate("");
      setMatrialRequsetDate("");
      setBoqDate("");
      setGrnDate("");
      setInvoiceDate("");
      setMaterialIssueDate("");
      setMaterialTransferDate("");
      setMatrialReceiptDate("");
      setMatrialReturnDate("");
      if (internalReturnDete?.length > 0) {
        formdata.append("processed_date", internalReturnDete);
        formdata.append("module", type);
      } else {
        setinternalReturnDateErr(true);
      }
    } else if (type == 8) {
      setPoDate("");
      setMatrialRequsetDate("");
      setBoqDate("");
      setGrnDate("");
      setInvoiceDate("");
      setMaterialIssueDate("");
      setinternalReturnDate("");
      setMatrialReceiptDate("");
      setMatrialReturnDate("");
      if (materialTransferDete?.length > 0) {
        formdata.append("processed_date", materialTransferDete);
        formdata.append("module", type);
      } else {
        setMaterialTransferDateErr(true);
      }
    } else if (type == 9) {
      setPoDate("");
      setMatrialRequsetDate("");
      setBoqDate("");
      setGrnDate("");
      setInvoiceDate("");
      setMaterialIssueDate("");
      setinternalReturnDate("");
      setMaterialTransferDate("");
      setMatrialReturnDate("");
      if (matrialReceiptDete?.length > 0) {
        formdata.append("processed_date", matrialReceiptDete);
        formdata.append("module", type);
      } else {
        setMatrialReceiptDateErr(true);
      }
    } else if (type == 10) {
      setPoDate("");
      setMatrialRequsetDate("");
      setBoqDate("");
      setGrnDate("");
      setInvoiceDate("");
      setMaterialIssueDate("");
      setinternalReturnDate("");
      setMaterialTransferDate("");
      setMatrialReceiptDate("");
      if (matrialReturnDete?.length > 0) {
        formdata.append("processed_date", matrialReturnDete);
        formdata.append("module", type);
      } else {
        setMatrialReturnDateErr(true);
      }
    }

    api
      .runModule(formdata)
      .then((res) => {
        if (res?.status == "success") {
          setPoDateErr(false);
          setMatrialRequsetDateErr(false);
          setBoqDateErr(false);
          setGrnDateErr(false);
          setInvoiceDateErr(false);
          setMaterialIssueDateErr(false);
          setinternalReturnDateErr(false);
          setMaterialTransferDateErr(false);
          setMatrialReceiptDateErr(false);
          setMatrialReturnDateErr(false);
          setPoDate("");
          setMatrialRequsetDate("");
          setBoqDate("");
          setGrnDate("");
          setInvoiceDate("");
          setMaterialIssueDate("");
          setinternalReturnDate("");
          setMaterialTransferDate("");
          setMatrialReceiptDate("");
          setMatrialReturnDate("");
          alert(res?.message);
        } else {
          console.log("res", res);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const employeeStatus = () => {
    let formdata = new FormData();
    formdata.append("processed_date", dete);
    if (dete?.length == 0) {
      setDateErr(true);
    } else {
      api
        .employeeStatus(formdata)
        .then((res) => {
          if (res?.status == "success") {
            let dates = res?.employee_module;
            dates?.map((item, ind) => {
              if (item?.module == 1) {
                setPoDate(item?.processed_date);
                setpo(item?.status);
              } else if (item?.module == 2) {
                setMatrialRequsetDate(item?.processed_date);
                setmaterialReq(item?.status);
              } else if (item?.module == 3) {
                setBoqDate(item?.processed_date);
                setboq(item?.status);
              } else if (item?.module == 4) {
                setGrnDate(item?.processed_date);
                setgrn(item?.status);
              } else if (item?.module == 5) {
                setInvoiceDate(item?.processed_date);
                setinvoice(item?.status);
              } else if (item?.module == 6) {
                setMaterialIssueDate(item?.processed_date);
                setmaterialIss(item?.status);
              } else if (item?.module == 7) {
                setinternalReturnDate(item?.processed_date);
                setinternalRet(item?.status);
              } else if (item?.module == 8) {
                setMaterialTransferDate(item?.processed_date);
                setmaterialTrans(item?.status);
              } else if (item?.module == 9) {
                setMatrialReceiptDate(item?.processed_date);
                setmaterialRece(item?.status);
              } else if (item?.module == 10) {
                setMatrialReturnDate(item?.processed_date);
                setmaterialRet(item?.status);
              }
            });
          } else {
            console.log("res", res);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center  bg-white">
      <h4>Day End Process</h4>
      <div className="d-flex ac-jb">
        <Col className="d-flex flex-wrap">
          {/* <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Project Name</label>
          <select
            onChange={(e) => projectSelectionHandler(e)}
            value={projectName}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
          >
            <option>Select Project</option>
            {projectList?.map((project) => (
              <option key={project?.id} value={project?.id}>
                {project?.name}
              </option>
            ))}
          </select>

          {projectName?.length == 0 && projectNameErr && (
            <p className="err">enter project name</p>
          )}
        </Col>
         <Col xs={12} sm={6} xl={4} className="inputinner-box text-start">
          <label className="w-100">Project From</label>
          <p
            style={{ minHeight: "2.1rem", width: "90%", lineHeight: "2.1rem" }}
            className="input-select bg-lightBlue ms-0 ps-3"
          >
            {moment(projectDate).format("DD-MM-YYYY")}
          </p>
        </Col> */}
          {/* <Col xs={12} sm={6} xl={4} className="inputinner-box text-start">
            <label className="w-100">Date</label>
            <input
              type="date"
              className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
              placeholder="Enter Invoice Date"
              onChange={(e) => setDate(e.target.value)}
              value={dete}
            />
            {dete?.length == 0 && deteErr && (
              <p err position-absolute>
                Select Date
              </p>
            )}
          </Col> */}
        </Col>
        {/* <div className="d-flex ac-js">
          <button
            class="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-4 py-2 "
            onClick={() => employeeStatus()}
          >
            Run
          </button>
        </div> */}
      </div>
      {/* <Col className="d-flex flex-wrap mt-2">
        <Col xs={6} sm={4} md={3} xxl={2} className=" text-start mb-3 w-100">
          <p className="m-0 p-0 fs-12 mb-1 w-100 text-nowrap">Approvals</p>
          <button className="bg-primar border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex">
            <div className="aprvl">
              <img src={tickIcon} />
            </div>
            Approved
          </button>
        </Col>
        <Col xs={6} sm={4} md={3} xxl={2} className=" text-start mb-3">
          <p className="m-0 p-0 fs-12 mb-1 w-100 text-nowrap">GRN Approvals</p>
          <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex">
            <div className="pendg">
              <img src={dotIcon} />
            </div>
            Pending
          </button>
        </Col>
        <Col xs={6} sm={4} md={3} xxl={2} className=" text-start mb-3">
          <p className="m-0 p-0 fs-12 mb-1 w-100 text-nowrap">Material Transfer</p>
          <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex">
            <div className="pendg">
              <img src={dotIcon} />
            </div>
            Pending
          </button>
        </Col>
        <Col xs={6} sm={4} md={3} xxl={2} className=" text-start mb-3">
          <p className="m-0 p-0 fs-12 mb-1 w-100 text-nowrap">Material Receipt</p>
          <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex">
            <div className="pendg">
              <img src={dotIcon} />
            </div>
            Pending
          </button>
        </Col>
        <Col xs={6} sm={4} md={3} xxl={2} className=" text-start mb-3">
          <p className="m-0 p-0 fs-12 mb-1 w-100 text-nowrap">Material Issues</p>
          <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex">
            <div className="pendg">
              <img src={dotIcon} />
            </div>
            Pending
          </button>
        </Col>
        <Col xs={6} sm={4} md={3} xxl={2} className=" text-start mb-3">
          <p className="m-0 p-0 fs-12 mb-1 w-100 text-nowrap">Material Return</p>
          <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex">
            <div className="pendg">
              <img src={dotIcon} />
            </div>
            Pending
          </button>
        </Col>
      </Col> */}
      <div className="w-80 d-flex as-js mx-0">
        <Col className="mt-2 w-100">
          {/* <Col
            xs={12}
            sm={6}
            xl={4}
            className="text-start mb-3 w-100 d-flex as-js"
          >
            <div className="w-50">
              <p className="m-0 p-0 fs-12 mb-1 text-nowrap">Purchase Order :</p>
              <button
                class="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-3 py-1 mt-3"
                onClick={() => runProcess(1)}
              >
                Run
              </button>
            </div>
            <div className="w-40 d-flex gap-4">
              <div>
                <input
                  type="date"
                  className="border-0 bg-lightBlue px-2 py-1 w-100 mt-1"
                  placeholder="Enter Invoice Date"
                  onChange={(e) => setPoDate(e.target.value)}
                  value={poDete}
                />
                {poDete?.length == 0 && poDeteErr && (
                  <p className="err position-absolute">Select Po Date</p>
                )}
              </div>
              {po ? (
                +po == 2 ? (
                  <button className="bg-primar border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="aprvl">
                      <img src={tickIcon} />
                    </div>
                    Approved
                  </button>
                ) : (
                  <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="pendg">
                      <img src={dotIcon} />
                    </div>
                    Pending
                  </button>
                )
              ) : null}
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            xl={4}
            className="text-start mb-3 w-100 d-flex as-js"
          >
            <div className="w-50">
              <p className="m-0 p-0 fs-12 mb-1 text-nowrap">
                Material Request :
              </p>
              <button
                class="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-3 py-1 mt-3"
                onClick={() => runProcess(2)}
              >
                Run
              </button>
            </div>
            <div className="w-40 d-flex gap-4">
              <div>
                <input
                  type="date"
                  className="border-0 bg-lightBlue px-2 py-1 w-100 mt-1"
                  placeholder="Enter Invoice Date"
                  onChange={(e) => setMatrialRequsetDate(e.target.value)}
                  value={matrialRequsetDete}
                />
                {matrialRequsetDete?.length == 0 && matrialRequsetDeteErr && (
                  <p className="err position-absolute">
                    Select Matrial Requset Date
                  </p>
                )}
              </div>
              {materialReq ? (
                +materialReq == 2 ? (
                  <button className="bg-primar border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="aprvl">
                      <img src={tickIcon} />
                    </div>
                    Approved
                  </button>
                ) : (
                  <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="pendg">
                      <img src={dotIcon} />
                    </div>
                    Pending
                  </button>
                )
              ) : null}
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            xl={4}
            className="text-start mb-3 w-100 d-flex as-js"
          >
            <div className="w-50">
              <p className="m-0 p-0 fs-12 mb-1 text-nowrap">BOQ :</p>
              <button
                class="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-3 py-1 mt-3"
                onClick={() => runProcess(3)}
              >
                Run
              </button>
            </div>
            <div className="w-40 d-flex gap-4">
              <div>
                <input
                  type="date"
                  className="border-0 bg-lightBlue px-2 py-1 w-100 mt-1"
                  placeholder="Enter Invoice Date"
                  onChange={(e) => setBoqDate(e.target.value)}
                  value={boqDete}
                />
                {boqDete?.length == 0 && boqDeteErr && (
                  <p className="err position-absolute">Select BOQ Date</p>
                )}
              </div>
              {boq ? (
                +boq == 2 ? (
                  <button className="bg-primar border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="aprvl">
                      <img src={tickIcon} />
                    </div>
                    Approved
                  </button>
                ) : (
                  <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="pendg">
                      <img src={dotIcon} />
                    </div>
                    Pending
                  </button>
                )
              ) : null}
            </div>
          </Col> */}
          <Col
            xs={12}
            sm={6}
            xl={4}
            className="text-start mb-3 w-100 d-flex as-js"
          >
            <div className="w-50">
              <p className="m-0 p-0 fs-12 mb-1 text-nowrap">GRN :</p>
              <button
                class="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-3 py-1 mt-3"
                onClick={() => runProcess(4)}
              >
                Run
              </button>
            </div>
            <div className="w-40 d-flex gap-4">
              <div>
                {" "}
                <input
                  type="date"
                  className="border-0 bg-lightBlue px-2 py-1 w-100 mt-1"
                  placeholder="Enter Invoice Date"
                  onChange={(e) => setGrnDate(e.target.value)}
                  value={grnDete}
                />
                {grnDete?.length == 0 && grnDeteErr && (
                  <p className="err position-absolute">Select GRN Date</p>
                )}
              </div>
              {grn ? (
                +grn == 2 ? (
                  <button className="bg-primar border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="aprvl">
                      <img src={tickIcon} />
                    </div>
                    Approved
                  </button>
                ) : (
                  <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="pendg">
                      <img src={dotIcon} />
                    </div>
                    Pending
                  </button>
                )
              ) : null}
            </div>
          </Col>

          <Col
            xs={12}
            sm={6}
            xl={4}
            className="text-start mb-3 w-100 d-flex as-js"
          >
            <div className="w-50">
              <p className="m-0 p-0 fs-12 mb-1 text-nowrap">
                Invoice Booking :
              </p>
              <button
                class="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-3 py-1 mt-3"
                onClick={() => runProcess(5)}
              >
                Run
              </button>
            </div>
            <div className="w-40 d-flex gap-4">
              <div>
                <input
                  type="date"
                  className="border-0 bg-lightBlue px-2 py-1 w-100 mt-1"
                  placeholder="Enter Invoice Date"
                  onChange={(e) => setInvoiceDate(e.target.value)}
                  value={invoiceDete}
                />
                {invoiceDete?.length == 0 && invoiceDeteErr && (
                  <p className="err position-absolute">
                    Select Invoice Booking Date
                  </p>
                )}
              </div>
              {invoice ? (
                +invoice == 2 ? (
                  <button className="bg-primar border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="aprvl">
                      <img src={tickIcon} />
                    </div>
                    Approved
                  </button>
                ) : (
                  <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="pendg">
                      <img src={dotIcon} />
                    </div>
                    Pending
                  </button>
                )
              ) : null}
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            xl={4}
            className="text-start mb-3 w-100 d-flex as-js"
          >
            <div className="w-50">
              <p className="m-0 p-0 fs-12 mb-1 text-nowrap">Material Issue :</p>
              <button
                class="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-3 py-1 mt-3"
                onClick={() => runProcess(6)}
              >
                Run
              </button>
            </div>
            <div className="w-40 d-flex gap-4">
              <div>
                <input
                  type="date"
                  className="border-0 bg-lightBlue px-2 py-1 w-100 mt-1"
                  placeholder="Enter Invoice Date"
                  onChange={(e) => setMaterialIssueDate(e.target.value)}
                  value={materialIssueDete}
                />

                {materialIssueDete?.length == 0 && materialIssueDeteErr && (
                  <p className="err position-absolute">
                    Select Material Issue Date
                  </p>
                )}
              </div>
              {materialIss ? (
                +materialIss == 2 ? (
                  <button className="bg-primar border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="aprvl">
                      <img src={tickIcon} />
                    </div>
                    Approved
                  </button>
                ) : (
                  <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="pendg">
                      <img src={dotIcon} />
                    </div>
                    Pending
                  </button>
                )
              ) : null}
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            xl={4}
            className="text-start mb-3 w-100 d-flex as-js"
          >
            <div className="w-50">
              <p className="m-0 p-0 fs-12 mb-1 text-nowrap">
                Internal Return :
              </p>
              <button
                class="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-3 py-1 mt-3"
                onClick={() => runProcess(7)}
              >
                Run
              </button>
            </div>
            <div className="w-40 d-flex gap-4">
              <div>
                <input
                  type="date"
                  className="border-0 bg-lightBlue px-2 py-1 w-100 mt-1"
                  placeholder="Enter Invoice Date"
                  onChange={(e) => setinternalReturnDate(e.target.value)}
                  value={internalReturnDete}
                />
                {internalReturnDete?.length == 0 && internalReturnDeteErr && (
                  <p className="err position-absolute">
                    Select Internal Return Date
                  </p>
                )}
              </div>
              {internalRet ? (
                +internalRet == 2 ? (
                  <button className="bg-primar border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="aprvl">
                      <img src={tickIcon} />
                    </div>
                    Approved
                  </button>
                ) : (
                  <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="pendg">
                      <img src={dotIcon} />
                    </div>
                    Pending
                  </button>
                )
              ) : null}
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            xl={4}
            className="text-start mb-3 w-100 d-flex as-js"
          >
            <div className="w-50">
              <p className="m-0 p-0 fs-12 mb-1 text-nowrap">
                Material Transfer :
              </p>
              <button
                class="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-3 py-1 mt-3"
                onClick={() => runProcess(8)}
              >
                Run
              </button>
            </div>
            <div className="w-40 d-flex gap-4">
              <div>
                <input
                  type="date"
                  className="border-0 bg-lightBlue px-2 py-1 w-100 mt-1"
                  placeholder="Enter Invoice Date"
                  onChange={(e) => setMaterialTransferDate(e.target.value)}
                  value={materialTransferDete}
                />
                {materialTransferDete?.length == 0 &&
                  materialTransferDeteErr && (
                    <p className="err position-absolute">
                      Select Material Transfer Dete
                    </p>
                  )}
              </div>
              {materialTrans ? (
                +materialTrans == 2 ? (
                  <button className="bg-primar border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="aprvl">
                      <img src={tickIcon} />
                    </div>
                    Approved
                  </button>
                ) : (
                  <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="pendg">
                      <img src={dotIcon} />
                    </div>
                    Pending
                  </button>
                )
              ) : null}
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            xl={4}
            className="text-start mb-3 w-100 d-flex as-js"
          >
            <div className="w-50">
              <p className="m-0 p-0 fs-12 mb-1 text-nowrap">
                Material Receipt :
              </p>
              <button
                class="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-3 py-1 mt-3"
                onClick={() => runProcess(9)}
              >
                Run
              </button>
            </div>
            <div className="w-40 d-flex gap-4">
              <div>
                <input
                  type="date"
                  className="border-0 bg-lightBlue px-2 py-1 w-100 mt-1"
                  placeholder="Enter Invoice Date"
                  onChange={(e) => setMatrialReceiptDate(e.target.value)}
                  value={matrialReceiptDete}
                />
                {matrialReceiptDete?.length == 0 && matrialReceiptDeteErr && (
                  <p className="err position-absolute">
                    Select Matrial Receipt Dete
                  </p>
                )}
              </div>
              {materialRece ? (
                +materialRece == 2 ? (
                  <button className="bg-primar border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="aprvl">
                      <img src={tickIcon} />
                    </div>
                    Approved
                  </button>
                ) : (
                  <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="pendg">
                      <img src={dotIcon} />
                    </div>
                    Pending
                  </button>
                )
              ) : null}
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            xl={4}
            className="text-start mb-3 w-100 d-flex as-js"
          >
            <div className="w-50">
              <p className="m-0 p-0 fs-12 mb-1 text-nowrap">
                Material Return :
              </p>
              <button
                class="bg-primar border-0 rounded-1 text-white f-1 fs-14 px-3 py-1 mt-3"
                onClick={() => runProcess(10)}
              >
                Run
              </button>
            </div>
            <div className="w-40 d-flex gap-4">
              <div>
                <input
                  type="date"
                  className="border-0 bg-lightBlue px-2 py-1 w-100 mt-1"
                  placeholder="Enter Invoice Date"
                  onChange={(e) => setMatrialReturnDate(e.target.value)}
                  value={matrialReturnDete}
                />
                {matrialReturnDete?.length == 0 && matrialReturnDeteErr && (
                  <p className="err position-absolute">
                    Select Matrial Return Dete
                  </p>
                )}
              </div>
              {materialRet ? (
                +materialRet == 2 ? (
                  <button className="bg-primar border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="aprvl">
                      <img src={tickIcon} />
                    </div>
                    Approved
                  </button>
                ) : (
                  <button className="bg-orange border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
                    <div className="pendg">
                      <img src={dotIcon} />
                    </div>
                    Pending
                  </button>
                )
              ) : null}
            </div>
          </Col>

          {/* <Col
          xs={12}
          sm={6}
          xl={4}
          className="text-start mb-3 d-flex ac-jb gap-5">
          <p className="m-0 p-0 fs-12 mb-1 w-100 text-nowrap">GRN :</p>
          <div className="w-100 d-flex gap-4">
            <input
              type="date"
              className="border-0 bg-lightBlue px-2 py-1 w-100 mt-1"
              placeholder="Enter Invoice Date"
              onChange={(e) => setInvoiceDate(e.target.value)}
              value={InvoiceDate}
            />
            <button className="bg-primar border-0 rounded-1 my-0 text-white f-1 fs-13 px-2 py-1 d-flex ac-jc">
              <div className="aprvl">
                <img src={tickIcon} />
              </div>
              Approved
            </button>
          </div>
        </Col> */}
        </Col>
      </div>

      {/* <Col className="text-start">
        <button className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2 me-3">
          Fetch
        </button>
      </Col> */}
    </Col>
  );
};

export default DayEnd;
