import React, { useState } from "react";
import { Col } from "react-bootstrap";

const BankDetails = ({
  setTab,
  setTempFormData,
  tempFormData,
  setAllowAccess,
  allowAccess,
}) => {
  const [error, setError] = useState([]);
  const [bankDetails, setBankDetails] = useState({
    acc_type: tempFormData?.acc_type || "",
    branch: tempFormData?.branch || "",
  });
  const [bankDetailsRequiredFields, setBankDetailsRequiredFields] = useState({
    bank_name: tempFormData?.bank_name || "",
    account_no: tempFormData?.account_no || "",
    ifsc: tempFormData?.ifsc || "",
  });

  const empMasterBankNextClickHandler = () => {
    // console.log("coming-bank-submit", bankDetailsRequiredFields);

    let temp = [];
    Object.entries(bankDetailsRequiredFields).forEach((item) => {
      console.log("each-value", item);
      if (item[1] === "") {
        temp.push(item[0]);
      }
    });

    setError(temp);
    if (Object.values(bankDetailsRequiredFields).every((x) => x !== "")) {
      console.log("coming-bank-submit-11");
      setTempFormData({
        ...tempFormData,
        ...bankDetails,
        ...bankDetailsRequiredFields,
      });
      setTab("document");
      setAllowAccess([...allowAccess, "bank"]);
      resetData();
    }
  };

  const resetData = () => {
    setBankDetails({
      acc_type: "",
      branch: "",
    });

    setBankDetailsRequiredFields({
      bank_name: "",
      account_no: "",
      ifsc: "",
    });
  };
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-0 text-center">
      <h4 className="w-95 ms-xl-0">Bank Details</h4>
      <Col className="d-flex flex-wrap">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Name of Bank<span className="err">*</span>
          </label>
          <input
            value={bankDetailsRequiredFields.bank_name}
            onChange={(e) =>
              setBankDetailsRequiredFields({
                ...bankDetailsRequiredFields,
                bank_name: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Name of Bank"
          />
          {Object.values(error)?.includes("bank_name") && (
            <p className="err">Bank Field Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            Account No<span className="err">*</span>
          </label>
          <input
            value={bankDetailsRequiredFields.account_no}
            onChange={(e) =>
              setBankDetailsRequiredFields({
                ...bankDetailsRequiredFields,
                account_no: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Account No"
          />
          {Object.values(error)?.includes("account_no") && (
            <p className="err">Account Field Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Account Type</label>
          <input
            value={BankDetails.acc_type}
            onChange={(e) =>
              setBankDetails({
                ...bankDetails,
                acc_type: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Account Type"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">
            IFSC Code<span className="err">*</span>
          </label>
          <input
            value={bankDetailsRequiredFields.ifsc}
            onChange={(e) =>
              setBankDetailsRequiredFields({
                ...bankDetailsRequiredFields,
                ifsc: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter IFSC Code"
          />
          {Object.values(error)?.includes("ifsc") && (
            <p className="err">Account Field Required</p>
          )}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Branch Name</label>
          <input
            value={BankDetails.branch}
            onChange={(e) =>
              setBankDetails({
                ...bankDetails,
                branch: e.target.value,
              })
            }
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Branch Name"
          />
        </Col>
        <Col sm={12} className="inputinner-box text-start">
          <button
            className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-16 px-4 py-2"
            onClick={empMasterBankNextClickHandler}>
            Next
          </button>
          <button
            className="bg-gray border-0 rounded-1 my-3 lightBlue f-1 fs-14 px-4 py-2 ms-3"
            onClick={resetData}>
            cancel
          </button>
        </Col>
      </Col>
    </Col>
  );
};

export default BankDetails;
