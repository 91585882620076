// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { accountTreeIcon, tickIcon } from "../../assets/img";
import { api } from "../../constants/Services";
import { useNavigate } from "react-router-dom";
// import { mmCheck } from "../../constants/StaticData";

const MaterialMasterLevl = ({ materialLevelDetails, setMatId }) => {
  // // console.log(
  // //   "🚀 ~ file: MaterialMasterLevl.js:10 ~ MaterialMasterLevl ~ materialLevelDetails:",
  // //   materialLevelDetails
  // // );
  const { child_material, parent_material } = materialLevelDetails;
  // console.log(
  // "🚀 ~ file: MaterialMasterLevl.js:15 ~ MaterialMasterLevl ~ parent_material:",
  // parent_material
  // );
  // console.log(
  // "🚀 ~ file: MaterialMasterLevl.js:15 ~ MaterialMasterLevl ~ child_material:",
  // child_material
  // );

  const first_child = child_material?.filter(
    (children) => children?.level == 2
  );
  const sec_child = child_material?.filter((children) => children?.level == 3);
  const third_child = child_material?.filter(
    (children) => children?.level == 4
  );
  // console.log("🚀 ~ file: MaterialMasterLevl.js:31 ~ MaterialMasterLevl ~ third_child:", third_child)

  const fouth_child = child_material?.filter(
    (children) => children?.level == 5
  );
  const fifthx_child = child_material?.filter(
    (children) => children?.level == 6
  );

  const navigate = useNavigate();

  const [filteredMaterialList, setFilteredMaterialList] = useState([]);
  const materDetailsHandler = (sel_material) => {
    setMatId(sel_material);
    // navigate("/procurement/material-action", {
    //   state: { id: sel_material, action: "view" },
    // });
  };
  const materialFilterHandler = (search_value) => {
    const renderedList = parent_material?.filter((parent) => {
      if (
        parent?.material_code?.includes(search_value) ||
        parent?.name.trim().toLowerCase().includes(search_value)
      ) {
        return parent;
      }
    });
    search_value.length > 0
      ? setFilteredMaterialList(renderedList)
      : setFilteredMaterialList([]);
  };
  return (
    <Col className="emply-master-form px-3 py-2 py-md-4 bg-white mt-3 text-center">
      <Col xs={12} className="inputinner-box text-start text-center">
        <input
          className="border-0 bg-lightBlue px-2 py-1 w-50 mt-1"
          placeholder="Search...,"
          onChange={(e) => materialFilterHandler(e.target.value)}
        />
      </Col>
      <h4>Material Master</h4>
      {filteredMaterialList &&
        filteredMaterialList?.map((parent) => (
          <Col key={parent?.id}>
            <button
              className="d-flex level-btn"
              // onClick={() => setMatId(parent?.id)}
            >
              <p className="primary f2">{parent?.name}</p>
              <p className="ms-2 f2">{parent?.material_code}</p>
            </button>
            {first_child?.map(
              (child, ind) =>
                child?.parent_id === parent.id && (
                  <Col key={child?.id}>
                    <Col
                      xs={12}
                      className="level-master d-flex justify-content-end pointer"
                      onClick={() => materDetailsHandler(child?.parent_id)}
                    >
                      <div className="line-space" />
                      <Col>
                        <Col className="d-flex level-btn justify-content-between">
                          <div className="d-flex align-items-center">
                            <div className="text-start ms-2">
                              <p className="text-black fs-14 f3">
                                {child?.name}
                              </p>
                              <p className="text-dark fs-12 f1">
                                Level {child.level}
                              </p>
                            </div>
                          </div>
                        </Col>
                        {sec_child &&
                          sec_child?.map(
                            (sec_level_child) =>
                              sec_level_child?.parent_id == child?.id && (
                                <Col
                                  xs={12}
                                  key={sec_level_child?.id}
                                  className="level-master d-flex justify-content-end pointer"
                                  onClick={() =>
                                    materDetailsHandler(
                                      sec_level_child?.parent_id
                                    )
                                  }
                                >
                                  <div className="line-space" />
                                  <Col>
                                    <Col className="d-flex level-btn justify-content-between">
                                      <div className="d-flex align-items-center">
                                        <div className="text-start ms-2">
                                          <p className="text-black fs-14 f3">
                                            {sec_level_child?.name}
                                          </p>
                                          <p className="text-dark fs-12 f1">
                                            Level {sec_level_child.level}
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                    {third_child &&
                                      third_child?.map(
                                        (third_level_child) =>
                                          third_level_child?.parent_id ==
                                            sec_level_child?.id && (
                                            <Col
                                              xs={12}
                                              key={third_level_child?.id}
                                              className="level-master d-flex justify-content-end pointer"
                                              onClick={() =>
                                                materDetailsHandler(
                                                  third_level_child?.parent_id
                                                )
                                              }
                                            >
                                              <div className="line-space" />
                                              <Col>
                                                <Col className="d-flex level-btn justify-content-between">
                                                  <div className="d-flex align-items-center">
                                                    <div className="text-start ms-2">
                                                      <p className="text-black fs-14 f3">
                                                        {
                                                          third_level_child?.name
                                                        }
                                                      </p>
                                                      <p className="text-dark fs-12 f1">
                                                        Level
                                                        {
                                                          third_level_child?.level
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </Col>
                                                {fouth_child &&
                                                  fouth_child?.map(
                                                    (fouth_level_child) =>
                                                      fouth_level_child?.parent_id ==
                                                        third_level_child?.id && (
                                                        <Col
                                                          xs={12}
                                                          key={
                                                            fouth_level_child?.id
                                                          }
                                                          className="level-master d-flex justify-content-end pointer"
                                                          onClick={() =>
                                                            materDetailsHandler(
                                                              fouth_level_child?.parent_id
                                                            )
                                                          }
                                                        >
                                                          <div className="line-space" />
                                                          <Col>
                                                            <Col className="d-flex level-btn justify-content-between">
                                                              <div className="d-flex align-items-center">
                                                                <div className="text-start ms-2">
                                                                  <p className="text-black fs-14 f3">
                                                                    {
                                                                      fouth_level_child?.name
                                                                    }
                                                                  </p>
                                                                  <p className="text-dark fs-12 f1">
                                                                    Level
                                                                    {
                                                                      fouth_level_child?.level
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </div>
                                                            </Col>
                                                            {fifthx_child &&
                                                              fifthx_child?.map(
                                                                (
                                                                  fifth_level_child
                                                                ) =>
                                                                  fifth_level_child?.parent_id ==
                                                                    fouth_level_child?.id && (
                                                                    <Col
                                                                      xs={12}
                                                                      key={
                                                                        fifth_level_child?.id
                                                                      }
                                                                      className="level-master d-flex justify-content-end pointer"
                                                                      onClick={() =>
                                                                        materDetailsHandler(
                                                                          fifth_level_child?.parent_id
                                                                        )
                                                                      }
                                                                    >
                                                                      <div className="line-space" />
                                                                      <Col>
                                                                        <Col className="d-flex level-btn justify-content-between">
                                                                          <div className="d-flex align-items-center">
                                                                            <div className="text-start ms-2">
                                                                              <p className="text-black fs-14 f3">
                                                                                {
                                                                                  fifth_level_child?.name
                                                                                }
                                                                              </p>
                                                                              <p className="text-dark fs-12 f1">
                                                                                Level
                                                                                {
                                                                                  fifth_level_child?.level
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                          </div>
                                                                        </Col>
                                                                      </Col>
                                                                    </Col>
                                                                  )
                                                              )}
                                                          </Col>
                                                        </Col>
                                                      )
                                                  )}
                                              </Col>
                                            </Col>
                                          )
                                      )}
                                  </Col>
                                </Col>
                              )
                          )}
                      </Col>
                    </Col>
                  </Col>
                )
            )}
          </Col>
        ))}
      {/* 
      <Col xs={12} className="level-master d-flex justify-content-end">
        <div className="line-space" />
        <Col>
          <Col className="d-flex level-btn justify-content-between">
            <div className="d-flex align-items-center">
              <div className="img-box">
                <img
                  src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1031&q=80"
                  className="inner-img"
                  alt="inner-img"
                />
              </div>
              <div className="text-start ms-2">
                <p className="text-black fs-14 f3">Rubesh John</p>
                <p className="text-dark fs-12 f1">Level 1</p>
              </div>
            </div>
            <button className="tree-icon">
              <img src={accountTreeIcon} />
            </button>
          </Col>
          <Col xs={12} className="level-master d-flex justify-content-end">
            <div className="line-space" />
            <Col>
              <Col className="d-flex level-btn justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="img-box">
                    <img
                      src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1031&q=80"
                      className="inner-img"
                      alt="inner-img"
                    />
                  </div>
                  <div className="text-start ms-2">
                    <p className="text-black fs-14 f3">Rubesh John</p>
                    <p className="text-dark fs-12 f1">Level 2</p>
                  </div>
                </div>
                <button className="tree-icon">
                  <img src={accountTreeIcon} alt="acc-tree" />
                </button>
              </Col>
              <Col xs={12} className="level-master d-flex justify-content-end">
                <div className="line-space" />
                <Col>
                  <Col className="d-flex level-btn justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="img-box">
                        <img
                          src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1031&q=80"
                          className="inner-img"
                          alt="inner-img"
                        />
                      </div>
                      <div className="text-start ms-2">
                        <p className="text-black fs-14 f3">Rubesh John</p>
                        <p className="text-dark fs-12 f1">Level 3</p>
                      </div>
                    </div>
                    <button className="tree-icon">
                      <img src={accountTreeIcon} alt="act-tree-img" />
                    </button>
                  </Col>
                  <Col
                    xs={12}
                    className="level-master d-flex justify-content-end">
                    <div className="line-space" />
                    <Col>
                      <Col className="d-flex level-btn justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="img-box">
                            <img
                              src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1031&q=80"
                              className="inner-img"
                              alt="inner-img"
                            />
                          </div>
                          <div className="text-start ms-2">
                            <p className="text-black fs-14 f3">Rubesh John</p>
                            <p className="text-dark fs-12 f1">Level 4</p>
                          </div>
                        </div>
                        <button className="tree-icon">
                          <img src={accountTreeIcon} alt="inner-img" />
                        </button>
                      </Col>
                      <Col
                        xs={12}
                        className="level-master d-flex justify-content-end">
                        <div className="line-space" />
                        <Col>
                          <Col className="d-flex level-btn justify-content-between">
                            <div className="d-flex align-items-center">
                              <div className="img-box">
                                <img
                                  src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1031&q=80"
                                  className="inner-img"
                                  alt="inner-img"
                                />
                              </div>
                              <div className="text-start ms-2">
                                <p className="text-black fs-14 f3">
                                  Rubesh John
                                </p>
                                <p className="text-dark fs-12 f1">Level 5</p>
                              </div>
                            </div>
                            <button className="tree-icon">
                              <img src={accountTreeIcon} alt="inner-img" />
                            </button>
                          </Col>
                        </Col>
                      </Col>
                    </Col>
                  </Col>
                </Col>
              </Col>
            </Col>
          </Col>
        </Col>
      </Col> */}
    </Col>
  );
};

export default MaterialMasterLevl;
