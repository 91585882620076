import React, { useState } from "react";

import { api } from "../../constants/Services";
import { Col } from "react-bootstrap";

const GSTRegForm = ({
  gstFormDetails,
  setGstFormDetails,
  setTempForm,
  showDrop,
  setShowDrop,
  tempForm,
}) => {
  const [vendorsList, setVendorsList] = useState([]);
  const [pan, setPan] = useState();

  const gstSearchNameHandler = () => {
    let formData = new FormData();
    formData.append("name", tempForm.company_name);
    tempForm.company_name !== "" &&
      api.vendorSearch(formData).then((res) => {
        setVendorsList(res?.vendors);
        setPan(res?.vendors[0]?.pan);
        setShowDrop(true);
      });
  };

  const vendorDetailsHandler = (ven_id) => {
    const selected_vendor = vendorsList.filter(
      (vendor) => vendor?.id === +ven_id
    );
    let gst_type_temp;
    if (selected_vendor) {
      gst_type_temp = selected_vendor[0]?.gst_state_type;
      gst_type_temp = gst_type_temp?.toLowerCase().split("-")[1];
    }

    let state_box = document.getElementById("state-dropdown");
    let temp_gst_state;
    // console.log("test-23", selected_vendor[0]);
    Object?.entries(state_box?.options)?.map((state) => {
      // console.log("state", state[1].text.toLowerCase().trim());
      if (
        state[1].text
          .toLowerCase()
          .trim()
          .includes(selected_vendor[0]?.state?.toLowerCase())
      ) {
        temp_gst_state = state[1]?.value;
      }
    });
    // __reactProps$49sgr4e9679

    setTempForm({
      ...tempForm,
      supplier_type: selected_vendor[0]?.type,
    });

    setGstFormDetails({
      ...gstFormDetails,
      vendor_id: selected_vendor[0]?.id,
      pan: selected_vendor[0]?.pan,
      gst_state: temp_gst_state,
      gst_type: gst_type_temp,
    });
  };

  let renderingContent;

  if (showDrop) {
    renderingContent = (
      <select
        className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 pointer"
        onChange={(e) => vendorDetailsHandler(e.target.value)}
      >
        <option>Select Vendor</option>
        {vendorsList?.map((vendor) => (
          <option value={vendor?.id}>{vendor.company_name}</option>
        ))}
      </select>
    );
  } else {
    renderingContent = (
      <div className="d-flex position-relative">
        <input
          style={{ width: "90%", marginRight: "auto" }}
          value={tempForm.company_name}
          onChange={(e) =>
            setTempForm({
              ...tempForm,
              company_name: e.target.value.replace(/[^\sa-z]/gi, ""),
            })
          }
          className="border-0 bg-lightBlue form-control px-2 py-1 mt-1"
          placeholder="Enter your Supplier Name"
        />
        <button
          class="text-white bg-primar border-0 rounded-1 gst-btn position-absolute"
          onClick={gstSearchNameHandler}
        >
          Submit
        </button>
      </div>
    );
  }

  return (
    <Col className="emply-master-form px-3 py-2 py-md-3 bg-white mt-3 text-center">
      <h4 className="w-95 ms-xl-0">GST Registration</h4>
      <Col className="d-flex flex-wrap mt-2">
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Supplier Name</label>
          {renderingContent}
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Supplier Type</label>
          <p
            style={{ width: "90%", marginRight: "auto", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            {tempForm.supplier_type}
          </p>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">Date</label>
          <input
            value={gstFormDetails.gst_date}
            onChange={(e) =>
              setGstFormDetails({ ...gstFormDetails, gst_date: e.target.value })
            }
            type="date"
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter Date"
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">PAN</label>
          <p
            style={{ width: "90%", marginRight: "auto", minHeight: "2.1rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            {gstFormDetails?.pan}
          </p>
        </Col>

        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">State Code</label>
          <select
            id="state-dropdown"
            value={gstFormDetails.gst_state}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 pointer"
            onChange={(e) =>
              setGstFormDetails({
                ...gstFormDetails,
                gst_state: e.target.value,
              })
            }
          >
            <option>Choose Gst State</option>
            <option value={35}>35 Andaman and Nicobar Islands </option>
            <option value={4}>4 Chandigarh </option>
            <option value={26}>26 Dadra & Nagar Haveli and Daman & Di </option>
            <option value={97}>97 Other Territory </option>
            <option value={37}>37 Andhra Pradesh </option>
            <option value={12}>12 Arunachal Pradesh </option>
            <option value={18}>18 Assam </option>
            <option value={10}>10 Bihar </option>
            <option value={22}>22 Chattisgarh </option>
            <option value={30}>30 Goa</option>
            <option value={24}>24 Gujarat</option>
            <option value={6}>6 Haryana </option>
            <option value={2}>2 Himachal Pradesh</option>
            <option value={1}>1 Jammu and Kashmir </option>
            <option value={20}>20 Jharkhand </option>
            <option value={29}>29 Karnataka</option>
            <option value={32}>32 Kerala</option>
            <option value={31}>31 Lakshadweep Islands </option>
            <option value={23}>23 Madhya Pradesh</option>
            <option value={27}>27 Maharashtra </option>
            <option value={14}>14 Manipur </option>
            <option value={17}>17 Meghalaya </option>
            <option value={15}>15 Mizoram </option>
            <option value={13}>13 Nagaland </option>
            <option value={21}>21 Odisha</option>
            <option value={34}>34 Pondicherry</option>
            <option value={3}>3 Punjab </option>
            <option value={7}>7 Delhi</option>
            <option value={8}>8 Rajasthan </option>
            <option value={11}>11 Sikkim </option>
            <option value={33}>33 Tamil Nadu </option>
            <option value={36}>36 Telangana</option>
            <option value={16}>16 Tripura</option>
            <option value={9}>9 Uttar Pradesh</option>
            <option value={5}>5 Uttarakhand</option>
            <option value={19}>19 West Bengal</option>
          </select>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GST Type</label>
          <select
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1 pointer"
            value={gstFormDetails.gst_type}
            onChange={(e) =>
              setGstFormDetails({ ...gstFormDetails, gst_type: e.target.value })
            }
          >
            <option>Choose Gst Type</option>
            <option value="regular">Regular</option>
            <option value="composite">Composite</option>
          </select>
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GST Registration Form</label>
          <input
            value={gstFormDetails?.temp}
            className="border-0 bg-lightBlue px-2 py-1 w-90 mt-1"
            placeholder="Enter your GST Registration Form"
            onChange={(e) =>
              setGstFormDetails({ ...gstFormDetails, temp: e.target.value })
            }
          />
        </Col>
        <Col xs={12} sm={6} xl={6} className="inputinner-box text-start">
          <label className="w-100">GST No</label>
          <p
            style={{ width: "90%", marginRight: "auto", minHeight: "2rem" }}
            className="border-0 bg-lightBlue px-2 py-1 mt-1"
          >
            {`${gstFormDetails?.gst_state}${gstFormDetails?.pan}${gstFormDetails?.temp}`}
          </p>
        </Col>
        {/* <Col xs={12} sm={12} className="inputinner-box text-start">
          <button className="bg-primar border-0 rounded-1 my-3 text-white f-1 fs-14 px-4 py-2">
            Submit
          </button>
        </Col> */}
      </Col>
    </Col>
  );
};

export default GSTRegForm;
