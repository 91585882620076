// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { MdDeleteOutline, MdOutlineFileCopy } from "react-icons/md";
import info_icon from "../../assets/icons/common/Info.svg";
import { useNavigate } from "react-router-dom";
import { calendarIcon } from "../../assets/img";
import moment from "moment";
import useUser from "../../constants/Storage/userData";

const Header = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  var [date, setDate] = useState(new Date());

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  let login_name = localStorage.getItem("name");
  let login_role = localStorage.getItem("role");

  return (
    <Col xs={10} className="d-flex top-header  px-3 py-2 bg-white w-100">
      <Col xs={1} className="d-flex align-items-center">
        <BiArrowBack className="pointer fs-4" onClick={() => navigate(-1)} />
      </Col>
      <Col xs={5}>
        <h6 className="text-uppercase">{user?.name}</h6>
        <p>{user?.role}</p>
        <div className="header-icons">
          <BsArrowLeftCircle onClick={() => navigate(-1)} />
          <BsArrowRightCircle onClick={() => navigate(1)} />
          <MdOutlineFileCopy />
          <MdDeleteOutline />
        </div>
      </Col>

      <Col xs={5} className="d-flex align-items-center justify-content-end">
        <img
          src={info_icon}
          onClick={() => navigate("/notification")}
          className="calender-icon pointer me-3"
          alt="info-icon"
        />
        <img
          src={calendarIcon}
          className="calender-icon me-3"
          alt="calendor-icon"
        />
        <div>
          {/* <p>{moment().format("DD MMMM yyy")}</p>
          <p>{moment().format("hh:ss a")}</p> */}
          <p>{moment(date).format("DD-MMM-YYYY")}</p>
          <p>{moment(date).format("hh:mm A")}</p>
        </div>
      </Col>
    </Col>
  );
};

export default Header;
