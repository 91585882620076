import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { api } from "../../constants/Services";
import AttributeForm from "../../component/Attributes/AttributeForm";
import ReportLevel from "../../component/Attributes/ReportLevel";
import Header from "../../component/Header/Header";

const AttributeMasterScreen = () => {
  const [matId, setMatId] = useState(null);
  const [materialDetails, setMaterialDetail] = useState();

  console.log(
    "🚀 ~ file: AttributeMasterScreen.js:11 ~ AttributeMasterScreen ~ materialDetails:",
    materialDetails
  );
  const [materialLevelDetails, setMaterialLevelDetails] = useState({
    parent_material: [],
    child_material: [],
  });
  const [error, setError] = useState([]);

  const [attributeOptionalFields, setAttributeOptionalFields] = useState({
    specification1: "",
    specification2: "",
    specification3: "",
    specification4: "",
  });

  const [attributeRequiredDetails, setAttributeRequiredDetails] = useState({
    make: "",
    color: "",
    model: "",
    attribute_type: "",
  });

  useEffect(() => {
    api.material_assests_search("?type=2").then((res) => {
      if (res?.status === "success") {
        setMaterialLevelDetails({
          parent_material: res?.parent_suggestions,
          child_material: res?.other_level_sugestions,
        });
      }
      console.log("mater-search-res", res);
    });
  }, []);

  useEffect(() => {
    api.material_show(matId).then((res) => {
      setMaterialDetail(res?.material);
    });
  }, [matId]);
  return (
    <Col xs={12} className="py-2 px-1 p-md-3">
      <Header />
      <AttributeForm
        materialDetails={materialDetails}
        setMaterialDetail={setMaterialDetail}
      />
      <ReportLevel
        materialLevelDetails={materialLevelDetails}
        setMatId={setMatId}
      />
    </Col>
  );
};

export default AttributeMasterScreen;
